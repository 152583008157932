import React from "react";
import {
  AddFormButton,
  FilterButton,
  YellowButton,
} from "../../../../shared/components/TableElements";
import { useTranslation } from "react-i18next";

const SingleProvider = ({
  providerName,
  providerIcon,
  providerState,
  handleClick,
}) => {
  const ProviderButton = ({ providerState }) => {
    const { t } = useTranslation("common");
    return (
      <>
        {providerState === 1 && (
          <AddFormButton type="button" onClick={handleClick}>
            {t("connect")}
          </AddFormButton>
        )}
        {providerState === 2 && (
          <YellowButton type="button" onClick={handleClick}>
            {t("modify")}
          </YellowButton>
        )}
        {providerState === 3 && (
          <FilterButton disabled={true} style={{ opacity: 0.4 }}>
            {t("coming_soon")}
          </FilterButton>
        )}
        {providerState === 4 && (
          <AddFormButton type="button" disabled={true}>
            {t("connect")}
          </AddFormButton>
        )}
      </>
    );
  };

  return (
    <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
      <img
        src={providerIcon}
        alt="provider-icon"
        width={120}
        height={120}
        style={{ objectFit: "contain", opacity: providerState === 3 ? 0.4 : 1 }}
      />
      {/* <p>{providerName}</p> */}
      <ProviderButton providerState={providerState} />
    </div>
  );
};

export default SingleProvider;
