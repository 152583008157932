import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
  FilterButton,
} from "../../shared/components/TableElements";
import {
  initialRangeData,
  initialContainsData,
} from "../../shared/constants/filterData";
import DataReactTable from "../DataTable/components/DataReactTable";
import { Link } from "react-router-dom";
import getUnixTime from "date-fns/getUnixTime";
import {
  getDisplayDate,
  getDistanceUnit,
  getListingDisplayDate,
} from "../../shared/helpers";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import { useLocation } from "react-router";
import { putApi } from "../../api/getUser";
import ExplorePopup from "../../shared/components/ExplorePopup";
import endOfToday from "date-fns/endOfToday";
import { LinkSpan } from "../../shared/components/form/FormElements";
import startOfDay from "date-fns/startOfDay";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const DailyMileageTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  //const fromCancel = location.state?.fromCancel;
  const columnWidth = 130;
  const { rowData, values, status } = useSelector((state) => state.filter);
  const { minDate } = useSelector((state) => state.columns);
  const filterData = useSelector((state) => state.filter.data);
  const columns = useSelector((state) => state.advancedFilter.dailymileage);
  const { currency, distance } = useSelector((state) => state.units);
  const { reload, setReload } = useAuth();
  const { orgDate } = useSelector((state) => state.user);

  const { isLoggedIn, setLoading } = useAuth();
  const newData = {
    ...filterData,
    ...columns,
  };
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = filterData;

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(endOfToday()),
    query: "get_mileage_data",
    sortIndex: 3,
  });

  // //Initial Effect
  // useEffect(() => {
  //   if (
  //     pathname.includes("dailymileage") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //   ) {
  //     (async () => {
  //       setLoading(true);
  //       const startDate = 0;
  //       const endDate = getUnixTime(endOfToday());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "dailymileage",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       dispatch(
  //         updateInitialData({
  //           query: "get_mileage_data",
  //           startDate,
  //           endDate,
  //           order: 2,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: 2,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: endDate,
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "get_mileage_data",
  //       };

  //       dispatch(fetchData({ ...initialData, ...columns }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // useEffect(() => {
  //   // if (token) {
  //   if (!firstTimeLoad) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData(newData));
  //   }
  //   // }
  // }, [
  //   draw,
  //   length,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   //start,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   columns,
  // ]);

  // useEffect(() => {
  //   // if (token) {
  //   if (!firstTimeLoad) {
  //     dispatch(fetchData(newData));
  //   }
  //   // }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   // if (token) {
  //   if (!firstTimeLoad && reload === "dailymileage") {
  //     setReload("");
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...newData, start: 0 }));
  //   }
  //   // }
  // }, [reload]);

  let valuesArray = [
    {
      id: 0,
      name: t("distance"),
      value: values?.footertotalkm
        ? `${values?.footertotalkm} ${getDistanceUnit(distance)}`
        : NATEXT,
      //units: values.footertotalkm ? "Km" : "",
      //icon: "road",
    },
  ];

  if (values?.footertotalhr) {
    valuesArray.push({
      id: 1,
      name: t("hours_const"),
      value: values?.footertotalhr
        ? `${values?.footertotalhr} ${getDistanceUnit("Hours")}`
        : NATEXT,
    });
  }

  const { defaultContainsData, defaultRangeData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      enteredBy: {
        column: "enteredBy",
        name: t("entered_by"),
        ...defaultContainsData,
      },
      date: {
        column: "date",
        name: t("date_tv"),
        dateOption: "all_time",
        from: startOfDay(new Date(minDate * 1000)),
        to: endOfToday(),
        isFiltered: false,
      },
      startingOdo: {
        column: "startingOdo",
        name: t("starting_odo"),
        ...defaultRangeData,
      },
      endingOdo: {
        column: "endingOdo",
        name: t("ending_odo"),
        ...defaultRangeData,
      },
      distance: {
        column: "distance",
        name: t("distance"),
        ...defaultRangeData,
      },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );
  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("entered_by"),
        accessor: "uname",
        width: 130,
        disableFilters: true,
        Cell: ({ value }) => (value ? value : NATEXT),
        //Cell: ({ value }) => <VehicleComponent value={value} />,
      },
      {
        id: 3,
        Header: t("date_tv"),
        accessor: "timestamp",
        width: columnWidth,
        Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
        //Cell: ({ value }) => (value ? getListingDisplayDate(value) : NATEXT),
      },
      {
        id: 4,
        Header: t("starting_odo"),
        accessor: "starting_odo",
        width: columnWidth,
        Cell: ({ row }) =>
          row?.original?.starting_odo && row?.original?.odo_require
            ? `${row?.original?.starting_odo} ${
                row?.original?.primary_meter === "Hours"
                  ? getDistanceUnit("Hours")
                  : getDistanceUnit(distance)
              }`
            : NATEXT,
      },
      {
        id: 5,
        Header: t("ending_odo"),
        accessor: "ending_odo",
        width: columnWidth,
        Cell: ({ row }) =>
          row?.original?.ending_odo && row?.original?.odo_require
            ? `${row?.original?.ending_odo} ${
                row?.original?.primary_meter === "Hours"
                  ? getDistanceUnit("Hours")
                  : getDistanceUnit(distance)
              }`
            : NATEXT,
      },
      {
        id: 6,
        Header: t("distance"),
        accessor: "dist",
        width: columnWidth,
        Cell: ({ row }) =>
          row.original.dist && +row.original.dist > 0
            ? `${row.original.dist} ${
                row?.original?.primary_meter === "Hours"
                  ? getDistanceUnit("Hours")
                  : getDistanceUnit(distance)
              }`
            : NATEXT,
      },
      {
        id: 7,
        Header: t("notes_tv"),
        accessor: "comments",
        Cell: ({ value }) => (value ? `${value}` : NATEXT),
        width: columnWidth,
      },
      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
      // {
      //   id: 12,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
    ],
    [distance]
  );

  let count = 0;
  let defaultColumns = {};
  columnHeaders.forEach((column) => {
    defaultColumns[count] = {
      accessor: column.Header,
      header: column.Header,
    };
    count++;
  });

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };
  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IXXH403O"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("se_mileage")}</ColumnHeading>
            {/* <FilterButton onClick={() => setShowExplorePopup(true)}>
              <i
                className="fi fi-rr-globe"
                style={{ margin: "4px 4px 0 0" }}
              ></i>
              <span>{t("explore")}</span>
            </FilterButton> */}
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col className="d-flex justify-content-end pb-1">
            <Link to={"/dailymileage/addnew"}>
              <AddFormButton id="add-fillup-button">
                {t("add_mileage")}
              </AddFormButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={valuesArray}
            initialData={initialData}
            defaultColumns={defaultColumns}
          />
        </Row>
      </Container>
    </>
  );
};

export default DailyMileageTable;
