import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTableConstructor from "./components/ReactTableConstructor";
import ReactTableCell from "./components/ReactTableCell";
import ReactTableCellEditable from "./components/ReactTableEditableCell";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import ReactTableConstructorClient from "./components/ReactTableConstructorClient";

const ReactTableBase = ({
  tableConfig,
  columns,
  data,
  updateDraggableData,
  updateEditableData,
  valuesArray,
  initialData,
  setData,
  localFilters,
  setLocalFilters,
  notInspectedVehiclesLength,
  vehiclesTableData,
  pauses,
}) => {
  const location = useLocation();
  const path = location.pathname;
  let screen = path.slice(1, path.length);
  let serverSideFetching = true;
  if (
    screen.includes("inspectiontasks") ||
    screen.includes("inspectionforms") ||
    screen.includes("inspectionscheduling") ||
    screen.includes("servicetasks") ||
    screen.includes("expensetasks") ||
    screen.includes("users") ||
    screen.includes("vehicles") ||
    screen.includes("dailytimesheet") ||
    screen.includes("faultcodes")
  ) {
    serverSideFetching = false;
  }

  const [rightPanelData, setRightPanelData] = useState({
    show: false,
    content: "",
  });

  const {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize,
  } = tableConfig;

  const length = useSelector((state) => state.filter.data.length);

  const totalRecords = useSelector((state) => state.filter.totalRecords);

  let sortByObj = { id: 2, desc: true };
  if (screen.includes("dailymileage")) {
    sortByObj = { id: 3, desc: true };
  } else if (screen.includes("workorders")) {
    sortByObj = { id: 4, desc: true };
  } else if (
    screen.includes("vehicles") ||
    screen.includes("users") ||
    screen.includes("faultcodes")
  ) {
    sortByObj = { id: 1, desc: false };
  } else if (screen.includes("issues")) {
    sortByObj = { id: 6, desc: true };
  } else if (
    screen.includes("maintenancereminders") ||
    screen.includes("renewals") ||
    screen.includes("inspectionscheduling") ||
    screen.includes("dailytimesheet")
  ) {
    sortByObj = { id: 3, desc: true };
  } else if (
    screen.includes("servicetasks") ||
    screen.includes("expensetasks")
  ) {
    sortByObj = { id: 2, desc: false };
  } else if (
    screen.includes("inspectiontasks") ||
    screen.includes("inspectionforms")
  ) {
    sortByObj = { id: 1, desc: false };
  } else if (screen.includes("inspectionsubmissions")) {
    sortByObj = { id: 3, desc: true };
  }
  const [filterValue, setFilterValue] = useState(null);
  const tableOptions = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable, // !isSortable
    manualSortBy: true, // !isSortable
    manualGlobalFilter: true, //!withSearchEngine
    manualPagination: true, //!withPagination
    pageCount: Math.ceil(totalRecords / length),
    initialState: {
      pageIndex: 0,
      pageSize: 25,
      //pageSize: 50,  //manualPageSize ? manualPageSize[0] : 10,
      globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
      sortBy: [sortByObj], // id: columns[0]?.id
      columnOrder: [1, 2, 3, 4, 5, 6],
      //hiddenColumns: [7, 8, 9, 10, 11, 12, 13],
    },
    disableSortRemove: true,
    //defaultCanSort: true,
    //autoResetSortBy: false,
  };

  const tableOptionsClient = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable, // !isSortable
    manualSortBy: !isSortable,
    manualGlobalFilter: !withSearchEngine,
    manualPagination: false,
    //pageCount: Math.ceil(totalRecords / length),
    initialState: {
      pageIndex: 0,
      pageSize: manualPageSize ? manualPageSize[0] : 10,
      globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
      sortBy: [sortByObj], // id: columns[0]?.id
      //columnOrder: [1, 2, 3, 4, 5, 6],
      //hiddenColumns: [7, 8, 9, 10, 11, 12, 13],
    },
    disableSortRemove: true,
    //defaultCanSort: true,
    //autoResetSortBy: false,
  };

  let tableOptionalHook = [];
  //if (isResizable) tableOptionalHook = [useFlexLayout];
  if (withSearchEngine) {
    tableOptions.defaultColumn = {
      Cell: ReactTableCell,
    };
  }
  if (isEditable) {
    tableOptions.defaultColumn = {
      Cell: ReactTableCellEditable,
    };
  }

  return (
    <>
      {serverSideFetching ? (
        <ReactTableConstructor
          tableConfig={tableConfig}
          tableOptions={tableOptions}
          valuesArray={valuesArray}
          initialData={initialData}
          screen={screen}
          setData={setData}
          notInspectedVehiclesLength={notInspectedVehiclesLength}
          vehiclesTableData={vehiclesTableData}
          rightPanelData={rightPanelData}
          setRightPanelData={setRightPanelData}
          pauses={pauses}
        />
      ) : (
        <ReactTableConstructorClient
          tableConfig={tableConfig}
          tableOptions={tableOptionsClient}
          valuesArray={valuesArray}
          initialData={initialData}
          screen={screen}
          data={data}
          setData={setData}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
          rightPanelData={rightPanelData}
          setRightPanelData={setRightPanelData}
        />
      )}
    </>
  );
};

ReactTableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
};

ReactTableBase.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [],
  },
  columns: [
    { Header: "#", accessor: "id" },
    { Header: "Header Example Title one", accessor: "first" },
    { Header: "Header Example Title two", accessor: "last" },
  ],
  data: [
    { id: 1, first: "Cell Example Data one", last: "Cell Example Data two" },
    { id: 2, first: "Cell Example Data three", last: "Cell Example Data four" },
  ],
  updateDraggableData: () => {},
  updateEditableData: () => {},
};

export default ReactTableBase;
