import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    className: 'off',
  };
  
  const shadowSlice = createSlice({
    name: 'shadow',
    initialState,
    reducers: {
        changeBlocksShadows: (state, action) => {
            state.className = state.className === 'off' ? 'on' : 'off';
        },
    },
  });
  
  export const { changeBlocksShadows } = shadowSlice.actions;
  export default shadowSlice.reducer;


