import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ActionIconContainer,
  AddFormButton,
} from "../../shared/components/TableElements";

const InactiveErrorPopup = ({ inactiveErrData, setInactiveErrData }) => {
  const { t } = useTranslation("common");

  const { users, manager } = inactiveErrData;
  const handleClose = () => {
    setInactiveErrData(null);
  };
  return (
    <Modal show={inactiveErrData} onHide={handleClose}>
      <Modal.Header>
        <h4>{t("error")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            {t("cannot_inactivate_manager_err_msg", {
              managerName: manager.name,
            })}
          </p>
          <ul>
            {users.map((u) => (
              <li
                id={u.user_id}
                key={u.user_id}
                style={{ fontWeight: 400, marginTop: "0.75rem" }}
              >
                {u.name}
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton id="ok-button" type="button" onClick={handleClose}>
          {t("ok")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default InactiveErrorPopup;
