import React, { useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import {
  FormContainer,
  FormGroupLabel,
  FormHeading,
} from "@/shared/components/form/FormElements";
import { useSelector } from "react-redux";
import {
  FormItem,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import { colorTextNew } from "../../../utils/palette";
import { useEffect } from "react";
import { postApi } from "../../../api/getUser";
import Error from "@/shared/components/form/Error";
import { useHistory, useLocation } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import {
  convertToDays,
  CustomInput,
  Desc,
  DueOnWrap,
  EditIcon,
  EmailPreviewText,
  NotifyMeWrap,
  ServicesPreviewHeading,
  SingleValueOption,
  Textarea,
  TimeSelect,
} from "../MaintenanceReminders/MaintenanceRemindersForm";
import FormHeader from "../../../shared/components/form/FormHeader";
import { ActionIconWrap } from "../../../shared/components/table/components/RightPanel";
import { ReactComponent as CrossSmallIcon } from "@/shared/img/cross-small.svg";
import { AddFormButton } from "../../../shared/components/TableElements";
import {
  convertToDisplayDate,
  convertToTextDate,
  getDateFnsLocale,
  getDistanceUnit,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../../shared/helpers";
import { formStyles } from "../../../shared/constants/styles";
import add from "date-fns/add";
import { updateExpenseTaskOptions } from "../../OtherExpenses/ExpensesForm";
import ExpenseTasksFormPopup from "../../OtherExpenses/ExpenseTasksFormPopup";
import getUnixTime from "date-fns/getUnixTime";
import sub from "date-fns/sub";
import { useDispatch } from "react-redux";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const RenewalsForm = ({ isHorizontal, isAboveError }) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const location = useLocation();

  const { distance } = useSelector((state) => state.units);

  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.filter(
    (v) =>
      (v.veh_active === 0 && v.secondary_status === 1) || v.veh_active === 1
  );
  const usersList = useSelector((state) => state.users.list);
  const allExpenseTasks = useSelector((state) => state.expenseTasks.list);

  const [expenseTaskOptions, setExpenseTaskOptions] = useState([]);

  //React hook form fields
  const vehicle = watch("vehicle");
  const renewalTask = watch("renewalTask");
  const serviceIntervalOdo = watch("serviceIntervalOdo");
  const serviceIntervalDuration = watch("serviceIntervalDuration");
  //const lastServiceOdo = watch("lastServiceOdo");
  //const lastServiceDate = watch("lastServiceDate");
  const notifyMeOdo = watch("notifyMeOdo");
  const notifyMeDuration = watch("notifyMeDuration");
  const notes = watch("notes");
  const durationSI = watch("durationSI");
  const durationNM = watch("durationNM");
  const dueOnOdo = watch("dueOnOdo");
  const dueOnDate = watch("dueOnDate");
  const userEmail = watch("userEmail");

  const [dueOn, setDueOn] = useState({ odo: "", date: "" });

  const [dueOnPreview, setDueOnPreview] = useState({ odo: [], date: [] });
  const [notifyMePreview, setNotifyMePreview] = useState({ odo: [], date: [] });

  const [lastServiced, setLastServiced] = useState("");
  const [lastServicedText, setLastServicedText] = useState({
    header: "",
    desc: "",
    data: "",
  });

  const [schedulePreviewText, setSchedulePreviewText] = useState(
    t("schedule_preview_empty")
  );

  const [showDueOn, setShowDueOn] = useState(false);

  const firstTimeEdit = useRef(false);

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const validateMaxNotifyMe = (v) => {
    //console.log(notifyMeDuration, v, durationNM);
    if (v) {
      return (
        convertToDays(+v, durationNM) <
        convertToDays(+serviceIntervalDuration, durationSI)
      );
    } else {
      return true;
    }
  };

  const [lastServiceOdoData, setLastServiceOdoData] = useState();
  const [lastServiceDateData, setLastServiceDateData] = useState();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //States for Create new service task
  const newTaskInfo = useRef("");
  const [newTaskId, setNewTaskId] = useState("");
  const [showAddTaskFormPopup, setShowAddTaskFormPopup] = useState(false);

  const [lastServiceCalc, setLastServiceCalc] = useState({
    odo: 0,
    date: new Date(),
  });

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //initial Effect
  useEffect(() => {
    if (type !== "edit") {
      setValue("durationSI", "days");
      setValue("durationNM", "days");
    }
  }, []);

  //Effect to set Service Task that is newly created
  useEffect(() => {
    if (newTaskId) {
      (async () => {
        const apiData = {
          querystring: "getallexpensetask",
          expense_veh_id: vehicle.vehicleId,
        };

        const expenseTasksRes = await updateExpenseTaskOptions(apiData);
        setExpenseTaskOptions(expenseTasksRes.filter((t) => +t.recurring));
      })();
    }
  }, [newTaskId]);

  useEffect(() => {
    if (newTaskId) {
      let newExpenseTask = expenseTaskOptions.find(
        (t) => t.expense_task_id === newTaskId
      );

      if (newExpenseTask) {
        setValue("renewalTask", newExpenseTask);
        setNewTaskId("");
      }
    }
  }, [expenseTaskOptions]);

  //Effect to set vehicle in case of edit
  useEffect(() => {
    if (type === "edit" && vehiclesList?.length > 0) {
      firstTimeEdit.current = true;
      console.log(rowData);
      const {
        veh_id,
        service_task_id,
        last_date_of_service,
        last_odo_of_service,
      } = rowData;
      const selectedVehicle = vehicles.find(
        (veh) => veh.vehicleId === rowData.veh_id
      );
      setValue("vehicle", selectedVehicle);

      const lastServiceDate = last_date_of_service
        ? new Date(last_date_of_service * 1000)
        : new Date();
      setLastServiceCalc({ odo: last_odo_of_service, date: lastServiceDate });
    }
  }, [vehiclesList]);

  //Update Service Task List with Change in Vehicle
  useEffect(() => {
    (async () => {
      if (vehicle) {
        const apiData = {
          querystring: "getallexpensetask",
          expense_veh_id: vehicle?.vehicleId,
        };
        // const res = await postApi(apiData, "commonNew");
        // if (res.success) {
        //   const { user_data } = res;
        //   setServiceTaskOptions(user_data);
        // }
        const expenseTasksRes = await updateExpenseTaskOptions(apiData);
        setExpenseTaskOptions(expenseTasksRes.filter((t) => +t.recurring));

        setValue("renewalTask", "");
        setValue("serviceIntervalOdo", "");
        setValue("serviceIntervalDuration", "");
        setValue("notifyMeDuration", "");
        setValue("notifyMeOdo", "");

        setDueOn({ odo: "", date: "" });

        if (type === "edit" && firstTimeEdit.current) {
          setValue(
            "renewalTask",
            expenseTasksRes.find(
              (t) => t.expense_task_id === rowData.expense_task_id
            )
          );
        }
      }
    })();
  }, [vehicle]);

  //Getting max_odo, lastservice...(form details) on change of vehicle or service task
  //let lastServiceOdoData = useRef(0);
  //let lastServiceDateData = useRef(new Date());

  useEffect(() => {
    (async () => {
      if (vehicle?.vehicleId && renewalTask?.expense_task_id) {
        const res = await postApi(
          {
            querystring: "get_veh_expensetask_lastexpensedate",
            selected_veh_ids: JSON.stringify([vehicle.vehicleId]),
            servicetaskid: renewalTask.expense_task_id,
          },
          "commonNew"
        );
        const data = res.lastservicedata[0];
        setLastServiceOdoData(data[0]);
        //Set lastservicecalc only in case of add
        if (type !== "edit") {
          setLastServiceCalc((prev) => ({ ...prev, odo: data[0] }));
        }
        if (data[0] > 0 && data[1] > 0) {
          setLastServiceDateData(new Date(data[1] * 1000));
          //Set lastservicecalc only in case of add
          if (type !== "edit") {
            setLastServiceCalc((prev) => ({
              ...prev,
              date: new Date(data[1] * 1000),
            }));
          }
          setLastServiced("last-serviced");
        } else if (data[0] > 0 && data[1] === 0) {
          setLastServiceDateData(new Date());
          if (type !== "edit") {
            setLastServiceCalc((prev) => ({
              ...prev,
              date: new Date(),
            }));
          }
          setLastServiced("max-odo");
        } else if (data[0] === 0 && data[1] === 0) {
          setLastServiceDateData(new Date());
          if (type !== "edit") {
            setLastServiceCalc((prev) => ({
              ...prev,
              date: new Date(),
            }));
          }
          setLastServiced("no-value");
        }

        if (type === "edit" && firstTimeEdit.current) {
          const {
            due_days,
            due_days_unit,
            due_odo,
            days_threshold,
            odo_threshold,
            emails,
            description,
          } = rowData;
          setValue("serviceIntervalOdo", due_odo ? +due_odo : "");

          setValue("serviceIntervalDuration", due_days ? +due_days : "");

          //Confirm whether to send in days or convert to required period.
          //const inspectionIntervalUnit = getPeriodFromUnits(due_days_unit);
          setValue("durationSI", "days");

          setValue("notifyMeOdo", odo_threshold ? +odo_threshold : "");

          setValue("notifyMeDuration", days_threshold ? +days_threshold : "");

          //Confirm whether to send in days or convert to required period.
          //const notifyMeUnit = getPeriodFromUnits(odo_threshold);
          setValue("durationNM", "days");

          //Emails
          if (emails) {
            const userEmails = emails.split(":::");
            if (userEmails.length > 0) {
              let temp = [];
              usersList.forEach((user) => {
                if (userEmails.includes(user.email)) {
                  temp.push(user);
                }
              });
              setValue("userEmail", temp);
            }
          }

          setValue("notes", description);

          //firstTimeEdit.current = false;
        }
      }
    })();
  }, [renewalTask]);

  //Change Last Serviced Data text based on change in lastServiced
  useEffect(() => {
    if (lastServiced === "last-serviced") {
      setLastServicedText({
        header: t("last_renewed_on"),
        desc: t("last_renewed_on_desc"),
        data: `${lastServiceOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )} (${convertToTextDate(lastServiceDateData)})
          `,
      });
    } else if (lastServiced === "max-odo") {
      setLastServicedText({
        header: t("current_odometer_reading"),
        desc: t("current_reading_desc_renewal"),
        data: `${lastServiceOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )}`,
      });
    } else if (lastServiced === "no-value") {
      setLastServicedText({
        header: "",
        desc: t("current_reading_desc_renewal"),
        data: "",
      });
    }
  }, [lastServiced, lastServiceOdoData, lastServiceDateData]);

  //Schedule Preview-Change in dueOnOdo and notifyMeOdo
  useEffect(() => {
    if (+dueOn.odo > 0) {
      setDueOnPreview((prev) => ({
        ...prev,
        odo: [
          Number(dueOn.odo).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 1)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 2)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 3)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 4)).toFixed(2),
        ],
      }));
      if (+notifyMeOdo > 0 && +notifyMeOdo < +serviceIntervalOdo) {
        let notifyByOdo = +dueOn.odo - +notifyMeOdo;

        setNotifyMePreview((prev) => ({
          ...prev,
          odo: [
            Number(notifyByOdo).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 1)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 2)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 3)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 4)).toFixed(2),
          ],
        }));
      } else {
        setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
      }
    } else {
      //setDueOdoArray([]);
      setDueOnPreview((prev) => ({ ...prev, odo: [] }));
      setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
    }
    //console.log(dueOdoArray, dueOn);
  }, [dueOn.odo, notifyMeOdo]);

  //Scheduled Preview-dueDate logic for dueDate/serviceIntervalDuration/durationUnit change
  useEffect(() => {
    //console.log(dueOn);
    let temp1 = new Date(dueOn.date);
    let temp2 = new Date(dueOn.date);
    let temp3 = new Date(dueOn.date);
    let temp4 = new Date(dueOn.date);
    let temp5 = new Date(dueOn.date);

    const noOfDays = convertToDays(serviceIntervalDuration, durationSI);

    temp1.setDate(temp1.getDate());
    temp2.setDate(temp2.getDate() + +noOfDays * 1);
    temp3.setDate(temp3.getDate() + +noOfDays * 2);
    temp4.setDate(temp4.getDate() + +noOfDays * 3);
    temp5.setDate(temp5.getDate() + +noOfDays * 4);

    setDueOnPreview((prev) => ({
      ...prev,
      date: [temp1, temp2, temp3, temp4, temp5],
    }));
  }, [dueOn.date, notifyMeDuration, durationNM]);

  useEffect(() => {
    const noOfDaysSI = convertToDays(+serviceIntervalDuration, durationSI);
    const noOfDaysNM = convertToDays(+notifyMeDuration, durationNM);
    if (
      notifyMeDuration &&
      dueOnPreview.date.length > 0 &&
      +notifyMeDuration > 0 &&
      noOfDaysNM < noOfDaysSI
    ) {
      const { date } = dueOnPreview;
      let n1 = new Date(date[0]);
      let n2 = new Date(date[1]);
      let n3 = new Date(date[2]);
      let n4 = new Date(date[3]);
      let n5 = new Date(date[4]);
      n1.setDate(n1.getDate() - noOfDaysNM);
      n2.setDate(n2.getDate() - noOfDaysNM);
      n3.setDate(n3.getDate() - noOfDaysNM);
      n4.setDate(n4.getDate() - noOfDaysNM);
      n5.setDate(n5.getDate() - noOfDaysNM);

      setNotifyMePreview((prev) => ({
        ...prev,
        date: [n1, n2, n3, n4, n5],
      }));
    } else {
      setNotifyMePreview((prev) => ({
        ...prev,
        date: [],
      }));
    }
  }, [dueOnPreview.date, notifyMeDuration, durationNM]);

  //Set Initial NotifyMe Values Based on Service Interval Values
  useEffect(() => {
    if (!serviceIntervalDuration && !serviceIntervalOdo) {
      setSchedulePreviewText(t("schedule_preview_empty"));
    } else if (serviceIntervalDuration > 0 || serviceIntervalOdo > 0) {
      setSchedulePreviewText(
        t("schedule_preview_filled_renewal", {
          last_serviced_text:
            lastServiced === "last-serviced"
              ? t("last_renewal_date_sm")
              : t("current_reading_sm"),
        })
      );
    }

    if (!firstTimeEdit.current && tag !== "free-user") {
      firstTimeEdit.current = false;

      if (serviceIntervalDuration) {
        const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
        if (noOfDays >= 90) {
          setValue("notifyMeDuration", 15);
          setValue("durationNM", "days");
        }
      } else {
        setValue("notifyMeDuration", "");
      }

      if (serviceIntervalOdo) {
        if (serviceIntervalOdo >= 5000) {
          setValue("notifyMeOdo", 250);
        }
      } else {
        setValue("notifyMeOdo", "");
      }
    }
  }, [serviceIntervalDuration, serviceIntervalOdo, durationSI]);

  //Effects to update inspectOn/dueOn(odo and date)
  useEffect(() => {
    if (serviceIntervalOdo >= 0) {
      let temp = +serviceIntervalOdo + +lastServiceCalc.odo;
      temp = temp.toFixed(2);
      setDueOn((prev) => ({
        ...prev,
        odo: temp,
      }));
    }
  }, [serviceIntervalOdo, lastServiceCalc]);

  useEffect(() => {
    const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
    if (lastServiceCalc.date) {
      const temp = add(new Date(lastServiceCalc.date), { days: noOfDays });
      setDueOn((prev) => ({ ...prev, date: temp }));
    }
  }, [serviceIntervalDuration, durationSI, lastServiceCalc]);

  // //Change Last Serviced Data text based on change in lastServiced
  // useEffect(() => {
  //   //console.log(lastServiced);
  //   //console.log(lastServiceOdoData);
  //   if (lastServiced === "last-serviced") {
  //     setLastServicedText({
  //       header: t("last_serviced_on"),
  //       desc: t("last_serviced_on_desc"),
  //       data: `${lastServiceOdoData} ${getDistanceUnit(
  //         distance
  //       )} (${convertToTextDate(lastServiceDateData)})
  //         `,
  //     });
  //   } else if (lastServiced === "max-odo") {
  //     setLastServicedText({
  //       header: t("current_odometer_reading"),
  //       desc: t("current_reading_desc"),
  //       data: `${lastServiceOdoData} ${getDistanceUnit(distance)}`,
  //     });
  //   } else if (lastServiced === "no-value") {
  //     setLastServicedText({
  //       header: "",
  //       desc: t("current_reading_desc"),
  //       data: "",
  //     });
  //   }
  // }, [lastServiced, lastServiceOdoData, lastServiceDateData]);

  //handleChange Functions

  // const convertToDays = (input, duration) => {
  //   switch (duration) {
  //     case "days":
  //       return input * 1;
  //     case "weeks":
  //       return input * 7;
  //     case "months":
  //       return input * 30;
  //     case "years":
  //       return input * 365;
  //   }
  // };

  // const handleSIDurationChange = () => {
  //   let temp = new Date(lastServiceDateData);
  //   //console.log(durationSI);
  //   const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
  //   //console.log(noOfDays);
  //   temp.setDate(temp.getDate() + noOfDays);
  //   //setValue("dueDate", temp);
  //   setDueOn((prev) => ({ ...prev, date: temp }));
  // };

  // const handleSIOdoChange = (e) => {
  //   if (e.code === "Minus") {
  //     e.preventDefault();
  //   }
  //   const dueOnOdo = +serviceIntervalOdo + +lastServiceOdoData;
  //   setDueOn((prev) => ({
  //     ...prev,
  //     odo: dueOnOdo,
  //   }));
  // };

  const handleEditIconClick = () => {
    clearErrors("dueOnOdo");
    setValue("dueOnDate", dueOn.date);
    setValue("dueOnOdo", dueOn.odo);
    setShowDueOn(true);
  };

  const handleSaveDueOn = () => {
    if (serviceIntervalOdo) {
      setError("dueOnOdo", {
        types: {
          required: t("required_err"),
          min: t("value_greater_than_interval_err_msg", {
            screenName: t("renewal_sm"),
          }),
        },
      });

      if (dueOnOdo && +dueOnOdo >= +serviceIntervalOdo) {
        clearErrors("dueOnOdo");
      }

      if (!errors.dueOnOdo) {
        let odo = dueOnOdo ? Number(dueOnOdo).toFixed(2) : 0;
        setDueOn({ date: dueOnDate, odo: odo });
        setShowDueOn(false);
      }
    } else {
      setDueOn((prev) => ({ ...prev, date: dueOnDate }));
      setShowDueOn(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      let reminderId =
        type === "edit"
          ? rowData?.reminder_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      const notifyMeDays = notifyMeDuration
        ? convertToDays(notifyMeDuration, durationNM)
        : 0;
      const serviceIntervalDays = serviceIntervalDuration
        ? convertToDays(serviceIntervalDuration, durationSI)
        : 0;

      let emailRecipients = "";
      if (userEmail) {
        userEmail.forEach((item) => {
          emailRecipients += item.email + ":::";
        });
      }
      emailRecipients = emailRecipients.slice(0, emailRecipients.length - 3);

      let lastOdoOfService = lastServiceCalc.odo ? lastServiceCalc.odo : 0;
      let lastDateOfService = lastServiceCalc.date
        ? getUnixTime(lastServiceCalc.date)
        : getUnixTime(new Date());

      if (serviceIntervalDuration) {
        const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
        lastDateOfService = getUnixTime(
          sub(new Date(dueOn.date), { days: noOfDays })
        );
      }
      if (serviceIntervalOdo) {
        lastOdoOfService = +dueOn.odo - +serviceIntervalOdo;
      }

      let dueOnOdoVal = serviceIntervalOdo ? Number(dueOn.odo) : 0;
      let dueOnDateVal = serviceIntervalDuration ? getUnixTime(dueOn.date) : 0;

      const apiData = {
        source: "web",
        //veh_list_array: JSON.stringify([vehicle.vehicleId]),
        veh_list_array: vehicle.vehicleId,
        due_on_odo: dueOnOdoVal,
        due_on_date: dueOnDateVal,
        timethreshold: +notifyMeDays,
        odothreshold: +notifyMeOdo ? +notifyMeOdo : 0,
        querystring: "add_reminder",
        emails: emailRecipients,
        emails_sent: 0,
        reminder_id: reminderId,
        service_task_id: null,
        expense_task_id: renewalTask.expense_task_id,
        last_date_of_service: lastDateOfService,
        last_odo_of_service: +lastOdoOfService,
        due_days: +serviceIntervalDays,
        due_odo: +serviceIntervalOdo ? +serviceIntervalOdo : 0,
        operation: type === "edit" ? "update" : "create",
        days_threshold: +notifyMeDays,
        odo_threshold: +notifyMeOdo ? +notifyMeOdo : 0,
        desc: notes ? notes : "",
        type: 1,
        action: type === "edit" ? 2 : 1,
        sync_version: "v3",
      };

      // (async () => {
      setSaveBtnLoading(true);
      const response = await postApi(apiData, "commonNew");
      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("renewal", { count: 1 }) })
          : t("record_added_msg", { recordName: t("renewal", { count: 1 }) });
      if (response.success === 1) {
        dispatch(addMsg(snackbarMsg));
        setSaveBtnLoading(false);
        history.push("/reminders/renewals", { fromCancel: false });
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleCreate = (value) => {
    newTaskInfo.current = { taskName: value, vehicleId: vehicle.vehicleId };
    setShowAddTaskFormPopup(true);
  };

  const handleCancel = () => {
    reset({
      vehicle: "",
      renewalTask: "",
      serviceIntervalOdo: "",
      serviceIntervalDuration,
      notifyMeOdo: "",
      notifyMeDuration: "",
      durationSI: "days",
      durationNM: "days",
      dueOnOdo: "",
      dueOnDate: "",
      userEmail: "",
      notes: "",
    });

    history.push("/reminders/renewals", { fromCancel: true });
  };

  const inputStyles = {
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    width: "100%",
    outline: "none",
  };

  return (
    <>
      <ExpenseTasksFormPopup
        showPopup={showAddTaskFormPopup}
        setShowPopup={setShowAddTaskFormPopup}
        newTaskInfo={newTaskInfo.current}
        newTaskId={newTaskId}
        setNewTaskId={setNewTaskId}
        from={"add-renewal"}
      />
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer
        horizontal={false}
        onSubmit={handleSubmit(onSubmit)}
        //style={{ height: "80vh" }}
      >
        <FormHeader
          title={type && type === "edit" ? t("edit_renewal") : t("add_renewal")}
          //saveButtonText={"Save Reminder"}
          handleCancel={handleCancel}
          disableSave={!renewalTask}
          isSaveLoading={saveBtnLoading}
        />
        {/* </CardBody> */}
        {/* </Card> */}

        <Container>
          <Row>
            <Col
              md={vehicle && renewalTask ? 8 : 12}
              style={{ paddingRight: vehicle && renewalTask ? "0" : "12px" }}
            >
              <Card>
                <CardBody
                  style={{
                    overflowY: "scroll",
                    height: `${window.innerHeight - 150}px`,
                    padding: 0,
                    overflowX: "hidden",
                  }}
                >
                  <Row className="px-3">
                    <Col md={vehicle && renewalTask ? 6 : 4}>
                      <div>
                        <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                        <Controller
                          name="vehicle"
                          control={control}
                          //errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={vehicles}
                              //value={vehicles.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              onCreateOption={handleCreate}
                              placeholder={t("vehicle_placeholder")}
                              styles={formStyles}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col md={vehicle && renewalTask ? 6 : 4}>
                      <div>
                        <FormGroupLabel>{t("expense_task")}</FormGroupLabel>
                        <Controller
                          name="renewalTask"
                          control={control}
                          errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <CreatableSelect
                              options={expenseTaskOptions}
                              //value={serviceTasks.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              onCreateOption={handleCreate}
                              placeholder={t("expense_task_placeholder")}
                              styles={formStyles}
                              isDisabled={vehicle ? false : true}
                              components={{ Option: SingleValueOption }}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.renewalTask && (
                        <Error error={errors.renewalTask.message} />
                      )}
                    </Col>
                  </Row>
                  {vehicle && renewalTask && (
                    <>
                      <div className="px-3 pt-3 d-flex flex-column gap-1">
                        {lastServiced !== "no-value" && (
                          <>
                            <p style={{ fontSize: "12px", fontWeight: 300 }}>
                              {lastServicedText.header}
                            </p>
                            <p style={{ fontSize: "14px", fontWeight: 500 }}>
                              {lastServicedText.data}
                            </p>
                          </>
                        )}
                        <Desc>{lastServicedText.desc}</Desc>
                      </div>
                      <hr />
                      <Row className="px-3">
                        <h5>{t("renewal_interval")}</h5>
                        <FormGroupLabel>{t("renew_every")}</FormGroupLabel>
                        <Col md={4}>
                          <FormItem>
                            <FormField
                              name="serviceIntervalOdo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              //errors={errors}
                              rules={{
                                required:
                                  +serviceIntervalDuration ||
                                  +serviceIntervalOdo
                                    ? false
                                    : t("odo_or_duration_err"),
                                min: {
                                  value: 0,
                                  message: t("negative_value_err_msg"),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("odometer")}
                              style={inputStyles}
                              // onKeyUp={() =>
                              //   setValue(
                              //     "dueOdo",
                              //     +lastServiceOdo + +serviceIntervalOdo
                              //   )
                              // }
                              //onKeyUp={handleSIOdoChange}
                            />
                            <UnitSpan>
                              {getDistanceUnit(
                                vehicle?.primary_meter === "Hours"
                                  ? "Hours"
                                  : distance
                              )}
                            </UnitSpan>
                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>
                          {/* {errors?.serviceIntervalOdo && (
                            <Error error={errors.serviceIntervalOdo.message} />
                          )} */}
                        </Col>
                        <Col
                          md={1}
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          <span>{t("or")}</span>
                        </Col>
                        <Col md={4}>
                          <FormItem
                          // onFocus={() => setSIDurationFocus(true)}
                          // onBlur={() => setSIDurationFocus(false)}
                          // focus={SIDurationFocus}
                          >
                            <FormField
                              name="serviceIntervalDuration"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              errors={errors}
                              rules={{
                                required:
                                  +serviceIntervalOdo ||
                                  +serviceIntervalDuration
                                    ? false
                                    : t("odo_or_duration_err"),
                                min: {
                                  value: 0,
                                  message: t("negative_value_err_msg"),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("duration")}
                              style={inputStyles}
                              //onKeyUp={handleSIDurationChange}
                            />
                            <TimeSelect
                              {...register("durationSI")}
                              defaultValue="days"
                            >
                              <option value="days">{t("days")}</option>
                              <option value="weeks">{t("weeks")}</option>
                              <option value="months">{t("months")}</option>
                              <option value="years">{t("years")}</option>
                            </TimeSelect>
                          </FormItem>
                          {/* {errors?.serviceIntervalDuration && (
                            <Error
                              error={errors.serviceIntervalDuration.message}
                            />
                          )} */}
                        </Col>
                        <Col
                          md={3}
                          className="d-flex justify-content-start align-items-center p-0"
                        >
                          <span>{t("whichever_comes_first")}</span>
                        </Col>
                      </Row>
                      {(errors.serviceIntervalOdo ||
                        errors.serviceIntervalDuration) && (
                        <Row className="px-3">
                          <Col md={4}>
                            {errors?.serviceIntervalOdo && (
                              <Error
                                error={errors.serviceIntervalOdo.message}
                              />
                            )}
                          </Col>
                          <Col md={1}></Col>
                          <Col md={4}>
                            {errors?.serviceIntervalDuration && (
                              <Error
                                error={errors.serviceIntervalDuration.message}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                      <hr />
                      <Row className="px-3">
                        <div className="d-flex align-items-center">
                          <h5>{t("notification_settings")}</h5>
                          {tag === "free-user" && (
                            <div
                              //className="mb-1"
                              style={{ marginBottom: "0.1rem" }}
                            >
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                                handleClick={() => setShowSubscribePopup(true)}
                              />
                            </div>
                          )}
                        </div>
                        <Desc
                          style={{ paddingTop: "4px" }}
                          className="d-flex gap-1"
                        >
                          <span>{t("notifications_email_msg")}</span>
                          {tag === "free-user" && (
                            <span>{`(${t("paid_feature_str_sm")})`}</span>
                          )}
                        </Desc>
                        <FormGroupLabel>{t("notify_me")}</FormGroupLabel>
                        <Col>
                          <FormItem
                            disabled={
                              !serviceIntervalOdo || tag === "free-user"
                            }
                          >
                            <FormField
                              name="notifyMeOdo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              disabled={
                                !serviceIntervalOdo || tag === "free-user"
                              }
                              //errors={errors}
                              rules={{
                                min: {
                                  value: 0,
                                  message: t("negative_value_err_msg"),
                                },
                                max: {
                                  value: +serviceIntervalOdo - 1,
                                  message: t("greater_than_interval_err", {
                                    type: t("renewal_sm"),
                                  }),
                                },
                              }}
                              defaultValue=""
                              //isAboveError={isAboveError}
                              placeholder={t("odometer")}
                              style={inputStyles}
                            />
                            <UnitSpan>
                              {getDistanceUnit(
                                vehicle?.primary_meter === "Hours"
                                  ? "Hours"
                                  : distance
                              )}
                            </UnitSpan>
                          </FormItem>
                          {/* {errors?.notifyMeOdo && (
                            <Error error={errors.notifyMeOdo.message} />
                          )} */}
                        </Col>
                        <Col
                          md={1}
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          <span>{t("or")}</span>
                        </Col>
                        <Col>
                          <FormItem
                            disabled={
                              !serviceIntervalDuration || tag === "free-user"
                            }
                          >
                            <FormField
                              name="notifyMeDuration"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              disabled={
                                !serviceIntervalDuration || tag === "free-user"
                              }
                              errors={errors}
                              rules={{
                                min: {
                                  value: 0,
                                  message: t("negative_value_err_msg"),
                                },
                                // max: {
                                //   value: getMaxNotifyMeDuration(),
                                //   message:
                                //     "Value cannot be greater than service interval.",
                                // },
                                validate: {
                                  maxValue: (v) =>
                                    validateMaxNotifyMe(v) ||
                                    t("greater_than_interval_err", {
                                      type: t("renewal_sm"),
                                    }),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("duration")}
                              style={inputStyles}
                            />
                            <TimeSelect
                              {...register("durationNM")}
                              defaultValue="days"
                              disabled={
                                !serviceIntervalDuration || tag === "free-user"
                              }
                              onClick={() => {
                                setError("notifyMeDuration", {
                                  type: "maxValue",
                                });
                              }}
                            >
                              <option value="days">{t("days")}</option>
                              <option value="weeks">{t("weeks")}</option>
                              <option value="months">{t("months")}</option>
                              <option value="years">{t("years")}</option>
                            </TimeSelect>
                          </FormItem>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex justify-content-start align-items-center p-0"
                        >
                          <span>{t("before_renewal_schedule")}</span>
                        </Col>
                      </Row>
                      {(errors.notifyMeOdo || errors.notifyMeDuration) && (
                        <Row className="px-3">
                          <Col md={4}>
                            {errors?.notifyMeOdo && (
                              <Error error={errors.notifyMeOdo.message} />
                            )}
                          </Col>
                          <Col md={1}></Col>
                          <Col md={4}>
                            {errors?.notifyMeDuration && (
                              <Error error={errors.notifyMeDuration.message} />
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("send_notification_to")}
                          {/* <Tooltip text={t("premium_feature")}>
                            <DollarIcon className="fi fi-ss-usd-circle"></DollarIcon>
                          </Tooltip> */}
                        </FormGroupLabel>
                        <Col>
                          <Controller
                            name="userEmail"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={usersList}
                                value={value}
                                onChange={onChange}
                                placeholder={t("noti_recipients")}
                                styles={formStyles}
                                isMulti
                                closeMenuOnSelect={false}
                                //hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                isDisabled={tag === "free-user"}
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </Col>
                      </Row>
                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("notification_email_preview")}
                        </FormGroupLabel>
                        {(+serviceIntervalOdo > 0 ||
                          +serviceIntervalDuration > 0) &&
                          tag !== "free-user" && (
                            <EmailPreviewText>
                              {/* {t("email_reminder_msg")}{" "}
                              <strong>{vehicle.label}</strong>{" "}
                              {t("is_due_for_sm")}{" "}
                              <strong>{renewalTask.label}</strong> */}
                              <Trans
                                i18nKey="email_reminder_msg"
                                t={t}
                                values={{
                                  vehicleName: vehicle.label,
                                  taskName: renewalTask.label,
                                }}
                              />
                              {notifyMeOdo > 0 &&
                                notifyMePreview.odo.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>
                                      {`${notifyMeOdo} ${getDistanceUnit(
                                        vehicle?.primary_meter === "Hours"
                                          ? "Hours"
                                          : distance
                                      )}`}
                                    </strong>
                                  </>
                                )}
                              {notifyMeOdo > 0 &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                !!serviceIntervalDuration &&
                                ` ${t("or")} `}
                              {notifyMeDuration > 0 &&
                                notifyMePreview.date.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>
                                      {convertToDays(
                                        notifyMeDuration,
                                        durationNM
                                      )}{" "}
                                      {t("days_sm", {
                                        count: convertToDays(
                                          notifyMeDuration,
                                          durationNM
                                        ),
                                      })}
                                    </strong>
                                  </>
                                )}
                              {notifyMeOdo > 0 &&
                                notifyMeDuration > 0 &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                `, ${t("whichever_comes_first")}`}
                              .{" "}
                              {lastServiced === "last-serviced" && (
                                <>
                                  {/* <span>{t("last_renewal_done_on")}</span>
                                  <strong>
                                    {" "}
                                    {convertToDisplayDate(lastServiceDateData)}
                                  </strong>
                                  {`. `} */}
                                  <Trans
                                    i18nKey="last_renewal_done_on"
                                    t={t}
                                    values={{
                                      lastExpenseDate:
                                        convertToDisplayDate(
                                          lastServiceDateData
                                        ),
                                    }}
                                  />{" "}
                                </>
                              )}
                              {t("email_log_msg_renewal")}
                            </EmailPreviewText>
                          )}
                        <Col>
                          <Controller
                            name="notes"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Textarea
                                rows={2}
                                id="notes"
                                name="notes"
                                value={value}
                                onChange={onChange}
                                placeholder={t("email_preview_placeholder")}
                                disabled={tag === "free-user"}
                              ></Textarea>
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* </FormContainer> */}
                </CardBody>
              </Card>
            </Col>

            {vehicle && renewalTask && (
              <Col md={4}>
                <Card>
                  <CardBody>
                    <div>
                      <FormHeading>{t("schedule_preview")}</FormHeading>
                      <Desc>{schedulePreviewText}</Desc>
                      {((serviceIntervalOdo && +serviceIntervalOdo > 0) ||
                        (serviceIntervalDuration &&
                          +serviceIntervalDuration > 0)) && (
                        <>
                          <ServicesPreviewHeading>
                            {t("next_5_renewals_due_on")}
                            {/* <span style={{ fontSize: "12px" }}>
                              {" "}
                              ({t("whichever_comes_first")})
                            </span> */}
                          </ServicesPreviewHeading>
                          <hr style={{ margin: "5px 0" }} />

                          {[0, 1, 2, 3, 4].map((num) => {
                            return (
                              <React.Fragment key={num}>
                                <DueOnWrap>
                                  {+serviceIntervalOdo > 0 && dueOn.odo && (
                                    <span>{`${
                                      dueOnPreview.odo[num]
                                    } ${getDistanceUnit(
                                      vehicle?.primary_meter === "Hours"
                                        ? "Hours"
                                        : distance
                                    )}`}</span>
                                  )}
                                  {+serviceIntervalDuration > 0 &&
                                    +serviceIntervalOdo > 0 && (
                                      <span> {t("or")} </span>
                                    )}
                                  <span>
                                    {+serviceIntervalDuration > 0 && dueOn.date
                                      ? convertToTextDate(
                                          dueOnPreview.date[num]
                                        )
                                      : ""}
                                  </span>
                                  {num === 0 && (
                                    <EditIcon
                                      className="fi fi-rr-pencil"
                                      onClick={handleEditIconClick}
                                    ></EditIcon>
                                  )}
                                </DueOnWrap>
                                {(notifyMePreview.date.length > 0 ||
                                  notifyMePreview.odo.length > 0) && (
                                  <NotifyMeWrap>
                                    <i className="fi fi-rr-clock"></i>
                                    <span>{t("notify_by")} </span>

                                    {!!notifyMeOdo &&
                                      +notifyMeOdo > 0 &&
                                      +notifyMeOdo < +serviceIntervalOdo && (
                                        <span>
                                          {`${
                                            notifyMePreview.odo[num]
                                          } ${getDistanceUnit(
                                            vehicle?.primary_meter === "Hours"
                                              ? "Hours"
                                              : distance
                                          )}`}
                                        </span>
                                      )}

                                    {+notifyMeDuration > 0 &&
                                      +notifyMeOdo > 0 &&
                                      +notifyMeOdo < +serviceIntervalOdo &&
                                      convertToDays(
                                        notifyMeDuration,
                                        durationNM
                                      ) <
                                        convertToDays(
                                          serviceIntervalDuration,
                                          durationSI
                                        ) && <span> {t("or")} </span>}
                                    {notifyMeDuration &&
                                      notifyMePreview.date.length > 0 && (
                                        <span>
                                          {convertToTextDate(
                                            notifyMePreview.date[num]
                                          )}
                                        </span>
                                      )}
                                  </NotifyMeWrap>
                                )}
                                <hr style={{ margin: "5px 0" }} />
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Modal
            show={showDueOn}
            onHide={() => setShowDueOn(!showDueOn)}
            centered
          >
            <Modal.Header>
              <p>{t("next_renewal_due_on")}</p>
              <ActionIconWrap onClick={() => setShowDueOn(!showDueOn)}>
                <CrossSmallIcon />
              </ActionIconWrap>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {dueOn.odo && serviceIntervalOdo && (
                  <Col md={5}>
                    {/* <FormItem>
                      <input
                        type="number"
                        placeholder={t("odometer")}
                        value={dueOnOdo}
                        onChange={(e) => setDueOnOdo(e.target.value)}
                        style={{
                          border: "none",
                          outline: "none",
                          margin: "0 0 0 8px",
                          width: "100%",
                        }}
                      />
                      <UnitSpan>Km</UnitSpan>
                    </FormItem> */}
                    <FormItem
                    //onFocus={() => setDueOnOdoFocus(true)}
                    //onBlur={() => setDueOnOdoFocus(false)}
                    //focus={dueOnOdoFocus}
                    >
                      <FormField
                        name="dueOnOdo"
                        control={control}
                        component="input"
                        type="number"
                        onKeyDown={handleNegative}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        errors={errors}
                        //rules={{ required: "This is required field" }}
                        rules={{
                          required: "required_err",
                          // min: {
                          //   value: +dueOn.odo,
                          //   message: t("negative_value_err_msg"),
                          // },
                        }}
                        defaultValue={dueOn.odo}
                        isAboveError={isAboveError}
                        placeholder={t("odometer")}
                        style={{ ...inputStyles, padding: "5px 10px" }}
                      />
                      <UnitSpan>
                        {getDistanceUnit(
                          vehicle?.primary_meter === "Hours"
                            ? "Hours"
                            : distance
                        )}
                      </UnitSpan>
                    </FormItem>
                    {/* {errors?.dueOnOdo && (
                      <Error error={errors.dueOnOdo.message} />
                    )} */}
                  </Col>
                )}
                {dueOn.odo &&
                  serviceIntervalOdo &&
                  serviceIntervalDuration &&
                  dueOn.date && (
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span>{t("or")}</span>
                    </Col>
                  )}
                {dueOn.date && serviceIntervalDuration && (
                  <Col md={5}>
                    {/* <DatePicker
                      selected={dueOnDate}
                      onChange={(e) => setDueOnDate(e)}
                      customInput={<CustomInput />}
                      minDate={new Date(dueOn.date)}
                    /> */}
                    <Controller
                      name="dueOnDate"
                      control={control}
                      defaultValue={dueOn.date}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          selected={value}
                          onChange={onChange}
                          customInput={<CustomInput />}
                          placeholderText={t("due_on_date")}
                          id="datepicker"
                          locale={getDateFnsLocale()}
                          dateFormat={"P"}
                          showMonthDropdown
                          showYearDropdown
                          //minDate={new Date(dueOn.date)}
                        />
                      )}
                      //rules={{ required: true }}
                    />
                  </Col>
                )}
              </Row>
              {errors?.dueOnOdo && errors?.dueOnOdo?.types && (
                <Error
                  error={
                    dueOnOdo
                      ? errors.dueOnOdo.types.min
                      : errors.dueOnOdo.types.required
                  }
                />
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <AddFormButton
                type="button"
                id="save-button"
                onClick={handleSaveDueOn}
              >
                {t("save")}
              </AddFormButton>
            </Modal.Footer>
          </Modal>
        </Container>
      </FormContainer>
    </>
  );
};

RenewalsForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

RenewalsForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default RenewalsForm;

//export const Textarea = styled.textarea``;
