import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    updateGroupsList: (state, action) => {
      state.list = action.payload;
    },
    clearGroups: (state, action) => {
      state.list = [];
    },
  },
});

export const { updateGroupsList, clearGroups } = groupsSlice.actions;
export default groupsSlice.reducer;
