import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";

import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import styled from "styled-components";
import TotalStatsTab from "./TotalStatsTab";
import { useSelector } from "react-redux";
import { getDistanceUnit } from "../../shared/helpers";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { NATEXT } from "../../shared/constants";

const ChartsComponent = ({
  graphData,
  setGraphData,
  graphQuery,
  statsQuery,
  setGraphQuery,
  setStatsQuery,
  verticalActiveKey,
  setVerticalActiveKey,
  statsValue,
  setStatsValue,
}) => {
  const { t } = useTranslation("common");

  const [activeKey, setActiveKey] = useState(1);

  const { distance, currency, volume } = useSelector((state) => state.units);
  const [distanceUnit, setDistanceUnit] = useState(getDistanceUnit(distance));

  const horizontalTabsData = [
    {
      id: 1,
      tabKey: 1,
      label: t("total_stats"),
    },
    {
      id: 2,
      tabKey: 2,
      label: t("fuel_stats"),
    },
    {
      id: 3,
      tabKey: 3,
      label: t("service_stats"),
    },
    {
      id: 4,
      tabKey: 4,
      label: t("other_expense_stats"),
    },
    {
      id: 5,
      tabKey: 5,
      label: t("trip_stats"),
    },
  ];

  const [verticalTabsData, setVerticalTabsData] = useState([
    {
      label: t("tc_tv"),
      tabKey: 1,
      graphQueryString: "gettotalcostgraph",
      statsQueryString: "get_total_cost_custom",
      //fetchStatsData: 1,
    },
    {
      label: `${t("tc_tv")}/${getDistanceUnit(distance)}`,
      tabKey: 2,
      graphQueryString: "totalcostperdistance",
      statsQueryString: "",
    },
    {
      label: `${t("tc_tv")}/${t("day")}`,
      tabKey: 3,
      graphQueryString: "totalcostperday",
      statsQueryString: "",
    },
  ]);

  //Set setVerticalTabsData when distance changes
  useEffect(() => {
    setVerticalTabsData([
      {
        label: t("tc_tv"),
        tabKey: 1,
        graphQueryString: "gettotalcostgraph",
        statsQueryString: "get_total_cost_custom",
        //fetchStatsData: 1,
      },
      {
        label: `${t("tc_tv")}/${getDistanceUnit(distance)}`,
        tabKey: 2,
        graphQueryString: "totalcostperdistance",
        statsQueryString: "total_cost_per_dist",
      },
      {
        label: `${t("tc_tv")}/${t("day")}`,
        tabKey: 3,
        graphQueryString: "totalcostperday",
        statsQueryString: "total_cost_per_day",
      },
    ]);
  }, [distance]);

  const handleHorTabSelect = (e) => {
    setStatsValue(NATEXT);
    setActiveKey(e);
    let verticalTabOptions = [];
    if (e == 1) {
      verticalTabOptions = [
        {
          label: t("tc_tv"),
          tabKey: 1,
          graphQueryString: "gettotalcostgraph",
          statsQueryString: "get_total_cost_custom",
          //fetchStatsData: 1,
        },
        {
          label: `${t("tc_tv")}/${getDistanceUnit(distance)}`,
          tabKey: 2,
          graphQueryString: "totalcostperdistance",
          statsQueryString: "total_cost_per_dist",
        },
        {
          label: `${t("tc_tv")}/${t("day")}`,
          tabKey: 3,
          graphQueryString: "totalcostperday",
          statsQueryString: "total_cost_per_day",
        },
      ];
      // setVerticalTabsData([
      //   {
      //     label: "Total Cost",
      //     tabKey: 1,
      //     graphQueryString: "gettotalcostgraph",
      //     statsQueryString: "get_totalcost",
      //     //fetchStatsData: 1,
      //   },
      //   {
      //     label: `Total Cost/${distanceUnit}`,
      //     tabKey: 2,
      //     graphQueryString: "totalcostperdistance",
      //     statsQueryString: "",
      //   },
      //   {
      //     label: `Total Cost/Day`,
      //     tabKey: 3,
      //     graphQueryString: "totalcostperday",
      //     statsQueryString: "",
      //   },
      // ]);
    }

    if (e == 2) {
      verticalTabOptions = [
        {
          label: t("fuel_eff"),
          tabKey: 1,
          graphQueryString: "getEffForGraph",
          statsQueryString: "getfueleff",
        },
        {
          label: `${t("fuel_cost_per")}${getDistanceUnit(distance)}`,
          tabKey: 2,
          graphQueryString: "FuelCostPerDist",
          statsQueryString: "fillup_cost_per_dist",
        },
        {
          label: `${t("fuel_cost_per")}${t("day")}`,
          tabKey: 3,
          graphQueryString: "FuelCostPerDay",
          statsQueryString: "fillups_cost_per_day",
        },
        {
          label: t("total_fuel_cost"),
          tabKey: 4,
          graphQueryString: "",
          statsQueryString: "get_total_fillup_cost_custom",
        },
        {
          label: t("cost_per_fillup"),
          tabKey: 5,
          graphQueryString: "costperfillup",
          statsQueryString: "costperfillup",
        },
        {
          label: `${t("avg_price_per")}${volume}`,
          tabKey: 6,
          graphQueryString: "PricePerVolUnit",
          statsQueryString: "PricePerVolUnit",
        },
        {
          label: t("total_fillups"),
          tabKey: 7,
          graphQueryString: "",
          statsQueryString: "totalfillups",
        },
        {
          label: t("qty_per_fillup"),
          tabKey: 8,
          graphQueryString: "QtyPerFillup",
          statsQueryString: "QtyPerFillup",
        },
        {
          label: t("dist_bw_fillups"),
          tabKey: 9,
          graphQueryString: "DistBetFillup",
          statsQueryString: "DistBetFillup",
        },
      ];
    }

    if (e == 3) {
      verticalTabOptions = [
        {
          label: t("total_service_cost"),
          tabKey: 1,
          graphQueryString: "",
          statsQueryString: "get_total_service_cost_custom",
        },
        {
          label: `${t("service_cost_per")}${getDistanceUnit(distance)}`,
          tabKey: 2,
          graphQueryString: "servicecostperdistance",
          statsQueryString: "service_cost_per_dist",
        },
        {
          label: `${t("service_cost_per")}${t("day")}`,
          tabKey: 3,
          graphQueryString: "servicecostperday",
          statsQueryString: "service_cost_per_day",
        },
        {
          label: t("total_service"),
          tabKey: 4,
          graphQueryString: "",
          statsQueryString: "totalservices",
        },
      ];
    }

    if (e == 4) {
      verticalTabOptions = [
        {
          label: t("total_other_expense_cost"),
          tabKey: 1,
          graphQueryString: "",
          statsQueryString: "get_total_expense_cost_custom",
        },
        {
          label: `${t("other_expense_cost_per")}${getDistanceUnit(distance)}`,
          tabKey: 2,
          graphQueryString: "expensecostperdistance",
          statsQueryString: "expense_cost_per_dist",
        },
        {
          label: `${t("other_expense_cost_per")}${t("day")}`,
          tabKey: 3,
          graphQueryString: "expensecostperday",
          statsQueryString: "expense_cost_per_day",
        },
        {
          label: t("total_other_expenses"),
          tabKey: 4,
          graphQueryString: "",
          statsQueryString: "totalexpenses",
        },
      ];
    }

    if (e == 5) {
      verticalTabOptions = [
        {
          label: t("total_trips"),
          tabKey: 1,
          graphQueryString: "",
          statsQueryString: "totaltrips",
        },
        {
          label: t("total_trip_distance"),
          tabKey: 2,
          graphQueryString: "totaltripdistance",
          statsQueryString: "totaltripdistance",
        },
        {
          label: t("avg_fuel_cost_per_trip"),
          tabKey: 3,
          graphQueryString: "avgfuelcostpertrip",
          statsQueryString: "avgfuelcostpertrip",
        },
        {
          label: t("avg_exp_per_trip"),
          tabKey: 4,
          graphQueryString: "avgexpensepertrip",
          statsQueryString: "avgexpensepertrip",
        },
        {
          label: t("avg_income_per_trip"),
          tabKey: 5,
          graphQueryString: "avgincomepertrip",
          statsQueryString: "avgincomepertrip",
        },
        {
          label: t("avg_speed_per_trip"),
          tabKey: 6,
          graphQueryString: "",
          statsQueryString: "avgspeedpertrip",
        },
      ];
    }

    setVerticalTabsData(verticalTabOptions);
    setGraphData(null);
    setGraphQuery(verticalTabOptions[0].graphQueryString);
    setStatsQuery(verticalTabOptions[0].statsQueryString);
    setVerticalActiveKey(1);
  };

  const Tabs = () => {
    return (
      <Tab.Container
        activeKey={activeKey}
        onSelect={(e) => handleHorTabSelect(e)}
      >
        <TabsWrap>
          <Nav
            className="nav-tabs"
            style={{
              flexWrap: "nowrap",
              height: "auto", //window.innerHeight - 400 + "px",
            }}
          >
            {/* <HorNavItem id="total-stats-tab" active={activeKey == "1"}>
              <NavLink eventKey="1" name="user-details-anchor">
                Total Stats
              </NavLink>
            </HorNavItem>
            <HorNavItem id="documents-tab" active={activeKey == "2"}>
              <NavLink eventKey="2" name="documents-anchor">
                Fuel Stats
              </NavLink>
            </HorNavItem>
            <HorNavItem id="vehicles-assigned-tab" active={activeKey == "3"}>
              <NavLink eventKey="3" name="vehicles-assigned-anchor">
                Service Stats
              </NavLink>
            </HorNavItem>
            <HorNavItem id="vehicles-assigned-tab" active={activeKey == "4"}>
              <NavLink eventKey="4" name="vehicles-assigned-anchor">
                Other Exp Stats
              </NavLink>
            </HorNavItem>
            <HorNavItem id="vehicles-assigned-tab" active={activeKey == "5"}>
              <NavLink eventKey="5" name="vehicles-assigned-anchor">
                Trip Stats
              </NavLink>
            </HorNavItem> */}
            {horizontalTabsData.map((tab, i) => (
              <HorNavItem
                key={tab.id}
                id={`nav-item-${tab.id}`}
                $active={activeKey == tab.tabKey}
              >
                <NavLink eventKey={tab.tabKey} id={`nav-link-${tab.id}`}>
                  {tab.label}
                </NavLink>
              </HorNavItem>
            ))}
          </Nav>
          <Tab.Content>
            {horizontalTabsData.map((tab) => (
              <Tab.Pane
                id={`tab-pane-${tab.id}`}
                eventKey={tab.tabKey}
                active={activeKey == tab.tabKey}
                key={tab.id}
                // style={{ width: "-webkit-fill-available" }}
              >
                <TotalStatsTab
                  data={verticalTabsData}
                  graphData={graphData}
                  setGraphData={setGraphData}
                  graphQuery={graphQuery}
                  setGraphQuery={setGraphQuery}
                  setStatsQuery={setStatsQuery}
                  verticalActiveKey={verticalActiveKey}
                  setVerticalActiveKey={setVerticalActiveKey}
                  statsValue={statsValue}
                  setStatsValue={setStatsValue}
                />
              </Tab.Pane>
            ))}
            {/* <Tab.Pane
              eventKey="1"
              style={
                {
                  //height: `${window.innerHeight - 200}px`,
                  //overflow: "auto",
                  //width: "-webkit-fill-available",
                }
              }
            >
              <TotalStatsTab
                data={verticalTabsData}
                //setData={setTotalStatsData}
                graphData={graphData}
                setGraphData={setGraphData}
                graphQuery={graphQuery}
                setGraphQuery={setGraphQuery}
                setStatsQuery={setStatsQuery}
                verticalActiveKey={verticalActiveKey}
                setVerticalActiveKey={setVerticalActiveKey}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="2"></Tab.Pane>
            <Tab.Pane eventKey="3"></Tab.Pane> */}
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>
    );
  };

  return (
    <>
      {/* <Row className="m-0 p-0"> */}
      {/* <Col className="m-0 p-0"> */}
      <TabsWrap
        style={{
          border: "1px solid lightgray",
          //borderTop: "none",
        }}
      >
        <Tabs />
      </TabsWrap>
      {/* </Col> */}
      {/* <Col className="m-0 p-0">
          <div>Graphs & Charts</div>
        </Col> */}
      {/* </Row> */}
    </>
  );
};

export default ChartsComponent;

//styles

const HorNavItem = styled(NavItem)`
  width: -webkit-fill-available;
  ${(p) => !p.$active && `background: aliceblue`}
  ${(p) => p.$active && `font-weight: 600`}
`;
