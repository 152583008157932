import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Card, CardBody } from "@/shared/components/Card";
import {
  FormContainer,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CheckBoxInput,
  Label,
  FormItem,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import { colorLightGray, colorTextNew } from "../../../utils/palette";
import { useEffect } from "react";
import Error from "@/shared/components/form/Error";
import { useHistory, useLocation } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import { postApi } from "../../../api/getUser";
import FormHeader from "../../../shared/components/form/FormHeader";
import { Desc } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { NATEXT } from "../../../shared/constants";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import { fetchInspectionTasks } from "../../../features/inspectionTasks/inspectionTasksSlice";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const InspectionTasksForm = ({ isHorizontal, isAboveError }) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const dispatch = useDispatch();

  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const { userId } = useSelector((state) => state.user);
  const allInspectionTasks = useSelector((state) => state.inspectionTasks.list);

  //console.log(errors);

  //React hook form fields
  const inspectionTask = watch("inspectionTask");
  const passFail = watch("passFail");
  const info = watch("info");
  const photo = watch("photo");
  const instructions = watch("instructions");

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Check if any one of the attributes is checked
  const isAttributeChecked = passFail || info || photo;

  const attributesArr = [
    {
      id: 0,
      label: t("inspection_passfail"),
      value: "passFail",
      isChecked: passFail,
      desc: t("pass_fail_desc"),
    },
    {
      id: 1,
      label: t("info"),
      value: "info",
      isChecked: info,
      desc: t("info_desc"),
    },
    {
      id: 2,
      label: t("photo"),
      value: "photo",
      isChecked: photo,
      desc: t("photo_desc"),
    },
  ];

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  useEffect(() => {
    if (type === "edit") {
      console.log("data", rowData);
      setValue("inspectionTask", rowData.task_name);
      setValue(
        "instructions",
        (!rowData.description || rowData.description) === NATEXT
          ? ""
          : rowData.description
      );
      setValue("passFail", rowData.pass_required === 1 ? true : false);
      setValue("info", rowData.value_required === 1 ? true : false);
      setValue("photo", rowData.image_required === 1 ? true : false);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      if (type !== "edit") {
        //Duplicate task name check
        for (let task of allInspectionTasks) {
          if (
            task.task_name.toLocaleLowerCase() ===
            inspectionTask?.trim()?.toLocaleLowerCase()
          ) {
            setError(
              "inspectionTask",
              {
                type: "custom",
                message: t("dup_insp_task_name_error"),
              },
              { shouldFocus: true }
            );
            return;
          } else {
            clearErrors("inspectionTask");
          }
        }
      }
      setSaveBtnLoading(true);
      //const { inspectionTask, passFail, info, photo, instructions } = data;

      const inspectionTaskId =
        type === "edit" ? rowData.task_id : `${userId}_${Date.now()}`;

      const apiData = {
        querystring: "addEditInspectionTask",
        inspection_task_name: inspectionTask ? inspectionTask : "",
        pass_required: passFail ? passFail : false,
        value_required: info ? info : false,
        image_required: photo ? photo : false,
        description: instructions ? instructions : "",
        operation: type === "edit" ? "update" : "create",
        inspection_task_id: inspectionTaskId,
        action: type === "edit" ? 2 : 1,
        source: "web",
        sync_version: "v2",
      };

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("inspection_task") })
          : t("record_added_msg", { recordName: t("inspection_task") });

      console.log("apiData", apiData);
      const res = await postApi(apiData, "inspection");
      if (res.success) {
        const apiData = {
          //email: "testgroup@12.in",
          querystring: "get_all_inspection_tasks",
        };
        dispatch(fetchInspectionTasks(apiData));
        reset({
          inspectionTask: "",
          passFail: false,
          info: false,
          photo: false,
          instructions: "",
        });
        dispatch(addMsg(snackbarMsg));
        setSaveBtnLoading(false);
        history.push("/inspection/inspectiontasks", { fromCancel: false });
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleCancel = () => {
    reset({
      inspectionTask: "",
      passFail: false,
      info: false,
      photo: false,
      instructions: "",
    });

    history.push("/inspection/inspectiontasks", { fromCancel: true });
  };

  const inputStyles = {
    //borderColor: colorLightGray,
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    //width: '200px'
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer
        horizontal={false}
        onSubmit={handleSubmit(onSubmit)}
        //style={{ width: "100%" }}
      >
        <FormHeader
          title={
            type === "edit"
              ? t("edit_inspection_task")
              : t("add_inspection_task")
          }
          isSaveLoading={saveBtnLoading}
          handleCancel={handleCancel}
        />

        <Container>
          <Row>
            <Col>
              <Card
                style={
                  {
                    //width: "102%",
                    //overflowY: "auto",
                    //height: `${window.innerHeight - 120}px`,
                    //scrollbarStyles,
                    //padding: 10
                  }
                }
              >
                <CardBody
                  formcard="true"
                  // style={{
                  //   overflowY: "scroll",
                  //   paddingTop: 0,
                  //   //padding: '0 16px',
                  //   //margin: 0
                  //   //height: "650px",
                  //   //scrollbarStyles,
                  // }}
                >
                  <Row className="pb-2">
                    <Col md={6}>
                      <FormGroupLabel>
                        {t("inspection_task")}
                        {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                      </FormGroupLabel>
                      <FormItem>
                        <FormField
                          name="inspectionTask"
                          control={control}
                          component="input"
                          //type="number"
                          //errors={errors}
                          rules={{
                            required: t("required_err"),
                          }}
                          defaultValue=""
                          isAboveError={isAboveError}
                          placeholder={t("inpection_task_name")}
                          style={inputStyles}
                        />
                      </FormItem>
                      {errors?.inspectionTask && (
                        <Error error={errors.inspectionTask.message} />
                      )}
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col md={10}>
                      <FormGroupLabel style={{ marginBottom: "0px" }}>
                        {t("task_actions_label")}
                        {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                      </FormGroupLabel>
                      {/* <Desc>{t("task_actions_desc")}</Desc> */}
                      {attributesArr.map((attr) => {
                        return (
                          <Row key={attr.id}>
                            {/* <Col
                              md={12}
                              className="p-2 d-flex align-items-center"
                            > */}
                            <AttrContainer>
                              <Controller
                                key={attr.id}
                                name={attr.value}
                                control={control}
                                rules={{
                                  required: !isAttributeChecked
                                    ? t("one_action_reqd_err_msg")
                                    : false,
                                }}
                                errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  // <InputRadioContainer>
                                  //   <Label className="m-0">
                                  //     <CheckboxInput
                                  //       name={attr.value}
                                  //       type="checkbox"
                                  //       //checked={false}
                                  //       onChange={onChange}
                                  //       style={{ width: "auto" }}
                                  //       //defaultValue={false}
                                  //     />
                                  //     {attr.label}
                                  //   </Label>
                                  // </InputRadioContainer>
                                  <div>
                                    <CheckBoxInput
                                      name={attr.value}
                                      type="checkbox"
                                      onChange={onChange}
                                      style={{
                                        width: "auto",
                                        margin: "0 4px 0 0",
                                      }}
                                      id={attr.value}
                                      checked={attr.isChecked}
                                    />
                                    <Label htmlFor={attr.value}>
                                      {attr.label}
                                    </Label>
                                  </div>
                                )}
                              />
                              <Desc>{attr.desc}</Desc>
                            </AttrContainer>
                            {/* </Col> */}
                            {/* <Col
                              md={10}
                              style={{
                                border: `1px solid ${colorLightGray}`,
                                borderRadius: "5px",
                              }}
                              className="p-2 my-2"
                            >
                              <div>{attr.desc}</div>
                            </Col> */}
                          </Row>
                        );
                      })}
                      {errors?.photo && <Error error={errors.photo.message} />}
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col md={10}>
                      <FormGroupLabel>
                        {t("instructions_to_driver_label")}
                        {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                      </FormGroupLabel>
                      {/* <Controller
                        name="instructions"
                        control={control}
                        errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Textarea
                            rows={2}
                            id="instructions"
                            name="instructions"
                            onChange={onChange}
                          ></Textarea>
                        )}
                      /> */}

                      <FormItem>
                        <FormField
                          name="instructions"
                          control={control}
                          component="input"
                          //type="number"
                          //errors={errors}
                          // rules={{
                          //   required: "Inspection Task Name is required",
                          // }}
                          defaultValue=""
                          isAboveError={isAboveError}
                          placeholder={t("instructions_to_driver_placeholder")}
                          style={inputStyles}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

InspectionTasksForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

InspectionTasksForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default InspectionTasksForm;

export const Textarea = styled.textarea``;

export const AttrContainer = styled(Col)`
  display: flex;
  margin: 4px 12px;
  //border: 1px solid ${colorLightGray};
  flex-direction: column;
  box-shadow: 1px 1px 3px ${colorLightGray};
  padding: 8px;
  // p {
  //   margin: 0 0 4px 8px;
  // }
`;
