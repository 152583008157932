import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  consumption: "",
  currency: "",
  distance: "",
  hourlyConsumption: "",
  volume: "",
};

const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    updateUnits: (state, action) => {
      //console.log(action);
      state.consumption = action.payload.consumption;
      state.currency = action.payload.currency;
      state.distance = action.payload.distance;
      state.hourlyConsumption = action.payload.hourly_consumption;
      state.volume = action.payload.volume;
    },
  },
});

export const { updateUnits } = unitsSlice.actions;

export default unitsSlice.reducer;
