import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ItemContainer, ItemLabel, ItemValue } from "./";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { taskBgColor } from "../../../../../utils/palette";
import { useTranslation } from "react-i18next";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { NATEXT } from "../../../../constants";
import {
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedTransactionType,
} from "../../../../helpers";
import { postApi } from "../../../../../api/getUser";
import { dhm } from "../../../../../components/WorkOrders";

const ServicesRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);
  const {
    service_date,
    odo,
    odo_unit,
    //total_cost,
    //service_task_ids,
    //labor_cost,
    //parts_cost,
    service_station,
    service_id,
    service_task_name,
    serviced_by,
    transaction_type,
    card_number,
    comments,
    org_id,
    receipt_img_name,
    tax,
    discount,
    uname,
    total_time,
    parts_total,
    labor_total,
    total,
  } = rowData;

  // let partsTotal, laborTotal, total;
  // if (total_cost) {
  //   let totalCost = JSON.parse(total_cost);
  //   partsTotal = totalCost.parts_total;
  //   laborTotal = totalCost.labor_cost_total;
  //   total = totalCost.total ? Number(totalCost.total) : 0;
  // }

  const [serviceTasks, setServiceTasks] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     let serviceTaskOptions = [];
  //     let selectedServiceTasks = [];
  //     const apiData = {
  //       querystring: "get_all_service_tasks",
  //       service_veh_id: "All",
  //     };

  //     const res = await postApi(apiData, "commonNew");

  //     if (res.success) {
  //       serviceTaskOptions = res.user_data;
  //       if (rowData?.labor_cost && rowData?.parts_cost) {
  //         const serviceTaskIds = JSON.parse(rowData?.service_task_ids);
  //         const laborCostData = JSON.parse(rowData?.labor_cost);
  //         const partsCostData = JSON.parse(rowData?.parts_cost);
  //         serviceTaskIds?.forEach((taskId) => {
  //           let temp = {
  //             ...serviceTaskOptions.find((t) => t.service_task_id === taskId),
  //           };
  //           temp.partsCost = +partsCostData[taskId];
  //           temp.laborCost = +laborCostData[taskId];
  //           temp.totalCost = +partsCostData[taskId] + +laborCostData[taskId];
  //           selectedServiceTasks.push(temp);
  //         });
  //       }
  //     }
  //     setServiceTasks(selectedServiceTasks);
  //   })();
  // }, [rowData]);

  useEffect(() => {
    //Single service fetch call on edit
    (async () => {
      const singleServiceApiData = {
        querystring: "fetchSingleServiceData",
        service_id: rowData?.service_id,
      };
      const singleServiceRes = await postApi(singleServiceApiData, "workOrder");
      if (singleServiceRes.success) {
        const { service_data, service_details_data } = singleServiceRes;

        if (service_details_data?.length > 0) {
          let serviceTaskDetails = [];
          for (let task of service_details_data) {
            const {
              service_task_detail_id,
              service_task_id,
              service_id,
              wo_id,
              labor_cost,
              parts_cost,
              total_cost,
              service_task_name,
            } = task;
            serviceTaskDetails.push({
              service_task_detail_id,
              service_task_id,
              service_id,
              service_task_name,
              wo_id,
              laborCost: +labor_cost ? +labor_cost : "",
              partsCost: +parts_cost ? +parts_cost : "",
              totalCost: +total_cost ? +total_cost : "",
              action: 2,
              label: service_task_name,
              value: service_task_id,
            });
          }
          setServiceTasks(serviceTaskDetails);
        }
      }
    })();
  }, [rowData]);

  let receiptsIdArr = [];
  let receiptsUrlData = [];
  if (receipt_img_name && receipt_img_name !== "N/A") {
    receiptsIdArr = receipt_img_name.split(":::");
    receiptsUrlData = receiptsIdArr.map((id) => ({
      id: id,
      url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${org_id}/services/${id}`,
    }));
    console.log(receiptsUrlData);
  }
  return (
    <>
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>{getListingDisplayDate(service_date)}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("time_tv")}</ItemLabel>
            <ItemValue>
              {new Date(1000 * service_date).toLocaleTimeString()}
            </ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("odometer")}</ItemLabel>
            <ItemValue>
              {odo} {getDistanceUnit(odo_unit)}
            </ItemValue>
          </ItemContainer>
        </Col>
        {service_station && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("service_station")}</ItemLabel>
              <ItemValue>{service_station}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {/* {service_task_name && service_task_name !== "N/A" && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>Service Task</ItemLabel>
              <ItemValue>{service_task_name}</ItemValue>
            </ItemContainer>
          </Col>
        )} */}
      </Row>
      {serviceTasks?.map((task) => {
        return (
          <Row
            key={task.service_task_id}
            style={{
              background: taskBgColor,
              padding: "4px",
              margin: "0.25rem 0",
              borderRadius: "5px",
            }}
          >
            <p style={{ fontSize: "16px" }}>{task.service_task_name}</p>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("parts")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.partsCost ? task.partsCost?.toFixed(2) : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("labor")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.laborCost ? task.laborCost?.toFixed(2) : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("tc_tv")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.totalCost ? task.totalCost?.toFixed(2) : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>
          </Row>
        );
      })}
      <Row
        style={{
          background: taskBgColor,
          padding: "4px",
          margin: "0.25rem 0",
          borderRadius: "5px",
        }}
      >
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("parts_total")}`}</ItemLabel>
            <ItemValue>{parts_total ? parts_total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("labor_total")}`}</ItemLabel>
            <ItemValue>{labor_total ? labor_total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("total")}`}</ItemLabel>
            <ItemValue>{total ? total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer
            className="d-flex gap-2 align-items-center justify-content-end"
            //style={{ marginRight: "8px" }}
          >
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("discount")}:
            </ItemLabel>
            <ItemValue>
              {+discount ? `${discount?.toFixed(2)} ${currency}` : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer
            className="d-flex gap-2 align-items-center justify-content-end"
            //style={{ marginRight: "12px" }}
          >
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "80px" }}
            >
              {t("tax")}:
            </ItemLabel>
            <ItemValue>
              {+tax ? `${tax?.toFixed(2)} ${currency}` : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("grand_total")}:
            </ItemLabel>
            <ItemValue
              style={{
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              {+total
                ? `${(+total - +discount + +tax)?.toFixed(2)} ${currency}`
                : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>

      <Row>
        {!!total_time && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("total_duration")}</ItemLabel>
              <ItemValue>{dhm(total_time * 1000)}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {transaction_type && transaction_type !== "N/A" && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("transaction_type")}</ItemLabel>
              <ItemValue>
                {getTranslatedTransactionType(transaction_type, t)}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!+card_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>
                {transaction_type === "Check"
                  ? t("check_number")
                  : t("card_number")}
              </ItemLabel>
              <ItemValue>{card_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {serviced_by && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("serviced_by")}</ItemLabel>
              <ItemValue>{uname}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {comments && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              {/* <textarea
                value={comments}
                disabled
                style={{ minWidth: "90%", margin: "1px", padding: "5px" }}
              ></textarea> */}
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        {receiptsUrlData.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {receiptsUrlData.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ServicesRightPanel;

export const ReceiptImg = styled.img`
  overflow: visible;
  width: 120px;
  height: 90px;
  &:active {
    transform: scale(2) translate(10%, 10%);
    overflow: visible;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 10px;
  ${(p) => p.active && `background: lightgray`}
`;
