import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        updateLoading: (state, action) => {
            state.isLoading = !state.isLoading;
        },
    },
});

export const { updateLoading } = loadingSlice.actions;
export default loadingSlice.reducer;


