import React from "react";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  HintText,
  Label,
  LinkSpan,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import FormField from "../../shared/components/form/FormField";
import { useHistory, useLocation } from "react-router";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import Error from "@/shared/components/form/Error";
import { useState } from "react";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import {
  AddFormButton,
  FilterButton,
  Icon,
  ActionIconContainer,
} from "../../shared/components/TableElements";
import format from "date-fns/format";
import {
  CustomInput,
  Desc,
  EditIcon,
} from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { uploadImgToS3 } from "../Issues/IssuesForm";
import ServiceTasks from "../../shared/components/form/ServiceTasks";
import { useEffect } from "react";
import addHours from "date-fns/addHours";
import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";
import DatePicker from "react-datepicker";
import getUnixTime from "date-fns/getUnixTime";
import SelectTechnicianPopup from "../../shared/components/form/SelectTechnicianPopup";
import { NATEXT } from "../../shared/constants";
import {
  daysHoursMinutes,
  getDateFnsLocale,
  getDisplayDate,
  getDisplayDateWithTimeNew,
  getDistanceFactorforOdo,
  getListingDisplayDate,
  getTimestamp,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";
import { getVehicles, post, postApi } from "../../api/getUser";
import AddButton from "../../shared/components/form/AddButton";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import Snackbar from "../../shared/components/Snackbar";
import { updateVehiclesList } from "../../features/vehicles/vehiclesSlice";
import WorkOrderFormHeader from "./components/WorkOrderFormHeader";
import { WorkOrderStatus } from "./WorkOrdersTable";
import {
  calculateSinglePauseTime,
  dhm,
  getTimeSinceAcceptance,
  getTotalTime,
} from ".";
import Tooltip from "@/shared/components/Tooltip";
import PausesTable from "./components/PausesTable";
import PauseConfirmPopup from "./components/PauseConfirmPopup";
import ActionPopup from "../../shared/components/ActionPopup";
import { areObjsEqual } from "../../utils/helpers";
import { useRef } from "react";
import { updateServiceTaskOptions } from "../Services/ServicesForm";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import CustomTimeToCompletePopup from "./components/CustomTimeToCompletePopup";
import ZoomModal from "../../shared/components/ZoomModal";
import { auth } from "../../api/firebase";
import InfoPopup from "../../shared/components/InfoPopup";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import SubscribePopup from "../../shared/components/SubscribePopup";
import LogsPopup from "./components/LogsPopup";
import FileUploaderNew from "../../shared/components/form/FileUploaderNew";
import ErrorPopup from "../../shared/components/ErrorPopup";
import AddIssueModal from "./components/AddIssueModal";

const WorkOrderForm = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { tag, fetchTelematicsOdo } = useAuth();

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const users = useSelector((state) => state.users.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );
  const { currency, distance } = useSelector((state) => state.units);
  const { userId, orgId, roleId, userName } = useSelector(
    (state) => state.user
  );

  const [workOrderNo, setWorkOrderNo] = useState("");
  //hook-form variables
  const purchaseOrder = watch("purchaseOrder");
  const vehicle = watch("vehicle");
  const odometer = watch("odometer");
  const priority = watch("priority");
  const dueOnDate = watch("dueOnDate"); //value from date-picker in case of custom
  const dueIn = watch("dueIn");
  const invoiceNo = watch("invoiceNo");
  const comments = watch("comments");

  const [workOrderImgs, setWorkOrderImgs] = useState([]);

  const routeData = location?.state?.data;
  const [rowData, setRowData] = useState(null); //routeData?.rowData

  //let type = location?.state?.type;
  const [type, setType] = useState(location?.state?.type);
  const mode = location?.state?.mode;

  //Data related to service tasks - start
  const [serviceTasks, setServiceTasks] = useState([]);
  const [serviceTaskOptions, setServiceTaskOptions] = useState([]);

  const serviceTasksRef = useRef([]);

  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [total, setTotal] = useState("");
  const [partsCostTotal, setPartsCostTotal] = useState("");
  const [laborCostTotal, setLaborCostTotal] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);

  const [showServiceTasksError, setShowServiceTasksError] = useState(false);
  const [showDiscountError, setShowDiscountError] = useState(false);

  //Data related to service tasks - end

  //States to toggle Popups
  const [showTechPopup, setShowTechPopup] = useState(false);
  const [showPauseConfirmPopup, setShowPauseConfirmPopup] = useState(false);
  const [showPausesTable, setShowPausesTable] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);

  const [technician, setTechnician] = useState("");
  //State to check if technician selected has changed
  const [technicianChanged, setTechnicianChanged] = useState(false);

  const [selectedTechnicianText, setSelectedTechnicianText] = useState("");

  //State to toggle Add Issue Popup
  const [showAddIssueModal, setShowAddIssueModal] = useState(false);

  const [timeSinceAcc, setTimeSinceAcc] = useState({ display: "", seconds: 0 });

  const prioritiesOptions = [
    {
      value: 1,
      label: t("low"),
    },
    {
      value: 2,
      label: t("med"),
    },
    {
      value: 3,
      label: t("high"),
    },
  ];

  const [createdOn, setCreatedOn] = useState(new Date());
  const [dueOn, setDueOn] = useState({ value: "", label: "" });

  //State to read when the WO was accepted
  const [startedOn, setStartedOn] = useState("");

  const [createdBy, setCreatedBy] = useState("");

  //Ref to check if service to be updated is present as it could be deleted
  const serviceAction = useRef("");

  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  //When woMode is 'accepted' or 'resumed', fromCancel in handleCancel will be false
  //Since we need to call listing api
  const [woMode, setWoMode] = useState("");

  //To show odo fetched via telematics msg
  const [odoFetchedMsg, setOdoFetchedMsg] = useState("");

  const [telematicsOdo, setTelematicsOdo] = useState(0);

  const dueInOptions = [
    {
      value: 1,
      label: t("2_hours"),
    },
    {
      value: 2,
      label: t("6_hours"),
    },
    {
      value: 3,
      label: t("10_hours"),
    },
    {
      value: 4,
      label: t("24_hours"),
    },
    {
      value: 5,
      label: t("2_days"),
    },
    {
      value: 6,
      label: t("next_week"),
    },
    {
      value: 7,
      label: t("custom"),
    },
  ];

  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [scheduledWorkOrder, setScheduledWorkOrder] = useState(false);

  const [workOrderStatus, setWorkOrderStatus] = useState("new");

  //State to maintain list of Open Issues
  const [issuesList, setIssuesList] = useState([]);

  //State for issues selected
  const [issues, setIssues] = useState([]);

  //State to toggle snackbar and contain snackbar message
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const [pauses, setPauses] = useState([]);
  //State to maintain pauses that are complete to show in the table
  const [completedPauses, setCompletedPauses] = useState([]);
  const [pauseBtnLoading, setPauseBtnLoading] = useState(false);

  //State to maintain reason required during pause
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const [acceptWOBtnLoading, setAcceptWOBtnLoading] = useState(false);
  const [resumeWOBtnLoading, setResumeWOBtnLoading] = useState(false);

  //States for Yes and No Buttons in action popup on close (is 'vehName' back in service?)
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [noBtnLoading, setNoBtnLoading] = useState(false);

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    filterDateEnd:
      rowData?.status === 3 ? rowData?.end_date : getUnixTime(new Date()),
  });

  const [showTimeToCompletePopup, setShowTimeToCompletePopup] = useState(false);
  const [customTotalTime, setCustomTotalTime] = useState(null);

  //To toggle issue img modal
  const [showIssueImgsModal, setShowIssueImgsModal] = useState(false);

  //To set issue img data
  const [issueImgs, setIssueImgs] = useState([]);

  //print data
  const [printData, setPrintData] = useState({});

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  const [logs, setLogs] = useState([]);
  const [showLogsPopup, setShowLogsPopup] = useState(false);

  //State to determine whether to show logs or old pause table
  const [showLogs, setShowLogs] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Flag to check if service tasks have changed
  //To update assoc service
  const [serviceTasksChanged, setServiceTasksChanged] = useState(false);

  //Ref used to store odo_validation_date
  const odoValidationDate = useRef(0);

  //Effect to set showlogs to true on add
  useEffect(() => {
    if (type !== "edit") {
      setShowLogs(true);
    }
  }, []);

  //Effect to set created by on add
  useEffect(() => {
    if (type !== "edit" && users?.length > 0 && userId) {
      setCreatedBy(users?.find((u) => u.user_id === userId));
    }
  }, [users, userId]);

  //Effect to set workorder no on add
  useEffect(() => {
    if (type !== "edit" && orgId) {
      (async () => {
        const totalCountRes = await postApi(
          { querystring: "getMaxWorkOrderCount" },
          "commonNew"
        );
        const count = totalCountRes.user_data[0]?.maxCount;
        const woCount = count ? count + 1 : 1;
        setWorkOrderNo(`WO_${orgId}_${woCount}`);
      })();
    }
  }, [orgId]);

  //Effect to show high odo warning when telematics odo is fetched
  useEffect(() => {
    if (
      +telematicsOdo &&
      +lastOdoValue &&
      +telematicsOdo - +lastOdoValue > 1000
    ) {
      setShowHighOdoErr(true);
    } else {
      setShowHighOdoErr(false);
    }
  }, [telematicsOdo, lastOdoValue]);

  //Effect to set printData on change of time since acc and technician
  useEffect(() => {
    if (rowData) {
      let printData = {
        woId: rowData.wo_id,
        technicianId: selectedTechnicianText,
      };
      printData.timeToComplete = timeSinceAcc.display;

      setPrintData(printData);
    }
  }, [rowData, timeSinceAcc, selectedTechnicianText]);

  //Effect to set timeSinceAcc and/or custom total time
  useEffect(() => {
    if ((workOrderStatus === 1 || workOrderStatus === 2) && startedOn) {
      let schedule = "1:2:3:4:5:6:7";
      let scheduleStartTime = "00:00";
      let scheduleEndTime = "23:59";
      if (technician) {
        const { working_days, starting_time, ending_time } = technician;
        schedule = working_days ? working_days : "1:2:3:4:5:6:7";
        scheduleStartTime = starting_time ? starting_time.slice(0, 5) : "00:00";
        scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";
      }

      const incompletePause = pauses.find((p) => !p.end_time);

      let endTime = new Date();
      if (incompletePause) {
        endTime = new Date(incompletePause.start_time * 1000);
      }

      let { display, seconds } = getTimeSinceAcceptance(
        startedOn,
        endTime,
        scheduleStartTime,
        scheduleEndTime,
        schedule,
        pauses.filter((p) => p.end_time)
      );
      setTimeSinceAcc({ display: display, seconds: seconds });
    }

    if (workOrderStatus === 3 && +rowData.total_time) {
      const { d, h, m, display } = daysHoursMinutes(+rowData.total_time);

      setCustomTotalTime({
        seconds: +rowData.total_time,
        display: display,
        days: d,
        hours: h,
        minutes: m,
      });
    }
  }, [completedPauses, technician, workOrderStatus, pauses, startedOn]);

  //Update timeSinceAcc on change of customTotalTime
  useEffect(() => {
    if (customTotalTime) {
      setTimeSinceAcc({
        display: customTotalTime.display,
        seconds: customTotalTime.seconds,
      });
    }
  }, [customTotalTime]);

  const printFunc = () => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    const email = auth.currentUser.email;
    const env = process.env.REACT_APP_ENV === "test" ? "test" : "app";
    const url = `https://s3.us-east-2.amazonaws.com/web.simplyfleet.app/${env}/work_order_report_v2.html?email=${email}&wo_id=${printData.woId}&time_complete=${printData.timeToComplete}&selected_technician=${printData.technicianId}`;
    window.open(url, "_blank", "noreferrer");
  };

  const handleOdoValidation = async (btnLoadingStateSetter) => {
    //Odo Validation
    const distanceFactor = getDistanceFactorforOdo(
      vehicle?.primary_meter,
      distance
    );

    let odoConverted = odometer * distanceFactor;
    odoValidationDate.current = getUnixTime(new Date());
    const odoCheckApiData = {
      odo: odoConverted,
      veh_id: vehicle.vehicleId,
      datetocheck: odoValidationDate.current,
      querystring: "get_around_dates_v3",
      check_from: "work_orders",
      check_id: type === "edit" ? rowData.wo_id : "",
    };
    let odoValidationRes = 1;

    odoValidationRes = await odoValidation(
      "odometer",
      odoCheckApiData,
      setError,
      btnLoadingStateSetter,
      t
    );

    if (!odoValidationRes) {
      odoValidationDate.current = 0;
    }

    return odoValidationRes;
  };

  const handlePauseClick = async () => {
    try {
      if (!reason) {
        setReasonError(t("required_err"));
        return;
      } else {
        if (reasonError) {
          setReasonError("");
        }
        setPauseBtnLoading(true);
        if (
          odometer !== "" &&
          +odometer >= 0 &&
          (+rowData.odo !== +odometer || !rowData?.odo_validation_date)
        ) {
          const odoValRes = await handleOdoValidation(setPauseBtnLoading);
          if (!odoValRes) {
            setShowPauseConfirmPopup(false);
            return;
          }
        }
        const res = await updateWOData(2, rowData.start_date);
        if (res) {
          const pauseApiData = {
            sync_version: "v2",
            action: 1,
            operation: "create",
            querystring: "addEditWorkOrderPause",
            pause_id: `${vehicle.vehicleId}_${Date.now()}`,
            wo_id: rowData.wo_id,
            start_time: getUnixTime(new Date()),
            end_time: 0,
            pause_time: 0,
            reason: reason,
            veh_id: vehicle.vehicleId,
          };
          const response = await postApi(pauseApiData, "workOrder");
          if (response.success === 1) {
            //No need to update logs/notes as we are going back to listing
            dispatch(addMsg(t("wo_paused")));
            history.push("/workorders", { fromCancel: false });
            setShowPauseConfirmPopup(false);
            setPauseBtnLoading(false);
          }
        } else {
          setPauseBtnLoading(false);
          setShowErrPopup(true);
          return;
        }
      }
    } catch (e) {
      setPauseBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handlePauseWOClick = () => {
    setShowPauseConfirmPopup(true);
  };

  const handleCloseWOClick = async () => {
    let odoValidationRes = 1;
    if (odometer === "") {
      if (vehicle && vehicle?.provider_type_1 && fetchTelematicsOdo) {
        setError(
          "odometer",
          { type: "required", message: t("required_err") },
          { shouldFocus: true }
        );
        setOdoFetchedMsg(t("fetching_data_via_telematics"));
        const odoApiData = {
          querystring: "get_current_obd_odometer",
          veh_id: vehicle.veh_id,
          provider_type: 1,
        };
        const res = await post("integrations", odoApiData);
        if (res.success) {
          let odoVal = +res.data.odometer_in_meters;

          if (+odoVal) {
            odoVal = odoVal?.toFixed(2);
            setValue("odometer", odoVal);
            clearErrors("odometer");
            setOdoFetchedMsg(t("fetched_via_telematics_device"));
            if (+odoVal && +lastOdoValue && +odoVal - +lastOdoValue > 1000) {
              setShowHighOdoErr(true);
            } else {
              setShowHighOdoErr(false);
            }
          }
        } else {
          setOdoFetchedMsg("");
          setError(
            "odometer",
            { type: "required", message: t("required_err") },
            { shouldFocus: true }
          );
        }
      } else {
        setOdoFetchedMsg("");
        setError(
          "odometer",
          { type: "required", message: t("required_err") },
          { shouldFocus: true }
        );
      }

      return;
    } else {
      clearErrors("odometer");
      if (
        odometer !== "" &&
        +odometer >= 0 &&
        (+rowData.odo !== +odometer || !rowData?.odo_validation_date)
      ) {
        const odoValRes = await handleOdoValidation(setYesBtnLoading);
        if (!odoValRes) {
          setYesBtnLoading(false);
          setNoBtnLoading(false);
          setShowActionPopup(false);
          return;
        }
      }
    }

    if (serviceTasks?.length === 0) {
      setShowServiceTasksError(true);
      return;
    }

    if (odometer !== "" && odoValidationRes && serviceTasks.length > 0) {
      setShowActionPopup(true);
    }
  };

  const handleResumeWOClick = async () => {
    try {
      setResumeWOBtnLoading(true);
      if (
        odometer !== "" &&
        +odometer >= 0 &&
        (+rowData.odo !== +odometer || !rowData?.odo_validation_date)
      ) {
        const odoValRes = await handleOdoValidation(setResumeWOBtnLoading);
        if (!odoValRes) {
          return;
        }
      }
      const woRes = await updateWOData(
        1,
        rowData.start_date,
        0,
        0,
        "",
        "resume"
      );
      if (woRes) {
        const currentPauseObj = pauses.find((p) => !p.end_time);

        const pauseStartTime = new Date(currentPauseObj.start_time * 1000);
        const pauseEndTime = new Date();
        const endTime = getUnixTime(pauseEndTime);
        let schedule = "1:2:3:4:5:6:7";
        let scheduleStartTime = "00:00";
        let scheduleEndTime = "23:59";
        if (technician) {
          const { starting_time, ending_time, working_days } = technician;
          schedule = working_days ? working_days : "1:2:3:4:5:6:7";
          scheduleStartTime = starting_time ? starting_time : "00:00";
          scheduleEndTime = ending_time ? ending_time : "23:59";
        }

        const pauseTime = calculateSinglePauseTime(
          schedule,
          pauseStartTime,
          pauseEndTime,
          scheduleStartTime,
          scheduleEndTime
        );
        //Get reqd data for pauseApidData
        const pauseApiData = {
          sync_version: "v2",
          action: 2,
          operation: "update",
          querystring: "addEditWorkOrderPause",
          pause_id: currentPauseObj.pause_id,
          wo_id: rowData.wo_id,
          start_time: currentPauseObj.start_time,
          end_time: endTime,
          pause_time: pauseTime,
          reason: currentPauseObj.reason,
          veh_id: currentPauseObj.veh_id,
        };
        const response = await postApi(pauseApiData, "workOrder");
        if (response.success === 1) {
          setWoMode("resumed");
          setResumeWOBtnLoading(false);
          setWorkOrderStatus(1);

          //Logic to get pauses
          (async () => {
            const pauseApiFetchData = {
              querystring: "get_all_pauses_for_workorder",
              wo_id: rowData.wo_id,
            };
            const pauseResponse = await postApi(pauseApiFetchData, "commonNew");
            if (pauseResponse.success === 1) {
              const pauseUserData = pauseResponse.user_data;
              const completedPauses = pauseUserData.filter((p) => p.end_time);
              setPauses(pauseUserData);
              setCompletedPauses(completedPauses);
            }
          })();

          setSnackbarMsg(t("wo_resumed"));
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
            setSnackbarMsg("");
          }, 5000);
        }
      } else {
        setResumeWOBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setResumeWOBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const updateVehicleToBackInService = async () => {
    //Get vehicle back in service
    const vehicleApiData = {
      veh_id: vehicle?.vehicleId,
      secondary_status: 0,
      veh_active: 1,
      operation: "update",
    };
    const vehRes = await postApi(vehicleApiData, "vehicle");
    if (vehRes.success === 1) {
      const vehiclesList = await getVehicles("getallvehicle", t);
      dispatch(updateVehiclesList(vehiclesList));
    } else {
      const { vehicle_data } = vehRes;
      if (vehicle_data === "duplicate_entry") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_err"),
        }));
      } else if (vehicle_data === "vehicle_limit_reached") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("vehicle_limit_reached_err"),
        }));
      } else if (vehicle_data === "trail_vehicle_limit_reached") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("trail_vehicle_limit_reached_err"),
        }));
      }
      setVehicleUpdErr(true);
      setShowActionPopup(false);
    }
    return vehRes.success;
  };

  const handleCloseWO = async () => {
    try {
      const serviceId = `${vehicle.vehicleId}_${Date.now()}`;
      const endDate = new Date();
      const endDateUnix = getUnixTime(endDate);

      let schedule = "1:2:3:4:5:6:7";
      let scheduleStartTime = "00:00";
      let scheduleEndTime = "23:59";
      if (technician) {
        const { working_days, starting_time, ending_time } = technician;
        schedule = working_days ? working_days : "1:2:3:4:5:6:7";
        scheduleStartTime = starting_time ? starting_time.slice(0, 5) : "00:00";
        scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";
      }
      const incompletePause = pauses.find((p) => !p.end_time);
      let endTime = endDate;
      if (incompletePause) {
        endTime = new Date(incompletePause.start_time * 1000);
      }

      const totalTime = getTimeSinceAcceptance(
        startedOn,
        endTime,
        scheduleStartTime,
        scheduleEndTime,
        schedule,
        completedPauses
      ).seconds;

      const res = await updateWOData(
        3,
        rowData.start_date,
        endDateUnix,
        totalTime,
        serviceId
      );
      if (res) {
        let serviceTaskIds = [];
        let partsCostData = {};
        let laborCostData = {};

        if (serviceTasks?.length > 0) {
          serviceTasks?.forEach((t) => {
            serviceTaskIds.push(t.service_task_id);
            partsCostData[t.service_task_id] = t.partsCost ? +t.partsCost : 0;
            laborCostData[t.service_task_id] = t.laborCost ? +t.laborCost : 0;
          });
        }

        //Odometer of new service created should be max of vehicle maxOdo and odo from telematics
        const distanceFactor = getDistanceFactorforOdo(
          vehicle?.primary_meter,
          distance
        );

        //Fetch max odo
        let odoFromTelematics = 0;
        let maxOdo = 0;
        let odoVal = 0;
        const latestDate = +endDateUnix + 1;

        const maxOdoRes = await postApi(
          {
            querystring: "getOdometerFromDate",
            sub_querystring: "",
            veh_id: vehicle?.veh_id,
            filter_date_start: 0,
            filter_date_end: latestDate, //getUnixTime(new Date()),
          },
          "dashboard"
        );

        if (maxOdoRes.success) {
          let [nextOdo, lastOdo] = maxOdoRes.odohint_data;
          if (lastOdo) {
            //Convert to vehicle unit
            lastOdo = lastOdo / distanceFactor;
            maxOdo = lastOdo;
          }
        }

        //Fetch odo from telematics
        if (fetchTelematicsOdo && vehicle?.provider_type_1) {
          const odoApiData = {
            querystring: "get_current_obd_odometer",
            veh_id: vehicle?.veh_id,
            provider_type: 1,
          };
          const odoRes = await post("integrations", odoApiData);
          if (odoRes.success) {
            odoFromTelematics = +odoRes.data.odometer_in_meters;
          }
        }

        odoVal = Math.max(maxOdo, odoFromTelematics);
        if (odoVal) {
          //Convert back to org unit
          odoVal = odoVal * distanceFactor;
          odoVal = +odoVal.toFixed(2);
        }

        const serviceApiData = {
          querystring: "addEditService",
          action: 1,
          source: "web",
          sync_version: "v2",
          operation: "create",
          service_id: serviceId,
          veh_id: vehicle.vehicleId,
          user_id: createdBy.user_id,
          service_date: endDateUnix,
          odo: odoVal,
          odo_unit: vehicle?.primary_meter === "Hours" ? "Hours" : distance,
          currency: currency ? currency : "",
          //labor_cost: laborCostData,
          //parts_cost: partsCostData,
          // total_cost: {
          //   parts_total: partsCostTotal ? +partsCostTotal : 0,
          //   labor_cost_total: laborCostTotal ? +laborCostTotal : 0,
          //   total: total ? +total : 0,
          // },
          //service_task_ids: serviceTaskIds,
          parts_total: +partsCostTotal ? +partsCostTotal : 0,
          labor_total: +laborCostTotal ? +laborCostTotal : 0,
          total: +total ? +total : 0,
          operator_userid: technician ? technician.user_id : createdBy.user_id,
          receipt_img_name: "",
          tax: tax ? +tax : 0,
          discount: discount ? +discount : 0,
          comments: t("service_via_work_order_comments", {
            woNo: rowData.wo_number,
            createdDate: getListingDisplayDate(rowData.create_date),
          }),
          card_number: 0,
          service_station: "",
          transaction_type: "Cash",
          total_time: totalTime,
        };

        const serviceResponse = await postApi(serviceApiData, "workOrder");

        if (!serviceResponse.success) {
          return 0;
        }

        if (serviceResponse.success) {
          if (serviceTasksRef.current.length > 0) {
            let serviceTaskDetails = [];
            for (let task of serviceTasksRef.current) {
              const {
                partsCost,
                laborCost,
                totalCost,
                service_task_id,
                service_task_detail_id,
                action,
              } = task;

              serviceTaskDetails.push({
                service_task_detail_id: service_task_detail_id,
                service_task_id: service_task_id,
                service_id: serviceId,
                wo_id: rowData.wo_id,
                parts_cost: partsCost ? +partsCost : 0,
                labor_cost: laborCost ? +laborCost : 0,
                total_cost: totalCost ? +totalCost : 0,
                action: action,
              });
            }

            const serviceTaskDetailsApiData = {
              source: "web",
              sync_version: "v2",
              operation: type === "edit" ? "update" : "create",
              querystring: "addEditServiceTaskDetails",
              service_task_details: serviceTaskDetails,
            };

            const serviceTaskDetailsApiRes = await postApi(
              serviceTaskDetailsApiData,
              "workOrder"
            );
            return serviceTaskDetailsApiRes.success;
          }
        }
      }
    } catch (e) {
      console.log(e);
      return 0;
    }
  };

  const handleYes = () => {
    (async () => {
      setYesBtnLoading(true);
      const vehRes = await updateVehicleToBackInService();
      if (vehRes) {
        const closeWORes = await handleCloseWO();
        if (closeWORes) {
          dispatch(addMsg(t("wo_closed")));
          history.push("/workorders", { fromCancel: false });
          setYesBtnLoading(false);
          setShowActionPopup(false);
        } else {
          setYesBtnLoading(false);
          setShowErrPopup(true);
          setShowActionPopup(false);
          return;
        }
      } else {
        setYesBtnLoading(false);
        return;
      }
    })();
  };

  const handleNo = () => {
    (async () => {
      setNoBtnLoading(true);
      const closeWORes = await handleCloseWO();
      if (closeWORes) {
        dispatch(addMsg(t("wo_closed")));
        history.push("/workorders", { fromCancel: false });
        setNoBtnLoading(false);
        setShowActionPopup(false);
      } else {
        setNoBtnLoading(false);
        setShowErrPopup(true);
        setShowActionPopup(false);
        return;
      }
    })();
  };

  //Effect to populate fields on edit
  useEffect(() => {
    if (
      type === "edit" &&
      vehiclesList?.length > 0 &&
      users?.length > 0 &&
      orgId
    ) {
      (async () => {
        const singleWORes = await postApi(
          {
            querystring: "fetchSingleWoData",
            wo_id: routeData?.rowData?.wo_id,
          },
          "workOrder"
        );
        if (singleWORes.success) {
          const { wo_data, service_details_data } = singleWORes;
          const singleWO = wo_data;
          setRowData(singleWO);

          //Service Task Details
          if (service_details_data?.length > 0) {
            let serviceTaskDetails = [];
            for (let task of service_details_data) {
              const {
                service_task_detail_id,
                service_task_id,
                service_id,
                wo_id,
                labor_cost,
                parts_cost,
                total_cost,
                service_task_name,
              } = task;
              serviceTaskDetails.push({
                service_task_detail_id,
                service_task_id,
                service_id,
                service_task_name,
                wo_id,
                laborCost: +labor_cost ? +labor_cost : "",
                partsCost: +parts_cost ? +parts_cost : "",
                totalCost: +total_cost ? +total_cost : "",
                action: 2,
                label: service_task_name,
                value: service_task_id,
              });
            }
            setServiceTasks(serviceTaskDetails);
            serviceTasksRef.current = serviceTaskDetails;
          }

          const {
            veh_id,
            assigned_to,
            comments,
            create_date,
            created_by,
            due_date,
            start_date,
            end_date,
            invoice_number,
            name,
            odo,
            po_number,
            priority,
            service_id,
            //service_task_ids,
            status,
            technician_id,
            //total_cost,
            //type,
            wo_number,
            wo_img_name,
            tax,
            discount,
            due_selection,
            //labor_cost,
            //parts_cost,
            wo_id,
            created_by_name,
            odo_validation_date,
          } = singleWO;
          const workOrderType = singleWO.type; //To avoid name clash with type add/edit
          const workOrderLogs = singleWO.logs; //To avoid name clash with 'logs' state

          setWorkOrderNo(wo_number);
          setValue("purchaseOrder", po_number);
          //This if condition is to avoid setting vehicle every time since change in vehicle will call issues fetch api leading to infinite re-renders
          if (!vehicle) {
            setValue(
              "vehicle",
              vehicles.find((v) => v.vehicleId === veh_id)
            );
          }
          const selectedTechnician = users.find(
            (t) => t.user_id === technician_id
          );
          setTechnician(selectedTechnician);
          setScheduledWorkOrder(!workOrderType);
          let odoVal = 0;
          if (odo && odo != -1000) {
            const distanceFactor = getDistanceFactorforOdo(
              vehicles.find((v) => v.vehicleId === veh_id)?.primary_meter,
              distance
            );

            odoVal = (+odo / distanceFactor)?.toFixed(2);
          }
          if (odo == -1000) {
            odoVal = "";
          }
          setValue("odometer", odoVal);
          odoValidationDate.current = odo_validation_date || 0;
          setValue(
            "priority",
            prioritiesOptions.find((p) => p.value === priority)
          );
          setValue(
            "dueIn",
            dueInOptions.find((o) => o.value === due_selection)
          );
          setCreatedOn(new Date(create_date * 1000));
          // setCreatedBy(users.find((u) => u.user_id === created_by));
          setCreatedBy({ user_id: created_by, label: created_by_name });
          setWorkOrderStatus(status);

          setValue("comments", comments);

          setTax(+tax ? Math.round(+tax) : 0);
          setDiscount(+discount ? Math.round(+discount) : 0);

          //Logic to set Images
          let imgNames = [];

          if (wo_img_name) {
            imgNames = wo_img_name.split(":::");

            const imgs = [];
            imgNames.map((imgName, i) => {
              imgs.push({
                existing: true,
                imgName: imgName,
                id: Date.now() + i,
                url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/workorder/${wo_id}/${imgName}`,
              });
            });

            setWorkOrderImgs(imgs);
          }
          //Logic to set Images- End

          setValue("invoiceNo", invoice_number);

          if (start_date) {
            setStartedOn(new Date(start_date * 1000));
          }

          //Logic to get pauses
          // (async () => {
          const pauseApiFetchData = {
            querystring: "get_all_pauses_for_workorder",
            wo_id: wo_id,
          };
          const pauseResponse = await postApi(pauseApiFetchData, "commonNew");
          if (pauseResponse.success === 1) {
            const pauseUserData = pauseResponse.user_data;

            const completedPauses = pauseUserData.filter((p) => p.end_time);

            setPauses(pauseUserData);
            setCompletedPauses(completedPauses);
          }
          // })();

          //Logic to update logs
          if (workOrderLogs) {
            setShowLogs(true);
            const parsedLogs = JSON.parse(workOrderLogs);
            if (parsedLogs && parsedLogs?.length > 0) {
              const logsArr = parsedLogs.map((log) => ({
                ...log,
                user: users.find((u) => u.user_id === log.user_id)?.name,
              }));
              setLogs(logsArr);
            }
          } else {
            if (workOrderLogs === "") {
              setShowLogs(true);
            }
            if (workOrderLogs === null) {
              setShowLogs(false);
            }
          }

          //Logic to fetch and set associated issues
          // (async () => {
          const apiData = {
            querystring: "get_wo_assigned_issues",
            wo_id: wo_id,
          };
          const res = await postApi(apiData, "commonNew");

          if (res.success === 1) {
            const { user_data } = res;
            const temp = user_data.map((issue) => {
              let imgNames = [];
              let images = [];
              const editIssueimgName = issue?.issue_img_name;
              if (editIssueimgName) {
                imgNames = editIssueimgName.split(":::");

                imgNames.map((imgName, i) => {
                  images.push({
                    existing: true,
                    imgName: imgName,
                    id: Date.now() + i,
                    url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/issues/${issue?.issue_id}/${imgName}`,
                  });
                });
              }
              return {
                ...issue,
                label: issue.issue,
                value: issue.issue_id,
                images,
              };
            });

            setIssues(temp);
          }
          // })();

          if (mode === "from-workorder-table-error") {
            const { rowData } = routeData;
            const { odo } = rowData;

            if (
              serviceTasksRef.current.map((t) => t.action !== 3).length === 0
            ) {
              setShowServiceTasksError(true);
            }

            if (!odo || odo == -1000) {
              setError(
                "odometer",
                { type: "required", message: t("required_err") },
                { shouldFocus: true }
              );

              const currentVehicle = vehicles.find(
                (v) => v.vehicleId === veh_id
              );

              if (currentVehicle.provider_type_1 && fetchTelematicsOdo) {
                setOdoFetchedMsg(t("fetching_data_via_telematics"));

                const odoApiData = {
                  querystring: "get_current_obd_odometer",
                  veh_id: rowData.veh_id,
                  provider_type: 1,
                };
                const res = await post("integrations", odoApiData);
                if (res.success) {
                  let odoVal = +res.data.odometer_in_meters;

                  if (+odoVal) {
                    odoVal = odoVal?.toFixed(2);
                    setValue("odometer", odoVal);
                    clearErrors("odometer");
                    setOdoFetchedMsg(t("fetched_via_telematics_device"));
                    setTelematicsOdo(odoVal);
                  }
                } else {
                  setTelematicsOdo(0);
                  setOdoFetchedMsg("");
                  // setError(
                  //   "odometer",
                  //   { type: "required", message: t("required_err") },
                  //   { shouldFocus: true }
                  // );
                }
              } else {
                setTelematicsOdo(0);
                // setError(
                //   "odometer",
                //   { type: "required", message: t("required_err") },
                //   { shouldFocus: true }
                // );
              }
            }

            if (routeData.odoErrorMessage) {
              setError(
                "odometer",
                { type: "custom", message: routeData.odoErrorMessage },
                { shouldFocus: true }
              );
            }
          }

          //Logic to call api and update serviceAction(serviceAction used to check if associated service is present)
          if (service_id) {
            // (async () => {
            const res = await postApi(
              {
                service_id: service_id,
                querystring: "get_workorder_service_count",
              },
              "commonNew"
            );
            if (res.success) {
              const { user_data } = res;
              serviceAction.current = user_data[0].action;
            }
            // })();
          }
        }
      })();
    }
  }, [users, orgId]);

  //Effect to fetch odo from telematics
  useEffect(() => {
    if (vehicle && !mode) {
      const fetchOdoFromTelematics = async () => {
        setValue("odometer", "");
        setOdoFetchedMsg(t("fetching_data_via_telematics"));
        const odoApiData = {
          querystring: "get_current_obd_odometer",
          veh_id: vehicle?.veh_id,
          provider_type: 1,
        };
        const res = await post("integrations", odoApiData);
        if (!res?.success) {
          setTelematicsOdo(0);
          setOdoFetchedMsg("");
          setValue("odometer", "");
        }
        if (res.success) {
          let odoVal = +res.data.odometer_in_meters;

          if (+odoVal) {
            odoVal = odoVal?.toFixed(2);
            setValue("odometer", odoVal);
            setOdoFetchedMsg(t("fetched_via_telematics_device"));
            setTelematicsOdo(odoVal);
          }
        }
      };

      if (!vehicle?.provider_type_1 || !fetchTelematicsOdo) {
        setTelematicsOdo(0);
        setOdoFetchedMsg("");
      }

      if (vehicle?.provider_type_1 && fetchTelematicsOdo) {
        //For add
        if (type !== "edit") {
          (async () => {
            await fetchOdoFromTelematics();
          })();
        }

        //For edit - Fetch only if odo is not entered
        if (type === "edit") {
          if (rowData.odo == -1000) {
            (async () => {
              await fetchOdoFromTelematics();
            })();
          }
        }
      }
    }
  }, [vehicle, fetchTelematicsOdo]);

  //Effect to fetch list of all open issues
  useEffect(() => {
    if (vehicle) {
      (async () => {
        const apiData = {
          //email: "testgroup@12.in",
          veh_id: vehicle.vehicleId,
          querystring: "get_open_unassigned_issues",
        };
        const res = await postApi(apiData, "commonNew");
        if (res.success === 1) {
          const { user_data } = res;
          const temp = user_data.map((issue) => {
            let imgNames = [];
            let images = [];
            const editIssueimgName = issue?.issue_img_name;
            if (editIssueimgName) {
              imgNames = editIssueimgName.split(":::");

              imgNames.map((imgName, i) => {
                images.push({
                  existing: true,
                  imgName: imgName,
                  id: Date.now() + i,
                  url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/issues/${issue?.issue_id}/${imgName}`,
                });
              });
            }
            return {
              ...issue,
              label: issue.issue,
              value: issue.issue_id,
              images,
            };
          });

          setIssuesList(temp);
        }
      })();

      (async () => {
        const apiData = {
          //email: "testgroup@12.in",
          querystring: "getallservicetask",
          service_veh_id: vehicle.vehicleId,
        };
        const serviceTasksRes = await updateServiceTaskOptions(apiData);
        setServiceTaskOptions(serviceTasksRes);
      })();
    }
  }, [vehicle]);

  //Effect to handle create work order from add-issue
  useEffect(() => {
    if (mode === "from-add-issue") {
      const {
        veh_id,
        comments,
        reported_date,
        reported_by,
        issue,
        issue_desc,
        issue_id,
        user_name,
        name,
        odo,
        priority,
        status,
        issue_img_name,
      } = routeData;

      if (!vehicle) {
        setValue(
          "vehicle",
          vehicles.find((v) => v.vehicleId === veh_id)
        );
      }
      // let odoConverted = "";
      // if (odo) {
      //   const distanceFactor = getDistanceFactorforOdo(
      //     vehicles.find((v) => v.vehicleId === veh_id)?.primary_meter,
      //     distance
      //   );

      //   odoConverted = (odo / distanceFactor).toFixed(2);
      // }
      // setValue("odometer", odoConverted);

      setScheduledWorkOrder(false);
      setWorkOrderStatus("new");
      //setValue("priority", 0);
      setIssues(issuesList.filter((issue) => issue.issue_id === issue_id));

      setValue(
        "comments",
        t("created_for_issue_comments", { issueName: issue, count: 1 })
      );

      //Created By and Images pending disc.
    }

    if (mode === "from-issues-table") {
      const vehicleId = routeData[0].veh_id;
      const issueIdsArr = routeData.map((row) => row.issue_id);

      if (!vehicle) {
        setValue(
          "vehicle",
          vehicles.find((v) => v.vehicleId === vehicleId)
        );
      }

      setScheduledWorkOrder(false);
      setWorkOrderStatus("new");
      //setValue("priority", 0);

      const selectedIssues = issuesList.filter((issue) =>
        issueIdsArr.includes(issue.issue_id)
      );

      setIssues(selectedIssues);
      let selectedIssueNames = "";
      if (selectedIssues.length > 0) {
        selectedIssueNames = selectedIssues.map((i) => i.issue).join(",");
      }

      setValue(
        "comments",
        t("created_for_issue_comments", {
          issueName: selectedIssueNames,
          count: selectedIssues.length,
        })
      );
    }
  }, [issuesList]);

  //Effect to update technician text on change of technician
  useEffect(() => {
    if (technician) {
      if (roleId === 4) {
        setSelectedTechnicianText(technician.label);
      } else {
        const { label, hourly_rate, starting_time, ending_time } = technician;
        let rateText = `(${hourly_rate} ${currency}/hour)`;
        if (!hourly_rate) {
          rateText = "";
        }
        setSelectedTechnicianText(
          `${label} ${rateText} - ${
            starting_time && starting_time != "00:00:00"
              ? starting_time?.slice(0, 5)
              : "00:00"
          } - ${
            ending_time && ending_time != "00:00:00"
              ? ending_time?.slice(0, 5)
              : "23:59"
          }`
        );
      }
    }
  }, [technician]);

  //Effect to change dueOn on change of dueIn
  useEffect(() => {
    let temp = "";

    if (dueIn?.value === 1) {
      temp = addHours(createdOn, 2);
      setDueOn({
        value: temp,
        label: getDisplayDateWithTimeNew(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 2) {
      temp = addHours(createdOn, 6);
      setDueOn({
        value: temp,
        label: getDisplayDateWithTimeNew(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 3) {
      temp = addHours(createdOn, 10);
      setDueOn({
        value: temp,
        label: getDisplayDateWithTimeNew(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 4) {
      temp = addHours(createdOn, 24);
      setDueOn({
        value: temp,
        label: getDisplayDateWithTimeNew(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 5) {
      temp = addDays(createdOn, 2);
      setDueOn({
        value: temp,
        label: getListingDisplayDate(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 6) {
      temp = addWeeks(createdOn, 1);
      setDueOn({
        value: temp,
        label: getListingDisplayDate(getUnixTime(temp)),
      });
    } else if (dueIn?.value === 7) {
      setValue("dueOnDate", new Date());
    }
  }, [dueIn]);

  const handleIssueRemove = (issueId) => {
    setIssues((prev) => {
      const temp = [...prev];
      return temp.filter((issue) => issue.issue_id !== issueId);
    });
  };

  //Function to get the value of send_email
  const getSendEmail = () => {
    let sendEmail = 0;
    if (technician) {
      if (rowData?.technician_id) {
        if (rowData.technician_id !== technician.user_id) {
          sendEmail = 1;
        }
      } else {
        sendEmail = 1;
      }
    }

    return sendEmail;
  };

  //Function to show Issue Images Popup
  const handleissueImgClick = (issue) => {
    const imgs = issue.images;
    if (imgs && imgs.length > 0) {
      setIssueImgs(imgs);
      setShowIssueImgsModal(true);
    }
  };

  const handleAcceptWOClick = async () => {
    setAcceptWOBtnLoading(true);

    if (
      odometer !== "" &&
      +odometer >= 0 &&
      (+rowData.odo !== +odometer || !rowData?.odo_validation_date)
    ) {
      const odoValRes = await handleOdoValidation(setAcceptWOBtnLoading);
      if (!odoValRes) {
        return;
      }
    }

    const startDate = new Date();
    const woUpdated = await updateWOData(
      1,
      getUnixTime(startDate),
      0,
      0,
      "",
      "accept"
    );

    if (woUpdated) {
      setWoMode("accepted");
      if (vehicle.vehActive === 1) {
        const vehicleApiData = {
          veh_id: vehicle?.vehicleId,
          secondary_status: 1,
          veh_active: 0,
          operation: "update",
        };

        //Calling Vehicle Api
        const vehRes = await postApi(vehicleApiData, "vehicle");

        if (vehRes.success === 1) {
          //(async () => {
          const vehiclesRes = await getVehicles("getallvehicle", t);
          dispatch(updateVehiclesList(vehiclesRes));
          //})();
        }
      }
      //dispatch(addMsg("Work Order Accepted"));
      setAcceptWOBtnLoading(false);
      setShowSnackbar(true);
      setSnackbarMsg(t("wo_accepted"));
      setStartedOn(startDate);
      setWorkOrderStatus(1);
      setTimeout(() => {
        setShowSnackbar(false);
        setSnackbarMsg("");
      }, 5000);
    }
    // })();
  };

  //Function to update WO table when pause is clicked
  const updateWOData = async (
    workOrderStatus,
    startDate,
    endDate = 0,
    totalTime = 0,
    serviceId = "",
    mode = ""
  ) => {
    let dueDate = "";
    let dueDateFormatted = "";
    if (dueIn) {
      dueDate =
        dueIn.value === 7 ? getUnixTime(dueOnDate) : getUnixTime(dueOn.value);
      let dateToFormat = dueIn.value === 7 ? dueOnDate : dueOn.value;
      dueDateFormatted = format(dateToFormat, "MMMM dd, yyyy hh:mm a");
    }

    let serviceTaskIds = [];
    let partsCostData = {};
    let laborCostData = {};

    if (serviceTasks?.length > 0) {
      serviceTasks?.forEach((t) => {
        serviceTaskIds.push(t.service_task_id);
        partsCostData[t.service_task_id] = t.partsCost ? +t.partsCost : 0;
        laborCostData[t.service_task_id] = t.laborCost ? +t.laborCost : 0;
      });
    }

    //Logic to get generate imagesToS3 array and imagesString
    let imagesToS3 = [];

    let imagesString = "";

    workOrderImgs?.forEach((img, i) => {
      if (img?.hasOwnProperty("existing")) {
        imagesString += `${img?.imgName}:::`;
      } else {
        if (img.file) {
          const extension = img?.file?.name.split(".").pop();
          const imgName = `${getTimestamp(userId, i)}.${extension}`;
          const key = `image_data/org_${orgId}/workorder/${rowData.wo_id}/${imgName}`;

          imagesString += `${imgName}:::`;

          imagesToS3.push({
            ...img,
            key: key,
          });
        }
      }
    });
    imagesString = imagesString.slice(0, imagesString.length - 3);

    const sendEmail = getSendEmail();

    let issueIds = "";
    if (issues.length > 0) {
      issues.forEach((issue) => {
        issueIds += `${issue.issue_id}:::`;
      });
      issueIds = issueIds.slice(0, imagesString.length - 3);
    }

    let odoConverted = 0;
    if (+odometer) {
      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );

      odoConverted = +odometer * distanceFactor;
    }

    //Logs
    let stringifiedLogs = "";

    //For old records, logs should remain as null
    if (rowData?.logs === null) {
      stringifiedLogs = null;
    } else {
      let logsData = [];
      if (logs.length > 0) {
        logsData = [...logs];
      }

      //Logs for Accept
      if (mode === "accept") {
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: "",
          user: userName,
          user_id: userId,
          log_type: 2,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });

        setLogs((prev) => {
          let temp = [...prev];
          temp.unshift({
            log_id: `${userId}_${Date.now()}`,
            time_stamp: getUnixTime(new Date()),
            message: "",
            user: userName,
            user_id: userId,
            log_type: 2,
            linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
          });
          return temp;
        });
      }

      //Logs for Pause
      if (workOrderStatus == 2 && reason) {
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: reason,
          user: userName,
          user_id: userId,
          log_type: 3,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });
      }

      //Logs for Resume
      if (mode === "resume") {
        //const incompletePause = pauses.find((p) => !p.end_time);
        //const reason = incompletePause?.reason;
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: "",
          user: userName,
          user_id: userId,
          log_type: 4,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });

        setLogs((prev) => {
          let temp = [...prev];
          temp.unshift({
            log_id: `${userId}_${Date.now()}`,
            time_stamp: getUnixTime(new Date()),
            message: "",
            user: userName,
            user_id: userId,
            log_type: 4,
            linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
          });
          return temp;
        });
      }

      //Logs for Close
      if (workOrderStatus == 3) {
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: "",
          user: userName,
          user_id: userId,
          log_type: 5,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });
      }

      if (logsData.length > 0) {
        const logsApiData = logsData.map((log) => ({
          log_id: log.log_id,
          user_id: log.user_id,
          message: log.message,
          time_stamp: log.time_stamp,
          log_type: log.log_type,
          linkedIssues: log?.linkedIssues ? log?.linkedIssues : [],
        }));

        if (logsApiData.length > 0) {
          stringifiedLogs = JSON.stringify(logsApiData);
        }
      }
    }

    const apiData = {
      action: 2,
      sync_version: "v2",
      source: "web",
      operation: "update",
      querystring: "addEditWorkOrder",
      wo_id: rowData.wo_id,
      org_id: orgId,
      veh_id: vehicle.vehicleId,
      odo: odometer === "" ? -1000 : odoConverted,
      wo_number: rowData.wo_number,
      type: scheduledWorkOrder ? 0 : 1,
      status: workOrderStatus,
      priority: priority.value,
      create_date: rowData.create_date,
      created_by: createdBy.user_id,
      due_selection: dueIn ? dueIn.value : 0,
      due_date: dueIn ? dueDate : 0,
      due_date_formatted: dueIn ? dueDateFormatted : "",
      po_number: purchaseOrder ? purchaseOrder : "",
      invoice_number: invoiceNo ? invoiceNo : "",
      start_date: startDate, //getUnixTime(startedOn),
      end_date: endDate,
      //labor_cost: laborCostData,
      //parts_cost: partsCostData,
      // total_cost: {
      //   parts_total: partsCostTotal ? +partsCostTotal : 0,
      //   labor_cost_total: laborCostTotal ? +laborCostTotal : 0,
      //   total: total ? +total : 0,
      // },
      //service_task_ids: serviceTaskIds,
      parts_total: partsCostTotal ? partsCostTotal : 0,
      labor_total: laborCostTotal ? laborCostTotal : 0,
      total: total ? total : 0,
      service_id: serviceId,
      tax: tax ? +tax : 0,
      discount: discount ? +discount : 0,
      wo_img_name: imagesString,
      comments: comments ? comments : "",
      //total_time: 0,
      send_email: sendEmail,
      technician_id: technician ? technician.user_id : "",
      issue_ids: issueIds,
      total_time: totalTime,
      logs: stringifiedLogs,
      odo_validation_date: odometer ? odoValidationDate.current : 0,
    };

    const response = await postApi(apiData, "workOrder");

    if (response.success === 1) {
      //Service Task Details - Start
      let serviceTaskDetails = [];
      if (serviceTasksRef.current.length > 0 && workOrderStatus !== 3) {
        //Dont call details api when closing WO since it has to be called after add service
        //api is called.Hence checking workOrderStatus !== 3
        let service_id = rowData?.service_id;
        if (serviceId) {
          service_id = serviceId;
        }
        for (let task of serviceTasksRef.current) {
          const {
            partsCost,
            laborCost,
            totalCost,
            service_task_id,
            service_task_detail_id,
            action,
          } = task;

          serviceTaskDetails.push({
            service_task_detail_id: service_task_detail_id,
            service_task_id: service_task_id,
            service_id: service_id || null,
            wo_id: rowData.wo_id,
            parts_cost: partsCost ? +partsCost : 0,
            labor_cost: laborCost ? +laborCost : 0,
            total_cost: totalCost ? +totalCost : 0,
            action: action,
          });
        }

        const serviceTaskDetailsApiData = {
          source: "web",
          sync_version: "v2",
          operation: type === "edit" ? "update" : "create",
          querystring: "addEditServiceTaskDetails",
          service_task_details: serviceTaskDetails,
        };

        const serviceTaskDetailsApiRes = await postApi(
          serviceTaskDetailsApiData,
          "workOrder"
        );
      }

      //Update rowData if WO is accepted or resumed since user will remain in form
      //in these two scenarios and not be taken back to listing
      if (mode && (mode === "accept" || mode === "resume")) {
        const singleWORes = await postApi(
          {
            querystring: "fetchSingleWoData",
            wo_id: routeData?.rowData?.wo_id,
          },
          "workOrder"
        );
        if (singleWORes.success) {
          const { wo_data, service_details_data } = singleWORes;
          const singleWO = wo_data;
          setRowData(singleWO);

          //Service Task Details Edit
          if (service_details_data?.length > 0) {
            let serviceTaskDetails = [];
            for (let task of service_details_data) {
              const {
                service_task_detail_id,
                service_task_id,
                service_id,
                wo_id,
                labor_cost,
                parts_cost,
                total_cost,
                service_task_name,
              } = task;
              serviceTaskDetails.push({
                service_task_detail_id,
                service_task_id,
                service_id,
                service_task_name,
                wo_id,
                laborCost: +labor_cost ? +labor_cost : "",
                partsCost: +parts_cost ? +parts_cost : "",
                totalCost: +total_cost ? +total_cost : "",
                action: 2,
                label: service_task_name,
                value: service_task_id,
              });
            }
            //setServiceTasks(serviceTaskDetails); //No need to update serviceTasks as it will remain the same
            serviceTasksRef.current = serviceTaskDetails;
          }
        }
      }

      if (imagesToS3?.length > 0) {
        const statusArr = [];
        for (let img of imagesToS3) {
          if (img.file) {
            const extension = img.file.name.split(".").pop();
            let imgName = `${getTimestamp(userId)}.${extension}`;
            let key = `image_data/org_${orgId}/workorder/${rowData.wo_id}/${imgName}`;
            //uploadToS3Arr.push({ key: key, file: img.file });
            const status = await uploadImgToS3(img);
            statusArr.push(status);
            if (statusArr.length === imagesToS3.length) {
              const imgUploadSuccess = statusArr.every(
                (status) => status === 200
              );

              return imgUploadSuccess;
            }
          }
        }
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  };

  const handleEditIconClick = () => {
    setShowTimeToCompletePopup(true);
  };

  const onSubmit = async () => {
    try {
      setSaveBtnLoading(true);
      if (
        odometer !== "" &&
        +odometer >= 0 &&
        (+rowData?.odo !== +odometer || !rowData?.odo_validation_date)
      ) {
        const odoValRes = await handleOdoValidation(setSaveBtnLoading);
        if (!odoValRes) {
          return;
        }
      }

      const workOrderId =
        type === "edit" ? rowData.wo_id : `${vehicle.vehicleId}_${Date.now()}`;

      let dueDate = "";
      let dueDateFormatted = "";
      if (dueIn) {
        dueDate =
          dueIn.value === 7 ? getUnixTime(dueOnDate) : getUnixTime(dueOn.value);
        let dateToFormat = dueIn.value === 7 ? dueOnDate : dueOn.value;
        dueDateFormatted = format(dateToFormat, "MMMM dd, yyyy hh:mm a");
      }

      let serviceTaskIds = [];
      let partsCostData = {};
      let laborCostData = {};

      if (serviceTasks?.length > 0) {
        serviceTasks?.forEach((t) => {
          serviceTaskIds.push(t.service_task_id);
          partsCostData[t.service_task_id] = t.partsCost ? t.partsCost : 0;
          laborCostData[t.service_task_id] = t.laborCost ? t.laborCost : 0;
        });
      }

      //Logic to get generate imagesToS3 array and imagesString
      let imagesToS3 = [];

      let imagesString = "";

      workOrderImgs?.forEach((img, i) => {
        if (img?.hasOwnProperty("existing")) {
          imagesString += `${img?.imgName}:::`;
        } else {
          if (img.file) {
            const extension = img?.file?.name.split(".").pop();
            const imgName = `${getTimestamp(userId, i)}.${extension}`;
            const key = `image_data/org_${orgId}/workorder/${workOrderId}/${imgName}`;

            imagesString += `${imgName}:::`;

            imagesToS3.push({
              ...img,
              key: key,
            });
            // return {
            //   ...img,
            //   key: key,
            // };
          }
        }
      });
      imagesString = imagesString.slice(0, imagesString.length - 3);

      let startDate = startedOn ? getUnixTime(startedOn) : 0;
      let endDate = rowData?.end_date ? rowData.end_date : 0;

      const sendEmail = getSendEmail();

      let issueIds = "";
      if (issues.length > 0) {
        issues.forEach((issue) => {
          issueIds += `${issue.issue_id}:::`;
        });
        issueIds = issueIds.slice(0, imagesString.length - 3);
      }

      // (async () => {
      let odoConverted = 0;
      if (+odometer) {
        const distanceFactor = getDistanceFactorforOdo(
          vehicle?.primary_meter,
          distance
        );

        odoConverted = +odometer * distanceFactor;
      }

      let totalTime =
        type === "edit" && workOrderStatus === 3 && customTotalTime?.seconds
          ? customTotalTime.seconds
          : 0;

      //logs
      let stringifiedLogs = "";

      if (type === "edit" && rowData?.logs === null) {
        stringifiedLogs = null;
      } else {
        let logsArr = [];
        if (logs.length > 0) {
          logsArr = [...logs];
        }

        //Add WO created log when work order is created
        if (type !== "edit") {
          logsArr.unshift({
            log_id: `${userId}_${Date.now()}`,
            time_stamp: getUnixTime(new Date()),
            message: "",
            user: userName,
            user_id: userId,
            log_type: 1,
            linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
          });
        }

        if (logsArr.length > 0) {
          const logsApiData = logsArr.map((log) => ({
            log_id: log.log_id,
            user_id: log.user_id,
            message: log.message,
            time_stamp: log.time_stamp,
            log_type: log.log_type,
            linkedIssues: log?.linkedIssues ? log?.linkedIssues : [],
          }));

          if (logsApiData.length > 0) {
            stringifiedLogs = JSON.stringify(logsApiData);
          }
        }
      }

      const apiData = {
        action: type === "edit" ? 2 : 1,
        sync_version: "v2",
        source: "web",
        operation: type === "edit" ? "update" : "create",
        querystring: "addEditWorkOrder",
        wo_id: workOrderId,
        org_id: orgId,
        veh_id: vehicle.vehicleId,
        odo: odometer === "" ? -1000 : odoConverted,
        wo_number: type === "edit" ? rowData.wo_number : workOrderNo,
        type: scheduledWorkOrder ? 0 : 1,
        status: type === "edit" ? workOrderStatus : 0,
        priority: priority.value,
        create_date:
          type === "edit" ? rowData.create_date : getUnixTime(new Date()),
        created_by: createdBy.user_id,
        due_selection: dueIn ? dueIn.value : 0,
        due_date: dueIn ? dueDate : 0,
        due_date_formatted: dueIn ? dueDateFormatted : "",
        po_number: purchaseOrder ? purchaseOrder : "",
        invoice_number: invoiceNo ? invoiceNo : "",
        start_date: startDate,
        end_date: endDate,
        // labor_cost: laborCostData,
        // parts_cost: partsCostData,
        // total_cost: {
        //   parts_total: partsCostTotal ? partsCostTotal : 0,
        //   labor_cost_total: laborCostTotal ? laborCostTotal : 0,
        //   total: total ? total : 0,
        // },
        //service_task_ids: serviceTaskIds,
        parts_total: partsCostTotal ? partsCostTotal : 0,
        labor_total: laborCostTotal ? laborCostTotal : 0,
        total: total ? total : 0,
        service_id: type === "edit" ? rowData.service_id : "",
        tax: tax ? +tax : 0,
        discount: discount ? +discount : 0,
        wo_img_name: imagesString,
        comments: comments ? comments : "",
        total_time: totalTime,
        send_email: sendEmail,
        technician_id: technician ? technician.user_id : "",
        issue_ids: issueIds,
        logs: stringifiedLogs,
        odo_validation_date: odometer ? odoValidationDate.current : 0,
      };

      const response = await postApi(apiData, "workOrder");

      if (!response?.success) {
        setShowErrPopup(true);
        setSaveBtnLoading(false);
        return;
      }

      if (response.success === 1) {
        //Service Task Details - Start
        if (serviceTasksRef.current.length > 0) {
          let serviceTaskDetails = [];
          let serviceId = rowData?.service_id;

          //let count = 1;
          for (let task of serviceTasksRef.current) {
            const {
              partsCost,
              laborCost,
              totalCost,
              service_task_id,
              service_task_detail_id,
              action,
            } = task;
            // let serviceTaskDetailId = `${vehicle?.vehicleId}_${
            //   Date.now() + count
            // }`;
            // let action = 2;

            // if (task.hasOwnProperty("service_task_detail_id")) {
            //   serviceTaskDetailId = task.service_task_detail_id;
            // }
            // if (task.hasOwnProperty("action")) {
            //   action = task.action;
            // }

            serviceTaskDetails.push({
              service_task_detail_id: service_task_detail_id,
              service_task_id: service_task_id,
              service_id: serviceId || null,
              wo_id: workOrderId,
              parts_cost: partsCost ? +partsCost : 0,
              labor_cost: laborCost ? +laborCost : 0,
              total_cost: totalCost ? +totalCost : 0,
              action: action,
            });
            //count += 1;
          }

          const serviceTaskDetailsApiData = {
            source: "web",
            sync_version: "v2",
            operation: type === "edit" ? "update" : "create",
            querystring: "addEditServiceTaskDetails",
            service_task_details: serviceTaskDetails,
          };

          const serviceTaskDetailsApiRes = await postApi(
            serviceTaskDetailsApiData,
            "workOrder"
          );

          if (!serviceTaskDetailsApiRes?.success) {
            setShowErrPopup(true);
            setSaveBtnLoading(false);
            return;
          }
        }

        // if (serviceTaskDetailsApiRes.success) {
        //Check if associated service still exists
        if (serviceAction.current !== 3) {
          //Update Services Table if laborCost, partsCost, tax, discount, total_time have changed
          if (
            workOrderStatus === 3 &&
            (serviceTasksChanged ||
              +rowData.tax !== +tax ||
              +rowData.discount !== +discount ||
              +rowData.total_time !== totalTime)
          ) {
            // const serviceTaskIdsString = serviceTaskIds
            //   .map((id) => `'${id}'`)
            //   .join();
            // const fetchServiceApiData = {
            //   service_id: rowData.service_id,
            //   veh_id: rowData.veh_id,
            //   service_task_ids: serviceTaskIdsString,
            //   querystring: "getsingleservice",
            // };

            const singleServiceApiData = {
              querystring: "fetchSingleServiceData",
              service_id: rowData?.service_id,
            };
            const singleServiceRes = await postApi(
              singleServiceApiData,
              "workOrder"
            );
            // const singleServiceRes = await postApi(
            //   fetchServiceApiData,
            //   "commonNew"
            // );
            if (singleServiceRes.success === 1) {
              const singleServiceData = singleServiceRes?.service_data;
              const serviceApiData = {
                querystring: "addEditService",
                action: 2,
                source: "web",
                operation: "update",
                sync_version: "v2",
                service_id: singleServiceData.service_id,
                veh_id: singleServiceData.veh_id,
                user_id: singleServiceData.user_id,
                service_date: singleServiceData.service_date,
                odo: singleServiceData.odo,
                odo_unit: singleServiceData.odo_unit,
                currency: singleServiceData.currency,
                //labor_cost: laborCostData,
                //parts_cost: partsCostData,
                parts_total: partsCostTotal ? partsCostTotal : 0,
                labor_total: laborCostTotal ? laborCostTotal : 0,
                total: total ? total : 0,
                tax: tax ? +tax : 0,
                discount: discount ? +discount : 0,
                // total_cost: {
                //   parts_total: partsCostTotal,
                //   labor_cost_total: laborCostTotal,
                //   total: total,
                // },
                //service_task_ids: serviceTaskIds,
                operator_userid: singleServiceData.serviced_by,
                receipt_img_name: singleServiceData.receipt_img_name,
                comments: singleServiceData.comments,
                card_number: singleServiceData.card_number
                  ? singleServiceData.card_number
                  : 0,
                service_station: singleServiceData.service_station
                  ? singleServiceData.service_station
                  : "",
                transaction_type: singleServiceData.transaction_type
                  ? singleServiceData.transaction_type
                  : "Cash",
                total_time: totalTime,
              };

              const serviceResponse = await postApi(
                serviceApiData,
                "workOrder"
              );
            }
          }
        }

        const snackbarMsg =
          type === "edit"
            ? t("record_edited_msg", { recordName: t("work_order") })
            : t("record_added_msg", { recordName: t("work_order") });
        //Upload Images
        if (imagesToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imagesToS3);
          if (docsUploaded) {
            dispatch(addMsg(snackbarMsg));
            history.push("/workorders", { fromCancel: false });

            setSaveBtnLoading(false);
          }
        } else {
          dispatch(addMsg(snackbarMsg));
          history.push("/workorders", { fromCancel: false });
          setSaveBtnLoading(false);
        }
        // }
      }
    } catch (e) {
      setShowErrPopup(true);
      setSaveBtnLoading(false);
      return;
    }
    // })();
  };

  return (
    <>
      {vehicleUpdErr && (
        <InfoPopup
          message={vehicleUpdErrData.message}
          icon={vehicleUpdErrData.icon}
          showPopup={vehicleUpdErr}
          setShowPopup={setVehicleUpdErr}
        />
      )}
      {showIssueImgsModal && (
        <ZoomModal
          showModal={showIssueImgsModal}
          setShowModal={setShowIssueImgsModal}
          docs={issueImgs}
        />
      )}
      {showSnackbar && (
        <Snackbar
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          message={snackbarMsg}
        />
      )}
      {showAddIssueModal && (
        <AddIssueModal
          showAddIssueModal={showAddIssueModal}
          setShowAddIssueModal={setShowAddIssueModal}
          issuesList={issuesList}
          issues={issues}
          setIssues={setIssues}
        />
      )}
      {showTechPopup && (
        <SelectTechnicianPopup
          showPopup={showTechPopup}
          setShowPopup={setShowTechPopup}
          technician={technician}
          setTechnician={setTechnician}
          techniciansList={users?.filter((u) => u.role_id === 4)}
        />
      )}

      {showPauseConfirmPopup && (
        <PauseConfirmPopup
          showPopup={showPauseConfirmPopup}
          setShowPopup={setShowPauseConfirmPopup}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          handlePauseClick={handlePauseClick}
          pauseBtnLoading={pauseBtnLoading}
        />
      )}

      {showActionPopup && (
        <ActionPopup
          showPopup={showActionPopup}
          setShowPopup={setShowActionPopup}
          message={t("is_veh_back_in_service", { vehicleName: vehicle.label })}
          handleYes={handleYes}
          handleNo={handleNo}
          yesBtnLoading={yesBtnLoading}
          noBtnLoading={noBtnLoading}
        />
      )}

      {showTimeToCompletePopup && (
        <CustomTimeToCompletePopup
          showPopup={showTimeToCompletePopup}
          setShowPopup={setShowTimeToCompletePopup}
          customTotalTime={customTotalTime}
          setCustomTotalTime={setCustomTotalTime}
        />
      )}

      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}

      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <WorkOrderFormHeader
          handleCancel={() => {
            if (
              mode &&
              (mode === "from-add-issue" || mode === "from-issues-table")
            ) {
              history.push("/issues", { fromCancel: true });
            } else {
              let fromCancel = true;
              if (woMode && (woMode === "accepted" || woMode === "resumed")) {
                fromCancel = false;
              }
              history.push("/workorders", { fromCancel });
            }
          }}
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_work_order") : t("add_work_order")}
          rowData={rowData}
          workOrderStatus={workOrderStatus}
          handleAcceptWOClick={handleAcceptWOClick}
          showPauseConfirmPopup={showPauseConfirmPopup}
          setShowPauseConfirmPopup={setShowPauseConfirmPopup}
          handlePauseWOClick={handlePauseWOClick}
          handleResumeWOClick={handleResumeWOClick}
          handleCloseWOClick={handleCloseWOClick}
          pauses={pauses}
          acceptWOBtnLoading={acceptWOBtnLoading}
          resumeWOBtnLoading={resumeWOBtnLoading}
          printFunc={printFunc}
        />
        <Container>
          <Row>
            <Col md={6} style={{ paddingRight: 0 }}>
              <Card>
                <CardBody
                  style={{
                    height: `${window.innerHeight - 150}px`,
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "0.5rem 0",
                  }}
                >
                  <Row className="px-2">
                    <Col>
                      <FormGroupLabel>{t("work_order_no")}</FormGroupLabel>
                      <div
                        style={{ height: "34px" }}
                        className="d-flex align-items-center"
                      >
                        <p>{workOrderNo}</p>
                      </div>
                    </Col>

                    <Col>
                      <FormGroupLabel>{t("purchase_order_no")}</FormGroupLabel>
                      <FormItem
                        disabled={roleId === 4 || workOrderStatus === 3}
                        //tabIndex={0}
                      >
                        <FormField
                          name="purchaseOrder"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("purchase_order_no")}
                          style={formInputStyles}
                          disabled={
                            (type === "edit" && roleId === 4) ||
                            workOrderStatus === 3
                          }
                          //onKeyUp={handleSIOdoChange}
                        />
                        {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                      </FormItem>

                      {errors?.purchaseOrder && (
                        <Error error={errors.purchaseOrder.message} />
                      )}
                    </Col>
                  </Row>

                  <Row className="pt-4 pb-2">
                    <hr className="p-0 m-0" />
                  </Row>
                  <Row className="px-2">
                    <Col>
                      <FormGroupLabel>{t("technician")}</FormGroupLabel>
                      <FormValueContainer>
                        {!technician && workOrderStatus === 3 && (
                          <p className="p-0 m-0">{NATEXT}</p>
                        )}
                        {technician && (
                          <p style={{ marginRight: "0.5rem" }}>
                            {selectedTechnicianText}
                          </p>
                        )}
                        {roleId !== 4 && workOrderStatus !== 3 && (
                          <FilterButton
                            style={{ display: "block" }}
                            type="button"
                            onClick={() => setShowTechPopup(true)}
                          >
                            {technician ? t("change") : t("select")}
                          </FilterButton>
                        )}
                      </FormValueContainer>
                    </Col>
                  </Row>

                  <Row className="px-2">
                    <Col>
                      <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            isDisabled={
                              (type === "edit" && roleId === 4) ||
                              (workOrderStatus !== 0 &&
                                workOrderStatus !== "new")
                            }
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col>
                      {/* Odometer */}

                      <Col>
                        <FormGroupLabel>{t("odometer")}</FormGroupLabel>
                        <FormItem
                          disabled={workOrderStatus === 3}
                          showWarning={showHighOdoErr}
                          //tabIndex={0}
                        >
                          <FormField
                            name="odometer"
                            control={control}
                            component="input"
                            type="number"
                            defaultValue=""
                            onKeyDown={handleNegative}
                            onWheel={handleNumberInputOnWheelPreventChange}
                            placeholder={t("odometer")}
                            onChange={(e) => {
                              clearErrors("odometer");
                              setValue("odometer", e.target.value);
                              if (odoFetchedMsg) {
                                setOdoFetchedMsg("");
                              }
                            }}
                            style={formInputStyles}
                            disabled={workOrderStatus === 3}
                            onKeyUp={(e) => {
                              const { value } = e.target;
                              if (
                                value &&
                                lastOdoValue &&
                                +value - +lastOdoValue > 1000
                              ) {
                                setShowHighOdoErr(true);
                              } else {
                                setShowHighOdoErr(false);
                              }
                            }}
                          />
                          {odoUnit && <UnitSpan>{odoUnit}</UnitSpan>}
                          {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                        </FormItem>

                        {/* {errors?.odometer && (
                          <Error error={errors.odometer.message} />
                        )} */}
                        <HintText>{odoHint}</HintText>
                        {odoFetchedMsg && <HintText>{odoFetchedMsg}</HintText>}
                        {showHighOdoErr && (
                          <Error error={t("high_odo_err_msg")} />
                        )}
                        {errors?.odometer && (
                          <Error error={errors.odometer.message} />
                        )}
                        {/* {errors?.odometer ? (
                          <Error error={errors.odometer.message} />
                        ) : (
                          showHighOdoErr && (
                            <Error error={t("high_odo_err_msg")} />
                          )
                        )} */}
                      </Col>
                    </Col>
                  </Row>

                  <Row className="px-2">
                    <Col md={6}>
                      {/* Priority */}
                      <FormGroupLabel>
                        {t("work_order_priority")}
                      </FormGroupLabel>

                      <Controller
                        name="priority"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={prioritiesOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("priority_placeholder")}
                            styles={formStyles}
                            isDisabled={
                              (type === "edit" && roleId === 4) ||
                              workOrderStatus === 3
                            }
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.priority && (
                        <Error error={errors.priority.message} />
                      )}
                    </Col>
                  </Row>

                  <Row className="px-2">
                    <Col>
                      <FormGroupLabel>{t("created_on")}</FormGroupLabel>
                      <FormValueContainer>
                        <p>
                          {type === "edit" && createdOn
                            ? getDisplayDateWithTimeNew(getUnixTime(createdOn))
                            : NATEXT}
                        </p>
                      </FormValueContainer>
                    </Col>

                    <Col>
                      <FormGroupLabel>{t("created_by")}</FormGroupLabel>
                      <FormValueContainer>
                        <p>{createdBy?.label}</p>
                      </FormValueContainer>
                    </Col>
                  </Row>

                  <Row className="px-2">
                    <Col>
                      <FormGroupLabel>{t("due_in")}</FormGroupLabel>
                      <Controller
                        name="dueIn"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={dueInOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("due_in_placeholder")}
                            styles={formStyles}
                            isDisabled={
                              (type === "edit" && roleId === 4) ||
                              workOrderStatus === 3
                            }
                          />
                        )}
                        //rules={{ required: t("required_err") }}
                      />

                      {errors?.dueIn && <Error error={errors.dueIn.message} />}
                    </Col>

                    <Col>
                      <FormGroupLabel>{t("due_on")}</FormGroupLabel>
                      {dueIn?.value === 7 ? (
                        <Controller
                          name="dueOn"
                          defaultValue={new Date()}
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <DatePicker
                              id="datepicker"
                              locale={getDateFnsLocale()}
                              dateFormat={"P"}
                              showMonthDropdown
                              showYearDropdown
                              //minDate={new Date()}
                              selected={value}
                              onChange={onChange}
                              customInput={<CustomInput disabled={false} />}
                              placeholderText={t("date_tv")}
                              disabled={
                                (type === "edit" && roleId === 4) ||
                                workOrderStatus === 3
                              }
                            />
                          )}
                          // rules={{
                          //   required: t("required_err"),
                          // }}
                        />
                      ) : (
                        <FormValueContainer>
                          {type === "edit" ? (
                            workOrderStatus === 3 && !dueIn ? (
                              NATEXT
                            ) : (
                              dueOn.label
                            )
                          ) : (
                            <p>{t("will_be_calc_after_saving")}</p>
                          )}
                        </FormValueContainer>
                      )}
                    </Col>
                  </Row>
                  <hr className="my-3" />

                  <Row className="px-2">
                    <Col>
                      {/* <FormGroupLabel
                        style={{ height: "0.75rem" }}
                      ></FormGroupLabel> */}
                      <FormValueContainer>
                        <CheckBoxInput
                          type="checkbox"
                          id="scheduledWorkOrder"
                          name="scheduledWorkOrder"
                          value="scheduledWorkOrder"
                          checked={scheduledWorkOrder}
                          onChange={() =>
                            setScheduledWorkOrder((prev) => !prev)
                          }
                          style={{ marginRight: "0.5rem" }}
                          disabled={
                            (type === "edit" && roleId === 4) ||
                            workOrderStatus === 3 ||
                            rowData?.reminder_name
                          }
                        />
                        <Label
                          htmlFor="scheduledWorkOrder"
                          disabled={
                            (type === "edit" && roleId === 4) ||
                            workOrderStatus === 3 ||
                            rowData?.reminder_name
                          }
                        >
                          {t("scheduled_work_order_label")}
                        </Label>
                      </FormValueContainer>
                    </Col>
                  </Row>

                  {rowData?.reminder_name && (
                    <Row className="px-2 pt-1">
                      <Col className="d-flex flex-column gap-2">
                        <p>{t("reminders_assoc_with_wo")}</p>
                        <Row>
                          <Col>
                            <FormGroupLabel className="mt-1">
                              {t("reminder_name")}
                            </FormGroupLabel>
                          </Col>
                          <Col>
                            <FormGroupLabel className="mt-1">
                              {t("instructions")}
                            </FormGroupLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>{rowData?.reminder_name}</p>
                          </Col>
                          <Col>
                            <p>
                              {rowData?.reminder_instructions
                                ? rowData?.reminder_instructions
                                : NATEXT}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {/* <Row className="pt-4 pb-2"> */}
                  <hr className="mt-3 mb-3" />
                  {/* </Row> */}

                  <Row className="px-2">
                    <Col className="d-flex flex-column gap-2">
                      <p>{t("issues_assoc_with_wo")}</p>
                      {issues.length > 0 && (
                        <div>
                          <Row>
                            <Col md={4}>
                              <FormGroupLabel>{t("issue_name")}</FormGroupLabel>
                            </Col>
                            <Col md={6}>
                              <FormGroupLabel>{t("issue_desc")}</FormGroupLabel>
                            </Col>
                            <Col md={1}>
                              <FormGroupLabel>{t("images")}</FormGroupLabel>
                            </Col>
                            <Col md={1}></Col>
                          </Row>
                          {issues.map((issue) => {
                            return (
                              <Row key={issue.issue_id} className="py-2">
                                <Col md={4}>
                                  <p>{issue.issue}</p>
                                </Col>
                                <Col md={6}>
                                  <p>
                                    {issue.issue_desc
                                      ? issue.issue_desc
                                      : NATEXT}
                                  </p>
                                </Col>
                                <Col md={1}>
                                  {issue?.images?.length > 0 && (
                                    <ActionIconContainer
                                      onClick={() => handleissueImgClick(issue)}
                                    >
                                      <i className="fi fi-rr-picture"></i>
                                    </ActionIconContainer>
                                  )}
                                </Col>
                                <Col md={1}>
                                  {roleId !== 4 &&
                                    (workOrderStatus === 0 ||
                                      workOrderStatus === "new") && (
                                      <ActionIconContainer
                                        onClick={() =>
                                          handleIssueRemove(issue.issue_id)
                                        }
                                      >
                                        <i className="fi fi-rr-cross-small" />
                                      </ActionIconContainer>
                                    )}
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      )}
                      {issues.length === 0 &&
                        workOrderStatus !== 0 &&
                        workOrderStatus !== "new" && <div>{NATEXT}</div>}
                      {roleId !== 4 &&
                        (workOrderStatus === 0 ||
                          workOrderStatus === "new") && (
                          <AddButton
                            handleClick={() => setShowAddIssueModal(true)}
                          />
                        )}
                    </Col>
                  </Row>

                  {/* <Row className="pt-4 pb-2"> */}
                  <hr className="mb-0 mt-3" />
                  {/* </Row> */}

                  <Row className="px-2 pb-3">
                    <Col>
                      <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                      <FormItem
                      //disabled={status == 2}
                      //tabIndex={0}
                      >
                        <FormField
                          name="comments"
                          control={control}
                          component="input"
                          defaultValue=""
                          //isAboveError={isAboveError}
                          placeholder={t("notes_tv")}
                          style={formInputStyles}
                          //disabled={roleId === 4}
                          //onKeyUp={handleSIOdoChange}
                        />
                        {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                      </FormItem>

                      {errors?.comments && (
                        <Error error={errors.comments.message} />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <CardBody
                  style={{
                    height: `${window.innerHeight - 150}px`,
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "0.5rem 0",
                  }}
                >
                  <Row className="px-2">
                    <Col className="d-flex gap-2 align-items-center mt-3">
                      <FormGroupLabel className="m-0">
                        {t("work_order_status")}
                      </FormGroupLabel>
                      <div>
                        <WorkOrderStatus status={workOrderStatus} />
                      </div>
                    </Col>

                    {showLogs ? (
                      <>
                        <Col>
                          <FormGroupLabel
                            style={{ height: "0.75rem" }}
                          ></FormGroupLabel>
                          <LinkSpan
                            onClick={() => setShowLogsPopup(true)}
                          >{`${t(t("notes_prog_updates"))} (${logs.length})
                       `}</LinkSpan>
                        </Col>
                        {showLogsPopup && (
                          <LogsPopup
                            showPopup={showLogsPopup}
                            setShowPopup={setShowLogsPopup}
                            logs={logs}
                            setLogs={setLogs}
                            user={createdBy}
                            readOnly={workOrderStatus == 3}
                            assocIssues={issues}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {pauses.length > 0 && (
                          <>
                            <Col>
                              <FormGroupLabel
                                style={{ height: "0.75rem" }}
                              ></FormGroupLabel>
                              <LinkSpan
                                onClick={() => setShowPausesTable(true)}
                              >{`${t("paused")} ${pauses.length} ${
                                pauses.length === 1 ? t("time_tv") : t("times")
                              }`}</LinkSpan>
                            </Col>
                            {showPausesTable && (
                              <PausesTable
                                showPopup={showPausesTable}
                                setShowPopup={setShowPausesTable}
                                pauses={pauses}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Row>

                  {vehicle && (
                    <>
                      {(workOrderStatus === "new" || workOrderStatus === 0) && (
                        <Row className="px-2 py-3">
                          <Col>
                            <Desc>
                              {workOrderStatus === "new"
                                ? t("save_this_work_order_desc")
                                : !technician
                                ? t("open_work_order_tech_not_assigned_desc")
                                : roleId === 4
                                ? t("open_work_order_tech_desc")
                                : t("open_work_order_desc")}
                            </Desc>
                          </Col>
                        </Row>
                      )}

                      {type === "edit" && (
                        <>
                          {startedOn && (
                            <>
                              <Row className="px-2">
                                <Col>
                                  <FormGroupLabel
                                    style={{ marginRight: "0.5rem" }}
                                  >
                                    {t("time_since_acc")}
                                  </FormGroupLabel>
                                  <Tooltip text={t("time_since_acc_tooltip")}>
                                    <Icon className="fi fi-rr-info"></Icon>
                                  </Tooltip>
                                  <FormValueContainer>
                                    {timeSinceAcc.display}
                                    {workOrderStatus === 3 && (
                                      <EditIcon
                                        className="fi fi-rr-pencil"
                                        onClick={handleEditIconClick}
                                      ></EditIcon>
                                    )}
                                  </FormValueContainer>
                                </Col>
                              </Row>
                              <Row className="px-2">
                                <Col>
                                  <FormGroupLabel>
                                    {t("started_on")}
                                  </FormGroupLabel>
                                  <FormValueContainer>
                                    {startedOn &&
                                      getDisplayDateWithTimeNew(
                                        getUnixTime(startedOn)
                                      )}
                                  </FormValueContainer>
                                </Col>
                                {!!rowData?.end_date && (
                                  <>
                                    <Col>
                                      <FormGroupLabel>
                                        {t("end_date")}
                                      </FormGroupLabel>
                                      <FormValueContainer>
                                        {getDisplayDateWithTimeNew(
                                          rowData?.end_date
                                        )}
                                      </FormValueContainer>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                          <Row className="px-2">
                            <Col>
                              <FormGroupLabel>
                                {t("service_tasks")}
                              </FormGroupLabel>
                              <ServiceTasks
                                showAddServiceModal={showAddServiceModal}
                                setShowAddServiceModal={setShowAddServiceModal}
                                serviceTasks={serviceTasks}
                                setServiceTasks={setServiceTasks}
                                discount={discount}
                                setDiscount={setDiscount}
                                tax={tax}
                                setTax={setTax}
                                partsCostTotal={partsCostTotal}
                                setPartsCostTotal={setPartsCostTotal}
                                laborCostTotal={laborCostTotal}
                                setLaborCostTotal={setLaborCostTotal}
                                total={total}
                                setTotal={setTotal}
                                grandTotal={grandTotal}
                                setGrandTotal={setGrandTotal}
                                showServiceTasksError={showServiceTasksError}
                                setShowServiceTasksError={
                                  setShowServiceTasksError
                                }
                                showDiscountError={showDiscountError}
                                workOrderStatus={workOrderStatus}
                                selectedVehicleId={vehicle.vehicleId}
                                serviceTaskOptions={serviceTaskOptions}
                                setServiceTaskOptions={setServiceTaskOptions}
                                serviceTasksRef={serviceTasksRef}
                                serviceTasksChanged={serviceTasksChanged}
                                setServiceTasksChanged={setServiceTasksChanged}
                                vehicle={vehicle}
                              />
                            </Col>
                          </Row>

                          <Row className="px-2">
                            <Col>
                              <FormGroupLabel>
                                {t("images")}
                                {tag === "free-user" && (
                                  <SubscribeTooltipIcon
                                    message={t("subscribe_msg")}
                                  />
                                )}
                              </FormGroupLabel>

                              <FileUploaderNew
                                disabled={false}
                                images={workOrderImgs}
                                setImages={setWorkOrderImgs}
                                shouldDelete={true}
                              />

                              {/* <FileUploaderContainer>
                                {workOrderImgs?.map((img) => {
                                  return (
                                    <ImageWithZoom
                                      key={img.id}
                                      img={img}
                                      //handleDeleteImg={handleDeleteImg}
                                      images={workOrderImgs}
                                      setImages={setWorkOrderImgs}
                                      shouldDelete={true}
                                    />
                                  );
                                })}
                                <FileUploader
                                  disabled={false}
                                  images={workOrderImgs}
                                  setImages={setWorkOrderImgs}
                                />
                              </FileUploaderContainer> */}
                            </Col>
                          </Row>

                          <Row className="px-2 pb-3">
                            <Col md={6}>
                              <FormGroupLabel>{t("invoice_no")}</FormGroupLabel>
                              <FormItem
                              //disabled={status == 2}
                              //tabIndex={0}
                              >
                                <FormField
                                  name="invoiceNo"
                                  control={control}
                                  component="input"
                                  defaultValue=""
                                  //isAboveError={isAboveError}
                                  placeholder={t("invoice_no")}
                                  style={formInputStyles}
                                  //disabled={status == 2}
                                  //onKeyUp={handleSIOdoChange}
                                />
                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.invoiceNo && (
                                <Error error={errors.invoiceNo.message} />
                              )}
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

export default WorkOrderForm;
