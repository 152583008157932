import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { ActionIconContainer, AddFormButton } from "../../../shared/components/TableElements";
import { MultiValueOption } from "../../../components/VehicleInspection/InspectionForms/InspectionFormsForm";
import { openDropdownStyles } from "../../../shared/constants/styles";


const AddIssueModal = ({
  showAddIssueModal,
  setShowAddIssueModal,
  issues,
  setIssues,
  issuesList,
}) => {
  const { t } = useTranslation("common");

  const [selectedTasks, setSelectedTasks] = useState([]);

  //Effect to select tasks based on existing service tasks
  useEffect(() => {
    if (showAddIssueModal) {
      setSelectedTasks(issues);
    }
  }, [showAddIssueModal]);

  useEffect(() => {
    setSelectedTasks(issues);
  }, [issues]);

  const handleOK = () => {
    setIssues(selectedTasks);
    setShowAddIssueModal(false);
  };

  return (
    <Modal
      show={showAddIssueModal}
      onHide={() => setShowAddIssueModal((prev) => !prev)}
    >
      <Modal.Body>
        <div className="d-flex justify-content-end mb-2">
          <ActionIconContainer
            onClick={() => setShowAddIssueModal((prev) => !prev)}
          >
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
        <div
          style={{
            height: `${window.innerHeight - 170}px`,
          }}
        >
          <Select
            options={issuesList}
            value={selectedTasks}
            //value={vehicles.find((c) => c.value === value)}
            //value={serviceTask}
            onChange={setSelectedTasks}
            //onCreateOption={handleCreate}
            placeholder={t("issue_placeholder")}
            styles={openDropdownStyles}
            defaultMenuIsOpen
            isMulti
            menuIsOpen
            isClearable
            isSearchable
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            components={{
              Option: MultiValueOption,
            }}
            //isDisabled={status == 1 || status == 2}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <AddFormButton onClick={handleOK}>{t("ok")}</AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddIssueModal;
