import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false,
    collapse: false,
  };
  
  const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        changeSidebarVisibility: (state, action) => {
            state.collapse = !state.collapse;
        },
        changeMobileSidebarVisibility: (state, action) => {
            state.show = !state.show;
        },
    },
  });
  
  export const { changeMobileSidebarVisibility, changeSidebarVisibility } = sidebarSlice.actions;
  export default sidebarSlice.reducer;


