import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useFormContext } from "react-hook-form";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
} from "../../shared/constants";
import {
  CheckBoxInput,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  Label,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { formInputStyles } from "../../shared/constants/styles";

import Error from "@/shared/components/form/Error";
import {
  FilterButton,
  ActionIconContainer,
} from "../../shared/components/TableElements";
import DocUploader from "../../shared/components/form/DocUploader";

const NewDocumentForm = ({
  doc,
  documents,
  setDocuments,
  type,
  activeKey,
  setActiveKey,
  docUpdated,
  setDocUpdated,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const status = watch("status");

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const [docName, setDocName] = useState("");
  const [docFile, setDocFile] = useState("");
  const [showDocToUser, setShowDocToUser] = useState(true);
  const [fields, setFields] = useState([]);
  //const docName = watch("docName");

  //console.log("fields", fields);

  const [docDeleted, setDocDeleted] = useState("");

  const isDisabled = !status;

  //To populate values on edit
  useEffect(() => {
    if (type === "edit") {
      (async () => {
        const { docName, fields, isVisible, image } = doc;
        const imageFormat = doc?.image_format;
        setDocName(docName);
        setShowDocToUser(isVisible == "1");

        let temp = [];
        if (pathname.includes("users")) {
          fields?.forEach((f, i) => {
            temp.push({
              id: Date.now() + i,
              title: f.title ? f.title : "",
              author: f.author ? f.author : "",
            });
          });
        }
        let count = 0;
        if (pathname.includes("vehicles")) {
          for (let obj of fields) {
            let [key, value] = Object.entries(obj)[0];
            temp.push({
              id: Date.now() + count,
              title: key ? key : "",
              author: value ? value : "",
            });
            count += 1;
          }
        }
        setFields(temp);

        // let imgUrl = "";

        // if (image?.imgFormat === "normal") {
        //   imgUrl = image.url;
        // } else {
        //   imgUrl = await toDataUrl(image.url);
        // }

        //console.log("imgUrl", imgUrl);

        // if (imgUrl) {
        //   if (image?.imgFormat === "normal") {
        //     setDocFile({ ...image, url: imgUrl, imgFormat: "normal" });
        //   } else {
        //     setDocFile({ ...image, url: imgUrl, imgFormat: "existing" });
        //   }
        // } else {
        //   setDocFile("");
        // }

        if (pathname.includes("users")) {
          if (image) {
            if (image.extension === "txt") {
              const imgUrl = await toDataUrl(image.url);
              let ext = image?.type[0];
              setDocFile({ ...image, url: imgUrl, ext });
            } else {
              setDocFile({ ...image, ext: image.extension });
            }
          }
        }

        if (pathname.includes("vehicles")) {
          if (image?.url) {
            setDocFile({ ...image, ext: image.extension });
          } else {
            setDocFile("");
          }
        }
      })();
    }
  }, []);

  useEffect(() => {
    setDocuments((prev) =>
      prev.map((d) => {
        if (d.id === doc.id) {
          return { ...d, isVisible: showDocToUser ? "1" : "0" };
        } else {
          return d;
        }
      })
    );
  }, [showDocToUser]);

  useEffect(() => {
    setDocuments((prev) =>
      prev.map((d) => {
        if (d.id === doc.id) {
          return { ...d, fields: fields };
        } else {
          return d;
        }
      })
    );
  }, [fields]);

  useEffect(() => {
    // if (docFile) {
    //   setDocUpdated(true);
    // }
    setDocuments((prev) => {
      let temp = [...prev];
      temp = temp.map((d) => {
        if (d.id === doc.id) {
          if (docFile) {
            return {
              ...d,
              image: docFile,
              imgUpdated: true,
            };
          } else {
            return {
              ...d,
              image: docFile,
            };
          }
        } else {
          return d;
        }
      });
      return temp;
    });
  }, [docFile]);

  // useEffect(() => {
  //   console.log("documents", documents);
  // }, [documents]);

  const handleAddFields = () => {
    setFields((prev) => {
      const temp = [...prev];
      temp.push({ id: Date.now(), title: "", author: "" });
      return temp;
    });
  };

  const handleDocNameChange = (e) => {
    console.log(e.target.value);
    setDocuments((prev) => {
      let temp = [...prev];
      temp = temp.map((d) => {
        if (d.id === doc.id) {
          return { ...doc, docName: e.target.value };
        } else {
          return d;
        }
      });
      return temp;
    });
  };

  const handleFieldChange = (e, id) => {
    const { name, value } = e.target;
    //console.log(name, value, id);
    setFields((prev) =>
      //let temp = [];
      prev.map((field) => {
        if (field.id === id) {
          return { ...field, [name]: value };
        } else {
          return field;
        }
      })
    );
  };

  const handleRemoveField = (id) => {
    setFields((prev) => prev.filter((field) => field.id !== id));
  };

  const handleDeleteDoc = () => {
    let key = 0;
    setDocuments((prev) => {
      const temp = [...prev];
      temp.forEach((d, id) => {
        if (d.id === doc.id) {
          key = id;
        }
      });
      return temp.filter((d) => d.id !== doc.id);
    });
    setActiveKey(key + 1);
    //setDocDeleted(Date.now());
  };

  return (
    <>
      <Container>
        <Row className="my-3">
          <Col md={6}>
            <FormGroupLabel>{t("doc_name")}</FormGroupLabel>
            <FormItem
              //style={{ width: "438px" }}
              disabled={isDisabled || doc?.existingDoc}
              //tabIndex={0}
            >
              {/* <FormField
                name="docName"
                control={control}
                component="input"
                defaultValue=""
                //isAboveError={isAboveError}
                placeholder={t("doc_name")}
                style={formInputStyles}
                //disabled={status == 2}
                //onKeyUp={handleSIOdoChange}
              /> */}

              <input
                type="text"
                id="doc-name"
                name="docName"
                value={doc.docName}
                //onChange={(e) => setDocName(e.target.value)}
                onChange={handleDocNameChange}
                placeholder={t("doc_name")}
                style={formInputStyles}
                disabled={isDisabled || doc?.existingDoc}
              />
            </FormItem>

            {errors?.docName && <Error error={errors.docName.message} />}
          </Col>
        </Row>

        <Row>
          <Col className="my-1">
            {/* <FormGroupLabel style={{ height: "0.75rem" }}></FormGroupLabel> */}
            <FormValueContainer>
              <Label
                //htmlFor="showDocToUser"
                disabled={isDisabled}
                //onClick={() => setShowDocToUser((prev) => !prev)}
              >
                <CheckBoxInput
                  type="checkbox"
                  id="showDocToUser"
                  name="showDocToUser"
                  //value="showDocToUser"
                  checked={showDocToUser}
                  onChange={(e) => setShowDocToUser((prev) => !prev)}
                  style={{ marginRight: "0.5rem" }}
                  disabled={isDisabled}
                />

                {t("make_doc_available_to_operator")}
              </Label>
            </FormValueContainer>
          </Col>
        </Row>
        <Row>
          <Col className="my-3">
            {/* <FormGroupLabel style={{ height: "0.75rem" }}></FormGroupLabel> */}
            <DocUploader
              docFile={docFile}
              setDocFile={setDocFile}
              documents={documents}
              setDocuments={setDocuments}
              currentDoc={doc}
              isDisabled={isDisabled}
            />
          </Col>
        </Row>
        <div>
          {doc.updatedDate && (
            <Row>
              <Col md={3}>
                <FormGroupLabel className="m-0">
                  {t("updated_date")}
                </FormGroupLabel>
              </Col>
              <Col md={3}>
                <p>{doc.updatedDate}</p>
              </Col>
            </Row>
          )}
          {/* <Row>
            <Col xs={6} className="my-2">
              <hr/>
            </Col>
          </Row> */}
          {fields.length > 0 && (
            <Row>
              <Col md={3}>
                <FormGroupLabel>{t("detail_title")}</FormGroupLabel>
              </Col>
              <Col md={3}>
                <FormGroupLabel>{t("detail")}</FormGroupLabel>
              </Col>
            </Row>
          )}
          {fields?.map((field) => {
            return (
              <React.Fragment key={field.id}>
                <Row className="d-flex align-items-center my-3">
                  <Col xs={3} className="d-flex align-items-center gap-2">
                    <FormItem
                      //style={{ width: "160px" }}
                      disabled={isDisabled}
                      //tabIndex={0}
                    >
                      <input
                        type="text"
                        name="title"
                        id="field-title"
                        value={field.title}
                        style={formInputStyles}
                        onChange={(e) => handleFieldChange(e, field.id)}
                        disabled={isDisabled}
                      />
                    </FormItem>
                  </Col>

                  <Col xs={3} className="d-flex align-items-center gap-2 ">
                    <FormItem
                      //style={{ width: "160px" }}
                      disabled={isDisabled}
                      //tabIndex={0}
                    >
                      <input
                        type="text"
                        name="author"
                        id="field-author"
                        value={field.author}
                        style={formInputStyles}
                        onChange={(e) => handleFieldChange(e, field.id)}
                        disabled={isDisabled}
                      />
                    </FormItem>
                  </Col>
                  {!isDisabled && (
                    <Col>
                      <ActionIconContainer
                        //style={{ fontSize: "18px", top: "1px" }}
                        onClick={() => handleRemoveField(field.id)}
                      >
                        <i
                          className="fi fi-rr-cross-small"
                          //style={{ fontSize: "16px" }}
                        />
                      </ActionIconContainer>
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            );
          })}
        </div>
        {!isDisabled && (
          <>
            <Row>
              <Col className="my-3">
                <FilterButton type="button" onClick={handleAddFields}>
                  + {t("add_details")}
                </FilterButton>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <hr className="m-0" />
              </Col>
            </Row>
            <Row>
              <Col className="my-3">
                <FilterButton type="button" onClick={handleDeleteDoc}>
                  {t("remove_doc")}
                </FilterButton>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default NewDocumentForm;

export const toDataUrl = async (path) => {
  console.log(path);
  try {
    const client = new S3Client({
      region: "us-east-2",
      credentials: fromCognitoIdentityPool({
        identityPoolId: IDENTITY_POOL_ID,
        client: new CognitoIdentityClient({ region: BUCKET_REGION }),
      }),
      apiVersion: "2006-03-01",
    });

    const streamToString = async (stream) =>
      new Promise((resolve, reject) => {
        const chunks = [];
        stream.on("data", (chunk) => chunks.push(chunk));
        stream.on("error", reject);
        stream.on("end", () => resolve(Buffer.concat(chunks).toString("utf8")));
      });

    const command = new GetObjectCommand({
      Bucket: ALBUM_BUCKET_NAME,
      Key: path,
    });

    const { Body } = await client.send(command);

    let b64Url = await new Response(Body, {}).text();
    const [fileType, base64Code] = b64Url.split(",");
    console.log("fileType", fileType);

    //b64ToBlob
    var sliceSize = 1024;
    var byteCharacters = window.atob(base64Code);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const blob = new Blob(byteArrays, {
      type: fileType.split(";")[0].split(":")[1],
    });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } catch (e) {
    //console.log(e);
    return false;
  }
};
