import React from "react";
import { Route, Switch } from "react-router-dom";
import ExpensesTable from "./ExpensesTable";
import ExpensesTasksTable from "./ExpensesTasksTable";
import ExpensesForm from "./ExpensesForm";
import ExpenseTasksForm from "./ExpenseTasksForm";

export default () => (
  <Switch>
    <Route exact path="/expenses/expenses" component={ExpensesTable} />
    <Route exact path="/expenses/expensetasks" component={ExpensesTasksTable} />
    <Route exact path="/expenses/expenses/addnew" component={ExpensesForm} />
    <Route
      exact
      path="/expenses/expensetasks/addnew"
      component={ExpenseTasksForm}
    />
  </Switch>
);
