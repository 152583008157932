import React from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { colorBlue, colorLightGray, colorTextNew } from "@/utils/palette";
import { Button } from "../../../Button";
import { colorBlueHover, colorRed } from "../../../../../utils/palette";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addAdvancedFilter } from "../../../../../features/advancedFilter/advancedFilterSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleNegative } from "../../../../helpers";

const RangeFilter = ({ commonProps, top, data }) => {
  const {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    setLocalFilters,
  } = commonProps;

  const [firstInputFlag, setFirstInputFlag] = useState(false);
  const [secondInputFlag, setSecondInputFlag] = useState(false);
  const { t } = useTranslation("common");

  // useEffect(() => {
  //   const currentFilterCol = appliedFilters.find(
  //     (col) => col.column === data.column
  //   );
  //   if (currentFilterCol && currentFilterCol?.input_1) {
  //     setFilterData((prev) => {
  //       return { ...prev, [currentFilterCol.column]: { ...currentFilterCol } };
  //     });
  //   }
  // }, [appliedFilters]);

  // //Logic to show and hide filter icon in advanced filter pop-up and changing radioName on change of radioValue
  // useEffect(() => {
  //   if (data.radioValue === "between") {
  //     if (data.input_1 && data.input_2 && +data.input_2 > +data.input_1) {
  //       setFilterData((prev) => {
  //         const newData = {
  //           ...data,
  //           radioName: t("between"),
  //           isFiltered: true,
  //         };
  //         return { ...prev, [data.column]: newData };
  //       });
  //     } else {
  //       setFilterData((prev) => {
  //         const newData = {
  //           ...data,
  //           radioName: t("between"),
  //           isFiltered: false,
  //         };
  //         return { ...prev, [data.column]: newData };
  //       });
  //     }
  //   } else {
  //     if (data.input_1) {
  //       setFilterData((prev) => {
  //         const newData = {
  //           ...data,
  //           radioName: getPlaceholderText(data.radioValue),
  //           isFiltered: true,
  //         };
  //         return { ...prev, [data.column]: newData };
  //       });
  //     } else {
  //       setFilterData((prev) => {
  //         const newData = {
  //           ...data,
  //           radioName: getPlaceholderText(data.radioValue),
  //           isFiltered: false,
  //         };
  //         return { ...prev, [data.column]: newData };
  //       });
  //     }
  //   }
  // }, [data.radioValue, data.input_1, data.input_2]);

  const [firstInputErrorMsg, setFirstInputErrorMsg] = useState("Required");
  const [secondInputErrorMsg, setSecondInputErrorMsg] = useState("");

  const emptyValueMessage = "Please enter a value!";
  const positiveValueMessage = "Please enter a value greater than 0!";
  const fromGreaterThanToMessage = `'From' value cannot be greater than 'To' value!`;

  // useEffect(() => {
  //   if (data.input_1 < 0) {
  //     setFirstInputFlag(true);
  //     setFirstInputErrorMsg(positiveValueMessage);
  //   } else {
  //     setFirstInputFlag(false);
  //   }
  // }, [data.input_1]);

  const dispatch = useDispatch();

  const getPlaceholderText = (value = data.radioValue) => {
    if (value === "greater-than") {
      return t("greater_than");
    } else if (value === "less-than") {
      return t("less_than");
    } else if (value === "equal-to") {
      return t("equal_to");
    } else if (value === "between") {
      return t("from");
    }
    return t("greater_than");
  };

  const updateAppliedFilters = () => {
    // let updatedRadioName =
    // data.radioValue === 'between' ? t('fillups.between') : getPlaceholderText(data.radioValue);
    // setData(prev => ({...prev, radioName: updatedRadioName}));
    if (screen.includes("inspectionscheduling")) {
      setLocalFilters((prev) => {
        const temp = prev.filter((f) => f.type !== data.column);
        temp.push({
          id: data.column,
          type: data.column,
          data: data,
        });
        return temp;
      });
    } else {
      if (data.column === "time") {
        let newData = {};
        if (data.radioValue === "between") {
          newData = {
            ...data,
            input_1: data.input_1 * 3600,
            input_2: data.input_2 * 3600,
          };
        } else {
          newData = { ...data, input_1: data.input_1 * 3600 };
        }
        dispatch(addAdvancedFilter({ ...newData, screen }));
      } else {
        dispatch(addAdvancedFilter({ ...data, screen }));
      }
    }
    setAppliedFilters((prev) => {
      const newArray = prev.filter((item) => item.column !== data.column);
      newArray.push(data);
      return newArray;
    });
  };

  const handleApply = () => {
    if (data.radioValue === "between") {
      if (!data.input_1 && !data.input_2) {
        setFirstInputErrorMsg(emptyValueMessage);
        setSecondInputErrorMsg(emptyValueMessage);
        setFirstInputFlag(true);
        setSecondInputFlag(true);
        setTimeout(() => setFirstInputFlag(false), 3000);
        setTimeout(() => setSecondInputFlag(false), 3000);
      } else if (!data.input_1) {
        setFirstInputErrorMsg(emptyValueMessage);
        setFirstInputFlag(true);
        setTimeout(() => setFirstInputFlag(false), 3000);
      } else if (!data.input_2) {
        setSecondInputErrorMsg(emptyValueMessage);
        setSecondInputFlag(true);
        setTimeout(() => setSecondInputFlag(false), 3000);
      } else if (data.input_1 && data.input_2) {
        if (+data.input_1 < 0 && +data.input_2 < 0) {
          setFirstInputErrorMsg(positiveValueMessage);
          setSecondInputErrorMsg(positiveValueMessage);
          setFirstInputFlag(true);
          setSecondInputFlag(true);
          setTimeout(() => setFirstInputFlag(false), 3000);
          setTimeout(() => setSecondInputFlag(false), 3000);
        } else if (+data.input_1 < 0) {
          setFirstInputErrorMsg(positiveValueMessage);
          setFirstInputFlag(true);
          setTimeout(() => setFirstInputFlag(false), 3000);
        } else if (+data.input_2 < 0) {
          setSecondInputErrorMsg(positiveValueMessage);
          setSecondInputFlag(true);
          setTimeout(() => setSecondInputFlag(false), 3000);
        } else if (+data.input_1 > +data.input_2) {
          setSecondInputErrorMsg(fromGreaterThanToMessage);
          setSecondInputFlag(true);
          setTimeout(() => setSecondInputFlag(false), 3000);
        } else {
          //setData((prev) => ({ ...prev, radioName: t("fillups.between") }));
          updateAppliedFilters();
        }
      }
    } else if (data.radioValue !== "between") {
      if (!data.input_1) {
        setFirstInputErrorMsg(emptyValueMessage);
        setFirstInputFlag(true);
        setTimeout(() => setFirstInputFlag(false), 3000);
      } else if (data.input_1) {
        if (data.input_1 < 0) {
          setFirstInputErrorMsg(positiveValueMessage);
          setFirstInputFlag(true);
          setTimeout(() => setFirstInputFlag(false), 3000);
        } else {
          // setData((prev) => ({
          //   ...prev,
          //   radioName: getPlaceholderText(data.radioValue),
          // }));
          updateAppliedFilters();
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isFiltered = data.isFiltered;
    if (name === "input_1") {
      if (data.radioValue === "between") {
        if (value && data.input_2 && +data.input_2 > +value) {
          isFiltered = true;
        } else {
          isFiltered = false;
        }
      } else {
        if (value) {
          isFiltered = true;
        } else {
          isFiltered = false;
        }
      }
    }

    if (name === "input_2") {
      if (value && data.input_1 && value > +data.input_1) {
        isFiltered = true;
      } else {
        isFiltered = false;
      }
    }

    let radioName = data.radioName;
    if (name === "radioValue") {
      radioName = getPlaceholderText(value);
    }
    setFilterData((prev) => {
      const newData = { ...data, [name]: value, isFiltered, radioName };
      return { ...prev, [data.column]: newData };
    });
  };

  return (
    <RangeFilterContainer top={top} id="number-filter">
      <InputRadioContainer>
        <RadioInput
          type="radio"
          id="greater-than"
          value="greater-than"
          name="radioValue"
          checked={data.radioValue === "greater-than"}
          onChange={handleChange}
        />
        <Label htmlFor="greater-than">{t("greater_than")}</Label>
      </InputRadioContainer>
      <InputRadioContainer>
        <RadioInput
          type="radio"
          id="less-than"
          value="less-than"
          name="radioValue"
          checked={data.radioValue === "less-than"}
          onChange={handleChange}
        />

        <Label htmlFor="less-than">{t("less_than")}</Label>
      </InputRadioContainer>
      {data.column !== "time" && (
        <InputRadioContainer>
          <RadioInput
            type="radio"
            id="equal-to"
            value="equal-to"
            name="radioValue"
            checked={data.radioValue === "equal-to"}
            onChange={handleChange}
          />
          <Label htmlFor="equal-to">{t("equal_to")}</Label>
        </InputRadioContainer>
      )}
      <InputRadioContainer>
        <RadioInput
          type="radio"
          id="between"
          value="between"
          name="radioValue"
          checked={data.radioValue === "between"}
          onChange={handleChange}
        />
        <Label htmlFor="between">{t("between")}</Label>
      </InputRadioContainer>
      <div>
        <div>
          <TextInput
            type="number"
            min={0}
            name="input_1"
            value={data.input_1}
            onChange={handleChange}
            onKeyDown={handleNegative}
            //onKeyPress={handleFirstInputKeyPress}
            placeholder={
              data.column === "time"
                ? //|| data.column === "timeToClose"
                  `${getPlaceholderText()} (${t("in_hours")})`
                : getPlaceholderText()
            }
          />
        </div>
        {firstInputFlag && <ErrorText>{firstInputErrorMsg}</ErrorText>}
        <Collapse in={data.radioValue === "between"}>
          <div>
            <TextInput
              type="number"
              name="input_2"
              value={data.input_2}
              onChange={handleChange}
              onKeyDown={handleNegative}
              //onInput={handleSecondInput}
              placeholder={
                data.column === "time"
                  ? //|| data.column === "timeToClose"
                    `${t("to")} (${t("in_hours")})`
                  : t("to")
              }
            />
          </div>
        </Collapse>
        {secondInputFlag && <ErrorText>{secondInputErrorMsg}</ErrorText>}
      </div>
      {/* <div>Make Sure that the input value entered is greater than 0.</div> */}
      {/* <SaveButtonContainer>
        <SaveButton id="apply-filter-button" variant="primary" onClick={handleApply}>
          {t("filter.apply")}
        </SaveButton>
      </SaveButtonContainer> */}
    </RangeFilterContainer>
  );
};

export default RangeFilter;

// styles
export const RangeFilterContainer = styled.div`
  //min-width: 150px;
  padding: 20px 0;
  background: transparent;
  position: absolute;
  //border: 1px solid lightgrey;
  //border-radius: 5px;
  display: flex;
  flex-direction: column;
  //right: 170px;
  //${(props) => (props.top ? `top: ${props.top}px` : `top: 55px`)}
`;

export const InputRadioContainer = styled.div`
  padding: 3px;
  //cursor: pointer;
  display: flex;
  align-items: start;
`;

export const SaveButtonContainer = styled.div`
  //padding: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const TextInput = styled.input`
  border: 1px solid lightgray;
  margin: 5px;
  padding: 4px;
  border-radius: 5px;

  &:hover {
    //box-shadow: 0 0 5pt 0.5pt ${colorLightGray};
  }

  &:focus {
    outline: none;
    border-color: ${colorBlue};
    //box-shadow: 0 0 1pt 1pt ${colorBlue};
  }
`;

export const Label = styled.label`
  color: ${colorTextNew};
  padding: 0px 5px;
  margin-botttom: 10px;
  cursor: pointer;

  &:hover {
    color: ${colorBlueHover};
  }
`;

export const SaveButton = styled(Button)`
  height: 28px;
  width: 60px;
  font-size: 14px;
`;

export const ErrorText = styled.p`
  margin-left: 5px;
  color: ${colorRed};
`;

export const RadioInput = styled.input`
  margin-top: 3px;
  vertical-align: middle;
  height: 100%;
`;
