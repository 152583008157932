import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  AddFormButton,
  ColumnHeading,
  StatusContainer,
} from "../../shared/components/TableElements";
import DataReactTable from "../DataTable/components/DataReactTable";
import { getDisplayDate } from "../../shared/helpers";
import { NATEXT } from "../../shared/constants";
import UserComponent from "../../shared/components/table/components/UserComponent";
import { colorBlue, colorGreenStatus } from "../../utils/palette";
import { getUsers, postApi, putApi } from "../../api/getUser";
import { updateUsersList } from "../../features/users/usersSlice";
import { useAuth } from "../../contexts/AuthContext";
import {
  updateNoOfRecords,
  updateUserSettingsData,
} from "../../features/columns/columnsSlice";
import InfoPopup from "../../shared/components/InfoPopup";
import { useHistory, useLocation } from "react-router";
import SubscribePopup from "../../shared/components/SubscribePopup";

const UserManagementTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const columnWidth = 130;

  const { orgId, roleId } = useSelector((state) => state.user);

  const history = useHistory();

  const users = useSelector((state) => state.users.list);
  const {
    isLoggedIn,
    reload,
    setReload,
    setLoading,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();

  const [showDummyRecordsPopup, setShowDummyRecordsPopup] = useState(false);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  const fromCancel = location?.state?.fromCancel;

  //Effect to set initial default rows
  useEffect(() => {
    if (isLoggedIn && (!isClientApiCalled || !fromCancel)) {
      setIsClientApiCalled(true);
      const mode = location?.state?.mode;
      if (mode && mode === "from-signup") {
        setShowDummyRecordsPopup(true);
      }

      (async () => {
        setLoading(true);
        let defaultRows = 10;
        let minDate = 0;
        let colOrder = "[0,1,2,3,4,5]";
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "users",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { default_rows, col_order } = data;
            if (col_order) {
              colOrder = col_order;
            }
            defaultRows = default_rows;
          }
        }
        dispatch(
          updateUserSettingsData({
            col_order: colOrder,
            min_date: minDate,
            default_rows: defaultRows,
            screen: "users",
          })
        );
        const usersList = await getUsers();
        dispatch(updateUsersList(usersList));
        setLoading(false);
      })();
    }
  }, []);

  useEffect(() => {
    //console.log(reload);
    if (isLoggedIn && reload === "users") {
      (async () => {
        const usersList = await getUsers();
        dispatch(updateUsersList(usersList));
        setReload("");
      })();
    }
  }, [reload]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("name"),
        accessor: "name",
        width: 130,
        disableFilters: true,
        //dragDisabled: true,
        Cell: ({ row }) => (
          <UserComponent
            userName={row.original.name}
            //groupName={row.original.group_name}
            userId={row.original.user_id}
            userImg={row.original.user_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("email"),
        accessor: "email",
        //width: 130,
        disableFilters: true,
        Cell: ({ value }) => (value ? value : NATEXT),
        //Cell: ({ value }) => <VehicleComponent value={value} />,
      },
      {
        id: 3,
        Header: t("phone"),
        accessor: "phone",
        width: columnWidth,
        //Cell: ({ value }) => new Date(value * 1000).toLocaleDateString("en-GB"),
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 4,
        Header: t("role"),
        accessor: "role_name",
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 5,
        Header: t("manager"),
        accessor: "manager_name",
        width: columnWidth,
        Cell: ({ value }) => (value && value !== "N/A" ? value : NATEXT),
      },
      {
        id: 6,
        Header: t("joining_date"),
        accessor: "joining_date",
        width: columnWidth,
        //Cell: ({ value }) => new Date(value * 1000).toLocaleDateString("en-GB"),
        Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
      },
      {
        id: 7,
        Header: t("status"),
        accessor: "user_active",
        width: columnWidth,
        Cell: ({ value }) => <UserStatus status={value} />,
      },
    ],
    []
  );

  const handleAddUser = async () => {
    const apiData = {
      querystring: "getusersubscriptionstatus",
      source: "user",
    };
    const res = await postApi(apiData, "commonNew");
    console.log("res", res);
    let errMsg = "";
    if (res.success) {
      const { subscription_data } = res;
      if (subscription_data != "Email not found") {
        const { prostatus, usercount } = subscription_data;

        if (prostatus == "trial" && usercount >= 3) {
          errMsg = t("no_pro_trial_err_msg");
        }
      } else {
        errMsg = t("no_pro_trial_err_msg");
      }
    } else {
      errMsg = t("no_pro_trial_err_msg");
    }

    if (errMsg) {
      setsubscribePopupErrMsg(errMsg);
      setShowSubscribePopup(true);
      return;
    }

    history.push("users/addnew");
  };

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: users,
  };
  return (
    <>
      {/* {showDummyRecordsPopup && (
        <InfoPopup
          message={<Trans i18nKey="dummy_records_message" t={t}></Trans>}
          showPopup={showDummyRecordsPopup}
          setShowPopup={setShowDummyRecordsPopup}
          icon={{ className: "fi fi-rr-truck-moving", color: colorBlue }}
          actionIcon={{
            label: t("okay"),
            handler: () => setShowDummyRecordsPopup(false),
          }}
        />
      )} */}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          <Col
          //sm={11}
          //className=""
          >
            <ColumnHeading>{t("users")}</ColumnHeading>
          </Col>
          <Col className="d-flex justify-content-end pb-1">
            {roleId !== 4 && (
              // <Link to={"/users/addnew"}>
              <AddFormButton
                id="add-user-button"
                type="button"
                onClick={handleAddUser}
              >
                {t("add_user")}
              </AddFormButton>
              // </Link>
            )}
          </Col>
        </Row>
        <Row>
          <DataReactTable reactTableData={reactTableData} />
        </Row>
      </Container>
    </>
  );
};

export default UserManagementTable;

export const UserStatus = ({ status }) => {
  const { t } = useTranslation("common");
  let userData = { text: "", bg: "" };

  if (status === 1) {
    userData.text = t("active");
    userData.bg = colorGreenStatus;
  } else if (status === 0) {
    userData.text = t("inactive");
    userData.bg = "lightgray";
  }

  return (
    <StatusContainer background={userData.bg}>
      <p style={{ color: "black" }}>{userData.text}</p>
    </StatusContainer>
  );
};
