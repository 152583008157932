import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    direction: 'ltr',
};

const directionSlice = createSlice({
    name: 'direction',
    initialState,
    reducers: {
        changeDirectionToLTR: (state, action) => {
            state.direction = 'ltr';
        },
        changeDirectionToRTL: (state, action) => {
            state.direction = 'rtl';
        }
    },
});

export const { changeDirectionToLTR, changeDirectionToRTL } = directionSlice.actions;
export default directionSlice.reducer;


