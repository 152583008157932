import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { colorText } from "@/utils/palette";
import { left, marginRight } from "@/utils/directions";
import {
  TopbarBack,
  TopbarButton,
  TopbarDownIcon,
} from "./BasicTopbarComponents";
import {
  TopbarCollapse,
  TopbarCollapseContent,
} from "./CollapseTopbarComponents";
import {
  colorGrayNew,
  colorLightGray,
  taskBgColor,
} from "../../../../utils/palette";

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;
const de = `${process.env.PUBLIC_URL}/img/language/de.png`;
const es = `${process.env.PUBLIC_URL}/img/language/es.png`;

const GbLng = ({ active }) => (
  <TopbarLanguageButtonTitle>
    <img src={gb} alt="gb" />
    <Span $active={active}>EN</Span>
  </TopbarLanguageButtonTitle>
);

const SpLng = ({ active }) => (
  <TopbarLanguageButtonTitle>
    <img src={es} alt="es" />
    <Span $active={active}>ES</Span>
  </TopbarLanguageButtonTitle>
);

const FrLng = () => (
  <TopbarLanguageButtonTitle>
    <img src={fr} alt="fr" />
    <Span>FR</Span>
  </TopbarLanguageButtonTitle>
);

const DeLng = () => (
  <TopbarLanguageButtonTitle>
    <img src={de} alt="de" />
    <Span>DE</Span>
  </TopbarLanguageButtonTitle>
);

const TopbarLanguage = () => {
  const { i18n } = useTranslation("common");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const selectedLang = localStorage.getItem("language");

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  let lang = <GbLng />;
  let langOptions = { label: "EN", img: gb, alt: "gb" };
  if (selectedLang) {
    if (selectedLang === "es") {
      lang = <SpLng />;
      langOptions.label = "ES";
      langOptions.img = es;
      langOptions.alt = "es";
    } else {
      if (window.navigator.language.includes("es")) {
        lang = <SpLng />;
        langOptions.label = "ES";
        langOptions.img = es;
        langOptions.alt = "es";
      }
    }
  }
  // const [mainButtonContent, setMainButtonContent] = useState(lang);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  const changeLanguage = (lng) => {
    const currentLanguage = localStorage.getItem("language");
    if (currentLanguage && currentLanguage === lng) {
      return;
    }
    localStorage.setItem("language", lng);
    location.reload();
    // i18n.changeLanguage(lng).then(() => {
    //   switch (lng) {
    //     case "en":
    //       //window.location.replace("");
    //       setMainButtonContent(<GbLng />);
    //       break;
    //     case "es":
    //       localStorage.setItem("language", "es");
    //       location.reload();
    //       //window.location.replace("");
    //       setMainButtonContent(<SpLng />);
    //       break;
    //     case "fr":
    //       setMainButtonContent(<FrLng />);
    //       break;
    //     case "de":
    //       setMainButtonContent(<DeLng />);
    //       break;
    //     default:
    //       setMainButtonContent(<GbLng />);
    //       break;
    //   }
    // });
  };

  return (
    <TopbarLanguageCollapse>
      <TopbarButton type="button" onClick={toggleLanguage}>
        <TopbarLanguageButtonTitle>
          <img src={langOptions.img} alt={langOptions.alt} />
          <span style={{ color: colorGrayNew }}>{langOptions.label}</span>
        </TopbarLanguageButtonTitle>
        <TopbarDownIcon />
      </TopbarButton>
      {isCollapsed && (
        <TopbarBack
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarLanguageCollapseContent>
          <TopbarLanguageButton
            type="button"
            onClick={() => changeLanguage("en")}
            $active={selectedLang === "en"}
          >
            <GbLng active={selectedLang === "en"} />
          </TopbarLanguageButton>
          <TopbarLanguageButton
            type="button"
            onClick={() => changeLanguage("es")}
            $active={selectedLang === "es"}
          >
            <SpLng active={selectedLang === "es"} />
          </TopbarLanguageButton>
          {/* <TopbarLanguageButton
            type="button"
            onClick={() => changeLanguage("fr")}
          >
            <FrLng />
          </TopbarLanguageButton>
          <TopbarLanguageButton
            type="button"
            onClick={() => changeLanguage("de")}
          >
            <DeLng />
          </TopbarLanguageButton> */}
        </TopbarLanguageCollapseContent>
      </Collapse>
    </TopbarLanguageCollapse>
  );
};

export default TopbarLanguage;

// region STYLES

const TopbarLanguageCollapse = styled(TopbarCollapse)`
  min-width: 70px;
  display: block;

  & > button {
    padding: 0 4px;
    width: 100%;
  }
`;

const TopbarLanguageCollapseContent = styled(TopbarCollapseContent)`
  max-width: 75px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    ${left}: 0;
  }
`;

const TopbarLanguageButton = styled.button`
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  background: ${(p) => (p.$active ? colorLightGray : "transparent")};
  cursor: pointer;
  font-size: 13px;
  //line-height: 16px;
  transition: 0.3s;
  padding: 8px 5px 4px 15px;
  text-align: ${left};

  // &:first-child {
  //   padding-top: 14px;
  // }

  // &:last-child {
  //   padding-bottom: 14px;
  // }

  &:hover {
    ${(p) => !p.$active && `background: ${taskBgColor}`};
  }
`;

export const TopbarLanguageButtonTitle = styled.span`
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
  color: ${colorText};

  &:not(:last-child) {
    ${marginRight}: 5px;
  }

  img {
    height: 11px;
    width: 16px;
    ${marginRight}: 4px;
  }
`;

export const Span = styled.span`
  //color: ${colorGrayNew};
  ${(p) => p.$active && `font-weight: 600`};
`;
// endregion
