import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colorGray, colorLightGray } from "../../../utils/palette";
import { useLocation } from "react-router";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../SubscribePopup";
import { useTranslation } from "react-i18next";

const FileUploader = ({ handleFileUpload, disabled, images, setImages }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const location = useLocation();
  const screen = location.pathname;
  const { t } = useTranslation("common");

  //State to maintain allowed file types for Daily Time Sheet
  const [allowedFileTypes, setAllowedFileTypes] = useState(
    "image/*, .doc, .docx, .pdf"
  );

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  useEffect(() => {
    if (screen?.includes("dailytimesheet")) {
      console.log(images);
      if (images?.length > 0) {
        if (images[0].file?.type?.includes("image")) {
          setAllowedFileTypes("image/*");
        }
      } else {
        setAllowedFileTypes("image/*, .doc, .docx, .pdf");
      }
    }
  }, [images]);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    //console.log('click');
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const { files } = event.target;
    //console.log(event.target.files);
    const fileUploaded = event.target.files[0];
    const { length, ...filesObj } = files;

    //console.log(filesObj, length);
    let uploadedFilesArr = [];
    Object.keys(filesObj).map((key) => {
      const extension = filesObj[key].name.split(".").pop();
      uploadedFilesArr.push({
        id: Date.now() + key,
        file: filesObj[key],
        url: URL.createObjectURL(filesObj[key]),
        extension,
      });
    });

    setImages((prev) => {
      //console.log(prev);
      //console.log(uploadedFilesArr);
      const temp = [...prev, ...uploadedFilesArr];
      return temp;
    });

    //handleFileUpload(files);
  };
  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      <AddImgIcon
        className="fi fi-rr-add"
        onClick={handleClick}
        disabled={disabled}
      ></AddImgIcon>
      <input
        type="file"
        id="upload-img"
        name="upload-img"
        accept={
          screen.includes("dailytimesheet")
            ? allowedFileTypes
            : "image/*, .doc, .docx, .pdf"
        }
        ref={hiddenFileInput}
        onChange={handleChange}
        //onClick={handleChange}
        style={{ display: "none" }}
        multiple={!screen?.includes("dailytimesheet")}
        disabled={disabled}
      />
    </>
  );
};

export default FileUploader;

//styles

export const AddImgIcon = styled.i`
  font-size: 30px;
  position: relative;
  top: 4px;
  color: ${colorGray};

  ${(p) =>
    !p.disabled &&
    `
    cursor: pointer;
    &:hover {
    color: ${colorLightGray};
    }
`};

  ${(p) =>
    p.disabled &&
    `
      //color: ${colorLightGray};
      cursor: not-allowed
    `};
`;
