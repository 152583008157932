import React from "react";
import styled from "styled-components";
import {
  colorBg,
  colorGreen,
  colorGreenStatus,
  colorLightGray,
  colorStatusActive,
  colorYellowNew,
} from "../../../utils/palette";
import { LinkSpan } from "../form/FormElements";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ErrorDetailsTable from "./ErrorDetailsTable";

const ImportCompleteComponent = ({
  issuesArr,
  importFile,
  selectValues,
  showErrDetails,
  setShowErrDetails,
  noOfRowsInserted,
}) => {
  const { t } = useTranslation("common");

  // const [showErrDetails, setShowErrDetails] = useState(false);

  //console.log(issuesArr);

  //const successRecords = importFile?.rows?.length - issuesArr?.length;
  return (
    <>
      {/* {showErrDetails && (
        <ErrorDetailsTable
          showPopup={showErrDetails}
          setShowPopup={setShowErrDetails}
          issuesArr={issuesArr}
          importFile={importFile}
          selectValues={selectValues}
        />
      )} */}
      <ImportCompleteContainer>
        <h5 style={{ fontWeight: 600 }}>{`${t("import_complete")}!`}</h5>
        <div className="d-flex gap-2 align-items-center">
          <i
            className={
              noOfRowsInserted === 0
                ? "fi fi-rr-triangle-warning"
                : "fi fi-rs-check-circle"
            }
            style={{
              color: noOfRowsInserted === 0 ? "red" : "green",
              position: "relative",
              top: 1,
            }}
          />
          <span style={{ fontWeight: 400, fontSize: 14 }}>
            {t("records_imported", {
              count: noOfRowsInserted,
            })}
          </span>
        </div>
        {issuesArr.length > 0 && (
          <div className="d-flex gap-2 align-items-center">
            <i
              className="fi fi-rr-triangle-warning"
              style={{ color: colorYellowNew, position: "relative", top: 1 }}
            />
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              {t("records_had_issues", {
                count: issuesArr?.length,
              })}
            </span>
            <LinkSpan
              style={{ fontSize: 14 }}
              onClick={() => setShowErrDetails(true)}
            >
              {t("view_details")}
            </LinkSpan>
          </div>
        )}
      </ImportCompleteContainer>
    </>
  );
};

export default ImportCompleteComponent;

//styles
const ImportCompleteContainer = styled.div`
  border: 1px dashed ${colorLightGray};
  background: ${colorBg};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: ${window.innerHeight - 340}px;
  //cursor: pointer;
`;
