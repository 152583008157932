import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  Label,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import FormHeader from "../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { postApi } from "../../api/getUser";
import { useDispatch } from "react-redux";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { fetchServicesTasks } from "../../features/servicesTasks/servicesTasksSlice";
import { isObjEmpty } from "../../shared/helpers";

const ServiceTasksFormPopup = ({
  showPopup,
  setShowPopup,
  newTaskInfo,
  setNewServiceTaskId,
  from,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.slice(1, vehiclesList.length);

  const { userId } = useSelector((state) => state.user);

  const type = location?.state?.type;
  const rowData = location?.state?.rowData;

  const serviceTaskOptions = useSelector((state) => state.servicesTasks.list);

  //Coming from Add Service
  const mode = location?.state?.mode;
  const fromType = location?.state?.fromType;
  const route = location?.state?.route;
  const redirect = location?.state?.redirect;
  const routeData = location?.state?.routeData;
  const serviceData = location?.state?.routeData?.serviceData;

  const [recurringTask, setRecurringTask] = useState(false);
  const [applyToAllVeh, setApplyToAllVeh] = useState(true);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const serviceTaskName = watch("serviceTaskName");
  const comments = watch("comments");

  const { taskName, vehicleId } = newTaskInfo;

  useEffect(() => {
    setValue("serviceTaskName", taskName);
  }, [taskName]);

  useEffect(() => {
    if (from && from === "add-maintenance-reminder") {
      setRecurringTask(true);
    }
  }, []);

  const handleClose = () => {
    reset({
      serviceTaskName: "",
      comments: "",
    });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const addServiceTask = async () => {
    console.log(errors);
    if (!isObjEmpty(errors)) {
      return;
    }
    // let taskOptions = [...serviceTaskOptions];
    // //if task is being added from reminders, then only check recurring tasks
    // if (from && from === "add-maintenance-reminder") {
    //   taskOptions = taskOptions.filter((t) => +t.recurring);
    // }
    for (let serviceTask of serviceTaskOptions) {
      if (applyToAllVeh) {
        if (
          serviceTask.service_task_name.toLocaleLowerCase() ===
          serviceTaskName?.trim().toLocaleLowerCase()
        ) {
          let errMsg = t("dup_service_task_name_error");
          if (
            from &&
            from === "add-maintenance-reminder" &&
            +!serviceTask.recurring
          ) {
            errMsg = t("dup_service_task_name_error_from_reminder");
          }
          setError(
            "serviceTaskName",
            {
              type: "custom",
              message: errMsg,
            },
            { shouldFocus: true }
          );
          return;
        }
      } else {
        if (
          serviceTask.service_task_name.toLocaleLowerCase() ===
            serviceTaskName?.trim().toLocaleLowerCase() &&
          (serviceTask.veh_id === vehicleId || serviceTask.veh_id === "All")
        ) {
          let errMsg = t("dup_service_task_name_error");
          if (
            from &&
            from === "add-maintenance-reminder" &&
            +!serviceTask.recurring
          ) {
            errMsg = t("dup_service_task_name_error_from_reminder");
          }
          setError(
            "serviceTaskName",
            {
              type: "custom",
              message: errMsg,
            },
            { shouldFocus: true }
          );
          return;
        }
      }
    }

    const serviceTaskId = `${userId}_${Date.now()}`;

    const apiData = {
      querystring: "add_service_task",
      service_task_id: serviceTaskId,
      service_task_name: serviceTaskName,
      recurring: recurringTask ? 1 : 0,
      servicetask_veh_id: applyToAllVeh ? "All" : vehicleId,
      comments: comments,
      source: "web",
      sync_version: "v2",
      action: 1,
    };

    console.log(apiData);

    setSaveBtnLoading(true);
    const response = await postApi(apiData, "commonNew");
    if (response.success === 1) {
      setSaveBtnLoading(false);
      const apiData = {
        querystring: "getallservicetask",
        service_veh_id: "All",
      };
      dispatch(fetchServicesTasks(apiData));
      setNewServiceTaskId(serviceTaskId);
      setShowPopup(false);
    }
  };

  return (
    <Modal show={showPopup} size="md">
      <Modal.Header>
        <h4>{t("add_custom_task")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FormContainer
        //onSubmit={handleSubmit(onSubmit)}
        >
          {/* <div
            className="d-flex justify-content-end align-items-center"
            style={{ width: "-webkit-fill-available" }}
          >
            <AddFormButton
              id="save-form-button"
              type="button"
              onClick={addServiceTask}
              //disabled={disableSave}
              //isLoading={isSaveLoading}
              //onClick={handleSave}
            >
              {saveBtnLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          </div> */}
          {/* <FilterButton
          id="cancel-form-button"
          type="button"
          onClick={handleCancel}
        >
          {t('cancel')}
        </FilterButton> */}
          <Container>
            <Card>
              <CardBody
                style={{
                  height: `${window.innerHeight - 260}px`,
                  overflow: "auto",
                }}
              >
                <Row>
                  <Col md={12}>
                    <FormGroupLabel className="m-0">
                      {t("service_task_name")}
                    </FormGroupLabel>
                    <FormItem disabled={false}>
                      <FormField
                        name="serviceTaskName"
                        control={control}
                        component="input"
                        defaultValue=""
                        placeholder={t("service_task_name")}
                        style={formInputStyles}
                        disabled={false}
                        rules={{ required: t("required_err") }}
                      />
                    </FormItem>

                    {errors?.serviceTaskName && (
                      <Error error={errors.serviceTaskName.message} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                    <FormItem disabled={false}>
                      <FormField
                        name="comments"
                        control={control}
                        component="input"
                        defaultValue=""
                        placeholder={t("notes_tv")}
                        style={formInputStyles}
                        disabled={false}
                      />
                    </FormItem>

                    {errors?.comments && (
                      <Error error={errors.comments.message} />
                    )}
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md={12}>
                    <CheckBoxInput
                      disabled={from === "add-maintenance-reminder"}
                      type="checkbox"
                      id="recurringTask"
                      name="recurringTask"
                      value="recurringTask"
                      checked={recurringTask}
                      onChange={() => setRecurringTask((prev) => !prev)}
                      style={{ margin: "0 8px 0 0" }}
                    />
                    <Label
                      htmlFor="recurringTask"
                      disabled={from === "add-maintenance-reminder"}
                    >
                      {t("recurring_task")}
                    </Label>
                  </Col>
                  <Desc style={{ paddingLeft: "2rem" }}>
                    {t("no_reminder_for_nonrecurring")}
                  </Desc>
                </Row>
                <Row className="pt-3">
                  <Col md={12} className="pt-2">
                    <CheckBoxInput
                      type="checkbox"
                      id="applyToAllVeh"
                      name="applyToAllVeh"
                      value="applyToAllVeh"
                      checked={applyToAllVeh}
                      onChange={() => setApplyToAllVeh((prev) => !prev)}
                      style={{ margin: "0 8px 0 0" }}
                    />
                    <Label htmlFor="applyToAllVeh">{t("apply_all_vehs")}</Label>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </FormContainer>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton
          id="save-form-button"
          type="button"
          onClick={addServiceTask}
        >
          {saveBtnLoading ? <CustomLoader /> : t("save")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceTasksFormPopup;
