import { Route, Switch } from "react-router";
import InspectionFormsForm from "./InspectionFormsForm";
import InspectionFormsTable from "./InspectionFormsTable";

export default () => (
  <Switch>
    <Route
      exact path="/inspection/inspectionforms/"
      component={InspectionFormsTable}
    />
    <Route
      path="/inspection/inspectionforms/addnew"
      component={InspectionFormsForm}
    />
  </Switch>
);