import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  NavItem,
  NavLink,
  VerticalColoredTabs,
} from "@/shared/components/Tabs";
import { TabsWrap } from "../../shared/components/Tabs";
import SingleChart from "./SingleChart";
import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { NATEXT } from "../../shared/constants";

const TotalStatsTab = ({
  data,
  //setData,
  graphData,
  setGraphData,
  graphQuery,
  setGraphQuery,
  setStatsQuery,
  verticalActiveKey,
  setVerticalActiveKey,
  statsValue,
  setStatsValue,
}) => {
  //console.log("statsValue", statsValue, verticalActiveKey, data);
  //const [verticalActiveKey, setVerticalActiveKey] = useState(2);

  const VerticalTabs = () => {
    // useEffect(() => {
    //   const verticalTab = data.find((d) => d.tabKey == activeKey);
    //   if (verticalTab?.graphQueryString) {
    //     setGraphQuery(verticalTab.graphQueryString);
    //   }
    // }, [activeKey]);

    const handleSelect = (e) => {
      if (e != verticalActiveKey) {
        setStatsValue(NATEXT);
      }
      setVerticalActiveKey(e);
      //setGraphData(null);
      const verticalTab = data.find((d) => d.tabKey == e);
      // if (verticalTab?.graphQueryString) {
      setGraphQuery(verticalTab.graphQueryString);
      setStatsQuery(verticalTab.statsQueryString);
      // }
    };

    const handleTabClick = (data) => {
      if (data?.graphQueryString) {
        setGraphQuery(data.graphQueryString);
      }
    };

    return (
      <Tab.Container
        activeKey={verticalActiveKey}
        // onSelect={(e) => {
        //   setActiveKey(e);
        // }}
        onSelect={(e) => handleSelect(e)}
      >
        <TabsWrap>
          <Nav className="nav-tabs" style={{ width: "auto", height: "362px" }}>
            {data.map((d, i) => (
              <NavItem
                $vertical
                //active={activeKey == i + 1}
                key={i}
                id={`vertical-nav-item-${i + 1}`}
                //onClick={() => handleTabClick(d)}
                //style={{ minWidth: "280px" }}
              >
                <NavLink
                  id={`vertical-nav-link-${i + 1}`}
                  eventKey={i + 1}
                  //onClick={() => handleTabClick(d)}
                >
                  <div className="d-flex justify-content-between">
                    <p>{d.label}</p>
                    {i + 1 == verticalActiveKey && (
                      <p style={{ fontWeight: 500 }}>{statsValue}</p>
                    )}
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Tab.Content
            style={{
              width: "-webkit-fill-available",
              height: "inherit",
              borderLeft: "1px solid lightgray",
            }}
            className="d-flex justfy-content-center align-items-center"
          >
            {data.map((d, i) => (
              <Tab.Pane
                eventKey={i + 1}
                key={i}
                id={`tab-pane-${i + 1}`}
                style={{ width: "-webkit-fill-available" }}
              >
                <div
                  style={
                    {
                      //borderRight: "1px solid",
                      width: "webkit-fill-available",
                      //margin: "2rem",
                      //height: `inherit`,
                    }
                  }
                  className="d-flex justify-content-center align-items-center"
                >
                  <SingleChart
                    graphData={graphData}
                    graphQuery={graphQuery}
                    setGraphData={setGraphData}
                  />
                </div>
              </Tab.Pane>
            ))}

            {/* <Tab.Pane eventKey={2}>
              <div
                style={{ border: "1px solid" }}
                className="d-flex justify-content-center align-items-center"
              >
                Stats and Charts{" "}
              </div>
            </Tab.Pane> */}
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>
    );
  };

  return (
    <VerticalColoredTabs>
      <VerticalTabs />
    </VerticalColoredTabs>
  );
};

export default TotalStatsTab;

//styles
const VerNavItem = styled(NavItem)`
  width: auto;
  ${(p) => !p.active && `background: aliceblue`}
`;
