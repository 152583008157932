import React, { useState, useEffect } from "react";
import {
  getDateWithTime,
  getDistanceFactorforOdo,
  getOdoUnit,
} from "../helpers";
import { postApi } from "../../api/getUser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useFetchOdoHint = ({ vehicle, date, time, filterDateEnd }) => {
  const { t } = useTranslation("common");
  const [odoHint, setOdoHint] = useState("");
  const [odoUnit, setOdoUnit] = useState("");
  const [lastOdoValue, setLastOdoValue] = useState(0);
  const [nextOdoValue, setNextOdoValue] = useState(0);

  const { distance } = useSelector((state) => state.units);

  //Effect to change odo hint data on change of vehicle, date and time
  useEffect(() => {
    (async () => {
      if (vehicle) {
        let filterEndDate = filterDateEnd
          ? filterDateEnd
          : getDateWithTime(date, time);

        const res = await postApi(
          {
            querystring: "getOdometerFromDate",
            sub_querystring: "",
            veh_id: vehicle?.vehicleId,
            filter_date_start: 0,
            filter_date_end: filterEndDate,
          },
          "dashboard"
        );
        if (res.success === 1) {
          let [nextOdo, lastOdo] = res.odohint_data;
          lastOdo = +lastOdo;
          nextOdo = +nextOdo;
          const distanceFactor = getDistanceFactorforOdo(
            vehicle?.primary_meter,
            distance
          );
          if (lastOdo) {
            lastOdo = lastOdo / distanceFactor;
            setLastOdoValue(lastOdo);
            lastOdo = lastOdo.toFixed(2);
          } else {
            setLastOdoValue(0);
          }
          if (nextOdo) {
            nextOdo = nextOdo / distanceFactor;
            setNextOdoValue(nextOdo);
            nextOdo = nextOdo.toFixed(2);
          } else {
            setNextOdoValue(0);
          }

          if (lastOdo && nextOdo) {
            setOdoHint(
              t("last_odo") + lastOdo + ", " + t("next_odo") + nextOdo
            );
          } else if (lastOdo && !nextOdo) {
            setOdoHint(t("last_odo") + lastOdo);
          } else if (!lastOdo && nextOdo) {
            setOdoHint(t("next_odo") + nextOdo);
          } else {
            setOdoHint("");
          }
        }
      }
    })();
  }, [vehicle, date, time, filterDateEnd]);

  //Effect to update Odo Unit on vehicle change
  useEffect(() => {
    if (vehicle) {
      setOdoUnit(getOdoUnit(vehicle?.primary_meter));
    }
  }, [vehicle]);
  return { odoHint, odoUnit, lastOdoValue, nextOdoValue };
};

export default useFetchOdoHint;
