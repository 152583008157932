import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Card, CardBody } from "@/shared/components/Card";
import { ActionIconContainer } from "../../shared/components/TableElements";
import { colorLightBlue, colorYellowNew } from "../../utils/palette";
import { formInputStyles } from "../../shared/constants/styles";
import { postApi } from "../../api/getUser";
import { VehicleImg } from "../../shared/components/table/components/VehicleComponent";
import {
  AssignedVehiclesContainer,
  VehicleListContainer,
} from "../UserManagement/VehiclesAssignedForm";
import CheckboxInputComponent from "../../shared/components/form/CheckboxInputComponent";
import {
  CheckBoxInput,
  FormItem,
  Label,
} from "../../shared/components/form/FormElements";
import InfoPopup from "../../shared/components/InfoPopup";

const UsersAssignedForm = ({
  usersAssigned,
  setUsersAssigned,
  userAssignedUpdated,
  setUserAssignedUpdated,
  type,
  rowData,
}) => {
  const { t } = useTranslation("common");
  let usersList = useSelector((state) => state.users.list);
  const { orgId, roleId, userId } = useSelector((state) => state.user);
  const [users, setUsers] = useState(usersList.filter((u) => u.role_id !== 4));

  const [search, setSearch] = useState("");

  const [showNoUserAssignedWarning, setShowNoUserAssignedWarning] =
    useState(false);

  const url = "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";

  const [selectAll, setSelectAll] = useState(false);

  const status = useFormContext().watch("status");
  const isDisabled = !status;

  //Effect to set users initially
  useEffect(() => {
    if (usersList?.length > 0) {
      setUsers(usersList.filter((u) => u.role_id !== 4));
    }
  }, [usersList]);

  //Effect to auto select manager as user assigned in case of manager login
  useEffect(() => {
    if (roleId === 2 && type !== "edit") {
      setUsersAssigned(usersList.filter((u) => u.user_id === userId));
      setUserAssignedUpdated(true);
    }
  }, [roleId, userId, usersList]);

  //Effect to update usersAssigned on edit
  useEffect(() => {
    if (type === "edit" && rowData && usersList?.length > 0) {
      const { veh_id } = rowData;
      (async () => {
        const res = await postApi(
          {
            veh_id: veh_id,
            querystring: "getsinglevehicle",
          },
          "commonNew"
        );
        const { user_data } = res;
        if (user_data.length > 0) {
          const selectedUserIds = user_data
            ?.filter((u) => u.operator_vehicle_action !== 3)
            .map((u) => u.user_id);
          const selectedUsers = usersList.filter((u) =>
            selectedUserIds.includes(u.user_id)
          );
          setUsersAssigned(selectedUsers);
        }
      })();
    }
  }, [rowData, usersList]);

  //To uncheck select all on change of usersAssigned length
  //When search is present, disable selectAll and uncheck it
  useEffect(() => {
    if (search) {
      setSelectAll(false);
    } else {
      if (usersAssigned.length === usersList.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [usersAssigned, usersList, search]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setUsers((prev) => {
      const temp = usersList.filter((u) => {
        return u.label.toLocaleLowerCase().includes(value.toLocaleLowerCase());
      });
      return temp;
    });
  };

  const handleCheckboxChange = (id) => {
    if (!userAssignedUpdated) {
      setUserAssignedUpdated(true);
    }

    // if (usersAssigned.length === 1 && roleId === 2) {
    //   setShowNoUserAssignedWarning(true);
    // }
    let newUsersAssigned = [];

    setUsersAssigned((prev) => {
      let temp = [...prev];
      const selected = users.find((u) => u.user_id === id);
      console.log(selected);
      if (temp.includes(selected)) {
        temp = temp.filter((u) => u.user_id !== selected.user_id);
        if (temp.length === 0 && roleId === 2) {
          setShowNoUserAssignedWarning(true);
        }
        return temp;
      } else {
        temp.push(selected);
        return temp;
      }
    });
  };

  const handleRemoveClick = (id) => {
    if (!userAssignedUpdated) {
      setUserAssignedUpdated(true);
    }

    if (usersAssigned.length === 1 && roleId === 2) {
      setShowNoUserAssignedWarning(true);
    }

    //This condition is not reqd as it is handled in the effect on change of usersAssigned
    // if (selectAll) {
    //   setSelectAll(false);
    // }
    setUsersAssigned((prev) => {
      let temp = [...prev];
      temp = temp.filter((u) => u.user_id !== id);
      return temp;
    });
  };

  const handleSelectAllChange = (e) => {
    if (!userAssignedUpdated) {
      setUserAssignedUpdated(true);
    }
    const { checked } = e.target;
    setSelectAll(checked);

    if (checked) {
      setUsersAssigned(usersList);
    } else {
      setUsersAssigned([]);
      if (roleId === 2) {
        setShowNoUserAssignedWarning(true);
      }
      // if (roleId === 2) {
      //   setUsersAssigned(users.filter((u) => u.user_id === userId));
      // }
      // else {
      //   setUsersAssigned([]);
      // }
    }
  };

  return (
    <>
      {showNoUserAssignedWarning && (
        <InfoPopup
          message={
            "You will lose access to this vehicle as there are no users assigned to it. Please assign atleast one user if you want to retain access to this vehicle."
          }
          icon={{
            className: "fi fi-rr-triangle-warning",
            color: colorYellowNew,
          }}
          showPopup={showNoUserAssignedWarning}
          setShowPopup={setShowNoUserAssignedWarning}
        />
      )}
      <Container
      //style={{ background: "#f2f4f7", margin: 0, padding: 0 }}
      >
        <Row>
          <Col
            md={6}
            //style={{ paddingRight: 0 }}
          >
            <Card
            //style={{ margin: "1rem 0" }}
            >
              <CardBody
                style={{
                  height: `${window.innerHeight - 230}px`,
                }}
              >
                <Row>
                  <Col>
                    <h5 className="mb-2">{t("users_to_be_assigned_to_veh")}</h5>

                    {/* <Select
                    options={usersList}
                    //value={serviceTasks.find((c) => c.value === value)}
                    value={usersAssigned}
                    //onChange={onChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    placeholder={t("veh_name")}
                    styles={selectStyles}
                    isMulti
                    controlShouldRenderValue={false}
                    isClearable
                    isSearchable
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                    defaultMenuIsOpen
                    menuIsOpen
                    //autoFocus
                  /> */}

                    <FormItem disabled={isDisabled}>
                      <input
                        disabled={isDisabled}
                        type="text"
                        name="user-filter-search"
                        id="user-filter-search"
                        value={search}
                        onChange={handleSearchChange}
                        style={formInputStyles}
                        placeholder={t("user_name")}
                      />
                    </FormItem>
                    <FormItem
                      className="d-flex justify-content-start px-2"
                      disabled={isDisabled || search}
                    >
                      {/* <input
                      type="checkbox"
                      name="select-all-checkbox"
                      id="select-all-checkbox"
                      onChange={handleSearchChange}
                      style={formInputStyles}
                      placeholder={t("user_name")}
                    /> */}
                      <CheckboxInputComponent
                        checked={selectAll}
                        setChecked={setSelectAll}
                        id={"select-all-checkbox"}
                        label={t("select_all")}
                        onChange={handleSelectAllChange}
                        disabled={isDisabled || search}
                      />
                    </FormItem>

                    <VehicleListContainer>
                      {users.map((user) => {
                        return (
                          <div
                            key={user.user_id}
                            className="d-flex align-items-center gap-2 py-2"
                          >
                            <CheckBoxInput
                              type="checkbox"
                              id={user.user_id}
                              //checked={veh.checked}
                              checked={usersAssigned?.includes(user)}
                              onChange={() =>
                                handleCheckboxChange(user.user_id)
                              }
                              disabled={
                                isDisabled
                                // ||
                                // (roleId === 2 &&
                                //   user.user_id === userId &&
                                //   type !== "edit")
                              }
                            />
                            <UserImgComponent
                              userName={user.name}
                              userImg={user.user_img}
                              userId={user.user_id}
                              orgId={user.org_id}
                            />
                            <Label
                              style={{ fontSize: "14px" }}
                              htmlFor={user.user_id}
                              disabled={
                                isDisabled
                                // ||
                                // (roleId === 2 &&
                                //   user.user_id === userId &&
                                //   type !== "edit")
                              }
                            >
                              {user.label}
                            </Label>
                          </div>
                        );
                      })}
                    </VehicleListContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6}
            //className="p-0"
            // style={{
            //   background: "#f9f9f9",
            //   height: `${window.innerHeight - 230}px`,
            //   overflow: 'auto'
            // }}
          >
            <Card
              style={
                {
                  //background: "#f9f9f9",
                  //height: `${window.innerHeight - 230}px`,
                }
              }
            >
              <CardBody
                style={{
                  height: `${window.innerHeight - 230}px`,
                  //background: "#f9f9f9",
                  //paddingRight: 0,
                }}
              >
                <Row
                //style={{ background: 'lightgray' }}
                >
                  <Col>
                    <h5 className="mb-2">{t("assigned_users")}</h5>

                    <AssignedVehiclesContainer>
                      {usersAssigned.map((user) => {
                        return (
                          <div
                            key={user.user_id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div className="d-flex gap-3 my-2">
                              <UserImgComponent
                                userName={user.name}
                                userImg={user.user_img}
                                userId={user.user_id}
                                orgId={user.org_id}
                              />
                              <p>{user.label}</p>
                            </div>
                            {!isDisabled && (
                              <ActionIconContainer
                                onClick={() => handleRemoveClick(user.user_id)}
                                id={`remove-${user.user_id}-icon`}
                              >
                                <i className="fi fi-rs-cross-small" />
                              </ActionIconContainer>
                            )}
                          </div>
                        );
                      })}
                    </AssignedVehiclesContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsersAssignedForm;

//styles

export const UserImgComponent = ({ userName, userImg, userId, orgId }) => {
  //const { orgId, userId } = useSelector((state) => state.user);
  const url = "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";
  const imageUrl = `${url}/org_${orgId}/user_${userId}/profile/${userImg}`;
  //console.log("imgUrl", imageUrl);

  let userInitials = "";
  const userNameArr = userName.split(" ");
  userNameArr.forEach((name) => (userInitials += name[0]?.toUpperCase()));
  userInitials = userInitials.slice(0, 3);
  return (
    <>
      {userImg ? (
        <VehicleImg
          zoom
          src={imageUrl}
          alt="vehicle-img"
          // style={{ width: "40px", height: "40px" }}
          className="rounded-circle"
        />
      ) : (
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: 20,
            height: 20,
            border: `1px solid ${colorLightBlue}`,
            background: "gray",
            color: "white",

            textAlign: "center",
            //padding: "0.25rem",
          }}
        >
          <span style={{ fontSize: "8px" }}>{userInitials}</span>
        </div>
      )}
    </>
  );
};
