import React from "react";
import { Route, Switch } from "react-router-dom";
import ServicesTable from "./ServicesTable";
import ServicesTasksTable from "./ServicesTasksTable";
import ServicesForm from "./ServicesForm";
import ServiceTasksForm from "./ServiceTasksForm";

export default () => (
  <Switch>
    <Route exact path="/services/services" component={ServicesTable} />
    <Route exact path="/services/servicetasks" component={ServicesTasksTable} />
    <Route exact path="/services/services/addnew" component={ServicesForm} />
    <Route
      exact
      path="/services/servicetasks/addnew"
      component={ServiceTasksForm}
    />
  </Switch>
);
