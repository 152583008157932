import { Route, Switch } from "react-router";
import DailyTimeSheetTable from "./DailyTimeSheetTable";
import DailyTimeSheetForm from "./DailyTimeSheetForm";

export default () => (
  <Switch>
    <Route exact path="/dailytimesheet" component={DailyTimeSheetTable} />
    <Route exact path="/dailytimesheet/addnew" component={DailyTimeSheetForm} />
  </Switch>
);
