import styled from "styled-components";
import { CustomLoader, FilterButton } from "./TableElements";
import {
  colorBackgroundBody,
  colorLightGray,
  colorTextNew,
} from "../../utils/palette";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SplitButton = ({
  mainActionLabel,
  subActionLabel,
  mainActionHandler,
  subActionHandler,
  handleResumeWOClick,
  handlePauseWOClick,
  handleCloseWOClick,
  workOrderStatus,
  resumeWOBtnLoading,
}) => {
  const { t } = useTranslation("common");

  //console.log("workOrderStatus", workOrderStatus);

  const [open, setOpen] = useState(false);
  const closeRef = useRef(null);

  //console.log("subactionhandler", subActionHandler);
  //console.log("typeofsubactionhandler", typeof subActionHandler);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!closeRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [closeRef]);

  const mainActionClick = () => {
    if (workOrderStatus === 1) {
      handleCloseWOClick();
    } else if (workOrderStatus === 2) {
      handleResumeWOClick();
    }
  };

  const subActionClick = () => {
    if (workOrderStatus === 1) {
      handlePauseWOClick();
    } else if (workOrderStatus === 2) {
      handleCloseWOClick();
    }
  };

  return (
    <div ref={closeRef}>
      <MainContainer>
        <ButtonContainer
          onClick={mainActionClick}
          type="button"
          disabled={resumeWOBtnLoading}
          // isDropdownOpen={open}
          //style={{ background: "#2EB26B", color: 'white' }}
        >
          {resumeWOBtnLoading ? (
            <CustomLoader />
          ) : workOrderStatus === 1 ? (
            t("close_work_order")
          ) : (
            t("resume_work_order")
          )}
        </ButtonContainer>
        <IconContainer
          type="button"
          onClick={() => setOpen((prev) => !prev)}
          isDropdownOpen={open}
        >
          <i className="fi fi-bs-angle-small-down"></i>
        </IconContainer>
      </MainContainer>
      {open && (
        <DropdownWrap onClick={subActionClick}>
          <DropdownItem type="button">
            {workOrderStatus === 1
              ? t("pause_work_order")
              : t("close_work_order")}
          </DropdownItem>
        </DropdownWrap>
      )}
    </div>
  );
};

export default SplitButton;

const DropdownWrap = styled.div`
  //background: white;
  position: absolute;
  //height: 32px;
  cursor: pointer;
  //padding: 0.25rem 0;
  z-index: 1;
  //width: 150px;
`;

const DropdownItem = styled.button`
  padding: 0.3rem 1.5rem;
  background: white;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  //border: none;
  display: block;
  //width: -webkit-fill-available;
  text-align: justify;
  &:hover {
    background: ${colorLightGray};
  }
`;

export const IconContainer = styled.div`
  border-left: 1px solid ${colorLightGray};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  i {
    margin-top: 0.25rem;
  }

  &:hover {
    background: ${colorBackgroundBody};
  }

  ${(p) => p.isDropdownOpen && `background: ${colorBackgroundBody}`}
`;

export const ButtonContainer = styled.button`
  outline: none;
  background: none;
  border: none;

  //width: 150px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
  font-size: 14px;
  color: ${colorTextNew};
  //height: 34px;
  //border: 1px solid ${colorLightGray};
  //border-right: 0;
  //border-radius: 5px;
  padding: 0 10px;
  margin: 0;
  min-width: 100px;
  white-space: nowrap;
  //background: white;
  font-weight: 500;

  svg {
    margin-right: 5px;
    margin-bottom: 1px;
  }

  i {
    margin: 5px 0 0 24px;
  }

  &:hover {
    ${(p) => !p.disabled && `background: ${colorLightGray}`};
  }
`;

export const MainContainer = styled.div`
  display: flex;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: white;
  height: 32px;
`;
