import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addAdvancedFilter } from "../../../../../features/advancedFilter/advancedFilterSlice";
import { CheckboxInput, Label } from "../ColumnReorder";
import { ContainsFilterContainer } from "./ContainsFilter";
import {
  InputRadioContainer,
  SaveButton,
  SaveButtonContainer,
} from "./RangeFilter";

const TypeFilter = ({ commonProps, top, left, data }) => {
  const {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    setLocalFilters,
  } = commonProps;

  const { column, name, isFiltered, type, ...rest } = data;
  const typeArray = Object.keys(rest);
  const entriesArray = Object.entries(rest);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  //Logic for toggling filter icon on selection/deselection of type
  //const checkedArr = Object.values(rest).map((obj) => obj.value);
  // const isSelected = checkedArr.reduce(
  //   (isSelected, item) => item || isSelected,
  //   false
  // );

  // useEffect(() => {
  //   setFilterData((prev) => {
  //     const newData = {
  //       ...data,
  //       isFiltered: isSelected,
  //     };
  //     return { ...prev, [column]: newData };
  //   });
  // }, [isSelected]);

  // useEffect(() => {
  //   const currentFilterCol = appliedFilters.find(
  //     (col) => col?.column === data?.column
  //   );
  //   if (currentFilterCol) {
  //     const { headersArray, ...rest } = currentFilterCol;
  //     setFilterData((prev) => {
  //       return { ...prev, [currentFilterCol?.column]: { ...rest } };
  //     });
  //   }
  // }, [appliedFilters]);

  const handleCheckboxChange = (item) => {
    let { column, name, isFiltered, ...rest } = data;
    let newCheckObj = {
      ...rest,
      [item[0]]: { header: item[1]?.header, value: !item[1].value },
    };

    const checkedArr = Object.values(newCheckObj).map((obj) => obj.value);

    const isSelected = checkedArr.reduce(
      (isSelected, item) => item || isSelected,
      false
    );

    setFilterData((prev) => {
      const newData = {
        ...data,
        [item[0]]: { ...item[1], value: !item[1].value },
        isFiltered: isSelected,
      };
      return { ...prev, [column]: newData };
    });
  };

  const handleApply = () => {
    const { column, name, ...rest } = data;
    const apiData = {};
    const headersArray = [];
    for (let key in rest) {
      if (rest[key].value) {
        headersArray.push(rest[key].header);
        apiData[key] = 1;
      }
    }
    //keysArr.forEach(key => apiData[key] = rest[key][value]);
    if (screen.includes("inspectionscheduling")) {
      setLocalFilters((prev) => {
        const temp = prev.filter((f) => f.type !== column);
        temp.push({
          id: column,
          type: column,
          data: apiData,
        });
        return temp;
      });
    } else {
      dispatch(addAdvancedFilter({ ...apiData, name, column, screen }));
    }
    setAppliedFilters((prev) => {
      const newArray = prev.filter((item) => item.column !== data.column);
      const dataWithHeaders = { ...data, headersArray };
      newArray.push(dataWithHeaders);
      return newArray;
    });
  };

  return (
    <ContainsFilterContainer top={top} left={left} id="type-filter">
      {entriesArray.map((item) => {
        let styles = {};
        let status = item[0];
        if (
          screen.includes("reminders") ||
          screen.includes("renewals") ||
          screen.includes("inspectionscheduling")
        ) {
          styles.fontWeight = 800;
          if (status === "scheduled") {
            styles.color = "#5CB85C";
          } else if (status === "dueSoon") {
            styles.color = "#F0AD4E";
          } else if (status === "overdue") {
            styles.color = "#D9534F";
          }
        }
        return (
          <InputRadioContainer key={item[0]}>
            <Label style={styles}>
              <CheckboxInput
                name={item[0]}
                type="checkbox"
                checked={data[item[0]].value}
                onChange={() => handleCheckboxChange(item)}
              />
              {item[1].header}
            </Label>
          </InputRadioContainer>
        );
      })}
      {screen.includes("inspectionscheduling") && (
        <SaveButtonContainer>
          <SaveButton
            id="apply-filter-button"
            variant="primary"
            onClick={handleApply}
          >
            {t("apply")}
          </SaveButton>
        </SaveButtonContainer>
      )}
    </ContainsFilterContainer>
  );
};

export default TypeFilter;

//styles
//export const Label = styled.label``;
//export const CheckboxInput = styled.label``;
