import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FilterButton } from "../../shared/components/TableElements";
import { LinkSpan } from "../../shared/components/form/FormElements";
import { useState } from "react";
import SignUpDetailsForm from "./SignUpDetailsForm";
import { auth } from "../../api/firebase";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { AppleSignInButton, GoogleSignInButton } from "./LoginForm";
import { ReactComponent as GoogleLogo } from "../../../src/shared/img/googleLogo.svg";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const SignUpForm = ({
  signUpMode,
  setSignUpMode,
  activeKey,
  setActiveKey,
  signUpWithEmail,
  setSignUpWithEmail,
}) => {
  const { t } = useTranslation("common");
  //States for Loading Buttons
  const [googleBtnLoading, setGoogleBtnLoading] = useState(false);
  const [appleBtnLoading, setAppleBtnLoading] = useState(false);

  const { setIsLoggedIn } = useAuth();

  useEffect(() => {
    if (auth?.currentUser && activeKey == 2) {
      localStorage.setItem("logout", true);
      signOut(auth);
      setIsLoggedIn(false);
    }
  }, []);

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      // provider.setCustomParameters({
      //   prompt: "select_account",
      // });
      //provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      setGoogleBtnLoading(true);
      //const auth = getAuth();
      await signInWithPopup(auth, provider);
      document.cookie = `uiPreference=new; path=/; domain=.simplyfleet.app`;
      setSignUpMode("google");
      setSignUpWithEmail(true);
      setGoogleBtnLoading(false);
    } catch (error) {
      console.log(error);
      setGoogleBtnLoading(false);
    }
  };

  const handleAppleSignUp = async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      setAppleBtnLoading(true);
      await signInWithPopup(auth, provider);
      document.cookie = `uiPreference=new; path=/; domain=.simplyfleet.app`;
      setSignUpMode("apple");
      setSignUpWithEmail(true);
      setAppleBtnLoading(false);
    } catch (error) {
      console.log(error);
      setAppleBtnLoading(false);
    }
  };

  return (
    <>
      {!signUpWithEmail && (
        <Container
          className="d-flex flex-column gap-3 p-5"
          id="sign-up-container"
        >
          <Row className="d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              <FilterButton
                id="sign-up-with-email"
                onClick={() => setSignUpWithEmail(true)}
                style={{
                  width: "-webkit-fill-available",
                  height: "40px",
                  color: "#003f7d",
                }}
              >
                {t("email_sign_up")}
              </FilterButton>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              <GoogleSignInButton
                id="sign-up-with-google"
                onClick={handleGoogleSignUp}
                // style={{
                //   width: "-webkit-fill-available",
                //   height: "34px",
                //   color: "#003f7d",
                // }}
              >
                <GoogleLogo width={18} height={18} />
                <span>{t("google_sign_up")}</span>
              </GoogleSignInButton>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              <AppleSignInButton
                id="sign-up-with-apple"
                onClick={handleAppleSignUp}
                style={{
                  width: "-webkit-fill-available",
                  height: "34px",
                  color: "#003f7d",
                }}
              >
                <i className="fi fi-brands-apple" />
                <span>{t("apple_sign_up")}</span>
              </AppleSignInButton>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center pt-2">
            <Col md={8} className="d-flex justify-content-center">
              <div
                className="d-flex align-items-center gap-1"
                id="already-have-an-acc"
              >
                {t("already_have_acc")}
                <LinkSpan onClick={() => setActiveKey(1)} id="login-span">
                  {t("login")}
                </LinkSpan>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default SignUpForm;
