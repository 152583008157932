import { Route, Switch } from "react-router";
import FillupsTable from "./FillupsTable";
import FillupsForm from "./FillupsForm";

export default () => (
  <Switch>
    <Route exact path="/fillups" component={FillupsTable} />
    <Route exact path="/fillups/addnew" component={FillupsForm} />
  </Switch>
);
