import React, { useState, useEffect, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import { useTranslation } from "react-i18next";
import DataReactTable from "../DataTable/components/DataReactTable";
import {
  initialContainsData,
  initialfuelTypeData,
  initialRangeData,
  initialTransactionTypeData,
} from "../../shared/constants/filterData";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
} from "../../shared/components/TableElements";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedFuelTypeUnit,
  getTranslatedTransactionType,
  getVolumeUnit,
} from "../../shared/helpers";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import { putApi } from "../../api/getUser";
import ExplorePopup from "../../shared/components/ExplorePopup";
import { clearAllAdvancedFilters } from "../../features/advancedFilter/advancedFilterSlice";
import { LinkSpan } from "../../shared/components/form/FormElements";
import { useLocation } from "react-router";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const FillupsTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const location = useLocation();
  //const fromCancel = location.state?.fromCancel;
  //const [fromCancel, setFromCancel] = useState(fromCancelBtn);
  //console.log(fromCancel);

  const { data, values, status } = useSelector((state) => state.filter);
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;
  //let { start_date, end_date, ...otherData } = data;
  const columns = useSelector((state) => state.advancedFilter.fillups);
  const newData = { ...data, ...columns };
  const rowData = useSelector((state) => state.filter.rowData);
  const optionValues = useSelector((state) => state.filter.values);
  const { minDate, maxDate, noOfRecords } = useSelector(
    (state) => state.columns
  );
  const { pathname } = location;
  const { currency, consumption, distance, volume, hourlyConsumption } =
    useSelector((state) => state.units);

  const users = useSelector((state) => state.users.list);
  const { orgDate } = useSelector((state) => state.user);

  const {
    isLoggedIn,
    reload,
    setReload,
    setLoading,
    //fromCancel,
    //setFromCancel,
  } = useAuth();

  //Logic for first time load(Reload start_date and end_date)
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "getallfillups",
    sortIndex: 2,
  });

  //On initial load, send update initial data
  // useEffect(() => {
  //   //window.history.replaceState({}, "");
  //   if (
  //     pathname.includes("fillups") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //   ) {
  //     (async () => {
  //       //setFromCancel(true);
  //       setLoading(true);
  //       if (columns) {
  //         dispatch(
  //           clearAllAdvancedFilters({
  //             columns: Object.keys(columns),
  //             screen: "fillups",
  //           })
  //         );
  //       }
  //       const startDate = 0;
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "fillups",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //         dispatch(
  //           updateUserSettingsData({
  //             col_order: colOrder,
  //             min_date: minDate,
  //             default_rows: defaultRows,
  //           })
  //         );
  //       }

  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
  //         index = colOrderUI.findIndex((col) => col === 2);
  //       }

  //       dispatch(
  //         updateInitialData({
  //           query: "getallfillups",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         // filter_group_id: "",
  //         // filter_vehicle_id: "",
  //         filter_group_id,
  //         filter_vehicle_id,
  //         querystring: "getallfillups",
  //       };

  //       const initialAdvancedFilterCols = {
  //         odo: {},
  //         distance: {},
  //         quantity: {},
  //         totalCost: {},
  //         efficiency: {},
  //         fillingStation: {},
  //         transactionType: {},
  //         cardNumber: {},
  //         fuelBrand: {},
  //         fuelType: {},
  //         comments: {},
  //       };

  //       dispatch(fetchData({ ...initialData, ...initialAdvancedFilterCols }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();

  //     // dispatch(
  //     //   updateInitialData({
  //     //     query: "getallfillups",
  //     //     startDate,
  //     //     endDate,
  //     //     order: index,
  //     //     length: noOfRecords ? noOfRecords : 25,
  //     //   })
  //     // );
  //     // const initialData = {
  //     //   draw: 1,
  //     //   length: noOfRecords ? noOfRecords : 25,
  //     //   search: {
  //     //     value: "",
  //     //     regex: false,
  //     //   },
  //     //   order: [
  //     //     {
  //     //       column: index,
  //     //       dir: "desc",
  //     //     },
  //     //   ],
  //     //   start_date: 0,
  //     //   end_date: getUnixTime(new Date()),
  //     //   start: 0,
  //     //   filter_group_id: "",
  //     //   filter_vehicle_id: "",
  //     //   querystring: "getallfillups",
  //     // };

  //     // dispatch(fetchData({ ...initialData, ...columns }));
  //     // setTimeout(() => {
  //     //   setFirstTimeLoad(false);
  //     // }, 2000);
  //   }
  // }, [isLoggedIn, orgDate]);

  // //Fetch data on every filter change expect for start and set start to 0
  // useEffect(() => {
  //   if (
  //     pathname.includes("fillups") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getallfillups" &&
  //     !reload
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns, start: 0 }));
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   //querystring,
  //   columns,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (
  //     pathname.includes("fillups") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getallfillups" &&
  //     !reload
  //   ) {
  //     console.log("Effect with start change - fillups ");
  //     dispatch(fetchData({ ...data, ...columns }));
  //   }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   if (
  //     pathname.includes("fillups") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getallfillups" &&
  //     reload.includes("fillups")
  //   ) {
  //     console.log("Reload Effect");
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns, start: 0 }));
  //     //setLoading(true);
  //     setReload("");
  //   }
  // }, [reload]);

  // useEffect(() => {
  //   //console.log(firstTimeLoad);
  //   if (firstTimeLoad) {
  //     const startDate = 0;
  //     //console.log(startDate);
  //     const endDate = getUnixTime(new Date());
  //     start_date = 0;
  //     end_date = getUnixTime(new Date());
  //     dispatch(
  //       fetchData({
  //         ...otherData,
  //         ...columns,
  //         querystring: "getallfillups",
  //         start_date,
  //         end_date,
  //       })
  //     );
  //     //console.log(firstTimeLoad)
  //     setFirstTimeLoad(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!firstTimeLoad) {
  //     dispatch(fetchData(newData));
  //   }
  // }, [data, columns]);

  //KPI Values
  let fillupsValuesArray = [
    {
      id: 0,
      key: "footertotalcost",
      name: t("cost"),
      value: optionValues?.footertotalcost
        ? `${+optionValues.footertotalcost?.toFixed(2)} ${currency}`
        : NATEXT,
      // units: currency,
      // icon: "currency",
    },
    {
      id: 1,
      key: "footertotalqty",
      name: t("qty_tv"),
      value: optionValues?.footertotalqty
        ? `${+optionValues.footertotalqty?.toFixed(2)} ${getVolumeUnit(volume)}`
        : NATEXT,
      // units: volume,
      // icon: "gasStation",
    },
    {
      id: 2,
      key: "footertotaleff",
      name: t("avg_eff"),
      // value:
      //   optionValues?.footertotaleff && consumption !== "L/100km"
      //     ? `${+optionValues.footertotaleff?.toFixed(2)} ${consumption}`
      //     : optionValues?.footertotaleff && consumption === "L/100km"
      //     ? `${(100 / +optionValues.footertotaleff)?.toFixed(2)} ${consumption}`
      //     : NATEXT,
      value: optionValues?.footertotaleff
        ? `${+optionValues.footertotaleff?.toFixed(2)} ${consumption}`
        : NATEXT,
      // units: consumption,
      // icon: "gauge",
    },
    // {
    //   id: 3,
    //   key: "footerhourlyeff",
    //   name: t("hourly_eff"),
    //   value: optionValues?.footerhourlyeff
    //     ? `${+optionValues.footerhourlyeff?.toFixed(2)} ${consumption}`
    //     : NATEXT,
    // },
  ];

  if (optionValues?.footerhourlyeff) {
    fillupsValuesArray.push({
      id: 3,
      key: "footerhourlyeff",
      name: t("hourly_eff"),
      value: optionValues?.footerhourlyeff
        ? `${+optionValues.footerhourlyeff?.toFixed(2)} ${hourlyConsumption}`
        : NATEXT,
    });
  }

  const {
    defaultContainsData,
    defaultRangeData,
    defaultTransactionTypeData,
    defaultFuelTypeData,
  } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      date: {
        column: "date",
        name: t("date_tv"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      odo: {
        column: "odo",
        name: t("odo"),
        ...defaultRangeData,
      },
      distance: {
        column: "distance",
        name: t("distance"),
        ...defaultRangeData,
      },
      quantity: {
        column: "quantity",
        name: t("qty_tv"),
        ...defaultRangeData,
      },
      totalCost: {
        column: "totalCost",
        name: t("tc_tv"),
        ...defaultRangeData,
      },
      efficiency: {
        column: "efficiency",
        name: t("efficiency"),
        ...defaultRangeData,
      },
      fillingStation: {
        column: "fillingStation",
        name: t("fs_tv"),
        ...defaultContainsData,
      },
      transactionType: {
        column: "transactionType",
        name: t("transaction_type"),
        ...defaultTransactionTypeData,
      },
      cardNumber: {
        column: "cardNumber",
        name: t("card_number"),
        ...defaultContainsData,
      },
      fuelBrand: {
        column: "fuelBrand",
        name: t("fb_tv"),
        ...defaultContainsData,
      },
      fuelType: {
        column: "fuelType",
        name: t("fuel_type"),
        ...defaultFuelTypeData,
      },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );

  // const initialData = {
  //   date: {
  //     column: "date",
  //     name: t("date_tv"),
  //     ...getInitialDateData(),
  //   },
  //   odo: {
  //     column: "odo",
  //     name: t("odo"),
  //     ...initialRangeData,
  //   },
  //   distance: {
  //     column: "distance",
  //     name: t("distance"),
  //     ...initialRangeData,
  //   },
  //   quantity: {
  //     column: "quantity",
  //     name: t("qty_tv"),
  //     ...initialRangeData,
  //   },
  //   totalCost: {
  //     column: "totalCost",
  //     name: t("tc_tv"),
  //     ...initialRangeData,
  //   },
  //   efficiency: {
  //     column: "efficiency",
  //     name: t("efficiency"),
  //     ...initialRangeData,
  //   },
  //   fillingStation: {
  //     column: "fillingStation",
  //     name: t("fs_tv"),
  //     ...initialContainsData,
  //   },
  //   transactionType: {
  //     column: "transactionType",
  //     name: t("transaction_type"),
  //     ...initialTransactionTypeData,
  //   },
  //   cardNumber: {
  //     column: "cardNumber",
  //     name: t("card_number"),
  //     ...initialContainsData,
  //   },
  //   fuelBrand: {
  //     column: "fuelBrand",
  //     name: t("fb_tv"),
  //     ...initialContainsData,
  //   },
  //   fuelType: {
  //     column: "fuelType",
  //     name: t("fuel_type"),
  //     ...initialfuelTypeData,
  //   },
  //   comments: {
  //     column: "comments",
  //     name: t("notes_tv"),
  //     ...initialContainsData,
  //   },
  // };

  // const rows = [];

  // for (let i = 0; i < rowData?.length; i++) {
  //   rows.push({
  //     name: rowData[i].name ? rowData[i].name.slice(0, 20) : NATEXT,
  //     fillup_date: rowData[i].fillup_date
  //       ? `${rowData[i].fillup_date}`
  //       : NATEXT,
  //     odo: rowData[i].odo ? `${rowData[i].odo}` : NATEXT,
  //     distance: rowData[i].distance ? `${rowData[i].distance} Km` : NATEXT,
  //     qty: rowData[i].qty ? `${rowData[i].qty}` : NATEXT,
  //     qty_unit: rowData[i].qty_unit,
  //     total_cost: rowData[i].total_cost ? `${rowData[i].total_cost}` : NATEXT,
  //     eff: rowData[i].eff ? `${(rowData[i].eff * 100).toFixed(1)}` : NATEXT,
  //     filled_by: rowData[i].filled_by ? `${rowData[i].filled_by}` : NATEXT,
  //     filling_station: rowData[i].filling_station
  //       ? rowData[i].filling_station.slice(0, 20)
  //       : NATEXT,
  //     transaction_type: rowData[i].transaction_type
  //       ? rowData[i].transaction_type
  //       : NATEXT,
  //     card_number: rowData[i].card_number ? rowData[i].card_number : NATEXT,
  //     fuel_brand: rowData[i].fuel_brand ? rowData[i].fuel_brand : NATEXT,
  //     fuel_type: rowData[i].fuel_type ? rowData[i].fuel_type : NATEXT,
  //     comments: rowData[i].comments ? rowData[i].comments.slice(0, 70) : NATEXT,
  //     row_id: rowData[i].fillup_id ? rowData[i].fillup_id : NATEXT,
  //     group_name: rowData[i].group_name
  //       ? rowData[i].group_name.slice(0, 20)
  //       : NATEXT,
  //     veh_id: rowData[i].veh_id,
  //     veh_img: rowData[i].veh_img,
  //     org_id: rowData[i].org_id,
  //     primary_meter: rowData[i].primary_meter,
  //     odo_unit: rowData[i].odo_unit,
  //     consumption: rowData[i].consumption,
  //     currency: rowData[i].currency,
  //     m_fill: rowData[i].m_fill ? rowData[i].m_fill : NATEXT,
  //     p_fill: rowData[i].p_fill ? rowData[i].p_fill : NATEXT,
  //     octane: rowData[i].octane ? rowData[i].octane : NATEXT,
  //     receipt_img_name: rowData[i].receipt_img_name
  //       ? rowData[i].receipt_img_name
  //       : NATEXT,
  //   });
  // }

  // const getColumnWidth = (accessor, headerText, rows = rowData) => {
  //     console.log("a", accessor, rowData);
  //     const maxWidth = 300;
  //     const magicSpacing = 11;
  //     const cellLength = Math.max(
  //       ...rows.map((row) => (`${row[accessor]}` || "").length),
  //       headerText.length
  //     );
  //     console.log('CellLength', cellLength);
  //     return Math.min(maxWidth, cellLength * magicSpacing);
  // };

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        //width: getColumnWidth("name", "Vehicle"),
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("date_tv"),
        accessor: "fillup_date",
        disableCol: true,
        //disableFilters: true,
        //width: getColumnWidth("fillup_date", "Date"),
        Cell: ({ value }) => (value ? getListingDisplayDate(value) : NATEXT),
      },
      {
        id: 3,
        Header: t("odo"),
        accessor: "odo",
        //disableFilters: true,
        //width: getColumnWidth("odo", "Odo"),
        // disableGlobalFilter: false,
        Cell: ({ row }) =>
          `${row.original.odo} ${getDistanceUnit(row.original.odo_unit)}`,
      },
      {
        id: 4,
        Header: t("distance"),
        accessor: "distance",
        Cell: ({ row }) => {
          const { m_fill } = row?.original;
          const rowDist = row?.original?.distance;
          let distanceValue = rowDist
            ? `${rowDist} ${getDistanceUnit(row.original.odo_unit)}`
            : NATEXT;
          if (m_fill) {
            distanceValue = t("missed_fillup");
          }

          return distanceValue;
        },

        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
      },
      {
        id: 5,
        Header: t("qty_tv"),
        accessor: "qty",
        //disableFilters: true,
        //width: getColumnWidth("qty", "Quantity"),
        // disableGlobalFilter: false,
        Cell: ({ row }) => {
          const { qty, qty_unit, p_fill } = row.original;
          let qtyString = `${row.original.qty} ${getVolume(
            row.original.qty_unit
          )}`;
          if (!qty) {
            return "";
          }
          if (p_fill) {
            qtyString += ` (P)`;
          }
          return qtyString;
        },
      },
      {
        id: 6,
        Header: t("tc_tv"),
        accessor: "total_cost",
        // disableGlobalFilter: false,
        disableSortBy: false,
        //disableFilters: true,
        //width: getColumnWidth("total_cost", "Total Cost"),
        // Cell: ({ value }) =>
        //   value ? `${+value?.toFixed(2)} ${currency}` : NATEXT,
        Cell: ({ value }) => {
          //console.log(value);
          let numberedValue = +value;
          if (numberedValue) {
            return `${numberedValue?.toFixed(2)} ${currency}`;
          } else {
            return NATEXT;
          }
        },
      },
      {
        id: 7,
        Header: t("efficiency"),
        accessor: "eff",
        //disableFilters: true,
        //width: columnWidth,
        // disableGlobalFilter: false,
        Cell: ({ row }) => {
          const { consumption, eff, primary_meter, odo_unit } = row?.original;
          let efficiency = eff ? `${eff} ${consumption}` : NATEXT;
          if (consumption == "L/100km" && eff && primary_meter != "Hours") {
            efficiency = `${(100 / eff)?.toFixed(2)} ${consumption}`;
          }
          if (odo_unit === "Hours") {
            efficiency = `${eff} ${hourlyConsumption}`;
          }
          return eff ? efficiency : NATEXT;
        },
      },
      {
        id: 8,
        Header: t("filled_by"),
        accessor: "uname",
        disableFilters: true,
        //Cell: ({ value }) => users?.find((u) => u.user_id === value)?.label,
        //width: columnWidth,
        // disableGlobalFilter: false,
      },
      {
        id: 9,
        Header: t("fs_tv"),
        accessor: "filling_station",
        accessorId: "task_name",
        Cell: ({ value }) => (value ? `${value}` : NATEXT),
        //disableFilters: true,
        //width: 150,
        // disableGlobalFilter: false,
      },
      {
        id: 10,
        Header: t("transaction_type"),
        accessor: "transaction_type",
        Cell: ({ value }) =>
          value ? getTranslatedTransactionType(value, t) : NATEXT,
        //disableFilters: true,
        //width: 150,
      },
      {
        id: 11,
        Header: t("card_number"),
        accessor: "card_number",
        Cell: ({ value }) => (+value ? +value : NATEXT),
        //disableFilters: true,
        //width: columnWidth,
      },
      {
        id: 12,
        Header: t("fb_tv"),
        accessor: "fuel_brand",
        Cell: ({ value }) => (value ? value : NATEXT),
        //disableFilters: true,
        //width: columnWidth,
      },
      {
        id: 13,
        Header: t("fuel_type"),
        accessor: "fuel_type",
        Cell: ({ value }) =>
          value ? getTranslatedFuelTypeUnit(value, t) : NATEXT,
        //disableFilters: true,
        //width: columnWidth,
      },
      {
        id: 14,
        Header: t("notes_tv"),
        accessor: "comments",
        accessorId: "comments",
        Cell: ({ value }) => (value ? value : NATEXT),
        //disableFilters: true,
        //width: columnWidth,
      },
      // {
      //   id: 14,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    [rowData, distance, currency, consumption]
  );

  let count = 0;
  let defaultColumns = {};
  columnHeaders.forEach((column) => {
    defaultColumns[count] = {
      accessor: column.Header,
      header: column.Header,
    };
    count++;
  });

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IDIGFZHN"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          {/* <Col>
            <ColumnHeading>{t("fillups")}</ColumnHeading>
          </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("fillups")}</ColumnHeading>
            {/* <FilterButton onClick={() => setShowExplorePopup(true)}>
              <i
                className="fi fi-rr-globe"
                style={{ margin: "4px 4px 0 0" }}
              ></i>
              <span>{t("explore")}</span>
            </FilterButton> */}
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col className="d-flex justify-content-end mb-1">
            <Link to={"/fillups/addnew"}>
              <AddFormButton id="add-fillup-button">
                {t("add_fill_up")}
              </AddFormButton>
            </Link>
          </Col>
        </Row>
        {/* <Row> */}
        {/* <Col>  */}
        {/* <div style={{ height: "100%" }}> */}
        <DataReactTable
          reactTableData={reactTableData}
          valuesArray={fillupsValuesArray}
          initialData={initialData}
          defaultColumns={defaultColumns}
        />
        {/* </div> */}
        {/* </Col> */}
        {/* </Row> */}
      </Container>
    </>
    // <div style={{ height: '90%', background: 'lightblue' }}>Hi</div>
  );
};

export default FillupsTable;

export const getVolume = (value) => {
  const { t } = useTranslation("common");

  if (value === "Liters") {
    return t("ltr_const");
  } else if (value === "Gallons (UK)") {
    return t("gal_uk_const");
  } else if (value === "Gallons (US)") {
    return t("gal_us_const");
  }
};
