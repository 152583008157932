import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '@/shared/components/Loading';

const directionFunc = (location, rtl) => (location.pathname === '/' ? 'ltr' : rtl.direction);

const MainWrapper = ({ children, location }) => {
  const { direction } = useSelector(state => ({
    direction: state.direction,
    // isFetching: state.appConfig.isFetching,
  }));
  const dispatch = useDispatch();
  const isFetching = false;
  
  // useEffect(() => {
  //   dispatch(fetchAppConfig());
  // }, []);

  return (
    <Fragment>
      {isFetching ? (
        <Loading loading={isFetching} />
      ) : (
        <div
          className={`${directionFunc(location, direction)}-support`}
          dir={directionFunc(location, direction)}
        >
          <div className="wrapper">
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(MainWrapper);
