import React, { useState } from "react";
import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import DataReactTable from "../../DataTable/components/DataReactTable";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../../features/filter/filterSlice";
import VehicleComponent from "../../../shared/components/table/components/VehicleComponent";
import {
  initialContainsData,
  initialRangeData,
  initialStatusData,
} from "../../../shared/constants/filterData";
import RowOptions from "../../../shared/components/table/components/RowOptions";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import { Link } from "react-router-dom";
import { NATEXT, initialLoadDelay } from "../../../shared/constants";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getDisplayDate,
  getDistanceUnit,
  getListingDisplayDate,
} from "../../../shared/helpers";
import { useLocation } from "react-router";
import { putApi } from "../../../api/getUser";
import { updateUserSettingsData } from "../../../features/columns/columnsSlice";
import getUnixTime from "date-fns/getUnixTime";
import { LinkSpan } from "../../../shared/components/form/FormElements";
import ExplorePopup from "../../../shared/components/ExplorePopup";
import useFetchData from "../../../shared/custom-hooks/useFetchData";
import ProgressBar from "../../../shared/components/ProgressBar";
import getDefaultFilterData from "../../../shared/constants/getDefaultFilterData";

const MaintenanceRemindersTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;
  //const fromCancel = location.state?.fromCancel;
  const { data, values } = useSelector((state) => state.filter);
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;
  const columns = useSelector(
    (state) => state.advancedFilter.maintenancereminders
  );
  const newData = {
    ...data,
    ...columns,
    querystring: "get_all_service_reminder_data_v2",
  };
  const rowData = useSelector((state) => state.filter.rowData);

  const { reload, setReload, isLoggedIn, setLoading } = useAuth();
  const { distance } = useSelector((state) => state.units);

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const { minDate } = useSelector((state) => state.columns);

  const { orgDate } = useSelector((state) => state.user);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "get_all_service_reminder_data_v2",
    sortIndex: 3,
  });

  // useEffect(() => {
  //   if (
  //     pathname.includes("maintenancereminders") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //   ) {
  //     (async () => {
  //       setLoading(true);
  //       const startDate = 0;
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "maintenancereminders",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       dispatch(
  //         updateInitialData({
  //           query: "get_all_service_reminder_data_v2",
  //           startDate,
  //           endDate,
  //           order: 2,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: 2,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "get_all_service_reminder_data_v2",
  //       };
  //       dispatch(fetchData({ ...initialData, ...columns }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // //Fetch data on every filter change expect for start and set start to 0
  // useEffect(() => {
  //   if (
  //     pathname.includes("reminders") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "get_all_service_reminder_data_v2" &&
  //     !reload
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns, start: 0 }));
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   //querystring,
  //   columns,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (
  //     pathname.includes("reminders") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "get_all_service_reminder_data_v2" &&
  //     !reload
  //   ) {
  //     dispatch(fetchData({ ...data, ...columns }));
  //   }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   if (reload === "maintenancereminders" && isLoggedIn && !firstTimeLoad) {
  //     setReload("");
  //     dispatch(fetchData(newData));
  //   }
  // }, [reload]);

  const { defaultContainsData, defaultRangeData, defaultStatusData } =
    getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      // status: {
      //   column: "status",
      //   name: t("status"),
      //   ...defaultStatusData,
      // },
      task: {
        column: "task",
        name: t("task"),
        ...defaultContainsData,
      },
      daysRemaining: {
        column: "daysRemaining",
        name: t("days_remaining"),
        ...defaultRangeData,
      },
      odoRemaining: {
        column: "odoRemaining",
        name: t("odo_remaining"),
        ...defaultRangeData,
      },
      lastServiceOdo: {
        column: "lastServiceOdo",
        name: t("last_service_odo"),
        ...defaultRangeData,
      },
      lastServiceDate: {
        column: "lastServiceDate",
        name: t("last_service_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
        mode: "custom",
      },
      dueOdo: {
        column: "dueOdo",
        name: t("due_odo"),
        ...defaultRangeData,
      },
      dueDate: {
        column: "dueDate",
        name: t("due_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
        mode: "custom",
      },
    }),
    [minDate]
  );

  // Row Data
  const rows = [];

  // for (let i = 0; i < rowData.length; i++) {
  //   rows.push({
  //     reminder_id: rowData[i].reminder_id,
  //     row_id: rowData[i].reminder_id,
  //     service_task_id: rowData[i].service_task_id,
  //     NAME: rowData[i].NAME ? rowData[i].NAME.slice(0, 20) : NATEXT,
  //     service_task_name: rowData[i].service_task_name
  //       ? `${rowData[i].service_task_name}`
  //       : NATEXT,
  //     DUE_ODO: rowData[i]["DUE ODO"] ? rowData[i]["DUE ODO"] : NATEXT,
  //     MAX_ODO: rowData[i]["MAX ODO"] ? rowData[i]["MAX ODO"] : NATEXT,
  //     DUE_IN: rowData[i]["DUE IN"] ? rowData[i]["DUE IN"] : NATEXT,
  //     PERCENTAGE: rowData[i].PERCENTAGE ? `${rowData[i].PERCENTAGE} ` : NATEXT,
  //     TIME_PERCENTAGE: rowData[i].TIME_PERCENTAGE
  //       ? `${rowData[i].TIME_PERCENTAGE} `
  //       : NATEXT,
  //     ODO_PERCENTAGE: rowData[i].ODO_PERCENTAGE
  //       ? `${rowData[i].ODO_PERCENTAGE} `
  //       : NATEXT,
  //     due_days: rowData[i].due_days ? rowData[i].due_days : NATEXT,
  //     due_odo: rowData[i].due_odo ? rowData[i].due_odo : NATEXT,
  //     last_date_of_service: rowData[i].last_date_of_service
  //       ? `${rowData[i].last_date_of_service} `
  //       : NATEXT,
  //     last_odo_of_service: rowData[i].last_odo_of_service
  //       ? `${rowData[i].last_odo_of_service} `
  //       : NATEXT,
  //     odo_threshold: rowData[i].odo_threshold
  //       ? `${rowData[i].odo_threshold}`
  //       : NATEXT,
  //     days_threshold: rowData[i].days_threshold
  //       ? `${rowData[i].days_threshold}`
  //       : NATEXT,

  //     veh_id: rowData[i].veh_id,
  //     veh_img: rowData[i].veh_img,
  //     org_id: rowData[i].org_id,
  //     primary_meter: rowData[i].primary_meter,
  //   });
  // }

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.NAME}
            groupName="N/A"
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("task"),
        accessor: "service_task_name",
        accessorId: "task_name",
      },
      {
        id: 3,
        Header: t("status"),
        accessor: "status",
        //disableSortBy: true,
        Cell: ({ row }) => {
          //console.log(row);
          const { due_days, last_date_of_service } = row.original;
          let due = last_date_of_service + Number(due_days) * 86400;
          let currentDate = Math.floor(Date.now() / 1000);
          let dueIn = ((due - currentDate) / (60 * 60 * 24)).toFixed(0);
          return (
            <StatusComponent
              due_days={row.original.due_days}
              dueOdo={row.original}
              distance={row.original["DUE ODO"] - row.original["MAX ODO"]}
              percentage={row.original.PERCENTAGE}
              days={dueIn}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: 4,
        Header: `${t("due_on")}`, //(${getDistanceUnit(distance)}/${t("date_tv")}),
        accessor: "due",
        disableSortBy: true,
        Cell: ({ row }) => {
          let dueDate = new Date(row.original.last_date_of_service * 1000);
          const dueDays = +row.original.due_days;
          let lastServiceDate = new Date(
            row.original.last_date_of_service * 1000
          );
          dueDate.setDate(lastServiceDate.getDate() + dueDays);
          dueDate =
            dueDate && dueDate instanceof Date && !isNaN(dueDate)
              ? getListingDisplayDate(dueDate / 1000) //dueDate.toLocaleDateString("en-US")
              : NATEXT;
          return (
            <DueComponent
              distance={row.original["DUE ODO"]}
              date={dueDate}
              due_days={row.original.due_days}
              due_odo={row.original.due_odo}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: 5,
        Header: `${t("last_serviced")}`, //(${getDistanceUnit(distance)}/${t("date_tv")}),
        accessor: "last_service",
        disableSortBy: true,
        Cell: ({ row }) => {
          const dueDate = new Date(row.original.last_date_of_service * 1000);
          const lastServiceDate =
            dueDate && dueDate instanceof Date && !isNaN(dueDate)
              ? getListingDisplayDate(dueDate / 1000) //dueDate.toLocaleDateString("en-US")
              : NATEXT;
          return (
            <LastServiceComponent
              distance={row.original.last_odo_of_service}
              date={lastServiceDate}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: "edit",
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row?.original} />,
        disableSortBy: true,
      },
      // {
      //   id: "edit",
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    [distance]
  );

  //Default Columns
  let count = 0;
  let defaultColumns = {};
  columnHeaders.forEach((column) => {
    defaultColumns[count] = {
      accessor: column.Header,
      header: column.Header,
    };
    count++;
  });

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IYBSWVIM"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          {/* <Col
          sm={10}
          //className="d-flex justify-content-start align-items-center"
          >
          <ColumnHeading>{t("maintenance_reminders")}</ColumnHeading>
        </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("maintenance_reminders")}</ColumnHeading>

            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            <Link to={"/reminders/maintenancereminders/addnew"}>
              <AddFormButton>{t("add_reminder")}</AddFormButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            //valuesArray={fillupsValuesArray}
            initialData={initialData}
            defaultColumns={defaultColumns}
          />
        </Row>
      </Container>
    </>
  );
};

export default MaintenanceRemindersTable;

export const DueComponent = ({
  distance,
  date,
  due_odo,
  due_days,
  rowData,
}) => {
  let distanceUnit = useSelector((state) => state.units.distance);
  if (rowData?.primary_meter === "Hours") {
    distanceUnit = "Hours";
  }
  let distanceText =
    due_odo && distance && distance !== NATEXT
      ? `${distance} ${getDistanceUnit(distanceUnit)}`
      : NATEXT;
  let dateText =
    due_days && due_days !== NATEXT && date && date !== NATEXT ? date : NATEXT;
  //console.log(distance, due_odo, due_days);
  return (
    <div>
      <div>
        {/* {distance} {!distance?.toLocaleString().includes("N/A") ? "Km" : ""}{" "} */}
        {distanceText}
      </div>
      <div>{dateText}</div>
    </div>
  );
};

export const LastServiceComponent = ({ distance, date, rowData }) => {
  let distanceUnit = useSelector((state) => state.units.distance);
  if (rowData?.primary_meter === "Hours") {
    distanceUnit = "Hours";
  }
  let distanceText =
    distance && distance !== NATEXT
      ? `${distance} ${getDistanceUnit(distanceUnit)}`
      : NATEXT;
  let dateText = date && date !== NATEXT ? date : NATEXT;
  return (
    <div>
      <div>
        {/* {distance} {!distance?.toLocaleString().includes("N/A") ? "Km" : ""}{" "} */}
        {distanceText}
      </div>
      <div>{dateText}</div>
    </div>
  );
};

export const StatusComponent = ({
  dueOdo,
  due_days,
  distance,
  percentage,
  days,
  rowData,
}) => {
  const { t } = useTranslation("common");
  let distanceUnit = useSelector((state) => state.units.distance);
  if (rowData?.primary_meter === "Hours") {
    distanceUnit = "Hours";
  }
  const distanceVal = distance && distance > 0 ? distance.toFixed(2) : "";
  const distanceText =
    dueOdo.due_odo && distance && distance > 0 && distance !== NATEXT
      ? `${t("due_in_sm")} ${distanceVal} ${getDistanceUnit(distanceUnit)} `
      : "";
  const daysText =
    due_days && days && days > 0 && days !== NATEXT
      ? `${t("due_in_sm")} ${days} ${t("days_sm", { count: +days })}`
      : "";
  let bottomText = "";
  if (distanceText === "" && daysText !== "") {
    bottomText = daysText;
  } else if (distanceText !== "" && daysText === "") {
    bottomText = distanceText;
  } else if (distanceText !== "" && daysText !== "") {
    bottomText = `${t("due_in_sm")} ${distanceVal} ${getDistanceUnit(
      distanceUnit
    )} ${t("or")} ${days} ${t("days_sm", { count: +days })}`;
  }
  let statusText = "SCHEDULED";
  let color = "#5CB85C";
  let background = "";
  if (percentage <= 90) {
    statusText = "SCHEDULED";
    color = "#5CB85C";
  } else if (percentage > 90 && percentage < 100) {
    statusText = "DUE SOON";
    color = "#F0AD4E";
  } else if (percentage >= 100) {
    statusText = "OVERDUE";
    color = "#D9534F";
    bottomText = "";
  }
  return (
    <div
      className="d-flex flex-column justify-content-center gap-2"
      style={{ paddingRight: "1.25rem" }}
    >
      {/* <div> */}
      {/* <p
        style={{
          color: color,
          //background: "lightgreen",
          width: "80px",
          //textAlign: "center",
          fontWeight: 800,
          fontSize: "12px",
        }}
      >
        {statusText}
      </p> */}

      {/* <div style={{ margin: "0 8px 8px 0" }}> */}
      <ProgressBar color={color} now={percentage} />
      {/* </div> */}

      {/* </div> */}
      {bottomText && <p style={{ fontSize: "11px" }}>{bottomText}</p>}
    </div>
  );
};
