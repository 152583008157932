import React from "react";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IssueStatus } from "../../../../../components/Issues/IssuesTable";
import format from "date-fns/format";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { useSelector } from "react-redux";
import { getDisplayDateWithTimeNew, getDistanceUnit } from "../../../../helpers";

const IssuesRightPanel = ({
  rowData,
  clamped,
  setClamped,
  showReadMoreButton,
  notesRef,
  setShowReadMoreButton,
  editData,
  setEditData,
}) => {
  const { t } = useTranslation("common");

  const usersList = useSelector((state) => state.users.list);
  const { orgId } = useSelector((state) => state.user);

  const { distance } = useSelector((state) => state.units);

  const {
    close_date,
    comments,
    inspection_id,
    issue,
    issue_desc,
    issue_id,
    issue_img_name,
    name,
    odo,
    priority,
    reported_by,
    reported_date,
    secondary_status,
    service_id,
    source,
    status,
    primary_meter,
    user_name,
    veh_id,
    veh_img,
    work_order_id,
  } = rowData;

  const priorityText =
    priority == 0
      ? t("not_set")
      : priority == 1
      ? t("low")
      : priority == 2
      ? t("med")
      : priority == 3
      ? t("high")
      : "";

  // const reportedAtText = format(
  //   new Date(reported_date * 1000),
  //   "d-MMM-yyyy h:mm a"
  // );

  const reportedAtText = getDisplayDateWithTimeNew(reported_date);


  const issueImgs = [];
  if (issue_img_name) {
    const issueImgNames = issue_img_name?.split(":::");
    issueImgNames?.forEach((imgName, i) => {
      issueImgs.push({
        id: imgName,
        url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/issues/${issue_id}/${imgName}`,
      });
    });
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("status")}</ItemLabel>
            <ItemValue>
              <IssueStatus status={status} />
            </ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("priority")}</ItemLabel>
            <ItemValue>{priorityText}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("issue")}</ItemLabel>
            <ItemValue>{issue}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("odometer")}</ItemLabel>
            <ItemValue>
              {odo}{" "}
              {getDistanceUnit(primary_meter === "Hours" ? "Hours" : distance)}
            </ItemValue>
          </ItemContainer>
        </Col>
        {issue_desc && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("issue_desc")}</ItemLabel>
              <ItemValue>{issue_desc}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("reported_at")}</ItemLabel>
            <ItemValue>{reportedAtText}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("reported_by")}</ItemLabel>
            <ItemValue>{user_name}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={12}>
          <ItemContainer>
            <ItemLabel>{t("source")}</ItemLabel>
            <ItemValue>{source}</ItemValue>
          </ItemContainer>
        </Col>
        {comments && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {issueImgs.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {issueImgs.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default IssuesRightPanel;
