import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  HintText,
  Label,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import { Col, Container, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { format } from "date-fns";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import {
  CustomInput,
  Desc,
  TimeSelect,
} from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { postApi } from "../../../api/getUser";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactorforOdo,
  getImgString,
  getInitialStartTime,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  isObjEmpty,
  uploadDocsToS3,
} from "../../../shared/helpers";
import useFetchOdoHint from "../../../shared/custom-hooks/useFetchOdoHint";
import { scrollbarStyles } from "../../../utils/palette";
import CheckboxInputComponent from "../../../shared/components/form/CheckboxInputComponent";
import getUnixTime from "date-fns/getUnixTime";
import ErrorPopup from "../../../shared/components/ErrorPopup";
const IncomeFormPopup = ({
  showPopup,
  setShowPopup,
  newRecordData,
  setNewRecordData,
  depDate,
}) => {
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  //hook-form variables
  const vehicle = watch("vehicle");
  const date = watch("date");
  const desc = watch("desc");
  const invoiceNo = watch("invoiceNo");
  const billedTo = watch("billedTo");
  const amount = watch("amount");

  const [paid, setPaid] = useState(false);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.slice(1, vehiclesList.length);
  const users = useSelector((state) => state.users.list);
  const { userId, orgId } = useSelector((state) => state.user);
  const { currency } = useSelector((state) => state.units);
  const { distance } = useSelector((state) => state.units);

  const type = newRecordData?.type;
  const rowData = newRecordData?.rowData;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Initial Effect
  //Effect to set initial values
  useEffect(() => {
    if (type === "add") {
      setValue(
        "vehicle",
        vehicles?.find((v) => v.vehicleId === newRecordData?.vehicleId)
      );
      setValue("date", depDate);
    }
  }, []);

  //Effect for edit
  useEffect(() => {
    if (type === "edit") {
      console.log(rowData);
      setValue(
        "vehicle",
        vehicles?.find((v) => v.vehicleId === newRecordData?.vehicleId)
      );
      setValue("date", new Date(rowData.income_date * 1000));
      setValue("billedTo", rowData.billed_to);
      setValue("amount", rowData.amount);
      setValue("desc", rowData.income_desc);
      setValue("invoiceNo", rowData.invoice_number);
      setPaid(+rowData.paid);
    }
  }, []);

  const handleClose = () => {
    setNewRecordData((prev) => ({
      ...prev,
      screenName: "",
      type: "",
      newRecordId: "",
    }));
    // reset({
    //   expenseTaskName: "",
    //   comments: "",
    // });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const submitIncome = async () => {
    try {
      setSaveBtnLoading(true);
      const incomeId = type === "edit" ? rowData.income_id : ``; //${vehicle.vehicleId}_${Date.now()}

      const incomeApiData = {
        InvoiceNumber: invoiceNo ? invoiceNo : "",
        billedTo: billedTo ? billedTo : "",
        incomeId: incomeId,
        insertIncomeAmount: amount ? amount : "",
        insertIncomeDate: getUnixTime(date),
        insertIncomeDesc: desc,
        insertIncomeVehicle: vehicle.vehicleId,
        PaidUnpaid: paid ? 1 : 0,
        querystring: "insert_income",
        action: type === "edit" ? 2 : 1,
        //sync_version: "v2",
        source: "web",
      };

      const res = await postApi(incomeApiData, "commonNew");
      if (res.success) {
        const newIncomeId = res.inserted_income_id;
        setNewRecordData((prev) => ({
          ...prev,
          newRecordId: newIncomeId,
          //newVehicleId,
        }));
        setSaveBtnLoading(false);
        setShowPopup(false);
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };
  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <Modal show={showPopup} size="md">
        <Modal.Header>
          <h4>{type === "edit" ? t("edit_income") : t("add_income")}</h4>
          <div
            className="d-flex justify-content-end"
            //style={{ width: "-webkit-fill-available" }}
          >
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <ModalBody>
          <FormContainer
          //onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      {/* Vehicle */}
                      <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            isDisabled={true}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col>
                      <FormGroupLabel>{t("date_tv")}</FormGroupLabel>
                      <Controller
                        name="date"
                        //defaultValue={new Date()}
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <DatePicker
                            id="datepicker"
                            //minDate={new Date()}
                            selected={value}
                            onChange={onChange}
                            customInput={
                              <CustomInput
                              //disabled={status == 1 || status == 2}
                              />
                            }
                            placeholderText={t("date_tv")}
                            locale={getDateFnsLocale()}
                            dateFormat={"P"}
                            showMonthDropdown
                            showYearDropdown
                            //disabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{
                          required: t("required_err"),
                        }}
                      />
                      {errors?.date && <Error error={errors.date.message} />}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroupLabel>{t("invoice_no")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="invoiceNo"
                          control={control}
                          component="input"
                          type="text"
                          defaultValue=""
                          placeholder={t("invoice_no")}
                          style={formInputStyles}
                          //disabled={false}
                        />
                      </FormItem>

                      {errors?.invoiceNo && (
                        <Error error={errors.invoiceNo.message} />
                      )}
                    </Col>
                    <Col>
                      <FormGroupLabel>{t("billed_to")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="billedTo"
                          control={control}
                          component="input"
                          type="text"
                          defaultValue=""
                          placeholder={t("billed_to")}
                          style={formInputStyles}
                          //disabled={false}
                        />
                      </FormItem>

                      {errors?.billedTo && (
                        <Error error={errors.billedTo.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroupLabel>{t("desc")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="desc"
                          control={control}
                          rules={{ required: t("required_err") }}
                          component="input"
                          type="text"
                          defaultValue=""
                          placeholder={t("desc")}
                          style={formInputStyles}
                          //disabled={false}
                        />
                      </FormItem>

                      {errors?.desc && <Error error={errors.desc.message} />}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroupLabel>{t("amount")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="amount"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={(e) => handleNegative(e)}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          defaultValue=""
                          placeholder={t("amount")}
                          style={formInputStyles}
                          //disabled={false}
                        />
                        <UnitSpan>{currency}</UnitSpan>
                      </FormItem>

                      {errors?.amount && (
                        <Error error={errors.amount.message} />
                      )}
                    </Col>
                    <Col>
                      {/* <FormGroupLabel>{t("amount")}</FormGroupLabel> */}
                      <FormValueContainer style={{ marginTop: "2.5rem" }}>
                        <CheckboxInputComponent
                          id={"paid"}
                          checked={paid}
                          setChecked={setPaid}
                          label={t("this_income_is_paid")}
                        />
                      </FormValueContainer>
                    </Col>
                  </Row>

                  {/* <Row className="pt-2">
                  <Col>
                    <FormValueContainer>
                      <CheckboxInputComponent
                        id={"paid"}
                        checked={paid}
                        setChecked={setPaid}
                        label={"This income is paid"}
                      />
                    </FormValueContainer>
                  </Col>
                </Row> */}
                </CardBody>
              </Card>
            </Container>
          </FormContainer>
        </ModalBody>
        <Modal.Footer>
          <AddFormButton
            id="save-form-button"
            type="button"
            onClick={handleSubmit(submitIncome)}
            disabled={saveBtnLoading}
          >
            {saveBtnLoading ? <CustomLoader /> : t("save")}
          </AddFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IncomeFormPopup;

//styles

const ModalBody = styled(Modal.Body)`
  margin: 0;
  padding: 0;
  height: ${window.innerHeight - 220}px;
  overflow: auto;
  ${scrollbarStyles}
`;
