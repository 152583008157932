import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import NotificationsIcon from "mdi-react/NotificationsIcon";
import {
  TopbarBack,
  TopbarButton,
  TopbarButtonNewLabel,
} from "./BasicTopbarComponents";
import {
  TopbarCollapse,
  TopbarCollapseButton,
  TopbarCollapseContent,
  TopbarCollapseDate,
  TopbarCollapseImageWrap,
  TopbarCollapseItem,
  TopbarCollapseLink,
  TopbarCollapseMessage,
  TopbarCollapseName,
  TopbarCollapseTitle,
  TopbarCollapseTitleWrap,
} from "./CollapseTopbarComponents";
import { postApi } from "../../../../api/getUser";
import { getDisplayDate } from "../../../../shared/helpers";
import styled from "styled-components";
import { Desc } from "../../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  colorBlue,
  colorLightGray,
  scrollbarStyles,
  sidenavColor,
} from "../../../../utils/palette";
import getUnixTime from "date-fns/getUnixTime";
import { ActionIconContainer } from "../../../../shared/components/TableElements";
import { useTranslation } from "react-i18next";

const TopbarNotification = () => {
  const { t } = useTranslation("common");
  const [notifications, setNotifications] = useState([]);
  const [showNotificationsUnread, setShowNotificationsUnread] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await postApi(
        { querystring: "get_all_notification" },
        "commonNew"
      );
      if (res.success) {
        const { user_data } = res;
        let temp = [];
        if (user_data.length > 0) {
          let count = 1;
          for (let n of user_data) {
            let heading = "";
            const { pk_1, pk_2, pk_3 } = n;
            const pk_2_length = pk_2.length;
            if (pk_2 != "" && pk_3 == "" && pk_2_length == 10) {
              heading = "Scheduled Maintenance";
            } else if (pk_2 != "" && pk_3 == "DTS") {
              heading = "Daily Timesheet";
            } else if (pk_2 != "" && pk_3 == "SEM") {
              heading = "Daily Mileage";
            } else if (pk_2 != "" && pk_3 == "INS") {
              heading = "Inspection Schedule";
            } else {
              heading = "Scheduled Maintenance";
            }
            temp.push({ ...n, heading, id: count });
            count += 1;
          }

          // const notificationsUnread = user_data
          //   .map((n) => n.notification_read)
          //   .some((flag) => !flag);
          const notificationsUnread = user_data.some(
            (n) => n.notification_timestamp > n.last_read_notification_timestamp
          );

          setShowNotificationsUnread(notificationsUnread);
          setNotifications(temp);
        } else {
          setShowNotificationsUnread(false);
        }
      }
    })();
  }, []);

  const collapseNotification = async () => {
    if (isCollapsed) {
      await postApi(
        {
          querystring: "save_notification",
          timestamp: getUnixTime(new Date()),
        },
        "commonNew"
      );
    }
    if (showNotificationsUnread && !isCollapsed) {
      setShowNotificationsUnread(false);
    }
    setIsCollapsed(!isCollapsed);
  };

  const handleCloseClick = async (n) => {
    const { pk_1, pk_2, pk_3, id } = n;
    const apiData = {
      timestamp: getUnixTime(new Date()),
      querystring: "edit_notification",
      pk_1,
      pk_2,
      pk_3,
    };
    const res = await postApi(apiData, "commonNew");
    if (res.success) {
      setNotifications((prev) => prev.filter((noti) => noti.id !== id));
    }
  };

  return (
    <TopbarCollapse>
      <TopbarButton type="button" onClick={collapseNotification}>
        <NotificationsIcon />
        {!isCollapsed && showNotificationsUnread && (
          <TopbarButtonNewLabel>
            <span />
          </TopbarButtonNewLabel>
        )}
      </TopbarButton>
      {isCollapsed && (
        <TopbarBack
          aria-label="topbar__back"
          type="button"
          onClick={collapseNotification}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarCollapseContent style={{ width: "auto" }}>
          <TopbarCollapseTitleWrap>
            <TopbarCollapseTitle>{t("notifications")}</TopbarCollapseTitle>
            {/* <TopbarCollapseButton type="button">Mark all as read</TopbarCollapseButton> */}
          </TopbarCollapseTitleWrap>
          <NotificationsWrap>
            {notifications.length === 0 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "inherit" }}
              >
                {t("No notifications")}
              </div>
            )}
            {notifications.map((notification, i) => (
              // <TopbarCollapseItem key={i}>
              //   {/* <TopbarCollapseImageWrap>
              //   <img src={notification.ava} alt="" />
              // </TopbarCollapseImageWrap> */}

              //   <TopbarCollapseMessage>
              //     <TopbarCollapseName>
              //       {notification.message}
              //     </TopbarCollapseName>
              //   </TopbarCollapseMessage>
              //   <TopbarCollapseDate>
              //     {getDisplayDate(notification.notification_timestamp)}
              //   </TopbarCollapseDate>
              // </TopbarCollapseItem>
              <NotificationContainer key={i}>
                <div
                  className="d-flex flex-column gap-1"
                  style={{ width: 220 }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: sidenavColor,
                      fontWeight: 500,
                    }}
                  >
                    {notification.heading}
                  </p>
                  <p style={{ fontSize: "12px" }}>{notification.message}</p>
                </div>
                <Desc style={{ textWrap: "nowrap", alignSelf: "start" }}>
                  {getDisplayDate(notification.notification_timestamp)}
                </Desc>
                {/* <ActionIconContainer
                  style={{ alignSelf: "start" }}
                  //onClick={() => setShowDeleteConfirmationPopup(false)}
                  id="remove-notification"
                > */}
                <CloseIcon
                  className="fi fi-rr-cross-small"
                  onClick={() => handleCloseClick(notification)}
                  //style={{ alignSelf: "start", cursor: "pointer" }}
                />
                {/* </ActionIconContainer> */}
              </NotificationContainer>
            ))}
          </NotificationsWrap>
          {/* <TopbarCollapseLink to="/online_marketing_dashboard" onClick={collapseNotification}>
            See all notifications
          </TopbarCollapseLink> */}
        </TopbarCollapseContent>
      </Collapse>
    </TopbarCollapse>
  );
};

export default TopbarNotification;

//style
const NotificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem;
`;

const NotificationsWrap = styled.div`
  height: 300px;
  overflow: auto;
  //width: 400px;
  min-width: 400px;
  ${scrollbarStyles};
`;

const CloseIcon = styled.i`
  cursor: pointer;
  align-self: start;
  font-size: 14px;
  //padding: 0 0.2rem;
  &:hover {
    color: ${colorLightGray};
  }
`;
