import { Route, Switch } from "react-router";
import RenewalsForm from "./RenewalsForm.jsx";
import RenewalsTable from "./RenewalsTable.jsx";

export default () => (
  <Switch>
    <Route
      exact path="/reminders/renewals"
      component={RenewalsTable}
    />
    <Route
      exact path="/reminders/renewals/addnew"
      component={RenewalsForm}
    />
  </Switch>
);
