import { useEffect, useState } from "react";
import styled from "styled-components";
import infoImg from "../../../../src/shared/img/info-popup.png";
import {
  ActionIcon,
  FilterButton,
} from "../../../shared/components/TableElements";
import { CheckboxInput } from "../../../shared/components/table/components/ColumnReorder";
import {
  CheckBoxInput,
  Label,
} from "../../../shared/components/form/FormElements";
import { colorLightGray } from "../../../utils/palette";
import { useTranslation } from "react-i18next";

export const InfoPopup = ({
  closePopup,
  dontShowPopup,
  showInfoPopup,
  setShowInfoPopup,
  handleDontShow,
}) => {
  const { t } = useTranslation("common");

  const [transform, setTransform] = useState(false);

  //const [showInfoPopup, setShowInfoPopup] = useState(true);

  const normalStyle = { width: 0, height: 0, transition: "height 1s" };
  const transformedStyle = {
    width: "420px", //window.innerWidth - 1000,
    height: "375px", //window.innerHeight - 305,
    transition: "height 1s",
    //transform: "rotate(180deg)",
  };

  useEffect(() => {
    setTimeout(() => {
      setTransform(true);
    }, 1000);
  });

  const handleCheckboxChange = (e) => {
    //console.log(e.target.checked);
    const { checked } = e.target;
    if (checked === true) {
      localStorage.setItem("dont-show-popup", true);
      //handleDontShow(e.target.checked);
    }
  };

  const handleClose = () => {
    sessionStorage.setItem("close-popup", true);
    setShowInfoPopup(false);
  };

  return (
    <InfoContainer
      //transform={transform}
      style={transform ? transformedStyle : normalStyle}
    >
      <div className="d-flex justify-content-end align-items-center">
        <ActionIcon
          className="fi fi-br-cross-small p-1"
          onClick={handleClose}
        ></ActionIcon>
      </div>
      <img
        src={infoImg}
        alt="submissions-popup-info"
        width={400}
        style={{ alignSelf: "center" }}
        height={300}
      />
      <div className="p-2">
        <CheckBoxInput
          type="checkbox"
          id="dont-show-popup"
          style={{ marginRight: "0.5rem" }}
          onChange={handleCheckboxChange}
        />
        <Label htmlFor="dont-show-popup">{t("dont_show_again")}</Label>
      </div>
    </InfoContainer>
  );
};

export const InfoContainer = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: #d8e6ed;
  bottom: 0%;
  right: 2%;
  border: 1px solid;
  border-radius: 5px;
  transition: "height 2s";
  overflow: hidden;
`;
