import React from "react";
import { Route, Switch } from "react-router-dom";
import UserManagementTable from "./UserManagementTable";
import UserManagementForm from "./UserManagementForm";

export default () => (
  <Switch>
    <Route exact path="/users" component={UserManagementTable} />
    <Route exact path="/users/addnew" component={UserManagementForm} />
  </Switch>
);
