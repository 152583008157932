import React from "react";
import format from "date-fns/format";
import { Col, Modal, Row } from "react-bootstrap";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import { useTranslation } from "react-i18next";
import {
  daysHoursMinutes,
  getDisplayDateWithTime,
  getDistanceUnit,
  getTimeInHoursAndMinutes,
} from "../../../../helpers";
import { useSelector } from "react-redux";
import { dhm } from "../../../../../components/WorkOrders";
import { LinkSpan } from "../../../form/FormElements";
import { useEffect } from "react";
import { useState } from "react";
import {
  getSecondaryTableColumns,
  //expensesColumns,
  //fillupsColumns,
  //incomeColumns,
  updateExpensesData,
  updateFillupsData,
  updateIncomeData,
  updateTable,
} from "../../../../../components/Trips";
import {
  ActionIconContainer,
  AddFormButton,
  ColumnHeaderRow,
  DataRow,
  TableContainer,
} from "../../../TableElements";
import ImageWithZoom from "../../../form/ImageWithZoom";

const TripsRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");

  const {
    arr_date,
    arr_loc,
    arr_odo,
    dep_date,
    dep_loc,
    dep_odo,
    odo_unit,
    row_id,
    trip_by,
    trip_id,
    avg_speed,
    receipt_image_name,
    trip_expenses,
    trip_fillups,
    trip_income,
    trip_distance,
    veh_id,
    trip_time,
    primary_meter,
    comments,
  } = rowData;

  const { distance, currency, consumption } = useSelector(
    (state) => state.units
  );

  const { orgId, userId } = useSelector((state) => state.user);

  const [fillupsData, setFillupsData] = useState([]);

  const [expensesData, setExpensesData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);

  const [tableData, setTableData] = useState(null);
  const tripDocs = [];
  let images = "";
  if (receipt_image_name) {
    images = receipt_image_name?.split(":::");
    images?.forEach((imgName, i) => {
      //console.log(imgName);
      tripDocs.push({
        id: imgName,
        url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/trips/${imgName}`,
      });
    });
  }

  const { fillupsColumns, expensesColumns, incomeColumns } =
    getSecondaryTableColumns();

  useEffect(() => {
    (async () => {
      setFillupsData([]);
      setExpensesData([]);
      setIncomeData([]);
      //Logic to update inner tables
      if (trip_fillups) {
        const fillupsApiData = {
          querystring: "gettripfillups",
          tripFillupIds: trip_fillups,
        };
        await updateTable(
          fillupsApiData,
          updateFillupsData,
          setFillupsData,
          {
            currency,
            distance: odo_unit,
            consumption,
          },
          t
        );
      }

      if (trip_income) {
        const incomeApiData = {
          querystring: "gettripIncome",
          tripIncomeIds: trip_income,
        };
        await updateTable(
          incomeApiData,
          updateIncomeData,
          setIncomeData,
          {
            currency,
            distance: odo_unit,
          },
          t
        );
      }

      if (trip_expenses) {
        const expensesApiData = {
          querystring: "gettripExpense",
          tripExpenseIds: trip_expenses,
        };

        await updateTable(
          expensesApiData,
          updateExpensesData,
          setExpensesData,
          { currency, distance: odo_unit },
          t
        );
      }
    })();
  }, [rowData]);

  return (
    <>
      {tableData && (
        <ReadOnlySecondaryTable
          tableData={tableData}
          setTableData={setTableData}
        />
      )}
      <Row>
        <Col md={6}>
          <ItemContainer id="item-container">
            <ItemLabel id="item-label">{t("dep_date_and_time")}</ItemLabel>
            <ItemValue id="item-value">
              {getDisplayDateWithTime(dep_date)}
            </ItemValue>
          </ItemContainer>
        </Col>

        {dep_loc && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("dep_loc")}</ItemLabel>
              <ItemValue id="item-value">{dep_loc}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!dep_odo && (
          <Col md={12}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("dep_odo")}</ItemLabel>
              <ItemValue id="item-value">{`${dep_odo} ${getDistanceUnit(
                odo_unit
              )}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!arr_date && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("arr_date_and_time")}</ItemLabel>
              <ItemValue id="item-value">
                {getDisplayDateWithTime(arr_date)}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {arr_loc && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("arr_loc")}</ItemLabel>
              <ItemValue id="item-value">{arr_loc}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!arr_odo && (
          <Col md={12}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("arr_odo")}</ItemLabel>
              <ItemValue id="item-value">{`${arr_odo} ${getDistanceUnit(
                odo_unit
              )}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!trip_distance && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("distance_travelled")}</ItemLabel>
              <ItemValue id="item-value">{`${trip_distance} ${getDistanceUnit(
                odo_unit
              )}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!avg_speed && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("average_speed")}</ItemLabel>
              <ItemValue id="item-value">{`${avg_speed} ${getDistanceUnit(
                odo_unit
              )}/hr`}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!trip_time && (
          <Col md={6}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("trip_duration")}</ItemLabel>
              <ItemValue id="item-value">
                {daysHoursMinutes(trip_time).display}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {comments && (
          <Col md={12}>
            <ItemContainer id="item-container">
              <ItemLabel id="item-label">{t("notes_tv")}</ItemLabel>
              <ItemValue id="item-value">{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {tripDocs.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {tripDocs.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {fillupsData.length > 0 && (
          <Col md={12} className="my-2">
            <ItemValue>
              <LinkSpan
                onClick={() =>
                  setTableData({
                    rows: fillupsData.map((row) => row.displayData),
                    columns: fillupsColumns.filter(
                      (col) => col.accessor !== "edit"
                    ),
                    heading: t("trip_fillups"),
                  })
                }
              >{`${fillupsData.length} ${
                fillupsData.length === 1 ? t("trip_fillup") : t("trip_fillups")
              }`}</LinkSpan>
            </ItemValue>
          </Col>
        )}

        {expensesData.length > 0 && (
          <Col md={12} className="my-2">
            <ItemValue>
              <LinkSpan
                onClick={() =>
                  setTableData({
                    rows: expensesData.map((row) => row.displayData),
                    columns: expensesColumns.filter(
                      (col) => col.accessor !== "edit"
                    ),
                    heading: t("trip_expenses"),
                  })
                }
              >{`${expensesData.length} ${
                expensesData.length === 1
                  ? t("trip_expense")
                  : t("trip_expenses")
              }`}</LinkSpan>
            </ItemValue>
          </Col>
        )}

        {incomeData.length > 0 && (
          <Col md={12} className="my-2">
            <ItemValue>
              <LinkSpan
                onClick={() =>
                  setTableData({
                    rows: incomeData.map((row) => row.displayData),
                    columns: incomeColumns.filter(
                      (col) => col.accessor !== "edit"
                    ),
                    heading: t("trip_income"),
                  })
                }
              >{`${incomeData.length} ${
                incomeData.length === 1 ? t("trip_income") : t("trip_incomes")
              }`}</LinkSpan>
            </ItemValue>
          </Col>
        )}
      </Row>
    </>
  );
};

export default TripsRightPanel;

export const ReadOnlySecondaryTable = ({ tableData, setTableData }) => {
  const { t } = useTranslation("common");

  const { rows, columns, heading } = tableData;

  const handleClose = () => {
    setTableData(null);
  };
  return (
    <Modal show={tableData} onHide={() => setTableData(null)} size="lg">
      <Modal.Header>
        <h4>{heading}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>

      <Modal.Body>
        <TableContainer>
          <ColumnHeaderRow>
            {columns?.map((col, id) => {
              return (
                <Col key={id}>
                  <span>{col.header}</span>
                </Col>
              );
            })}
          </ColumnHeaderRow>

          {rows.map((row) => (
            <DataRow key={row.rowId}>
              {Object.keys(row).map((key) => {
                return (
                  key !== "rowId" && (
                    <Col key={key}>
                      <span>{row[key]}</span>
                    </Col>
                  )
                );
              })}
            </DataRow>
          ))}
        </TableContainer>
      </Modal.Body>

      <Modal.Footer>
        <AddFormButton id="ok-button" type="button" onClick={handleClose}>
          {t("ok")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};
