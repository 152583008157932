import React from "react";
import { Col, Row } from "react-bootstrap";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import { getDistanceUnit, getListingDisplayDate } from "../../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DailyMileageRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");

  const { distance } = useSelector((state) => state.units);
  const {
    dist,
    starting_odo,
    ending_odo,
    row_id,
    timestamp,
    uname,
    comments,
    primary_meter,
    odo_require,
  } = rowData;
  return (
    <Row>
      {uname && (
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("entered_by")}</ItemLabel>
            <ItemValue>{uname}</ItemValue>
          </ItemContainer>
        </Col>
      )}
      {timestamp && (
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>
              {/* {new Date(timestamp * 1000).toLocaleDateString(
                navigator.language,
                {
                  day: "numeric",
                  year: "numeric",
                  month: "short",
                }
              )} */}
              {getListingDisplayDate(timestamp)}
            </ItemValue>
          </ItemContainer>
        </Col>
      )}
      {!!(starting_odo && +starting_odo > 0) && !!odo_require && (
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("starting_odo")}</ItemLabel>
            <ItemValue>
              {starting_odo}{" "}
              {getDistanceUnit(primary_meter === "Hours" ? "Hours" : distance)}
            </ItemValue>
          </ItemContainer>
        </Col>
      )}
      {!!(ending_odo && +ending_odo > 0) && !!odo_require && (
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("ending_odo")}</ItemLabel>
            <ItemValue>
              {ending_odo}{" "}
              {getDistanceUnit(primary_meter === "Hours" ? "Hours" : distance)}
            </ItemValue>
          </ItemContainer>
        </Col>
      )}

      {!!(dist && +dist >= 0) && (
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("distance")}</ItemLabel>
            <ItemValue>
              {dist}{" "}
              {getDistanceUnit(primary_meter === "Hours" ? "Hours" : distance)}
            </ItemValue>
          </ItemContainer>
        </Col>
      )}

      {comments && (
        <Col sm={12}>
          <ItemContainer>
            <ItemLabel>{t("notes_tv")}</ItemLabel>
            <ItemValue>{comments}</ItemValue>
          </ItemContainer>
        </Col>
      )}
    </Row>
  );
};

export default DailyMileageRightPanel;
