import { useRef, useEffect } from "react";

const useClickAway = ({ handleCloseElement }) => {
  const ref = useRef(null);

  //Close anything that is open when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        handleCloseElement();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return ref;
};

export default useClickAway;
