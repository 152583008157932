import React, { useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
  Ticks,
  LegendElement,
  LegendOptions,
  //Scale,
} from "chart.js";
import enUS from "date-fns/locale/en-US";
import "chartjs-adapter-date-fns";
import de from "date-fns/locale/de";
import { TableSpinner } from "../../shared/components/table/components/ReactTableConstructor";
import { colorBorder, colorGray, colorLightGray } from "../../utils/palette";
import { useTranslation } from "react-i18next";

const SingleChart = ({
  graphData,
  graphQuery,
  //lineChartOptions
}) => {
  const { t } = useTranslation("common");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
    //Ticks
    //Scale
  );
  const chartLineColor = "#919190";

  const lineChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          padding: 10,
          color: "orange",
          boxWidth: 4,
          boxHeight: 4,
          border: 1,
          align: "center",
          textAlign: "center",

          font: {
            size: 14,
          },
        },

        position: "top",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          if (graphData) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";
            if (label) {
              label += ": ";
            }
            label += tooltipItem.yLabel.toFixed(2);
            return label;
          }
        },
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        id: "x_axis",
        autoSkip: true,
        display: true,
        type: "time",
        ticks: {
          autoSkip: true,
          //source: "data",
          color: chartLineColor,
          maxTicksLimit: 10,
        },

        time: {
          displayFormats: {
            millisecond: "DD MMMM YYYY HH:mm",
            second: "mm:ss",
            minute: "HH:mm",
            hour: "HH:mm",
            day: "dd MMM",
            week: "DD MMM",
            month: "DD MMMM",
            quarter: "[Q]Q - YYYY",
            year: "YYYY",
          },
          //parser: "MM/DD/YYYY HH:mm",
          parser: "MM dd yyyy",
          tooltipFormat: "dd MMM yyyy",
          unit: "day",
          unitStepSize: 1,
        },
        grid: {
          display: false,
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
      },

      y: {
        display: true,
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: chartLineColor,
        },
        ticks: {
          autoSkip: true,
          color: chartLineColor,
          maxTicksLimit: 10,
        },
      },
    },
  };

  const barChartOptions = {
    maintainAspectRatio: false,
    scaleBeginAtZero: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        id: "timescale",
        type: "time",
        unit: "day",
        unitStepSize: 1,
        ticks: {
          autoSkip: true,
          color: chartLineColor,
          maxTicksLimit: 10,
        },
        autoSkip: true,

        time: {
          displayFormats: {
            millisecond: "DD MMMM YYYY HH:mm",
            second: "mm:ss",
            minute: "HH:mm",
            hour: "HH:mm",
            day: "dd MMM",
            week: "DD MMM",
            month: "DD MMMM",
            quarter: "[Q]Q - YYYY",
            year: "YYYY",
          },
          //parser: "MM/DD/YYYY HH:mm",
          parser: "MM dd yyyy",
          tooltipFormat: "dd MMM yyyy",
          unit: "day",
          unitStepSize: 1,
        },

        barPercentage: 1,
        categoryPercentage: 1,
        barThickness: 0.3,
        maxBarThickness: 0.3,
        minBarLength: 10,
        display: true,
        position: "bottom",
        scaleLabel: {
          display: true,
        },
        offset: true,

        grid: {
          display: false,
        },
      },

      y: {
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: chartLineColor,
        },
        display: true,
        ticks: {
          autoSkip: true,
          color: chartLineColor,
          maxTicksLimit: 10,
        },
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        //width: 700,
        //height: 300,
        //width: "-webkit-fill-available",
        //height: "-webkit-fill-available",
        width: window.innerWidth - 650 + "px",
        // border: "1px solid lightgray",
        height: window.innerHeight - 350 + "px",
        marginRight: "1rem",
      }}
    >
      {!graphQuery ? (
        <div
          style={{ fontWeight: 500, fontSize: "26px" }}
          //className="d-flex justify-content-center align-items-center"
        >
          {t("graph_not_available")}
        </div>
      ) : (
        <>
          {graphData ? (
            <>
              {graphQuery === "gettotalcostgraph" ||
              graphQuery === "totaltripdistance" ? (
                <Bar
                  id="bar-chart"
                  options={barChartOptions}
                  data={graphData}
                  //width={"-webkit-fill-available"}
                  //height={"-webkit-fill-available"}
                  width={`${window.innerWidth - 650}px`}
                  height={`${window.innerHeight - 350}px`}
                  //width={"800px"}
                  //height={"300px"}
                />
              ) : (
                <Line
                  id="line-chart"
                  options={lineChartOptions}
                  data={graphData}
                  datasetIdKey="id"
                  width={`${window.innerWidth - 650}px`}
                  height={`${window.innerHeight - 350}px`}
                  //width={"800px"}
                  //height={"300px"}
                />
              )}
            </>
          ) : (
            <div
            //className="d-flex justify-content-center align-items-center"
            >
              <TableSpinner animation="border" />
            </div>
          )}
        </>
      )}
    </div>
    //</div>
  );
};

export default SingleChart;
