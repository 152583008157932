import resources from "./resources";

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : window.navigator.language.includes("es")
    ? "es"
    : "en",
  resources,
};

export { resources };
