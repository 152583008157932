import React, { useState } from "react";
import { Col, Tab, Nav } from "react-bootstrap";
import {
  ColumnHeaderRow,
  DataRow,
  Table,
  TableContainer,
} from "../../shared/components/TableElements";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import { useSelector } from "react-redux";
import { getDisplayDate, getDistanceUnit } from "../../shared/helpers";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DueComponent,
  StatusComponent,
} from "../Reminders/MaintenanceReminders/MaintenanceRemindersTable";
import { LinkSpan } from "../../shared/components/form/FormElements";
import {
  colorBorder,
  colorLightGray,
  scrollbarStyles,
} from "../../utils/palette";
import { useHistory } from "react-router";
import { borderColor } from "polished";
import styled from "styled-components";

const DisplayTable = ({ data, heading, type }) => {
  const { t } = useTranslation("common");

  const { distance, currency } = useSelector((state) => state.units);
  const [distanceUnit, setDistanceUnit] = useState(getDistanceUnit(distance));

  //Set distanceUnit
  useEffect(() => {
    setDistanceUnit(getDistanceUnit(distance));
  }, [distance]);

  const DataTable = ({ rows }) => {
    // const { t } = useTranslation("common");
    const history = useHistory();
    const [rowData, setRowData] = useState([]);

    const columns = [
      { header: t("vehicle"), accessor: "veh_name" },
      { header: t("task"), accessor: "task_name" },
      { header: t("status"), accessor: "status" },
      {
        header: `${t("due_on")}`,
        accessor: "due_on",
      },
    ];

    useEffect(() => {
      if (rows && rows?.length > 0) {
        setRowData(getRows(rows));
      }
    }, [rows]);

    const getRows = (data) => {
      let rows = data.map((row) => {
        //veh_name
        let veh_name = (
          <VehicleComponent
            vehicleName={row.NAME}
            vehicleId={row.veh_id}
            vehicleImg={row.veh_img}
            orgId={row.org_id}
          />
        );

        //status
        const { due_days, last_date_of_service } = row;
        let due = last_date_of_service + Number(due_days) * 86400;
        let currentDate = Math.floor(Date.now() / 1000);
        let dueIn = ((due - currentDate) / (60 * 60 * 24)).toFixed(0);
        let status = (
          <StatusComponent
            due_days={row.due_days}
            dueOdo={row}
            distance={row["DUE ODO"] - row["MAX ODO"]}
            percentage={row.PERCENTAGE}
            days={dueIn}
          />
        );

        //due_on
        let dueDate = new Date(row.last_date_of_service * 1000);
        const dueDays = +row.due_days;
        let lastServiceDate = new Date(row.last_date_of_service * 1000);
        dueDate.setDate(lastServiceDate.getDate() + dueDays);
        dueDate =
          dueDate && dueDate instanceof Date && !isNaN(dueDate)
            ? getDisplayDate(dueDate / 1000) //dueDate.toLocaleDateString("en-US")
            : NATEXT;
        let due_on = (
          <DueComponent
            distance={row["DUE ODO"]}
            date={dueDate}
            due_days={row.due_days}
            due_odo={row.due_odo}
          />
        );

        //Task name
        let taskName = "";
        if (type === "reminders") {
          taskName = row.service_task_name;
        } else {
          taskName = row.expense_task_name;
        }

        if (taskName.length > 30) {
          taskName = `${taskName.slice(0, 29)}...`;
        }

        return {
          veh_name,
          task_name: taskName,
          status,
          due_on,
        };
      });
      return rows;
    };

    return (
      // <div>
      //   <TableContainer>
      //     <ColumnHeaderRow>
      //       {columns?.map((col, id) => {
      //         return (
      //           <Col key={id}>
      //             <span>{col.header}</span>
      //             {col.accessor === "due_on" && (
      //               <span style={{ fontSize: 8, fontWeight: 300 }}>
      //                 {` (${distanceUnit}/${t("date_tv")})`}
      //               </span>
      //             )}
      //           </Col>
      //         );
      //       })}
      //     </ColumnHeaderRow>
      //     <div style={{ height: 240 }}>
      //       {rowData.length > 0 ? (
      //         <>
      //           {rowData.map((row, i) => (
      //             <DataRow key={i}>
      //               {Object.keys(row).map((key) => {
      //                 return (
      //                   <Col key={key} className="d-flex align-items-center">
      //                     <div>{row[key]}</div>
      //                   </Col>
      //                 );
      //               })}
      //             </DataRow>
      //           ))}
      //         </>
      //       ) : (
      //         <div
      //           className="d-flex justify-content-center align-items-center"
      //           style={{ height: "inherit" }}
      //         >
      //           <span>No Records</span>
      //         </div>
      //       )}
      //     </div>
      //   </TableContainer>
      //   <div
      //     className="d-flex align-items-center p-2"
      //     style={{ border: `1px solid ${colorBorder}` }}
      //   >
      //     <LinkSpan
      //       onClick={() =>
      //         type === "reminders"
      //           ? history.push("/reminders/maintenancereminders")
      //           : history.push("/reminders/renewals")
      //       }
      //       style={{ fontSize: 14 }}
      //     >
      //       View all
      //     </LinkSpan>
      //   </div>
      // </div>
      <>
        <ScrollableDiv
        // style={{
        //   border: `1px solid ${colorBorder}`,
        //   height: "320px",
        //   overflow: "auto",
        // }}
        >
          <Table className="m-0">
            <thead style={{ position: "unset" }}>
              <tr style={{ position: "unset" }}>
                {columns?.map((col, id) => {
                  return (
                    <th key={id} style={{ position: "unset" }}>
                      <span>{col.header}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <TBODY>
              {rowData.map((row, i) => (
                <tr
                  key={i}
                  // style={{
                  //   borderBottom: `1px solid ${colorBorder}`,
                  // }}
                >
                  {Object.keys(row).map((key) => {
                    return (
                      <td key={key}>
                        <div>{row[key]}</div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {/* <tr>
              <td>
              <LinkSpan
              onClick={() =>
                type === "reminders"
                ? history.push("/reminders/maintenancereminders")
                : history.push("/reminders/renewals")
              }
              style={{ fontSize: 14 }}
              >
                  View all
                </LinkSpan>
              </td>
            </tr> */}
            </TBODY>
          </Table>

          {rowData.length === 0 && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "260px" }}
            >
              <span>{t("no_records")}</span>
            </div>
          )}
        </ScrollableDiv>
        <div
          className="d-flex align-items-center p-2"
          style={{ border: `1px solid ${colorBorder}` }}
        >
          <LinkSpan
            onClick={() =>
              type === "reminders"
                ? history.push("/reminders/maintenancereminders")
                : history.push("/reminders/renewals")
            }
            style={{ fontSize: 14 }}
          >
            {t("view_all")}
          </LinkSpan>
        </div>
      </>
    );
  };

  const Tabs = ({ data }) => {
    // const { t } = useTranslation("common");
    const [activeKey, setActiveKey] = useState(1);
    return (
      <>
        <h4 className="mb-1">{heading}</h4>
        <Tab.Container activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
          <TabsWrap>
            <Nav
              className="nav-tabs"
              style={{
                flexWrap: "nowrap",
                borderBottom: "none",
                //borderColor: colorBorder,
              }}
            >
              <NavItem id="total-stats-tab" $active={activeKey == "1"}>
                <NavLink eventKey="1" name="user-details-anchor">
                  {t("overdue")}
                </NavLink>
              </NavItem>
              <NavItem id="documents-tab" $active={activeKey == "2"}>
                <NavLink eventKey="2" name="documents-anchor">
                  {t("due_soon")}
                </NavLink>
              </NavItem>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey={"1"}>
                <DataTable rows={data.overdue} />
              </Tab.Pane>
              <Tab.Pane eventKey={"2"}>
                <DataTable rows={data.dueSoon} />
              </Tab.Pane>
            </Tab.Content>
          </TabsWrap>
        </Tab.Container>
      </>
    );
  };

  return (
    <TabsWrap>
      <Tabs data={data} />
    </TabsWrap>
  );
};

export default DisplayTable;

//styles

export const TBODY = styled.tbody`
  tr {
    border-bottom: 1px solid ${colorBorder};
    &:last-child {
      border-bottom: none;
    }
  }
`;

const ScrollableDiv = styled.div`
  border: 1px solid ${colorBorder};
  ${scrollbarStyles};
  overflow: auto;
  height: 320px;
`;
