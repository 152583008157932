import { Route, Switch } from "react-router";
import MaintenanceRemindersForm from './MaintenanceRemindersForm';
import MaintenanceRemindersTable from "./MaintenanceRemindersTable";

export default () => (
  <Switch>
    <Route
      exact path="/reminders/maintenancereminders"
      component={MaintenanceRemindersTable}
    />
    <Route
      exact path="/reminders/maintenancereminders/addnew"
      component={MaintenanceRemindersForm}
    />
  </Switch>
);
