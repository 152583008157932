import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard: [],
  fillups: [],
  service: [],
  trips: [],
  expense: [],
  dailymileage: [],
  maintenancereminders: [],
  renewals: [],
  inspectionschedules: [],
  dailytimesheet: [],
  vehicles: [],
  users: [],
  servicetasks: [],
  expensetasks: [],
  inspectiontasks: [],
  inspectionforms: [],
  issues: [],
  work_order: [],
  inspectionsubmissions: [],
  groups: [],
  faultcodes: [],
};

const storedFiltersSlice = createSlice({
  name: "storedFilters",
  initialState,
  reducers: {
    updateMoreFilters(state, action) {
      const { screen, appliedFilters } = action.payload;
      let currentScreenFilters = state[screen];
      let filtersWithoutMoreFilters = currentScreenFilters.filter(
        (f) => !f?.moreFilters
      );
      let updatedFilters = [...filtersWithoutMoreFilters, ...appliedFilters];
      state[screen] = updatedFilters;
    },
    updateExternalFilter(state, action) {
      const { screen, filterName, filterValue } = action.payload;
      let currentScreenFilters = state[screen];
      let currentFilter = currentScreenFilters.find(
        (f) => f?.filterName === filterName
      );
      if (currentFilter) {
        currentFilter.value = filterValue;
        currentScreenFilters = currentScreenFilters.filter(
          (f) => f?.filterName !== filterName
        );
        currentScreenFilters.push(currentFilter);
      } else {
        currentScreenFilters.push({
          filterType: "external",
          value: filterValue,
          filterName: filterName,
          moreFilters: false,
        });
      }

      state[screen] = currentScreenFilters;
    },
    //To remove single more filter
    removeMoreFilters(state, action) {
      const { screen, filterToRemove } = action.payload;
      let currentScreenFilters = state[screen];
      let updatedFilters = currentScreenFilters.filter(
        (filter) => filter.column !== filterToRemove?.column
      );

      state[screen] = updatedFilters;
    },
    clearMoreFilters(state, action) {
      const { screen } = action.payload;
      let currentScreenFilters = state[screen];
      let updatedFilters = currentScreenFilters.filter(
        (filter) => !filter?.moreFilters
      );
      state[screen] = updatedFilters;
    },
  },
});

export const {
  updateMoreFilters,
  updateExternalFilter,
  removeMoreFilters,
  clearMoreFilters,
} = storedFiltersSlice.actions;
export default storedFiltersSlice.reducer;
