import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionIconContainer,
  AddFormButton,
  ColumnHeaderRow,
  DataRow,
  TableContainer,
} from "../../../shared/components/TableElements";
import {
  getDisplayDate,
  getDisplayDateWithTime,
} from "../../../shared/helpers";
import { TechniciansContainer } from "../../../shared/components/form/SelectTechnicianPopup";
import { colorLightGray } from "../../../utils/palette";
import { useTranslation } from "react-i18next";
import { NATEXT } from "../../../shared/constants";

const PausesTable = ({ showPopup, setShowPopup, pauses }) => {
  const { t } = useTranslation("common");
  console.log(pauses);
  return (
    <Modal show={showPopup} onHide={() => setShowPopup((prev) => !prev)}>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h5>{t("paused_timeline")}</h5>
          <ActionIconContainer onClick={() => setShowPopup((prev) => !prev)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
        <TableContainer className="mx-0 my-3 p-0">
          <ColumnHeaderRow
            // style={{
            //   border: `1px solid ${colorLightGray}`,
            //   background: colorLightGray,
            // }}
            className="py-2 mx-0"
          >
            <Col md={4}>
              <span style={{ fontSize: "12px" }}>{t("pause_start")}</span>
            </Col>
            <Col md={4}>
              <span style={{ fontSize: "12px" }}>{t("pause_end")}</span>
            </Col>
            <Col md={4}>
              <span style={{ fontSize: "12px" }}>
                {t("reason_for_pausing")}
              </span>
            </Col>
          </ColumnHeaderRow>
          <TechniciansContainer>
            {pauses?.map((pause) => {
              return (
                <DataRow
                  key={pause.start_time}
                  //style={{ border: `1px solid ${colorLightGray}` }}
                  className="py-2 px-0 mx-0"
                >
                  <Col md={4}>
                    <span style={{ fontSize: 12 }}>
                      {pause.start_time
                        ? getDisplayDateWithTime(new Date(pause.start_time))
                        : NATEXT}
                    </span>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: 12 }}>
                      {pause.end_time
                        ? getDisplayDateWithTime(new Date(pause.end_time))
                        : NATEXT}
                    </span>
                  </Col>

                  <Col md={4}>
                    <span style={{ fontSize: 12 }}>{pause.reason}</span>
                  </Col>
                </DataRow>
              );
            })}
          </TechniciansContainer>
        </TableContainer>
        <div className="d-flex justify-content-end align-items-center">
          <AddFormButton onClick={() => setShowPopup(false)}>
            {t("ok")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PausesTable;

//styles

// export const TechniciansContainer = styled.div`
//   margin: 0;
//   padding: 0;
//   max-height: 400px;
//   overflow-y: auto;
//   //overflow-x: hidden;
//   // /${scrollbarStyles};
//   //width: -webkit-fill-width;
// `;

export const PauseRow = styled(Row)`
  border: 1px solid ${colorLightGray};
`;
