import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    className: 'off',
  };
  
  const borderSlice = createSlice({
    name: 'border',
    initialState,
    reducers: {
        changeRoundBorders: (state, action) => {
            state.className = state.className === 'off' ? 'on' : 'off';
        },
    },
  });

  export const { changeRoundBorders } = borderSlice.actions;
  
  export default borderSlice.reducer;


