import React, { createRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  LinkSpan,
} from "../../../shared/components/form/FormElements";
import FormHeader from "../../../shared/components/form/FormHeader";
import { ButtonGroup, Col, Container, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import Error from "@/shared/components/form/Error";
import {
  convertToDisplayTime,
  convertToTextDate,
  getDateFnsLocale,
  getDisplayDateWithTime,
  getDistanceFactorforOdo,
  getInitialStartTime,
  getTimestamp,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  isObjEmpty,
  odoValidation,
} from "../../../shared/helpers";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import {
  colorBorder,
  colorGray,
  colorLightGray,
  disabledBgColor,
  scrollbarStyles,
} from "../../../utils/palette";
import FormField from "../../../shared/components/form/FormField";
import styled from "styled-components";
import { Toggle } from "../InspectionSchedule/InspectionScheduleForm";
import {
  ImgContainer,
  ReceiptImg,
} from "../../../shared/components/table/components/RightPanel/FillupsRightPanel";
import { post, postApi } from "../../../api/getUser";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
  NATEXT,
} from "../../../shared/constants";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import axios from "axios";
import { CustomInput } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../../shared/components/TableElements";
import { Buffer } from "buffer";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { InfoPopup } from "./InfoPopup";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import useFetchOdoHint from "../../../shared/custom-hooks/useFetchOdoHint";
import ImageWithZoom from "../../../shared/components/form/ImageWithZoom";
import getUnixTime from "date-fns/getUnixTime";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import format from "date-fns/format";
import Dropzone from "react-dropzone";
import ErrorPopup from "../../../shared/components/ErrorPopup";
import ZoomModal from "../../../shared/components/ZoomModal";
const {
  CognitoIdentityClient,
  GetIdCommand,
} = require("@aws-sdk/client-cognito-identity");

const InspectionSubmissionsForm = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { distance } = useSelector((state) => state.units);

  const location = useLocation();
  const dispatch = useDispatch();

  const { orgId, userId } = useSelector((state) => state.user);

  const client = new S3Client({
    region: "us-east-2",
    credentials: fromCognitoIdentityPool({
      identityPoolId: IDENTITY_POOL_ID,
      client: new CognitoIdentityClient({ region: BUCKET_REGION }),
    }),
    apiVersion: "2006-03-01",
  });

  //Flag to conditionally render Vehicle and Submission Details
  const [showFields, setShowFields] = useState(false);

  const [submissions, setSubmissions] = useState({});

  const [formsData, setFormsData] = useState([]);
  //const [userData, setUserData] = useState([]);
  const userData = useSelector((state) => state.users.list).filter(
    (u) => u.role_id !== 4
  );

  const [startTimeSec, setStartTimeSec] = useState(
    Math.round(Date.now() / 1000)
  );
  const [endTimeSec, setEndTimeSec] = useState("");

  let tempCommentsData = {};
  const [commentsData, setCommentsData] = useState({});
  const [isCommentsErr, setIsCommentsErr] = useState(false);
  const [showCommentsErr, setShowCommentsErr] = useState(false);

  const [tasksIncomplete, setTasksIncomplete] = useState(false);

  const [showWarningModal, setShowWarningModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length + 1)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );
  const allUsers = useSelector((state) => state.users.list);

  const { data, screen, type } = location.state;
  const rowData = data?.rowData;
  const submissionsData = data?.submissionsData;

  const imgArrData = data?.imgArrData;

  //Hook Form States
  const vehicle = watch("vehicle");
  const comments = watch("comments");
  const inspectionForm = watch("inspectionForm");
  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const inspectedBy = watch("inspectedBy");
  const inspectionDate = watch("inspectionDate");

  const [signImg, setSignImg] = useState("");
  const [signRef, setSignRef] = useState("");

  const [showCanvas, setShowCanvas] = useState(false);
  const [showSignError, setShowSignError] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isSignReqd, setIsSignReqd] = useState(false);
  const [isOdoReqd, setIsOdoReqd] = useState(false);
  const [odoErr, setOdoErr] = useState(false);
  const [odoErrMsg, setOdoErrMsg] = useState("");

  const [loadInitialSign, setLoadInitialSign] = useState(false);

  const [hintOdo, setHintOdo] = useState("");

  const [currentImg, setCurrentImg] = useState("");
  const [showImgModal, setShowImgModal] = useState("");

  const [imgHovered, setImgHovered] = useState({ hovered: false, url: "" });

  const closePopup = sessionStorage.getItem("close-popup");
  const dontShowPopup = localStorage.getItem("dont-show-popup");
  const [showInfoPopup, setShowInfoPopup] = useState(true);

  const toggleButtons = [
    {
      id: 0,
      label: t("inspection_fail"),
      value: "0",
    },
    {
      id: 1,
      label: t("inspection_pass"),
      value: "1",
    },
  ];

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    filterDateEnd: type === "edit" ? rowData?.start_time : startTimeSec,
  });
  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  //ref used for focus for Odometer mileage value in case of error
  const odoRef = useRef(null);

  const { tag, fetchTelematicsOdo } = useAuth();
  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  const [odoFetchedMsg, setOdoFetchedMsg] = useState("");

  const [telematicsOdo, setTelematicsOdo] = useState(0);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Initial Effect
  useEffect(() => {
    if (type === "add") {
      (async () => {
        setValue("startTime", getInitialStartTime());
        setValue("endTime", "");
        setValue("inspectionDate", new Date());
        if (tag === "free-user") {
          setShowCanvas(false);
        } else {
          setShowCanvas(true);
        }
        const res = await postApi(
          { querystring: "get_all_inspection_form" },
          "commonNew"
        );

        if (res.success) {
          const { user_data } = res;
          if (user_data.length > 0) {
            const temp = [];
            user_data.forEach((item) => {
              temp.push({
                ...item,
                label: item.form_name,
                value: item.form_id,
                //formId: item.form_id,
                id: item.form_id,
              });
            });
            setFormsData(temp);
          }
        }
      })();
    }
  }, []);

  //Effect to set user options
  useEffect(() => {
    (async () => {
      if (vehicle) {
        const apiData = {
          veh_id: vehicle?.vehicleId,
          querystring: "getvehicleoperators",
        };
        const res = await postApi(apiData, "commonNew");
        if (res.success) {
          const { user_data } = res;
          if (user_data?.length > 0) {
            let temp = [];
            for (let user of user_data) {
              let filteredUser = allUsers.find(
                (u) => u.user_id === user.user_id
              );
              temp.push(filteredUser);
            }
            setUserOptions(temp);
          }
        }
      }
    })();
  }, [vehicle, allUsers]);

  //Effect to set default user on add
  useEffect(() => {
    if (type === "add" && allUsers?.length > 0 && userId) {
      setValue(
        "inspectedBy",
        allUsers?.find((u) => u.user_id === userId)
      );
    }
  }, [allUsers, userId]);

  //Effect to set startTimeSec(used for odoHint/odo Val)
  useEffect(() => {
    if (type !== "edit" && startTime && inspectionDate) {
      let inspectionDateISO = new Date(inspectionDate)
        .toISOString()
        .substring(0, 10);
      if (startTime) {
        //for start time
        let startTimeHours = startTime.match(/^(\d+)/)[1];
        let startTimeMinutes = startTime.match(/:(\d+)/)[1];
        let inspDateWithStartTime =
          inspectionDateISO +
          "T" +
          startTimeHours +
          ":" +
          startTimeMinutes +
          ":00";
        let startTimeSec = new Date(inspDateWithStartTime).getTime() / 1000;
        setStartTimeSec(startTimeSec);
      }
    }
  }, [inspectionDate, startTime]);

  //Effect to fetch telematics odo
  useEffect(() => {
    (async () => {
      if (type !== "edit" && vehicle && fetchTelematicsOdo) {
        let odoTaskPresent = false;
        Object.keys(submissions).forEach((id) => {
          if (submissions[id].taskId === `${orgId}_odometer`) {
            odoTaskPresent = true;
            setTelematicsOdo(0);
            setOdoFetchedMsg("");
            setSubmissions((prev) => {
              let temp = { ...prev };
              temp[`${orgId}_odometer`].value = "";
              return temp;
            });
          }
        });
        if (odoTaskPresent) {
          if (vehicle.provider_type_1) {
            setOdoFetchedMsg(t("fetching_data_via_telematics"));
            const odoApiData = {
              querystring: "get_current_obd_odometer",
              veh_id: vehicle.veh_id,
              provider_type: 1,
            };
            const res = await post("integrations", odoApiData);
            if (res.success) {
              let odoVal = +res.data.odometer_in_meters;

              if (+odoVal) {
                odoVal = odoVal?.toFixed(2);
                setSubmissions((prev) => {
                  let temp = { ...prev };
                  temp[`${orgId}_odometer`].value = odoVal;
                  return temp;
                });
                setTelematicsOdo(odoVal);
                setOdoFetchedMsg(t("fetched_via_telematics_device"));
                // if (
                //   +odoVal &&
                //   +lastOdoValue &&
                //   +odoVal - +lastOdoValue > 1000
                // ) {
                //   setShowHighOdoErr(true);
                // } else {
                //   setShowHighOdoErr(false);
                // }
              }
            } else {
              setSubmissions((prev) => {
                let temp = { ...prev };
                temp[`${orgId}_odometer`].value = "";
                return temp;
              });
              setTelematicsOdo(0);
              setOdoFetchedMsg("");
              setShowHighOdoErr(false);
            }
          } else {
            setSubmissions((prev) => {
              let temp = { ...prev };
              temp[`${orgId}_odometer`].value = "";
              return temp;
            });
            setOdoFetchedMsg("");
            setTelematicsOdo(0);
            setShowHighOdoErr(false);
          }
        } else {
          // setSubmissions((prev) => {
          //   let temp = { ...prev };
          //   temp[`${orgId}_odometer`].value = "";
          //   return temp;
          // });
          setOdoFetchedMsg("");
          setTelematicsOdo(0);
          setShowHighOdoErr(false);
        }
      }
    })();
  }, [vehicle]);

  //Effect to set high odo warning for odo after odo is fetched from telematics
  useEffect(() => {
    if (vehicle && vehicle.provider_type_1 && type !== "edit") {
      if (
        +telematicsOdo &&
        +lastOdoValue &&
        +telematicsOdo - +lastOdoValue > 1000
      ) {
        setShowHighOdoErr(true);
      } else {
        setShowHighOdoErr(false);
      }
    }
  }, [vehicle, lastOdoValue, telematicsOdo]);

  useEffect(() => {
    setIsCommentsErr(false);
    setTasksIncomplete(false);
    let tasksIncompleteArr = [];
    Object.keys(submissions).forEach((id) => {
      //console.log(submissions[id].comments);
      if (submissions[id].pass === "0") {
        setCommentsData((prev) => {
          let newObj = { ...prev };
          newObj[id] = {
            ...newObj[id],
            commentsRequired: submissions[id].comments ? false : true,
            commentsLengthErr: submissions[id].comments?.length < 25,
            commentsPlaceholder: t("reason_for_failure"),
          };
          return newObj;
        });
        // if (!submissions[id].comments) {
        //   setIsCommentsErr(true);
        // } else {
        //   setIsCommentsErr(false);
        // }
      } else {
        setCommentsData((prev) => {
          let newObj = { ...prev };
          newObj[id] = {
            ...newObj[id],
            commentsRequired: false,
            commentsLengthErr: false,
            commentsPlaceholder: t("notes_tv"),
          };
          return newObj;
        });
        //setIsCommentsErr(false);
      }

      const task = submissions[id];
      //console.log(task);
      let tasksIncompleteCheck = [];
      //let tasksIncompleteArr = [];
      //Conditions for task Incomplete
      if (task.showPass) {
        if (+task.pass === 2) {
          tasksIncompleteCheck.push(true);
        } else {
          tasksIncompleteCheck.push(false);
        }
      }
      if (task.showValue) {
        if (task.value === "") {
          tasksIncompleteCheck.push(true);
        } else {
          tasksIncompleteCheck.push(false);
        }
      }

      if (task.showImage) {
        if (task.imgArr.length === 0) {
          tasksIncompleteCheck.push(true);
        } else {
          tasksIncompleteCheck.push(false);
        }
      }

      const taskNotComplete = tasksIncompleteCheck.every(
        (item) => item === true
      );
      tasksIncompleteArr.push(taskNotComplete);

      //Logic for Odo required
      //if (isOdoReqd) {
      if (submissions[id].taskId === `${orgId}_odometer`) {
        if (submissions[id].value) {
          setOdoErr(false);
        } else {
          setOdoErr(true);
        }
      }
      //}
    });

    const anyTaskIncomplete = tasksIncompleteArr.some((item) => item === true);
    setTasksIncomplete(anyTaskIncomplete);
  }, [submissions]);

  //Effects to load initial signature

  useEffect(() => {
    if (signRef && tag !== "free-user") {
      const { _ctx } = signRef?.getSignaturePad();
      _ctx.font = "Italic 30px Homemade Apple, cursive";
      _ctx?.fillText(
        allUsers?.find((u) => u.user_id === userId)?.label,
        20,
        65
      );
      signRef.clear();
      setTimeout(() => {
        setLoadInitialSign(true);
      }, 500);
    }
  }, [signRef]);

  useEffect(() => {
    if (
      signRef &&
      type === "add" &&
      allUsers?.length > 0 &&
      userId &&
      tag !== "free-user"
    ) {
      const { _ctx } = signRef?.getSignaturePad();

      if (isCanvasEmpty) {
        _ctx.font = "Italic 20px Homemade Apple, cursive";
        //_ctx.fontStretch = "condensed";
        _ctx?.fillText(
          allUsers?.find((u) => u.user_id === userId)?.label,
          20,
          65
        );
      }

      setSignImg({
        file: signRef.getCanvas().toDataURL("image/png"),
        url: signRef.getCanvas().toDataURL("image/png"),
      });
      setIsCanvasEmpty(false);
    }
  }, [loadInitialSign, allUsers, userId]);

  const handleClearSignature = (e) => {
    e.preventDefault();
    signRef.clear();
    setSignImg({ file: "", url: "" });
    setIsCanvasEmpty(true);
  };

  const handleCanvasOnEnd = () => {
    setSignImg({
      file: signRef.getTrimmedCanvas().toDataURL("image/png"),
      url: signRef.getTrimmedCanvas().toDataURL("image/png"),
    });
    setIsCanvasEmpty(false);
  };

  // const handleSaveSignature = (e) => {
  //   e.preventDefault();
  //   if (firstTimeSave.current) {
  //     setIsCanvasEmpty(false);
  //     setSignImg({
  //       file: signRef.getCanvas().toDataURL("image/png"),
  //       url: signRef.getCanvas().toDataURL("image/png"),
  //     });
  //     firstTimeSave.current = false;
  //   } else {
  //     if (signRef.isEmpty()) {
  //       setIsCanvasEmpty(true);
  //       return;
  //     } else {
  //       setIsCanvasEmpty(false);
  //     }
  //     setSignImg({
  //       file: signRef.getTrimmedCanvas().toDataURL("image/png"),
  //       url: signRef.getTrimmedCanvas().toDataURL("image/png"),
  //     });
  //   }
  // };

  //Method to get initial start time

  // const getInitialStartTime = () => {
  //   const hours = new Date().getHours();
  //   const minutes = new Date().getMinutes();
  //   let hr = hours;
  //   let min = minutes;
  //   if (hours < 10) {
  //     hr = "0" + hr;
  //   }
  //   if (minutes < 10) {
  //     min = "0" + min;
  //   }
  //   return `${hr}:${min}`;
  // };

  //Effect to set showFields flag(Conditionally rendering vehicle and submission details) and fetch submission details in case of add
  useEffect(() => {
    if (type === "add") {
      if (inspectionForm) {
        (async () => {
          setOdoFetchedMsg("");
          setTelematicsOdo(0);
          setShowHighOdoErr(false);

          const data = {
            form_id: inspectionForm.form_id,
            querystring: "getSingleInspectionFormDetails",
          };

          const res = await postApi(data, "inspection");

          if (res.success) {
            const { inspection_data, inspection_taskid_data } = res;

            //Setting signature required state
            if (inspection_data.length > 0) {
              setIsSignReqd(inspection_data[0].sign_required);
              setIsOdoReqd(inspection_data[0].odo_required);
            }

            //Set state value(submissions)
            if (!isObjEmpty(inspection_taskid_data)) {
              let temp = {};
              Object.keys(inspection_taskid_data).map((id) => {
                temp[id] = {
                  taskName: inspection_taskid_data[id].task_name,
                  taskId: inspection_taskid_data[id].task_id,
                  pass: 2,
                  showPass:
                    inspection_taskid_data[id].pass_required === 1
                      ? true
                      : false,
                  //passState: 2,
                  value: "",
                  showValue:
                    inspection_taskid_data[id].value_required === 1
                      ? true
                      : false,
                  showImage:
                    inspection_taskid_data[id].image_required === 1
                      ? true
                      : false,
                  imgArr: [],
                  comments: "",
                  //commentsReqd: false,
                  //commentsPlaceholder: t('comments')
                };
                tempCommentsData[id] = {
                  commentsRequired: false,
                  commentsLengthErr: false,
                  commentsPlaceholder: t("notes_tv"),
                  ref: createRef(),
                };
              });
              setSubmissions(temp);
              setCommentsData(tempCommentsData);
            }
          }
        })();
        setShowFields(true);
      } else {
        setShowFields(false);
      }
    } else if (type === "edit") {
      setShowFields(true);

      (async () => {
        const data = {
          form_id: form_id,
          querystring: "getSingleInspectionFormDetails",
        };
        const res = await postApi(data, "inspection");
        if (res.success) {
          const { inspection_data, inspection_taskid_data } = res;

          //Setting signature required state
          if (inspection_data.length > 0) {
            setIsSignReqd(inspection_data[0].sign_required);
            setIsOdoReqd(inspection_data[0].odo_required);
          }
        }
      })();
    }
  }, [inspectionForm]);

  const form_id = rowData?.form_id;
  const inspection_id = rowData?.inspection_id;
  const form_name = rowData?.form_name;
  const inspection_by = rowData?.inspection_by;
  const inspection_date = rowData?.inspection_date;
  const start_time = rowData?.start_time;
  const end_time = rowData?.end_time;
  const user_name = rowData?.user_name;
  const signature = rowData?.signature;
  const veh_id = rowData?.veh_id;

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  useEffect(() => {
    if (type === "edit" && orgId && vehiclesList?.length > 0) {
      setShowCanvas(false);
      let selectedVehicle = vehicles.find((v) => v.vehicleId === veh_id);
      setValue("vehicle", selectedVehicle);

      if (signature && signature !== NATEXT && tag !== "free-user") {
        setSignImg({
          file: "",
          url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`,
        });
      }

      if (submissionsData) {
        let temp = {};

        submissionsData.forEach((sub) => {
          temp[sub.inspection_detail_id] = {
            taskName: sub.task_name,
            taskId: sub.task_id,
            pass: sub.pass.toString(),
            showPass: sub.pass === 1 || sub.pass === 0 || sub.pass === 2,
            value: sub.value,
            showValue: sub.value !== "-1000",
            comments: sub.comments,
            showImage: sub.image !== "-1000",
            imgArr: imgArrData[sub.inspection_detail_id]?.length
              ? imgArrData[sub.inspection_detail_id]
              : [],
            //commentsReqd: false,
            //commentsPlaceholder: t('comments')
          };
          tempCommentsData[sub.inspection_detail_id] = {
            commentsRequired: false,
            commentsLengthErr: false,
            commentsPlaceholder: t("notes_tv"),
            ref: createRef(),
          };
          //   tempArr.push({
          //     id: sub.inspection_detail_id,
          //     pass: sub.pass,
          //     value: sub.value,
          //     comments: sub.comments,
          //     label: sub.task_name,
          //   });
        });
        setSubmissions(temp);
        setCommentsData(tempCommentsData);
        //setSubmissionsArr(tempArr);
      }

      setValue("comments", rowData?.comments);
    }
  }, [orgId, vehiclesList]);

  //compute timestamp values for startTime and endTime and validate

  // useEffect(() => {
  //   if (inspectionDate) {
  //     let inspectionDateISO = new Date(inspectionDate)
  //       .toISOString()
  //       .substring(0, 10);
  //     if (startTime) {
  //       //for start time
  //       let startTimeHours = startTime.match(/^(\d+)/)[1];
  //       let startTimeMinutes = startTime.match(/:(\d+)/)[1];
  //       let inspDateWithStartTime =
  //         inspectionDateISO +
  //         "T" +
  //         startTimeHours +
  //         ":" +
  //         startTimeMinutes +
  //         ":00";
  //       setStartTimeSec(new Date(inspDateWithStartTime).getTime() / 1000);
  //     }

  //     //for end time
  //     if (endTime) {
  //       let endTimeHours = endTime.match(/^(\d+)/)[1];
  //       let endTimeMinutes = endTime.match(/:(\d+)/)[1];
  //       let inspDateWithEndTime =
  //         inspectionDateISO + "T" + endTimeHours + ":" + endTimeMinutes + ":00";
  //       setEndTimeSec(new Date(inspDateWithEndTime).getTime() / 1000);
  //     }
  //   }
  // }, [startTime, endTime, inspectionDate]);

  //Effect to get maxOdo
  // useEffect(() => {
  //   if (vehicle && startTimeSec) {
  //     //console.log(startTimeSec);
  //     (async () => {
  //       const res = await getMaxOdo({
  //         querystring: "getOdometerFromDate",
  //         sub_querystring: "",
  //         veh_id: vehicle.vehicleId,
  //         filter_date_start: 0,
  //         filter_date_end: startTimeSec,
  //       });
  //       const [lastOdo, nextOdo] = res.odohint_data;
  //       //console.log(lastOdo, nextOdo);

  //       if (lastOdo && nextOdo) {
  //         setHintOdo(t("last_odo") + lastOdo + ", " + t("next_odo") + nextOdo);
  //       } else if (lastOdo && !nextOdo) {
  //         setHintOdo(t("last_odo") + lastOdo);
  //       } else if (!lastOdo && nextOdo) {
  //         setHintOdo(t("next_odo") + nextOdo);
  //       }
  //     })();
  //   }
  // }, [startTimeSec, vehicle]);

  //console.log(submissions['20675_20675_20675_1_8_3']?.imgArr?.[0]);
  const updateValue = (e) => {
    if (odoFetchedMsg) {
      setOdoFetchedMsg("");
    }
    const { name, value } = e.target;
    const [id, type] = name.split("-");
    setSubmissions((prev) => {
      const newObj = { ...prev };
      if (type === "pass") {
        newObj[id][type] = +value;
      } else {
        newObj[id][type] = value;
      }
      return newObj;
    });
  };

  const updatePassFail = (e) => {
    const { name, value } = e.currentTarget;
    const [id, type] = name.split("-");
    //console.log(value);
    //console.log(e.currentTarget);
    setSubmissions((prev) => {
      const newObj = { ...prev };
      newObj[id][type] = value;
      //console.log(id, type);
      //console.log(newObj);
      return newObj;
    });
  };

  const handleImgUpload = (files, inspectionDetailId) => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    setSubmissions((prev) => {
      let newObj = { ...prev };
      const current = prev[inspectionDetailId]?.imgArr;
      if (files.length > 0) {
        Object.keys(files).map((key) => {
          current.push({
            id: Date.now() + key,
            file: files[key],
            url: URL.createObjectURL(files[key]),
            imgName: files[key].name,
            extension: files[key].name.split(".").pop(),
          });
        });
      }
      newObj[inspectionDetailId].imgArr = current;
      return newObj;
    });
  };

  const handleDeleteImg = (img, inspectionDetailId) => {
    setSubmissions((prev) => {
      let newObj = { ...prev };
      const current = prev[inspectionDetailId]?.imgArr?.filter(
        (i) => i.imgName !== img.imgName
      );
      //current = current.filter((i) => i.imgName !== img.imgName);
      newObj[inspectionDetailId].imgArr = current;
      return newObj;
    });
  };

  const handleImgClick = (img) => {
    setCurrentImg(img);
    setShowImgModal(true);
  };

  // const addSubmission = () => {
  //   setIsLoading(true);

  //   let odoValue = "";
  //   if (isOdoReqd) {
  //     let odoTaskId = Object.keys(submissions).find(
  //       (id) => submissions[id].taskId === `${orgId}_odometer`
  //     );
  //     const distanceFactor = getDistanceFactorforOdo(
  //       vehicle?.primary_meter,
  //       distance
  //     );
  //     odoValue = +submissions[odoTaskId]?.value / distanceFactor;
  //   }
  //   const inspectionId = getTimestamp(vehicle.vehicleId, 0);

  //   const inspectionDateSec = Math.round(inspectionDate / 1000);

  //   const jsonData = {
  //     email: "testgroup@12.in",
  //     sync_version: "v2",
  //     operation: "create",
  //     querystring: "addEditInspectionSubmission",
  //     inspection_id: inspectionId,
  //     veh_id: vehicle.vehicleId,
  //     inspection_date: startTimeSec, //Since startTimeSec is the current inspection date with the provided start time
  //     form_id: inspectionForm.id,
  //     inspection_by: inspectedBy.id,
  //     comments: comments,
  //     action: 1,
  //     signature: isSignReqd || signImg.url ? "Signature.png" : "",
  //     start_time: startTimeSec,
  //     end_time: endTimeSec,
  //     odo: odoValue,
  //   };

  //   //console.log("jsonData", jsonData);
  //   (async () => {
  //     const res = await addInspectionSubmission(jsonData); //Submit Inspection Submission Data

  //     if (signImg.file) {
  //       let buf = new Buffer.from(
  //         signImg.file.replace(/^data:image\/\w+;base64,/, ""),
  //         "base64"
  //       );
  //       const command = new PutObjectCommand({
  //         Bucket: ALBUM_BUCKET_NAME,
  //         Key: `image_data/org_${orgId}/inspections/${inspectionId}/Signature.png`,
  //         Body: buf,
  //         ContentDisposition: "",
  //         ContentType: "image/png", //signImg.file.type,
  //         //ContentEncoding: 'base64',
  //         ACL: "public-read",
  //       });
  //       //console.log(client, command);
  //       const response = await client.send(command);
  //     }

  //     if (res === 1) {
  //       const inspectionDetailsArr = [];
  //       let imagesUploadToS3 = []; //This array is for images that need to be uploaded to s3

  //       Object.keys(submissions).forEach((id, i) => {
  //         inspectionDetailsArr.push({
  //           inspection_detail_id: getTimestamp(vehicle.vehicleId, i),
  //           inspection_id: inspectionId,
  //           task_id: submissions[id].taskId,
  //           pass: submissions[id].showPass ? submissions[id].pass : "-1000",
  //           value: submissions[id].showValue ? submissions[id].value : "-1000",
  //           image: submissions[id].showImage
  //             ? getImgDetails(id, imagesUploadToS3, submissions, inspectionId)
  //             : "-1000",
  //           comments: submissions[id].comments,
  //           action: 1,
  //         });
  //       });

  //       console.log("inspectionDetailsArr", inspectionDetailsArr);

  //       const submissionDetailsJsonData = {
  //         email: "testgroup@12.in",
  //         sync_version: "v2",
  //         operation: "create",
  //         querystring: "addEditInspectionDetails",
  //         inspection_detail: inspectionDetailsArr,
  //       };

  //       console.log("submissionDetailsJsonData", submissionDetailsJsonData);

  //       const submissionDetailsRes = await addInspectionSubmission(
  //         submissionDetailsJsonData
  //       ); //Submit Submission Details Data
  //       console.log("submissionDetailsRes", submissionDetailsRes);

  //       if (submissionDetailsRes === 1) {
  //         if (imagesUploadToS3.length > 0) {
  //           const allImgsUploaded = await uploadTaskImgsToS3(imagesUploadToS3);
  //           // console.log("allImgsUploaded", allImgsUploaded);
  //           // if (!allImgsUploaded) {
  //           //   alert("Something went wrong.");
  //           // }
  //           history.goBack();
  //           //setIsLoading(false);
  //         } else {
  //           history.goBack();
  //           //setIsLoading(false);
  //         }
  //       }
  //     }
  //   })();
  // };

  // const editSubmission = () => {
  //   setIsLoading(true);

  //   let odoValue = "";
  //   if (isOdoReqd) {
  //     let odoTaskId = Object.keys(submissions).find(
  //       (id) => submissions[id].taskId === `${orgId}_odometer`
  //     );
  //     const distanceFactor = getDistanceFactorforOdo(
  //       vehicle?.primary_meter,
  //       distance
  //     );
  //     odoValue = +submissions[odoTaskId]?.value / distanceFactor;
  //   }
  //   //console.log("odoValue", odoValue);
  //   const jsonData = {
  //     email: "testgroup@12.in",
  //     sync_version: "v2",
  //     operation: "update",
  //     querystring: "addEditInspectionSubmission",
  //     inspection_id: inspection_id,
  //     veh_id: vehicle.vehicleId,
  //     inspection_date: inspection_date,
  //     form_id: form_id,
  //     inspection_by: inspection_by,
  //     comments: comments,
  //     action: 2,
  //     signature: signature,
  //     start_time: start_time,
  //     end_time: end_time,
  //     odo: odoValue,
  //   };

  //   const snackbarMsg =
  //     type === "edit"
  //       ? "Inspection Submission Successfully Updated"
  //       : "Inspection Submission Successfully Added";
  //   (async () => {
  //     //console.log("Hi");
  //     const res = await addInspectionSubmission(jsonData); //Submit Inspection Submission Data
  //     //console.log("res", res);

  //     if (signImg.file) {
  //       const command = new PutObjectCommand({
  //         Bucket: ALBUM_BUCKET_NAME,
  //         Key: `image_data/org_${orgId}/inspections/${inspection_id}/Signature.png`,
  //         Body: signImg.file,
  //         ContentDisposition: "",
  //         ContentType: signImg.file.type,
  //         ACL: "public-read",
  //       });
  //       //console.log(client, command);
  //       const response = await client.send(command);
  //     }

  //     if (res === 1) {
  //       const inspectionDetailsArr = [];
  //       let imagesUploadToS3 = []; //This array is for images that need to be uploaded to s3

  //       const getImgString = (id) => {
  //         let imgString = "";
  //         let { imgArr } = submissions[id];
  //         imgArr.forEach((img, i) => {
  //           if (img.file) {
  //             const extension = img.file.name.split(".").pop();
  //             let imgName = `${getTimestamp(userId, i)}.${extension}`;
  //             let key = `image_data/org_${orgId}/inspections/${inspection_id}/${imgName}`;
  //             console.log("key", key);
  //             imagesUploadToS3.push({ key: key, file: img.file });
  //             imgString += imgName;
  //           } else {
  //             imgString += img.imgName;
  //           }
  //           imgString += ":::";
  //         });
  //         return imgString.slice(0, imgString.length - 3);
  //       };

  //       Object.keys(submissions).forEach((id) => {
  //         inspectionDetailsArr.push({
  //           inspection_detail_id: id, // `${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
  //           inspection_id: inspection_id, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
  //           task_id: submissions[id].taskId, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
  //           pass: submissions[id].pass,
  //           value: submissions[id].value,
  //           image: submissions[id].showImage ? getImgString(id) : "-1000",
  //           comments: submissions[id].comments,
  //           action: 2,
  //         });
  //       });

  //       const submissionDetailsJsonData = {
  //         email: "testgroup@12.in",
  //         sync_version: "v2",
  //         operation: "update",
  //         querystring: "addEditInspectionDetails",
  //         inspection_detail: inspectionDetailsArr,
  //       };

  //       const submissionDetailsRes = await addInspectionSubmission(
  //         submissionDetailsJsonData
  //       ); //Submit Submission Details Data
  //       console.log("submissionDetailsRes", submissionDetailsRes);

  //       if (submissionDetailsRes === 1) {
  //         //let imagesUploadToS3 = [];
  //         // Object.keys(submissions).forEach((id) => {
  //         //   submissions[id].imgArr.forEach((img) => {
  //         //     if (img.file) {
  //         //       const extension = img.file.name.split(".").pop();
  //         //       let key = `image_data/org_${orgId}/inspections/${inspection_id}/${getTimestamp(
  //         //         vehicle.vehicleId
  //         //       )}.${extension}`;
  //         //       //console.log(key);
  //         //       imagesUploadToS3.push({ key: key, file: img.file });
  //         //     }
  //         //   });
  //         // });

  //         if (imagesUploadToS3.length > 0) {
  //           //const allImgsUploaded = await uploadTaskImgsToS3(imagesUploadToS3);
  //           const statusArr = [];
  //           imagesUploadToS3.forEach(async (img, i) => {
  //             if (img.file) {
  //               //const extension = img.file.name.split(".").pop();
  //               //let imgName = `${getTimestamp(userId, i)}.${extension}`;
  //               //let key = `image_data/org_${orgId}/issues/${issueId}/${imgName}`;
  //               //uploadToS3Arr.push({ key: key, file: img.file });
  //               const status = await uploadImgToS3(img);
  //               statusArr.push(status);
  //               if (statusArr.length === imagesUploadToS3.length) {
  //                 console.log(statusArr);
  //                 const imgUploadSuccess = statusArr.every(
  //                   (status) => status === 200
  //                 );
  //                 console.log(imgUploadSuccess);
  //                 if (imgUploadSuccess) {
  //                   dispatch(addMsg(snackbarMsg));
  //                   history.goBack();
  //                   setIsLoading(false);
  //                 }
  //               }
  //             }
  //           });
  //         } else {
  //           history.goBack();
  //           setIsLoading(false);
  //         }
  //       }
  //       //console.log(submissionsJsonData);
  //     }
  //   })();
  // };

  const submitInspection = async (setLoading) => {
    try {
      setLoading(true);

      let startTimeSec = 0;
      let endTimeSec = 0;

      if (type !== "edit") {
        let inspectionDateISO = new Date(inspectionDate)
          .toISOString()
          .substring(0, 10);
        if (startTime) {
          //for start time
          let startTimeHours = startTime.match(/^(\d+)/)[1];
          let startTimeMinutes = startTime.match(/:(\d+)/)[1];
          let inspDateWithStartTime =
            inspectionDateISO +
            "T" +
            startTimeHours +
            ":" +
            startTimeMinutes +
            ":00";
          startTimeSec = new Date(inspDateWithStartTime).getTime() / 1000;
        }

        //for end time
        if (endTime) {
          let endTimeHours = endTime.match(/^(\d+)/)[1];
          let endTimeMinutes = endTime.match(/:(\d+)/)[1];
          let inspDateWithEndTime =
            inspectionDateISO +
            "T" +
            endTimeHours +
            ":" +
            endTimeMinutes +
            ":00";
          endTimeSec = new Date(inspDateWithEndTime).getTime() / 1000;
        }

        //Start-Time End Time validation
        if (!endTime) {
          const endTimeDate = new Date();
          const endTimeVal = getInitialStartTime(endTimeDate);
          setValue("endTime", endTimeVal);
          let inspectionDateISO = new Date(inspectionDate)
            .toISOString()
            .substring(0, 10);
          let endTimeHours = endTimeVal.match(/^(\d+)/)[1];
          let endTimeMinutes = endTimeVal.match(/:(\d+)/)[1];
          let inspDateWithEndTime =
            inspectionDateISO +
            "T" +
            endTimeHours +
            ":" +
            endTimeMinutes +
            ":00";
          endTimeSec = new Date(inspDateWithEndTime).getTime() / 1000;
        }

        if (startTimeSec >= endTimeSec) {
          setError(
            "endTime",
            {
              type: "custom",
              message: t("value_lower_than_start_time_err_msg"),
            },
            { shouldFocus: true }
          );
          setLoading(false);

          if (showWarningModal) {
            setShowWarningModal(false);
          }
          return;
        } else {
          clearErrors("endTime");
        }
      }

      let odoValue = "";

      if (isOdoReqd) {
        const distanceFactor = getDistanceFactorforOdo(
          vehicle?.primary_meter,
          distance
        );
        let odoTaskId = Object.keys(submissions).find(
          (id) => submissions[id].taskId === `${orgId}_odometer`
        );
        odoValue = +submissions[odoTaskId]?.value * distanceFactor;
      }

      const inspectionId =
        type === "edit"
          ? rowData?.inspection_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      const formId = type === "edit" ? form_id : inspectionForm.id;
      const inspectionBy =
        type === "edit" ? inspection_by : inspectedBy?.user_id;

      let sign = isSignReqd || signImg.url ? "Signature.png" : "";
      if (type === "edit") {
        sign = signature;
      }

      if (tag === "free-user") {
        sign = "";
      }

      const startTimeVal = type === "edit" ? start_time : startTimeSec;
      const endTimeVal = type === "edit" ? end_time : endTimeSec;

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("inspection") })
          : t("record_added_msg", { recordName: t("inspection") });

      const apiData = {
        sync_version: "v2",
        operation: type === "edit" ? "update" : "create",
        querystring: "addEditInspectionSubmission",
        inspection_id: inspectionId,
        veh_id: vehicle.vehicleId,
        inspection_date: type === "edit" ? inspection_date : startTimeSec, //Since startTimeSec is the current inspection date with the provided start time
        form_id: formId,
        inspection_by: inspectionBy,
        comments: comments,
        action: type === "edit" ? 2 : 1,
        signature: sign,
        start_time: startTimeVal,
        end_time: endTimeVal,
        odo: odoValue,
        source: "web",
      };

      const inspectionRes = await postApi(apiData, "inspection");

      if (inspectionRes.success) {
        //Upload Signature Image if present

        if (signImg.file && tag !== "free-user") {
          let buf = new Buffer.from(
            signImg.file.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );
          const command = new PutObjectCommand({
            Bucket: ALBUM_BUCKET_NAME,
            Key: `image_data/org_${orgId}/inspections/${inspectionId}/Signature.png`,
            Body: buf,
            ContentDisposition: "",
            ContentType: "image/png", //signImg.file.type,
            //ContentEncoding: 'base64',
            ACL: "public-read",
          });
          //console.log(client, command);
          const response = await client.send(command);
        }

        const inspectionDetailsArr = [];
        let imagesUploadToS3 = []; //This array is for images that need to be uploaded to s3

        const submissionIds = Object.keys(submissions);
        let count = 1;
        for (let id of submissionIds) {
          const odoTask = submissions[id].taskId === `${orgId}_odometer`;
          if (type === "edit") {
            const prevData = submissionsData.find(
              (sub) => sub.inspection_detail_id === id
            );
            let failed = "no";
            if (
              (prevData?.pass == 2 && submissions[id].pass == 0) ||
              (prevData?.pass == 1 && submissions[id].pass == 0)
            ) {
              failed = "yes";
            }

            let value = submissions[id].value;
            if (odoTask && +value) {
              const distanceFactor = getDistanceFactorforOdo(
                vehicle?.primary_meter,
                distance
              );
              value = value * distanceFactor;
            }

            inspectionDetailsArr.push({
              inspection_detail_id: id, // `${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
              inspection_id: inspection_id, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
              task_id: submissions[id].taskId, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
              pass: submissions[id].pass,
              value: value,
              image: submissions[id].showImage
                ? getImgDetails(
                    id,
                    imagesUploadToS3,
                    submissions,
                    inspectionId,
                    orgId,
                    userId
                  )
                : "-1000",
              comments: submissions[id].comments,
              action: 2,
              failedTasks: failed,
              failed_task_id: submissions[id].taskId,
            });
          } else {
            let value = "-1000";
            if (submissions[id].showValue) {
              value = submissions[id].value;

              if (odoTask && +value) {
                const distanceFactor = getDistanceFactorforOdo(
                  vehicle?.primary_meter,
                  distance
                );
                value = value * distanceFactor;
              }
            }

            inspectionDetailsArr.push({
              inspection_detail_id: `${vehicle?.vehicleId}_${
                Date.now() + count
              }`,
              inspection_id: inspectionId,
              task_id: submissions[id].taskId,
              pass: submissions[id].showPass ? submissions[id].pass : "-1000",
              value: value,
              image: submissions[id].showImage
                ? getImgDetails(
                    id,
                    imagesUploadToS3,
                    submissions,
                    inspectionId,
                    orgId,
                    userId
                  )
                : "-1000",
              comments: submissions[id].comments,
              action: 1,
            });
          }
          count += 1;
        }

        const dateToFormat = type === "edit" ? inspection_date : startTimeSec;

        const submissionDetailsJsonData = {
          sync_version: "v2",
          operation: type === "edit" ? "update" : "create",
          querystring: "addEditInspectionDetails",
          inspection_detail: inspectionDetailsArr,
          source: "web",
          //utc_offset: new Date().getTimezoneOffset(),
          formatted_date: format(new Date(dateToFormat * 1000), "dd MMM yyyy"),
        };

        const submissionDetailsRes = await postApi(
          submissionDetailsJsonData,
          "inspection"
        );
        if (submissionDetailsRes.success) {
          const imgsToIssues = submissionDetailsRes.issuesImageArray;
          if (imagesUploadToS3.length > 0) {
            const allImgsUploaded = await uploadTaskImgsToS3(imagesUploadToS3);
            if (allImgsUploaded) {
              postApi(
                {
                  querystring: "copyInspectionImages",
                  issuesImageArray: imgsToIssues,
                },
                "inspection"
              );
              dispatch(addMsg(snackbarMsg));
              setLoading(false);
              //history.goBack();
              history.push("/inspection/inspectionsubmissions", {
                fromCancel: false,
              });
            }
          } else {
            dispatch(addMsg(snackbarMsg));
            setLoading(false);
            history.push("/inspection/inspectionsubmissions", {
              fromCancel: false,
            });
          }
        }
      } else {
        setLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const onSubmit = async () => {
    //Check if odo is reqd
    if (isOdoReqd) {
      const odoTask = Object.values(submissions).find(
        (sub) => sub.taskId === `${orgId}_odometer`
      );
      //Check if odometer mileage value is present
      if (odoTask.value) {
        setOdoErr(false);
        setOdoErrMsg("");
      } else {
        odoRef.current.focus();
        setOdoErr(true);
        setOdoErrMsg(t("required_err"));
        return;
      }
    }

    for (let entry of Object.entries(commentsData)) {
      const [id, obj] = entry;
      if (obj.commentsRequired) {
        console.log(commentsData[id]);
        commentsData[id]?.ref.current.focus();
        setShowCommentsErr(true);
        return;
      }
      if (obj.commentsLengthErr) {
        console.log(commentsData[id]);
        commentsData[id]?.ref.current.focus();
        setShowCommentsErr(true);
        return;
      }
    }

    if (tag !== "free-user") {
      if (!signImg.url && isCanvasEmpty && isSignReqd) {
        setShowSignError(true);

        return;
      } else if (signImg.url && !isCanvasEmpty && !isSignReqd) {
        setShowSignError(false);
      }
    }

    //Combine add and edit logic - start

    // setIsLoading(true);

    // let odoValue = "";
    // if (isOdoReqd) {
    //   let odoTaskId = Object.keys(submissions).find(
    //     (id) => submissions[id].taskId === `${orgId}_odometer`
    //   );
    //   odoValue = submissions[odoTaskId]?.value;
    // }

    // const inspectionId =
    //   type === "edit"
    //     ? rowData?.inspection_id
    //     : `${vehicle?.vehicleId}_${Date.now()}`;

    // const formId = type === "edit" ? form_id : inspectionForm.id;
    // const inspectionBy = type === "edit" ? inspection_by : inspectedBy.id;

    // let sign = isSignReqd || signImg.url ? "Signature.png" : "";
    // if (type === "edit") {
    //   sign = signature;
    // }

    // const startTime = type === "edit" ? startTimeSec : start_time;
    // const endTime = type === "edit" ? endTimeSec : end_time;

    // const snackbarMsg =
    //   type === "edit"
    //     ? "Inspection Submission Successfully Updated"
    //     : "Inspection Submission Successfully Added";

    // const apiData = {
    //   sync_version: "v2",
    //   operation: type === "edit" ? "update" : "create",
    //   querystring: "addEditInspectionSubmission",
    //   inspection_id: inspectionId,
    //   veh_id: vehicle.vehicleId,
    //   inspection_date: type === "edit" ? inspection_date : startTimeSec, //Since startTimeSec is the current inspection date with the provided start time
    //   form_id: formId,
    //   inspection_by: inspectionBy,
    //   comments: comments,
    //   action: type === "edit" ? 2 : 1,
    //   signature: sign,
    //   start_time: startTime,
    //   end_time: endTime,
    //   odo: odoValue,
    // };

    // const inspectionRes = await postApi(apiData, "inspection");

    // if (inspectionRes.success) {
    //   //Upload Signature Image if present
    //   if (signImg.file) {
    //     const command = new PutObjectCommand({
    //       Bucket: ALBUM_BUCKET_NAME,
    //       Key: `image_data/org_${orgId}/inspections/${inspection_id}/Signature.png`,
    //       Body: signImg.file,
    //       ContentDisposition: "",
    //       ContentType: signImg.file.type,
    //       ACL: "public-read",
    //     });
    //     //console.log(client, command);
    //     const response = await client.send(command);
    //   }

    //   const inspectionDetailsArr = [];
    //   let imagesUploadToS3 = []; //This array is for images that need to be uploaded to s3

    //   //Logic to get inspectionDetailsArr and imagesUploadToS3 in case of add and edit
    //   if (type === "edit") {
    //     Object.keys(submissions).forEach((id) => {
    //       inspectionDetailsArr.push({
    //         inspection_detail_id: id, // `${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
    //         inspection_id: inspection_id, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
    //         task_id: submissions[id].taskId, //`${vehicle.vehicleId}_${getTimestamp(vehicle.vehicleId)}`,
    //         pass: submissions[id].pass,
    //         value: submissions[id].value,
    //         image: submissions[id].showImage
    //           ? getImgDetails(id, imagesUploadToS3, submissions, inspectionId)
    //           : "-1000",
    //         comments: submissions[id].comments,
    //         action: 2,
    //       });
    //     });
    //   } else {
    //     Object.keys(submissions).forEach((id, i) => {
    //       inspectionDetailsArr.push({
    //         inspection_detail_id: getTimestamp(vehicle.vehicleId, i),
    //         inspection_id: inspectionId,
    //         task_id: submissions[id].taskId,
    //         pass: submissions[id].showPass ? submissions[id].pass : "-1000",
    //         value: submissions[id].showValue ? submissions[id].value : "-1000",
    //         image: submissions[id].showImage
    //           ? getImgDetails(id, imagesUploadToS3, submissions, inspectionId)
    //           : "-1000",
    //         comments: submissions[id].comments,
    //         action: 1,
    //       });
    //     });
    //   }

    //   const submissionDetailsJsonData = {
    //     sync_version: "v2",
    //     operation: type === "edit" ? "update" : "create",
    //     querystring: "addEditInspectionDetails",
    //     inspection_detail: inspectionDetailsArr,
    //   };

    //   const submissionDetailsRes = await postApi(
    //     submissionDetailsJsonData,
    //     "inspection"
    //   );
    //   if (submissionDetailsRes.success) {
    //     if (imagesUploadToS3.length > 0) {
    //       const allImgsUploaded = await uploadTaskImgsToS3(imagesUploadToS3);
    //       if (allImgsUploaded) {
    //         setIsLoading(false);
    //         dispatch(addMsg(snackbarMsg));
    //         history.goBack();
    //       }
    //     } else {
    //       setIsLoading(false);
    //       dispatch(addMsg(snackbarMsg));
    //       history.goBack();
    //     }
    //   }
    // }

    //end

    if (isOdoReqd) {
      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );

      let odoTaskId = Object.keys(submissions).find(
        (id) => submissions[id].taskId === `${orgId}_odometer`
      );
      let odoValue = +submissions[odoTaskId]?.value;
      let odoConverted = odoValue * distanceFactor;

      //Odo Validation
      const odoCheckApiData = {
        odo: odoConverted,
        veh_id: vehicle.vehicleId,
        datetocheck: type === "edit" ? rowData.start_time : startTimeSec,
        querystring: "get_around_dates_v3",
        check_from: "inspections",
        check_id: type === "edit" ? rowData.inspection_id : "",
      };

      const odoRes = await postApi(odoCheckApiData, "commonNew");
      if (!odoRes.success) {
        let { date, odometer, screen_name } = odoRes;
        let dateInSec = Number(date);
        const displayDate = getDisplayDateWithTime(dateInSec);
        //const errMsg = `One of your ${screen_name} records shows an odometer reading of ${odometer}, dated ${displayDate}`;
        const errMsg = t("odo_val_err_msg", {
          screenName: screen_name,
          odometer: odometer,
          date: displayDate,
        });
        odoRef.current.focus();
        setOdoErr(true);
        setOdoErrMsg(errMsg);
        return;
      }

      if (odoRes.success) {
        setOdoErr(false);
        setOdoErrMsg("");
      }

      // const odoValidationRes = await odoValidation(
      //   "odometer",
      //   odoCheckApiData,
      //   setError,
      //   setIsLoading
      // );

      // if (!odoValidationRes) {
      //   return;
      // }
    }

    if (tasksIncomplete) {
      setShowWarningModal(true);
      return;
    }

    await submitInspection(setIsLoading);
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}

      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}

      {showImgModal && (
        <ZoomModal
          showModal={showImgModal}
          setShowModal={setShowImgModal}
          doc={currentImg}
        />
      )}

      <FormContainer
        onSubmit={handleSubmit(onSubmit)}
        //height={window.innerHeight - 200}
      >
        <FormHeader
          title={
            type === "add"
              ? t("add_inspection_submission")
              : t("edit_inspection_submission")
          }
          handleCancel={() =>
            history.push("/inspection/inspectionsubmissions", {
              fromCancel: true,
            })
          }
          disableSave={type === "add" ? !inspectionForm : false}
          isSaveLoading={isLoading}
        />

        {showWarningModal && (
          <WarningModal
            showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
            submitInspection={submitInspection}
          />
        )}

        <Container>
          <Card>
            <CardBody
              className="m-0 py-2 px-0"
              style={{
                //height: 'auto'
                height: `${window.innerHeight - 150}px`,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {type === "add" && (
                <>
                  <Row className="px-3 py-2">
                    <Col md={4}>
                      <FormGroupLabel className="mt-0 p-0">
                        {t("inspection_form_label")}
                      </FormGroupLabel>
                      <Controller
                        name="inspectionForm"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={formsData}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("inspection_form_placeholder")}
                            styles={formStyles}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />
                      {errors?.inspectionForm && (
                        <Error error={errors.inspectionForm.message} />
                      )}
                    </Col>
                  </Row>
                  {inspectionForm && (
                    <>
                      <Row className="px-3 py-1">
                        <Col md={4}>
                          <FormGroupLabel className="mt-0 p-0">
                            {t("inspection_by")}
                          </FormGroupLabel>
                          <Controller
                            name="inspectedBy"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={userData}
                                // defaultValue={allUsers.find(
                                //   (u) => u.user_id === userId
                                // )}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                //onCreateOption={handleCreate}
                                placeholder={t("user_placeholder")}
                                styles={formStyles}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />
                          {errors?.inspectedBy && (
                            <Error error={errors.inspectedBy.message} />
                          )}
                        </Col>
                        <Col md={4}>
                          <FormGroupLabel className="mt-0 p-0">
                            {t("inspection_date")}
                          </FormGroupLabel>
                          <Controller
                            name="inspectionDate"
                            //defaultValue={new Date()}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <DatePicker
                                //minDate={new Date()}
                                id="datepicker"
                                locale={getDateFnsLocale()}
                                dateFormat={"P"}
                                dropdownMode="scroll"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                selected={value}
                                onChange={onChange}
                                customInput={<CustomInput />}
                                placeholderText={t("date_tv")}
                              />
                            )}
                            rules={{
                              required: t("required_err"),
                            }}
                          />
                          {errors?.inspectionDate && (
                            <Error error={errors.inspectionDate.message} />
                          )}
                        </Col>
                      </Row>
                      <Row className="px-3 py-1">
                        <Col md={4}>
                          <FormGroupLabel className="mt-0">
                            {t("start_time")}
                          </FormGroupLabel>
                          <FormItem>
                            <FormField
                              name="startTime"
                              control={control}
                              component="input"
                              type="time"
                              errors={errors}
                              rules={{
                                required: t("required_err"),
                              }}
                              //defaultValue={getInitialStartTime}
                              placeholder={t("start_time_placeholder")}
                              style={formInputStyles}
                              //onChange={handleTimeChange}
                              //onKeyUp={handleDueOdoChange}
                            />
                          </FormItem>
                          {errors?.startTime && (
                            <Error error={errors.startTime.message} />
                          )}
                        </Col>
                        <Col md={4}>
                          <FormGroupLabel className="mt-0">
                            {t("end_time")}
                          </FormGroupLabel>
                          <FormItem>
                            <FormField
                              name="endTime"
                              control={control}
                              component="input"
                              type="time"
                              errors={errors}
                              // rules={{
                              //   required: t("required_err"),
                              //   //min: 'hi'
                              //   // validate: {
                              //   //   custom: {
                              //   //     value: startTime + 1,
                              //   //     message:
                              //   //       "Value must be greater than start time",
                              //   //   },
                              //   // },
                              // }}
                              //defaultValue=""
                              placeholder={t("end_time_placeholder")}
                              style={formInputStyles}
                              //onChange={handleTimeChange}
                              //onKeyUp={handleDueOdoChange}
                            />
                          </FormItem>
                          {errors?.endTime && (
                            <Error error={errors.endTime.message} />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              {type === "edit" && (
                <Row className="px-3 py-1">
                  <Col>
                    <span style={{ fontSize: "16px", fontWeight: 500 }}>
                      {form_name}
                    </span>
                    <div className="d-flex align-items-center">
                      <span>{`${t("inspected_by")} ${user_name} `}</span>
                      <span
                        style={{
                          fontSize: "20px",
                          padding: "0 8px",
                          color: colorLightGray,
                        }}
                      >
                        |
                      </span>

                      <span>
                        {`${convertToTextDate(
                          new Date(inspection_date * 1000)
                        )}`}
                      </span>
                      <span
                        style={{
                          fontSize: "20px",
                          padding: "0 8px",
                          color: colorLightGray,
                        }}
                      >
                        |
                      </span>
                      <span>{`${convertToDisplayTime(
                        new Date(start_time * 1000)
                      )} - ${convertToDisplayTime(
                        new Date(end_time * 1000)
                      )}`}</span>
                    </div>
                  </Col>
                </Row>
              )}

              {showFields && (
                <>
                  <Row className="px-3 py-1">
                    <Col md={4}>
                      <FormGroupLabel className="m-0 p-0">
                        {t("vehicle")}
                      </FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="vehicle-select"
                            ref={ref}
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />
                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                  </Row>
                  <hr className="my-2" />

                  {Object.keys(submissions).length > 0 && (
                    <>
                      {Object.keys(submissions)?.map((id) => {
                        return (
                          <div className="px-2 pb-2" key={id}>
                            <Row
                              className="py-1 m-0"
                              style={{ background: "#f1f1f1" }}
                            >
                              <Col>
                                <div
                                  className="py-1"
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                >
                                  {submissions[id].taskName}
                                </div>
                                <Row>
                                  {submissions[id].showPass && (
                                    <Col md={5}>
                                      <div className="py-1">
                                        <ButtonGroup>
                                          {toggleButtons.map((btn) => {
                                            return (
                                              <Toggle
                                                key={btn.id}
                                                id={`${id}-${btn.id}`}
                                                type="radio"
                                                variant={
                                                  btn.id === 1
                                                    ? "outline-success"
                                                    : btn.id === 0
                                                    ? "outline-danger"
                                                    : "outline-seondary"
                                                }
                                                name={`${id}-pass`}
                                                value={btn.value}
                                                checked={
                                                  submissions[
                                                    id
                                                  ]?.pass?.toString() ===
                                                  btn.value
                                                }
                                                onChange={(e) =>
                                                  updatePassFail(e)
                                                }
                                                //onClick={(e) => updatePassFail(e)}
                                              >
                                                {btn.label}
                                              </Toggle>
                                            );
                                          })}
                                        </ButtonGroup>
                                      </div>
                                    </Col>
                                  )}
                                  {submissions[id].showValue && (
                                    <Col md={5} className="py-1">
                                      <FormItem
                                        showWarning={
                                          submissions[id].taskId ===
                                            `${orgId}_odometer` &&
                                          showHighOdoErr
                                        }
                                      >
                                        <input
                                          ref={
                                            submissions[id].taskId ===
                                            `${orgId}_odometer`
                                              ? odoRef
                                              : null
                                          }
                                          style={{
                                            ...formInputStyles,
                                            background: "white",
                                          }}
                                          type={
                                            submissions[id].taskId ===
                                            `${orgId}_odometer`
                                              ? "number"
                                              : "text"
                                          }
                                          //onKeyDown={handleNegative}
                                          onWheel={
                                            handleNumberInputOnWheelPreventChange
                                          }
                                          name={`${id}-value`}
                                          placeholder={t("value")}
                                          value={submissions[id]?.value}
                                          onKeyUp={(e) => {
                                            const { value } = e.target;
                                            if (
                                              submissions[id].taskId ===
                                                `${orgId}_odometer` &&
                                              value &&
                                              lastOdoValue &&
                                              +value - +lastOdoValue > 1000
                                            ) {
                                              setShowHighOdoErr(true);
                                            } else {
                                              setShowHighOdoErr(false);
                                            }
                                          }}
                                          onChange={(e) => updateValue(e)}
                                        />
                                      </FormItem>
                                      {submissions[id].taskId ===
                                        `${orgId}_odometer` &&
                                      odoErr &&
                                      odoErrMsg ? (
                                        <Error error={odoErrMsg} />
                                      ) : (
                                        submissions[id].taskId ===
                                          `${orgId}_odometer` &&
                                        showHighOdoErr && (
                                          <Error
                                            error={t("high_odo_err_msg")}
                                          />
                                        )
                                      )}
                                      {submissions[id].taskId ===
                                        `${orgId}_odometer` && (
                                        <>
                                          {odoFetchedMsg && (
                                            <HintText>{odoFetchedMsg}</HintText>
                                          )}
                                          <HintText>{odoHint}</HintText>
                                        </>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col md={10} className="py-1">
                                    <FormItem>
                                      <input
                                        ref={commentsData[id].ref}
                                        style={{
                                          ...formInputStyles,
                                          background: "white",
                                        }}
                                        name={`${id}-comments`}
                                        placeholder={
                                          commentsData[id]?.commentsPlaceholder
                                        }
                                        value={submissions[id]?.comments}
                                        onChange={(e) => updateValue(e)}
                                      />
                                    </FormItem>
                                    {showCommentsErr &&
                                      (commentsData[id].commentsRequired ||
                                        commentsData[id].commentsLengthErr) && (
                                        //!submissions[id]?.comments &&
                                        //commentsData[id].commentsRequired &&
                                        <Error
                                          error={
                                            commentsData[id].commentsRequired
                                              ? t("required_err")
                                              : t("min_25_chars_reqd_err_msg")
                                          }
                                        />
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                md={1}
                                style={{
                                  borderLeft: `1px solid ${colorLightGray}`,
                                  padding: 0,
                                  width: "auto",
                                }}
                              ></Col>
                              <Col
                                className="d-flex align-items-center"
                                style={{ overflow: "auto" }}
                              >
                                <Dropzone
                                  onDrop={(files) => handleImgUpload(files, id)}
                                  multiple={true}
                                  //accept="image/*"
                                  accept={{
                                    "image/*": [".jpg", ".jpeg", ".png"],
                                    // "application/pdf": [
                                    //   ".pdf",
                                    //   ".doc",
                                    //   ".docx",
                                    // ],
                                  }}
                                  noClick
                                  disabled={!submissions[id].showImage}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      style={{
                                        width: "-webkit-fill-available",
                                        height: "-webkit-fill-available",
                                        //overflow: "auto",
                                      }}
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                      className="d-flex align-items-center gap-2"
                                    >
                                      {submissions[id]?.imgArr?.map((img) => {
                                        return (
                                          <ImageWithZoom
                                            key={img.id}
                                            img={img}
                                            images={submissions[id]?.imgArr}
                                            //setImages={setFillupImgs}
                                            handleDelete={() =>
                                              handleDeleteImg(img, id)
                                            }
                                            shouldDelete={true}
                                            fromDropzone={true}
                                            imgClickHandler={() =>
                                              handleImgClick(img)
                                            }
                                          />
                                        );
                                      })}

                                      {submissions[id].showImage && (
                                        <FileUploader
                                          handleImgUpload={handleImgUpload}
                                          inspectionDetailId={id}
                                          imgArr={submissions[id].imgArr}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Dropzone>
                                {/* {showImgModal && (
                                  <ImgModal
                                    showImgModal={showImgModal}
                                    setShowImgModal={setShowImgModal}
                                    currentImg={currentImg}
                                    setCurrentImg={setCurrentImg}
                                  />
                                )} */}
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <hr className="mb-2 mt-0" />
                  {(showCanvas || signImg.url || tag === "free-user") && (
                    <Row className="px-3 pt-2 pb-3">
                      <Col md={4}>
                        <FormGroupLabel className="mt-0 p-0">
                          {t("signature")}
                          {tag === "free-user" && (
                            <SubscribeTooltipIcon
                              message={t("subscribe_msg")}
                              handleClick={() => setShowSubscribePopup(true)}
                            />
                          )}
                        </FormGroupLabel>
                        {tag === "free-user" ? (
                          <div
                            style={{
                              width: "240px",
                              height: "120px",
                              //margin: "4px 0",
                              border: `1px solid ${colorBorder}`,
                              background: disabledBgColor,
                            }}
                          ></div>
                        ) : (
                          <div>
                            {showCanvas && (
                              <>
                                <SignatureCanvas
                                  canvasProps={{ className: "signature" }}
                                  ref={(ref) => setSignRef(ref)}
                                  clearOnResize={false}
                                  onEnd={handleCanvasOnEnd}
                                />
                                {/* </div> */}
                                {isCanvasEmpty && isSignReqd === 1 && (
                                  <Error error={t("required_err")} />
                                )}
                                <div className="d-flex gap-2 my-1">
                                  <FilterButton
                                    onClick={handleClearSignature}
                                    type="button"
                                  >
                                    {t("clear")}
                                  </FilterButton>
                                  {/* <AddFormButton
                              onClick={handleSaveSignature}
                              type="button"
                              //disabled={isCanvasEmpty}
                            >
                              Save
                            </AddFormButton> */}
                                </div>
                              </>
                            )}
                            {signImg && signImg.url && type === "edit" && (
                              <div>
                                <img
                                  src={signImg.url}
                                  alt="signature-image"
                                  style={{
                                    width: "240px",
                                    height: "120px",
                                    margin: "4px 0",
                                  }}
                                  className="img-thumbnail"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className="px-3 pt-2 pb-3">
                    <FormGroupLabel className="mt-0">
                      {t("notes_tv")}
                    </FormGroupLabel>
                    <Col md={4}>
                      <FormItem
                      //tabIndex={0}
                      >
                        <FormField
                          name="comments"
                          control={control}
                          component="input"
                          //type="number"
                          //errors={errors}
                          // rules={{
                          //   required:
                          //     +inspectionIntervalDuration ||
                          //     +inspectionIntervalOdo
                          //       ? false
                          //       : t("odo_or_duration_err"),
                          //   min: {
                          //     value: 1,
                          //     message: t("greater_than_zero_err"),
                          //   },
                          // }}
                          defaultValue=""
                          //isAboveError={isAboveError}
                          placeholder={t("notes_tv")}
                          style={formInputStyles}
                        />
                      </FormItem>
                      {/* {errors?.inspectionIntervalOdo && (
                            <Error
                              error={errors.inspectionIntervalOdo.message}
                            />
                          )} */}
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
        {/* type !== "edit" && showInfoPopup && !dontShowPopup && !closePopup */}
        {type !== "edit" && showInfoPopup && !dontShowPopup && !closePopup && (
          <InfoPopup
            //closePopup={closePopup}
            showInfoPopup={showInfoPopup}
            setShowInfoPopup={setShowInfoPopup}
            //handleDontShow={handleDontShow}
            //dontShowPopup={dontShowPopup}
          />
        )}
      </FormContainer>
    </>
  );
};

export default InspectionSubmissionsForm;

//styles

export const InspectionsContainer = styled.div`
  //height: ${(p) => p.height && p.height}px;
  //height: inherit;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  ${scrollbarStyles};
`;

export const AddImgIcon = styled.i`
  cursor: pointer;
  font-size: 30px;
  position: relative;
  top: 4px;
  color: ${colorGray};

  &:hover {
    color: ${colorLightGray};
  }
`;

export const ImgDeleteIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  //top: -28px;
  right: 0px;
  top: -16px;
  color: ${colorGray};

  &:hover {
    color: ${colorLightGray};
  }
`;

const FileUploader = ({ handleImgUpload, inspectionDetailId, imgArr }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation("common");

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    //console.log('click');
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    //console.log("change");
    //const fileUploaded = event.target.files[0];
    const { files } = event.target;
    handleImgUpload(files, inspectionDetailId);
  };
  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}

      {imgArr?.length > 0 ? (
        <AddImgIcon className="fi fi-rr-add" onClick={handleClick}></AddImgIcon>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center gap-1"
          style={{
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
          }}
        >
          <p style={{ fontSize: "12px" }}>{t("drag_and_drop_files_here")}</p>
          <LinkSpan style={{ fontSize: "12px" }} onClick={handleClick}>
            {`${t("or")} ${t("click_to_select_file")}`}
          </LinkSpan>
        </div>
      )}
      <input
        type="file"
        id="upload-img"
        name="upload-img"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleChange}
        //onClick={handleChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
};

export const getImgDetails = (
  id,
  uploadToS3Arr,
  submissionsData,
  inspectionId,
  orgId,
  userId
) => {
  let imgString = "";
  let { imgArr } = submissionsData[id];
  imgArr.forEach((img, i) => {
    if (img.file) {
      const extension = img.file.name.split(".").pop();
      let imgName = `${getTimestamp(userId, i)}.${extension}`;
      let key = `image_data/org_${orgId}/inspections/${inspectionId}/${imgName}`;
      uploadToS3Arr.push({ key: key, file: img.file });
      imgString += imgName;
    } else {
      imgString += img.imgName;
    }
    imgString += ":::";
  });
  return imgString.slice(0, imgString.length - 3);
};

// export const uploadTaskImgsToS3 = async (uploadToS3Arr) => {
//   //const history = useHistory();
//   const client = new S3Client({
//     region: "us-east-2",
//     credentials: fromCognitoIdentityPool({
//       identityPoolId: IDENTITY_POOL_ID,
//       client: new CognitoIdentityClient({ region: BUCKET_REGION }),
//     }),
//     apiVersion: "2006-03-01",
//   });

//   const statusCodeArr = [];
//   uploadToS3Arr.forEach(async (img) => {
//     try {
//       const command = new PutObjectCommand({
//         Bucket: ALBUM_BUCKET_NAME,
//         Key: img.key,
//         Body: img.file,
//         ContentDisposition: "",
//         ContentType: img.file.type,
//         ACL: "public-read",
//       });
//       const response = await client.send(command);
//       //console.log("s3 response", response);
//       const statusCode = response.$metadata.httpStatusCode;
//       console.log("statusCode in method", statusCode);
//       statusCodeArr.push(statusCode);
//       if (statusCodeArr.length === uploadToS3Arr.length) {
//         //console.log(statusCodeArr);
//         const allImgsUploaded = statusCodeArr.every((code) => code === 200);
//         //console.log(allImgsUploaded);
//         //return allImgsUploaded;
//       }
//     } catch (err) {
//       console.error("s3 error", err);
//       return err;
//     }
//   });
// };

export const uploadTaskImgsToS3 = async (uploadToS3Arr) => {
  const client = new S3Client({
    region: "us-east-2",
    credentials: fromCognitoIdentityPool({
      identityPoolId: IDENTITY_POOL_ID,
      client: new CognitoIdentityClient({ region: BUCKET_REGION }),
    }),
    apiVersion: "2006-03-01",
  });

  try {
    const statusCodeArr = [];
    for (let i = 0; i < uploadToS3Arr.length; i++) {
      let currentImg = uploadToS3Arr[i];
      const command = new PutObjectCommand({
        Bucket: ALBUM_BUCKET_NAME,
        Key: currentImg.key,
        Body: currentImg.file,
        ContentDisposition: "",
        ContentType: currentImg.file.type,
        ACL: "public-read",
      });
      const response = await client.send(command);
      //console.log("s3 response", response);
      const statusCode = response.$metadata.httpStatusCode;
      statusCodeArr.push(statusCode);
      if (statusCodeArr.length === uploadToS3Arr.length) {
        const allImgsUploaded = statusCodeArr.every((code) => code === 200);
        return allImgsUploaded;
      }
    }
  } catch (e) {
    console.error("s3 error", e);
    return e;
  }

  // uploadToS3Arr.forEach(async (img) => {
  //   try {
  //     const command = new PutObjectCommand({
  //       Bucket: ALBUM_BUCKET_NAME,
  //       Key: img.key,
  //       Body: img.file,
  //       ContentDisposition: "",
  //       ContentType: img.file.type,
  //       ACL: "public-read",
  //     });
  //     const response = await client.send(command);
  //     //console.log("s3 response", response);
  //     const statusCode = response.$metadata.httpStatusCode;
  //     console.log("statusCode in method", statusCode);
  //     statusCodeArr.push(statusCode);
  //     if (statusCodeArr.length === uploadToS3Arr.length) {
  //       //console.log(statusCodeArr);
  //       const allImgsUploaded = statusCodeArr.every((code) => code === 200);
  //       //console.log(allImgsUploaded);
  //       //return allImgsUploaded;
  //     }
  //   } catch (err) {
  //     console.error("s3 error", err);
  //     return err;
  //   }
  // });
};

export const WarningModal = ({
  showWarningModal,
  setShowWarningModal,
  submitInspection,
}) => {
  const { t } = useTranslation("common");
  const [popupSaveBtnLoading, setPopupSaveBtnLoading] = useState(false);

  const handleCancel = () => {
    setShowWarningModal(false);
  };

  const handleSave = async () => {
    await submitInspection(setPopupSaveBtnLoading);
    setShowWarningModal(false);
  };

  return (
    <Modal show={showWarningModal} onHide={handleCancel}>
      <Modal.Header className="d-flex justify-content-between">
        {/* <Modal.Title>Modal heading</Modal.Title> */}
        {/* <p style={{ fontWeight: 500, fontSize: "16px" }}>Warning</p>
        <ActionIcon
          className="fi fi-rr-cross-small"
          onClick={handleCancel}
        ></ActionIcon> */}
        <h4>{t("warning")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={handleCancel}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>{t("insp_tasks_incomplete_warn_msg")}</Modal.Body>
      <Modal.Footer className="d-flex gap-3">
        <FilterButton
          type="button"
          onClick={handleCancel}
          disabled={popupSaveBtnLoading}
        >
          {t("cancel")}
        </FilterButton>
        <AddFormButton
          type="button"
          onClick={handleSave}
          disabled={popupSaveBtnLoading}
        >
          {popupSaveBtnLoading ? <CustomLoader /> : t("save")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export const ImgModal = ({
  showImgModal,
  setShowImgModal,
  currentImg,
  //setCurrentImg,
}) => {
  return (
    <Modal show={showImgModal} onHide={() => setShowImgModal(false)} centered>
      <Modal.Body>
        <div style={{ overflow: "hidden" }}>
          <ImgContainer>
            <ReactPanZoom image={currentImg.url} alt="task-image" />
          </ImgContainer>
        </div>
      </Modal.Body>
    </Modal>
  );
};
