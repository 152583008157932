import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { VerticalColoredTabs } from "@/shared/components/Tabs";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import { scrollbarStyles } from "../../utils/palette";
import LicenseForm from "./LicenseForm";
import NewDocumentForm from "./NewDocumentForm";
import { getUserDocUrl, getVehDocUrl } from "../../shared/helpers";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import SubscribePopup from "../../shared/components/SubscribePopup";

const DocumentsForm = ({
  rowData,
  type,
  documents,
  setDocuments,
  licenseInfo,
  docUpdated,
  setDocUpdated,
  licenseDocFile,
  setLicenseDocFile,
}) => {
  const { orgId, userId } = useSelector((state) => state.user);
  const location = useLocation();
  const screen = location.pathname;

  useEffect(() => {
    if (type === "edit") {
      if (rowData?.additional_documents) {
        (async () => {
          const additionalDocsData = JSON.parse(rowData?.additional_documents);
          const allDocsArr = additionalDocsData.All_Documents;
          let temp = [];
          let count = 0;

          for (let doc of allDocsArr) {
            const [[key, value]] = Object.entries(doc);
            console.log(key, value);
            const { items, image, visible } = value;
            let isVisible = visible;

            if (typeof visible === "object") {
              isVisible = visible[0];
            }

            // let updatedDate = "";
            // if (items[0]?.title === "Updated date") {
            //   updatedDate = items[0]?.author;
            // }

            //For users, image format has two formats
            if (screen?.includes("users")) {
              let updatedDate = "";
              if (items && items?.length > 0) {
                if (items[0]?.title === "Updated date") {
                  updatedDate = items[0]?.author;
                }
              }
              // (async () => {
              const { url, extension } = await getUserDocUrl(
                rowData.org_id,
                rowData.user_id,
                key,
                image
              );
              temp.push({
                id: Date.now() + count,
                docName: key,
                existingDoc: true,
                imgUpdated: false,
                fields: updatedDate ? items.slice(1, items.length) : items,
                updatedDate: updatedDate ? updatedDate : "",
                isVisible: isVisible,
                image: {
                  url: url,
                  extension: extension,
                  type: image,
                },
              });
            }

            if (screen?.includes("vehicles")) {
              //For vehicles screen
              //console.log("items", items);
              //console.log(Object.keys(items[0])[0]);
              let updatedDate = "";
              if (items && items?.length > 0) {
                if (Object.keys(items[0])[0] === "Updated date") {
                  updatedDate = items[0]["Updated date"];
                }
              }

              const { url, extension } = await getVehDocUrl(
                rowData.org_id,
                rowData.veh_id,
                image
              );

              temp.push({
                id: Date.now() + count,
                docName: key,
                existingDoc: true,
                imgUpdated: false,
                fields: updatedDate ? items.slice(1, items.length) : items,
                updatedDate: updatedDate ? updatedDate : "",
                isVisible: isVisible,
                isError: false,
                image: {
                  //imgFormat: "normal",
                  url: url,
                  type: image,
                  extension: extension,
                },
              });
            }
            count += 1;
          }

          // allDocsArr.forEach((doc, i) => {
          //   const [[key, value]] = Object.entries(doc);
          //   console.log(key, value);
          //   const { items, image, visible } = value;
          //   let isVisible = visible;

          //   if (typeof visible === "object") {
          //     isVisible = visible[0];
          //   }

          //   let updatedDate = "";
          //   if (items[0]?.title === "Updated date") {
          //     updatedDate = items[0]?.author;
          //   }

          //   //For users, image format has two formats
          //   if (screen?.includes("users")) {
          //     (async () => {
          //       const { url, extension } = await getUserDocUrl(
          //         rowData.org_id,
          //         rowData.user_id,
          //         key,
          //         image
          //       );
          //       temp.push({
          //         id: Date.now() + i,
          //         docName: key,
          //         existingDoc: true,
          //         imgUpdated: false,
          //         fields: updatedDate ? items.slice(1, items.length) : items,
          //         updatedDate: updatedDate ? updatedDate : "",
          //         isVisible: isVisible,
          //         image: {
          //           url: url,
          //           extension: extension,
          //           type: image[0],
          //         },
          //       });
          //     })();
          //     // if (imageFormat && imageFormat === "normal") {
          //     //   temp.push({
          //     //     id: Date.now() + i,
          //     //     docName: key,
          //     //     existingDoc: true,
          //     //     imgUpdated: false,
          //     //     fields: updatedDate ? items.slice(1, items.length) : items,
          //     //     updatedDate: updatedDate ? updatedDate : "",
          //     //     isVisible: isVisible,
          //     //     image: {
          //     //       imgFormat: "normal",
          //     //       url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${rowData?.org_id}/user_${rowData?.user_id}/Documents/${key}.${image[0]}`,
          //     //       extension: image,
          //     //     },
          //     //   });
          //     // } else {
          //     //   temp.push({
          //     //     id: Date.now() + i,
          //     //     docName: key,
          //     //     existingDoc: true,
          //     //     imgUpdated: false,
          //     //     fields: items,
          //     //     isVisible: isVisible,
          //     //     image: {
          //     //       imgFormat: "existing",
          //     //       url: `image_data/org_${orgId}/user_${userId}/Documents/${key}.txt`,
          //     //       extension: image,
          //     //     },
          //     //   });
          //     // }
          //   } else if (screen?.includes("vehicles")) {
          //     //For vehicles screen
          //     temp.push({
          //       id: Date.now() + i,
          //       docName: key,
          //       existingDoc: true,
          //       imgUpdated: false,
          //       fields: updatedDate ? items.slice(1, items.length) : items,
          //       updatedDate: updatedDate ? updatedDate : "",
          //       isVisible: isVisible,
          //       image: {
          //         imgFormat: "normal",
          //         url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${rowData?.org_id}/vehicle_${rowData?.veh_id}/Documents/${key}.${image[0]}`,
          //         extension: image,
          //       },
          //     });
          //   }
          // });
          console.log("temp", temp);
          setDocuments(temp);
        })();
      }
    }
  }, []);

  return (
    <VerticalColoredTabs>
      <Tabs
        documents={documents}
        setDocuments={setDocuments}
        type={type}
        rowData={rowData}
        licenseInfo={licenseInfo}
        docUpdated={docUpdated}
        setDocUpdated={setDocUpdated}
        screen={screen}
        licenseDocFile={licenseDocFile}
        setLicenseDocFile={setLicenseDocFile}
      />
    </VerticalColoredTabs>
  );
};

export default DocumentsForm;

const Tabs = ({
  documents,
  setDocuments,
  type,
  rowData,
  licenseInfo,
  docUpdated,
  setDocUpdated,
  screen,
  licenseDocFile,
  setLicenseDocFile,
}) => {
  const [activeKey, setActiveKey] = useState(1);
  const { tag } = useAuth();
  const { t } = useTranslation("common");

  const { watch } = useFormContext();
  const status = watch("status");
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  // addToEventKey = 2 for users screen since License Form takes eventKey=1 so documents will always start from 2.
  //For vehicles screen, there is no License Form so documents will start from eventKey=1
  let addToEventKey = screen.includes("users") ? 2 : 1;

  const isDisabled = !status;

  const handleAddNewDoc = () => {
    if (tag === "free-user") {
      setActiveKey(1);
      setShowSubscribePopup(true);
      return;
    }
    if (isDisabled) {
      return;
    }
    setDocuments((prev) => {
      let temp = [...prev];
      temp.push({
        id: Date.now(),
        docName: "",
        fields: [],
        isVisible: true,
        image: "",
        isError: false,
      });
      return temp;
    });
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      <Tab.Container activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
        <TabsWrap>
          {/* <div> */}
          <Nav className="nav-tabs">
            {screen.includes("users") && (
              <NavItem id="license-tab" $vertical={true}>
                <NavLink eventKey="1">{t('license')}</NavLink>
              </NavItem>
            )}
            {documents?.map((doc, i) => {
              return (
                <NavItem id={`document-item-${i}`} $vertical={true}>
                  <NavLink
                    id={`document-link-${i}`}
                    eventKey={i + addToEventKey}
                    className="d-flex justify-content-center align-items-center gap-2"
                  >
                    <span>
                      {doc.docName ? doc.docName : "Unnamed document"}
                    </span>
                    {doc?.isError && (
                      <i
                        className="fi fi-rr-triangle-warning"
                        style={{
                          color: "red",
                          position: "relative",
                          top: "2px",
                        }}
                      ></i>
                    )}
                  </NavLink>
                </NavItem>
              );
            })}
            <NavItem
              onClick={handleAddNewDoc}
              disabled={isDisabled}
              id="add-new-doc-item"
              $vertical={true}
            >
              <NavLink
                eventKey={documents?.length + addToEventKey}
                disabled={isDisabled}
                id="add-new-doc-link"
                className="d-flex justify-content-center align-items-center gap-2"
              >
                <span>+{t('add_new_doc')}</span>
                {tag === "free-user" && (
                  <SubscribeTooltipIcon message={t("subscribe_msg")} />
                )}
              </NavLink>
            </NavItem>
          </Nav>
          {/* </div> */}
          <TabContent
          //style={{ height: `${window.innerHeight - 192}px`, overflow: "auto" }}
          >
            {screen.includes("users") && (
              <TabPane eventKey="1">
                <LicenseForm
                  type={type}
                  rowData={rowData}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  licenseInfo={licenseInfo}
                  licenseDocFile={licenseDocFile}
                  setLicenseDocFile={setLicenseDocFile}
                />
              </TabPane>
            )}
            {documents?.map((doc, i) => {
              return (
                <TabPane eventKey={i + addToEventKey} key={i + addToEventKey}>
                  <NewDocumentForm
                    type={type}
                    doc={doc}
                    documents={documents}
                    setDocuments={setDocuments}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    docUpdated={docUpdated}
                    setDocUpdated={setDocUpdated}
                  />
                </TabPane>
              );
            })}
            <TabPane
            //eventKey={documents?.length + addToEventKey}
            >
              {/* <NewDocumentForm /> */}
              <></>
            </TabPane>
          </TabContent>
        </TabsWrap>
      </Tab.Container>
    </>
  );
};

export const TabPane = styled(Tab.Pane)`
  ${scrollbarStyles};
`;

export const TabContent = styled(Tab.Content)`
  ${scrollbarStyles};
  //margin-top: 1rem;
  height: ${window.innerHeight - 200}px;
  width: -webkit-fill-available;
  overflow: auto;
  border-left: 1px solid #dddddd;
  //border-top: 1px solid #dddddd;
`;
