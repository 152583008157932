import React from "react";
import { Route, Switch } from "react-router-dom";
import DailyMileageTable from "./DailyMileageTable";
import DailyMileageForm from "./DailyMileageForm";

export default () => (
  <Switch>
    <Route exact path="/dailymileage" component={DailyMileageTable} />
    <Route exact path="/dailymileage/addnew" component={DailyMileageForm} />
  </Switch>
);
