import React, { useState } from "react";
import {
  colorBlue,
  colorBlueHover,
  colorGray,
  colorGrayNew,
  colorLightGray,
} from "../../../../utils/palette";
import Tooltip from "@/shared/components/Tooltip";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "@/shared/img/search.svg";
import { NATEXT } from "../../../constants";
import { useSelector } from "react-redux";

const url = "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";

const VehicleComponent = ({
  vehicleName,
  groupName,
  vehicleId,
  vehicleImg,
  orgId,
}) => {
  const allVehicles = useSelector((state) => state.vehicles.list);
  const currentVehicle = allVehicles.find((v) => v.veh_id === vehicleId);
  const vehicleInactive =
    (currentVehicle?.veh_active === 0 &&
      currentVehicle?.secondary_status === 0) ||
    (currentVehicle?.veh_active === 2 &&
      currentVehicle?.secondary_status === 0);

  const [showIcon, setShowIcon] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const imageUrl =
    vehicleImg && vehicleImg !== NATEXT
      ? `${url}/org_${orgId}/vehicle_${vehicleId}/${vehicleImg}`
      : defaultVehicleIcon;

  const handleVehicleNameHover = (event) => {
    let e = event.target;
    setShowTooltip(e.scrollWidth > e.clientWidth);
    //console.log(e.clientWidth, e.scrollWidth);
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      {/* <VehicleImgContainer
        hovered={showIcon}
        id="vehicle-img"
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
        //onClick={() => setShowIcon(false)}
      > */}
      <VehicleImg
        //hovered={showIcon}
        zoom
        src={imageUrl}
        alt="vehicle-img"
        // style={{ width: "40px", height: "40px" }}
        className="rounded-circle"
      />
      {/* {showIcon && (
          <ZoomInIconContainer>
            <SearchIcon width={22} height={14} />
          </ZoomInIconContainer>
        )} */}
      {/* </VehicleImgContainer> */}
      {showTooltip ? (
        <Tooltip text={vehicleName}>
          <VehicleName
            onMouseOver={(e) => handleVehicleNameHover(e)}
            inactive={vehicleInactive}
          >
            {vehicleName}
            {/* </p> */}
            {/* <p>{groupName === "N/A" ? "" : groupName}</p> */}
          </VehicleName>
        </Tooltip>
      ) : (
        <VehicleName
          onMouseOver={(e) => handleVehicleNameHover(e)}
          inactive={vehicleInactive}
        >
          {vehicleName}
          {/* </p> */}
          {/* <p>{groupName === "N/A" ? "" : groupName}</p> */}
        </VehicleName>
      )}
    </div>
  );
};

export default VehicleComponent;

export const ZoomInIconContainer = styled.span`
  position: absolute;
  //padding: 4px;
  //top: -1px;
  //right: 4px;
  z-index: 0;
  svg {
    fill: white;
  }
`;

export const VehicleImg = styled.img`
  //padding: 2px;
  width: 20px;
  height: 20px;
  z-index: 0;
  border: 1px solid ${colorBlueHover};
  object-fit: cover;

  ${(p) =>
    p.zoom &&
    `&:hover {
    opacity: 0.3;
    cursor: zoom-in;
  }

  &:active {
    opacity: 1;
    -webkit-transform: scale(8) translate(10%, 15%);
    //transform: scale(8) translate(25%, 30%);
    overflow: scroll;
    cursor: zoom-out;
    //position: relative;
    z-index: 9999;
    //border: 1px solid ${colorBlueHover};
  }`};

  //Use this only if using VehicleImgContainer
  ${(p) =>
    p.hovered &&
    `opacity: 0.4;
    cursor: zoom-in;
  `};
`;

export const VehicleImgContainer = styled.span`
  border: 1px solid ${colorBlueHover};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  //${(p) => p.hovered && `border-color: rgba(0,0,0, 0.6)`};
  ${(p) => p.hovered && `cursor: zoom-in;`};

  &:active {
    ${VehicleImg} {
      opacity: 1;
      transform: scale(8) translate(25%, 25%);
      overflow: hidden;
      position: absolute;
      z-index: 9999;
      border: 1px solid ${colorBlueHover};
    }
  }
`;

export const VehicleName = styled.p`
  max-width: 250px;
  //fontWeight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) => p.inactive && `color: #C5C5C5`}
`;
