import styled from "styled-components";
import {
  colorBackground,
  colorBlue,
  colorBlueHover,
  colorGray,
  colorHover,
  colorLightGray,
  colorTextNew,
  scrollbarStyles,
} from "../../../../../utils/palette";
import { Button } from "@/shared/components/Button";
import FormatColumnsIcon from "mdi-react/FormatColumnsIcon";
import { useEffect, useState } from "react";
import { TopbarBack } from "../../../../../containers/Layout/components/topbar/BasicTopbarComponents";
import { marginRight, right, left } from "@/utils/directions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
//import { SaveButton, SaveButtonContainer } from "../Filter/RangeFilter";
import { useDispatch } from "react-redux";
import {
  updateColOrder,
  updateColumns,
} from "../../../../../features/columns/columnsSlice";
import { useSelector } from "react-redux";
import { ReactComponent as ReOrderImage } from "@/shared/img/menu-burger.svg";
import { getScreenName } from "../../../../helpers";
import { ReactComponent as ColumnSelectionIcon } from "@/shared/img/table-columns.svg";
import Tooltip from "@/shared/components/Tooltip";
import { FilterButton } from "../../../TableElements";
import { postApi } from "../../../../../api/getUser";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const ColumnReorder = ({
  allColumns,
  setColumnOrder,
  setHiddenColumns,
  screen,
}) => {
  const { t } = useTranslation("common");
  const [toggleOptions, setToggleOptions] = useState(false);
  const [columns, setColumns] = useState([]);

  const { setLoading } = useAuth();

  useEffect(() => {
    let columns = [];
    if (screen.includes("users") || screen.includes("vehicles")) {
      columns = allColumns
        .slice(1, allColumns.length)
        .filter((col) => typeof col.id === "number");
    } else {
      columns = allColumns
        .slice(2, allColumns.length)
        .filter((col) => typeof col.id === "number");
    }
    setColumns(columns);
  }, [allColumns, toggleOptions]);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    setToggleOptions(!toggleOptions);
  };

  function handleCheckboxChange(id) {
    //const { name, checked } = e.target;
    // console.log(e);
    // const filtered = columns.filter(item => item.id !== column.id);
    // const currentColumn = columns.find(item => item.id === column.id);
    // currentColumn.isVisible = !currentColumn.isVisible;
    // filtered.push(currentColumn);
    setColumns((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          //console.log({...item, isVisible: !item.isVisible});
          return { ...item, isVisible: !item.isVisible };
        }
        return item;
      });
    });
  }

  const handleApply = (e) => {
    if (screen.includes("users") || screen.includes("vehicles")) {
      console.log(columns);
      let visibleCols = [1];
      for (let column of columns) {
        if (column.isVisible) {
          visibleCols.push(column.id);
        }
      }
      setColumnOrder(visibleCols);
      let columnsToBeFiltered = [...Array(allColumns.length).keys()]
        .map((val) => +val + 1)
        .slice(1);
      let hiddenCols = columnsToBeFiltered.filter(
        (no) => !visibleCols.includes(no)
      );
      setHiddenColumns(hiddenCols);

      (async () => {
        const apiData = {
          querystring: "insert_col_order_v2",
          col_order: JSON.stringify([0, ...visibleCols]),
          screen_name: screen.includes("vehicles")
            ? "vehicle"
            : getScreenName(screen),
        };
        const res = await postApi(apiData, "commonNew");
        if (res?.success) {
          dispatch(updateColOrder({ colOrder: [...visibleCols] }));
        }
      })();
    } else {
      // e.preventDefault();
      // let temp = [0];
      // console.log(columns);
      // for (let column of columns) {
      //   if (column.isVisible) {
      //     temp.push(column.id - 1);
      //   }
      // }
      // //console.log("temp", temp);
      // const data = {
      //   screenName: getScreenName(screen),
      //   columnNames: temp,
      //   defaultRows: pageSize,
      // };
      // dispatch(updateColumns(data));
      (async () => {
        //setLoading(true);

        // if (screen.includes("fillups")) {
        let order = [0];
        for (let column of columns) {
          if (column.isVisible) {
            order.push(column.id - 1);
          }
        }

        const apiData = {
          querystring: "insert_col_order_v2",
          col_order: JSON.stringify(order),
          screen_name: getScreenName(screen),
        };
        const res = await postApi(apiData, "commonNew");
        console.log(res);
        if (res.success) {
          let orderForUI = order.map((o) => o + 1);
          dispatch(updateColOrder({ colOrder: orderForUI }));
          setToggleOptions(false);
        }
      })();
    }
    setToggleOptions(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const data = reorder(
      columns,
      result.source.index,
      result.destination.index
    );

    setColumns(data);
  }

  return (
    <div style={{ width: 0 }}>
      <Tooltip text={t("manage_cols")}>
        <ColumnReOrderButtonContainer
          id="column-reorder-button"
          //variant="outline-secondary"
          size="sm"
          onClick={handleClick}
        >
          {/* <FormatColumnsIcon /> */}
          <ColumnSelectionIcon fill="#35353B" />
        </ColumnReOrderButtonContainer>
      </Tooltip>
      {toggleOptions && (
        <TopbarBack
          type="button"
          aria-label="profile button"
          onClick={() => setToggleOptions(!toggleOptions)}
        />
      )}

      {toggleOptions && (
        <ColumnOrderContainer>
          <div style={{ height: "auto", background: "white" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="draggable" type="data">
                {(provided) => (
                  <ColumnList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {/* <ColumnList> */}
                    {columns.map((column, index) => {
                      return (
                        <Draggable
                          draggableId={`${column.id}`}
                          index={index}
                          key={column.id}
                          type="data"
                          isDragDisabled={column.dragDisabled}
                        >
                          {(provided) => (
                            <Column
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div>
                                <Label>
                                  <CheckboxInput
                                    name="isVisible"
                                    type="checkbox"
                                    disabled={column?.disableCol}
                                    checked={column.isVisible}
                                    onChange={() =>
                                      handleCheckboxChange(column.id)
                                    }
                                  />{" "}
                                  {column.Header}
                                </Label>
                              </div>
                              <div>
                                <ReOrderImage
                                  fill={colorGray}
                                  width={13}
                                  height={13}
                                />
                              </div>
                            </Column>
                          )}
                        </Draggable>
                      );
                    })}
                    {/* </ColumnList> */}
                    {provided.placeholder}
                  </ColumnList>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <ApplyButtonContainer>
            <FilterButton type="button" onClick={handleApply}>
              {t("apply")}
            </FilterButton>
          </ApplyButtonContainer>
        </ColumnOrderContainer>
      )}
    </div>
  );
};

export default ColumnReorder;

// styles

export const ColumnOrderContainer = styled.div`
  position: absolute;
  //left: 840px;
  right: 94px;
  //height: 400px;
  //left: -10px;
  //top: 135px;
  width: 180px;
  z-index: 9999;
  background: ${colorBackground};
  //border: 1px solid ${colorLightGray};
`;

export const Column = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 60px;

  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  padding: 8px 8px 4px 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: ${colorBackground};
  color: ${colorTextNew};

  &:hover {
    color: ${colorBlue};
  }

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    //${left}: 0 !important;
  }
`;
export const ColumnList = styled.div`
  border: 1px solid ${colorLightGray};
  //padding: 5px;
  border-radius: 5px;
  width: 200px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;

  height: 300px;
  overflow: auto;
  ${scrollbarStyles};

  //z-index: 100;
  position: absolute;
  //${right}: 70px;

  @media screen and (max-width: 320px) {
    //${right}: -50px;
  }
`;

export const Label = styled.label`
  color: ${colorTextNew};
  padding: 3px 5px;
  //cursor: pointer;

  &:hover {
    color: ${colorBlueHover};
  }
`;

export const CheckboxInput = styled.input`
  margin-top: -4px;
  margin-right: 6px;
  padding: 5px;
  vertical-align: middle;
  height: 100%;

  // &:hover {
  //   outline:none;
  //   border-color: ${colorBlueHover};
  // }
`;

export const ColumnReOrderButtonContainer = styled.button`
  padding: 4px 8px !important;
  width: 32px;
  height: 32px;
  //margin-right: 15px;
  //margin-left: 15px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: white;

  &:hover {
    background: ${colorLightGray};
  }

  @media screen and (max-width: 480px) {
    ${marginRight}: 0;
  }
`;

export const ApplyButtonContainer = styled.div`
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  //z-index: 101;
  top: 300px;
  background: ${colorBackground};
  width: 200px;
  //padding-right: 10px;
  //padding-bottom: 10px;
  padding: 10px 0;
`;

export const ApplyButton = styled(Button)`
  height: 30px;
  width: 55px;
`;
