import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert as AlertBootstrap } from "react-bootstrap";
import styled from "styled-components";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import CommentAlertOutlineIcon from "mdi-react/CommentAlertOutlineIcon";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import RoadIcon from "mdi-react/RoadIcon";
import GaugeIcon from "mdi-react/GaugeIcon";
import BellIcon from "mdi-react/BellIcon";
import { Button } from "@/shared/components/Button";
import { lighten } from "polished";
import {
  colorFieldsBorder,
  colorAdditional,
  colorBlue,
  colorAccent,
  colorYellow,
  colorRed,
  colorWhite,
  colorLightBlue,
  colorLightAccent,
  colorLightYellow,
  colorLightRed,
  colorText,
  colorLightGray,
} from "@/utils/palette";
import {
  right,
  left,
  paddingRight,
  paddingLeft,
  borderRight,
} from "@/utils/directions";
import { buttonColorNew, sidenavColor } from "../../utils/palette";

const Alert = ({
  color,
  icon,
  children,
  bordered,
  colored,
  neutral,
  closable,
}) => {
  const [visible, setVisible] = useState(true);

  const onShow = () => {
    setVisible(true);
  };

  const onDismiss = () => {
    setVisible(false);
  };

  let Icon;

  switch (icon) {
    case "currency":
      Icon = <CurrencyUsdIcon />;
      break;
    case "gasStation":
      Icon = <GasStationIcon />;
      break;
    case "gauge":
      Icon = <GaugeIcon />;
      break;
    case "road":
      Icon = <RoadIcon />;
      break;
    case "info":
      //Icon = <InformationOutlineIcon />;
      Icon = <BellIcon />;
      break;
    case "success":
      Icon = <ThumbUpOutlineIcon />;
      break;
    case "warning":
      Icon = <CommentAlertOutlineIcon />;
      break;
    case "danger":
      Icon = <CloseCircleOutlineIcon />;
      break;
    default:
      Icon = <InformationOutlineIcon />;
      break;
  }

  if (visible) {
    return (
      <StyledAlert
        variant={color}
        $bordered={bordered}
        $colored={colored}
        $neutral={neutral}
      >
        {/* {icon && <AlertIcon>{Icon}</AlertIcon>} */}
        {closable && (
          <AlertCloseButton type="button" onClick={onDismiss}>
            <span className="lnr lnr-cross" />
          </AlertCloseButton>
        )}
        <AlertContent>{children}</AlertContent>
      </StyledAlert>
    );
  }

  return (
    <Button variant="secondary" onClick={onShow}>
      Show Alert
    </Button>
  );
};

Alert.propTypes = {
  color: PropTypes.string, // 'info', 'success', 'warning', 'danger'
  icon: PropTypes.string,
  bordered: PropTypes.bool,
  colored: PropTypes.bool,
  neutral: PropTypes.bool,
  closable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  color: "info",
  icon: "currency",
  bordered: false,
  colored: false,
  neutral: false,
  closable: false,
};

export default Alert;

// region STYLES

const AlertCloseButton = styled.button`
  color: ${colorWhite};
  opacity: 1;
  font-weight: 100;
  ${right}: 20px;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  top: calc(50% - 7px);
  text-shadow: none;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }
`;

const AlertIcon = styled.div`
  padding: 10px;
  display: flex;

  // background: ${lighten(0.22, colorLightBlue)};
  // fill: ${colorWhite};
  svg {
    box-sizing: content-box;
    padding: 3px;
    //background: #ffeec4;
    background: white;
    border-radius: 5px;
    fill: ${colorWhite};
    margin: auto 0;
    width: 32px;
    height: 32px;
  }
`;

const AlertContent = styled.div`
  text-align: ${left};
  padding-left: 10px;
  color: black;

  p {
    max-height: 20px;
    box-sizing: content-box;
    font-weight: 500;
    font-size: 16px;
    color: black;
  }

  span {
    box-sizing: content-box;
    max-height: 15px;
    font-weight: 300;
    font-size: 12px;
    color: black;
  }
`;

const getMainColor = (color) => {
  switch (color) {
    case "info":
      return colorBlue;
    case "success":
      return colorAccent;
    case "warning":
      return colorYellow;
    case "danger":
      return colorRed;
    default:
      return colorBlue;
  }
};

const getLightColor = (color) => {
  switch (color) {
    case "info":
      return colorLightBlue;
    case "success":
      return colorLightAccent;
    case "warning":
      return colorLightYellow;
    case "danger":
      return colorLightRed;
    default:
      return colorLightBlue;
  }
};

const StyledAlert = styled(AlertBootstrap)`
  //box-shadow: 1px 1px ${colorLightGray};
  //width: 180px;
  border-radius: 5;
  //border-width: 2px;
  position: relative;
  //margin-top: 15px;
  //margin-bottom: 15px;
  padding: 0;
  margin-bottom: 4px;
  min-width: 150px;
  max-width: 300px;
  //margin-bottom: 7px;
  display: flex;
  background-color: ${(props) =>
    props.$bordered || props.$neutral
      ? "transparent"
      : getMainColor(props.variant)};
  border-color: ${buttonColorNew}; //${(props) =>
    props.$neutral ? colorFieldsBorder : getMainColor(props.variant)};
  border: none;

  p {
    color: ${(props) =>
      props.$bordered || props.$neutral ? colorText : colorWhite};
    margin-top: 0;
  }

  ${(props) =>
    props.$colored &&
    `
    ${AlertIcon} {
      background-color: ${getLightColor(props.variant)};
    }
  `}

  ${(props) =>
    props.$bordered &&
    `
    ${AlertCloseButton} {
      color: ${colorAdditional};
    }

    ${AlertIcon} {
      background-color: ${getMainColor(props.variant)};
    }
  `}

  ${(props) =>
    props.$neutral &&
    `
    ${AlertCloseButton} {
      color: ${colorAdditional};
    }

    // fill: ${getMainColor(props.variant)};
    svg {
      fill: #ffb703;
    }

    ${AlertIcon} {
      ${borderRight}: 1px solid #f2f4f7;
    }
  `}
`;

// endregion
