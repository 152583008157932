import React from "react";
import { useTranslation } from "react-i18next";
import { colorGreen, colorGreenHover } from "../../utils/palette";
import styled from "styled-components";
import Tooltip from "./Tooltip";

const SubscribeTooltipIcon = ({ message, handleClick }) => {
  const { t } = useTranslation("common");

  const handleIconClick = () => {
    if (handleClick) {
      handleClick();
    }
    return;
  };
  return (
    <Tooltip text={message ? message : t("subscribe_msg")}>
      <DollarIcon
        className="fi fi-ss-usd-circle"
        onClick={handleIconClick}
      ></DollarIcon>
    </Tooltip>
  );
};

export default SubscribeTooltipIcon;

//styles
export const DollarIcon = styled.i`
  cursor: pointer;
  color: ${colorGreenHover};
  position: relative;
  font-size: 16px;
  top: 3px;
  margin-left: 4px;
  &:hover {
    color: ${colorGreen};
  }
`;
