import styled from "styled-components";
import { Table as BootstrapTable, Container, Row } from "react-bootstrap";
import {
  colorBackground,
  colorBackgroundBody,
  colorBlue,
  colorBorder,
  colorHeaderDataPicker,
  colorHover,
  colorText,
  colorTextAdditional,
} from "@/utils/palette";
import { right, left } from "@/utils/directions";
import {
  buttonColorNew,
  colorGray,
  colorGrayNew,
  colorGreen,
  colorGreenHover,
  colorLightGray,
  colorRed,
  colorRedHover,
  colorRedNew,
  colorTextNew,
  colorYellow,
  colorYellowNew,
  disabledBgColor,
  scrollbarStyles,
  sidenavLinkHoverColor,
} from "../../utils/palette";

export const Table = styled(BootstrapTable)`
  text-align: ${left};

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: inherit;
  }

  thead tr {
    position: sticky;
    top: 0;
    border-top: none;
    z-index: 1;
  }

  thead th {
    white-space: nowrap;
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    top: 0;
    position: sticky;
    color: ${colorText};
    border-bottom: 2px solid ${colorBorder};
    border-bottom-color: ${colorBorder} !important;
    background: ${colorBackground} !important;

    &:first-child {
      min-width: 45px;
    }
  }

  tbody td,
  tfoot td,
  th {
    border: none;
    padding: 10px 5px;
    color: ${colorTextAdditional};
  }

  th,
  td {
    vertical-align: middle;
    background: transparent !important;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .badge {
    font-size: 100%;
  }

  tfoot {
    tr {
      border-top: 1px solid #eff1f5;
      border-bottom: none;
    }

    td {
      min-height: 45px;
      min-width: 100px;
      padding: 10px 5px 10px 5px;
      font-family: "Inter", sans-serif;
      color: ${colorText};
      font-weight: 700;

      &:first-child {
        min-width: 45px;
        white-space: nowrap;
      }
    }
  }

  ${(props) =>
    props.bordered &&
    `
    text-align: ${left(props)};

    tr {
      border-top: none;
      border-bottom: 1px solid ${colorBorder};
    }
    
    tbody tr:last-child {
      border-bottom: none;
    }
  `}

  ${(props) =>
    props.headAccent &&
    `

    thead {
      color: ${colorTextAdditional(props)};
      background-color: ${colorHover(props)};

      th {
        border-bottom: none;
      }
    }
  `}

  ${(props) =>
    props.striped &&
    `

    tbody tr:nth-of-type(odd) {
      --bs-table-accent-bg: ${colorHover(props)};
      background-color: ${colorHover(props)};
    }
  `}

  &.table-hover {
    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      // &:hover {
      //   background-color: ${colorBackgroundBody};
      // }
    }
  }

  &.p-left {
    thead tr th {
      padding-left: 2rem;
    }
    tbody tr td {
      padding-left: 2rem;
    }
  }

  &.react-table {
    thead tr {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .react-table__column-header {
    color: ${colorTextNew};
    display: flex;
    //flex-wrap: wrap;
    align-content: center;
    //justify-content: start;

    &:first-child {
      margin-right: 5px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 18px;
        height: 20px;
        //${right}: -20px;
      }
    }
  }

  &.resizable-table {
    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px !important;

      &:first-child {
        max-width: 60px;
        min-width: 60px !important;
      }

      .resizer {
        top: 7px;
        right: 10px;
        width: 2px;
        height: 80%;
        position: absolute;
        touch-action: none;
        background: ${colorBorder};

        &.isResizing {
          background: ${colorHeaderDataPicker};
        }
      }
    }
  }

  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  &.editable-table {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -ms-user-select: none; /* IE10+ */

    thead {
      //min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 60px;
        min-width: 60px;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid ${colorBlue};
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      font-size: 13px;
      font-family: "Roboto", sans-serif;
      padding-left: 0;
      background-color: ${colorBackground};
      color: ${colorTextAdditional};

      &:active {
        border: none;
        outline: 1px solid ${colorBlue};
      }
    }

    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
  }
`;

export const AddFormButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: white;
  height: 32px;
  background: ${buttonColorNew};
  border: 1px solid ${buttonColorNew};
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
  min-width: 100px;
  //max-width: 300px;
  white-space: nowrap;
  font-weight: 500;
  &:hover {
    ${(p) => !p.disabled && `background: ${sidenavLinkHoverColor}`};
  }

  ${(p) =>
    p.disabled &&
    `background: ${colorGray};
    border-color: ${colorGray};
    //background: #f2f2f2;
    //border-color: #f2f2f2;
    //color: black;
  `};

  ${(p) =>
    p.isLoading &&
    `div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #ffffff);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
    animation: s3 1s infinite linear;

    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
`}
`;

export const FilterButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
  font-size: 14px;
  color: ${colorTextNew};
  height: 32px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
  min-width: 100px;
  white-space: nowrap;
  background: white;
  font-weight: 500;

  svg {
    margin-right: 5px;
    margin-bottom: 1px;
  }

  i {
    margin: 5px 0 0 24px;
  }

  &:hover {
    ${(p) => !p.disabled && `background: ${colorLightGray}`};
  }

  ${(p) => p.isDropdownOpen && `background: ${colorLightGray}`}

  ${(p) =>
    p.disabled &&
    `background: ${disabledBgColor};
    border-color: ${colorLightGray};
  `};
`;

export const YellowButton = styled.button`
  background: ${colorYellowNew};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  //color: white;
  height: 32px;
  border: 1px solid ${colorYellowNew};
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
  min-width: 100px;
  white-space: nowrap;
  font-weight: 500;
  &:hover {
    ${(p) => !p.disabled && `background: ${colorYellow}`};
  }

  ${(p) =>
    p.disabled &&
    `background: ${colorGray};
    border-color: ${colorGray};
  `};

  ${(p) =>
    p.isLoading &&
    `div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #ffffff);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
    animation: s3 1s infinite linear;

    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
`}
`;

export const RedButton = styled.button`
  background: ${colorRedNew};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: white;
  height: 32px;
  border: 1px solid ${colorRedNew};
  border-radius: 5px;
  padding: 0 10px;
  margin: 0;
  min-width: 100px;
  white-space: nowrap;
  font-weight: 500;
  &:hover {
    ${(p) => !p.disabled && `background: ${colorRedHover}`};
  }

  ${(p) =>
    p.disabled &&
    `background: ${colorGray};
    border-color: ${colorGray};
  `};

  ${(p) =>
    p.isLoading &&
    `div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #ffffff);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
    animation: s3 1s infinite linear;

    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
`}
`;

export const ColumnHeading = styled.h4`
  font-weight: 500;
  font-size: 18px;
  //border-bottom: 2px solid ${colorYellow};
`;

export const Icon = styled.i`
  position: relative;
  top: 2px;
  color: ${colorGray};
  margin-right: 4px;
`;

export const ActionIcon = styled.i`
  position: relative;
  font-size: 16px;
  //width: 24px;
  //height: 24px;
  top: 2px;
  color: ${colorGray};
  //margin-right: 16px;
  cursor: pointer;
  //padding: 0 4px;

  &:hover {
    color: ${colorLightGray};
    //background: ${colorLightGray}
  }
`;

export const ActionIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  i {
    margin: 0;
    position: absolute !important;
    width: 14px;
    height: 14px;
    font-size: 14px;
    top: 3px;
    color: ${colorGray};
  }

  &:hover {
    //color: ${colorLightGray};
    background: ${colorLightGray};
  }
`;

export const RowOptionsButtonContainer = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
  height: 32px;
  width: 32px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  //padding: 0 10px;
  //margin: 0;
  white-space: nowrap;
  background: white;

  i {
    color: ${colorGray};
    position: relative;
    top: 1px;
  }

  &:hover {
    background: ${colorLightGray};
  }
`;

export const ImageWithZoomContainer = styled.div`
  position: relative;
  width: 120px;
  height: 80px;

  &:hover {
    cursor: zoom-in;
  }

  i {
    display: none;
    color: white;
    position: absolute;
    top: 40%;
    right: 45%;
    z-index: 10;
    //${(p) => p.isHovered && `display: inline`}
  }

  img {
    ${(p) => p.isHovered && `opacity: 0.5`}
  }
`;

export const CustomLoader = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(gray 10%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
  animation: s3 1s infinite linear;

  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 6px;
  font-size: 12px;
  height: 24px;
  //margin-bottom: 18px;
  background: ${(p) => p.background && p.background};
  color: white;
  border-radius: 12px;

  // p {
  //   color: white;
  // }
`;

export const ColumnHeaderRow = styled(Row)`
  //background: ${colorLightGray};
  border-bottom: 1px solid ${colorBorder};
  padding: 0.5rem;
  font-weight: 600;
`;

export const TableContainer = styled(Container)`
  border: 1px solid ${colorBorder};
`;

export const DataRow = styled(Row)`
  border-bottom: 1px solid ${colorBorder};
  &:last-child {
    border: none;
  }
  padding: 0.5rem;
  //margin: 0;
`;

export const ScrollableTableDiv = styled.div`
  ${scrollbarStyles}
  //height: ${window.innerHeight - 400}px;
  height: 60vh;
  overflow: auto;
`;

export const TableHeadSticky = styled.thead`
  //background: ${colorLightGray};
  position: sticky;
  z-index: 100;
  top: -2px;
`;

export const KPIValue = styled.span`
  font-size: 16px;
  font-weight: 500;
  display: block;
`;

export const ColHeader = styled.span`
  font-weight: 300;
  font-size: 12px;
`;

export const RowValue = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

export const ScrollableRow = styled(Row)`
  overflow: auto;
  ${(p) => p.height && `height: ${p.height}`};
  ${scrollbarStyles}
`;
