import React from "react";
import { colorBlueHover } from "../../../utils/palette";
import styled from "styled-components";

const CheckboxInputComponent = ({
  label,
  checked,
  setChecked,
  id,
  onChange,
  disabled,
  name,
}) => {
  const handleCheckboxChange = (e) => {
    if (onChange) {
      onChange(e);
    } else {
      setChecked((prev) => !prev);
    }
  };
  return (
    <Label
      htmlFor={id}
      className="d-flex align-items-center"
      disabled={disabled}
    >
      <Checkbox
        type="checkbox"
        id={id}
        name={name ? name : id}
        //value={id}
        checked={checked}
        onChange={handleCheckboxChange}
        style={{ margin: "0 8px 0 0" }}
        disabled={disabled}
      />
      {label}
    </Label>
  );
};

export default CheckboxInputComponent;

//styles

export const Checkbox = styled.input`
  //position: relative;
  //top: 2px;
  width: auto !important;
  //margin: 0 8px;
`;

export const Label = styled.label`
  //margin-left: 4px;
  margin: 0;

  ${(p) =>
    !p.disabled &&
    `
  
  &:hover {
    color: ${colorBlueHover};
  }
  `}
`;
