import React, { useEffect, useState } from "react";
import { post } from "../../api/getUser";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";

const useFetchOdo = ({
  vehicle,
  type,
  setValue,
  odoKey,
  odoReqd,
  lastOdoValue,
  setShowHighOdoErr,
  odoReqdPresent,
}) => {
  const { t } = useTranslation("common");
  const { fetchTelematicsOdo } = useAuth();
  //State to set odo fetched via telematics device msg
  const [odoFetchedMsg, setOdoFetchedMsg] = useState("");
  const [odo, setOdo] = useState(0);

  let odoRequired = true;
  if (odoReqdPresent) {
    odoRequired = odoReqd ? true : false;
  }

  useEffect(() => {
    (async () => {
      if (type !== "edit" && vehicle && odoRequired && fetchTelematicsOdo) {
        if (vehicle.provider_type_1) {
          setOdo(0);
          setValue(odoKey ? odoKey : "odometer", "");
          setOdoFetchedMsg(t("fetching_data_via_telematics"));
          const odoApiData = {
            querystring: "get_current_obd_odometer",
            veh_id: vehicle.veh_id,
            provider_type: 1,
          };
          const res = await post("integrations", odoApiData);
          //console.log(res);
          if (res.success) {
            let odoVal = +res.data.odometer_in_meters;
            // let convertedOdoVal = 0;
            // if (vehicle.primary_meter === "Miles") {
            //   convertedOdoVal = +odoValInMeters * 0.000621;
            // }
            // if (vehicle.primary_meter === "Kilometers") {
            //   convertedOdoVal = +odoValInMeters * 1000;
            // }
            if (+odoVal) {
              odoVal = odoVal?.toFixed(2);
              setOdo(odoVal);
            }
            //setOdoVal(convertedOdoVal);
            setValue(odoKey ? odoKey : "odometer", odoVal);
            setOdoFetchedMsg(t("fetched_via_telematics_device"));
          } else {
            setOdo(0);
            setValue(odoKey ? odoKey : "odometer", "");
            setOdoFetchedMsg("");
            //setOdoFetchedMsg("");
          }
        } else {
          setOdo(0);
          setValue(odoKey ? odoKey : "odometer", "");
          setOdoFetchedMsg("");
        }
      } else {
        setOdo(0);
        //setValue(odoKey ? odoKey : "odometer", "");
        setOdoFetchedMsg("");
      }
    })();
  }, [vehicle]);

  //Effect to set high odo err
  useEffect(() => {
    if (
      +odo &&
      +lastOdoValue &&
      +odo - +lastOdoValue > 1000
      //&&
      //odoRequired
      //&&
      //vehicle &&
      //vehicle.provider_type_1 &&
      //type !== "edit" &&
      //odoRequired
    ) {
      setShowHighOdoErr(true);
    } else {
      setShowHighOdoErr(false);
    }
  }, [lastOdoValue, odo]);

  return { odoFetchedMsg, setOdoFetchedMsg };
};

export default useFetchOdo;
