import { Modal, Row, Col, Container } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  ColumnHeaderRow,
  DataRow,
  TableContainer,
} from "../../TableElements";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  colorBorder,
  colorLightBlue,
  scrollbarStyles,
} from "../../../../utils/palette";
import { useLocation } from "react-router";

//This component is used to display the list of assigned operators/vehicles from the side panel
export const AssignedEntitiesModal = ({
  showPopup,
  setShowPopup,
  heading,
  assignedEntities,
}) => {
  const { t } = useTranslation("common");

  const { pathname } = useLocation();

  const AssignedVehicle = ({ row }) => (
    <div className="d-flex gap-2 align-items-center">
      <img
        src={row.imageUrl}
        width={40}
        height={40}
        className="rounded-circle"
      />
      <span style={{ fontWeight: 400 }}>{row.label}</span>
    </div>
  );

  const AssignedUser = ({ row }) =>
    row?.imageUrl ? (
      <AssignedVehicle row={row} />
    ) : (
      <div className="d-flex gap-2 align-items-center">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: 40,
            height: 40,
            border: `1px solid ${colorLightBlue}`,
            background: "gray",
            color: "white",

            textAlign: "center",
          }}
        >
          <span style={{ fontSize: "12px" }}>{row.userInitials}</span>
        </div>
        <span style={{ fontWeight: 400 }}>{row.label}</span>
      </div>
    );

  const handleClose = () => {
    setShowPopup(false);
  };
  return (
    <Modal show={showPopup} onHide={() => setShowPopup(false)} size="md">
      <Modal.Header>
        <h4>{heading}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ListContainer>
          {assignedEntities.map((row) => (
            <DataRow key={row.rowId}>
              <Col>
                {pathname.includes("vehicles") ? (
                  <AssignedUser row={row} />
                ) : (
                  <AssignedVehicle row={row} />
                )}
              </Col>
            </DataRow>
          ))}
        </ListContainer>
      </Modal.Body>

      <Modal.Footer>
        <AddFormButton id="ok-button" type="button" onClick={handleClose}>
          {t("ok")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignedEntitiesModal;

//styles
export const ListContainer = styled(Container)`
  overflow: auto;
  height: ${window.innerHeight - 250}px;
  ${scrollbarStyles}
  border: 1px solid ${colorBorder};
`;
