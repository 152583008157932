import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import {
  FormContainer,
  FormGroupLabel,
  FormHeading,
} from "@/shared/components/form/FormElements";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CheckBoxInput,
  FormItem,
  Label,
} from "../../../shared/components/form/FormElements";
import {
  buttonColorNew,
  colorAdditional,
  colorGray,
  colorLightBlue,
  colorLightGray,
  colorTextNew,
  scrollbarStyles,
} from "../../../utils/palette";
import { useEffect } from "react";

import Error from "@/shared/components/form/Error";
import Tooltip from "@/shared/components/Tooltip";
import { useHistory, useLocation } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import { postApi } from "../../../api/getUser";

import { AttrComponent } from "../InspectionTasks/InspectionTasksTable";
import { createFilter } from "react-select/dist/react-select.cjs.prod";
import FormHeader from "../../../shared/components/form/FormHeader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import InspectionTasksFormPopup from "../InspectionTasks/InspectionTasksFormPopup";
import { ActionIconContainer } from "../../../shared/components/TableElements";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import { updateInspectionTasks } from "../../../features/inspectionTasks/inspectionTasksSlice";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const InspectionFormsForm = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const { userId, orgId } = useSelector((state) => state.user);

  //React hook form fields
  const inspectionForm = watch("inspectionForm");
  const description = watch("description");
  const inspectionTasks = watch("inspectionTasks");

  const [signReqd, setSignReqd] = useState(0);
  const [odoReqd, setOdoReqd] = useState(1);

  const [inspectionTasksError, setInspectionTasksError] = useState(false);
  const location = useLocation();

  const { tag } = useAuth();
  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const allInspectionTasks = useSelector((state) => state.inspectionTasks.list);
  const [inspectionTasksOptions, setInspectionTasksOptions] = useState([]);

  //States for Create new inspection task
  //const newTaskInfo = useRef("");
  const [newTaskInfo, setNewTaskinfo] = useState("");
  const [newTaskId, setNewTaskId] = useState("");
  const [showAddTaskFormPopup, setShowAddTaskFormPopup] = useState(false);

  useEffect(() => {
    if (inspectionTasks?.length > 0) {
      setInspectionTasksError(false);
    }
  }, [inspectionTasks]);

  useEffect(() => {
    if (allInspectionTasks.length > 0) {
      let temp = allInspectionTasks.filter(
        (task) => task.task_id !== `${orgId}_odometer`
      );
      setInspectionTasksOptions(temp);
    }
  }, [allInspectionTasks]);

  //Effect to set Inspection Task that is newly created
  // useEffect(() => {
  //   console.log("newTaskId", newTaskId);
  //   if (newTaskId) {
  //     (async () => {
  //       const inspectionTasksRes = await getInspectionTaskOptions(orgId);
  //       setInspectionTasksOptions(inspectionTasksRes);
  //     })();
  //   }
  // }, [newTaskId]);

  // useEffect(() => {
  //   if (newTaskId) {
  //     let newInspectionTask = expenseTaskOptions.find(
  //       (t) => t.expense_task_id === newTaskId
  //     );

  //     if (newInspectionTask) {
  //       setValue("renewalTask", newInspectionTask);
  //       setNewTaskId("");
  //     }
  //   }
  // }, [expenseTaskOptions]);

  useEffect(() => {
    if (type === "edit" && inspectionTasksOptions.length > 0) {
      (async () => {
        const res = await postApi(
          {
            form_id: rowData.form_id,
            querystring: "getsingleinspectionform",
          },
          "commonNew"
        );
        if (res.success) {
          const singleFormData = res.user_data[0];
          setValue("inspectionForm", singleFormData?.form_name);
          setValue("description", singleFormData?.description);
          setSignReqd(singleFormData?.sign_required);
          setOdoReqd(singleFormData?.odo_required);

          const taskIds = singleFormData?.task_ids;

          if (taskIds) {
            const taskIdsArr = taskIds.split(":::");
            let selectedTasks = [];
            //console.log(inspectionTasksOptions);
            if (inspectionTasksOptions.length > 0) {
              // const selectedTasks = inspectionTasksOptions.filter((task) =>
              //   taskIdsArr.includes(task.task_id)
              // );
              const inspectionTaskOptionsIdsArr = inspectionTasksOptions.map(
                (t) => t.task_id
              );
              for (let taskId of taskIdsArr) {
                if (inspectionTaskOptionsIdsArr.includes(taskId)) {
                  const selected = inspectionTasksOptions.find(
                    (t) => t.task_id === taskId
                  );
                  selectedTasks.push(selected);
                }
              }
              //console.log(selectedTasks);
              setValue("inspectionTasks", selectedTasks);
            }
          }
        }
      })();
    }

    //Update Inspection Tasks if new Inspection Task is created
    if (newTaskId) {
      const newInspectionTask = inspectionTasksOptions.find(
        (t) => t.task_id === newTaskId
      );
      console.log(newTaskId, newInspectionTask);
      if (newInspectionTask) {
        setValue("inspectionTasks", [...inspectionTasks, newInspectionTask]);
        setNewTaskId("");
      }
    }
  }, [inspectionTasksOptions]);

  const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const data = reorder(
      inspectionTasks,
      result.source.index,
      result.destination.index
    );

    setValue("inspectionTasks", data);
  }

  const inputStyles = {
    //borderColor: colorLightGray,
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    //width: '200px'
  };

  const selectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      //minWidth: "250px",
      padding: "0px",
      margin: "0px",
      cursor: "text",
      //height: "32px",
      //marginLeft: "0",
      //marginRight: "15px",
      //marginTop: "10px",
      //borderRadius: "5px",
      //cursor: "pointer",
      color: colorTextNew,
      borderColor: state.isFocused ? colorLightBlue : colorLightGray,
      //minHeight: state.isFocused ? "35px" : "35px",
      minHeight: "32px",
      boxShadow: state.isFocused ? null : null,
    }),
    option: (baseStyles, state) => {
      //console.log(state);
      return {
        ...baseStyles,
        color: state.isFocused ? colorTextNew : colorTextNew,
        backgroundColor: state.isSelected ? "white" : "white",
        backgroundColor: state.isFocused ? "#faf9f6" : "white",
        // borderBottom: state.isSelected
        //   ? `1px solid ${colorGray}`
        //   : baseStyles.border,
      };
    },
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: colorTextNew,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      //color: "#636c72",
      padding: "5px 2px",
      margin: 1,
      color: colorAdditional,
      fontSize: "13px",
      //lineHeight: '32px',
      //minHeight: '32px'
      //padding: "7px 0",
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      //height: "fit-content",
      //zIndex: 2,
      //padding: "7px 0",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      //height: "32px",
      //padding: "0 7px",
      padding: "0 6px",
      margin: 0,
      //overflowY: 'auto'
    }),

    input: (provided, state) => ({
      ...provided,
      padding: "0 2px",
      margin: 0,
      //paddingBottom: "5px",
      //lineHeight: '32px',
      //height: "32px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
    // multiValueRemove: (provided, state) => ({
    //   ...provided,
    //   display: 'none'
    // }),
    // multiValueLabel: (provided, state) => ({
    //   ...provided,
    //   margin: 0,
    //   padding: 0,
    //   //margin: 0,
    //   //paddingRight: '5px',
    //   display: 'none',
    // }),
    multiValueContainer: (provided, state) => ({
      ...provided,
      //margin: 0,
      //paddingRight: '5px',
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      margin: 0,
      height: `${window.innerHeight - 500}px`,
      border: `1px solid ${colorLightGray}`,
      boxShadow: "none",
    }),
    menuList: (provided, state) => ({
      ...provided,
      //margin: 0,
      //height: "100%",
      //height: `${window.innerHeight - 400}px`,
      maxHeight: "100%",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };

  const handleRemove = (task) => {
    //console.log(task.task_id);
    const updatedTasks = inspectionTasks.filter(
      (inspectionTask) => inspectionTask.task_id !== task.task_id
    );
    setValue("inspectionTasks", updatedTasks);
  };

  const handleCreate = (value) => {
    setNewTaskinfo({ taskName: value });
    // newTaskInfo.current = { taskName: value };
    setShowAddTaskFormPopup(true);
  };

  const onSubmit = async (data) => {
    try {
      if (inspectionTasks.length === 0) {
        console.log(inspectionTasks);
        setInspectionTasksError(true);
      } else {
        setInspectionTasksError(false);
        setSaveBtnLoading(true);
        const { inspectonForm, inspectionTasks, description } = data;
        let taskIds = "";
        console.log("odo Reqd", odoReqd);
        if (odoReqd) {
          taskIds += `${orgId}_odometer:::`;
        }
        inspectionTasks.forEach((task) => {
          taskIds += `${task.task_id}:::`;
        });
        taskIds = taskIds.slice(0, taskIds.length - 3);

        const inspectionFormId =
          type === "edit" ? rowData.form_id : `${userId}_${Date.now()}`;

        let signRequired = signReqd ? 1 : 0;
        if (tag === "free-user") {
          signRequired = 0;
        }

        const apiData = {
          querystring: "addEditInspectionForm",
          inspection_form_name: inspectionForm ? inspectionForm : "",
          description: description ? description : "",
          operation: type === "edit" ? "update" : "create",
          inspection_form_id: inspectionFormId,
          task_ids: taskIds,
          sign_required: signRequired,
          odo_required: odoReqd ? 1 : 0,
          action: type === "edit" ? 2 : 1,
          source: "web",
          sync_version: "v2",
        };

        const snackbarMsg =
          type === "edit"
            ? t("record_edited_msg", { recordName: t("inspection_form_label") })
            : t("record_added_msg", { recordName: t("inspection_form_label") });

        const res = await postApi(apiData, "inspection");
        if (res.success) {
          reset({
            inspectionTask: "",
            passFail: false,
            info: false,
            photo: false,
            instructions: "",
          });
          dispatch(addMsg(snackbarMsg));
          setSaveBtnLoading(false);
          //history.goBack();
          history.push("/inspection/inspectionforms", { fromCancel: false });
        } else {
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleCancel = () => {
    reset({
      inspectionForm: "",
      passFail: false,
      info: false,
      photo: false,
      instructions: "",
    });
    history.push("/inspection/inspectionforms", { fromCancel: true });
  };
  return (
    <>
      <InspectionTasksFormPopup
        showPopup={showAddTaskFormPopup}
        setShowPopup={setShowAddTaskFormPopup}
        newTaskInfo={newTaskInfo}
        newTaskId={newTaskId}
        setNewTaskId={setNewTaskId}
      />
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer
        horizontal={false}
        onSubmit={handleSubmit(onSubmit)}
        //style={{ width: "100%" }}
      >
        <FormHeader
          title={
            type === "edit"
              ? t("edit_inspection_form")
              : t("add_inspection_form")
          }
          isSaveLoading={saveBtnLoading}
          handleCancel={handleCancel}
        />
        {/* </CardBody> */}
        {/* </Card> */}

        <Container>
          <Row>
            <Col md={6} lg={6} xl={6} style={{ paddingRight: 0 }}>
              <Card
                style={{
                  width: "100%",
                  //overflowY: "auto",
                  //height: "100vh",
                  scrollbarStyles,
                  height: `${window.innerHeight - 120}px`,
                }}
              >
                <CardBody
                  style={{
                    overflowY: "scroll",
                    paddingTop: 0,
                    //height: "650px",
                    //scrollbarStyles,
                  }}
                >
                  <Row className="pt-2">
                    <Col>
                      <FormGroupLabel>
                        {t("inspection_form_label")}
                        {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                      </FormGroupLabel>
                      <FormItem>
                        <FormField
                          name="inspectionForm"
                          control={control}
                          component="input"
                          //type="number"
                          //errors={errors}
                          rules={{
                            required: t("required_err"),
                          }}
                          defaultValue=""
                          //isAboveError={isAboveError}
                          placeholder={t("inspection_form_name_placeholder")}
                          style={inputStyles}
                        />
                      </FormItem>
                      {errors?.inspectionForm && (
                        <Error error={errors.inspectionForm.message} />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroupLabel>
                        {t("short_desc")}
                        {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                      </FormGroupLabel>
                      <FormItem>
                        <FormField
                          name="description"
                          control={control}
                          component="input"
                          //type="number"
                          //errors={errors}
                          // rules={{
                          //   required: t("required_err"),
                          // }}
                          defaultValue=""
                          //isAboveError={isAboveError}
                          placeholder={t("enter_desc_for_form")}
                          style={inputStyles}
                        />
                      </FormItem>
                      {/* <Controller
                        name="description"
                        control={control}
                        errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Textarea
                            rows={2}
                            id="description"
                            name="description"
                            onChange={onChange}
                          ></Textarea>
                        )}
                      /> */}
                    </Col>
                  </Row>
                  <Row className="pt-4 pb-2">
                    <Col>
                      <CheckBoxInput
                        type="checkbox"
                        id="signReqd"
                        name="signReqd"
                        value="signReqd"
                        checked={signReqd}
                        onChange={() => setSignReqd((prev) => !prev)}
                        style={{ margin: "0 8px 0 0" }}
                        disabled={tag === "free-user"}
                      />
                      <Label htmlFor="signReqd" disabled={tag === "free-user"}>
                        {t("signature_is_mandatory_label")}
                      </Label>
                      {tag === "free-user" && (
                        <SubscribeTooltipIcon
                          message={t("subscribe_msg")}
                          handleClick={() => setShowSubscribePopup(true)}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <CheckBoxInput
                        type="checkbox"
                        id="odoReqd"
                        name="odoReqd"
                        value="odoReqd"
                        checked={odoReqd}
                        onChange={() => setOdoReqd((prev) => !prev)}
                        style={{ margin: "0 8px 0 0" }}
                      />
                      <Label htmlFor="odoReqd">
                        {t("odometer_is_mandatory_label")}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroupLabel>
                        {t("inspection_tasks_to_be_added_label")}
                      </FormGroupLabel>
                      {inspectionTasksError && (
                        <div style={{ margin: "-8px 0 8px 0" }}>
                          <Error error={t("one_insp_task_reqd_err_msg")} />
                        </div>
                      )}
                      <Controller
                        name="inspectionTasks"
                        control={control}
                        errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <CreatableSelect
                            // ref={selectRef}
                            // onInputChange={(value) => {
                            //   selectRef.current.getFocusedOptionRef =
                            //     () => null;
                            // }}
                            options={inspectionTasksOptions}
                            //value={serviceTasks.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option: MultiValueOption,
                            }}
                            onCreateOption={(data) => handleCreate(data)}
                            placeholder={t("inspection_task_placeholder")}
                            styles={selectStyles}
                            isMulti
                            controlShouldRenderValue={false}
                            isClearable={false}
                            //deleteRemoves={false}
                            backspaceRemovesValue={false}
                            isSearchable
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            defaultMenuIsOpen
                            menuIsOpen
                            //autoFocus
                          />
                        )}
                        defaultValue={[]}
                        rules={
                          {
                            // required: {
                            //   value: inspectionTasks?.length > 0,
                            //   message: t("required_err"),
                            // },
                            //minLength: 1 || 'Atleast one inspection task is required'
                          }
                        }
                      />
                      {/* {errors?.inspectionTasks && (
                        <Error error={errors.inspectionTasks.message} />
                      )}
                      {inspectionTasksError && (
                        <Error
                          error={"Atleast one inspection task is required"}
                        />
                      )} */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <Card style={{ height: `${window.innerHeight - 120}px` }}>
                <CardBody className="p-0">
                  <div className="p-2">
                    <FormHeading>{t("insp_tasks_order")}</FormHeading>
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <Col md={1}></Col>
                    {inspectionTasks?.length > 0 && (
                      <>
                        <Row className="px-1">
                          <Col md={1}></Col>
                          <Col md={4}></Col>
                          <Col md={6} className="d-flex justify-content-center">
                            <ColHeader>{t("action_tasks")}</ColHeader>
                          </Col>
                          {/* <Col md={1}></Col> */}
                        </Row>
                        <Row className="px-1">
                          <Col md={1}></Col>
                          <Col md={4}>
                            <ColHeader>{t("inspection_task")}</ColHeader>
                          </Col>
                          <Col md={2} className="m-0 p-0">
                            <ColHeader className="d-flex justify-content-center">
                              {t("inspection_passfail")}
                            </ColHeader>
                          </Col>
                          <Col md={2}>
                            <ColHeader className="d-flex justify-content-center">
                              {t("info")}
                            </ColHeader>
                          </Col>
                          <Col md={2}>
                            <ColHeader className="d-flex justify-content-center">
                              {t("photo")}
                            </ColHeader>
                          </Col>
                          <Col md={1}></Col>
                        </Row>
                      </>
                    )}
                    {/* {inspectionTasks?.map((item) => {
                      return (
                        <React.Fragment key={item.task_id}>
                          <hr
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                          <Row className="d-flex align-items-center">
                            <Col md={1}>
                              <i className="fi fi-rr-menu-burger"></i>
                            </Col>
                            <Col md={4}>
                              <p>{item.label}</p>
                            </Col>
                            <Col md={2}>
                              <AttrComponent isRequired={item.pass_required} />
                            </Col>
                            <Col md={2}>
                              <AttrComponent isRequired={item.value_required} />
                            </Col>
                            <Col md={2}>
                              <AttrComponent isRequired={item.image_required} />
                            </Col>
                            <Col md={1}>
                              <RemoveButton
                                fill={colorGray}
                                onClick={() => handleRemove(item)}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      );
                    })} */}
                    <DnDContainer
                    // style={{
                    //   height: `${window.innerHeight - 260}px`,
                    //   overflowY: "auto",
                    //   //overflowX: 'hidden',
                    //   scrollbarStyles
                    // }}
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="draggable" type="data">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {/* <ColumnList> */}
                              {inspectionTasks?.map((item, index) => {
                                return (
                                  <Draggable
                                    draggableId={`${item.task_id}`}
                                    index={index}
                                    key={item.task_id}
                                    type="data"
                                    //isDragDisabled={column.Header === "Vehicle"}
                                  >
                                    {(provided) => (
                                      <Row
                                        className="d-flex align-items-center px-1 py-2"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Col
                                          md={1}
                                          className="d-flex align-items-center"
                                        >
                                          <ReOrderButtonIcon className="fi fi-rr-menu-burger"></ReOrderButtonIcon>
                                        </Col>
                                        <Col md={4}>
                                          <p>{item.label}</p>
                                        </Col>
                                        <Col md={2}>
                                          <AttrComponent
                                            isRequired={item.pass_required}
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <AttrComponent
                                            isRequired={item.value_required}
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <AttrComponent
                                            isRequired={item.image_required}
                                          />
                                        </Col>
                                        <Col md={1}>
                                          {/* <RemoveButtonIcon
                                            className="fi fi-rr-cross-small"
                                            onClick={() => handleRemove(item)}
                                          /> */}
                                          <ActionIconContainer
                                            onClick={() => handleRemove(item)}
                                            style={{ marginTop: "0.5rem" }}
                                          >
                                            <i className="fi fi-rr-cross-small" />
                                          </ActionIconContainer>
                                        </Col>
                                      </Row>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {/* </ColumnList> */}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <AlwaysScrollToBottom />
                    </DnDContainer>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

export default InspectionFormsForm;

export const MultiValueOption = (props) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const screen = location.pathname;
  let label = "";
  if (screen?.includes("services") || screen?.includes("workorders")) {
    label = `${t("create_new_task", { screenName: "service" })} `;
  }
  if (screen?.includes("expenses") || screen?.includes("trips")) {
    label = `${t("create_new_task", { screenName: "expense" })} `;
  }
  if (screen?.includes("inspectionforms")) {
    label = `${t("create_new_task", { screenName: "inspection" })} `;
  }

  //console.log(props.data);
  if (props.data.__isNew__) {
    return (
      <>
        <hr style={{ margin: 0, padding: 0 }} />
        <components.Option {...props}>
          <span
            style={{ color: buttonColorNew, fontWeight: 300, fontSize: "12px" }}
          >
            {label}
            <span style={{ fontWeight: 500 }}>"{props.data.value}"</span>
          </span>
        </components.Option>
      </>
    );
  }
  return (
    <components.Option {...props}>
      {/* <InputRadioContainer>
          <CheckboxInput
            name={props.value}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ width: "auto" }}
            //defaultValue={false}
          />
          <Label>{props.label}</Label>
        </InputRadioContainer> */}
      <div
        className="d-flex align-items-center"
        style={{ height: "18px" }}
        {...props}
      >
        <TaskCheckboxInput
          id="task"
          name={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <TaskLabel htmlFor="task" onClick={(e) => e.preventDefault()}>
          {props.label}
        </TaskLabel>
      </div>
    </components.Option>
  );
};

export const Textarea = styled.textarea``;

export const RemoveButtonIcon = styled.i`
  position: relative;
  top: 6px;
  font-size: 18px;
  cursor: pointer;
  color: ${colorGray};
  &:hover {
    color: ${colorLightGray};
    //cursor: pointer;
  }
`;

export const ReOrderButtonIcon = styled.i`
  position: relative;
  top: 2px;
  font-size: 14px;
  cursor: pointer;
  color: ${colorGray};
`;

export const ColHeader = styled.p`
  font-size: 11px;
  font-weight: 300;
  //text-align: center;
`;

export const TaskLabel = styled.label`
  //font-size: 14px;
  margin: 0;
`;

export const TaskCheckboxInput = styled.input`
  width: auto !important;
  margin-right: 8px;
  //margin-top: -1px;
  position: relative;
  //top: 2px;
`;

export const DnDContainer = styled.div`
  max-height: ${window.innerHeight - 260}px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
  ${scrollbarStyles};
`;

export const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

export const getInspectionTaskOptions = async (orgId) => {
  const res = await postApi(
    { querystring: "get_all_inspection_tasks" },
    "commonNew"
  );
  if (res.success) {
    const { user_data } = res;
    let temp = user_data.map((item) => {
      return {
        ...item,
        label: item.task_name,
        value: item.task_id,
      };
    });
    temp = temp.filter((task) => task.task_id !== `${orgId}_odometer`);
    return temp;
  }
};
