import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useAsyncDebounce } from "react-table";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import {
  colorAdditional,
  colorBackground,
  colorText,
  colorTextNew,
  colorLightBlue,
  colorLightGray,
} from "@/utils/palette";
import { Button } from "@/shared/components/Button";
import CloseIcon from "mdi-react/CloseIcon";
import Alert from "@/shared/components/Alert";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  addFilterDate,
  addGroupId,
  addSearchValue,
  addVehicleId,
  clearAllFilters,
  removeFilterDate,
  //removeAdvancedFilter,
} from "../../../../features/filter/filterSlice";
import {
  removeAdvancedFilter,
  clearAllAdvancedFilters,
  addIssuesType,
  addStatusType,
  addCheckboxFilter,
} from "../../../../features/advancedFilter/advancedFilterSlice";
import { createFilter } from "react-select/dist/react-select.cjs.prod";
import MoreOptions from "./MoreOptions/MoreOptions";
import {
  Col,
  Collapse,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ColumnReorder, { CheckboxInput, Label } from "./ColumnReorder";
import {
  buttonColorNew,
  colorBlue,
  colorBlueHover,
  colorFormItemHover,
  colorGray,
  colorGrayNew,
  colorHover,
  colorYellowNew,
  sidenavLinkHoverColor,
} from "../../../../utils/palette";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { components } from "react-select";
// import AdvancedFilters, {
//   FilterButton,
//   FilterButtonContainer,
//   FilterButtonName,
// } from "./AdvancedFilters";
import {
  initialContainsData,
  initialfuelTypeData,
  initialRangeData,
  initialStatusData,
  initialTransactionTypeData,
  initialWOTypeData,
  //statusDataOptions,
} from "../../../constants/filterData";

import {
  issuesStyles,
  selectStylesNew,
  statusStyles,
} from "../../../constants/styles";
import AdvancedFiltersNew from "./AdvancedFiltersNew";
import { FilterButton } from "../../TableElements";
import { ReactComponent as FilterIcon } from "@/shared/img/bars-filter.svg";
import { ReactComponent as TrashIcon } from "@/shared/img/trash.svg";
import { InputRadioContainer } from "./AdvancedFiltersNew/RangeFilter";
import ExtDateFilter from "./ExtDateFilter";
import { useRef } from "react";
import {
  convertToDisplayDate,
  convertToFilterDate,
  debounce,
  generateGroupsTreeArr,
  generateTreeArr,
  getDeleteQuery,
  getMultiDeleteInfo,
  getScreenName,
  isObjEmpty,
} from "../../../helpers";
import InfoPopup from "../../InfoPopup";
import { TreeSelect } from "primereact/treeselect";
import ImportComponent from "../../ImportComponent";
import { deleteApi } from "../../../../api/getUser";
import { DeletePopup } from "./DeletePopup";
import { addMsg } from "../../../../features/snackbar/snackbarSlice";
import endOfToday from "date-fns/endOfToday";
import getUnixTime from "date-fns/getUnixTime";
import ActionPopup from "../../ActionPopup";
import SubscribePopup from "../../SubscribePopup";
import { useAuth } from "../../../../contexts/AuthContext";
import CombinedDateFilter from "./CombinedDateFilter";
import subDays from "date-fns/subDays";
import {
  clearMoreFilters,
  removeMoreFilters,
  updateExternalFilter,
} from "../../../../features/storedFilters/storedFiltersSlice";
import startOfToday from "date-fns/startOfToday";
import getDefaultFilterData from "../../../constants/getDefaultFilterData";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

const TempFilter = ({
  rows,
  dataLength,
  allColumns,
  valuesArray,
  initialData,
  selectedFlatRows,
  screen,
  setSubmissionsData,
  setColumnOrder,
  setHiddenColumns,
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.filter);
  const { t } = useTranslation("common");

  const location = useLocation();
  const { pathname } = location;
  //const screen = path.slice(1, path.length);
  const history = useHistory();

  const { roleId } = useSelector((state) => state.user);

  const [filterData, setFilterData] = useState(initialData);

  // useEffect(() => {
  //   setFilterData(initialData);
  // }, [initialData]);

  const [dateOption, setDateOption] = useState("all-time");

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [optionValues, setOptionValues] = useState(valuesArray);
  const [searchHasValue, setSearchHasValue] = useState(false);

  const [showImportPopup, setShowImportPopup] = useState(false);
  const [filterBySearch, setFilterBySearch] = useState("");

  const {
    tag,
    vehicle,
    setVehicle,
    groupId,
    setGroupId,
    vehicles,
    setVehicles,
    groups,
    setGroups,
    filters,
    setFilters,
    search,
    setSearch,
  } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  // const onChange = useAsyncDebounce((item) => {
  //   const value = item.trim();
  //   if (value) {
  //     setSearchHasValue(true);
  //   } else {
  //     setSearchHasValue(false);
  //   }
  //   setFilterBySearch(value);
  //   setSearch(value);
  //   //setTimeout(() => {
  //   dispatch(addSearchValue(value));
  //   //}, 200);
  //   setFilterValue(value);
  //   setGlobalFilter(value);
  // }, 200);
  const onChange = (value) => {
    // const value = item.trim();
    // if (value) {
    //   setSearchHasValue(true);
    // } else {
    //   setSearchHasValue(false);
    // }
    // setFilterBySearch(value);
    // debounce(() => {
    dispatch(addSearchValue(value));

    dispatch(
      updateExternalFilter({
        screen: screenName,
        filterName: "search",
        filterValue: value,
      })
    );

    // setFilters((prev) => {
    //   let temp = { ...prev };
    //   let currentScreen = getScreenName(screen);
    //   let currentScreenFilters = temp[currentScreen];
    //   let searchFilter = currentScreenFilters?.find(
    //     (f) => f?.filterName === "search"
    //   );
    //   if (searchFilter) {
    //     searchFilter.value = value;
    //     currentScreenFilters = currentScreenFilters.filter(
    //       (f) => f?.filterName !== "search"
    //     );
    //     currentScreenFilters.push(searchFilter);
    //   } else {
    //     currentScreenFilters.push({
    //       filterType: "external",
    //       value: value,
    //       filterName: "search",
    //       moreFilters: false,
    //     });
    //   }

    //   return { ...temp, [currentScreen]: currentScreenFilters };
    // });

    //setSearch(value);
    // setTimeout(() => {
    //   dispatch(addSearchValue(value));
    // });
    //setFilterValue(value);
    //setGlobalFilter(value);
    // });
  };

  const debouncedSearch = useCallback(debounce(onChange), []);

  const { minDate } = useSelector((state) => state.columns);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const groupsList = useSelector((state) => state.groups.list);

  // const [vehicles, setVehicles] = useState(vehiclesList);
  // const [groups, setGroups] = useState([]);

  //const [vehicle, setVehicle] = useState(vehiclesList[0]);

  //const [groupId, setGroupId] = useState(null);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  //For Inspection Submissions
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const extDateFilterRef = useRef(null);

  //These two states are for Info Popup which is shown in case record cannot be deleted
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoData, setInfoData] = useState({});

  //Close External Date Filter when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!extDateFilterRef?.current?.contains(event.target)) {
        setIsDateFilterOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [extDateFilterRef]);

  //Filter Data for Reminders
  const statusDataOptions = [
    { id: 0, value: "scheduled", label: t("scheduled") },
    { id: 1, value: "dueSoon", label: t("due_soon") },
    { id: 2, value: "overdue", label: t("overdue") },
  ];
  const [statusArr, setStatusArr] = useState([]);

  //Filter Data for Inspection Submission
  const issuesArrayData = [
    {
      id: 0,
      label: t("all_submissions"),
      value: "all",
    },
    {
      id: 1,
      label: t("with_issues"),
      value: "with",
    },
    {
      id: 2,
      label: t("without_issues"),
      value: "without",
    },
  ];
  const [issue, setIssue] = useState(issuesArrayData[0]);

  //Filter Data for Issues
  const [issueStatus, setIssueStatus] = useState([]);
  const issueStatusOptions = [
    {
      id: 0,
      label: t("drawer_open"),
      value: "open",
    },
    {
      id: 1,
      label: t("in_progress"),
      value: "inProgress",
    },
    {
      id: 2,
      label: t("closed"),
      value: "closed",
    },
  ];

  const [woStatus, setWOStatus] = useState([]);
  const woStatusOptions = [
    {
      id: 0,
      label: t("drawer_open"),
      value: "open",
    },
    {
      id: 1,
      label: t("in_progress"),
      value: "inProgress",
    },
    {
      id: 2,
      label: t("paused"),
      value: "paused",
    },
    {
      id: 3,
      label: t("closed"),
      value: "closed",
    },
  ];

  ///Used for both issues and work orders
  const [issuePriority, setIssuePriority] = useState([]);
  const issuePriorityOptions = [
    {
      id: 0,
      label: t("not_set"),
      value: "notSet",
    },
    {
      id: 1,
      label: t("low"),
      value: "low",
    },
    {
      id: 2,
      label: t("med"),
      value: "medium",
    },
    {
      id: 3,
      label: t("high"),
      value: "high",
    },
  ];

  //State to maintian error msg in case of errors on Create Work Order button
  const [createWOError, setCreateWOError] = useState(false);
  const [createWOErrorMessage, setCreateWOErrorMessage] = useState(false);

  const [multiDeleteApiData, setMultiDeleteApiData] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  //State to show create wo confirmation popup
  const [showCreateWOPopup, setShowCreateWOPopup] = useState(false);

  //State to maintain no of selected issues from which to create a work order
  const [selectedIssues, setSelectedIssues] = useState(false);

  const screenName = getScreenName(pathname);
  const storedFilters = useSelector((state) => state.storedFilters);

  const [externalDatePreset, setExternalDatePreset] = useState({
    filterText: t("last_7_days"),
    from: subDays(startOfToday(), 6),
    to: endOfToday(),
    dateOption: "last-7-days",
  });

  useEffect(() => {
    let screenName = getScreenName(screen);
    // if (screen.includes("renewals")) {
    //   screenName = "maintenancereminders";
    // }

    const currentScreenFilters = storedFilters[screenName];

    if (currentScreenFilters && currentScreenFilters?.length > 0) {
      const moreFilters = currentScreenFilters.filter((f) => f?.moreFilters);
      setAppliedFilters(moreFilters);
    }

    const searchFilter = currentScreenFilters?.find(
      (f) => f.filterName === "search"
    );
    if (searchFilter && searchFilter?.value) {
      setFilterBySearch(searchFilter?.value);
      // if (searchFilter?.value) {
      setSearchHasValue(true);
      // }
    }
    if (
      screen.includes("maintenancereminders") ||
      screen.includes("renewals")
    ) {
      const remindersFilters = storedFilters[screenName];
      // setAppliedFilters(moreFilters);

      // const moreFilters = remindersFilters.filter((f) => f?.moreFilters);
      const statusFilter = remindersFilters.find(
        (f) => f?.filterName === "status"
      );
      if (statusFilter) {
        setStatusArr(statusFilter?.value);
      }
      // const searchFilter = remindersFilters?.find(
      //   (f) => f.filterName === "search"
      // );
      // if (searchFilter && searchFilter?.value) {
      //   setFilterBySearch(searchFilter?.value);
      //   // if (searchFilter?.value) {
      //   setSearchHasValue(true);
      //   // }
      // }
    }
    if (screen.includes("inspectionsubmissions")) {
      const submissionsFilters = storedFilters.inspectionsubmissions;

      const dateFilter = submissionsFilters.find(
        (f) => f?.filterName === "date"
      );

      if (dateFilter) {
        const { filterText, from, to, dateOption, warningText } =
          dateFilter?.value;
        const fromDate = from ? new Date(from * 1000) : subDays(new Date(), 6);
        const toDate = to ? new Date(to * 1000) : new Date();
        setExternalDatePreset({
          filterText: filterText ? filterText : t("last_7_days"),
          from: fromDate,
          to: toDate,
          dateOption: dateOption ? dateOption : "last-7-days",
        });

        setSubmissionsData((prev) => ({
          ...prev,
          filterText: filterText ? filterText : t("last_7_days"),
          warningText: warningText,
        }));
      }

      const issueFilter = submissionsFilters.find(
        (f) => f?.filterName === "issue"
      );
      if (issueFilter) {
        setIssue(issueFilter?.value);
      }
    }

    if (screen.includes("issues")) {
      const issuesFilters = storedFilters.issues;

      const statusFilter = issuesFilters.find(
        (f) => f?.filterName === "status"
      );

      if (statusFilter) {
        setIssueStatus(statusFilter?.value);
      }
      const priorityFilter = issuesFilters.find(
        (f) => f?.filterName === "priority"
      );
      setIssuePriority(priorityFilter?.value);
    }

    if (screen.includes("workorders")) {
      const woFilters = storedFilters.work_order;

      const statusFilter = woFilters.find((f) => f?.filterName === "status");

      if (statusFilter) {
        setWOStatus(statusFilter?.value);
      }
      const priorityFilter = woFilters.find(
        (f) => f?.filterName === "priority"
      );
      setIssuePriority(priorityFilter?.value);
    }

    // const searchFilter = filters[screenName]?.find(
    //   (f) => f.filterName === "search"
    // );
    // if (searchFilter) {
    //   setFilterBySearch(searchFilter?.value);
    //   if (searchFilter?.value) {
    //     setSearchHasValue(true);
    //   }
    // }

    // const appliedFilters = Object.values(columns).map(
    //   (filter) => !isObjEmpty(filter)
    // );
    // setAppliedFilters(appliedFilters);
    //setFilterBySearch(search);
    //setFilterValue(search);
    //setGlobalFilter(search);
  }, [screen]);

  //Effect to set issue status
  // useEffect(() => {
  //   if (screen.includes("issues")) {
  //     let filterObj = {};
  //     issueStatus?.forEach((item) => {
  //       filterObj[item.value] = 1;
  //     });
  //     dispatch(
  //       addCheckboxFilter({
  //         filterObj,
  //         screen: "issues",
  //         filterName: "issueStatus",
  //       })
  //     );
  //   }
  // }, [issueStatus]);

  //Effect to set wo status
  // useEffect(() => {
  //   if (screen.includes("workorders")) {
  //     let filterObj = {};
  //     woStatus?.forEach((item) => {
  //       filterObj[item.value] = 1;
  //     });
  //     dispatch(
  //       addCheckboxFilter({
  //         filterObj,
  //         screen: "workorders",
  //         filterName: "woStatus",
  //       })
  //     );
  //   }
  // }, [woStatus]);

  //Effect to set priority for issues and work orders
  // useEffect(() => {
  //   if (screen.includes("issues") || screen.includes("workorders")) {
  //     let filterObj = {};
  //     let screenName,
  //       filterName = "";
  //     if (screen.includes("issues")) {
  //       screenName = "issues";
  //       filterName = "issuePriority";
  //     } else {
  //       screenName = "workorders";
  //       filterName = "woPriority";
  //     }
  //     issuePriority?.forEach((item) => {
  //       filterObj[item.value] = 1;
  //     });
  //     dispatch(
  //       addCheckboxFilter({
  //         filterObj,
  //         screen: screen,
  //         filterName: filterName,
  //       })
  //     );
  //   }
  // }, [issuePriority]);

  //Effect to set KPI values
  useEffect(() => {
    setOptionValues(valuesArray);
  }, [valuesArray]);

  //Effect to send Issues filter data in Inspection Submission
  // useEffect(() => {
  //   dispatch(addIssuesType(issue));
  // }, [issue]);

  //Effect to send status filter data in Reminders
  // useEffect(() => {
  //   let statusObj = {};
  //   statusArr?.forEach((item) => {
  //     statusObj[item.value] = 1;
  //   });
  //   dispatch(addStatusType(statusObj));
  // }, [statusArr]);

  // useEffect(() => {
  //   if (groupsList.length > 0) {
  //     const groupsTreeArr = generateTreeArr(groupsList);
  //     setGroups(groupsTreeArr);
  //     setGroupId(groupsTreeArr[0]?.key);
  //   }

  //   setVehicles(vehiclesList);
  //   setVehicle(vehiclesList[0]);
  // }, [vehiclesList, groupsList]);

  // useEffect(() => {
  //   const selectedGroup = groupsList.find((g) => g.group_id === groupId);
  //   if (groupId === undefined) {
  //     setVehicle("");
  //   } else if (groupId === "all-groups") {
  //     setVehicles(vehiclesList);
  //     setVehicle(vehiclesList[0]);
  //   } else {
  //     const temp = vehiclesList.filter((singleVehicle) => {
  //       if (
  //         singleVehicle?.id === 0 ||
  //         groupId === singleVehicle?.group_id ||
  //         (selectedGroup?.groupNodes &&
  //           selectedGroup.groupNodes.includes(singleVehicle?.group_id))
  //       ) {
  //         return singleVehicle;
  //       }
  //     });
  //     setVehicles(temp);
  //     setVehicle(vehiclesList[0]);
  //   }

  //   const value = groupId && groupId !== "all-groups" ? groupId : "";
  //   dispatch(addGroupId(value));
  // }, [groupId]);

  // useEffect(() => {
  //   const value = vehicle?.vehicleId ? vehicle.vehicleId : "";
  //   setTimeout(() => {
  //     dispatch(addVehicleId(value));
  //   }, 200);
  // }, [vehicle?.vehicleId]);

  const handleCloseClick = (filter) => {
    const { column } = filter;
    if (column === "date") {
      let startDate = 0;
      let endDate = getUnixTime(new Date());
      if (screen.includes("dailymileage")) {
        endDate = getUnixTime(endOfToday());
      }
      dispatch(removeFilterDate({ startDate, endDate }));
    }
    dispatch(removeAdvancedFilter({ column, screen }));
    setAppliedFilters((prev) => {
      let updatedFilters = prev.filter((filter) => filter.column !== column);
      return updatedFilters;
    });

    dispatch(
      removeMoreFilters({
        screen: getScreenName(screen),
        filterToRemove: filter,
      })
    );
    // setFilters((prev) => {
    //   let temp = { ...prev };
    //   const currentScreen = getScreenName(screen);
    //   let currentScreenFilters = temp[currentScreen];

    //   let updatedFilters = currentScreenFilters.filter(
    //     (filter) => filter.column !== column
    //   );
    //   return { ...temp, [currentScreen]: updatedFilters };
    // });

    //updateFilterState(filter);
    updateFilterState(filter, setFilterData, minDate, screen, t);
  };

  const handleClearAll = () => {
    const columns = appliedFilters.map((item) => item.column);
    dispatch(clearAllAdvancedFilters({ columns, screen }));
    let startDate = 0;
    let endDate = getUnixTime(new Date());
    if (screen.includes("dailymileage")) {
      endDate = getUnixTime(endOfToday());
    }
    dispatch(removeFilterDate({ startDate, endDate }));
    setAppliedFilters([]);

    //setFilters((prev) => ({ ...prev, [getScreenName(screen)]: [] }));

    // setFilters((prev) => {
    //   let temp = { ...prev };
    //   const currentScreen = getScreenName(screen);
    //   let currentScreenFilters = temp[currentScreen];

    //   let updatedFilters = currentScreenFilters.filter(
    //     (filter) => !filter?.moreFilters
    //   );
    //   return { ...temp, [currentScreen]: updatedFilters };
    // });
    dispatch(clearMoreFilters({ screen: getScreenName(screen) }));
    setFilterData(initialData);
  };

  const getFilterMessage = (filter) => {
    if (Object.hasOwn(filter, "from")) {
      return `${filter.fromString} - ${filter.toString}`;
    } else if (Object.hasOwn(filter, "input_2")) {
      if (filter.radioValue === "between") {
        return `${filter.radioName} ${filter.input_1} and ${filter.input_2}`;
      } else {
        return `${filter.radioName} ${filter.input_1}`;
      }
    } else if (Object.hasOwn(filter, "input_1")) {
      return `${filter.radioName} ${filter.input_1}`;
    } else {
      const { headersArray } = filter;

      let message = ``;
      headersArray.forEach((item, i) => {
        if (i === headersArray.length - 1) {
          message += item;
        } else {
          message += `${item}, `;
        }
      });
      return message;
    }
  };

  const handleDeleteSelected = async () => {
    // const rowId = screen.includes("maintenancereminders")
    //   ? "reminder_id"
    //   : "fillup_id";
    const { rowId, subQuery } = getMultiDeleteInfo(screen);
    const rowIdArr = selectedFlatRows.map((item) => item.original[rowId]);
    const deleteIds = rowIdArr.join(":::");
    const apiData = {
      delete_ids: deleteIds,
      querystring: "multiple_delete",
      sub_query_string: subQuery,
    };
    setMultiDeleteApiData(apiData);
    setShowDeletePopup(true);
  };

  const handleCreateWorkOrder = () => {
    if (tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    const selectedRecords = selectedFlatRows.map((row) => row.original);

    let selectedVehicleId = selectedRecords[0].veh_id;
    for (let row of selectedRecords) {
      if (row.status !== 0 || row.work_order_id) {
        setCreateWOErrorMessage(t("create_wo_for_assigned_issues_err_msg"));
        setCreateWOError(true);
        return;
      }
      if (row.veh_id !== selectedVehicleId) {
        setCreateWOErrorMessage(t("create_wo_for_multiple_veh_err_msg"));
        setCreateWOError(true);
        return;
      }
    }
    setSelectedIssues(selectedRecords);

    setShowCreateWOPopup(true);
  };

  const handleCreateWO = () => {
    history.push("/workorders/addnew", {
      data: selectedIssues,
      type: "add",
      mode: "from-issues-table",
    });
  };

  //Inspection submissions date filter
  //Logic to get warning text in inspection submissions
  const getWarningText = (date) => {
    const { dateOption, from, to } = date;
    if (dateOption === "all-time") {
      return `${t("all_time_sm")}`;
    } else if (dateOption === "last-7-days") {
      return `${t("in")} ${t("the")} ${t("last_7_days_sm")}`;
    } else if (dateOption === "last-30-days") {
      return `${t("in")} ${t("the")} ${t("last_30_days_sm")}`;
    } else if (dateOption === "last-90-days") {
      return `${t("in")} ${t("the")} ${t("last_90_days_sm")}`;
    } else if (dateOption === "this-year") {
      return `${t("this_year_sm")}`;
    } else if (dateOption === "this-month") {
      return `${t("this_month_sm")}`;
    } else if (dateOption === "today") {
      return `${t("today_sm")}`;
    } else if (dateOption === "yesterday") {
      return `${t("yesterday_sm")}`;
    } else if (dateOption === "all-time") {
      return `${t("all_time")}`;
    } else if (dateOption === "custom") {
      return `from ${convertToDisplayDate(from)} - ${convertToDisplayDate(to)}`;
    }
  };

  const handleDateFilterApply = (date) => {
    const dateOptionsObj = {
      today: t("today"),

      yesterday: t("yesterday"),

      "last-7-days": t("last_7_days"),

      "last-30-days": t("last_30_days"),

      "last-90-days": t("last_90_days"),

      "this-month": t("this_month"),

      "this-year": t("this_year"),
      "all-time": t("all_time"),

      custom: t("custom"),
    };

    setSubmissionsData((prev) => ({
      ...prev,
      filterText:
        date?.dateOption === "custom"
          ? `${convertToFilterDate(date.from)} - ${convertToFilterDate(
              date.to
            )}`
          : dateOptionsObj[date?.dateOption],
      warningText: getWarningText(date),
    }));

    if (date.from && date.to) {
      const startDate = getUnixTime(startOfDay(date.from)); // Math.round(date.from / 1000);
      const endDate = getUnixTime(endOfDay(date.to)); //Math.round(date.to / 1000);

      if (screen?.includes("inspectionsubmissions")) {
        dispatch(addFilterDate({ start: startDate, end: endDate }));
        const filterObj = {
          from: startDate,
          to: endDate,
          dateOption: date.dateOption,
          filterText:
            date?.dateOption === "custom"
              ? `${convertToFilterDate(date.from)} - ${convertToFilterDate(
                  date.to
                )}`
              : dateOptionsObj[date?.dateOption],
          warningText: getWarningText(date),
        };
        dispatch(
          updateExternalFilter({
            screen: screenName,
            filterName: "date",
            filterValue: filterObj,
          })
        );
      }
      // else if (screen?.includes("dailytimesheet")) {
      //   setDailyTSFilters((prev) => {
      //     let temp = prev.filter((f) => f.type !== "date");
      //     temp.push({ type: "date", data: { startDate, endDate } });
      //     return temp;
      //   });
      // }
    }
  };

  return (
    <>
      {showCreateWOPopup && (
        <ActionPopup
          showPopup={showCreateWOPopup}
          setShowPopup={setShowCreateWOPopup}
          handleYes={handleCreateWO}
          message={t("create_wo_from_issue_confirmation_msg", {
            count: selectedIssues.length,
          })}
          handleNo={() => setShowCreateWOPopup(false)}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          deleteApiData={multiDeleteApiData}
          screen={screen}
          setShowInfoPopup={setShowInfoPopup}
          setInfoData={setInfoData}
        />
      )}
      {showInfoPopup && (
        <InfoPopup
          showPopup={showInfoPopup}
          setShowPopup={setShowInfoPopup}
          message={infoData?.msg}
          icon={infoData?.icon}
          listMsg={infoData?.listMsg}
        />
      )}
      {createWOError && (
        <InfoPopup
          message={createWOErrorMessage}
          showPopup={createWOError}
          setShowPopup={setCreateWOError}
          icon={{ color: "red", className: "fi fi-rr-triangle-warning" }}
        />
      )}
      {showImportPopup && (
        <ImportComponent
          showPopup={showImportPopup}
          setShowPopup={setShowImportPopup}
          screenName={getScreenName(screen)}
          //modalHeading={"Import Daily Mileage into Simply Fleet"}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <Container style={{ padding: "2px 0" }}>
        <div className="d-flex justify-content-between mx-3 my-0 p-0 gap-3">
          {/* <Col md={11}> */}
          {selectedFlatRows.length <= 0 ? (
            <div className="d-flex justify-content-start align-items-center gap-3 flex-lg-row flex-sm-column">
              {/* <Col md={3} className='p-0 ml-3'> */}
              {/* <SearchWrap id="search" active={searchHasValue}> */}
              <SearchInput
                id="search"
                type="search"
                value={filterBySearch}
                placeholder={t("filter_by_keyword")}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setSearchHasValue(true);
                  } else {
                    setSearchHasValue(false);
                  }
                  setFilterBySearch(value);
                  debouncedSearch(value);
                }}
                active={searchHasValue}
              />
              {/* {dataLength !== rows.length && (
                <span>Found {rows.length} matches</span>
              )} */}
              {/* </SearchWrap> */}
              {/* </Col> */}
              {/* <Col md={3}> */}
              {/* <Select
                id="group-dropdown"
                value={group}
                onChange={setGroup}
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="color"
                options={groups}
                styles={selectStylesNew}
                placeholder={t("group")}
                filterOption={createFilter({ ignoreAccents: false })}
              /> */}
              {/* <TreeSelect
                selected={group}
                setSelected={setGroup}
                options={groups}
                setOptions={setGroups}
                allOptions={generateGroupsTreeArr(groupsList)}
              /> */}
              <TreeSelect
                id="group-treeselect-dropdown"
                value={groupId}
                onChange={(e) => setGroupId(e.value)}
                options={groups}
                //className="md:w-20rem w-full"
                placeholder={t("group")}
                filter
                className={
                  groupId && groupId !== "all-groups"
                    ? "treeselect-with-value"
                    : ""
                }
              ></TreeSelect>
              {/* </Col> */}
              {/* <SelectSearch options={vehiclesList} value={vehicle} onChange={setVehicle} name="language" placeholder="Choose your language" search/> */}
              {/* <Col md={3}> */}
              <Select
                id="vehicle-dropdown"
                value={vehicle}
                onChange={setVehicle}
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="color"
                options={vehicles}
                styles={selectStylesNew}
                placeholder={t("vehicle")}
              />
              {/* </Col> */}

              {/* Status Filter for reminders screen */}
              {screen.includes("reminders") && (
                <Select
                  id="status-dropdown"
                  options={statusDataOptions}
                  value={statusArr}
                  onChange={(arr) => {
                    setStatusArr(arr);
                    let statusObj = {};
                    arr?.forEach((item) => {
                      statusObj[item.value] = 1;
                    });
                    dispatch(
                      addStatusType({
                        statusObj,
                        screen: getScreenName(screen),
                      })
                    );
                    dispatch(
                      updateExternalFilter({
                        screen: getScreenName(screen),
                        filterName: "status",
                        filterValue: arr,
                      })
                    );
                  }}
                  //className="basic-multi-select"
                  //classNamePrefix="select"
                  //name="color"
                  styles={statusStyles}
                  placeholder={t("status")}
                  isSearchable={false}
                  isMulti={true}
                  //isClearable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  //controlShouldRenderValue={false}
                  filterOption={createFilter({
                    ignoreAccents: false,
                  })}
                />
                //</Col>
              )}
              {screen.includes("inspectionsubmissions") && (
                // <Col md={1}>
                <>
                  <Select
                    id="submission-issues-dropdown"
                    value={issue}
                    //onChange={setIssue}
                    onChange={(item) => {
                      setIssue(item);
                      dispatch(addIssuesType(item));
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "issue",
                          filterValue: item,
                        })
                      );
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    options={issuesArrayData}
                    styles={issuesStyles}
                    placeholder={t("issues")}
                    isSearchable={false}
                  />
                  {/* <div ref={extDateFilterRef}>
                    <DateContainer
                      onClick={() => setIsDateFilterOpen((prev) => !prev)}
                    >
                      <span>{submissionsData.filterText}</span>
                      <i className="fi fi-bs-angle-small-down"></i>
                    </DateContainer>
                    {isDateFilterOpen && (
                      <ExtDateFilter
                        isDateFilterOpen={isDateFilterOpen}
                        setIsDateFilterOpen={setIsDateFilterOpen}
                        submissionsData={submissionsData}
                        setSubmissionsData={setSubmissionsData}
                      />
                    )}
                  </div> */}
                  <CombinedDateFilter
                    presetDate={externalDatePreset}
                    handleDateApply={handleDateFilterApply}
                    allTimeStartDate={new Date(minDate * 1000)}
                  />
                </>
                //</Col>
              )}
              {screen.includes("issues") && (
                <>
                  <Select
                    id="issues-status-dropdown"
                    value={issueStatus}
                    //onChange={setIssueStatus}
                    onChange={(val) => {
                      setIssueStatus(val);
                      let filterObj = {};
                      val?.forEach((item) => {
                        filterObj[item.value] = 1;
                      });
                      dispatch(
                        addCheckboxFilter({
                          filterObj,
                          screen: "issues",
                          filterName: "issueStatus",
                        })
                      );
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "status",
                          filterValue: val,
                        })
                      );
                    }}
                    name="color"
                    options={issueStatusOptions}
                    components={{
                      Option,
                    }}
                    styles={issuesStyles}
                    placeholder={t("status")}
                    isSearchable={false}
                    isMulti={true}
                    //isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    //isClearable={false}
                  />
                  {/* <Select
                    id="issues-priority-dropdown"
                    value={issuePriority}
                    onChange={setIssuePriority}
                    //className="basic-single"
                    //classNamePrefix="select"
                    //name="color"
                    options={issuePriorityOptions}
                    components={{
                      Option,
                    }}
                    isMulti={true}
                    //isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    styles={issuesStyles}
                    placeholder="Priority"
                    isSearchable={false}
                    controlShouldRenderValue={false}
                    //isClearable={false}
                  /> */}
                </>
              )}
              {screen.includes("workorders") && (
                <>
                  <Select
                    id="wo-status-dropdown"
                    value={woStatus}
                    //onChange={setWOStatus}
                    onChange={(val) => {
                      setWOStatus(val);
                      let filterObj = {};
                      val?.forEach((item) => {
                        filterObj[item.value] = 1;
                      });
                      dispatch(
                        addCheckboxFilter({
                          filterObj,
                          screen: "workorders",
                          filterName: "woStatus",
                        })
                      );
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "status",
                          filterValue: val,
                        })
                      );
                    }}
                    name="color"
                    options={woStatusOptions}
                    components={{
                      Option,
                    }}
                    styles={issuesStyles}
                    placeholder={t("status")}
                    isSearchable={false}
                    isMulti={true}
                    //isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    //isClearable={false}
                  />
                </>
              )}

              {(screen.includes("issues") || screen.includes("workorders")) && (
                <Select
                  id="priority-dropdown"
                  value={issuePriority}
                  //onChange={setIssuePriority}
                  onChange={(val) => {
                    setIssuePriority(val);
                    let filterObj = {};
                    let screenName,
                      filterName = "";
                    if (screen.includes("issues")) {
                      screenName = "issues";
                      filterName = "issuePriority";
                    } else {
                      screenName = "workorders";
                      filterName = "woPriority";
                    }
                    val?.forEach((item) => {
                      filterObj[item.value] = 1;
                    });
                    dispatch(
                      addCheckboxFilter({
                        filterObj,
                        screen: screen,
                        filterName: filterName,
                      })
                    );
                    dispatch(
                      updateExternalFilter({
                        screen: getScreenName(screen),
                        filterName: "priority",
                        filterValue: val,
                      })
                    );
                  }}
                  //className="basic-single"
                  //classNamePrefix="select"
                  //name="color"
                  options={issuePriorityOptions}
                  components={{
                    Option,
                  }}
                  isMulti={true}
                  //isClearable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={issuesStyles}
                  placeholder={t("priority")}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  //isClearable={false}
                />
              )}
              <FilterButton
                onClick={() => setShowAdvancedFilters(true)}
                id="advanced-filter-button"
              >
                <FilterIcon />
                {/* {t("filter")} */}
                {t("more_filters")}
              </FilterButton>
              <AdvancedFiltersNew
                show={showAdvancedFilters}
                onHide={() => setShowAdvancedFilters(false)}
                filterData={filterData}
                setFilterData={setFilterData}
                dateOption={dateOption}
                setDateOption={setDateOption}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                screen={screen}
                initialData={initialData}
              />
              {/* </Col> */}
              {/* </div> */}
            </div>
          ) : (
            //<Row>
            <DeleteRowsContainer>
              {screen.includes("issues") && roleId != 4 && (
                <FilterButton
                  onClick={handleCreateWorkOrder}
                  disabled={roleId == 4}
                >
                  {t("create_work_order")}
                </FilterButton>
              )}
              {(roleId != 4 ||
                (roleId == 4 && screen.includes("expenses"))) && (
                <FilterButton
                  onClick={handleDeleteSelected}
                  disabled={roleId == 4 && !screen.includes("expenses")}
                >
                  {t("delete_selected")}
                </FilterButton>
              )}
            </DeleteRowsContainer>
            //</Row>
          )}
          {/* </Col> */}

          <RightSideContainer>
            {screen !== "dailymileage" &&
              !screen.includes("reminders") &&
              !screen.includes("inspectionsubmissions") && (
                <ColumnReorder
                  allColumns={allColumns}
                  screen={screen}
                  setColumnOrder={setColumnOrder}
                  setHiddenColumns={setHiddenColumns}
                />
              )}
            <MoreOptions
              screen={screen}
              setShowImportPopup={setShowImportPopup}
            />
          </RightSideContainer>
        </div>

        {appliedFilters.length > 0 && (
          <AppliedFiltersContainer>
            {appliedFilters.map((filter) => {
              return (
                <AppliedFilter key={filter.column}>
                  <span>
                    <span style={{ fontSize: "12px" }}>{filter.name}:</span>
                    <span style={{ fontWeight: 500 }}>
                      {" "}
                      {getFilterMessage(filter)}
                    </span>
                  </span>
                  <span
                    onClick={() => handleCloseClick(filter)}
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </span>
                </AppliedFilter>
              );
            })}
            <FilterButton onClick={handleClearAll}>
              {t("clear_all")}
            </FilterButton>
          </AppliedFiltersContainer>
        )}
        {optionValues?.length > 0 && (
          <div>
            <hr style={{ margin: "8px 0" }} />
          </div>
        )}
        <div className="d-flex mx-3 gap-3">
          {optionValues?.map((item) => (
            <Alert
              color="info"
              neutral
              icon={item.icon}
              key={item.id}
              //className="col-xl-4"
            >
              <span>{item.name}</span>
              <p>{`${item.value} ${item?.units ? item.units : ""}`}</p>
            </Alert>
          ))}
        </div>
        {/* </Container> */}
      </Container>
    </>
  );
};

const filterGreaterThan = (rows, id, filterValue) =>
  rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

TempFilter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
};

TempFilter.defaultProps = {
  setFilterValue: () => {},
  placeholder: "Search...",
};

export default TempFilter;

// region STYLES

export const SearchWrap = styled.div`
  background: white;
  display: flex;
  align-items: center;
  border: 1px solid ${colorLightGray};
  //outline-width: 0;
  border-radius: 5px;
  height: 32px;
  margin: 0;
  padding: 0;
  //padding: 6px 10px;
  font-size: 12px;
  min-width: 150px;
  //max-width: 150px;
  color: ${colorTextNew};
  //background: ${colorBackground};

  &:focus-within {
    border-color: ${colorBlue};
  }

  ${(p) =>
    p.active &&
    `border-width: 2px;
  border-color: ${colorBlueHover};
`};
`;

export const SearchInput = styled.input`
  width: 150px;

  outline: none;
  box-shadow: none;
  background: none;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  color: ${colorTextNew};

  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  background: white;
  box-sizing: border-box;

  &:hover {
    border-color: ${colorFormItemHover};
  }

  &:focus {
    border-color: ${colorBlueHover};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 11px;
  }
  :-ms-input-placeholder {
    font-size: 11px;
  }

  ${(p) =>
    p.active &&
    `border-width: 2px;
  border-color: ${colorBlueHover};
`};
`;

// Added

export const FunctionsWrap = styled.div`
  display: flex;
  wrap: no-wrap
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${colorAdditional};
`;

export const AppliedFiltersContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: wrap;
  button {
    height: 30px;
  }
`;

export const AppliedFilter = styled.div`
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  background: white;

  span {
    //color: ${colorTextNew};
    svg {
      width: 18px;
      height: 24px;
      margin-left: 5px;
      //padding: 0 2px 2px 2px;
      fill: ${colorGray};

      &:hover {
        fill: lightgray;
      }
    }
  }
`;

const DeleteButtonContainer = styled.div``;

export const DeleteButton = styled(Button)`
  background: white;
  color: ${colorTextNew};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 10px;
  background-color: transparent;

  svg {
    height: 18px;
    width: 16px;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: ${colorHover};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`;

export const ClearSelection = styled.p`
  margin: 10px 10px 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${colorBlueHover};
`;

export const CancelButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: ${colorTextNew};
  height: 35px;
  border: 1px solid ${colorGrayNew};
  border-radius: 5px;
  margin: 10px 5px 0 5px;
  padding: 10px;
  min-width: 95px;
  white-space: nowrap;
  background: ${colorGrayNew};

  &:hover {
    background: ${colorLightGray};
  }
`;

export const DeleteRowsContainer = styled.div`
  //margin-bottom: 2px;
  //margin-top: 5px;
  display: flex;
  gap: 1rem;
  justify-content: start;
  align-items: center;
`;

export const RightSideContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 50px;
  //margin-right: 32px;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: white;
  padding: 4px 6px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  min-width: 170px;

  &:hover {
    border-color: ${colorFormItemHover};
  }

  i {
    position: relative;
    top: 4px;
    color: ${colorGray};
    margin-left: 4px;
    font-size: 20px;
  }
`;

// endregion

export const updateFilterState = (
  filter,
  setFilterData,
  minDate,
  screen,
  t
) => {
  if (Object.hasOwn(filter, "from")) {
    setFilterData((prev) => {
      const newData = {
        ...filter,
        from: new Date(minDate * 1000),
        to: screen.includes("dailymileage") ? endOfToday() : new Date(),
        dateOption: "all_time",
        isFiltered: false,
      };
      return { ...prev, [filter.column]: newData };
    });
  } else if (Object.hasOwn(filter, "input_2")) {
    setFilterData((prev) => {
      const newData = {
        ...filter,
        radioValue: "greater-than",
        radioName: t("greater_than"),
        input_1: "",
        input_2: "",
        isFiltered: false,
      };
      return { ...prev, [filter.column]: newData };
    });
  } else if (Object.hasOwn(filter, "input_1")) {
    setFilterData((prev) => {
      const newData = {
        ...filter,
        radioValue: "is",
        radioName: t("is"),
        input_1: "",
        isFiltered: false,
      };
      return { ...prev, [filter.column]: newData };
    });
  } else {
    setFilterData((prev) => {
      let newData = {};
      if (filter.column === "transactionType") {
        newData = {
          column: "transactionType",
          name: t("transcation_type"),
          cash: { header: t("cash_const"), value: false },
          debitCard: { header: t("debit_card_const"), value: false },
          creditCard: { header: t("credit_card_const"), value: false },
          check: { header: t("check_const"), value: false },
          isFiltered: false,
          type: "checkbox",
        };
      } else if (filter.column === "fuelType") {
        newData = {
          column: "fuelType",
          name: t("fuel_type"),
          petrol: { header: t("petrol_const"), value: false },
          diesel: { header: t("diesel_const"), value: false },
          isFiltered: false,
          type: "checkbox",
        };
      } else if (filter.column === "status") {
        newData = {
          column: "status",
          name: t("status"),
          scheduled: { header: t("scheduled_caps"), value: false },
          dueSoon: { header: t("due_soon_caps"), value: false },
          overdue: { header: t("overdue_caps"), value: false },
          isFiltered: false,
        };
      } else if (filter.column === "woType") {
        newData = {
          column: "woType",
          name: t("type"),
          scheduled: { header: t("scheduled"), value: false },
          unscheduled: { header: t("unscheduled"), value: false },
          isFiltered: false,
          type: "checkbox",
        };
      } else if (filter.column === "primaryMeter") {
        newData = {
          column: "primaryMeter",
          name: t("primary_meter"),
          miles: { header: t("miles"), value: false },
          kilometers: { header: t("kilometers"), value: false },
          hours: { header: t("hours_const"), value: false },
          isFiltered: false,
          type: "checkbox",
        };
      }
      return { ...prev, [filter.column]: newData };
    });
  }
};

//styles

export const Option = (props) => {
  if (props.data.__isNew__) {
    return <components.Option {...props}></components.Option>;
  }
  return (
    <div>
      <components.Option {...props}>
        <InputRadioContainer style={{ height: "32px" }}>
          <label
            onClick={(e) => e.preventDefault()}
            style={{ padding: "3px 0px" }}
          >
            <CheckboxInput
              name={props.value}
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              style={{ width: "auto" }}
              //defaultValue={false}
            />
            {props.label}
          </label>
        </InputRadioContainer>
      </components.Option>
    </div>
  );
};
