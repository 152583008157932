import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import DatePickerField from "@/shared/components/form/date-pickers/DatePicker";
import { ErrorText, SaveButton, SaveButtonContainer } from "./RangeFilter";
//import { addAdvancedFilter } from "../../../../../features/filter/filterSlice";
import { useDispatch } from "react-redux/es/exports";
import { useTranslation } from "react-i18next";
import { addAdvancedFilter } from "../../../../../features/advancedFilter/advancedFilterSlice";
import { addFilterDate } from "../../../../../features/filter/filterSlice";
import { CustomInput } from "../../../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  colorFormItemHover,
  colorGray,
  colorLightGray,
  colorTextNew,
} from "../../../../../utils/palette";
import { useSelector } from "react-redux";
import { getDateFnsLocale } from "../../../../helpers";
import endOfToday from "date-fns/endOfToday";

const DateFilter = ({ commonProps, top, data }) => {
  const {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    setLocalFilters,
  } = commonProps;
  const { t } = useTranslation("common");
  const [isError, setIsError] = useState(false);
  const errorMessage = `'From' Date cannot be greater than 'To' Date!`;
  //const [dateOption, setDateOption] = useState(data.dateOption);
  const dispatch = useDispatch();
  const { minDate, maxDate } = useSelector((state) => state.columns);

  // useEffect(() => {
  //   const end = new Date();
  //   const start = new Date(minDate * 1000);
  //   setFilterData((prev) => {
  //     const temp = { ...prev };
  //     let dateData = temp.date;
  //     dateData = { ...dateData, from: start, to: end };
  //     return { ...temp, date: dateData };
  //   });
  // }, []);

  // useEffect(() => {
  //   getCurrentDates("all_time");
  // }, [minDate]);

  //Logic for toggling filter icon in advanced filter pop-up
  // useEffect(() => {
  //   const isFiltered = data.dateOption === "all_time" ? false : true;
  //   setFilterData((prev) => ({
  //     ...prev,
  //     [data.column]: { ...data, isFiltered: isFiltered },
  //   }));
  // }, [data.dateOption]);

  //Set applied Filters value if it exists
  // useEffect(() => {
  //   const currentFilterCol = appliedFilters.find(
  //     (col) => col.column === data.column
  //   );
  //   if (currentFilterCol && currentFilterCol?.dateOption !== "all_time") {
  //     setFilterData((prev) => {
  //       return {
  //         ...prev,
  //         [currentFilterCol?.column]: {
  //           ...currentFilterCol,
  //           from: new Date(currentFilterCol?.from * 1000),
  //           to: new Date(currentFilterCol?.to * 1000),
  //           //isFiltered: true,
  //         },
  //       };
  //     });
  //   }
  // }, [appliedFilters]);

  function handleDateOptionChange(e) {
    const { value } = e.target;

    if (value) {
      setFilterData((prev) => ({
        ...prev,
        [data.column]: { ...data, dateOption: value },
      }));
    }
    getCurrentDates(value);
  }

  function getCurrentDates(value) {
    if (value === "all_time") {
      const end = endOfToday(); //new Date();
      const start = new Date(minDate * 1000);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: false,
        },
      }));
    } else if (value === "this_month") {
      const end = new Date();
      const start = new Date().setDate(1);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    } else if (value === "last_month") {
      const end = new Date().setDate(0);
      const start = new Date(new Date().setDate(0)).setDate(1);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    } else if (value === "last_30_days") {
      const end = new Date();
      let start = new Date();
      start.setDate(start.getDate() - 30);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    } else if (value === "last_90_days") {
      const end = new Date();
      let start = new Date();
      start.setDate(start.getDate() - 90);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    } else if (value === "last_week") {
      const end = new Date();
      end.setDate(end.getDate() - (end.getDay() + 1));
      let start = new Date();
      start.setDate(start.getDate() - (start.getDay() + 7));
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    } else if (value === "this_year") {
      const end = new Date();
      let start = new Date(new Date().getFullYear(), 0, 1);
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setFilterData((prev) => ({
        ...prev,
        [data.column]: {
          ...data,
          from: start,
          to: end,
          dateOption: value,
          isFiltered: true,
        },
      }));
    }
  }

  const [from, setFrom] = useState("October 13, 2014 11:13:00");
  const [to, setTo] = useState(null);

  const onStartDateChange = (date) => {
    setFilterData((prev) => ({
      ...prev,
      [data.column]: {
        ...data,
        from: date,
        dateOption: "custom",
        isFiltered: true,
      },
    }));
    //setFrom(date);
  };

  const onEndDateChange = (date) => {
    setFilterData((prev) => ({
      ...prev,
      [data.column]: {
        ...data,
        to: date,
        dateOption: "custom",
        isFiltered: true,
      },
    }));
  };

  const handleApply = () => {
    if (data.from && data.to) {
      if (Math.round(data.from / 1000) > Math.round(data.to / 1000)) {
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 3000);
      } else {
        updateAppliedFilters();
      }
    }
  };

  const updateAppliedFilters = () => {
    const dateInfo = {
      ...data,
      from: Math.round(data.from / 1000),
      to: Math.round(data.to / 1000),
    };
    if (screen.includes("inspectionscheduling")) {
      setLocalFilters((prev) => {
        const temp = prev.filter((f) => f.type !== data.column);
        temp.push({
          id: data.column,
          type: data.column,
          data: dateInfo,
        });
        return temp;
      });
    } else if (screen.includes("reminders")) {
      dispatch(addAdvancedFilter({ ...dateInfo, screen }));
    } else {
      dispatch(addFilterDate({ start: dateInfo.from, end: dateInfo.to })); // Sending start_date and end_date
    }
    setAppliedFilters((prev) => {
      const newArray = prev.filter((item) => item.column !== data.column);
      const readableDate = {
        ...data,
        from: new Date(data.from).toLocaleDateString("en-GB"),
        to: new Date(data.to).toLocaleDateString("en-GB"),
      };
      newArray.push(readableDate);
      return newArray;
    });
  };

  return (
    <DatepickerContainer top={top} className="col-xl-6">
      <DateOptions
        //dateOption={dateOption}
        //setDateOption={setDateOption}
        data={data}
        handleDateOptionChange={handleDateOptionChange}
      />
      {/* <DatepickerField
        name="From"
        dateFormat="MM/dd/yyyy"
        selected={data.from}
        onDateChange={onStartDateChange}
      />
      <DatepickerField
        name="To"
        dateFormat="MM/dd/yyyy"
        selected={data.to}
        onDateChange={onEndDateChange}
      /> */}
      <DatePicker
        className="col-xl-12"
        //dateFormat={"MM/dd/yyyy"}
        locale={getDateFnsLocale()}
        dateFormat={"P"}
        name="From"
        selected={data.from}
        onChange={onStartDateChange}
        customInput={<CustomInput />}
        showYearDropdown={true}
        showMonthDropdown={true}
        //showYearPicker={true}
      />
      <DatePicker
        name="To"
        //minDate={new Date()}
        //minDate={new Date(data.from*1000)}
        //dateFormat={"MM/dd/yyyy"}
        locale={getDateFnsLocale()}
        dateFormat={"P"}
        selected={data.to}
        onChange={onEndDateChange}
        customInput={<CustomInput />}
        showYearDropdown={true}
        showMonthDropdown={true}
      />
      {isError && <ErrorText>{errorMessage}</ErrorText>}
      {/* <SaveButtonContainer onClick={handleApply}>
        <SaveButton id="apply-filter-button" variant="primary">
          {t("filter.apply")}
        </SaveButton>
      </SaveButtonContainer> */}
    </DatepickerContainer>
  );
};

const DatepickerField = ({ name, selected, onDateChange, ...props }) => {
  return (
    <DatePickerWrap>
      {name && <p>{name}</p>}
      <DatePickerField
        selected={selected}
        onChange={onDateChange}
        defaultValue={new Date()}
        {...props}
      />
    </DatePickerWrap>
  );
};

DatepickerField.propTypes = {
  name: PropTypes.string,
};

DatepickerField.defaultProps = {
  name: null,
};

export const DateOptions = ({
  dateOption,
  setDateOption,
  data,
  handleDateOptionChange,
}) => {
  const { t } = useTranslation("common");
  return (
    <DateOptionsContainer className="col-xl-12">
      <select
        //style={{ border: "1px solid gray", borderRadius: "5px" }}
        name="dateOptions"
        id="dateOptions"
        value={data.dateOption}
        onChange={handleDateOptionChange}
      >
        <option value="all_time">{t("all_time")}</option>
        <option value="this_month">{t("this_month")}</option>
        <option value="last_week">{t("last_week")}</option>
        <option value="last_month">{t("last_month")}</option>
        <option value="last_30_days">{t("last_30_days")}</option>
        <option value="last_90_days">{t("last_90_days")}</option>
        <option value="this_year">{t("this_year")}</option>
        <option value="custom">{t("custom")}</option>
      </select>
    </DateOptionsContainer>
  );
};

export default DateFilter;
// region STYLES

const DatePickerWrap = styled.div`
  //margin-right: 20px;
  //margin-left: 20px;
`;

const DatepickerContainer = styled.div`
  padding: 20px 0;
  background: white;
  position: absolute;
  //border: 1px solid lightgrey;
  //border-radius: 5px;
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  //left: 170px;
  //top: -1px;
  //${(props) => (props.top ? `top: ${props.top}px` : `top: 0px`)}
`;

export const DateOptionsContainer = styled.div`
  //margin-top: 2px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${colorFormItemHover};
  }

  select {
    cursor: pointer;
    padding: 5px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: ${colorTextNew};
    background: none;
    //box-shadow: none;
  }
`;

// endregion
