import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ColumnHeading } from "../../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../../shared/components/Card";
import { colorLightGray, colorYellowNew } from "../../../utils/palette";
import SingleProvider from "./components/SingleProvider";
import { Desc } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import samsaraIcon from "@/shared/img/samsara-logo.jpg";
import geoTabIcon from "@/shared/img/geotab_logo.svg";
import streamlineIcon from "@/shared/img/streamline-logo.png";
import verizonConnectIcon from "@/shared/img/verizon-connect-logo.png";
import wexIcon from "@/shared/img/wex-logo.png";
import arcoIcon from "@/shared/img/arco_logo.png";
import Samsara from "./components/Samsara";
import { useAuth } from "../../../contexts/AuthContext";
import Snackbar from "../../../shared/components/Snackbar";
import styled from "styled-components";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import Geotab from "./components/Geotab";
import Streamline from "./components/Streamline";

const Integrations = () => {
  const { t } = useTranslation("common");
  const { integrationsData, tag } = useAuth();

  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const [samsaraStep, setSamsaraStep] = useState(0);
  const [geotabStep, setGeotabStep] = useState(0);
  const [streamlineStep, setStreamlineStep] = useState(0);

  //1-Connect 2-Modify 3-Coming soon 4-Connect(disabled since other provider is connected)
  const [providerStates, setProviderStates] = useState({
    samsara: 1,
    geotab: 1,
    streamline: 1,
    verizonConnect: 3,
  });
  const [telematicsConnected, setTelematicsConnected] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);

  //Update providerStates
  useEffect(() => {
    let providerStates = {
      samsara: 1,
      geotab: 1,
      streamline: 1,
      verizonConnect: 3,
    };
    if (integrationsData?.length > 0) {
      for (let intObj of integrationsData) {
        const { provider, provider_type, service_enabled, service_type } =
          intObj;
        if (provider_type === 1 && provider === 1) {
          providerStates.samsara = 2;
          providerStates.geotab = 4;
          providerStates.streamline = 4;
        }
        if (provider_type === 1 && provider === 2) {
          providerStates.geotab = 2;
          providerStates.samsara = 4;
          providerStates.streamline = 4;
        }
        if (provider_type === 1 && provider === 3) {
          providerStates.streamline = 2;
          providerStates.geotab = 4;
          providerStates.samsara = 4;
        }
      }
    }
    setProviderStates(providerStates);

    //Check whether any one telematics device is connected
    const isTelematicsConnected =
      providerStates.samsara === 2 ||
      providerStates.geotab === 2 ||
      providerStates.streamline === 2 ||
      providerStates.verizonConnect === 2;
    setTelematicsConnected(isTelematicsConnected);
  }, [integrationsData]);

  const handleSamsaraClick = () => {
    if (tag === "free-user" || tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    if (providerStates.samsara === 1) {
      setSamsaraStep(1);
    }

    if (providerStates.samsara === 2) {
      setSamsaraStep(6);
    }
  };

  const handleGeotabClick = () => {
    if (tag === "free-user" || tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    if (providerStates.geotab === 1) {
      setGeotabStep(1);
    }

    if (providerStates.geotab === 2) {
      setGeotabStep(6);
    }
  };

  const handleSteamlineClick = () => {
    if (tag === "free-user" || tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    if (providerStates.streamline === 1) {
      setStreamlineStep(1);
    }

    if (providerStates.streamline === 2) {
      setStreamlineStep(6);
    }
  };

  // const updateProviderState = (provider, state) => {
  //   setProviderStates((prev) => ({ ...prev, [provider]: state }));
  //   if (state === 2 && !telematicsConnected) {
  //     setTelematicsConnected(true);
  //   }
  // };

  return (
    <>
      {showSnackbar && (
        <Snackbar
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {samsaraStep !== 0 && (
        <Samsara
          step={samsaraStep}
          setStep={setSamsaraStep}
          showSnackbar={true}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {geotabStep !== 0 && (
        <Geotab
          step={geotabStep}
          setStep={setGeotabStep}
          showSnackbar={true}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {streamlineStep !== 0 && (
        <Streamline
          step={streamlineStep}
          setStep={setStreamlineStep}
          showSnackbar={true}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          <Col>
            <ColumnHeading>{t("integrations")}</ColumnHeading>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Card>
              <CardBody formcard="true">
                {/* Telematics */}
                <Row>
                  <Col className="d-flex flex-column gap-1">
                    <h4>{t("telematics")}</h4>
                    <Desc>{t("by_integrating_telematics_desc")}</Desc>
                    <div
                      className="d-flex flex-column"
                      style={{
                        border: `1px solid ${colorLightGray}`,
                        width: "min-content",
                        borderRadius: "5px",
                      }}
                    >
                      {!telematicsConnected && (
                        <div
                          className="p-2"
                          style={{
                            borderBottom: `1px solid ${colorLightGray}`,
                            background: colorYellowNew,
                          }}
                        >
                          <p>{t("not_connected_to_any_device")}</p>
                          {/* <p>Click on a device to connect. </p> */}
                        </div>
                      )}
                      <div className="d-flex gap-5 p-3">
                        <SingleProvider
                          providerName={"Samsara"}
                          providerState={providerStates.samsara}
                          providerIcon={samsaraIcon}
                          handleClick={handleSamsaraClick}
                          //updateProviderState={updateProviderState}
                        />
                        <SingleProvider
                          providerName={"GeoTab"}
                          providerState={providerStates.geotab}
                          providerIcon={geoTabIcon}
                          handleClick={handleGeotabClick}
                        />
                        {/* <SingleProvider
                          providerName={"Streamline"}
                          providerState={providerStates.streamline}
                          providerIcon={streamlineIcon}
                          handleClick={handleSteamlineClick}
                        /> */}
                        <SingleProvider
                          providerName={"Verizon connect"}
                          providerState={providerStates.verizonConnect}
                          providerIcon={verizonConnectIcon}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* Fuel Cards */}
                <Row className="mt-4">
                  <Col className="d-flex flex-column gap-1">
                    <h4>{t("fuel_cards")}</h4>
                    {/* <Desc>
                      By integrating telematics, you will be able to pull in
                      mileage and fuel codes into Simply Fleet
                    </Desc> */}
                    <div
                      style={{
                        border: `1px solid ${colorLightGray}`,
                        borderRadius: "5px",
                        width: "min-content",
                      }}
                      className="d-flex"
                    >
                      {/* <div
                        className="d-flex flex-column p-3 gap-2"
                        style={{ borderRight: `1px solid ${colorLightGray}` }}
                      >
                        <p>Not connected to any device.</p>
                        <p>Click on a device to connect. </p>
                      </div> */}
                      <div className="d-flex gap-5 p-3">
                        <SingleProvider
                          providerName={"WEX"}
                          providerState={3}
                          providerIcon={wexIcon}
                        />
                        <SingleProvider
                          providerName={"ARCO"}
                          providerState={3}
                          providerIcon={arcoIcon}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Desc>
                      {t("integration_reach_out_to_sf_msg")}{" "}
                      <a href="mailto:support@simply-fleet.com">
                        support@simply-fleet.com
                      </a>
                    </Desc>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Integrations;

//styles
export const IntegrationsImg = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
`;

