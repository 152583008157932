import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    updateVehiclesList: (state, action) => {
      state.list = action.payload;
    },
    clearVehicles: (state, action) => {
      state.list = [];
    },
  },
});

export const { updateVehiclesList, clearVehicles } = vehiclesSlice.actions;
export default vehiclesSlice.reducer;
