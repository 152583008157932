import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { colorBackgroundBody } from "@/utils/palette";
import { paddingLeft } from "@/utils/directions";
import Layout from "../../../Layout/index";

import Dashboard from "../../../../components/Dashboard/index";
import UserManagement from "../../../../components/UserManagement/index";
import Vehicles from "../../../../components/Vehicles/index";
import DailyTimeSheet from "../../../../components/DailyTimeSheet/index";
import DailyMileage from "../../../../components/DailyMileage/index";
import FillUps from "../../../../components/FillUps/index";
import Services from "../../../../components/Services/index";
import OtherExpenses from "../../../../components/OtherExpenses/index";
import Trips from "../../../../components/Trips/index";
import Billing from "../../../../components/Billing/index";
import VehicleInspection from "../../../../components/VehicleInspection";
import Settings from "../../../../components/Settings";
import Reminders from "../../../../components/Reminders";
import RenewalsForm from "../../../../components/Reminders/Renewals/RenewalsForm";
import MaintenanceRemindersForm from "../../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import Issues from "../../../../components/Issues";
import { createContext } from "react";
import { useState } from "react";
import WorkOrders from "../../../../components/WorkOrders";
import { useAuth } from "../../../../contexts/AuthContext";
import { auth } from "../../../../api/firebase";
import { UserNameProvider } from "../../../../contexts/UserNameContext";
import { useLocation } from "react-router";
import InfoPopup from "../../../../shared/components/InfoPopup";
import { Trans, useTranslation } from "react-i18next";
import { colorBlue } from "../../../../utils/palette";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { postApi } from "../../../../api/getUser";
import RatingPopup from "../../../../shared/components/RatingPopup";
import { useSelector } from "react-redux";
import FaultCodes from "../../../../components/FaultCodes";

export const DeletedContext = createContext(null);
export default () => {
  const [deleted, setDeleted] = useState(false);
  const toggleDeleted = () => setDeleted((prev) => !prev);
  const [reload, setReload] = useState(false);
  const toggleReload = () => setReload((prev) => !prev);
  const [loading, setLoading] = useState(false);
  const setLoadingToTrue = () => setLoading(true);
  const setLoadingToFalse = () => setLoading(false);

  const {
    isLoggedIn,
    dummyRecordsPopup,
    setDummyRecordsPopup,
    tag,
    showRatingPopup,
    showErrPopup,
    setShowErrPopup,
  } = useAuth();
  const currentUser = auth.currentUser;
  const { pathname } = useLocation();
  const { t } = useTranslation("common");
  const { status } = useSelector((state) => state.filter);

  //Effect to show global api error popup if status fails
  useEffect(() => {
    if (status === "failed") {
      setShowErrPopup(true);
    }
  }, [status]);

  useEffect(() => {
    if (
      !dummyRecordsPopup.show &&
      dummyRecordsPopup.signUp &&
      !pathname.includes("users/addnew")
    ) {
      setDummyRecordsPopup({ show: true, signUp: true });
    }
  }, [pathname]);

  // useEffect(() => {
  //   const existingScript = document.getElementById("freshChat");
  //   const tagsScript = document.getElementById("freshChatTags");
  //   console.log(existingScript);
  //   console.log(tagsScript);
  //   document.head.removeChild(existingScript);
  //   console.log(existingScript);
  // }, []);

  // useEffect(() => {
  //   loadChatApp();
  // }, []);

  //console.log("script", document.getElementById("freshChat"));

  //const [tag, setTag] = useState("");
  //let tag = "";
  // useEffect(() => {
  //   (async () => {
  //     if (isLoggedIn) {
  //       let tag = "free-user";
  //       const subRes = await postApi(
  //         {
  //           querystring: "get_subscription_status",
  //         },
  //         "commonNew"
  //       );
  //       if (subRes.success) {
  //         const { subscription_data } = subRes;
  //         const { subscription_status, product } = subscription_data;
  //         console.log(subscription_status, product);
  //         if (subscription_data != "Email not found") {
  //           if (subscription_status == "paid" && product == 5) {
  //             tag = "essential-user";
  //           } else if (subscription_status == "paid") {
  //             tag = "advanced-user";
  //           } else {
  //             tag = "free-user";
  //           }
  //         }

  //         setTag(tag);
  //       }
  //     }
  //   })();
  // }, [isLoggedIn]);

  // useEffect(() => {
  //   if (tag) {
  //     loadScripts();
  //   }
  // }, [tag]);

  // const loadChatApp = () => {
  //   const existingScript = document.getElementById("freshChat");
  //   console.log("existingScript", existingScript);
  //   if (!existingScript) {
  //     const script = document.createElement("script");
  //     script.src = "https://mobifolio.slack.com//fw-cdn.com/2480920/3044936.js";
  //     script.id = "freshChat";
  //     document.head.appendChild(script);
  //     script.onload = () => {
  //       window.fcWidgetMessengerConfig = {
  //         tags: ["advanced-user"],
  //       };
  //     };
  //   }
  //   //if (existingScript) ();
  // };

  const HelmetComp = ({ tag }) => {
    return (
      <div>
        {tag ? (
          <Helmet>
            <script>
              {`window.fcWidgetMessengerConfig = {
                tags: ["${tag}"],
              }`}
            </script>
            <script
              src="//fw-cdn.com/2480920/3044936.js"
              id="freshChat"
              chat="true"
            ></script>
          </Helmet>
        ) : (
          <Helmet></Helmet>
        )}
      </div>
    );
  };

  const loadScripts = () => (tag ? <HelmetComp tag={tag} /> : null);

  const redirect = () => {
    const logout = localStorage.getItem("logout");
    if (logout === "true") {
      return <Redirect push state={logout === "true"} to="/404" />;
    }
  };

  return (
    <>
      {redirect()}
      {isLoggedIn && (
        <>
          {showRatingPopup && <RatingPopup />}
          {/* {showErrPopup && (
            <InfoPopup
              showPopup={showErrPopup}
              setShowPopup={setShowErrPopup}
              message={t("listing_err_msg")}
              icon={{ className: "fi fi-rr-triangle-warning", color: "red" }}
              actionIcon={{
                handler: () => {
                  location.reload();
                },
                label: t("refresh"),
              }}
              onClose={() => location.reload()}
            />
          )} */}
          <UserNameProvider>
            {tag && <HelmetComp tag={tag} />}
            {/* {tag && loadScripts()} */}
            <div>
              {dummyRecordsPopup.show && (
                <InfoPopup
                  message={
                    <Trans i18nKey="dummy_records_message" t={t}></Trans>
                  }
                  showPopup={dummyRecordsPopup.show}
                  setShowPopup={() =>
                    setDummyRecordsPopup({ show: false, signUp: false })
                  }
                  icon={{
                    className: "fi fi-rr-truck-moving",
                    color: colorBlue,
                  }}
                  actionIcon={{
                    label: t("okay"),
                    handler: () =>
                      setDummyRecordsPopup({ show: false, signUp: false }),
                  }}
                />
              )}
              <Layout />
              {/* <Layout /> */}
              <DeletedContext.Provider
                value={{
                  deleted,
                  toggleDeleted,
                  reload,
                  toggleReload,
                  loading,
                  setLoadingToFalse,
                  setLoadingToTrue,
                }}
              >
                <ContainerWrap>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/users" component={UserManagement} />
                  <Route path="/vehicles" component={Vehicles} />
                  <Route path="/dailytimesheet" component={DailyTimeSheet} />
                  <Route path="/dailymileage" component={DailyMileage} />
                  <Route path="/fillups" component={FillUps} />
                  <Route path="/services" component={Services} />
                  <Route path="/expenses" component={OtherExpenses} />
                  <Route path="/reminders" component={Reminders} />
                  <Route path="/trips" component={Trips} />
                  <Route path="/inspection" component={VehicleInspection} />
                  <Route path="/billing" component={Billing} />
                  <Route path="/setup" component={Settings} />
                  <Route path="/faultcodes" component={FaultCodes} />
                  {/* <Route path="/faq" component={FAQ} />
                <Route path="/demo" component={DemoVideo} />
                <Route path="/feedback" component={Feedback} />
                <Route path="/support" component={ContactSupport} /> */}
                  {/* <Route path="/account" component={Account} /> */}
                  <Route
                    path="/addmaintenancereminder"
                    component={MaintenanceRemindersForm}
                  />
                  <Route path="/addrenewal" component={RenewalsForm} />
                  <Route path="/issues" component={Issues} />
                  <Route path="/workorders" component={WorkOrders} />

                  {/* <Route exact path="/issues" component={IssuesTable} /> */}
                  {/* <Route exact path="/issues/addnew" component={IssuesForm} /> */}
                  {/* <Route path="/addinspectiontask" component={InspectionTasksForm} /> */}
                  {/* <Route path="/addinspectionform" component={InspectionFormsForm} /> */}
                </ContainerWrap>
              </DeletedContext.Provider>
            </div>
          </UserNameProvider>
        </>
      )}
    </>
  );
};

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 75px; //Changed from 85 to 75
  height: 100%;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 800px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 800px) {
    padding-top: 150px;
  }
`;

// endregion
