import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AddFormButton,
  ColumnHeading,
  StatusContainer,
} from "../../shared/components/TableElements";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import { useEffect } from "react";
import getUnixTime from "date-fns/getUnixTime";
import { useMemo } from "react";
import DataReactTable from "../DataTable/components/DataReactTable";
import {
  initialContainsData,
  initialDateData,
  initialRangeData,
} from "../../shared/constants/filterData";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import { useContext } from "react";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import { VehicleStatus } from "../Vehicles/VehiclesTable";
import { useAuth } from "../../contexts/AuthContext";
import { putApi } from "../../api/getUser";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import { daysHoursMinutes, getDistanceUnit } from "../../shared/helpers";
import { LinkSpan } from "../../shared/components/form/FormElements";
import ExplorePopup from "../../shared/components/ExplorePopup";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const IssuesTable = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.filter);
  const rowData = useSelector((state) => state.filter.rowData);
  const deletedData = useSelector((state) => state.filter.deletedData);
  const optionValues = useSelector((state) => state.filter.values);
  const columns = useSelector((state) => state.advancedFilter.issues);

  const { roleId, orgDate } = useSelector((state) => state.user);

  const userData = useSelector((state) => state.users.list);

  const location = useLocation();
  //const fromCancel = location.state?.fromCancel;

  const { pathname } = location;

  const { loading, setLoadingToFalse, setLoadingToTrue } =
    useContext(DeletedContext);

  const { isLoggedIn, setLoading, reload, setReload } = useAuth();

  const { distance } = useSelector((state) => state.units);
  const { minDate } = useSelector((state) => state.columns);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "get_all_issues_data",
    sortIndex: 6,
  });

  // //On initial load, send update initial data
  // useEffect(() => {
  //   if (
  //     pathname.includes("issues") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&&
  //     //(!fromCancel || status === "initial")
  //   ) {
  //     (async () => {
  //       setLoading(true);
  //       const startDate = 0; //getUnixTime(subDays(new Date(), 6));
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "issues",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");

  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
  //         index = colOrderUI.findIndex((col) => col === 6);
  //       }

  //       dispatch(
  //         updateInitialData({
  //           query: "get_all_issues_data",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );
  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",

  //         querystring: "get_all_issues_data",
  //       };

  //       dispatch(fetchData({ ...initialData, ...columns })); //Destructure and add columns similar to date
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // //Fetch data on every filter change expect for start and set start to 0
  // useEffect(() => {
  //   if (
  //     pathname.includes("issues") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     !reload &&
  //     querystring === "get_all_issues_data"
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns, start: 0 })); //Destructure and add columns similar to date
  //     //}
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   //querystring,
  //   columns,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (pathname.includes("issues") && !firstTimeLoad) {
  //     dispatch(fetchData({ ...data, ...columns })); //Destructure and add columns similar to date
  //   }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   console.log("reload", reload);
  //   if (
  //     pathname.includes("issues") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "get_all_issues_data" &&
  //     reload === "issues"
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns, start: 0 }));
  //     //setLoading(true);
  //     setReload("");
  //   }
  // }, [reload]);

  const IssuesKPIArray = [
    {
      id: 0,
      key: "openIssues",
      name: t("open_high_priority_issues"),
      value: optionValues?.openIssues ? +optionValues.openIssues : NATEXT,
      //units: "Ltr",
      //icon: "gasStation",
    },
    {
      id: 1,
      key: "newIssues",
      name: t("new_issues"),
      value: optionValues?.newIssues ? +optionValues.newIssues : NATEXT,
      //units: "INR",
      //icon: "currency",
    },
  ];

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        //width: getColumnWidth("name", "Vehicle"),
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("issue"),
        accessor: "issue",
        accessorId: "task_name",
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
      },
      {
        id: 3,
        Header: t("desc"),
        accessor: "issue_desc",
        Cell: ({ value }) => (value ? value : NATEXT),
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
        accessorId: "comments",
      },
      {
        id: 4,
        Header: t("status"),
        accessor: "status",
        Cell: ({ value }) => <IssueStatus status={value} />,
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
      },
      {
        id: 5,
        Header: t("priority"),
        accessor: "priority",
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
        Cell: ({ value }) => getIssuePriorityText(value), //value == 1 ? "Low" : value == 2 ? "Medium" : value == 3 ? "High" : "",
      },

      {
        id: 6,
        Header: t("reported_date"),
        accessor: "reported_date",
        disableCol: true,
        //disableFilters: true,
        //width: getColumnWidth("fillup_date", "Date"),
        Cell: ({ value }) =>
          new Date(value * 1000).toLocaleDateString(navigator.language, {
            day: "numeric",
            year: "numeric",
            month: "short",
          }),
      },
      // {
      //   id: 7,
      //   Header: t("work_order"),
      //   accessor: "work_order",

      // },

      {
        id: 7,
        Header: t("reported_by"),
        accessor: "user_name",
        // Cell: ({ value }) => {
        //   const user = userData?.find((u) => u.user_id === value);
        //   return user?.label;
        // },
      },
      {
        id: 8,
        Header: t("vehicle_status"),
        accessor: "veh_id",
        Cell: ({ row }) => <VehicleStatus rowData={row?.original} />,
      },
      {
        id: 9,
        Header: t("odometer"),
        accessor: "odo",
        Cell: ({ row }) =>
          `${row.original.odo} ${getDistanceUnit(
            row?.original?.primary_meter === "Hours" ? "Hours" : distance
          )}`,
      },
      // {
      //   id: 10,
      //   Header: t("close_date"),
      //   accessor: "close_date",
      //   Cell: ({ value }) =>
      //     value
      //       ? new Date(value * 1000).toLocaleDateString(navigator.language, {
      //           day: "numeric",
      //           year: "numeric",
      //           month: "short",
      //         })
      //       : NATEXT,
      // },
      {
        id: 10,
        Header: t("time_to_close"),
        accessor: "close_date",
        Cell: ({ row }) => {
          let timeToClose = NATEXT;
          const { reported_date, close_date } = row?.original;
          if (reported_date && close_date && close_date > reported_date) {
            timeToClose = daysHoursMinutes(close_date - reported_date).display;
          }
          return timeToClose;
        },
      },
      {
        id: 11,
        Header: t("source"),
        accessor: "source",
      },
      {
        id: 12,
        Header: t("notes_tv"),
        accessor: (row) => (row.comments ? row.comments : NATEXT),
        accessorId: "comments",
        //disableFilters: true,
        //width: columnWidth,
      },
    ],
    [distance]
  );

  const { defaultContainsData, defaultRangeData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      odo: {
        column: "odo",
        name: t("odometer"),
        ...defaultRangeData,
      },
      issue: {
        column: "issue",
        name: t("issue"),
        ...defaultContainsData,
      },
      issueDesc: {
        column: "issueDesc",
        name: t("issue_desc"),
        ...defaultContainsData,
      },
      reportedBy: {
        column: "reportedBy",
        name: t("reported_by"),
        ...defaultContainsData,
      },
      date: {
        column: "date",
        name: t("reported_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      closeDate: {
        column: "closeDate",
        name: t("close_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
        mode: "custom",
      },
      timeToClose: {
        column: "timeToClose",
        name: t("time_to_close"),
        ...defaultRangeData,
      },
    }),
    [minDate]
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/I1OAAEMW"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          {/* <Col sm={10}>
          <ColumnHeading>{t("issues")}</ColumnHeading>
        </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("issues")}</ColumnHeading>
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            {roleId !== 4 && (
              <Link to={"/issues/addnew"}>
                <AddFormButton>{t("add_issue")}</AddFormButton>
              </Link>
            )}
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={IssuesKPIArray}
            initialData={initialData}
            //defaultColumns={defaultColumns}
          />
        </Row>
      </Container>
    </>
  );
};

export default IssuesTable;

export const IssueStatus = ({ status }) => {
  const { t } = useTranslation("common");
  let issueData = { text: "", bg: "" };

  if (status === "new") {
    issueData.text = t("new");
    issueData.bg = "#9FC5F8";
  } else if (status == 0) {
    issueData.text = t("drawer_open");
    issueData.bg = "#9FC5F8";
  } else if (status == 1) {
    issueData.text = t("in_progress");
    issueData.bg = "#FFE599";
  } else if (status == 2) {
    issueData.text = t("closed");
    issueData.bg = "#B6D7A8";
  }

  return (
    <StatusContainer background={issueData.bg}>
      <p>{issueData.text}</p>
    </StatusContainer>
  );
};

export const getIssuePriorityText = (priority) => {
  //console.log("getIssuePriorityText called");
  const { t } = useTranslation("common");
  switch (priority) {
    case 0:
      return t("not_set");
    case 1:
      return t("low");
    case 2:
      return t("med");
    case 3:
      return t("high");
    default:
      return t("not_set");
  }
};
