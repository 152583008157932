import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Groups from "./Groups";
import OperatorSettings from "./OperatorSettings";
import OrganizationSettings from "./OrganizationSettings";
import Integrations from "./Integrations";

export default () => (
  <Switch>
    <Route path="/setup/groups" component={Groups} />
    <Route path="/setup/operator" component={OperatorSettings} />
    <Route path="/setup/organization" component={OrganizationSettings} />
    <Route path="/setup/integrations" component={Integrations} />
    {/* <Route path="/setup/vehicles" component={Vehicles} /> */}
    {/* <Route path="/setup/users" component={UserManagement} /> */}
    {/* <Route path="*" component={NotFound404} /> */}
    <Redirect to="/" />
  </Switch>
);
