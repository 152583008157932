import React, { useState } from "react";
import {
  colorBackgroundBody,
  colorBlue,
  colorLightGray,
  colorTextNew,
  colorYellowNew,
} from "../../utils/palette";
import styled from "styled-components";
import {
  FormContainer,
  FormGroupField,
  FormGroupLabel,
  FormItem,
  LinkSpan,
} from "../../shared/components/form/FormElements";
import { formInputStyles } from "../../shared/constants/styles";
import {
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { auth } from "../../api/firebase";
import { Col, Container, Row } from "react-bootstrap";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { postApi } from "../../api/getUser";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect } from "react";
import { emailPattern } from "../../shared/helpers";
//import googleLogo from "../../../src/shared/img/googleLogo.svg";
import { ReactComponent as GoogleLogo } from "../../../src/shared/img/googleLogo.svg";

const LoginForm = ({
  updataPWData,
  setUpdatePWData,
  activeKey,
  setActiveKey,
  forgotPWData,
  setForgotPWData,
}) => {
  const history = useHistory();
  const { t } = useTranslation("common");
  const { isLoggedOut, setIsLoggedIn, setSignUp, isLoggedIn } = useAuth();
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const [googleBtnLoading, setGoogleBtnLoading] = useState(false);
  const [appleBtnLoading, setAppleBtnLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const email = watch("email");
  const password = watch("password");
  const logout = JSON.parse(localStorage.getItem("logout"));

  useEffect(() => {
    // console.log(auth?.currentUser);
    // if (auth?.currentUser) {
    //   setIsLoggedIn(true);
    //   history.push("/issues");
    // } else {
    //   setSignUp(true);
    // }
    if (logout) {
      setSignUp(true);
    }
  }, []);

  const signIn = async (loginMethod) => {
    let emailVal =
      loginMethod === "google" || loginMethod === "apple"
        ? auth.currentUser.email
        : email;
    setSignUp(true);
    const apiData = {
      querystring: "checkuseremail",
      email: emailVal ? emailVal : "",
      source: "web",
      last_updated: "0",
      org_last_updated: "0",
      allusers_last_updated: "454545454545454545",
    };
    const checkUserEmailRes = await postApi(apiData, "user", false);
    const { user_data } = checkUserEmailRes;
    if (checkUserEmailRes.success) {
      let notificationsApiData = {
        querystring: "createnotifications",
        pagesource: "login",
      };
      postApi(notificationsApiData, "commonNew");
      setSignUp(false);
      localStorage.setItem("logout", false);
      const { role_id } = user_data[0];
      if (role_id === 4) {
        history.push("/workorders");
      } else {
        history.push("/dashboard");
      }
    } else {
      let errorMessage = "";
      if (user_data === "email not found") {
        errorMessage = t("email_not_found_msg");
        setError(
          "email",
          {
            type: user_data,
            message: errorMessage,
          },
          { shouldFocus: true }
        );

        if (loginMethod === "email") {
          setLoginBtnLoading(false);
        } else if (loginMethod === "google") {
          setGoogleBtnLoading(false);
        } else if (loginMethod === "apple") {
          setAppleBtnLoading(false);
        }

        return;
      } else if (user_data === "No_auth") {
        errorMessage = t("not_authorized_to_login_err_msg");
        setError(
          "email",
          {
            type: user_data,
            message: errorMessage,
          },
          { shouldFocus: true }
        );

        if (loginMethod === "email") {
          setLoginBtnLoading(false);
        } else if (loginMethod === "google") {
          setGoogleBtnLoading(false);
        } else if (loginMethod === "apple") {
          setAppleBtnLoading(false);
        }
        return;
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      // provider.setCustomParameters({
      //   prompt: "select_account",
      // });
      //provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      setGoogleBtnLoading(true);
      //const auth = getAuth();
      await signInWithPopup(auth, provider);
      await signIn("google");
    } catch (error) {
      console.log(error);
      setGoogleBtnLoading(false);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      // provider.setCustomParameters({
      //   prompt: "select_account",
      // });
      // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      setAppleBtnLoading(true);
      //const auth = getAuth();
      await signInWithPopup(auth, provider);
      await signIn("apple");
    } catch (error) {
      console.log(error);
      setAppleBtnLoading(false);
    }
  };

  const handleForgotPassword = () => {
    if (email && email?.match(emailPattern)) {
      setForgotPWData({ email: email });
    } else {
      setForgotPWData({ email: "" });
    }
  };

  const onSubmit = async () => {
    try {
      setLoginBtnLoading(true);
      const userCred = await signInWithEmailAndPassword(
        auth,
        email?.toLocaleLowerCase(),
        password
      );
      const user = userCred?.user;

      if (user) {
        let { email, displayName } = user;
        if (displayName !== "null" && displayName !== null) {
          displayName = displayName.replaceAll(/\s/g, "");
          displayName = displayName + "@simplyfleet";
          displayName = displayName.toLowerCase();
          if (password === displayName) {
            setUpdatePWData({
              email: email,
              existingPassword: password,
              signIn: signIn,
            });
          } else {
            await signIn("email");
          }
        } else {
          await signIn("email");
        }
      }
    } catch (error) {
      const errorCode = error.code;
      let errorMessage = error.message;
      if (error.code == "auth/wrong-password") {
        errorMessage = t("invalid_pw_err_msg");
      } else {
        errorMessage = error.code;
      }
      setError(
        "password",
        {
          type: "invalid",
          message: errorMessage,
        },
        { shouldFocus: true }
      );
      setLoginBtnLoading(false);
    }
  };

  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)} id="login-form">
        <Container id="login-container">
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="email-label">
                {t("email_address")}
              </FormGroupLabel>
              <FormItem disabled={false} id="email-div">
                <FormField
                  name="email"
                  id="email"
                  control={control}
                  component="input"
                  type="email"
                  defaultValue=""
                  placeholder={t("email_address")}
                  style={formInputStyles}
                  disabled={false}
                  rules={{
                    required: t("required_err"),
                    pattern: {
                      value: emailPattern,
                      message: t("invalid_email_err_msg"),
                    },
                  }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.email && <Error error={errors.email.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="password-label">
                {t("password")}
              </FormGroupLabel>
              <FormItem disabled={false} id="password-div">
                <FormField
                  name="password"
                  id="password"
                  control={control}
                  component="input"
                  type="password"
                  defaultValue=""
                  placeholder={t("password")}
                  style={formInputStyles}
                  disabled={false}
                  rules={{
                    required: t("required_err"),
                    minLength: {
                      value: 6,
                      message: t("invalid_pwd"),
                    },
                  }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.password && <Error error={errors.password.message} />}
            </Col>
          </Row>

          <Row className="pt-4 d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              <AddFormButton
                //onClick={handleLogin}
                style={{
                  width: "-webkit-fill-available",
                  height: "40px",
                  background: "#003f7d",
                }}
                disabled={loginBtnLoading}
                id="login-btn"
              >
                {loginBtnLoading ? <CustomLoader /> : t("login")}
              </AddFormButton>
            </Col>
          </Row>
          <Row className="pt-2 d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-start">
              <LinkSpan onClick={handleForgotPassword} id="forgot-password">
                {t("forgot_password")}
              </LinkSpan>
            </Col>
          </Row>
          <Row className="py-2 d-flex justify-content-center">
            <Col md={8}>
              <Divider>{t("or_caps")}</Divider>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              {/* <FilterButton
                type="button"
                id="sign-in-with-google"
                onClick={handleGoogleLogin}
                disabled={googleBtnLoading}
                style={{
                  width: "-webkit-fill-available",
                  height: "34px",
                  color: "#003f7d",
                }}
              >
                Sign In with Google
              </FilterButton> */}
              {/* <GoogleLogin
                //width={"300px"}
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              /> */}
              <GoogleSignInButton
                type="button"
                id="sign-in-with-google"
                onClick={handleGoogleLogin}
                disabled={googleBtnLoading}
              >
                <GoogleLogo width={18} height={18} />
                <span>{t("google_signin")}</span>
              </GoogleSignInButton>
            </Col>
          </Row>
          <Row className="pt-4 d-flex justify-content-center">
            <Col md={8} className="d-flex justify-content-center">
              <AppleSignInButton
                type="button"
                id="sign-in-with-apple"
                onClick={handleAppleLogin}
                disabled={appleBtnLoading}
                // style={{
                //   width: "-webkit-fill-available",
                //   height: "34px",
                //   color: "#003f7d",
                // }}
              >
                <i className="fi fi-brands-apple"></i>
                {/* {appleBtnLoading ? <CustomLoader /> : "Sign In with Apple"} */}
                <span>{t("apple_sign_in")}</span>
              </AppleSignInButton>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pt-2">
            <Col md={8} className="d-flex justify-content-center">
              <div
                className="d-flex align-items-center gap-1"
                id="already-have-an-acc"
              >
                {t("dont_have_acc")}
                <LinkSpan onClick={() => setActiveKey(2)} id="login-span">
                  {t("go_back")}
                </LinkSpan>
              </div>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

export default LoginForm;

//styles

export const AccountWrap = styled.div`
  //height: 100vh;
  //width: 100%;
  //min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background: ${colorBackgroundBody};
`;

export const AccountCard = styled.form`
  padding: 50px;
  max-width: 520px;
  width: 100%;
  background-color: white;

  //   @media screen and (max-width: 520px) {
  //     padding: 35px 30px;
  //   }
`;

export const Divider = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;

  &:before,
  &:after {
    flex: 1;
    content: "";
    padding: 1px;
    background-color: lightgray;
    margin: 0rem 0.1rem;
  }
`;

export const GoogleSignInButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
  font-size: 14px;
  height: 40px !important;
  color: ${colorTextNew};
  height: 32px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  padding: 0 8px;
  margin: 0;
  //min-width: 100px;
  width: -webkit-fill-available;
  white-space: nowrap;
  background: white;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  svg {
    margin-right: 24px;
  }
`;

export const AppleSignInButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
  font-size: 14px;
  height: 40px !important;
  color: ${colorTextNew};
  height: 32px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  padding: 0 8px;
  margin: 0;
  //min-width: 100px;
  width: -webkit-fill-available;
  white-space: nowrap;
  background: black;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  span {
    color: white;
  }

  i {
    color: white;
    width: 18px;
    height: 18px;
    margin-right: 24px;
  }
`;
