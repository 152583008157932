import { Route, Switch } from "react-router";
import IssuesTable from "./IssuesTable";
import IssuesForm from "./IssuesForm";

export default () => (
  <Switch>
    <Route exact path="/issues" component={IssuesTable} />
    <Route exact path="/issues/addnew" component={IssuesForm} />
  </Switch>
);
