import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import simplyFleetLogo from "@/shared/img/simply-fleet-logo.png";

//import { Tabs as TabsWrap } from "@/shared/components/Tabs";

import { Nav, Tab } from "react-bootstrap";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";

import { useTranslation } from "react-i18next";
import { isObjEmpty } from "../../shared/helpers";
import SignUpForm from "./SignUpForm";
import LoginForm from "./LoginForm";
import styled from "styled-components";
import { scrollbarStyles } from "../../utils/palette";
import { JustifyTabs } from "../../shared/components/Tabs";
import SignUpDetailsForm from "./SignUpDetailsForm";
import { auth } from "../../api/firebase";
import { signOut } from "firebase/auth";
import { useAuth } from "../../contexts/AuthContext";
import UpdatePasswordForm from "./UpdatePasswordForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const Login = () => {
  const [signUpMode, setSignUpMode] = useState("email");

  const [signUpWithEmail, setSignUpWithEmail] = useState(false);

  const [updatePWData, setUpdatePWData] = useState(null);
  const [forgotPWData, setForgotPWData] = useState(null);

  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const [activeKey, setActiveKey] = useState("1"); //1 for login and 2 for signup

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (searchParams.has("signup")) {
      setActiveKey("2");
    }
  }, []);

  // useEffect(() => {
  //   if (auth.currentUser) {
  //     signOut(auth);
  //     setIsLoggedIn(false);
  //   }
  // }, []);
  // const redirect = () => {
  //   console.log("isLoggedIn", isLoggedIn);
  //   if (!isLoggedIn) {
  //     return <Redirect push state={!isLoggedIn} to="/login" />;
  //   }
  // };

  return (
    <>
      {/* {redirect()} */}
      {/* {signUpWithEmail && !updatePWData && !forgotPWData && (
        <SignUpDetailsForm
          signUpMode={signUpMode}
          setSignUpMode={setSignUpMode}
          signUpWithEmail={signUpWithEmail}
          setSignUpWithEmail={setSignUpWithEmail}
        />
      )} */}

      <Container
        style={{
          //height: "100vh",
          minWidth: "100%",
          overflowX: "hidden",
          margin: 0,
          padding: 0,
          position: "relative",
          background: "#0d2137",
          display: "flex",
          //height: '200vh',
          flexDirection: "column",
          //gap: 40,
        }}
        className="m-0 p-0"
      >
        <Header />
        <div
          style={{
            //height: window.innerHeight - 90 + "px",
            height: signUpWithEmail ? "auto" : "88vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //width: "-webkit-fill-available",
            background: "#0d2137",
          }}
        >
          {/* <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: window.innerHeight - 160 + "px",
            //marginTop: 40,
            //background: "white",
            //position: "absolute",
            //top: 90,
            width: "-webkit-fill-available",
          }}
        > */}
          {!signUpWithEmail && !updatePWData && !forgotPWData && (
            <JustifyTabs id="login-signup-tabs">
              <Tabs
                signUpMode={signUpMode}
                setSignUpMode={setSignUpMode}
                signUpWithEmail={signUpWithEmail}
                setSignUpWithEmail={setSignUpWithEmail}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                updatePWData={updatePWData}
                setUpdatePWData={setUpdatePWData}
                forgotPWData={forgotPWData}
                setForgotPWData={setForgotPWData}
              />
            </JustifyTabs>
          )}

          {signUpWithEmail && !updatePWData && !forgotPWData && (
            <SignUpDetailsForm
              signUpMode={signUpMode}
              setSignUpMode={setSignUpMode}
              signUpWithEmail={signUpWithEmail}
              setSignUpWithEmail={setSignUpWithEmail}
            />
          )}

          {/* Show Reset Password Data screen */}
          {updatePWData && !forgotPWData && activeKey == 1 && (
            <UpdatePasswordForm
              updatePWData={updatePWData}
              setUpdatePWData={setUpdatePWData}
            />
          )}

          {forgotPWData && !updatePWData && activeKey == 1 && (
            <ForgotPasswordForm
              forgotPWData={forgotPWData}
              setForgotPWData={setForgotPWData}
            />
          )}
          {/* </div> */}
        </div>
      </Container>
    </>
  );
};

export default Login;

const Tabs = ({
  signUpMode,
  setSignUpMode,
  signUpWithEmail,
  setSignUpWithEmail,
  activeKey,
  setActiveKey,
  updatePWData,
  setUpdatePWData,
  forgotPWData,
  setForgotPWData,
}) => {
  const { t } = useTranslation("common");

  return (
    <Tab.Container activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
      <TabsWrap>
        <Nav className="nav-tabs" id="nav-tabs">
          <NavItem
            id="login-nav-item"
            style={{
              background: activeKey == 2 ? "aliceblue" : "none",
              //borderBottom: "1px solid aliceblue",
            }}
          >
            <NavLink eventKey={1}>
              {t("login")}
              {/* {!isObjEmpty(errors) && (
                <i
                  className="fi fi-rr-cross-circle"
                  style={{
                    color: "red",
                    position: "relative",
                    top: "2px",
                  }}
                ></i>
              )} */}
            </NavLink>
          </NavItem>
          <NavItem
            id="signup-nav-item"
            style={{
              background: activeKey == 1 ? "aliceblue" : "none",
              //borderBottom: "1px solid aliceblue",
            }}
          >
            <NavLink eventKey={2}>{t("go_back")}</NavLink>
          </NavItem>
        </Nav>
        {/* )} */}
        <TabContent activeKey={activeKey} id="tab-content">
          <TabPane
            id="login-tab-pane"
            eventKey={1}
            // style={{
            //   height: `${window.innerHeight - 200}px`,
            //   overflow: "auto",
            // }}
          >
            <LoginForm
              updatePWData={updatePWData}
              setUpdatePWData={setUpdatePWData}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              forgotPWData={forgotPWData}
              setForgotPWData={setForgotPWData}
            />
          </TabPane>
          <TabPane eventKey={2} id="signup-tab-pane">
            <SignUpForm
              signUpMode={signUpMode}
              setSignUpMode={setSignUpMode}
              signUpWithEmail={signUpWithEmail}
              setSignUpWithEmail={setSignUpWithEmail}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
            />
          </TabPane>
        </TabContent>
      </TabsWrap>
    </Tab.Container>
  );
};

export const Header = () => {
  return (
    <HeaderContainer
      className="d-flex justify-content-start align-items-center"
      id="simply-fleet-header"
    >
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={simplyFleetLogo}
          alt="simply-fleet-logo"
          width={100}
          height={50}
          style={{ width: "100%" }}
          id="simply-fleet-logo"
        />
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  height: 12vh;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  box-shadow: 0 2px 18px -11px rgba(0, 0, 0, 0.2);
  padding: 0 4rem;
  width: inherit;
`;

const TabContent = styled(Tab.Content)`
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: start;
  //height: 430px;
  height: ${window.innerHeight - 202}px;

  overflow: auto;
  ${scrollbarStyles};
  width: ${window.innerWidth - 800}px;
  //width: 500px;
`;

const TabPane = styled(Tab.Pane)`
  width: inherit;
`;
