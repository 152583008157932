import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router";
import { OptionsMenu } from "../../../shared/components/table/components/MoreOptions/MoreOptions";
import {
  ActionIconContainer,
  CustomLoader,
} from "../../../shared/components/TableElements";
import { getVehicles, post, postApi } from "../../../api/getUser";
import { DeletedContext } from "../../../containers/App/Router/WrappedRoutes";
import PauseConfirmPopup from "./PauseConfirmPopup";
import styled from "styled-components";
import { colorLightGray } from "../../../utils/palette";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import getUnixTime from "date-fns/getUnixTime";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import {
  calculateSinglePauseTime,
  getTimeSinceAcceptance,
  getTotalTime,
} from "..";
import ActionPopup from "../../../shared/components/ActionPopup";
import {
  daysHoursMinutes,
  getDisplayDate,
  getDisplayDateWithTime,
  getDistanceFactorforOdo,
} from "../../../shared/helpers";
import { updateVehiclesList } from "../../../features/vehicles/vehiclesSlice";
import { auth } from "../../../api/firebase";
import InfoPopup from "../../../shared/components/InfoPopup";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";

const WOSidePanelDropdown = ({
  rowData,
  pauses,
  setRightPanelData,
  currentVehicle,
  logs,
  setLogs,
  serviceTasks,
  setServiceTasks,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const optionsRef = useRef();

  const { setReload, tag, fetchTelematicsOdo } = useAuth();
  const { userId, orgId, roleId, userName } = useSelector(
    (state) => state.user
  );
  const { currency, distance } = useSelector((state) => state.units);
  const usersList = useSelector((state) => state.users.list);

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [noBtnLoading, setNoBtnLoading] = useState(false);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const {
    veh_id,
    assigned_to,
    comments,
    create_date,
    created_by,
    due_date,
    start_date,
    end_date,
    invoice_number,
    name,
    odo,
    po_number,
    priority,
    due_selection,
    service_id,
    //service_task_ids,
    status,
    technician_id,
    //total_cost,
    type,
    wo_number,
    wo_img_name,
    tax,
    discount,
    //labor_cost,
    //parts_cost,
    wo_id,
    send_email,
    parts_total,
    labor_total,
    total,
    odo_validation_date,
  } = rowData;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!optionsRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [optionsRef]);

  const [open, setOpen] = useState(false);

  const [showPauseConfirmPopup, setShowPauseConfirmPopup] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [pauseBtnLoading, setPauseBtnLoading] = useState(false);

  //State to maintain reason required during pause
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  //State for loading resume func
  const [resumeLoading, setResumeLoading] = useState(false);

  const handlePauseClick = async () => {
    if (!reason) {
      setReasonError(t("required_err"));
      return;
    } else {
      if (reasonError) {
        setReasonError("");
      }
      setPauseBtnLoading(true);
      const res = await updateWOData(2);
      if (res) {
        const pauseApiData = {
          sync_version: "v2",
          action: 1,
          operation: "create",
          querystring: "addEditWorkOrderPause",
          pause_id: `${veh_id}_${Date.now()}`,
          wo_id: wo_id,
          start_time: getUnixTime(new Date()),
          end_time: 0,
          pause_time: 0,
          reason: reason,
          veh_id: veh_id,
        };
        const response = await postApi(pauseApiData, "workOrder");
        console.log("Pause table response", response);
        if (response.success === 1) {
          //updateSplitButtonDataToPaused();
          setRightPanelData({ show: false, content: "" });
          dispatch(addMsg("Work Order Paused"));
          setReload("work_order");
          setShowPauseConfirmPopup(false);
          setPauseBtnLoading(false);
        }
      }
    }
  };

  //Handler Functions

  //Function to update WO table when pause/resume/close is clicked
  const updateWOData = async (
    workOrderStatus,
    serviceId = "",
    endDate = 0,
    totalTime = 0
  ) => {
    let issueIds = "";

    //Logs
    let stringifiedLogs = "";

    //For old records, logs should remain as null
    if (rowData?.logs === null) {
      stringifiedLogs = null;
    } else {
      let logsData = [];
      if (logs.length > 0) {
        logsData = [...logs];
      }

      //Logs for Pause
      if (workOrderStatus == 2 && reason) {
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: reason,
          user: userName,
          user_id: userId,
          log_type: 3,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });
      }

      //Logs for Resume
      if (workOrderStatus == 1) {
        //const incompletePause = pauses.find((p) => !p.end_time);
        //const reason = incompletePause?.reason;
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: "",
          user: userName,
          user_id: userId,
          log_type: 4,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });

        // setLogs((prev) => {
        //   let temp = [...prev];
        //   temp.unshift({
        //     log_id: `${createdBy.user_id}_${Date.now()}`,
        //     time_stamp: getUnixTime(new Date()),
        //     message: reason,
        //     user: createdBy.name,
        //     user_id: createdBy.user_id,
        //     log_type: 2,
        //   });
        //   return temp;
        // });
      }

      //Logs for Close
      if (workOrderStatus == 3) {
        logsData.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: "",
          user: userName,
          user_id: userId,
          log_type: 5,
          linkedIssues: [], //linkedIssues exists only for log_type 0 (created by user)
        });
      }

      if (logsData.length > 0) {
        const logsApiData = logsData.map((log) => ({
          log_id: log.log_id,
          user_id: log.user_id,
          message: log.message,
          time_stamp: log.time_stamp,
          log_type: log.log_type,
          linkedIssues: log?.linkedIssues ? log?.linkedIssues : [],
        }));

        if (logsApiData.length > 0) {
          stringifiedLogs = JSON.stringify(logsApiData);
        }
      }
    }

    const apiData = {
      action: 2,
      sync_version: "v2",
      source: "web",
      operation: "update",
      querystring: "addEditWorkOrder",
      wo_id: wo_id,
      org_id: orgId,
      veh_id: veh_id,
      odo: odo,
      wo_number: wo_number,
      type: type,
      status: workOrderStatus,
      priority: priority,
      create_date: create_date,
      created_by: created_by,
      due_selection: due_selection,
      due_date: due_date,
      po_number: po_number,
      invoice_number: invoice_number,
      start_date: start_date,
      end_date: endDate,
      // labor_cost:
      //   typeof labor_cost === "string" ? JSON.parse(labor_cost) : labor_cost,
      // parts_cost:
      //   typeof parts_cost === "string" ? JSON.parse(parts_cost) : parts_cost,
      // total_cost:
      //   typeof total_cost === "string" ? JSON.parse(total_cost) : total_cost,
      // service_task_ids:
      //   typeof service_task_ids === "string"
      //     ? JSON.parse(service_task_ids)
      //     : service_task_ids,
      parts_total: +parts_total ? +parts_total : 0,
      labor_total: +labor_total ? +labor_total : 0,
      total: +total ? +total : 0,
      service_id: serviceId,
      tax: tax,
      discount: discount,
      wo_img_name: wo_img_name,
      comments: comments,
      //total_time: 0,
      send_email: send_email,
      technician_id: technician_id,
      issue_ids: issueIds,
      total_time: totalTime,
      logs: stringifiedLogs,
      odo_validation_date: odo_validation_date,
    };

    const response = await postApi(apiData, "workOrder");

    if (response.success === 1) {
      return 1;
    } else {
      return 0;
    }
    //})();
  };

  const handlePauseResume = () => {
    if (status === 1) {
      handlePauseWO();
    } else {
      handleResumeWO();
    }
  };

  const handlePauseWO = () => {
    setShowPauseConfirmPopup(true);
  };

  const handleResumeWO = () => {
    (async () => {
      setResumeLoading(true);
      console.log(pauses);
      const selectedRowPauses = pauses.filter((p) => p.wo_id === wo_id);
      const woRes = await updateWOData(1);

      if (woRes) {
        console.log(selectedRowPauses);
        const currentPauseObj = selectedRowPauses.find((p) => !p.end_time);

        const pauseStartTime = new Date(currentPauseObj.start_time * 1000);
        const pauseEndTime = new Date();
        const endTime = getUnixTime(pauseEndTime);
        console.log(pauseStartTime + "-" + pauseEndTime);
        let schedule = "1,2,3,4,5,6,7";
        let scheduleStartTime = "00:00";
        let scheduleEndTime = "23:59";
        if (technician_id) {
          const selectedTechnician = usersList.find(
            (u) => u.user_id === technician_id
          );

          if (selectedTechnician) {
            const { working_days, starting_time, ending_time } =
              selectedTechnician;
            schedule = working_days ? working_days : "1:2:3:4:5:6:7";
            scheduleStartTime = starting_time
              ? starting_time.slice(0, 5)
              : "00:00";
            scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";
          }
        }

        const pauseTime = calculateSinglePauseTime(
          schedule,
          pauseStartTime,
          pauseEndTime,
          scheduleStartTime,
          scheduleEndTime
        );
        console.log("pause time", pauseTime);

        //Get reqd data for pauseApidData
        const pauseApiData = {
          sync_version: "v2",
          action: 2,
          operation: "update",
          querystring: "addEditWorkOrderPause",
          pause_id: currentPauseObj.pause_id,
          wo_id: wo_id,
          start_time: currentPauseObj.start_time,
          end_time: endTime,
          pause_time: pauseTime,
          reason: currentPauseObj.reason,
          veh_id: currentPauseObj.veh_id,
        };
        const response = await postApi(pauseApiData, "workOrder");
        if (response.success === 1) {
          setResumeLoading(false);
          setRightPanelData({ show: false, content: "" });
          dispatch(addMsg(t("wo_resumed")));
          setReload("work_order");
          //setWorkOrderStatus(1);
          //updateSplitButtonDataToPaused();
        }
        //}
      }
    })();
  };

  const handlePrintWO = () => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    const email = auth.currentUser.email;
    let timeToComplete = 0;
    const { working_days, starting_time, ending_time, assigned_to } = rowData;
    if (rowData?.total_time) {
      timeToComplete = daysHoursMinutes(rowData?.total_time).display;
    } else if (rowData.start_date === 0) {
      timeToComplete = 0;
    } else {
      let schedule = working_days ? working_days : "1:2:3:4:5:6:7";
      let scheduleStartTime = starting_time
        ? starting_time.slice(0, 5)
        : "00:00";
      let scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";

      let endTime = new Date();

      let { display, seconds } = getTimeSinceAcceptance(
        new Date(rowData.start_date * 1000),
        endTime,
        scheduleStartTime,
        scheduleEndTime,
        schedule,
        pauses.filter((p) => p.end_time)
      );
      timeToComplete = display;
    }

    let selectedTechnician = "";
    if (rowData.technician_id) {
      const technician = usersList.find((u) => u.user_id === technician_id);
      const { label, hourly_rate, starting_time, ending_time } = technician;
      if (roleId === 4) {
        selectedTechnician = label;
      } else {
        let rateText = `(${hourly_rate} ${currency}/hour)`;
        if (!hourly_rate) {
          rateText = "";
        }
        selectedTechnician = `${label} ${rateText} - ${
          starting_time && starting_time != "00:00:00"
            ? starting_time?.slice(0, 5)
            : "00:00"
        } - ${
          ending_time && ending_time != "00:00:00"
            ? ending_time?.slice(0, 5)
            : "23:59"
        }`;
      }
    }
    const env = process.env.REACT_APP_ENV === "test" ? "test" : "app";
    const url = `https://s3.us-east-2.amazonaws.com/web.simplyfleet.app/${env}/work_order_report_v2.html?email=${email}&wo_id=${rowData.wo_id}&time_complete=${timeToComplete}&selected_technician=${selectedTechnician}`;
    window.open(url, "_blank", "noreferrer");
  };

  const handleCloseWO = async () => {
    let odoRes = 1;

    const { odo } = rowData;
    if (odo && odo != -1000 && !odo_validation_date) {
      const odoCheckApiData = {
        odo: odo,
        veh_id: veh_id,
        datetocheck: getUnixTime(new Date()),
        querystring: "get_around_dates_v3",
        check_id: wo_id,
      };

      odoRes = await postApi(odoCheckApiData, "commonNew");
      if (!odoRes.success) {
        let { date, odometer, screen_name } = odoRes;
        let dateInSec = Number(date);
        const displayDate = getDisplayDateWithTime(dateInSec);
        //let odoErrMsg = `One of your ${screen_name} records shows an odometer reading of ${odometer}, dated ${displayDate}`;
        let odoErrMsg = t("odo_val_err_msg", {
          date: displayDate,
          screenName: screen_name,
          odometer,
        });
        history.push("/workorders/addnew", {
          mode: "from-workorder-table-error",
          data: { rowData, odoErrorMessage: odoErrMsg },
          type: "edit",
        });
        return;
      }
    }

    if (rowData.odo == -1000 || serviceTasks.length === 0) {
      history.push("/workorders/addnew", {
        mode: "from-workorder-table-error",
        data: { rowData },
        type: "edit",
      });
      return;
    }

    if (odo != -1000 && odoRes && serviceTasks.length > 0) {
      setShowActionPopup(true);
    }
  };

  const updateVehicleToBackInService = async () => {
    //Get vehicle back in service
    const vehicleApiData = {
      veh_id: veh_id,
      secondary_status: 0,
      veh_active: 1,
      operation: "update",
    };
    const vehRes = await postApi(vehicleApiData, "vehicle");

    if (vehRes.success === 1) {
      const vehiclesList = await getVehicles("getallvehicle", t);
      dispatch(updateVehiclesList(vehiclesList));
    } else {
      const { vehicle_data } = vehRes;
      if (vehicle_data === "duplicate_entry") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_err"),
        }));
      } else if (vehicle_data === "vehicle_limit_reached") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("vehicle_limit_reached_err"),
        }));
      } else if (vehicle_data === "trail_vehicle_limit_reached") {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("trail_vehicle_limit_reached_err"),
        }));
      }
      setVehicleUpdErr(true);
      setShowActionPopup(false);
    }

    return vehRes.success;
  };

  const closeWorkOrder = async () => {
    const serviceId = `${veh_id}_${Date.now()}`;
    let endDate = new Date();
    const endDateUnix = getUnixTime(endDate);

    let schedule = "1:2:3:4:5:6:7";
    let scheduleStartTime = "00:00";
    let scheduleEndTime = "23:59";
    if (technician_id) {
      const selectedTechnician = usersList.find(
        (u) => u.user_id === technician_id
      );
      if (selectedTechnician) {
        const { working_days, starting_time, ending_time } = selectedTechnician;
        schedule = working_days ? working_days : "1:2:3:4:5:6:7";
        scheduleStartTime = starting_time ? starting_time.slice(0, 5) : "00:00";
        scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";
      }
    }

    const selectedRowPauses = pauses.filter((p) => p.wo_id === wo_id);

    const incompletePause = selectedRowPauses.find((p) => !p.end_time);
    let endTime = endDate;
    if (incompletePause) {
      endTime = new Date(incompletePause.start_time * 1000);
    }

    const totalTime = getTimeSinceAcceptance(
      new Date(start_date * 1000),
      endTime,
      scheduleStartTime,
      scheduleEndTime,
      schedule,
      selectedRowPauses
    ).seconds;

    const res = await updateWOData(3, serviceId, endDateUnix, totalTime);

    //Odometer of new service created should be max of vehicle maxOdo and odo from telematics
    const distanceFactor = getDistanceFactorforOdo(
      currentVehicle?.primary_meter,
      distance
    );

    //Fetch max odo
    let odoFromTelematics = 0;
    let maxOdo = 0;
    let odoVal = 0;
    const latestDate = +endDateUnix + 1;
    const maxOdoRes = await postApi(
      {
        querystring: "getOdometerFromDate",
        sub_querystring: "",
        veh_id: veh_id,
        filter_date_start: 0,
        filter_date_end: latestDate, //getUnixTime(new Date()),
      },
      "dashboard"
    );

    if (maxOdoRes.success) {
      let [nextOdo, lastOdo] = maxOdoRes.odohint_data;
      if (lastOdo) {
        //Convert to vehicle unit
        lastOdo = lastOdo / distanceFactor;
        maxOdo = lastOdo;
      }
    }

    //Fetch odo from telematics
    if (fetchTelematicsOdo && currentVehicle.provider_type_1) {
      const odoApiData = {
        querystring: "get_current_obd_odometer",
        veh_id: veh_id,
        provider_type: 1,
      };
      const odoRes = await post("integrations", odoApiData);
      if (odoRes.success) {
        odoFromTelematics = +odoRes.data.odometer_in_meters;
      }
    }

    odoVal = Math.max(maxOdo, odoFromTelematics);
    if (odoVal) {
      //Convert back to org unit
      odoVal = odoVal * distanceFactor;
      odoVal = +odoVal.toFixed(2);
    }

    if (res) {
      const serviceApiData = {
        querystring: "addEditService",
        action: 1,
        source: "web",
        operation: "create",
        sync_version: "v2",
        service_id: serviceId,
        veh_id: veh_id,
        user_id: created_by,
        service_date: endDateUnix,
        odo: odoVal,
        odo_unit: currentVehicle.primaryMeter,
        currency: currency ? currency : "",
        // labor_cost:
        //   typeof labor_cost === "string" ? JSON.parse(labor_cost) : labor_cost,
        // parts_cost:
        //   typeof parts_cost === "string" ? JSON.parse(parts_cost) : parts_cost,
        // total_cost:
        //   typeof total_cost === "string" ? JSON.parse(total_cost) : total_cost,
        // service_task_ids:
        //   typeof service_task_ids === "string"
        //     ? JSON.parse(service_task_ids)
        //     : service_task_ids,
        parts_total: +parts_total ? +parts_total : 0,
        labor_total: +labor_total ? +labor_total : 0,
        total: +total ? +total : 0,
        tax: tax,
        discount: discount,
        operator_userid: technician_id ? technician_id : created_by,
        receipt_img_name: "",
        comments: t("service_via_work_order_comments", {
          woNo: wo_number,
          createdDate: getDisplayDate(create_date),
        }),
        card_number: 0,
        service_station: "",
        transaction_type: "Cash",
        total_time: totalTime,
      };

      const serviceResponse = await postApi(serviceApiData, "workOrder");
      if (!serviceResponse.success) {
        return 0;
      }

      if (serviceResponse.success) {
        //Update service task details(Only change is to add service_id to each task)
        if (serviceTasks.length > 0) {
          let serviceTaskDetails = [];
          for (let task of serviceTasks) {
            const {
              partsCost,
              laborCost,
              totalCost,
              service_task_id,
              service_task_detail_id,
              action,
            } = task;

            serviceTaskDetails.push({
              service_task_detail_id: service_task_detail_id,
              service_task_id: service_task_id,
              service_id: serviceId,
              wo_id: rowData.wo_id,
              parts_cost: partsCost ? +partsCost : 0,
              labor_cost: laborCost ? +laborCost : 0,
              total_cost: totalCost ? +totalCost : 0,
              action: action,
            });
          }

          const serviceTaskDetailsApiData = {
            source: "web",
            sync_version: "v2",
            operation: type === "edit" ? "update" : "create",
            querystring: "addEditServiceTaskDetails",
            service_task_details: serviceTaskDetails,
          };

          const serviceTaskDetailsApiRes = await postApi(
            serviceTaskDetailsApiData,
            "workOrder"
          );
          return serviceTaskDetailsApiRes.success;
        }
      }
    }
  };

  //Handler functions for 'Yes' and 'No' buttons on close Work order (Is vehName back in service?)
  const handleYes = () => {
    (async () => {
      setYesBtnLoading(true);
      const vehRes = await updateVehicleToBackInService();
      if (vehRes) {
        const closeWORes = await closeWorkOrder();
        if (closeWORes) {
          setRightPanelData({ show: false, content: "" });
          dispatch(addMsg(t("wo_closed")));
          setReload("work_order");
          setShowActionPopup(false);
        }
      } else {
        setYesBtnLoading(false);
      }
    })();
  };

  const handleNo = () => {
    (async () => {
      setNoBtnLoading(true);
      const closeWORes = await closeWorkOrder();
      if (closeWORes) {
        //const vehRes = await updateVehicleToBackInService();
        // if (closeWORes) {
        setRightPanelData({ show: false, content: "" });
        dispatch(addMsg(t("wo_closed")));
        setReload("work_order");
        setShowActionPopup(false);
        // }
      }
    })();
  };

  return (
    <>
      {vehicleUpdErr && (
        <InfoPopup
          message={vehicleUpdErrData.message}
          icon={vehicleUpdErrData.icon}
          showPopup={vehicleUpdErr}
          setShowPopup={setVehicleUpdErr}
        />
      )}
      {showPauseConfirmPopup && (
        <PauseConfirmPopup
          showPopup={showPauseConfirmPopup}
          setShowPopup={setShowPauseConfirmPopup}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          handlePauseClick={handlePauseClick}
          pauseBtnLoading={pauseBtnLoading}
        />
      )}
      {showActionPopup && (
        <ActionPopup
          showPopup={showActionPopup}
          setShowPopup={setShowActionPopup}
          message={t("is_veh_back_in_service", {
            vehicleName: currentVehicle.label,
          })}
          handleYes={handleYes}
          handleNo={handleNo}
          yesBtnLoading={yesBtnLoading}
          noBtnLoading={noBtnLoading}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      <div style={{ position: "relative", top: "-2px" }} ref={optionsRef}>
        <ActionIconContainer
          onClick={() => setOpen(!open)}
          style={{ position: "relative", top: "2px" }}
        >
          <i className="fi fi-rr-menu-dots-vertical" />
        </ActionIconContainer>
        {open && (
          <DropdownWrap>
            {(status === 1 || status === 2) && (
              <OptionsMenu onClick={handleCloseWO}>
                <span>{t("close_work_order")}</span>
              </OptionsMenu>
            )}
            {(status === 1 || status === 2) && (
              <OptionsMenu onClick={handlePauseResume}>
                {resumeLoading ? (
                  <div className="d-flex justify-content-center">
                    <CustomLoader />
                  </div>
                ) : (
                  <span>
                    {status === 2
                      ? t("resume_work_order")
                      : t("pause_work_order")}
                  </span>
                )}
              </OptionsMenu>
            )}
            <OptionsMenu onClick={handlePrintWO}>
              <span>
                {t("print_work_order")}
                {tag === "free-user" && (
                  <SubscribeTooltipIcon message={t("subscribe_msg")} />
                )}
              </span>
            </OptionsMenu>
          </DropdownWrap>
        )}
      </div>
    </>
  );
};

export default WOSidePanelDropdown;

//styles

const DropdownWrap = styled.div`
  border: 1px solid ${colorLightGray};
  position: absolute;
  margin: 0.25rem;
  //border-radius: 5px;
  //width: 170px;
  right: -50px;
  //width: 50px !important;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  background: white;
  //width: max-content;
  &:hover {
    background: ${colorLightGray};
  }
`;
