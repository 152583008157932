import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AddFormButton } from "../../../shared/components/TableElements";

const NotFound404 = () => (
  <NotFoundContainer>
    <NotFountContent>
      <NotFoundInfo>
        Ooops! The page you are looking for could not be found :(
      </NotFoundInfo>
      {/* <Button as={Link} variant="primary" to="/login">Back to login</Button> */}
      <Link to={"/login"}>
        <AddFormButton type="button" id="add-fillup-button">
          Back to login
        </AddFormButton>
      </Link>
    </NotFountContent>
  </NotFoundContainer>
);

export default NotFound404;

// region STYLES

const NotFoundContainer = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: flex;
  background-size: cover;

  button {
    margin: 0;
  }
`;

const NotFountContent = styled.div`
  margin: auto;
  //padding: 10px;
`;

const NotFoundImage = styled.img`
  max-width: 500px;
  width: 100%;
`;

const NotFoundInfo = styled.h3`
  color: black;
  margin-bottom: 20px;
  //margin-top: 80px;
`;

// endregion
