import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
};

const rowSlice = createSlice({
    name: 'row',
    initialState,
    reducers: {
        addRowData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { addRowData } = rowSlice.actions;
export default rowSlice.reducer;


