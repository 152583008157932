import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
  RedButton,
} from "../../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormGroupLabel,
  FormItem,
  LinkSpan,
} from "../../../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { formInputStyles } from "../../../../shared/constants/styles";
import CheckboxInputComponent from "../../../../shared/components/form/CheckboxInputComponent";
import { Desc } from "../../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import geoTabIcon from "@/shared/img/geotab_logo.svg";
import simplyFleetIcon from "@/shared/img/simplyFleetLogo.png";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { post } from "../../../../api/getUser";
import { useDispatch } from "react-redux";
import { addMsg } from "../../../../features/snackbar/snackbarSlice";
import ActionPopup from "../../../../shared/components/ActionPopup";
import { ImageIcon } from "../../../../shared/components/table/components/RightPanel/InspectionSubmissionsRightPanel";
import {
  colorLightGray,
  scrollbarStyles,
  taskBgColor,
} from "../../../../utils/palette";
import ZoomModal from "../../../../shared/components/ZoomModal";
import { IntegrationsImg } from "..";
import dbNameHintImg from "../../../../shared/img/dbNameHintImg.png";

const Geotab = ({ step, setStep, setShowSnackbar }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const {
    integrationsData,
    setIntegrationsData,
    setFetchTelematicsOdo,
    setShowFaultCodes,
  } = useAuth();
  const { distance } = useSelector((state) => state.units);

  const [apiToken, setApiToken] = useState("");

  const [setupData, setSetupData] = useState({
    dbName: "",
    userName: "",
    password: "",
  });

  const [fetchAllEntities, setFetchAllEntities] = useState({
    allVehicles: true,
    //allUsers: true,
  });
  const [configData, setConfigData] = useState({
    odometer: true,
    faultCodes: true,
    //newUsers: true,
    newVehicles: true,
  });

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [showDeleteConnectionPopup, setShowDeleteConnectionPopup] =
    useState(false);

  //State to store url of steps/instruction images
  const [imageUrl, setImageUrl] = useState("");

  const [insctructionsImg, setInstructionsImg] = useState(null);
  const [showInsctructionsImg, setShowInstructionsImg] = useState(false);

  const [showInstructions, setShowInstructions] = useState(false);

  const [showDbNameHintImg, setShowDbNameHintImg] = useState(false);

  //Update config data
  useEffect(() => {
    if (integrationsData?.length > 0) {
      let configObj = {
        odometer: false,
        faultCodes: false,
      };
      for (let intObj of integrationsData) {
        const { provider, provider_type, service_enabled, service_type } =
          intObj;
        if (provider_type === 1 && provider === 2 && service_enabled === 1) {
          if (service_type === 1) {
            configObj.odometer = true;
          }
          if (service_type === 2) {
            configObj.faultCodes = true;
          }
        }
      }
      setConfigData(configObj);
    }
  }, [integrationsData]);

  const handleFetchAllEntitiesChange = (e) => {
    const { name, checked } = e.target;
    setFetchAllEntities((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSetupDataChange = (e) => {
    const { name, value } = e.target;
    setSetupData((prev) => ({ ...prev, [name]: value }));
  };

  const handleConfigChange = (e) => {
    const { name, checked } = e.target;
    setConfigData((prev) => ({ ...prev, [name]: checked }));
  };

  const fetchIntegrationsData = async () => {
    setShowFaultCodes(false);
    setFetchTelematicsOdo(false);
    //Fetch integrations data
    const apiData = {
      querystring: "get_integrations_data",
    };
    const res = await post("integrations", apiData);
    console.log(res);
    if (res.success) {
      const { data } = res;
      if (data.length > 0) {
        for (let intObj of data) {
          const { provider, provider_type, service_enabled, service_type } =
            intObj;

          if (
            provider_type === 1 &&
            provider !== 3 //Since there are no fault codes for streamline
            //&&
            //service_enabled === 1 &&
            //service_type === 2
          ) {
            setShowFaultCodes(true);
          }

          if (
            provider_type === 1 &&
            service_enabled === 1 &&
            service_type === 1
          ) {
            setFetchTelematicsOdo(true);
          }

          setIntegrationsData(data);
        }
      }
    } else {
      setIntegrationsData([]);
      setShowFaultCodes(false);
      setFetchTelematicsOdo(false);
    }
  };

  const handleIntegration = async () => {
    setStep(3);
    const apiData = {
      database_name: setupData.dbName,
      user_name: setupData.userName,
      password: setupData.password,
      fetch_all_vehicles: fetchAllEntities.allVehicles ? 1 : 0,
      //fetch_all_users: fetchAllEntities.allUsers ? 1 : 0,
      querystring: "setup_integration",
      provider: 2,
      provider_type: 1,
      primary_meter: distance,
    };
    console.log(apiData);
    const res = await post("integrations", apiData);
    if (res.success) {
      await fetchIntegrationsData();
      setStep(5);
    } else {
      setStep(4);
    }
  };

  const handleProceedToConfig = () => {
    setConfigData({
      odometer: true,
      faultCodes: true,
    });
    setStep(6);
  };

  const handleSaveConfig = async () => {
    setSaveBtnLoading(true);
    const apiData = {
      fetch_odometer: configData.odometer ? 1 : 0,
      fetch_fault_codes: configData.faultCodes ? 1 : 0,
      //fetch_new_vehicles: configData.newVehicles ? 1 : 0,
      querystring: "configure_integration_setup",
      provider: 2,
      provider_type: 1,
    };

    const res = await post("integrations", apiData);
    if (res.success) {
      await fetchIntegrationsData();
      setSaveBtnLoading(false);
      dispatch(addMsg(t("config_updated")));
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 3000);
      setStep(0);
    }
  };

  const handleDeleteConnection = async () => {
    setDeleteBtnLoading(true);
    const apiData = {
      querystring: "delete_integration",
      provider: 2,
      provider_type: 1,
    };

    const res = await post("integrations", apiData);
    if (res.success) {
      await fetchIntegrationsData();
      setDeleteBtnLoading(false);
      dispatch(addMsg(t("connection_deleted_success_msg")));
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 3000);
      setStep(0);
    }
  };

  return (
    <>
      <Modal show={step !== 0} size="lg">
        {showDeleteConnectionPopup && (
          <ActionPopup
            showPopup={showDeleteConnectionPopup}
            setShowPopup={setShowDeleteConnectionPopup}
            handleYes={handleDeleteConnection}
            handleNo={() => setShowDeleteConnectionPopup(false)}
            message={t("delete_connection_confirm_msg")}
            yesBtnLoading={deleteBtnLoading}
          />
        )}

        {showInsctructionsImg && (
          <ZoomModal
            showModal={showInsctructionsImg}
            setShowModal={setShowInstructionsImg}
            doc={{ url: imageUrl }}
          />
        )}

        <Modal.Header>
          <h5>
            {step === 6 ? t("configure_geotab") : t("integrate_with_geotab")}
          </h5>
          {step !== 3 && (
            <div className="d-flex justify-content-end">
              <ActionIconContainer onClick={() => setStep(0)}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <ScrollableDiv
          //className="d-flex justify-content-center"
          //style={{ height: 420, overflow: "auto" }}
          >
            {/* step 1 */}
            {step === 1 && (
              <div
                className="d-flex flex-column align-items-center gap-2"
                style={{ width: 500 }}
              >
                <div className="d-flex justify-content-center">
                  <IntegrationsImg
                    style={{ width: 120, height: 100 }}
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={100}
                  />
                </div>
                <div
                  className="d-flex flex-column justify-content-start align-items-start"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <div style={{ width: "-webkit-fill-available" }}>
                    <FormGroupLabel id="database-name-label">
                      {t("db_name")}
                    </FormGroupLabel>
                    <FormItem style={{ width: "-webkit-fill-available" }}>
                      <input
                        type="text"
                        name="dbName"
                        value={setupData.dbName}
                        style={formInputStyles}
                        onChange={handleSetupDataChange}
                      />
                    </FormItem>
                    <span>
                      <Desc style={{ display: "inline" }}>
                        {t("geotab_dbname_hint_text")}{" "}
                      </Desc>
                      <LinkSpan
                        onMouseEnter={() => setShowDbNameHintImg(true)}
                        onMouseLeave={() => setShowDbNameHintImg(false)}
                      >
                        {t("dbname_img_hint_text")}
                      </LinkSpan>
                    </span>
                    {showDbNameHintImg && (
                      <div style={{ position: "absolute", right: -100 }}>
                        <img
                          src={dbNameHintImg}
                          alt="db-name-hint-img"
                          width={1000}
                          height={100}
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    )}
                  </div>

                  <div style={{ width: "-webkit-fill-available" }}>
                    <FormGroupLabel id="username-label">
                      {t("user_name")}
                    </FormGroupLabel>
                    <FormItem style={{ width: "-webkit-fill-available" }}>
                      <input
                        type="text"
                        name="userName"
                        value={setupData.userName}
                        style={formInputStyles}
                        onChange={handleSetupDataChange}
                      />
                    </FormItem>
                    <Desc className="mt-1">
                      {t("geotab_username_hint_text")}{" "}
                    </Desc>
                  </div>

                  <div style={{ width: "-webkit-fill-available" }}>
                    <FormGroupLabel id="password-label">
                      {t("password")}
                    </FormGroupLabel>
                    <FormItem style={{ width: "-webkit-fill-available" }}>
                      <input
                        type="password"
                        name="password"
                        value={setupData.password}
                        style={formInputStyles}
                        onChange={handleSetupDataChange}
                      />
                    </FormItem>
                    <Desc className="mt-1">{t("geotab_pw_hint_text")} </Desc>
                  </div>
                </div>
              </div>
            )}

            {/* step 2 */}
            {step === 2 && (
              <div className="d-flex flex-column gap-5 align-items-center">
                <div className="d-flex justify-content-center">
                  <IntegrationsImg
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <CheckboxInputComponent
                      label={t("fetch_all_vehicles_from_geotab")}
                      name={"allVehicles"}
                      checked={fetchAllEntities.allVehicles}
                      onChange={handleFetchAllEntitiesChange}
                    />
                  </div>
                  {/* <CheckboxInputComponent
                label={"Fetch all my users from Geotab"}
                name={"allUsers"}
                checked={fetchAllEntities.allUsers}
                onChange={handleFetchAllEntitiesChange}
              /> */}

                  <Desc>{t("samsara_step_2_note")}</Desc>
                </div>
              </div>
            )}

            {/* step 3 */}
            {step === 3 && (
              <div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-br-arrow-right"
                    style={{ fontSize: 24, marginRight: "0.5rem" }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <p>{t("integration_in_prog")}</p>
                </div>
              </div>
            )}

            {/* step 4 */}
            {step === 4 && (
              <div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rr-cross-circle"
                    style={{
                      fontSize: 28,
                      color: "red",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                  <p
                    style={{ fontWeight: 500, fontSize: 16 }}
                    className="d-flex flex-column align-items-center"
                  >
                    {t("integration_failed")}
                  </p>
                  <br />
                  <p>{t("geotab_correct_data_warn_msg")}</p>
                </div>
              </div>
            )}

            {/* step 5 */}
            {step === 5 && (
              <div className="d-flex flex-column gap-5">
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rs-check-circle"
                    style={{
                      fontSize: 28,
                      color: "green",
                      //marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div>
                  <p
                    style={{ fontWeight: 500, fontSize: 16 }}
                    className="d-flex flex-column align-items-center"
                  >
                    {t("integration_complete")}
                  </p>
                  <br />
                  <p className="d-flex flex-column align-items-center">
                    {t("geotab_setup_success_msg")}
                  </p>
                  <br />
                  <p className="d-flex flex-column align-items-center">
                    {t("geotab_config_msg")}
                  </p>
                </div>
              </div>
            )}

            {/* step 6 */}
            {step === 6 && (
              <div className="d-flex flex-column align-items-center gap-5">
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={geoTabIcon}
                    alt="geotab-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rs-check-circle"
                    style={{
                      fontSize: 28,
                      color: "green",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column gap-4">
                  <CheckboxInputComponent
                    label={t("fetch_odo_val_from_geotab")}
                    name={"odometer"}
                    checked={configData.odometer}
                    onChange={handleConfigChange}
                  />
                  <CheckboxInputComponent
                    label={t("fetch_fault_codes_from_geotab")}
                    name={"faultCodes"}
                    checked={configData.faultCodes}
                    onChange={handleConfigChange}
                  />
                  {/* <CheckboxInputComponent
                    label={"Fetch vehicles whenever added in Geotab"}
                    name={"newVehicles"}
                    checked={configData.newVehicles}
                    onChange={handleConfigChange}
                  /> */}
                  {/* <CheckboxInputComponent
                label={"Fetch users whenever added in Geotab"}
                name={"newUsers"}
                checked={configData.newUsers}
                onChange={handleConfigChange}
              /> */}
                </div>
              </div>
            )}
          </ScrollableDiv>
        </Modal.Body>
        <Modal.Footer>
          {/* step 1 */}
          {step === 1 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton onClick={() => setStep(0)}>
                {t("cancel")}
              </FilterButton>

              <AddFormButton
                id="next-2-button"
                type="button"
                onClick={() => setStep(2)}
                disabled={
                  !setupData.dbName ||
                  !setupData.userName ||
                  !setupData.password
                }
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("next")}
              </AddFormButton>
            </div>
          )}

          {/* step 2 */}
          {step === 2 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton onClick={() => setStep(1)}>
                {t("back")}
              </FilterButton>

              <AddFormButton
                id="next-3-button"
                type="button"
                onClick={handleIntegration}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("next")}
              </AddFormButton>
            </div>
          )}

          {/* step 3 */}
          {step === 3 && <div style={{ height: "32px" }}></div>}

          {/* step 4 */}
          {step === 4 && (
            <div
              className="d-flex justify-content-start"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton
                id="back-button"
                type="button"
                onClick={() => setStep(1)}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("go_back_changed")}
              </FilterButton>
            </div>
          )}

          {/* step 5 */}
          {step === 5 && (
            <div
              className="d-flex justify-content-end"
              style={{ width: "-webkit-fill-available" }}
            >
              <AddFormButton
                id="proceed-to-config-button"
                type="button"
                onClick={handleProceedToConfig}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("proceed_to_config")}
              </AddFormButton>
            </div>
          )}

          {/* step 6 */}
          {step === 6 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <RedButton
                type="button"
                onClick={() => setShowDeleteConnectionPopup(true)}
                //disabled={deleteBtnLoading}
              >
                {t("delete_connection")}
              </RedButton>

              <AddFormButton
                id="save-button"
                type="button"
                onClick={handleSaveConfig}
                disabled={saveBtnLoading}
              >
                {saveBtnLoading ? <CustomLoader /> : t("save")}
              </AddFormButton>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Geotab;

//styles
const LI = styled.li`
  font-weight: 400;
  strong {
    font-weight: 500;
  }
`;

const BodyContainer = styled.div`
  height: 180px;
  overflow: auto;
  padding: 0.5rem;
  width: 500px;
  background: ${(p) => (p.$showInstructions ? taskBgColor : "")};
  ${scrollbarStyles};
`;

const CollapseIcon = styled.i`
  position: relative;
  top: 3px;
  // cursor: pointer;
  // &:hover {
  //   color: ${colorLightGray};
  // }
`;

const CollapseContainer = styled.div`
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 5px;
  &:hover {
    background: ${colorLightGray};
  }
`;

const ScrollableDiv = styled.div`
  height: 420px;
  overflow: auto;
  display: flex;
  justify-content: center;
  ${scrollbarStyles}
`;
