import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import styled from "styled-components";
import { colorGray, colorLightGray } from "../../../utils/palette";
import ZoomComponent from "../ZoomComponent";
import ZoomModal from "../ZoomModal";

const ImageWithZoom = ({
  img,
  shouldDelete,
  images,
  setImages,
  handleDelete,
  fromDropzone,
  imgClickHandler,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [imgHovered, setImgHovered] = useState(false);

  //console.log(img);

  const handleDeleteImg = () => {
    if (handleDelete) {
      handleDelete();
    } else {
      setImages((prev) => {
        const temp = prev.filter((i) => i.id !== img.id);
        return temp;
      });
    }
  };

  const handleImgClick = () => {
    if (imgClickHandler) {
      //imgClickHandler is used to show Image Modal in FileUploaderNew instead of showing it here.
      //This is done because ImageModal click events(zoom in, out,...) dont work inside the 
      //Dropzone Component. So the ImageModal has to be shown outside Dropzone.
      //This will be the case inside every form where there is drag n drop functionality
      imgClickHandler(img);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <ImageWithZoomContainer
          onClick={() => handleImgClick()}
          onMouseEnter={() => setImgHovered(true)}
          onMouseLeave={() => setImgHovered(false)}
          isHovered={imgHovered}
        >
          {["png", "jpg", "jpeg", "jfif"].includes(img?.extension) ? (
            <img
              src={img.url}
              alt="receipt-image"
              className="img-thumbnail"
              //width={120}
              //height={80}
              style={{
                width: "120px",
                height: "80px",
                objectFit: "cover",
              }}
            />
          ) : (
            <embed
              src={img.url}
              alt="receipt-image"
              className="img-thumbnail"
              //width={120}
              //height={80}
              style={{
                width: "120px",
                height: "80px",
              }}
            />
          )}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
            onClick={handleImgClick}
          />
          {/* <i className="fi fi-rr-zoom-in"></i> */}
        </ImageWithZoomContainer>
        {shouldDelete && (
          <ImgDeleteIcon
            onClick={() => handleDeleteImg(img)}
            className="fi fi-rr-cross-circle"
          ></ImgDeleteIcon>
        )}
      </div>

      {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
          <div style={{ overflow: "hidden" }}>
            <ImgContainer>
              <ReactPanZoom image={img.url} alt="uploaded-image" />
            </ImgContainer>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* {showModal && (
        <ZoomComponent
          showPopup={showModal}
          setShowPopup={setShowModal}
          docType={true}
          isSingle={true}
          img={img}
        />
      )} */}

      {/* {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          size="lg"
          className="d-flex align-items-center justify-content-center"
        >
          <Modal.Body>
            <ZoomComponent doc={img} />
          </Modal.Body>
        </Modal>
      )} */}

      {showModal && !fromDropzone && (
        <ZoomModal
          showModal={showModal}
          setShowModal={setShowModal}
          doc={img}
        />
      )}
    </>
  );
};

export default ImageWithZoom;

//styles

export const ImageWithZoomContainer = styled.div`
  position: relative;
  width: 120px;
  height: 80px;

  &:hover {
    cursor: zoom-in;
  }

  i {
    display: none;
    color: white;
    position: absolute;
    top: 40%;
    right: 45%;
    z-index: 10;
    //${(p) => p.isHovered && `display: inline`}
  }

  //   &:hover-within {
  //     img {
  //         opacity: 0.5;
  //     }
  //   }

  embed {
    ${(p) => p.isHovered && `opacity: 0.4`}
  }
`;

export const ImgDeleteIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  //top: -28px;
  right: -5px;
  top: -8px;
  color: ${colorGray};

  &:hover {
    color: ${colorLightGray};
  }
`;

export const ImgContainer = styled.div`
  //overflow: hidden;

  // div {
  //   background: black;
  // }
  img {
    margin: auto;
    //width: auto !important;
    width: inherit;
    height: ${window.innerHeight - 200}px;
    max-width: 100%;
    display: block;
  }
`;
