import React, { useRef, useState } from "react";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  HintText,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormHeader from "../../shared/components/form/FormHeader";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "@/shared/components/Card";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  formInputStyles,
  formStyles,
  openDropdownStyles,
} from "../../shared/constants/styles";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import FormField from "../../shared/components/form/FormField";
import {
  CustomInput,
  Desc,
  EditIcon,
} from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  daysHoursMinutes,
  getDateFnsLocale,
  getDateWithTime,
  getDisplayDate,
  getDistanceFactorforOdo,
  getDistanceUnit,
  getEditUsers,
  getInitialStartTime,
  getListingDisplayDate,
  getTimestamp,
  handleHighOdoErr,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";
import {
  colorBg,
  colorBlue,
  colorBorder,
  colorGray,
  colorLightGray,
  colorYellowNew,
  scrollbarStyles,
} from "../../utils/palette";
import FileUploader from "../../shared/components/form/FileUploader";
import {
  DisplayIcon,
  FileUploaderContainer,
  uploadImgToS3,
} from "../Issues/IssuesForm";
import CreatableSelect from "react-select/creatable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MultiValueOption } from "../VehicleInspection/InspectionForms/InspectionFormsForm";
import {
  AddFormButton,
  CustomLoader,
  FilterButton,
  ActionIconContainer,
  RowValue,
  ColHeader,
} from "../../shared/components/TableElements";
import styled from "styled-components";
import Error from "@/shared/components/form/Error";
import getUnixTime from "date-fns/getUnixTime";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import ImageWithZoom from "../../shared/components/form/ImageWithZoom";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { getVehicles, postApi } from "../../api/getUser";
import { useHistory, useLocation } from "react-router";
import format from "date-fns/format";
import { updateVehiclesList } from "../../features/vehicles/vehiclesSlice";
import ServiceTasks from "../../shared/components/form/ServiceTasks";
import { areObjsEqual } from "../../utils/helpers";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import addDays from "date-fns/addDays";
import { NATEXT } from "../../shared/constants";
import InfoPopup from "../../shared/components/InfoPopup";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import useFetchOdo from "../../shared/custom-hooks/useFetchOdo";
import FileUploaderNew from "../../shared/components/form/FileUploaderNew";
import CustomTimeToCompletePopup from "../WorkOrders/components/CustomTimeToCompletePopup";
import ErrorPopup from "../../shared/components/ErrorPopup";

const ServicesForm = () => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );
  const allUsers = useSelector((state) => state.users.list);
  const dispatch = useDispatch();
  const history = useHistory();

  const { currency, distance } = useSelector((state) => state.units);

  const [showAddServiceModal, setShowAddServiceModal] = useState(false);

  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  const { tag } = useAuth();

  //hook-form variables
  const vehicle = watch("vehicle");
  const odometer = watch("odometer");
  const serviceDate = watch("serviceDate");
  const serviceTime = watch("serviceTime");
  //const tax = watch("tax");
  //const discount = watch("discount");
  const cardNo = watch("cardNo");
  const transactionType = watch("transactionType");
  const serviceStation = watch("serviceStation");
  const servicedBy = watch("servicedBy");
  const comments = watch("comments");

  const [serviceTasks, setServiceTasks] = useState([]);
  const [serviceTaskOptions, setServiceTaskOptions] = useState([]);

  const allServiceTasks = useSelector((state) => state.servicesTasks.list);
  const users = useSelector((state) => state.users.list);
  const { userId, orgId } = useSelector((state) => state.user);

  const [userOptions, setUserOptions] = useState([]);

  const [tax, setTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [total, setTotal] = useState("");
  const [partsCostTotal, setPartsCostTotal] = useState("");
  const [laborCostTotal, setLaborCostTotal] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);

  const [serviceImages, setServiceImages] = useState([]);

  const [showServiceTasksError, setShowServiceTasksError] = useState(false);
  const [showDiscountError, setShowDiscountError] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //State to toggle Vehicle out of service popup
  const [showOutOfServicePopup, setShowOutOfServicePopup] = useState(false);

  const [serviceId, setServiceId] = useState("");

  const transactionTypeOptions = [
    {
      value: "Cash",
      label: t("cash_const"),
    },
    {
      value: "Check",
      label: t("check_const"),
    },
    {
      value: "Credit card",
      label: t("credit_card_const"),
    },
    {
      value: "Debit card",
      label: t("debit_card_const"),
    },
  ];

  const location = useLocation();
  const data = location?.state?.data;
  let rowData = data?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const mode = location?.state?.mode;

  //Data received from issue - Close with service
  const dataFromIssues = location?.state?.data;
  const vehOutOfService = dataFromIssues?.vehOutOfService;
  const issueApiData = dataFromIssues?.issueApiData;
  const issueImagesToS3 = dataFromIssues?.issueImagesToS3;

  const serviceFormData = getValues();
  const existingFormInfo = {
    ...serviceFormData,
    serviceTasks,
    serviceImages,
    tax,
    discount,
  };

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    date: serviceDate,
    time: serviceTime,
  });

  const workOrderId = useRef("");

  const [assocReminders, setAssocReminders] = useState([]);
  const [assocIssues, setAssocIssues] = useState([]);
  const [assocWorkOrders, setAssocWorkOrders] = useState([]);

  const [customTotalTime, setCustomTotalTime] = useState({
    display: NATEXT,
    seconds: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const [showTotalDurationPopup, setShowTotalDurationPopup] = useState(false);

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const serviceTasksRef = useRef([]);

  //Flag to check if service tasks have changed
  //To update WO
  const [serviceTasksChanged, setServiceTasksChanged] = useState(false);

  const { odoFetchedMsg, setOdoFetchedMsg } = useFetchOdo({
    vehicle,
    type,
    setValue,
    setShowHighOdoErr,
    odoReqdPresent: true,
    odoReqd: mode !== "close-issue-with-service",
  });

  useEffect(() => {
    if (type !== "edit") {
      const signedInUser = users.find((u) => u.user_id === userId);
      setValue("servicedBy", signedInUser);
      // setValue(
      //   "transactionType",
      //   transactionTypeOptions.find((t) => t.value === "Credit card")
      // );

      let defaultTransactionTypeValue = "Credit card";
      //Check if transaction type preference is already set in local storage
      const transactionTypePref = localStorage.getItem("transactionType");

      if (transactionTypePref) {
        defaultTransactionTypeValue = transactionTypePref;
      }
      setValue(
        "transactionType",
        transactionTypeOptions.find(
          (t) => t.value === defaultTransactionTypeValue
        )
      );

      setValue("serviceDate", new Date());
      setValue("serviceTime", getInitialStartTime());
    }

    if (type === "add") {
      if (mode === "close-issue-with-service") {
        const { issueApiData } = dataFromIssues;
        const vehicleFromIssue = vehicles.find(
          (v) => v.vehicleId === issueApiData?.veh_id
        );
        setValue("vehicle", vehicleFromIssue);

        //Convert odo to vehicle unit since odo from issueApiData will be in org unit
        const distanceFactor = getDistanceFactorforOdo(
          vehicleFromIssue?.primary_meter,
          distance
        );
        let odoInVehicleUnit = issueApiData?.odo
          ? (issueApiData?.odo / distanceFactor).toFixed(2)
          : 0;
        setValue("odometer", odoInVehicleUnit);
        const formattedDate = getListingDisplayDate(
          issueApiData?.reported_date
        );
        setValue(
          "comments",
          t("comments_service_from_issue", {
            issueName: issueApiData?.issue,
            issueReportedDate: formattedDate,
          })
        );
      }

      if (mode === "from-maintenance-reminders") {
        //console.log(rowData);

        setValue(
          "vehicle",
          vehicles.find((v) => v.vehicleId === rowData.veh_id)
        );
        setValue(
          "comments",
          t("comments_service_from_reminder", {
            taskName: rowData.service_task_name,
          })
        );

        //Logic to set serviceTasks
        let selectedServiceTask = allServiceTasks.find(
          (t) => t.service_task_id === rowData.service_task_id
        );
        //console.log(selectedServiceTask);
        if (selectedServiceTask) {
          setServiceTasks([selectedServiceTask]);
        }
      }
    }
  }, [userId, users, allServiceTasks]);

  //Effect for Edit Service
  useEffect(() => {
    if (
      type === "edit" &&
      !mode &&
      vehiclesList?.length > 0 &&
      //allUsers?.length > 0 &&
      orgId
    ) {
      //Single service fetch call on edit
      (async () => {
        const singleServiceApiData = {
          querystring: "fetchSingleServiceData",
          service_id: data?.rowData?.service_id,
        };
        const singleServiceRes = await postApi(
          singleServiceApiData,
          "workOrder"
        );
        console.log(singleServiceRes);
        if (singleServiceRes.success) {
          const { service_data, service_details_data } = singleServiceRes;
          rowData = service_data;

          if (service_details_data?.length > 0) {
            let serviceTaskDetails = [];
            for (let task of service_details_data) {
              const {
                service_task_detail_id,
                service_task_id,
                service_id,
                wo_id,
                labor_cost,
                parts_cost,
                total_cost,
                service_task_name,
              } = task;
              serviceTaskDetails.push({
                service_task_detail_id,
                service_task_id,
                service_id,
                service_task_name,
                wo_id,
                laborCost: +labor_cost ? +labor_cost : "",
                partsCost: +parts_cost ? +parts_cost : "",
                totalCost: +total_cost ? +total_cost : "",
                action: 2,
                label: service_task_name,
                value: service_task_id,
              });
            }
            setServiceTasks(serviceTaskDetails);
            serviceTasksRef.current = serviceTaskDetails;
          }
        }
      })();

      //Call to get Work Order Count
      (async () => {
        const workOrderApiData = {
          service_id: rowData.service_id,
          querystring: "get_service_workorder_count",
        };
        //console.log(workOrderApiData);
        let workOrderRes = await postApi(workOrderApiData, "commonNew");
        console.log("woCountRes", workOrderRes);
        if (workOrderRes?.success === 1) {
          const { user_data } = workOrderRes;
          if (user_data?.length > 0) {
            workOrderId.current = user_data[0].wo_id;
          }
        }
      })();

      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)
      );

      const distanceFactor = getDistanceFactorforOdo(
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
        distance
      );

      const odoUpd = rowData.odo
        ? (rowData?.odo / distanceFactor)?.toFixed(2)
        : 0;
      setValue("odometer", odoUpd);

      setValue("serviceDate", new Date(rowData?.service_date * 1000));
      setValue(
        "serviceTime",
        format(new Date(rowData?.service_date * 1000), "HH:mm")
      );

      //Set user and user options
      (async () => {
        const editUsers = await getEditUsers(
          rowData?.veh_id,
          rowData?.serviced_by
        );
        if (editUsers?.length > 0) {
          const selectedUser = editUsers?.find(
            (u) => u.user_id === rowData?.serviced_by
          );
          setValue("servicedBy", selectedUser);
          setUserOptions(editUsers);
        }
      })();

      // const selectedUser = allUsers?.find(
      //   (u) => u.user_id === rowData?.serviced_by
      // );
      // setValue("servicedBy", selectedUser);
      setValue("serviceStation", rowData?.service_station);
      setValue("cardNo", rowData?.card_number);
      //setValue("tax", rowData?.tax);
      //setValue("discount", rowData?.discount);

      setTax(rowData?.tax);
      setDiscount(rowData?.discount);

      setValue(
        "transactionType",
        transactionTypeOptions?.find(
          (t) => t.value === rowData.transaction_type
        )
      );
      setValue("comments", rowData?.comments);

      //Logic to set Images
      let imgNames = [];
      const editServiceimgName = rowData?.receipt_img_name;
      if (editServiceimgName) {
        imgNames = editServiceimgName.split(":::");

        const imgs = [];
        imgNames.map((imgName, i) => {
          imgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/services/${imgName}`,
          });
        });

        setServiceImages(imgs);
      }
      //End

      if (rowData.total_time) {
        const { d, h, m, display } = daysHoursMinutes(+rowData.total_time);

        setCustomTotalTime({
          seconds: +rowData.total_time,
          display: display,
          days: d,
          hours: h,
          minutes: m,
        });
      }
    }
  }, [rowData, vehiclesList, orgId]);

  //To set Service tasks on edit. Fetch action 3 service tasks on edit
  //in order to show deleted task names if any
  // useEffect(() => {
  //   if (
  //     type === "edit" &&
  //     !mode
  //     //&& allServiceTasks?.length > 0
  //   ) {
  //     //Logic to set serviceTasks
  //     (async () => {
  //       let serviceTaskOptions = [];
  //       let selectedServiceTasks = [];

  //       //To get action-3 service tasks
  //       const apiData = {
  //         querystring: "get_all_service_tasks",
  //         service_veh_id: "All",
  //       };

  //       const res = await postApi(apiData, "commonNew");
  //       if (res.success) {
  //         serviceTaskOptions = res.user_data;
  //         let serviceTaskIds = [];
  //         if (typeof rowData.service_task_ids === "string") {
  //           serviceTaskIds = JSON.parse(rowData?.service_task_ids);
  //         }

  //         let laborCostData = "";
  //         let partsCostData = "";
  //         let totalCostData = "";

  //         if (rowData.labor_cost && typeof rowData.labor_cost === "string") {
  //           laborCostData = JSON.parse(rowData?.labor_cost);
  //         }

  //         if (rowData.parts_cost && typeof rowData.parts_cost === "string") {
  //           partsCostData = JSON.parse(rowData?.parts_cost);
  //         }

  //         if (rowData.total_cost && typeof rowData.total_cost === "string") {
  //           totalCostData = JSON.parse(rowData?.total_cost);
  //         }

  //         serviceTaskIds?.forEach((taskId) => {
  //           const task = serviceTaskOptions.find(
  //             (t) => t.service_task_id === taskId
  //           );
  //           let temp = {
  //             ...task,
  //             label: task?.service_task_name,
  //             value: task?.service_task_id,
  //           };

  //           temp.partsCost = partsCostData[taskId]
  //             ? +partsCostData[taskId]
  //             : "";
  //           temp.laborCost = laborCostData[taskId]
  //             ? +laborCostData[taskId]
  //             : "";
  //           temp.totalCost = (+temp.laborCost + +temp.partsCost)?.toFixed(2);

  //           selectedServiceTasks.push(temp);
  //         });
  //       }
  //       setServiceTasks(selectedServiceTasks);
  //     })();
  //   }
  // }, [rowData, allServiceTasks]);

  //To set service docs/images on edit
  // useEffect(() => {
  //   if (type === "edit" && !mode) {
  //     //Logic to set Images
  //     let imgNames = [];
  //     const editServiceimgName = rowData?.receipt_img_name;
  //     if (editServiceimgName) {
  //       imgNames = editServiceimgName.split(":::");

  //       const imgs = [];
  //       imgNames.map((imgName, i) => {
  //         imgs.push({
  //           existing: true,
  //           imgName: imgName,
  //           id: Date.now() + i,
  //           url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/services/${rowData?.service_id}/${imgName}`,
  //         });
  //       });

  //       setServiceImages(imgs);
  //     }
  //     //End
  //   }
  // }, [rowData, orgId]);

  //Update ServiceTaskOptions on change of vehicle
  useEffect(() => {
    if (vehicle) {
      (async () => {
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: vehicle?.vehicleId,
        };
        const serviceTasksRes = await updateServiceTaskOptions(apiData);
        setServiceTaskOptions(serviceTasksRes);
      })();

      if (type === "edit") {
        //Get Assoc Issues
        (async () => {
          const apiData = {
            querystring: "get_associated_issues",
            veh_id: vehicle.vehicleId,
            service_id: rowData.service_id,
          };
          const res = await postApi(apiData, "commonNew");

          if (res.success) {
            const { user_data } = res;
            const temp = [];
            if (user_data.length > 0) {
              user_data.forEach((i) => {
                const { issue, issue_desc, reported_date } = i;
                const issueName = issue;

                let issueDesc = "";
                if (issue_desc) {
                  if (issueDesc.length > 100) {
                    issueDesc = `${issue_desc.slice(0, 99)}...`;
                  } else {
                    issueDesc = issue_desc;
                  }
                }
                //const issueDesc = issue_desc ? issue_desc : NATEXT;
                const reportedDate = reported_date
                  ? getListingDisplayDate(reported_date)
                  : NATEXT;
                temp.push({ issueName, issueDesc, reportedDate });
              });
              setAssocIssues(temp);
            }
          }
        })();

        //Get Assoc WO
        (async () => {
          const apiData = {
            querystring: "get_associated_work_order",
            veh_id: vehicle.vehicleId,
            service_id: rowData.service_id,
          };
          const res = await postApi(apiData, "commonNew");
          console.log("get_associated_work_order res", res);

          if (res.success) {
            const { user_data } = res;
            const temp = [];
            if (user_data.length > 0) {
              user_data.forEach((wo) => {
                const { wo_number, technician_id, create_date } = wo;
                const technicianName = allUsers.find(
                  (u) => u.user_id === technician_id
                )?.label;
                const createdDate = create_date
                  ? getListingDisplayDate(create_date)
                  : NATEXT;
                temp.push({
                  woNumber: wo_number,
                  technicianName,
                  createdDate,
                });
              });
              setAssocWorkOrders(temp);
            }
          }
        })();
      }
    }
  }, [vehicle]);

  useEffect(() => {
    (async () => {
      if (vehicle) {
        let serviceTaskIds = [];
        if (serviceTasks.length > 0) {
          serviceTaskIds = serviceTasks.map((t) => t.service_task_id);
        }

        const apiData = {
          querystring: "get_associated_reminder",
          veh_id: vehicle.vehicleId,
          service_task_id: serviceTaskIds,
        };
        const res = await postApi(apiData, "commonNew");
        let temp = [];
        if (res.success) {
          const { user_data } = res;
          if (user_data.length > 0) {
            user_data.forEach((reminder) => {
              const serviceTaskName = allServiceTasks.find(
                (t) => t.service_task_id === reminder.service_task_id
              )?.service_task_name;
              const dueOnOdo = reminder?.due_odo
                ? reminder.due_odo + reminder.last_odo_of_service
                : 0;

              const dueOnDate =
                reminder?.last_date_of_service && reminder?.due_days
                  ? addDays(
                      new Date(reminder.last_date_of_service * 1000),
                      reminder.due_days
                    )
                  : 0;

              const odoUnit =
                vehicle?.primary_meter === "Hours" ? "Hours" : distance;

              let dueOnText = "";
              if (dueOnOdo && dueOnDate) {
                dueOnText = `${dueOnOdo} ${getDistanceUnit(odoUnit)} ${t(
                  "or"
                )} ${getListingDisplayDate(dueOnDate / 1000)}`;
              }

              if (dueOnOdo && !dueOnDate) {
                dueOnText = `${dueOnOdo} ${getDistanceUnit(odoUnit)}`;
              }

              if (!dueOnOdo && dueOnDate) {
                dueOnText = `${getListingDisplayDate(dueOnDate / 1000)}`;
              }

              temp.push({ serviceTaskName, dueOnOdo, dueOnDate, dueOnText });
            });
          }
        }
        setAssocReminders(temp);
      }
    })();
  }, [vehicle, serviceTasks]);

  //Clear cardNo value on change of transaction type
  useEffect(() => {
    if (
      transactionType?.value === "Cash" ||
      transactionType?.value === "Check"
    ) {
      setValue("cardNo", "");
    }
  }, [transactionType]);

  //Limit card number to 4 digits
  useEffect(() => {
    if (
      (transactionType?.value === "Debit card" ||
        transactionType?.value === "Credit card") &&
      cardNo
    ) {
      if (cardNo?.length > 4) {
        setValue("cardNo", cardNo.slice(0, 4));
      }
    }
  }, [cardNo]);

  const updateIssue = async (serviceId) => {
    const { close_date, status, service_id, ...otherIssueApiData } =
      issueApiData;

    const response = await postApi(
      {
        close_date: getUnixTime(new Date()),
        status: 2,
        service_id: serviceId,
        ...otherIssueApiData,
      },
      "issue"
    );
    if (response.success === 1) {
      if (issueImagesToS3?.length > 0) {
        const statusArr = [];
        issueImagesToS3.forEach(async (img, i) => {
          if (img.file) {
            const extension = img.file.name.split(".").pop();
            let imgName = `${getTimestamp(userId, i)}.${extension}`;
            let key = `image_data/org_${orgId}/issues/${issueApiData?.issue_id}/${imgName}`;
            //uploadToS3Arr.push({ key: key, file: img.file });
            const status = await uploadImgToS3(img);
            statusArr.push(status);
            if (statusArr.length === issueImagesToS3.length) {
              console.log(statusArr);
              const imgUploadSuccess = statusArr.every(
                (status) => status === 200
              );
              console.log(imgUploadSuccess);
              if (imgUploadSuccess) {
                //history.push("/issues", { fromCancel: false });
                //dispatch(addMsg("Issue Closed"));
                //setSaveBtnLoading(false);
                return 1;
              }
            }
          }
        });
      } else {
        //history.push("/issues", { fromCancel: false });
        //dispatch(addMsg("Issue Closed"));
        //setSaveBtnLoading(false);
        return 1;
      }
    }
  };

  const closeIssue = async (serviceId) => {
    if (vehOutOfService) {
      setShowOutOfServicePopup(true);
    } else {
      const issueRes = await updateIssue(serviceId);
      if (issueRes) {
        history.push("/issues", { fromCancel: false });
        dispatch(addMsg("Issue Closed"));
        setSaveBtnLoading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      if (serviceTasks?.length === 0) {
        setShowServiceTasksError(true);
        return;
      }
      setSaveBtnLoading(true);

      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );
      let odoConverted = odometer * distanceFactor;

      //Odo Validation
      const odoCheckApiData = {
        odo: odoConverted,
        veh_id: vehicle.vehicleId,
        datetocheck: getDateWithTime(serviceDate, serviceTime),
        querystring: "get_around_dates_v3",
        check_from: "services",
        check_id: type === "edit" ? rowData.service_id : "",
      };

      const odoValidationRes = await odoValidation(
        "odometer",
        odoCheckApiData,
        setError,
        setSaveBtnLoading,
        t
      );

      if (!odoValidationRes) {
        return;
      }

      let serviceId =
        type === "edit"
          ? rowData?.service_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      // if (mode === "close-issue-with-service") {
      //   serviceId = `${vehicle?.vehicleId}_${Date.now()}`;
      // }

      let serviceDateWithTime = new Date(serviceDate);
      let [hours, minutes] = serviceTime.split(":");
      if (hours < 10) {
        hours = hours[1];
      }

      if (minutes < 10) {
        minutes = minutes[1];
      }
      serviceDateWithTime = setHours(serviceDateWithTime, hours);
      serviceDateWithTime = setMinutes(serviceDateWithTime, minutes);

      // let serviceTaskIds = [];
      // let partsCostData = {};
      // let laborCostData = {};

      // if (serviceTasks?.length > 0) {
      //   serviceTasks?.forEach((t) => {
      //     serviceTaskIds.push(t.service_task_id);
      //     partsCostData[t.service_task_id] = t.partsCost ? t.partsCost : 0;
      //     laborCostData[t.service_task_id] = t.laborCost ? t.laborCost : 0;
      //   });

      //   //serviceTaskIds = JSON.stringify(serviceTaskIds);
      //   //partsCostData = JSON.stringify(partsCostData);
      //   //laborCostData = JSON.stringify(laborCostData);
      // }

      let imagesToS3 = [];

      let imagesString = "";

      serviceImages?.forEach((img, i) => {
        if (img?.hasOwnProperty("existing")) {
          imagesString += `${img?.imgName}:::`;
        } else {
          if (img.file) {
            const extension = img?.file?.name.split(".").pop();
            const imgName = `${getTimestamp(userId, i)}.${extension}`;
            const key = `image_data/org_${orgId}/services/${imgName}`;

            imagesString += `${imgName}:::`;

            imagesToS3.push({
              ...img,
              key: key,
            });
            // return {
            //   ...img,
            //   key: key,
            // };
          }
        }
      });
      imagesString = imagesString.slice(0, imagesString.length - 3);

      const apiData = {
        querystring: "addEditService",
        action: type === "edit" ? 2 : 1,
        sync_version: "v2",
        service_date: getUnixTime(serviceDateWithTime),
        operator_userid: servicedBy?.user_id,
        source: "web",
        comments: comments ? comments : "",
        operation: type === "edit" ? "update" : "create",
        veh_id: vehicle.vehicleId,
        transaction_type: transactionType ? transactionType?.value : "",
        card_number: cardNo ? cardNo : 0,
        receipt_img_name: imagesString,
        service_id: serviceId,
        currency: currency ? currency : "",
        odo: odoConverted,
        //service_task_ids: serviceTaskIds,
        odo_unit: vehicle?.primary_meter === "Hours" ? "Hours" : distance,
        service_station: serviceStation ? serviceStation : "",
        //labor_cost: laborCostData,
        //parts_cost: partsCostData,
        // total_cost: {
        //   parts_total: partsCostTotal ? partsCostTotal : 0,
        //   labor_cost_total: laborCostTotal ? laborCostTotal : 0,
        //   total: total ? total : 0,
        // },
        parts_total: partsCostTotal ? partsCostTotal : 0,
        labor_total: laborCostTotal ? laborCostTotal : 0,
        total: total ? total : 0,
        tax: tax ? +tax : 0,
        discount: discount ? +discount : 0,
        total_time: customTotalTime.seconds,
      };

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("service") })
          : t("record_added_msg", { recordName: t("service") });
      const response = await postApi(apiData, "workOrder");

      if (response.success === 1) {
        console.log("res", response);
        //Service Task Details
        let serviceTaskDetails = [];

        //Prepare serviceTaskDetails Data - Start
        if (type === "edit") {
          if (serviceTasksRef.current.length > 0) {
            //Check if any of the tasks has wo_id.
            //If yes, then this service is associated with WO
            // let woId = null;
            // let woIdTask = serviceTasksRef.current.find((t) => t.wo_id);
            // if (woIdTask) {
            //   woId = woIdTask?.wo_id;
            // }

            //Altenatively, we can check workOrderId to see if service is assoc with a WO
            let woId = null;
            if (workOrderId.current) {
              woId = workOrderId?.current;
            }

            //let count = 1;
            for (let task of serviceTasksRef.current) {
              const {
                partsCost,
                laborCost,
                totalCost,
                service_task_id,
                service_task_detail_id,
                action,
              } = task;
              // let serviceTaskDetailId = `${vehicle?.vehicleId}_${
              //   Date.now() + count
              // }`;
              // let action = 2;

              // if (task.hasOwnProperty("service_task_detail_id")) {
              //   serviceTaskDetailId = task.service_task_detail_id;
              // }
              // if (task.hasOwnProperty("action")) {
              //   action = task.action;
              // }

              serviceTaskDetails.push({
                service_task_detail_id: service_task_detail_id, //serviceTaskDetailId,
                service_task_id: service_task_id,
                service_id: serviceId,
                wo_id: woId || null,
                parts_cost: partsCost ? +partsCost : 0,
                labor_cost: laborCost ? +laborCost : 0,
                total_cost: totalCost ? +totalCost : 0,
                action: action,
              });
              //count += 1;
            }
          }
        } else {
          //For Add Service,
          //serviceTaskDetailId will be newly generated
          //wo_id will always be null
          //Action will always be 1
          if (serviceTasks.length > 0) {
            let count = 1;
            for (let task of serviceTasks) {
              const { partsCost, laborCost, totalCost, service_task_id } = task;
              const serviceTaskDetailId = `${vehicle?.vehicleId}_${
                Date.now() + count
              }`;
              serviceTaskDetails.push({
                service_task_detail_id: serviceTaskDetailId,
                service_task_id: service_task_id,
                service_id: serviceId,
                wo_id: null,
                parts_cost: partsCost ? +partsCost : 0,
                labor_cost: laborCost ? +laborCost : 0,
                total_cost: totalCost ? +totalCost : 0,
                action: 1,
              });
              count += 1;
            }
          }
        }
        //Prepare serviceTaskDetails Data - End

        const serviceTaskDetailsApiData = {
          source: "web",
          sync_version: "v2",
          operation: type === "edit" ? "update" : "create",
          querystring: "addEditServiceTaskDetails",
          service_task_details: serviceTaskDetails,
        };

        const serviceTaskDetailsApiRes = await postApi(
          serviceTaskDetailsApiData,
          "workOrder"
        );

        //Error Handling
        if (!serviceTaskDetailsApiRes.success) {
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }

        if (serviceTaskDetailsApiRes.success) {
          //Check if service tasks data, tax, discount have changed to update WO
          if (
            type === "edit" &&
            (serviceTasksChanged ||
              +rowData.tax !== +tax ||
              +rowData.discount !== +discount)
          ) {
            //Check if current service has an associated Work Order
            if (workOrderId.current) {
              const singleWORes = await postApi(
                {
                  querystring: "get_single_workorder",
                  wo_id: workOrderId.current,
                },
                "commonNew"
              );
              if (singleWORes.success === 1) {
                const singleWO = singleWORes.user_data[0];

                const woApiData = {
                  action: 2,
                  sync_version: "v2",
                  source: "web",
                  operation: "update",
                  querystring: "addEditWorkOrder",
                  wo_id: singleWO.wo_id,
                  org_id: orgId,
                  veh_id: singleWO.veh_id,
                  odo: singleWO.odo,
                  wo_number: singleWO.wo_number,
                  type: singleWO.type,
                  status: singleWO.status,
                  priority: singleWO.priority,
                  create_date: singleWO.create_date,
                  created_by: singleWO.created_by,
                  due_selection: singleWO.due_selection,
                  due_date: singleWO.due_date,
                  po_number: singleWO.po_number,
                  invoice_number: singleWO.invoice_number,
                  start_date: singleWO.start_date,
                  end_date: singleWO.end_date,
                  //labor_cost: laborCostData,
                  //parts_cost: partsCostData,
                  // total_cost: {
                  //   parts_total: partsCostTotal,
                  //   labor_cost_total: laborCostTotal,
                  //   total: total,
                  // },
                  //service_task_ids: serviceTaskIds,
                  parts_total: partsCostTotal ? partsCostTotal : 0,
                  labor_total: laborCostTotal ? laborCostTotal : 0,
                  total: total ? total : 0,
                  service_id: singleWO.service_id,
                  tax: tax ? +tax : 0,
                  discount: discount ? +discount : 0,
                  wo_img_name: singleWO.wo_img_name,
                  comments: singleWO.comments,
                  total_time: singleWO.total_time,
                  send_email: singleWO.send_email,
                  technician_id: singleWO.technician_id,
                  logs: singleWO.logs,
                  odo_validation_date: singleWO.odo_validation_date,
                  //issue_ids: issueIds,
                };

                const response = await postApi(woApiData, "workOrder");
              }
            }
          }

          //Upload Images to S3
          if (imagesToS3?.length > 0) {
            const docsUploaded = await uploadDocsToS3(imagesToS3);
            if (docsUploaded) {
              if (mode === "close-issue-with-service") {
                setServiceId(serviceId);
                await closeIssue(serviceId);
              } else {
                dispatch(addMsg(snackbarMsg));
                history.push("/services/services", { fromCancel: false });
                setSaveBtnLoading(false);
              }
            }
          } else {
            if (mode === "close-issue-with-service") {
              setServiceId(serviceId);
              await closeIssue(serviceId);
            } else {
              dispatch(addMsg(snackbarMsg));
              history.push("/services/services", { fromCancel: false });
              setSaveBtnLoading(false);
            }
          }
        } else {
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }

        // //To check if service tasks data, tax, discount have changed to update WO
        // if (
        //   type === "edit" &&
        //   (!areObjsEqual(JSON.parse(rowData.labor_cost), laborCostData) ||
        //     !areObjsEqual(JSON.parse(rowData.parts_cost), partsCostData) ||
        //     +rowData.tax !== +tax ||
        //     +rowData.discount !== +discount)
        // ) {
        //   //Check if current service has an associated Work Order
        //   if (workOrderId.current) {
        //     const singleWORes = await postApi(
        //       {
        //         querystring: "get_single_workorder",
        //         wo_id: workOrderId.current,
        //       },
        //       "commonNew"
        //     );
        //     if (singleWORes.success === 1) {
        //       const singleWO = singleWORes.user_data[0];

        //       const woApiData = {
        //         action: 2,
        //         sync_version: "v2",
        //         source: "web",
        //         operation: "update",
        //         querystring: "addEditWorkOrder",
        //         wo_id: singleWO.wo_id,
        //         org_id: orgId,
        //         veh_id: singleWO.veh_id,
        //         odo: singleWO.odo,
        //         wo_number: singleWO.wo_number,
        //         type: singleWO.type,
        //         status: singleWO.status,
        //         priority: singleWO.priority,
        //         create_date: singleWO.create_date,
        //         created_by: singleWO.created_by,
        //         due_selection: singleWO.due_selection,
        //         due_date: singleWO.due_date,
        //         po_number: singleWO.po_number,
        //         invoice_number: singleWO.invoice_number,
        //         start_date: singleWO.start_date,
        //         end_date: singleWO.end_date,
        //         labor_cost: laborCostData,
        //         parts_cost: partsCostData,
        //         total_cost: {
        //           parts_total: partsCostTotal,
        //           labor_cost_total: laborCostTotal,
        //           total: total,
        //         },
        //         service_task_ids: serviceTaskIds,
        //         service_id: singleWO.service_id,
        //         tax: tax ? +tax : 0,
        //         discount: discount ? +discount : 0,
        //         wo_img_name: singleWO.wo_img_name,
        //         comments: singleWO.comments,
        //         total_time: singleWO.total_time,
        //         send_email: singleWO.send_email,
        //         technician_id: singleWO.technician_id,
        //         //issue_ids: issueIds,
        //       };

        //       const response = await postApi(woApiData, "workOrder");
        //     }
        //   }
        // }

        // if (imagesToS3?.length > 0) {
        //   const docsUploaded = await uploadDocsToS3(imagesToS3);
        //   if (docsUploaded) {
        //     if (mode === "close-issue-with-service") {
        //       setServiceId(serviceId);
        //       await closeIssue(serviceId);
        //     } else {
        //       dispatch(addMsg(snackbarMsg));
        //       history.push("/services/services", { fromCancel: false });
        //       setSaveBtnLoading(false);
        //     }
        //   }
        // } else {
        //   if (mode === "close-issue-with-service") {
        //     setServiceId(serviceId);
        //     await closeIssue(serviceId);
        //   } else {
        //     dispatch(addMsg(snackbarMsg));
        //     history.push("/services/services", { fromCancel: false });
        //     setSaveBtnLoading(false);
        //   }
        // }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      console.log(e);
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showOutOfServicePopup && (
        <OutOfServicePopup
          vehOutOfService={vehOutOfService}
          issueApiData={dataFromIssues.issueApiData}
          showOutOfServicePopup={showOutOfServicePopup}
          setShowOutOfServicePopup={setShowOutOfServicePopup}
          updateIssue={updateIssue}
          serviceId={serviceId}
          setSaveBtnLoading={setSaveBtnLoading}
          setVehicleUpdErr={setVehicleUpdErr}
          setVehicleUpdErrData={setVehicleUpdErrData}
        />
      )}
      {vehicleUpdErr && (
        <InfoPopup
          message={vehicleUpdErrData.message}
          icon={vehicleUpdErrData.icon}
          showPopup={vehicleUpdErr}
          setShowPopup={setVehicleUpdErr}
        />
      )}

      {showTotalDurationPopup && (
        <CustomTimeToCompletePopup
          showPopup={showTotalDurationPopup}
          setShowPopup={setShowTotalDurationPopup}
          customTotalTime={customTotalTime}
          setCustomTotalTime={setCustomTotalTime}
        />
      )}

      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //title={t("add_service")}
          handleCancel={() => {
            if (mode && mode === "from-maintenance-reminders") {
              history.push("/reminders/maintenancereminders", {
                fromCancel: true,
              });
            } else if (mode && mode === "close-issue-with-service") {
              history.push("/issues", {
                fromCancel: true,
              });
            } else {
              history.push("/services/services", { fromCancel: true });
            }
          }}
          disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_service") : t("add_service")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Row>
            <Col md={7} style={{ paddingRight: 0 }}>
              <Card>
                <CardBody
                  style={{
                    height: `${window.innerHeight - 150}px`,
                    overflow: "auto",
                  }}
                >
                  <Row>
                    <Col>
                      <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            //onChange={onChange}
                            onChange={(v) =>
                              handleVehicleOnChange(
                                v,
                                onChange,
                                "servicedBy",
                                setValue,
                                setUserOptions,
                                userId
                              )
                            }
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            isDisabled={
                              (mode && mode === "close-issue-with-service") ||
                              workOrderId.current
                            }
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col>
                      {/* Odometer */}
                      {vehicle && (
                        <Col>
                          <FormGroupLabel>{t("odometer")}</FormGroupLabel>
                          <FormItem
                            showWarning={showHighOdoErr}
                            //disabled={status == 2}
                            //tabIndex={0}
                          >
                            <FormField
                              name="odometer"
                              control={control}
                              component="input"
                              type="number"
                              rules={{ required: t("required_err") }}
                              onChange={(e) => {
                                clearErrors("odometer");
                                setValue("odometer", e.target.value);
                                if (odoFetchedMsg) {
                                  setOdoFetchedMsg("");
                                }
                              }}
                              placeholder={t("odometer")}
                              style={formInputStyles}
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              //disabled={status == 2}
                              onKeyUp={(e) =>
                                handleHighOdoErr(
                                  e,
                                  lastOdoValue,
                                  setShowHighOdoErr
                                )
                              }
                            />
                            <UnitSpan>{odoUnit}</UnitSpan>
                          </FormItem>
                          {odoFetchedMsg && (
                            <HintText>{odoFetchedMsg}</HintText>
                          )}
                          {odoHint && <HintText>{odoHint}</HintText>}
                          {showHighOdoErr && (
                            <Error error={t("high_odo_err_msg")} />
                          )}

                          {errors?.odometer && (
                            <Error error={errors.odometer.message} />
                          )}
                          {/* {errors?.odometer ? (
                            <Error error={errors.odometer.message} />
                          ) : (
                            showHighOdoErr && (
                              <Error error={t("high_odo_err_msg")} />
                            )
                          )} */}
                        </Col>
                      )}
                    </Col>
                  </Row>
                  {vehicle && (
                    <>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("service_date")}</FormGroupLabel>
                          <Controller
                            name="serviceDate"
                            //defaultValue={new Date()}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <DatePicker
                                id="datepicker"
                                maxDate={new Date()}
                                selected={value}
                                onChange={onChange}
                                customInput={
                                  <CustomInput
                                  //disabled={status == 1 || status == 2}
                                  />
                                }
                                placeholderText={t("date_tv")}
                                showMonthDropdown
                                showYearDropdown
                                locale={getDateFnsLocale()}
                                dateFormat={"P"}

                                //disabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{
                              required: t("required_err"),
                            }}
                          />
                          {errors?.serviceDate && (
                            <Error error={errors.serviceDate.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel>{t("service_time")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="serviceTime"
                              control={control}
                              component="input"
                              type="time"
                              style={formInputStyles}
                              disabled={false}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.serviceTime && (
                            <Error error={errors.serviceTime.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("serviced_by")}</FormGroupLabel>
                          <Controller
                            name="servicedBy"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={userOptions}
                                value={value}
                                onChange={onChange}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.servicedBy && (
                            <Error error={errors.servicedBy.message} />
                          )}
                        </Col>
                        <Col>
                          {/* Service Station */}
                          {vehicle && (
                            <Col>
                              <FormGroupLabel>
                                {t("service_station")}
                              </FormGroupLabel>
                              <FormItem
                              //disabled={status == 2}
                              //tabIndex={0}
                              >
                                <FormField
                                  name="serviceStation"
                                  control={control}
                                  component="input"
                                  //type="number"
                                  rules={{ required: false }}
                                  //errors={errors}
                                  // rules={{
                                  //   required:
                                  //     +serviceIntervalDuration || +serviceIntervalOdo
                                  //       ? false
                                  //       : t("odo_or_duration"),
                                  //   min: {
                                  //     value: 0,
                                  //     message: t("greater_than_zero_err"),
                                  //   },
                                  // }}
                                  //defaultValue=""
                                  //isAboveError={isAboveError}
                                  placeholder={t("service_station")}
                                  style={formInputStyles}
                                  //disabled={status == 2}
                                  //onKeyUp={handleSIOdoChange}
                                />

                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.serviceStation && (
                                <Error error={errors.serviceStation.message} />
                              )}
                            </Col>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("service_tasks")}</FormGroupLabel>
                          <ServiceTasks
                            showAddServiceModal={showAddServiceModal}
                            setShowAddServiceModal={setShowAddServiceModal}
                            serviceTasks={serviceTasks}
                            setServiceTasks={setServiceTasks}
                            serviceTaskOptions={serviceTaskOptions}
                            setServiceTaskOptions={setServiceTaskOptions}
                            discount={discount}
                            setDiscount={setDiscount}
                            tax={tax}
                            setTax={setTax}
                            partsCostTotal={partsCostTotal}
                            setPartsCostTotal={setPartsCostTotal}
                            laborCostTotal={laborCostTotal}
                            setLaborCostTotal={setLaborCostTotal}
                            total={total}
                            setTotal={setTotal}
                            grandTotal={grandTotal}
                            setGrandTotal={setGrandTotal}
                            showServiceTasksError={showServiceTasksError}
                            setShowServiceTasksError={setShowServiceTasksError}
                            showDiscountError={showDiscountError}
                            setShowDiscountError={setShowDiscountError}
                            existingFormInfo={existingFormInfo}
                            mode={"from-add-service"}
                            route={"/services/services/addnew"}
                            selectedVehicleId={vehicle.vehicleId}
                            serviceTasksRef={serviceTasksRef}
                            serviceTasksChanged={serviceTasksChanged}
                            setServiceTasksChanged={setServiceTasksChanged}
                            vehicle={vehicle}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginTop: "-2.65rem", width: "fit-content" }}
                      >
                        <Col className="d-flex">
                          <FormGroupLabel
                            style={{
                              margin: "0 0.5rem 0 0",
                              alignSelf: "center",
                            }}
                          >
                            {t("total_duration")}
                          </FormGroupLabel>
                          <FormValueContainer>
                            {customTotalTime.display}
                            <EditIcon
                              className="fi fi-rr-pencil"
                              onClick={() => {
                                setShowTotalDurationPopup(true);
                              }}
                            ></EditIcon>
                          </FormValueContainer>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroupLabel>
                            {t("transaction_type")}
                          </FormGroupLabel>
                          <Controller
                            name="transactionType"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={transactionTypeOptions}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                //onChange={onChange}
                                onChange={(v) => {
                                  onChange(v);
                                  localStorage.setItem(
                                    "transactionType",
                                    v?.value
                                  );
                                }}
                                placeholder={t("transaction_type_placeholder")}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.transactionType && (
                            <Error error={errors.transactionType.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel>
                            {transactionType?.value === "Check"
                              ? t("check_number")
                              : t("card_number")}
                          </FormGroupLabel>
                          <FormItem
                            disabled={transactionType?.value === "Cash"}
                          >
                            <FormField
                              name="cardNo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              //defaultValue=""
                              placeholder={
                                transactionType?.value === "Check"
                                  ? t("check_number")
                                  : t("last_4_digits")
                              }
                              style={formInputStyles}
                              disabled={transactionType?.value === "Cash"}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.cardNo && (
                            <Error error={errors.cardNo.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="comments"
                              control={control}
                              component="input"
                              //defaultValue=""
                              placeholder={t("notes_tv")}
                              style={formInputStyles}
                              disabled={false}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.comments && (
                            <Error error={errors.comments.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>
                            {t("images")}{" "}
                            {tag === "free-user" && (
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                              />
                            )}
                          </FormGroupLabel>
                          <FileUploaderNew
                            disabled={false}
                            images={serviceImages}
                            setImages={setServiceImages}
                            shouldDelete={true}
                          />
                          {/* <FileUploaderContainer>
                            {serviceImages?.map((img) => {
                              return (
                                <ImageWithZoom
                                  key={img.id}
                                  img={img}
                                  //handleDeleteImg={handleDeleteImg}
                                  images={serviceImages}
                                  setImages={setServiceImages}
                                  shouldDelete={true}
                                />
                              );
                            })}

                            <FileUploader
                              images={serviceImages}
                              setImages={setServiceImages}
                              //handleFileUpload={handleFileUpload}
                              //disabled={status == 2}
                            />

                            {false && (
                              <p
                                style={{
                                  textAlign: "center",
                                  width: "-webkit-fill-available",
                                  color: colorGray,
                                }}
                              >
                                No Images Uploaded
                              </p>
                            )}
                          </FileUploaderContainer> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  {/* Assoc Reminders */}
                  {assocReminders.length > 0 && (
                    <Row className="pb-3">
                      <Col>
                        <Row>
                          <Col>
                            <h4>{t("assoc_maint_reminders")}</h4>
                          </Col>
                        </Row>
                        <Row
                          className="pt-2"
                          style={{
                            borderBottom: `1px solid ${colorBorder}`,
                          }}
                        >
                          <Col sm={4}>
                            <ColHeader>{t("service_task_name")}</ColHeader>
                          </Col>
                          <Col sm={8}>
                            <ColHeader>{t("due_on")}</ColHeader>
                          </Col>
                        </Row>
                        <ScrollableRow height="15vh">
                          <Col>
                            {assocReminders.map((reminder) => (
                              <Row className="pt-2">
                                <Col sm={4}>
                                  <RowValue>
                                    {reminder.serviceTaskName}
                                  </RowValue>
                                </Col>
                                <Col sm={8}>
                                  <RowValue>{reminder.dueOnText}</RowValue>
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </ScrollableRow>
                      </Col>
                    </Row>
                  )}

                  {/* Assoc Issues */}
                  {assocIssues.length > 0 && (
                    <Row className="pb-3">
                      <Col>
                        <Row>
                          <Col>
                            <h4>{t("assoc_issues")}</h4>
                          </Col>
                        </Row>
                        <Row
                          className="pt-2"
                          style={{ borderBottom: `1px solid ${colorBorder}` }}
                        >
                          <Col sm={8}>
                            <ColHeader>{t("issue")}</ColHeader>
                          </Col>
                          <Col sm={4}>
                            <ColHeader>{t("reported_date")}</ColHeader>
                          </Col>
                        </Row>
                        <ScrollableRow height="15vh">
                          <Col>
                            {assocIssues.map((issue) => (
                              <>
                                <Row className="pt-2">
                                  <Col sm={8}>
                                    <RowValue>{issue.issueName}</RowValue>
                                  </Col>
                                  <Col sm={4}>
                                    <RowValue>{issue.reportedDate}</RowValue>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Desc>{issue.issueDesc}</Desc>
                                  </Col>
                                </Row>
                              </>
                            ))}
                          </Col>
                        </ScrollableRow>
                      </Col>
                    </Row>
                  )}

                  {/* Assoc Reminders */}
                  {assocWorkOrders.length > 0 && (
                    <Row className="pb-3">
                      <Col>
                        <Row>
                          <Col>
                            <h4>{t("assoc_wo")}</h4>
                          </Col>
                        </Row>
                        <Row
                          className="pt-2"
                          style={{
                            borderBottom: `1px solid ${colorBorder}`,
                          }}
                        >
                          <Col>
                            <ColHeader>{t("work_order_no")}</ColHeader>
                          </Col>
                          <Col>
                            <ColHeader>{t("assigned_technician")}</ColHeader>
                          </Col>
                          <Col>
                            <ColHeader>{t("created_date")}</ColHeader>
                          </Col>
                        </Row>
                        <ScrollableRow height="15vh">
                          <Col>
                            {assocWorkOrders.map((wo) => (
                              <Row className="pt-2">
                                <Col>
                                  <RowValue>{wo.woNumber}</RowValue>
                                </Col>
                                <Col>
                                  <RowValue>{wo.technicianName}</RowValue>
                                </Col>
                                <Col>
                                  <RowValue>{wo.createdDate}</RowValue>
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </ScrollableRow>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

export default ServicesForm;

//styles

export const AddIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  color: black;
  border: 1px solid ${colorLightGray};
  background: white;
  font-weight: 500;
  color: ${colorGray};
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  &:hover {
    background: ${colorLightGray};
  }
`;

export const AddServiceModal = ({
  showAddServiceModal,
  setShowAddServiceModal,
  serviceTaskOptions,
  serviceTasks,
  setServiceTasks,
  serviceFormData,
  serviceImages,
  mode,
  serviceTasksFromAddServiceTask,
  serviceTaskId,
  fromServiceTask,
  setFromServiceTask,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const [selectedTasks, setSelectedTasks] = useState([]);

  // useEffect(() => {
  //   if (mode === "from-add-service-task" && fromServiceTask && serviceTaskId) {
  //     const newServiceTask = serviceTaskOptions?.find(
  //       (t) => t.service_task_id === serviceTaskId
  //     );
  //     console.log(serviceTaskId, newServiceTask);
  //     const temp = [...serviceTasks];
  //     temp.push(newServiceTask);
  //     console.log(temp);
  //     setSelectedTasks(temp);
  //     //setFromServiceTask(false);
  //   }
  // }, [serviceTaskId]);

  useEffect(() => {
    setSelectedTasks(serviceTasks);
  }, [serviceTasks]);

  const handleOK = () => {
    setServiceTasks(selectedTasks);
    setShowAddServiceModal(false);
  };

  const handleCreate = (value) => {
    console.log(value);
    history.push("/services/servicetask/addnew", {
      type: "add",
      mode: "from-add-service",
      redirect: true,
      routeData: {
        serviceTaskName: value,
        serviceData: {
          ...serviceFormData,
          serviceImages,
          serviceTasks: selectedTasks,
        },
      },
    });
  };

  return (
    <Modal
      show={showAddServiceModal}
      onHide={() => setShowAddServiceModal((prev) => !prev)}
    >
      <Modal.Body
        style={
          {
            //height: "-webkit-fill-available",
            //height: `${window.innerHeight - 100}px`,
          }
        }
      >
        <div
          style={{
            height: `${window.innerHeight - 170}px`,
          }}
        >
          <CreatableSelect
            options={serviceTaskOptions}
            value={selectedTasks}
            //value={vehicles.find((c) => c.value === value)}
            //value={serviceTask}
            onChange={setSelectedTasks}
            onCreateOption={handleCreate}
            placeholder={t("service_task_placeholder")}
            styles={openDropdownStyles}
            defaultMenuIsOpen
            isMulti
            menuIsOpen
            isClearable
            isSearchable
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            components={{
              Option: MultiValueOption,
            }}
            //onCreateOption={(data) => console.log(data)}
            //isDisabled={status == 1 || status == 2}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <AddFormButton onClick={handleOK}>{t("ok")}</AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const OutOfServicePopup = ({
  vehOutOfService,
  issueApiData,
  showOutOfServicePopup,
  setShowOutOfServicePopup,
  updateIssue,
  setSaveBtnLoading,
  setVehicleUpdErrData,
  setVehicleUpdErr,
  serviceId,
}) => {
  setSaveBtnLoading(false);
  const vehiclesList = useSelector((state) => state.vehicles.list);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("common");

  const selectedVehicle = vehiclesList?.find(
    (v) => v.vehicleId === issueApiData?.veh_id
  );

  //Button Loading States
  const [yesButtonLoading, setYesButtonLoading] = useState(false);
  const [noButtonLoading, setNoButtonLoading] = useState(false);

  const modalContent = {
    iconClassName: vehOutOfService
      ? "fi fi-rr-info"
      : "fi fi-rr-triangle-warning",
    iconColor: vehOutOfService ? colorBlue : colorYellowNew,
    bodyText: vehOutOfService
      ? t("veh_back_in_service_popup_msg", {
          vehicleName: selectedVehicle?.label,
        })
      : t("close_issue_popup_msg"),
  };

  const handleYes = () => {
    setYesButtonLoading(true);
    (async () => {
      const vehicleApiData = {
        veh_id: selectedVehicle?.vehicleId,
        secondary_status: 0,
        veh_active: 1,
        operation: "update",
      };
      const res = await postApi(vehicleApiData, "vehicle");
      if (res?.success === 1) {
        const res = await updateIssue(serviceId);
        if (res === 1) {
          (async () => {
            const vehiclesRes = await getVehicles("getallvehicle", t);
            dispatch(updateVehiclesList(vehiclesRes));
          })();
          history.push("/issues", { fromCancel: false });
          dispatch(addMsg("Issue Closed"));
          setSaveBtnLoading(false);
          setYesButtonLoading(false);
          setShowOutOfServicePopup(false);
        }
      } else {
        const { vehicle_data } = res;
        if (vehicle_data === "duplicate_entry") {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_err"),
          }));
        } else if (vehicle_data === "vehicle_limit_reached") {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("vehicle_limit_reached_err"),
          }));
        } else if (vehicle_data === "trail_vehicle_limit_reached") {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("trail_vehicle_limit_reached_err"),
          }));
        }
        setYesButtonLoading(false);
        setVehicleUpdErr(true);
        setShowOutOfServicePopup(false);
        return;
      }
    })();
  };

  const handleNo = () => {
    setNoButtonLoading(true);
    (async () => {
      const res = await updateIssue(serviceId);
      if (res === 1) {
        history.push("/issues", { fromCancel: false });
        dispatch(addMsg("Issue Closed"));
        setSaveBtnLoading(false);
        setShowOutOfServicePopup(false);
        setNoButtonLoading(false);
      }
    })();
  };

  return (
    <Modal show={showOutOfServicePopup} centered size="sm">
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer
            onClick={() => setShowOutOfServicePopup(false)}
            id="close-issue-with-service-popup-icon-container"
          >
            <i
              className="fi fi-rr-cross-small"
              id="close-issue-with-service-popup-icon"
            />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-2 p-2">
          <DisplayIcon
            className={modalContent.iconClassName}
            color={modalContent.iconColor}
          />
          <p className="d-flex justify-content-center align-items-center">
            {/* <span> */}
            {modalContent?.bodyText}
            {/* </span> */}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2 gap-3">
          <FilterButton
            onClick={handleNo}
            //onClick={() => setVehicleBackInService("yes")}
            type="button"
          >
            {noButtonLoading ? <CustomLoader /> : t("no")}
          </FilterButton>
          <AddFormButton
            //onClick={() => setVehicleBackInService("no")}
            onClick={handleYes}
            type="button"
          >
            {yesButtonLoading ? <CustomLoader /> : t("yes")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const updateServiceTaskOptions = async (apiData) => {
  const res = await postApi(apiData, "commonNew");
  if (res.success) {
    const { user_data } = res;
    const temp = [];
    user_data.forEach((item) => {
      // if (+item.recurring) {
      temp.push({
        ...item,
        value: item.service_task_id,
        label: item.service_task_name,
        laborCost: "",
        partsCost: "",
        totalCost: "",
      });
      // }
    });
    return temp;
  }
};

//styles

const ScrollableRow = styled(Row)`
  height: ${(p) => p.height};
  overflow: auto;
  ${scrollbarStyles}
`;
