import React from "react";
import { useTranslation } from "react-i18next";

const getDefaultFilterData = () => {
  const { t } = useTranslation("common");

  return {
    defaultPrimaryMeterData: {
      miles: { header: t("miles"), value: false },
      kilometers: { header: t("kilometers"), value: false },
      hours: { header: t("hours_const"), value: false },
      isFiltered: false,
      type: "checkbox",
    },
    defaultRangeData: {
      radioValue: "greater-than",
      radioName: t("greater_than"),
      input_1: "",
      input_2: "",
      isFiltered: false,
    },
    defaultContainsData: {
      radioValue: "is",
      radioName: t("is"),
      input_1: "",
      isFiltered: false,
    },
    defaultFuelTypeData: {
      petrol: { header: t("petrol_const"), value: false },
      diesel: { header: t("diesel_const"), value: false },
      isFiltered: false,
      type: "checkbox",
    },
    defaultTransactionTypeData: {
      cash: { header: t("cash_const"), value: false },
      debitCard: { header: t("debit_card_const"), value: false },
      creditCard: { header: t("credit_card_const"), value: false },
      check: { header: t("check_const"), value: false },
      isFiltered: false,
      type: "checkbox",
    },
    defaultStatusData: {
      scheduled: { header: t("scheduled_caps"), value: false },
      dueSoon: { header: t("due_soon_caps"), value: false },
      overdue: { header: t("overdue_caps"), value: false },
    },
    defaultWOTypeData: {
      scheduled: { header: t("scheduled"), value: false },
      unscheduled: { header: t("unscheduled"), value: false },
      isFiltered: false,
      type: "checkbox",
    },
  };
};

export default getDefaultFilterData;
