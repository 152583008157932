import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import {
  CheckBoxInput,
  FormItem,
  Label,
} from "../../shared/components/form/FormElements";
import { Card, CardBody } from "@/shared/components/Card";

import { ActionIconContainer } from "../../shared/components/TableElements";
import {
  colorGray,
  colorYellowNew,
  scrollbarStyles,
} from "../../utils/palette";
import { formInputStyles } from "../../shared/constants/styles";
import {
  TaskCheckboxInput,
  TaskLabel,
} from "../VehicleInspection/InspectionForms/InspectionFormsForm";

import { postApi } from "../../api/getUser";
import { VehicleImg } from "../../shared/components/table/components/VehicleComponent";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import CheckboxInputComponent from "../../shared/components/form/CheckboxInputComponent";
import InfoPopup from "../../shared/components/InfoPopup";

const VehiclesAssignedForm = ({
  vehiclesAssigned,
  setVehiclesAssigned,
  vehAssignedUpdated,
  setVehAssignedUpdated,
  type,
  rowData,
}) => {
  const { t } = useTranslation("common");
  let vehiclesList = useSelector((state) => state.vehicles.list);
  const activeVehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );
  const [vehicles, setVehicles] = useState(activeVehicles);
  let usersList = useSelector((state) => state.users.list);

  const { orgId, roleId } = useSelector((state) => state.user);

  const [search, setSearch] = useState("");

  const url = "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";

  const [selectAll, setSelectAll] = useState(false);

  const status = useFormContext().watch("status");
  const role = useFormContext().watch("role");
  const isDisabled = type === "edit" && !status;
  const [noUsersAssignedWarningMsg, setNoUsersAssignedWarningMsg] =
    useState("");

  useEffect(() => {
    const activeVehicles = vehiclesList
      .slice(1, vehiclesList.length)
      .filter(
        (v) =>
          v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
      );
    setVehicles(activeVehicles);
  }, [vehiclesList]);

  //To uncheck select all on change of vehiclesAssigned length
  //When search is present, disable selectAll and uncheck it
  useEffect(() => {
    if (search) {
      setSelectAll(false);
    } else {
      if (vehiclesAssigned.length === vehicles.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [vehiclesAssigned, vehicles, search]);

  //Effect to update vehiclesAssigned on edit
  useEffect(() => {
    if (type === "edit" && vehiclesList?.length > 0) {
      //const { user_id } = rowData;
      const user_id = rowData?.user_id;
      (async () => {
        const res = await postApi(
          {
            user_id: user_id,
            querystring: "getoperatorvehicle",
          },
          "commonNew"
        );
        const { user_data } = res;
        if (user_data.length > 0) {
          const selectedVehIds = user_data.map((v) => v.veh_id);
          const selectedVehicles = vehiclesList.filter((v) =>
            selectedVehIds.includes(v.vehicleId)
          );
          setVehiclesAssigned(selectedVehicles);
        }
      })();
    }
  }, [rowData, vehiclesList]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setVehicles((prev) => {
      const temp = activeVehicles.filter((v) => {
        return v.label.toLocaleLowerCase().includes(value.toLocaleLowerCase());
      });
      return temp;
    });
  };

  const checkUserAssigned = async (vehicleId) => {
    const res = await postApi(
      {
        veh_id: vehicleId,
        querystring: "getsinglevehicle",
      },
      "commonNew"
    );
    const { user_data } = res;
    if (user_data.length <= 1) {
      const vehicleName = vehicles.find((v) => v.veh_id === vehicleId)?.name;
      setNoUsersAssignedWarningMsg({
        msg: `You will lose access to ${vehicleName} as there are no users assigned to it. Please make sure that atleast one user is assigned to this vehicle if you want to retain access to it.`,
      });
    }
  };

  const handleCheckboxChange = async (id, checked) => {
    if (!checked && roleId === 2) {
      // const res = await postApi(
      //   {
      //     veh_id: id,
      //     querystring: "getsinglevehicle",
      //   },
      //   "commonNew"
      // );
      // const { user_data } = res;
      // if (user_data.length <= 1) {
      //   setNoUsersAssignedWarningMsg({
      //     msg: "You will lose access to this vehicle as there are no users assigned to it. Please assign atleast one user to this vehicle if you want to retain access to it.",
      //   });
      // }
      await checkUserAssigned(id);
    }
    if (!vehAssignedUpdated) {
      setVehAssignedUpdated(true);
    }
    setVehiclesAssigned((prev) => {
      let temp = [...prev];
      const selected = vehicles.find((v) => v.vehicleId === id);
      if (temp.includes(selected)) {
        temp = temp.filter((v) => v.vehicleId !== selected.vehicleId);
        return temp;
      } else {
        temp.push(selected);
        return temp;
      }
    });
  };

  const handleRemoveClick = async (id) => {
    if (!vehAssignedUpdated) {
      setVehAssignedUpdated(true);
    }

    if (roleId === 2) {
      await checkUserAssigned(id);
    }
    setVehiclesAssigned((prev) => {
      let temp = [...prev];
      temp = temp.filter((v) => v.vehicleId !== id);
      return temp;
    });
  };

  const handleSelectAllChange = async (e) => {
    if (!vehAssignedUpdated) {
      setVehAssignedUpdated(true);
    }
    const { checked } = e.target;
    setSelectAll(checked);

    if (checked) {
      setVehiclesAssigned(activeVehicles.filter((v) => v.vehicleId));
    } else {
      if (roleId === 2) {
        let vehiclesWithNoUserAssigned = [];
        for (let v of vehiclesAssigned) {
          const res = await postApi(
            {
              veh_id: v.veh_id,
              querystring: "getsinglevehicle",
            },
            "commonNew"
          );
          const { user_data } = res;
          if (user_data.length <= 1) {
            vehiclesWithNoUserAssigned.push(v.name);
          }

          if (vehiclesWithNoUserAssigned.length > 0) {
            setNoUsersAssignedWarningMsg({
              msg: "You will lose access to the following vehicles as there are no users assigned to them. Please make sure that atleast one user is assigned to each of these vehicles if you want to retain access to them.",
              listMsg: vehiclesWithNoUserAssigned,
            });
          }

          // if (user_data.length > 0) {
          //   const selectedUserIds = user_data
          //     ?.filter((u) => u.operator_vehicle_action !== 3)
          //     .map((u) => u.user_id);
          //   const selectedUsers = users.filter((u) =>
          //     selectedUserIds.includes(u.user_id)
          //   );
          //   setUsersAssigned(selectedUsers);
          // }
        }
      }
      setVehiclesAssigned([]);
    }
  };

  return (
    <>
      {noUsersAssignedWarningMsg && (
        <InfoPopup
          message={noUsersAssignedWarningMsg?.msg}
          icon={{
            className: "fi fi-rr-triangle-warning",
            color: colorYellowNew,
          }}
          showPopup={noUsersAssignedWarningMsg}
          onClose={() => setNoUsersAssignedWarningMsg("")}
          listMsg={noUsersAssignedWarningMsg?.listMsg}
          //setShowPopup={setShowNoUserAssignedWarning}
        />
      )}
      <Container>
        {role?.value == 4 ? (
          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    height: `${window.innerHeight - 210}px`,
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {t("tech_have_access_to_all_vehicles")}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              md={6}
              //style={{ paddingRight: 0 }}
            >
              <Card
              //style={{ margin: "1rem 0" }}
              >
                <CardBody
                  style={{
                    height: `${window.innerHeight - 200}px`,
                  }}
                >
                  <Row>
                    <Col>
                      <h5 className="mb-2">
                        {t("vehicles_to_be_added_label")}
                      </h5>

                      {/* <Select
                    options={vehiclesList}
                    //value={serviceTasks.find((c) => c.value === value)}
                    value={vehiclesAssigned}
                    //onChange={onChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    placeholder={t("veh_name")}
                    styles={selectStyles}
                    isMulti
                    controlShouldRenderValue={false}
                    isClearable
                    isSearchable
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                    defaultMenuIsOpen
                    menuIsOpen
                    //autoFocus
                  /> */}

                      <FormItem disabled={!status}>
                        <input
                          disabled={!status}
                          type="text"
                          name="vehicle-filter-search"
                          id="vehicle-filter-search"
                          value={search}
                          onChange={handleSearchChange}
                          style={formInputStyles}
                          placeholder={t("veh_name")}
                        />
                      </FormItem>
                      <FormItem
                        className="d-flex justify-content-start px-2"
                        disabled={!status || search}
                      >
                        <CheckboxInputComponent
                          checked={selectAll}
                          setChecked={setSelectAll}
                          id={"select-all-checkbox"}
                          label={t("select_all")}
                          onChange={handleSelectAllChange}
                          disabled={!status || search}
                        />
                      </FormItem>
                      <VehicleListContainer>
                        {vehicles.map((veh) => {
                          return (
                            <div
                              key={veh.vehicleId}
                              className="d-flex align-items-center gap-2 py-2"
                            >
                              <CheckBoxInput
                                type="checkbox"
                                id={veh.vehicleId}
                                //checked={veh.checked}
                                checked={vehiclesAssigned?.includes(veh)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    veh.vehicleId,
                                    e.target.checked
                                  )
                                }
                                disabled={!status}
                              />
                              {/* <i
                            className="fi fi-rs-car-alt"
                            style={{
                              position: "relative",
                              top: "2px",
                              color: colorGray,
                            }}
                          ></i> */}
                              <VehicleImg
                                //hovered={showIcon}
                                src={
                                  veh.veh_img
                                    ? `${url}/org_${orgId}/vehicle_${veh.vehicleId}/${veh.veh_img}`
                                    : defaultVehicleIcon
                                }
                                alt="vehicle-img"
                                // style={{ width: "40px", height: "40px" }}
                                className="rounded-circle"
                              />
                              <Label
                                style={{ fontSize: "14px" }}
                                htmlFor={veh.vehicleId}
                                disabled={!status}
                              >
                                {veh.label}
                              </Label>
                            </div>
                          );
                        })}
                      </VehicleListContainer>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody
                  style={{
                    height: `${window.innerHeight - 200}px`,
                  }}
                >
                  <Row>
                    <Col>
                      <h5 className="mb-2">{t("assigned_vehicles")}</h5>

                      <AssignedVehiclesContainer>
                        {vehiclesAssigned.map((veh) => {
                          return (
                            <div
                              key={veh.vehicleId}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <div className="d-flex gap-3 my-2">
                                {/* <i
                              className="fi fi-rs-car-alt"
                              style={{
                                position: "relative",
                                top: "2px",
                                color: colorGray,
                              }}
                            ></i> */}
                                <VehicleImg
                                  //hovered={showIcon}
                                  src={
                                    veh.veh_img
                                      ? `${url}/org_${orgId}/vehicle_${veh.vehicleId}/${veh.veh_img}`
                                      : defaultVehicleIcon
                                  }
                                  alt="vehicle-img"
                                  // style={{ width: "40px", height: "40px" }}
                                  className="rounded-circle"
                                />
                                <p>{veh.label}</p>
                              </div>
                              {status && (
                                <ActionIconContainer
                                  onClick={() =>
                                    handleRemoveClick(veh.vehicleId)
                                  }
                                >
                                  <i className="fi fi-rs-cross-small" />
                                </ActionIconContainer>
                              )}
                            </div>
                          );
                        })}
                      </AssignedVehiclesContainer>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default VehiclesAssignedForm;

//styles

export const VehicleListContainer = styled.div`
  height: ${window.innerHeight - 358}px;
  overflow: auto;
  ${scrollbarStyles};
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
`;

export const AssignedVehiclesContainer = styled.div`
  height: ${window.innerHeight - 286}px;
  background: #f9f9f9;
  overflow: auto;
  ${scrollbarStyles};
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
  //background: lightgray;
`;

export const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        className="d-flex align-items-center"
        style={{ height: "18px" }}
        {...props}
      >
        <TaskCheckboxInput
          id="task"
          name={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <i
          className="fi fi-rs-car-alt"
          style={{
            position: "relative",
            top: "2px",
            color: colorGray,
          }}
        ></i>
        <TaskLabel htmlFor="task" onClick={(e) => e.preventDefault()}>
          {props.label}
        </TaskLabel>
      </div>
    </components.Option>
  );
};
