import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, newUrl, url } from "../../api/getUser";
import { auth } from "../../api/firebase";

const initialState = {
  list: [],
  status: "idle",
  error: null,
};

export const fetchServicesTasks = createAsyncThunk(
  "servicesTasks/fetchServicesTasks",
  async (apiData) => {
    try {
      const token = await getToken(); //sessionStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const email = auth?.currentUser?.email; //sessionStorage.getItem("email");
      const response = await axios.post(newUrl, { ...apiData, email }, config);
      const data = response?.data[0]?.value?.user_data;
      return data;
    } catch (err) {
      return err.message;
    }
  }
);

const serviceTasksSlice = createSlice({
  name: "servicesTasks",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchServicesTasks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchServicesTasks.fulfilled, (state, action) => {
        state.status = "succeeded";
        const serviceTasksList = [];
        action.payload.forEach((item) => {
          serviceTasksList.push({
            ...item,
            value: item.service_task_id,
            label: item.service_task_name,
            laborCost: "",
            partsCost: "",
            totalCost: "",
          });
        });
        //console.log('action.payload', action.payload);
        state.list = serviceTasksList; //action.payload;
      })
      .addCase(fetchServicesTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default serviceTasksSlice.reducer;
