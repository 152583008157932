import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";

import { colorLightGray, scrollbarStyles } from "../../utils/palette";
import {
  FormGroupLabel,
  FormItem,
} from "../../shared/components/form/FormElements";
import { formInputStyles } from "../../shared/constants/styles";
import {
  ActionIconContainer,
  FilterButton,
} from "../../shared/components/TableElements";

const VehicleSpecsForm = ({
  rowData,
  type,
  vehicleSpecsInfoRef,
  //setVehicleSpecsInfo,
  //vehicleSpecsFields,
  //setVehicleSpecsFields,
}) => {
  const { t } = useTranslation("common");

  const [vehicleSpecsInfo, setVehicleSpecsInfo] = useState({
    width: "",
    height: "",
    length: "",
    weight: "",
    cargoVol: "",
    maxPayload: "",
    frontTireInfo: "",
    frontTirePSI: "",
    rearTireInfo: "",
    rearTirePSI: "",
    fuelQuality: "",
    oil: "",
    oilCapacity: "",
    fuelTankCapacity: "",
  });

  const [vehicleSpecsFields, setVehicleSpecsFields] = useState([]);

  const status = useFormContext().watch("status");
  const isDisabled = !status;

  //Update fields on edit
  useEffect(() => {
    if (rowData) {
      const {
        width,
        height,
        length,
        weight,
        cargo_value,
        max_payload,
        front_tyre_info,
        front_tyre_psi,
        rear_tyre_info,
        rear_tyre_psi,
        fuel_quality,
        oil,
        oil_capacity,
        fuel_tank_capacity,
        custom_specs,
      } = rowData;

      //Logic to set Custom Specs
      if (custom_specs) {
        const customSpecs = custom_specs.split(":::");

        let temp = [];
        let count = 1;
        customSpecs.forEach((spec) => {
          const [key, value] = spec.split("~~");
          temp.push({ title: key, author: value, id: Date.now() + count });
          count += 1;
        });
        setVehicleSpecsFields(temp);
      }

      setVehicleSpecsInfo({
        width: width,
        height: height,
        length: length,
        weight: weight,
        cargoVol: cargo_value,
        maxPayload: max_payload,
        frontTireInfo: front_tyre_info,
        frontTirePSI: front_tyre_psi,
        rearTireInfo: rear_tyre_info,
        rearTirePSI: rear_tyre_psi,
        fuelQuality: fuel_quality,
        oil: oil,
        oilCapacity: oil_capacity,
        fuelTankCapacity: fuel_tank_capacity,
      });
    }
  }, [rowData]);

  //Update vehicleSpecsInfoRef on change of local state data
  useEffect(() => {
    vehicleSpecsInfoRef.current = {
      defaultData: vehicleSpecsInfo,
      additionalData: vehicleSpecsFields,
    };

    console.log(vehicleSpecsInfoRef);
  }, [vehicleSpecsInfo, vehicleSpecsFields]);

  const handleAddFields = () => {
    setVehicleSpecsFields((prev) => {
      const temp = [...prev];
      temp.push({ id: Date.now(), title: "", author: "" });
      return temp;
    });
  };

  const handleFieldChange = (e, id) => {
    const { name, value } = e.target;
    //console.log(name, value, id);
    setVehicleSpecsFields((prev) =>
      //let temp = [];
      prev.map((field) => {
        if (field.id === id) {
          return { ...field, [name]: value };
        } else {
          return field;
        }
      })
    );
  };

  const handleRemoveField = (id) => {
    setVehicleSpecsFields((prev) => prev.filter((field) => field.id !== id));
  };

  const handleVehicleSpecsChange = (e) => {
    const { name, value } = e.target;
    setVehicleSpecsInfo((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <>
      <Container className="p-4">
        <Row>
          <Col md={8}>
            <Heading>{t("vehicle_specifications")}</Heading>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("width")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="width"
                name="width"
                value={vehicleSpecsInfo.width}
                onChange={handleVehicleSpecsChange}
                placeholder={t("width")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("height")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="height"
                name="height"
                value={vehicleSpecsInfo.height}
                onChange={handleVehicleSpecsChange}
                placeholder={t("height")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("length")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="length"
                name="length"
                value={vehicleSpecsInfo.length}
                onChange={handleVehicleSpecsChange}
                placeholder={t("length")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("weight")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="weight"
                name="weight"
                value={vehicleSpecsInfo.weight}
                onChange={handleVehicleSpecsChange}
                placeholder={t("weight")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("cargo_volume")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="cargoVol"
                name="cargoVol"
                value={vehicleSpecsInfo.cargoVol}
                onChange={handleVehicleSpecsChange}
                placeholder={t("cargo_volume")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("max_payload")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="maxPayload"
                name="maxPayload"
                value={vehicleSpecsInfo.maxPayload}
                onChange={handleVehicleSpecsChange}
                placeholder={t("max_payload")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        {/* Tire Inforamtion */}
        <Row className="pt-4">
          <Col md={8}>
            <Heading>{t("tire_information")}</Heading>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("front_tyre_info")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="frontTireInfo"
                name="frontTireInfo"
                value={vehicleSpecsInfo.frontTireInfo}
                onChange={handleVehicleSpecsChange}
                placeholder={t("front_tyre_info")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("front_tyre_psi")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="frontTirePSI"
                name="frontTirePSI"
                value={vehicleSpecsInfo.frontTirePSI}
                onChange={handleVehicleSpecsChange}
                placeholder={t("front_tyre_psi")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("rear_tyre_info")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="rearTireInfo"
                name="rearTireInfo"
                value={vehicleSpecsInfo.rearTireInfo}
                onChange={handleVehicleSpecsChange}
                placeholder={t("rear_tyre_info")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("rear_tyre_psi")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="rearTirePSI"
                name="rearTirePSI"
                value={vehicleSpecsInfo.rearTirePSI}
                onChange={handleVehicleSpecsChange}
                placeholder={t("rear_tyre_psi")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        {/* Fluid Information */}
        <Row className="pt-4">
          <Col md={8}>
            <Heading>{t("fluid_information")}</Heading>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("fuel_quality")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="fuelQuality"
                name="fuelQuality"
                value={vehicleSpecsInfo.fuelQuality}
                onChange={handleVehicleSpecsChange}
                placeholder={t("fuel_quality")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("oil")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="oil"
                name="oil"
                value={vehicleSpecsInfo.oil}
                onChange={handleVehicleSpecsChange}
                placeholder={t("oil")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroupLabel>{t("oil_capacity")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="oilCapacity"
                name="oilCapacity"
                value={vehicleSpecsInfo.oilCapacity}
                onChange={handleVehicleSpecsChange}
                placeholder={t("oil_capacity")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col md={4}>
            <FormGroupLabel>{t("fuel_tank_capacity")}</FormGroupLabel>
            <FormItem disabled={isDisabled}>
              <input
                type="text"
                id="fuelTankCapacity"
                name="fuelTankCapacity"
                value={vehicleSpecsInfo.fuelTankCapacity}
                onChange={handleVehicleSpecsChange}
                placeholder={t("fuel_tank_capacity")}
                style={formInputStyles}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        </Row>

        {/* Custom Specifications */}
        {(!isDisabled || vehicleSpecsFields.length > 0) && (
          <Row className="pt-4">
            <Col md={8}>
              <Heading>{t("custom_specifications")}</Heading>
            </Col>
          </Row>
        )}

        {vehicleSpecsFields.length > 0 && (
          <Row>
            <Col md={3} sm={5} xs={5}>
              <FormGroupLabel>{t("detail_title")}</FormGroupLabel>
            </Col>
            <Col md={3} sm={5} xs={5}>
              <FormGroupLabel>{t("detail")}</FormGroupLabel>
            </Col>
          </Row>
        )}
        {vehicleSpecsFields?.map((field) => {
          return (
            <React.Fragment key={field.id}>
              <Row className="d-flex align-items-center">
                <Col
                  md={3}
                  sm={5}
                  xs={5}
                  className="d-flex align-items-center gap-2 mt-2 mb-2"
                >
                  <FormItem
                    disabled={isDisabled}
                    //style={{ width: "160px" }}
                    //disabled={status == 2}
                    //tabIndex={0}
                  >
                    <input
                      type="text"
                      name="title"
                      id="field-title"
                      value={field.title}
                      style={formInputStyles}
                      onChange={(e) => handleFieldChange(e, field.id)}
                      disabled={isDisabled}
                    />
                  </FormItem>
                </Col>

                <Col
                  md={3}
                  sm={5}
                  xs={5}
                  className="d-flex align-items-center gap-2 mt-2 mb-2"
                >
                  <FormItem disabled={isDisabled}>
                    <input
                      type="text"
                      name="author"
                      id="field-author"
                      value={field.author}
                      style={formInputStyles}
                      onChange={(e) => handleFieldChange(e, field.id)}
                      disabled={isDisabled}
                    />
                  </FormItem>
                </Col>
                {!isDisabled && (
                  <Col className="mt-2 mb-2">
                    <ActionIconContainer
                      onClick={() => handleRemoveField(field.id)}
                    >
                      <i className="fi fi-rr-cross-small" />
                    </ActionIconContainer>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          );
        })}

        {!isDisabled && (
          <Row className="pt-3">
            <Col>
              <FilterButton type="button" onClick={handleAddFields}>
                + {t("add_fields")}
              </FilterButton>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default VehicleSpecsForm;

//styles

const Heading = styled.h5`
  border-bottom: 1px solid ${colorLightGray};
`;

const ContainerWrap = styled(Container)`
  height: ${window.innerHeight - 200}px;
  overflow: auto;
  ${scrollbarStyles}
`;
