import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storedFormData: {},
};

const formDataSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.storedFormData = action.payload;
    },
    clearFormData: (state, action) => {
      state.storedFormData = {};
    },
  },
});

export const { updateFormData, clearFormData } = formDataSlice.actions;

export default formDataSlice.reducer;
