import React from "react";
import GroupsTable from "./GroupsTable";
import { Switch, Route } from "react-router-dom";

export default () => (
  <Switch>
    <Route exact path="/setup/groups" component={GroupsTable} />
    {/* <Route exact path="/setup/groups/addnew" component={UserManagementForm} /> */}
  </Switch>
);
