import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  FilterButton,
} from "../TableElements";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import ImportColMappingComponent from "./ImportColMappingComponent";
import Error from "@/shared/components/form/Error";
import ImportFileUploader from "./ImportFileUploader";
import Select from "react-select";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
} from "../../constants";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { useSelector } from "react-redux";
import { Steps } from "primereact/steps";
import { scrollbarStyles } from "../../../utils/palette";
import styled from "styled-components";
import { useEffect } from "react";
import { post, postApi } from "../../../api/getUser";
import { TableSpinner } from "../table/components/ReactTableConstructor";
import ImportCompleteComponent from "./ImportCompleteComponent";
import { getScreenName } from "../../helpers";
import { useAuth } from "../../../contexts/AuthContext";
import ErrorDetailsTable from "./ErrorDetailsTable";
import { auth, firebaseConfig } from "../../../api/firebase";
import {
  createUserWithEmailAndPassword,
  getAuth,
  inMemoryPersistence,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getImportData } from "./importData";
import useWebSocket from "react-use-websocket";
import { formStyles } from "../../constants/styles";
import { useRef } from "react";
import { fetchServicesTasks } from "../../../features/servicesTasks/servicesTasksSlice";
import { fetchExpenseTasks } from "../../../features/expenseTasks/expenseTasksSlice";
import { useDispatch } from "react-redux";
import SubscribePopup from "../SubscribePopup";

const ImportComponent = ({ showPopup, setShowPopup, screenName }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const { distance } = useSelector((state) => state.units);
  const { userId } = useSelector((state) => state.user);

  const allUsers = useSelector((state) => state.users.list);
  const loggedInUser = allUsers.find((u) => u.user_id === userId);

  const [screen, setScreen] = useState(screenName ? screenName : "");
  const [selectModule, setSelectModule] = useState(null);

  const { orgId } = useSelector((state) => state.user);

  const [importFile, setImportFile] = useState(null);
  const [mode, setMode] = useState(0);

  const [errMsg, setErrMsg] = useState("");

  const [selectValues, setSelectValues] = useState({});
  const [dateFormat, setDateFormat] = useState(null);

  //const [disableImport, setDisableImport] = useState(false);

  const [importLoading, setImportLoading] = useState(false);

  const [issuesArr, setIssuesArr] = useState([]);

  const { setReload, tag } = useAuth();

  const [showErrDetails, setShowErrDetails] = useState(false);

  const [noOfRowsInserted, setNoOfRowsInserted] = useState(0);

  const didUnmount = useRef(false);

  const [scrollableBodyHeight, setScrollableBodyHeight] = useState(400);

  const {
    options,
    otherMandatoryFields,
    queryString,
    screenHeading,
    sampleFileName,
  } = getImportData(screen);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const webSocketApi =
    process.env.REACT_APP_ENV === "test"
      ? "wss://3dg5rjnwel.execute-api.ap-south-1.amazonaws.com/test/"
      : "wss://tk15odkbyb.execute-api.us-east-1.amazonaws.com/prod/";

  const { sendMessage, lastMessage, getWebSocket, readyState } = useWebSocket(
    webSocketApi,
    {
      onOpen: () => console.log("Opened at " + new Date()),
      onClose: () => console.log("Closed at " + new Date()),
      onMessage: (event) => {
        //console.log("readyState", getWebSocket().OPEN);
        //console.log(JSON.parse(event.data));
        handleOnMessage(event.data);
      },

      shouldReconnect: (closeEvent) => {
        /*
          useWebSocket will handle unmounting for you, but this is an example of a 
          case in which you would not want it to automatically reconnect
        */
        return didUnmount.current === false;
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  );

  const moduleOptions = [
    {
      label: t("fillups"),
      value: "fillups",
    },
    {
      label: t("se_mileage"),
      value: "dailymileage",
    },
    {
      label: t("trips"),
      value: "trips",
    },
    {
      label: t("services"),
      value: "service",
    },
    {
      label: t("expenses"),
      value: "expense",
    },
    {
      label: t("service_tasks"),
      value: "servicetasks",
    },
    {
      label: t("expense_tasks"),
      value: "expensetasks",
    },
    {
      label: t("inspection_tasks"),
      value: "inspectiontasks",
    },
    {
      label: t("maintenance_reminders"),
      value: "maintenancereminders",
    },
    {
      label: t("renewals"),
      value: "renewals",
    },
    {
      label: t("inspection_forms"),
      value: "inspectionforms",
    },
    {
      label: t("vehicles"),
      value: "vehicles",
    },
    {
      label: t("users"),
      value: "users",
    },
    {
      label: t("groups"),
      value: "groups",
    },
    {
      label: t("vehicle_assignment"),
      value: "vehicleassignment",
    },
  ];

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  //Set scrollableBodyHeight
  useEffect(() => {
    if (screenName) {
      if (errMsg) {
        setScrollableBodyHeight(window.innerHeight - 340);
      } else {
        setScrollableBodyHeight(window.innerHeight - 330);
      }
    } else {
      if (errMsg) {
        setScrollableBodyHeight(window.innerHeight - 360);
      } else {
        setScrollableBodyHeight(window.innerHeight - 340);
      }
    }
  }, [errMsg, screenName]);

  function handleOnMessage(data) {
    if (data) {
      console.log(data);
      const parsedRes = JSON.parse(data);
      const res = parsedRes[0]?.value;
      if (res.success) {
        handleIssueResponse(res);
        didUnmount.current = true;
        getWebSocket().close();
      }
      //console.log(JSON.parse(data));
    }
  }

  // useEffect(() => {
  //   if (lastMessage !== null) {
  //     //setMessageHistory((prev) => prev.concat(lastMessage));
  //     setMessageHistory((prev) => prev.concat(lastMessage));
  //   }
  // }, [lastMessage, setMessageHistory]);

  // let socket = new WebSocket(
  //   "wss://3dg5rjnwel.execute-api.ap-south-1.amazonaws.com/test/"
  // );
  // // Listen for possible errors
  // socket.addEventListener("error", (event) => {
  //   console.log("WebSocket error: ", event);
  // });

  // socket.onopen = function (event) {
  //   console.log("Connected to: " + event.currentTarget.url);
  // };

  // socket.onclose = function (event) {
  //   console.log("Closed: " + event.currentTarget.url);
  // };

  // socket.onmessage = function (event) {
  //   console.log("Websocket data", event);
  // };

  // const { options, otherMandatoryFields, queryString, screenHeading } =
  //   getImportData(screen);

  const modalHeading = t("import_screen_into_sf", {
    screenName: screenName ? screenHeading : t("data"),
  });

  const stepItems = [
    {
      label: t("upload_file"),
    },
    {
      label: t("map_cols"),
    },
    {
      label: t("import_file"),
    },
  ];

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleBack = () => {
    setSelectValues({});
    setDateFormat({});
    setErrMsg("");
    setImportFile(null);
    setMode(0);
  };

  const handleOkay = () => {
    setShowPopup(false);
  };

  const createFirebaseUser = async (email, password) => {
    const tempApp = initializeApp(firebaseConfig, email);
    const tempAuth = getAuth(tempApp);
    tempAuth.setPersistence(inMemoryPersistence);

    const res = await createUserWithEmailAndPassword(tempAuth, email, password);
    console.log(res);
    return res.user;
    //const user = res?.user;
  };

  //get the index of whatever's available - name(Vehicle Name), license_no, vin_no
  const getVehicleDataIndex = () => {
    let vehIndex = 0;
    let selectValuesArr = Object.values(selectValues).map((o) => o.value);

    if (selectValuesArr.includes("name")) {
      const vehNameEntry = Object.entries(selectValues).find(
        (arr) => arr[1].value === "name"
      );

      vehIndex = +vehNameEntry[0];
      //console.log("vehIndex", vehIndex);
      return vehIndex;
    }

    if (selectValuesArr.includes("license_no")) {
      const licenseEntry = Object.entries(selectValues).find(
        (arr) => arr[1].value === "license_no"
      );

      vehIndex = +licenseEntry[0];
      return vehIndex;
    }

    if (selectValuesArr.includes("vin_no")) {
      const vinEntry = Object.entries(selectValues).find(
        (arr) => arr[1].value === "vin_no"
      );

      vehIndex = +vinEntry[0];
      return vehIndex;
    }

    return vehIndex;
  };

  //To convert from "1,2,3" to [1, 2, 3]
  const convertToNumberedIndex = (str) =>
    str
      ? str
          .split(",")
          .filter((val) => val)
          .map((val) => +val)
      : [];

  const handleIssueResponse = async (res) => {
    console.log("res", res);
    const issueRowStringArr = convertToNumberedIndex(
      res.insert_issue_row_string
    );
    //console.log("issueRowStringArr", issueRowStringArr);
    //if (issueRowStringArr.length > 0) {
    let issuesArrData = [];
    if (screen === "vehicles") {
      const {
        //insert_issue_row_string,
        vehicle_name_empty_rows,
        make_empty_rows,
        model_empty_rows,
        veh_name_present_rows,
        fuel_type_warning_rows,
        primary_meter_warning_rows,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const makeEmptyRows = convertToNumberedIndex(make_empty_rows);
      const modelEmptyRows = convertToNumberedIndex(model_empty_rows);
      const vehNamePresentRows = convertToNumberedIndex(veh_name_present_rows);
      //const vinPresentRows = convertToNumberedIndex(vin_present_rows);
      //const licensePresentRows = convertToNumberedIndex(license_present_rows);
      const fuelTypeWarningRows = convertToNumberedIndex(
        fuel_type_warning_rows
      );
      const primaryMeterWarningRows = convertToNumberedIndex(
        primary_meter_warning_rows
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...makeEmptyRows,
        ...modelEmptyRows,
        ...vehNamePresentRows,
        //...vinPresentRows,
        //...licensePresentRows,
        ...fuelTypeWarningRows,
        ...primaryMeterWarningRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      console.log(allIssuesSortedIndicesArr);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_name_empty_err_msg"),
          };
        } else if (makeEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_make_empty_err_msg"),
          };
        } else if (modelEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_model_empty_err_msg"),
          };
        } else if (vehNamePresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_name_present_err_msg"),
          };
        }
        // else if (vinPresentRows.includes(rowIndex)) {
        //   return {
        //     rowIndex,
        //     type: "error",
        //     message: t("vin_present_err_msg"),
        //   };
        // }
        // else if (licensePresentRows.includes(rowIndex)) {
        //   return {
        //     rowIndex,
        //     type: "error",
        //     message: t("license_present_err_msg"),
        //   };
        // }
        else if (fuelTypeWarningRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("fuel_type_warn_msg"),
          };
        } else if (primaryMeterWarningRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("primary_meter_warn_msg", {
              defaultPrimaryMeter: distance,
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "servicetasks") {
      const {
        //insert_issue_row_string,
        //vehicle_name_empty_rows,
        //veh_name_present_rows,
        //vin_present_rows,
        //license_present_rows,
        veh_not_found_string,
        vehicle_name_empty_rows,
        service_task_name_empty_rows,
        record_present_rows,
      } = res;

      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const serviceTaskNameEmptyRows = convertToNumberedIndex(
        service_task_name_empty_rows
      );
      const recordPresentRows = convertToNumberedIndex(record_present_rows);

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNotFoundRows,
        ...vehNameEmptyRows,
        ...serviceTaskNameEmptyRows,
        ...recordPresentRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      let vehDataIndex = getVehicleDataIndex();
      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            // message: `${
            //   importFile?.rows[rowIndex][vehDataIndex]
            //     ? importFile?.rows[rowIndex][vehDataIndex]
            //     : t("vehicle")
            // } ${t("not_found")}`,
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("veh_empty_err_msg"),
          };
        } else if (serviceTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("service_task_name"),
            }),
          };
        } else if (recordPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity: t("service_task_name"),
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "expensetasks") {
      const {
        veh_not_found_string,
        vehicle_name_empty_rows,
        expense_task_name_empty_rows,
        record_present_rows,
      } = res;

      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const expenseTaskNameEmptyRows = convertToNumberedIndex(
        expense_task_name_empty_rows
      );
      const recordPresentRows = convertToNumberedIndex(record_present_rows);

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNotFoundRows,
        ...expenseTaskNameEmptyRows,
        ...recordPresentRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      let vehDataIndex = getVehicleDataIndex();
      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (recordPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity: t("expense_task_name"),
            }),
          };
        } else if (expenseTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("expense_task_name"),
            }),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("veh_empty_err_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "inspectiontasks") {
      const {
        veh_not_found_string,
        inspection_task_name_empty_rows,
        record_present_rows,
      } = res;

      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const inspectionTaskNameEmptyRows = convertToNumberedIndex(
        inspection_task_name_empty_rows
      );
      const recordPresentRows = convertToNumberedIndex(record_present_rows);

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNotFoundRows,
        ...inspectionTaskNameEmptyRows,
        ...recordPresentRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      let vehDataIndex = getVehicleDataIndex();
      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (inspectionTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("inspection_task_name"),
            }),
          };
        } else if (recordPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity: t("inspection_task_name"),
            }),
          };
        } else if (
          vehNotFoundRows.includes(rowIndex) &&
          !issueRowStringArr.includes(rowIndex)
        ) {
          return {
            rowIndex,
            type: "warning",
            // message: `${
            //   importFile?.rows[rowIndex][vehDataIndex]
            //     ? importFile?.rows[rowIndex][vehDataIndex]
            //     : t("vehicle")
            // } ${t("not_found")}`,
            message: t("veh_does_not_exist_err_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "users") {
      const {
        //insert_issue_row_string,
        user_name_empty_rows,
        user_email_empty_rows,
        email_present_rows,
        email_present_in_other_org_rows,
        user_role_type_warning_rows,
        invalid_user_email_rows,
        manager_not_exist_warning_rows,
      } = res;

      const userNameEmptyRows = convertToNumberedIndex(user_name_empty_rows);
      const userEmailEmptyRows = convertToNumberedIndex(user_email_empty_rows);
      const emailPresentRows = convertToNumberedIndex(email_present_rows);
      const emailPresentInOtherOrgRows = convertToNumberedIndex(
        email_present_in_other_org_rows
      );
      //const vinPresentRows = convertToNumberedIndex(vin_present_rows);
      //const licensePresentRows = convertToNumberedIndex(license_present_rows);
      const userRoleTypeWarningRows = convertToNumberedIndex(
        user_role_type_warning_rows
      );
      const noManagerWarningRows = convertToNumberedIndex(
        manager_not_exist_warning_rows
      );

      const invalidUserEmailRows = convertToNumberedIndex(
        invalid_user_email_rows
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...userNameEmptyRows,
        ...userEmailEmptyRows,
        ...emailPresentRows,
        ...emailPresentInOtherOrgRows,
        ...invalidUserEmailRows,
        ...userRoleTypeWarningRows,
        ...noManagerWarningRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      console.log(allIssuesSortedIndicesArr);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (userNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("name") }),
          };
        } else if (userEmailEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("email") }),
          };
        } else if (emailPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("email_present_err_msg"),
          };
        } else if (emailPresentInOtherOrgRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("email_part_of_other_org_err_msg"),
          };
        } else if (invalidUserEmailRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("invalid_email_format_err_msg"),
          };
        } else if (userRoleTypeWarningRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("invalid_role_type_warn_msg"),
          };
        } else if (noManagerWarningRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("entity_does_not_exist_warn_msg", {
              entity: t("manager"),
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });

      setIssuesArr(issuesArrData);

      //Add users to firebase - start
      //console.log(importFile?.rows);
      let usersToFirebase = importFile.rows.filter(
        (row, i) => !issueRowStringArr.includes(i + 1)
      );

      let emailIndex = 1;
      const emailEntry = Object.entries(selectValues).find(
        (arr) => arr[1].value === "email"
      );
      emailIndex = +emailEntry[0];

      let passwordIndex = 1;
      const passwordEntry = Object.entries(selectValues).find(
        (arr) => arr[1].value === "password"
      );
      passwordIndex = +passwordEntry[0];

      console.log("usersToFirebase", usersToFirebase);
      const userAddedSuccess = [];

      if (usersToFirebase.length === 0) {
        setImportLoading(false);
        setMode(2);
        setReload(getScreenName(pathname));
      } else {
        for (let user of usersToFirebase) {
          console.log(user[emailIndex], user[passwordIndex]);
          const res = await createFirebaseUser(
            user[emailIndex],
            user[passwordIndex]
          );
          if (res) {
            userAddedSuccess.push(res.user);
            if (userAddedSuccess.length === usersToFirebase.length) {
              setImportLoading(false);
              setMode(2);
              setReload(getScreenName(pathname));
            }
          }
        }
      }
      //Add users to firebase - end
    }

    if (screen === "fillups") {
      const {
        //insert_issue_row_string,
        vehicle_name_empty_rows,
        veh_not_found_string,
        date_not_found_string,
        qty_not_found_string,
        odo_not_found_string,
        filled_by_exist_warning_rows_string,
        transaction_type_not_found_warning_string,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const dateNotFoundRows = convertToNumberedIndex(date_not_found_string);
      const qtyNotFoundRows = convertToNumberedIndex(qty_not_found_string);
      const odoNotFoundRows = convertToNumberedIndex(odo_not_found_string);

      const filledByExistsRows = convertToNumberedIndex(
        filled_by_exist_warning_rows_string
      );
      const transactionTypeNotFoundRows = convertToNumberedIndex(
        transaction_type_not_found_warning_string
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...dateNotFoundRows,
        ...qtyNotFoundRows,
        ...odoNotFoundRows,
        ...filledByExistsRows,
        ...transactionTypeNotFoundRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (dateNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("date_tv") }),
          };
        } else if (qtyNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("fuel_qty") }),
          };
        } else if (odoNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("odo_value") }),
          };
        } else if (filledByExistsRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("filled_by_user_does_not_exist_warn_msg", {
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else if (transactionTypeNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("transaction_type_warn_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "dailymileage") {
      const {
        veh_not_found_string,
        user_not_found_string,
        vehicle_name_empty_rows,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const userNotFoundRows = convertToNumberedIndex(user_not_found_string);

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...userNotFoundRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (userNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("entity_does_not_exist_warn_msg", {
              entity: t("entered_by"),
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    //Trips
    if (screen === "trips") {
      const {
        vehicle_name_empty_rows,
        veh_not_found_string,
        dep_date_not_found_string,
        arr_date_not_found,
        arr_odo_not_found_string,
        dep_odo_not_found_string,
        dep_odo_greaterthan_arr_odo_rows_string,
        dep_date_greaterthan_arr_date_rows_string,
        trip_by_exist_warning_rows_string,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const depDateNotFoundRows = convertToNumberedIndex(
        dep_date_not_found_string
      );
      const arrDateNotFoundRows = convertToNumberedIndex(arr_date_not_found);
      const depOdoNotFoundRows = convertToNumberedIndex(
        dep_odo_not_found_string
      );
      const arrOdoNotFoundRows = convertToNumberedIndex(
        arr_odo_not_found_string
      );
      const depOdoGreaterThanArrOdoRows = convertToNumberedIndex(
        dep_odo_greaterthan_arr_odo_rows_string
      );
      const depDateGreaterThanArrDateRows = convertToNumberedIndex(
        dep_date_greaterthan_arr_date_rows_string
      );
      const tripByExistsRows = convertToNumberedIndex(
        trip_by_exist_warning_rows_string
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...depDateNotFoundRows,
        ...arrDateNotFoundRows,
        ...depOdoNotFoundRows,
        ...arrOdoNotFoundRows,
        ...depOdoGreaterThanArrOdoRows,
        ...depDateGreaterThanArrDateRows,
        ...tripByExistsRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (depDateNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_not_found_err_msg", {
              entity: t("dep_date_and_time"),
            }),
          };
        } else if (arrDateNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_not_found_err_msg", {
              entity: t("arr_date_and_time"),
            }),
          };
        } else if (depOdoNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_not_found_err_msg", {
              entity: t("starting_odo"),
            }),
          };
        } else if (arrOdoNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_not_found_err_msg", {
              entity: t("ending_odo"),
            }),
          };
        } else if (depOdoGreaterThanArrOdoRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("dep_odo_greater_than_arr_odo_err_msg"),
          };
        } else if (depDateGreaterThanArrDateRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("dep_date_greater_than_arr_date_err_msg"),
          };
        } else if (tripByExistsRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "warning",
            message: t("entity_does_not_exist_warn_msg", {
              entity: t("trip_by"),
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "maintenancereminders" || screen === "renewals") {
      if (screen === "maintenancereminders") {
        //Fetch all service tasks
        (async () => {
          const apiData = {
            //email: "testgroup@12.in",
            querystring: "getallservicetask",
            service_veh_id: "All",
          };
          dispatch(fetchServicesTasks(apiData));
          // }
        })();
      } else {
        //Fetch all expense tasks
        (async () => {
          const apiData = {
            querystring: "getallexpensetask",
            service_veh_id: "All",
          };
          dispatch(fetchExpenseTasks(apiData));
        })();
      }

      const {
        //insert_issue_row_string,
        vehicle_name_empty_rows,
        veh_not_found_string,
        service_task_name_empty_rows,
        due_odo_due_days_empty_rows,
        email_recipient_not_found_warning_rows,
        negative_due_on_date_rows,
        negative_due_on_odo_rows,
        record_present_rows,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const serviceTaskNameEmptyRows = convertToNumberedIndex(
        service_task_name_empty_rows
      );
      const dueEmptyRows = convertToNumberedIndex(due_odo_due_days_empty_rows);
      const emailNotFoundRows = convertToNumberedIndex(
        email_recipient_not_found_warning_rows
      );
      const recordPresentRows = convertToNumberedIndex(record_present_rows);
      const negativeDueOnDateRows = convertToNumberedIndex(
        negative_due_on_date_rows
      );
      const negativeDueOnOdoRows = convertToNumberedIndex(
        negative_due_on_odo_rows
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...serviceTaskNameEmptyRows,
        ...dueEmptyRows,
        ...emailNotFoundRows,
        ...recordPresentRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (serviceTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName:
                screen === "maintenancereminders"
                  ? t("service_task_name")
                  : t("expense_task_name"),
            }),
          };
        } else if (dueEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("interval_cant_be_empty_err_msg"),
          };
        } else if (recordPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity:
                screen === "maintenancereminders"
                  ? t("maint_reminder")
                  : t("renewal"),
            }),
          };
        } else if (negativeDueOnDateRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message:
              screen === "maintenancereminders"
                ? t("negative_due_on_date_err_msg_service")
                : t("negative_due_on_date_err_msg_expense"),
          };
        } else if (negativeDueOnOdoRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message:
              screen === "maintenancereminders"
                ? t("negative_due_on_odo_err_msg_service")
                : t("negative_due_on_odo_err_msg_expense"),
          };
        } else if (
          emailNotFoundRows.includes(rowIndex) &&
          !issueRowStringArr.includes(rowIndex)
        ) {
          return {
            rowIndex,
            type: "warning",
            message: t("entity_does_not_exist_warn_msg", {
              entity: t("email"),
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    if (screen === "service" || screen === "expense") {
      if (screen === "service") {
        //Fetch all service tasks
        (async () => {
          const apiData = {
            //email: "testgroup@12.in",
            querystring: "getallservicetask",
            service_veh_id: "All",
          };
          dispatch(fetchServicesTasks(apiData));
          // }
        })();
      } else {
        //Fetch all expense tasks
        (async () => {
          const apiData = {
            querystring: "getallexpensetask",
            service_veh_id: "All",
          };
          dispatch(fetchExpenseTasks(apiData));
        })();
      }
      const {
        //insert_issue_row_string,
        vehicle_name_empty_rows,
        veh_not_found_string,
        service_task_name_empty_rows,
        odo_not_found_string,
        date_not_found_string,
        serviced_by_exist_warning_rows_string,
        transaction_type_not_found_warning_string,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const serviceTaskNameEmptyRows = convertToNumberedIndex(
        service_task_name_empty_rows
      );

      const odoNotFoundRows = convertToNumberedIndex(odo_not_found_string);
      const dateNotFoundRows = convertToNumberedIndex(date_not_found_string);

      const servicedByNotFoundRows = convertToNumberedIndex(
        serviced_by_exist_warning_rows_string
      );

      const transactionTypeNotFoundRows = convertToNumberedIndex(
        transaction_type_not_found_warning_string
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...serviceTaskNameEmptyRows,
        ...dateNotFoundRows,
        ...odoNotFoundRows,
        ...servicedByNotFoundRows,
        ...transactionTypeNotFoundRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (serviceTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName:
                screen === "service"
                  ? t("service_task_name")
                  : t("expense_task_name"),
            }),
          };
        } else if (dateNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName:
                screen === "service" ? t("service_date") : t("date_tv"),
            }),
          };
        } else if (odoNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", { fieldName: t("odo_value") }),
          };
        } else if (
          servicedByNotFoundRows.includes(rowIndex) &&
          !issueRowStringArr.includes(rowIndex)
        ) {
          return {
            rowIndex,
            type: "warning",
            message: t("entity_does_not_exist_warn_msg", {
              entity: t("email"),
              entityDefaultValue: loggedInUser?.label,
            }),
          };
        } else if (
          transactionTypeNotFoundRows.includes(rowIndex) &&
          !issueRowStringArr.includes(rowIndex)
        ) {
          return {
            rowIndex,
            type: "warning",
            message: t("transaction_type_warn_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    //Inspection Forms
    if (screen === "inspectionforms") {
      const {
        inspection_task_name_empty_rows,
        inspection_form_name_empty_rows,
        record_present_rows,
      } = res;

      const inspectionTaskNameEmptyRows = convertToNumberedIndex(
        inspection_task_name_empty_rows
      );
      const inspectionFormNameEmptyRows = convertToNumberedIndex(
        inspection_form_name_empty_rows
      );

      const recordPresentRows = convertToNumberedIndex(record_present_rows);

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...inspectionTaskNameEmptyRows,
        ...inspectionFormNameEmptyRows,
        ...recordPresentRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      let vehDataIndex = getVehicleDataIndex();
      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (inspectionTaskNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("inspection_task"),
            }),
          };
        } else if (inspectionFormNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("inspection_form_name"),
            }),
          };
        } else if (recordPresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity: t("inspection_form_name"),
            }),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    //Groups
    if (screen === "groups") {
      const {
        group_name_present_rows,
        group_name_empty_rows,
        group_level_reached_rows,
        parent_not_found_rows,
      } = res;

      const groupNamePresentRows = convertToNumberedIndex(
        group_name_present_rows
      );
      const groupNameEmptyRows = convertToNumberedIndex(group_name_empty_rows);
      const parentNotFoundRows = convertToNumberedIndex(parent_not_found_rows);
      const groupLevelReachedRows = convertToNumberedIndex(
        group_level_reached_rows
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...groupNamePresentRows,
        ...groupNameEmptyRows,
        ...parentNotFoundRows,
        ...groupLevelReachedRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (groupNamePresentRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("entity_already_exists_err_msg", {
              entity: t("group_name"),
            }),
          };
        } else if (groupNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("field_empty_err_msg", {
              fieldName: t("group_name"),
            }),
          };
        } else if (groupLevelReachedRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("group_level_err_msg"),
          };
        } else if (parentNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("parent_group_not_found_err_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    //Vehicle Assignment
    if (screen === "vehicleassignment") {
      const {
        vehicle_name_empty_rows,
        veh_not_found_string,
        user_name_email_empty_rows_string,
        user_name_email_not_found_rows_string,
      } = res;

      const vehNameEmptyRows = convertToNumberedIndex(vehicle_name_empty_rows);
      const vehNotFoundRows = convertToNumberedIndex(veh_not_found_string);
      const userEmailEmptyRows = convertToNumberedIndex(
        user_name_email_empty_rows_string
      );
      const userEmailNotFoundRows = convertToNumberedIndex(
        user_name_email_not_found_rows_string
      );

      const allIssuesSet = new Set([
        ...issueRowStringArr,
        ...vehNameEmptyRows,
        ...vehNotFoundRows,
        ...userEmailEmptyRows,
        ...userEmailNotFoundRows,
      ]);

      const allIssuesSortedIndicesArr = [...allIssuesSet].sort((a, b) => a - b);

      issuesArrData = allIssuesSortedIndicesArr.map((rowIndex) => {
        if (vehNameEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_empty_err_msg"),
          };
        } else if (vehNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("veh_does_not_exist_err_msg"),
          };
        } else if (userEmailEmptyRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("user_empty_err_msg"),
          };
        } else if (userEmailNotFoundRows.includes(rowIndex)) {
          return {
            rowIndex,
            type: "error",
            message: t("user_does_not_exist_err_msg"),
          };
        } else {
          return {
            rowIndex,
            type: "error",
            message: "Issue at row " + rowIndex,
          };
        }
      });
    }

    console.log("importFile", importFile);
    console.log("issueRowStringArr", issueRowStringArr.length);

    setNoOfRowsInserted(importFile?.rows?.length - issueRowStringArr?.length);
    if (screen !== "users") {
      //This condition to wait for users to be added to firebase before setting these states
      //These states are set in users if block so don't need to be set here again
      setIssuesArr(issuesArrData);
      setReload(screen);
      setImportLoading(false);
      setMode(2);
    }
    //}
  };

  const handleImport = async () => {
    try {
      //console.log(selectValues);
      const colIndices = Object.keys(selectValues).map((index) => +index);
      const colOptions = Object.values(selectValues);
      const colOptionValues = colOptions.map((o) => o.value);
      //console.log(colIndices);
      //console.log(colOptions);

      // importFile?.columns?.forEach((col) => {
      //   if (colIndices.includes(col.colIndex)) {
      //     mappingData[colOptions[+col.colIndex].value] = +col.colIndex;
      //   } else {
      //     mappingData["skip"] = +col.colIndex;
      //   }
      // });
      let dbOptions = options.slice(1, options.length);
      let mappingData = {};

      dbOptions.forEach((option, i) => {
        if (colOptionValues.includes(option.value)) {
          let colOption = colOptions.find((o) => o.value === option.value);

          //Logic for inserting multiple indices for service/expense tasks
          if (colOption?.isMultiple) {
            let temp = "";
            colOptions
              .filter((option) => option?.isMultiple)
              .forEach((colOption) => {
                //console.log(colOption);
                if (colOption.checked) {
                  temp += `${colOption.index}:`;
                }
              });
            if (temp) {
              temp = temp.slice(0, temp.length - 1);
              mappingData[colOption.value] = temp;
            } else {
              mappingData[colOption.value] = 999;
            }
          } else {
            if (colOption.checked) {
              mappingData[option.value] = colOption.index;
            } else {
              mappingData[option.value] = 999;
            }
          }
        } else {
          mappingData[option.value] = 999;
        }
      });

      //console.log("mappingData", mappingData);

      //Check if atleast one of name(Vehicle Name), license_no or vin_no is selected
      const screensWithVehicles = [
        "dailymileage",
        "vehicles",
        "fillups",
        "service",
        "expense",
        "maintenancereminders",
        "renewals",
        "vehicleassignment",
      ];
      if (screensWithVehicles.includes(screen)) {
        if (
          mappingData["name"] === 999 &&
          mappingData["license_no"] === 999 &&
          mappingData["vin_no"] === 999
        ) {
          setErrMsg(t("veh_license_or_vin_reqd_err_msg"));
          return;
        } else {
          setErrMsg("");
        }
      }

      //Mandatory fields check
      if (
        screen !== "maintenancereminders" &&
        screen !== "renewals" &&
        screen !== "vehicleassignment"
      ) {
        for (let option of otherMandatoryFields) {
          if (mappingData[option.value] === 999) {
            setErrMsg(
              t("import_mandatory_field_err_msg", { colName: option.label })
            );
            return;
          } else {
            setErrMsg("");
          }
        }
      }

      //Custom mandatory fields check for maintenace reminders and renewals
      if (screen === "maintenancereminders" || screen === "renewals") {
        // if (screen === "maintenancereminders") {
        if (mappingData.service_task_name === 999) {
          setErrMsg(
            t("import_mandatory_field_err_msg", {
              colName:
                screen === "maintenancereminders"
                  ? t("service_task_name")
                  : t("expense_task_name"),
            })
          );
          return;
        } else {
          setErrMsg("");
        }
        // }

        // if (screen === "renewals") {
        //   if (mappingData.expense_task_name === 999) {
        //     setErrMsg(
        //       t("import_mandatory_field_err_msg", {
        //         colName: t("expense_task_name"),
        //       })
        //     );
        //     return;
        //   } else {
        //     setErrMsg("");
        //   }
        // }

        if (mappingData.due_odo === 999 && mappingData.due_days === 999) {
          setErrMsg(t("odo_or_days_interval_reqd_err_msg"));
          return;
        } else {
          setErrMsg("");
        }
      }

      //Custom mandatory fields check for vehicle assignment
      if (screen === "vehicleassignment") {
        if (mappingData["user_name"] === 999 && mappingData["email"] === 999) {
          setErrMsg(t("user_name_or_email_reqd_err_msg"));
          return;
        } else {
          setErrMsg("");
        }
      }

      //Check if all columns that are checked are also mapped. If not SetErrMsg'
      for (let option of colOptions) {
        if (option.checked && option.value === "skip") {
          setErrMsg(
            t("column_not_mapped_err_msg", { colName: option.colName })
          );
          return;
        } else {
          setErrMsg("");
        }
      }

      let dateFormatData = {};

      for (let key of Object.keys(dateFormat)) {
        if (mappingData[key] === 999) {
          continue;
        }

        if (dateFormat[key]?.value) {
          setErrMsg("");
          dateFormatData[key] = dateFormat[key]?.value;
        } else {
          setErrMsg(t("date_format_mandatory_err_msg"));
          return;
        }

        // if (!dateFormat[key]?.value && mappingData[key] !== 999) {
        //   setErrMsg(
        //     "Date format is a mandatory field, please make sure it is selected."
        //   );
        //   return;
        // } else {
        //   setErrMsg("");
        //   dateFormatData[key] = dateFormat[key]?.value;
        // }
      }

      if (readyState === getWebSocket().OPEN) {
        setImportLoading(true);
        const fileName = `${Date.now()}.csv`;

        const s3UploadRes = await uploadCSVToS3(importFile?.file, fileName);
        if (s3UploadRes === 200) {
          const email = auth?.currentUser?.email;
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const apiData = {
            action: "import_data",
            email: email,
            mapping_data: mappingData,
            date_format: dateFormatData,
            querystring: queryString,
            //table_name: "STARTING_ENDING_MILEAGE",
            file_name: fileName,
            // locale: window.navigator.language
            //   ? window.navigator.language
            //   : "en-US",
            // time_zone: timeZone ? timeZone : "Asia/Calcutta",
            utc_offset: new Date().getTimezoneOffset(),
          };
          //const res = await postApi(apiData, "import");
          // console.log("api json", apiData);

          //console.log(readyState);

          if (screen === "maintenancereminders") {
            console.log("api json", { ...apiData, type: 0 });
            sendMessage(JSON.stringify({ ...apiData, type: 0 }));
          } else if (screen === "renewals") {
            console.log("api json", { ...apiData, type: 1 });
            sendMessage(JSON.stringify({ ...apiData, type: 1 }));
          } else {
            console.log("api json", apiData);
            sendMessage(JSON.stringify(apiData));
          }
        }
      } else {
        console.log("readyState", getWebSocket().readyState);
        setErrMsg(t("websocket_connection_closed_err_msg"));
        return;
      }

      //socket.send(JSON.stringify(apiData));
    } catch (e) {
      console.log(e);
    }
  };

  const uploadCSVToS3 = async (file, fileName) => {
    try {
      const client = new S3Client({
        region: "us-east-2",
        credentials: fromCognitoIdentityPool({
          identityPoolId: IDENTITY_POOL_ID,
          client: new CognitoIdentityClient({ region: BUCKET_REGION }),
        }),
        apiVersion: "2006-03-01",
      });

      const command = new PutObjectCommand({
        Bucket: ALBUM_BUCKET_NAME,
        Key: `csv_data/org_${orgId}/${fileName}`,
        Body: file,
        ContentDisposition: "inline",
        ContentType: "text/csv",
        ACL: "public-read",
      });
      const response = await client.send(command);
      //console.log("s3 response", response);
      const statusCode = response.$metadata.httpStatusCode;
      console.log("statusCode in method", statusCode);
      return statusCode;
      //statusCodeArr.push(statusCode);
    } catch (err) {
      console.error("s3 error", err);
      return err;
    }
  };

  return (
    <>
      {showErrDetails && (
        <ErrorDetailsTable
          showPopup={showErrDetails}
          setShowPopup={setShowErrDetails}
          issuesArr={issuesArr}
          importFile={importFile}
          selectValues={selectValues}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
          onGoToBilling={() => setShowPopup(false)}
        />
      )}
      <Modal show={showPopup} size="lg" style={{ zIndex: 999 }}>
        <Modal.Header>
          <h5>
            {/* {mode === 1
              ? "Match the CSV’s file’s columns to Simply Fleet fields"
              : modalHeading} */}
            {modalHeading}
          </h5>
          <div
            className="d-flex justify-content-end"
            //style={{ width: "-webkit-fill-available" }}
          >
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            height: !screenName
              ? window.innerHeight - 200
              : window.innerHeight - 232,
          }}
        >
          {!screenName && (
            <div className="d-flex gap-1 align-items-center mb-3">
              <span>{t("import_data_for")} :</span>
              <Select
                id="module-select"
                options={moduleOptions}
                value={selectModule}
                onChange={(option) => {
                  if (tag === "free-user") {
                    setShowSubscribePopup(true);
                    return;
                  }
                  setSelectModule(option);
                  setScreen(option?.value);
                }}
                //onCreateOption={handleCreate}
                placeholder={t("select_module")}
                styles={{
                  ...formStyles,

                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: "34px",
                    width: "220px",
                  }),
                }}
                isDisabled={mode === 1 || mode === 2}
              />
            </div>
          )}
          {screen && <Steps model={stepItems} activeIndex={mode} />}
          {importLoading ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                // height: screenName
                //   ? window.innerHeight - 335
                //   : window.innerHeight - 285,
                height: window.innerHeight - 315,
              }}
            >
              <TableSpinner animation="border" />
              <span>{t("import_in_prog")}</span>
            </div>
          ) : (
            <>
              {screen && (
                <ScrollableBody isErr={errMsg} height={scrollableBodyHeight}>
                  {mode === 0 && (
                    <ImportFileUploader
                      importFile={importFile}
                      setImportFile={setImportFile}
                      mode={mode}
                      setMode={setMode}
                      selectValues={selectValues}
                      setSelectValues={setSelectValues}
                      errMsg={errMsg}
                      setErrMsg={setErrMsg}
                      sampleFileName={sampleFileName}
                    />
                  )}

                  {mode === 1 && (
                    <ImportColMappingComponent
                      importFile={importFile}
                      setImportFile={setImportFile}
                      errMsg={errMsg}
                      setErrMsg={setErrMsg}
                      selectValues={selectValues}
                      setSelectValues={setSelectValues}
                      dateFormat={dateFormat}
                      setDateFormat={setDateFormat}
                      screenName={screen}
                    />
                  )}

                  {mode === 2 && (
                    <ImportCompleteComponent
                      issuesArr={issuesArr}
                      importFile={importFile}
                      selectValues={selectValues}
                      showErrDetails={showErrDetails}
                      setShowErrDetails={setShowErrDetails}
                      noOfRowsInserted={noOfRowsInserted}
                    />
                  )}
                </ScrollableBody>
              )}
            </>
          )}
          {errMsg && (
            <div className="pt-1">
              <Error error={errMsg} />
            </div>
          )}
        </Modal.Body>
        {/* {mode === 0 && (
            <div className="d-flex justify-content-start">
              <AddFormButton
                type="button"
                onClick={handleNext}
                disabled={!importFile}
              >
                Next
                </AddFormButton>
                </div>
              )} */}
        {mode === 1 && (
          <Modal.Footer>
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton
                type="button"
                id="import-back-btn"
                onClick={handleBack}
                disabled={importLoading}
              >
                {t("back")}
              </FilterButton>
              <AddFormButton
                type="button"
                id="import-btn"
                onClick={handleImport}
                disabled={importLoading}
              >
                {t("import")}
              </AddFormButton>
            </div>
          </Modal.Footer>
        )}
        {mode === 2 && (
          <Modal.Footer>
            <AddFormButton
              type="button"
              id="ok-btn"
              onClick={handleOkay}
              //disabled={disableImport || importLoading}
            >
              {t("ok")}
            </AddFormButton>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ImportComponent;

//styles
const ScrollableBody = styled.div`
  height: ${(p) => p.height}px;
  overflow: auto;
  ${scrollbarStyles}
  margin-top: 1rem;
`;
