import { Route, Switch } from "react-router";
import InspectionSubmissionsTable from "./InspectionSubmissionsTable";
import InspectionSubmissionsForm from "./InspectionSubmissionsForm";

export default () => (
  <Switch>
    <Route
      exact path="/inspection/inspectionsubmissions/"
      component={InspectionSubmissionsTable}
    />
    <Route
      path="/inspection/inspectionsubmissions/addnew"
      component={InspectionSubmissionsForm}
    />
  </Switch>
);