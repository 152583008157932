// import axios from "axios";
// import { darken, lighten } from "polished";
// import React from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import { useMemo } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { url } from "../../../api/getUser";
// import RowOptions from "../../../shared/components/table/components/RowOptions";
// import {
//   colorBlue,
//   colorBlueHover,
//   colorLightBlue,
// } from "../../../utils/palette";
// import DataReactTable from "../../DataTable/components/DataReactTable";

// const InspectionTasks = () => {
//   const [rowData, setRowData] = useState([]);

//   useEffect(() => {
//     (async () => {
//       const response = await axios.post(url, {
//         email: "testgroup@12.in",
//         querystring: "get_all_inspection_tasks",
//       });
//       //console.log(response);
//       const data = response?.data[0]?.value?.user_data;
//       //console.log(data);
//       setRowData(data);
//     })();
//   }, []);

//   const rows = [];
//   rowData.forEach((row) => {
//     rows.push({
//       task_id: row.task_id ? row.task_id : "N/A",
//       task_name: row.task_name ? row.task_name : "N/A",
//       org_id: row.org_id ? row.org_id : "N/A",
//       description: row.description ? row.description : "N/A",
//       pass_required: row.pass_required,
//       image_required: row.image_required,
//       value_required: row.value_required,
//       last_updated_at: row.last_updated_at ? row.last_updated_at : "N/A",
//       action: row.action ? row.action : "N/A",
//     });
//   });

//   const columnHeaders = useMemo(
//     () => [
//       {
//         id: 1,
//         Header: "Inspection Task Name",
//         accessor: "task_name",
//         //disableGlobalFilter: true,
//       },
//       {
//         id: 2,
//         Header: "Task Attributes",
//         accessor: "task_attributes",
//         disableGlobalFilter: true,
//         disableSortBy: true,
//         Cell: ({ row }) => <AttributesComponent row={row} />,
//       },
//       {
//         id: 3,
//         Header: "",
//         accessor: "edit",
//         accessorId: "edit",
//         Cell: () => <RowOptions />,
//         disableSortBy: true,
//       },
//     ],
//     []
//   );

//   const reactTableData = {
//     tableHeaderData: columnHeaders,
//     tableRowsData: rows,
//   };
//   return (
//     <Container>
//       <Row>
//         <DataReactTable
//           reactTableData={reactTableData}
//           //valuesArray={servicesValuesArray}
//           //initialData={initialData}
//           //filterToggleColumns={filterToggleColumns}
//           //defaultColumns={defaultColumns}
//           //columnSizeData={columnSizeData}
//         />
//       </Row>
//     </Container>
//   );
// };

// export default InspectionTasks;

// const AttributesComponent = ({ row }) => {
//   const { pass_required, value_required, image_required } = row.original;
//   const styles = {
//     //border: '1px solid lightgray',
//     borderRadius: "5px",
//     padding: "5px",
//     letterSpacing: 0.5,
//     //fontSize: 14,
//     //fontWeight: 600,
//     //color: 'darkgreen',
//     //background: 'lightgreen'
//   };
//   return (
//     <div className="d-flex gap-2">
//       {pass_required ? (
//         <div
//           style={{
//             ...styles,
//             color: "darkblue",
//             background: lighten(0.22, colorLightBlue),
//           }}
//         >
//           Pass/Fail
//         </div>
//       ) : (
//         ""
//       )}
//       {value_required ? <div style={{
//             ...styles,
//             color: lighten(0.1,"green"),
//             background: lighten(0.22, 'lightgreen'),
//           }}>Info</div> : ""}
//       {image_required ? <div style={{
//             ...styles,
//             color: '#a74b22',
//             background: '#FFEECC',
//           }}>Photo</div> : ""}
//     </div>
//   );
// };

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InspectionTasks from './InspectionTasksTable';
import InspectionTasksForm from './InspectionTasksForm';

export default () => (
  <Switch>
    <Route exact path="/inspection/inspectiontasks" component={InspectionTasks} />
    <Route path="/inspection/inspectiontasks/addnew" component={InspectionTasksForm} />
  </Switch>
);
