import React from "react";
import { Modal } from "react-bootstrap";
import { ActionIconContainer } from "./TableElements";
import { useTranslation } from "react-i18next";

const ExplorePopup = ({ showPopup, setShowPopup, exploreLink }) => {
  const { t } = useTranslation("common");
  return (
    <Modal size="lg" show={showPopup}>
      <Modal.Header>
        <h4>{t("explore")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={() => setShowPopup(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: "70vh",
            width: "auto",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <iframe
            src={exploreLink}
            loading="lazy"
            //webkitAllowFullScreen
            //mozAllowFullScreen
            allowFullScreen
            style={{
              width: "100%",
              height: "100%",
              colorScheme: "light",
              //position: "absolute",
              top: 0,
              left: 0,
              border: 0,
              padding: 0,
            }}
            //style="position: absolute; top: 0;border:0;padding:0; left: 0; width: 100%; height: 100%;color-scheme: light;"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExplorePopup;
