import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import getUnixTime from "date-fns/getUnixTime";
import { postApi, putApi } from "../../api/getUser";

import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectStylesNew } from "../../shared/constants/styles";
import { FormGroupLabel } from "../../shared/components/form/FormElements";
import { Card, CardBody } from "../../shared/components/Card";
import { TreeSelect } from "primereact/treeselect";
import { ColumnHeading, KPIValue } from "../../shared/components/TableElements";
import {
  colorLightGray,
  colorYellowNew,
  scrollbarStyles,
} from "../../utils/palette";
import DisplayTable from "./DisplayTable";
import CombinedDateFilter from "../../shared/components/table/components/CombinedDateFilter";
import ChartsComponent from "./ChartsComponent";
import {
  convertToFilterDate,
  daysHoursMinutes,
  generateTreeArr,
  getDistanceUnit,
  isObjEmpty,
} from "../../shared/helpers";
import MissedInspectionsTable from "./MissedInspectionsTable";
import { NATEXT } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { useRef } from "react";
import SingleVehicleInfo from "./SingleVehicleInfo";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import InfoPopup from "../../shared/components/InfoPopup";
import { updateExternalFilter } from "../../features/storedFilters/storedFiltersSlice";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import startOfMonth from "date-fns/startOfMonth";
import startOfYesterday from "date-fns/startOfYesterday";
import endOfYesterday from "date-fns/endOfYesterday";
import startOfYear from "date-fns/startOfYear";
import endOfDay from "date-fns/endOfDay";

const Dashboard = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const {
    isLoggedIn,
    showSubscriptionExpiredPopup,
    setShowSubscriptionExpiredPopup,
    vehicle,
    setVehicle,
    groupId,
    setGroupId,
    vehicles,
    setVehicles,
    groups,
    setGroups,
  } = useAuth();

  const { pathname } = useLocation();

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const usersList = useSelector((state) => state.users.list);
  const allVehicles = vehiclesList?.slice(1, vehiclesList.length);

  const { userId, orgDate } = useSelector((state) => state.user);

  const [loggedInUser, setLoggedInUser] = useState(
    usersList.find((u) => u.user_id === userId)
  );

  const { currency, consumption, hourlyConsumption, distance, volume } =
    useSelector((state) => state.units);

  const groupsList = useSelector((state) => state.groups.list);

  const [graphData, setGraphData] = useState(null);
  const [statsValue, setStatsValue] = useState(NATEXT);

  const [verticalActiveKey, setVerticalActiveKey] = useState(1);

  const [kpiData, setKpiData] = useState([
    {
      accessor: "totalCostOfOwnership",
      label: t("total_cost_of_ownership"),
      value: NATEXT,
    },
    {
      accessor: "totalExpense",
      label: t("total_expense"),
      value: NATEXT,
    },
    {
      accessor: "vehicleDowntime",
      label: t("vehicle_downtime"),
      value: NATEXT,
    },
    {
      accessor: "outOfService",
      label: t("veh_out_of_service"),
      value: NATEXT,
    },
    {
      accessor: "overdueReminders",
      label: t("overdue_reminders"),
      value: NATEXT,
    },
    {
      accessor: "avgEff",
      label: `${t("average")} ${consumption}`,
      value: NATEXT,
    },
    // {
    //   accessor: "overdueRenewals",
    //   label: "Overdue Renewals",
    //   value: NATEXT,
    // },
    {
      accessor: "openIssues",
      label: t("open_issues"),
      value: NATEXT,
    },
    {
      accessor: "openWO",
      label: t("open_wo"),
      value: NATEXT,
    },
  ]);

  const [unixDate, setUnixDate] = useState({
    from: getUnixTime(subDays(new Date(), 89)),
    to: getUnixTime(new Date()),
  });

  //const [toggleDateFilter, setToggleDateFilter] = useState(false);

  const [statsQuery, setStatsQuery] = useState("get_total_cost_custom");
  const [graphQuery, setGraphQuery] = useState("gettotalcostgraph");

  const [remindersData, setRemindersData] = useState({});
  const [renewalsData, setRenewalsData] = useState({});
  const [overdueReminders, setOverdueReminders] = useState({
    maintenances: 0,
    renewals: 0,
  });
  const [missedInspectionData, setMissedInspectionsData] = useState({
    tableData: [],
    totalVehicleCount: 0,
  });

  const totalCostRefData = useRef(null);

  const [delayLoad, setDelayLoad] = useState(false);

  const [externalDatePreset, setExternalDatePreset] = useState({
    filterText: t("last_90_days"),
    from: subDays(startOfToday(), 89),
    to: endOfToday(),
    dateOption: "last-90-days",
  });

  const storedFilters = useSelector((state) => state.storedFilters);

  const dateOptionsObj = {
    today: t("today"),

    yesterday: t("yesterday"),

    "last-7-days": t("last_7_days"),

    "last-30-days": t("last_30_days"),

    "last-90-days": t("last_90_days"),

    "this-month": t("this_month"),

    "this-year": t("this_year"),

    "all-time": t("all_time"),

    custom: t("custom"),
  };

  useEffect(() => {
    const dashboardFilters = storedFilters.dashboard;

    //const dateFilter = dashboardFilters.find((f) => f?.filterName === "date");

    const dashboardDateFilter = localStorage.getItem("dashboardDateFilter");
    if (dashboardDateFilter) {
      const dateFilter = JSON.parse(dashboardDateFilter);

      if (dateFilter) {
        //console.log("dateFilter", dateFilter);
        //const { filterText, from, to, dateOption } = dateFilter?.value; Old Way

        const { filterText, from, to, dateOption } = dateFilter;
        let fromDate = from ? new Date(from * 1000) : subDays(new Date(), 89);
        let toDate = to ? new Date(to * 1000) : new Date();

        if (dateOption === "all-time") {
          toDate = endOfToday();
          fromDate = 0; //subDays(new Date(), 89);
        } else if (dateOption === "last-7-days") {
          toDate = endOfToday();
          fromDate = subDays(new Date(), 6);
        } else if (dateOption === "this-month") {
          toDate = endOfToday();
          fromDate = startOfMonth(new Date());
        } else if (dateOption === "today") {
          fromDate = startOfToday();
          toDate = endOfToday();
        } else if (dateOption === "last-30-days") {
          toDate = endOfToday();
          fromDate = subDays(new Date(), 29);
          dateOption;
        } else if (dateOption === "yesterday") {
          fromDate = startOfYesterday();
          toDate = endOfYesterday();
        } else if (dateOption === "last-90-days") {
          toDate = endOfToday();
          fromDate = subDays(new Date(), 89);
        } else if (dateOption === "this-year") {
          toDate = endOfToday();
          fromDate = startOfYear(new Date());
        }
        //fromDate and toDate in case of custom will what whatever from and to dates were saved to localStorage

        //Get the filter text to display from dateOptionsObj since we want to show the translated string
        let filterTextDisplay = dateOptionsObj[dateOption];
        if (dateOption === "custom") {
          //If dateOption=custom, then date will be in from-to format so translation is not required
          filterTextDisplay = filterText;
        }
        setExternalDatePreset({
          filterText: filterTextDisplay ? filterTextDisplay : t("last_90_days"),
          from: startOfDay(fromDate),
          to: endOfDay(toDate),
          dateOption: dateOption ? dateOption : "last-90-days",
        });

        setUnixDate({ from: from, to: to });
      }
    }

    setTimeout(() => {
      setDelayLoad(true);
    }, 1000);
  }, []);

  //Effect to set loggedInUser
  useEffect(() => {
    if (userId && usersList?.length > 0) {
      setLoggedInUser(usersList.find((u) => u.user_id === userId));
    }
  }, [usersList, userId]);

  // //Update Reminders/Renewals kpi on change of reminders/renewals data
  // useEffect(() => {
  //   let overdueReminders = 0;
  //   let overdueRenewals = 0;
  //   if (
  //     remindersData &&
  //     remindersData?.overdue &&
  //     remindersData?.overdue?.length > 0
  //   ) {
  //     overdueReminders = remindersData.overdue?.length;
  //   }

  //   if (
  //     renewalsData &&
  //     renewalsData?.overdue &&
  //     renewalsData?.overdue?.length > 0
  //   ) {
  //     overdueRenewals = renewalsData.overdue?.length;
  //   }

  //   setKpiData((prev) => {
  //     let temp = [...prev];
  //     return temp.map((kpi) => {
  //       if (kpi.accessor === "overdueReminders") {
  //         return {
  //           ...kpi,
  //           value: `${overdueReminders} Maintenances / ${overdueRenewals} Renewals`,
  //         };
  //       } else {
  //         return kpi;
  //       }
  //     });
  //   });
  // }, [remindersData, renewalsData]);

  //Generate and set GroupsList(tree) && Vehicles List,
  // useEffect(() => {
  //   if (groupsList.length > 0) {
  //     const groupsTreeArr = generateTreeArr(groupsList);
  //     setGroups(groupsTreeArr);
  //     //setGroupId(groupsTreeArr[0]?.key);
  //   }

  //   if (vehiclesList.length > 0) {
  //     setVehicles(vehiclesList);
  //   }
  // }, [vehiclesList, groupsList]);

  //Update vehicles on change of group
  // useEffect(() => {
  //   const selectedGroup = groupsList.find((g) => g.group_id === groupId);
  //   if (groupId === undefined) {
  //     setVehicle("");
  //   } else if (groupId === "all-groups") {
  //     setVehicles(vehiclesList);
  //     setVehicle({
  //       id: 0,
  //       label: "All Vehicles",
  //       value: "",
  //       vehicleId: "",
  //     });
  //   } else {
  //     const temp = vehiclesList.filter((singleVehicle) => {
  //       if (
  //         singleVehicle?.id === 0 ||
  //         groupId === singleVehicle?.group_id ||
  //         (selectedGroup?.groupNodes &&
  //           selectedGroup.groupNodes.includes(singleVehicle?.group_id))
  //       ) {
  //         return singleVehicle;
  //       }
  //     });
  //     setVehicles(temp);
  //     //setVehicle(vehiclesList[0]);
  //   }
  //   const value = groupId ? groupId : "all-groups";
  //   //setGroupId(value);
  //   //dispatch(addGroupId(value));
  // }, [groupId]);

  useEffect(() => {
    if (graphQuery && vehicle && groupId && loggedInUser && delayLoad) {
      (async () => {
        setGraphData(null);
        await getGraphData();
      })();
    }
  }, [graphQuery, vehicle, groupId, unixDate, loggedInUser, delayLoad]);

  useEffect(() => {
    if (
      currency &&
      distance &&
      vehicle &&
      groupId &&
      pathname.includes("dashboard") &&
      delayLoad
    ) {
      (async () => {
        //setStatsValue(NATEXT);
        await getStatsData();
      })();
    }
  }, [statsQuery, vehicle, groupId, unixDate, currency, distance, delayLoad]);

  //Effect on change of vehicle and group
  useEffect(() => {
    if (isLoggedIn && vehiclesList.length > 0 && delayLoad) {
      (async () => {
        await getMissedInspectionsData();
      })();
    }
  }, [vehicle, groupId, isLoggedIn, vehiclesList, delayLoad]);

  //Effect on change of vehicle, group and date
  useEffect(() => {
    if (
      isLoggedIn &&
      currency &&
      consumption &&
      vehicle &&
      groupId &&
      pathname.includes("dashboard") &&
      delayLoad
    ) {
      (async () => {
        await getRemindersData("get_maintenance_reminders", setRemindersData);
      })();

      (async () => {
        await getRemindersData("get_renewals_reminders", setRenewalsData);
      })();

      (async () => {
        const getKpiDataRes = await getKpiData("get_kpi_data");
        const { open_issues, open_work_orders, out_of_service } = getKpiDataRes;
        setKpiData((prev) => {
          let temp = [...prev];
          return temp.map((kpi) => {
            if (kpi.accessor === "openIssues") {
              return { ...kpi, value: open_issues };
            } else if (kpi.accessor === "openWO") {
              return { ...kpi, value: open_work_orders };
            } else if (kpi.accessor === "outOfService") {
              return { ...kpi, value: out_of_service };
            } else {
              return kpi;
            }
          });
        });
      })();

      (async () => {
        const getTotalOwnershipRes = await getKpiData(
          "get_total_expense_ownership_cost"
        );
        const {
          total_ownership_cost,
          total_cost,
          total_expense_cost,
          total_service_cost,
          total_fillup_cost,
        } = getTotalOwnershipRes;
        const totalOwnershipCost = total_ownership_cost
          ? `${total_ownership_cost.toFixed(1)} ${currency}`
          : NATEXT;
        const totalExpenseCost = total_cost
          ? `${total_cost.toFixed(1)} ${currency}`
          : NATEXT;
        const totalServiceCost = total_service_cost
          ? `${total_service_cost.toFixed(1)} ${currency}`
          : NATEXT;
        const totalFillupCost = total_fillup_cost
          ? `${total_fillup_cost.toFixed(1)} ${currency}`
          : NATEXT;
        const totalCost = total_cost
          ? `${total_cost.toFixed(1)} ${currency}`
          : NATEXT;

        if (statsQuery === "get_total_cost_custom") {
          setStatsValue(totalCost);
        }
        totalCostRefData.current = {
          totalCost,
          totalFillupCost,
          totalServiceCost,
          totalExpenseCost,
        };

        setKpiData((prev) => {
          let temp = [...prev];
          return temp.map((kpi) => {
            if (kpi.accessor === "totalCostOfOwnership") {
              return { ...kpi, value: totalOwnershipCost };
            } else if (kpi.accessor === "totalExpense") {
              return { ...kpi, value: totalExpenseCost };
            } else {
              return kpi;
            }
          });
        });
      })();

      (async () => {
        const vehicleDowntimeRes = await getKpiData("get_vehicle_downtime");
        const { down_time } = vehicleDowntimeRes;

        setKpiData((prev) => {
          let temp = [...prev];
          return temp.map((kpi) => {
            if (kpi.accessor === "vehicleDowntime") {
              return {
                ...kpi,
                value: down_time
                  ? daysHoursMinutes(down_time)?.display
                  : NATEXT,
              };
            } else {
              return kpi;
            }
          });
        });
      })();
      (async () => {
        const getEffRes = await getKpiData("get_eff_data");
        const { veh_eff } = getEffRes;
        let vehEff = veh_eff ? veh_eff.toFixed(2) : 0;
        if (consumption === "L/100km" && veh_eff) {
          vehEff = (100 / veh_eff)?.toFixed(2);
        }
        const consumptionUnit =
          vehicle?.primary_meter === "Hours" ? hourlyConsumption : consumption;
        setKpiData((prev) => {
          let temp = [...prev];
          return temp.map((kpi) => {
            if (kpi.accessor === "avgEff") {
              return {
                ...kpi,
                value: vehEff ? `${vehEff} ${consumptionUnit}` : NATEXT,
              };
            } else {
              return kpi;
            }
          });
        });
      })();
    }
  }, [vehicle, groupId, unixDate, currency, consumption, delayLoad]);

  const getKpiData = async (query) => {
    const vehicleId = vehicle?.vehicleId ? vehicle.vehicleId : "all_vehicles";
    const groupid =
      !groupId || groupId === "all-groups" ? "all_groups" : groupId;
    const apiData = {
      querystring: query,
      sub_graph_query: graphQuery,
      veh_id: vehicleId,
      group_id: groupid,
      filter_date_start: unixDate.from,
      filter_date_end: unixDate.to,
    };

    const res = await putApi(apiData, "dashboard");
    if (res.success) {
      return res.data[0];
    }
  };

  const getGraphData = async () => {
    const groupid =
      !groupId || groupId === "all-groups" ? "all_groups" : groupId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const vehicleId = vehicle?.vehicleId ? vehicle.vehicleId : "all_vehicles";
    const apiData = {
      querystring: "get_graph_data",
      sub_graph_query: graphQuery,
      veh_id: vehicleId,
      group_id: groupid,
      filter_date_start: unixDate.from,
      filter_date_end: unixDate.to,
      timezone: timeZone,
    };
    const res = await postApi(apiData, "dashboard");

    if (res.success && !isObjEmpty(res?.dashboard_graph)) {
      const { dashboard_graph } = res;

      if (
        graphQuery === "gettotalcostgraph" ||
        graphQuery === "totaltripdistance"
      ) {
        let data = [];
        let coordinates = dashboard_graph[vehicleId]
          .filter(
            (obj, index, arr) => index === arr.findIndex((o) => obj.t === o.t)
          )
          .map((d) => ({
            x: d.t,
            y: d.y,
          }));
        data.push(coordinates);
        setGraphData({
          datasets: [
            {
              data: coordinates,
              backgroundColor: "#3b5e90",
              maxBarThickness: 10,
            },
          ],
        });
        return;
      }

      let data = [];
      let dashboardGraphData = { ...dashboard_graph };
      const allVehicleIds = allVehicles.map((v) => v.vehicleId);
      if (loggedInUser.role_id == 2 && !vehicle.vehicleId) {
        dashboardGraphData = {};
        for (let vehicleId in dashboard_graph) {
          if (allVehicleIds.includes(vehicleId)) {
            dashboardGraphData[vehicleId] = dashboard_graph[vehicleId];
          }
        }
      }
      for (let vehicleId in dashboardGraphData) {
        //const allVehicleIds = allVehicles.map((v) => v.vehicleId);
        // if (allVehicleIds.includes(vehicleId)) {
        let vehicleName = "";
        const currentVehicle = allVehicles.find(
          (v) => v.vehicleId === vehicleId
        );
        if (currentVehicle) {
          vehicleName = currentVehicle?.label;
        }

        let color = getRandomColor();
        let coordinates = dashboard_graph[vehicleId]
          .filter(
            (obj, index, arr) => index === arr.findIndex((o) => obj.t === o.t)
          )
          .map((d) => ({
            x: d.t,
            y: d.y,
          }));
        let dataPoint = {
          id: vehicleId,
          label: vehicleName,
          //fill: areafill,
          lineTension: 0.3,
          backgroundColor: color,
          borderColor: color,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          borderWidth: 2,
          pointBorderColor: color,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: coordinates, //dashboard_graph[vehicleId],
          spanGaps: false,
        };

        data.push(dataPoint);
        // }
      }

      setGraphData({ datasets: data });
    }

    if (isObjEmpty(res?.dashboard_graph)) {
      setGraphData({ datasets: [] });
    }
  };

  const getStatsData = async () => {
    if (statsQuery === "getfueleff") {
      const getEffRes = await getKpiData("get_eff_data");
      const { veh_eff } = getEffRes;
      let vehEff = veh_eff ? veh_eff.toFixed(2) : 0;
      if (consumption === "L/100km" && veh_eff) {
        vehEff = (100 / veh_eff)?.toFixed(2);
      }
      const consumptionUnit =
        vehicle?.primary_meter === "Hours" ? hourlyConsumption : consumption;
      setStatsValue(`${vehEff} ${consumptionUnit}`);
      return;
    }
    const updateStatsValue = () => {
      if (statsQuery === "get_total_cost_custom") {
        setStatsValue(totalCostRefData.current.totalCost);
      }
      if (statsQuery === "get_total_service_cost_custom") {
        setStatsValue(totalCostRefData.current.totalServiceCost);
      }
      if (statsQuery === "get_total_expense_cost_custom") {
        setStatsValue(totalCostRefData.current.totalExpenseCost);
      }
      if (statsQuery === "get_total_fillup_cost_custom") {
        setStatsValue(totalCostRefData.current.totalFillupCost);
      }
    };
    if (statsQuery.includes("custom")) {
      const getTotalOwnershipRes = await getKpiData(
        "get_total_expense_ownership_cost"
      );
      const {
        total_ownership_cost,
        total_cost,
        total_expense_cost,
        total_service_cost,
        total_fillup_cost,
      } = getTotalOwnershipRes;
      const totalOwnershipCost = total_ownership_cost
        ? `${total_ownership_cost.toFixed(1)} ${currency}`
        : NATEXT;
      const totalExpenseCost = total_expense_cost
        ? `${total_expense_cost.toFixed(1)} ${currency}`
        : NATEXT;
      const totalServiceCost = total_service_cost
        ? `${total_service_cost.toFixed(1)} ${currency}`
        : NATEXT;
      const totalFillupCost = total_fillup_cost
        ? `${total_fillup_cost.toFixed(1)} ${currency}`
        : NATEXT;
      const totalCost = total_cost
        ? `${total_cost.toFixed(1)} ${currency}`
        : NATEXT;

      if (statsQuery === "get_total_cost_custom") {
        setStatsValue(totalCost);
      }
      totalCostRefData.current = {
        totalCost,
        totalFillupCost,
        totalServiceCost,
        totalExpenseCost,
      };

      updateStatsValue();
      //  }
      return;
    }

    let subQueryString = statsQuery;
    if (statsQuery.includes("per_dist")) {
      subQueryString = "get_total_cost_per_dist";
    }
    if (statsQuery.includes("per_day")) {
      subQueryString = "get_total_cost_per_day";
    }

    const groupid =
      !groupId || groupId === "all-groups" ? "all_groups" : groupId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const vehicleId = vehicle?.vehicleId ? vehicle.vehicleId : "all_vehicles";
    const apiData = {
      querystring: "get_stat_data",
      sub_querystring: subQueryString,
      veh_id: vehicleId,
      group_id: groupid,
      filter_date_start: unixDate.from,
      filter_date_end: unixDate.to,
      //timezone: timeZone,
    };
    const res = await putApi(apiData, "dashboard");
    if (res.success) {
      if (statsQuery.includes("per_dist") || statsQuery.includes("per_day")) {
        const data = res.data[0];
        let value = +data[statsQuery];
        if (value) {
          value = value.toFixed(2);
          setStatsValue(`${value} ${currency}`);
        } else {
          setStatsValue(NATEXT);
        }
        //const value = data[statsQuery].toFixed(2);
        return;
      }

      // if(statsQuery.includes('per_hour')) {

      // }
      const stat_data = res.data[0].stat_data;
      if (stat_data) {
        if (
          statsQuery === "costperfillup" ||
          statsQuery === "PricePerVolUnit" ||
          statsQuery === "avgexpensepertrip" ||
          statsQuery === "avgincomepertrip" ||
          statsQuery === "avgfuelcostpertrip"
        ) {
          let data = stat_data.toFixed(2);
          setStatsValue(`${data} ${currency}`);
        } else if (statsQuery === "QtyPerFillup") {
          let data = stat_data.toFixed(2);
          setStatsValue(`${data} ${volume}`);
        } else if (
          statsQuery === "DistBetFillup" ||
          statsQuery === "totaltripdistance"
        ) {
          let data = stat_data.toFixed(2);
          setStatsValue(`${data} ${getDistanceUnit(distance)}`);
        } else {
          setStatsValue(stat_data);
        }
      } else {
        setStatsValue(NATEXT);
      }
    }
  };

  //Function to get reminders/renewals table data
  const getRemindersData = async (query, setData) => {
    const vehicleId = vehicle?.vehicleId ? vehicle.vehicleId : "all_vehicles";
    const groupid =
      !groupId || groupId === "all-groups" ? "all_groups" : groupId;
    const res = await putApi(
      {
        querystring: query,
        veh_id: vehicleId,
        group_id: groupid,
      },
      "dashboard"
    );
    if (res.success) {
      const { data } = res;
      let dueSoonArr = [];
      let overdueArr = [];

      if (data.length > 0) {
        data.forEach((row) => {
          const { PERCENTAGE } = row;
          if (PERCENTAGE > 90 && PERCENTAGE < 100) {
            dueSoonArr.push(row);
          } else if (PERCENTAGE >= 100) {
            overdueArr.push(row);
          }
        });

        //dueSoonArr = dueSoonArr.slice(0, 4);
        //overdueArr = overdueArr.slice(0, 4);
      }
      // setKpiData((prev) => {
      //   // let accessor =
      //   //   query === "get_maintenance_reminders"
      //   //     ? "overdueServices"
      //   //     : "overdueRenewals";
      //   let accessor = "overdueReminders";
      //   let temp = [...prev];
      //   return temp.map((kpi) => {
      //     if (kpi.accessor === accessor) {
      //       return { ...kpi, value: overdueArr.length };
      //     } else {
      //       return kpi;
      //     }
      //   });
      // });
      setOverdueReminders((prev) => {
        let { maintenances, renewals } = prev;
        if (query === "get_maintenance_reminders") {
          maintenances = overdueArr?.length;
        } else {
          renewals = overdueArr?.length;
        }

        return { maintenances, renewals };
      });
      setData({
        dueSoon: dueSoonArr.slice(0, 4),
        overdue: overdueArr.slice(0, 4),
      });
    }
  };

  const getMissedInspectionsData = async () => {
    const vehicleId = vehicle?.vehicleId ? vehicle.vehicleId : "all_vehicles";
    const groupid =
      !groupId || groupId === "all-groups" ? "all_groups" : groupId;

    const res = await putApi(
      {
        querystring: "get_missed_inspections",
        veh_id: vehicleId,
        group_id: groupid,
        filter_date_start: getUnixTime(startOfDay(new Date())),
        filter_date_end: getUnixTime(new Date()),
      },
      "dashboard"
    );
    let count = 0;
    if (res.success) {
      const { notInspected, total_vehicle_count } = res.data[0];
      count = total_vehicle_count;
      let notInspectedData = "";
      let notInspectedVehiclesLength = 0;
      if (notInspected) {
        notInspectedData = JSON.parse(notInspected);
        notInspectedVehiclesLength = notInspectedData.length;
      }

      //let vehiclesTableData = [];
      let vehiclesTableData = [];
      let vehiclesAssignedArr = [];
      const operatorRes = await postApi(
        { querystring: "get_all_vehicle_operators" },
        "commonNew"
      );
      if (operatorRes.success) {
        vehiclesAssignedArr = operatorRes?.user_data;
      }

      if (notInspectedData?.length > 0) {
        if (allVehicles.length > 0) {
          allVehicles.forEach((v) => {
            if (notInspectedData.includes(v.vehicleId)) {
              let temp = [];
              vehiclesAssignedArr.forEach((veh) => {
                if (v.vehicleId === veh.veh_id) {
                  temp.push({ userId: veh.user_id, userName: veh.name });
                }
              });
              vehiclesTableData.push({
                vehicleId: v.vehicleId,
                vehicleName: v.label,
                users: temp,
              });
            }
          });
        }
      } else {
        vehiclesTableData = [];
      }

      setMissedInspectionsData({
        tableData: vehiclesTableData,
        totalVehicleCount: count,
      });
    }
  };

  const handleDateApply = (date) => {
    let fromDate = getUnixTime(startOfDay(date.from));
    let toDate = getUnixTime(endOfDay(date.to));
    if (date?.dateOption === "all-time") {
      fromDate = 0;
    }
    setUnixDate({ from: fromDate, to: toDate });

    const filterObj = {
      from: fromDate,
      to: toDate,
      dateOption: date.dateOption,
      filterText:
        date?.dateOption === "custom"
          ? `${convertToFilterDate(date.from)} - ${convertToFilterDate(
              date.to
            )}`
          : dateOptionsObj[date?.dateOption],
    };

    localStorage.setItem("dashboardDateFilter", JSON.stringify(filterObj));
    dispatch(
      updateExternalFilter({
        screen: "dashboard",
        filterName: "date",
        filterValue: filterObj,
      })
    );
  };

  return (
    <>
      {showSubscriptionExpiredPopup && (
        <InfoPopup
          showPopup={showSubscriptionExpiredPopup}
          setShowPopup={setShowSubscriptionExpiredPopup}
          message={t("sub_expired_msg")}
          icon={{
            className: "fi fi-rr-triangle-warning",
            color: colorYellowNew,
          }}
          actionIcon={{
            handler: () => setShowSubscriptionExpiredPopup(false),
            label: t("ok"),
          }}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 mt-1 mb-2">
          <Col>
            <ColumnHeading>{t("dashboard")}</ColumnHeading>
          </Col>
        </Row>
        {!isLoggedIn ? (
          <div>{t("loading_msg")}</div>
        ) : (
          <Row>
            <Card>
              <CardBody
                //style={{ background: "#f2f4f7" }}
                formcard="true"
                className="p-0 m-0"
              >
                <Row className="p-3 m-0" style={{ background: "#f2f2f2" }}>
                  <Col className="p-0 m-0">
                    <div
                      className="d-flex justify-content-start align-items-center gap-3 flex-lg-row flex-sm-column"
                      style={{ background: "#f2f2f2" }}
                    >
                      {/* <SearchInput
                    id="search"
                    type="search"
                    placeholder={t("filter_by_keyword")}
                    //onChange={(e) => onChange(e.target.value)}
                    //active={searchHasValue}
                  /> */}

                      <TreeSelect
                        id="group-treeselect-dropdown"
                        value={groupId}
                        onChange={(e) => setGroupId(e.value)}
                        options={groups}
                        //className="md:w-20rem w-full"
                        placeholder={t("group")}
                        filter
                        className={
                          groupId && groupId !== "all-groups"
                            ? "treeselect-with-value"
                            : ""
                        }
                      ></TreeSelect>

                      <Select
                        id="vehicle-dropdown"
                        value={vehicle}
                        onChange={setVehicle}
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="color"
                        options={vehicles}
                        styles={selectStylesNew}
                        placeholder={t("vehicle")}
                      />

                      <CombinedDateFilter
                        //date={date}
                        //setDate={setDate}
                        //setToggleDateFilter={setToggleDateFilter}
                        //toggleDateFilter={toggleDateFilter}
                        handleDateApply={handleDateApply}
                        presetDate={externalDatePreset}
                        allTimeStartDate={subDays(new Date(), 89)}
                      />
                    </div>
                  </Col>
                </Row>
                <ScrollContainer>
                  <Row className="p-0 m-0">
                    <Col sm={vehicle?.vehicleId ? 9 : 12}>
                      <Row>
                        {kpiData.map((kpi, i) => (
                          <Col md={3} className="p-0 m-0" key={i}>
                            <KpiContainer>
                              <FormGroupLabel className="m-0">
                                {kpi.label}
                              </FormGroupLabel>
                              {kpi.accessor === "overdueReminders" ? (
                                <div
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "14px", //vehicle.vehicleId ? 10 : 14,
                                    //whiteSpace: "nowrap",
                                    padding: "0.1rem 0",
                                  }}
                                >
                                  {`${overdueReminders.maintenances} ${t(
                                    "maintenance",
                                    { count: overdueReminders.maintenances }
                                  )} / ${overdueReminders.renewals} ${t(
                                    "renewal",
                                    { count: overdueReminders.renewals }
                                  )}`}
                                </div>
                              ) : (
                                <KPIValue>{kpi.value}</KPIValue>
                              )}
                            </KpiContainer>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    {vehicle?.vehicleId && (
                      <Col sm={3} className="p-3">
                        <SingleVehicleInfo vehicle={vehicle} />
                      </Col>
                    )}
                  </Row>
                  <Row className="m-0 p-3">
                    <Col className="m-0 p-0">
                      <ChartsComponent
                        graphData={graphData}
                        setGraphData={setGraphData}
                        graphQuery={graphQuery}
                        setGraphQuery={setGraphQuery}
                        statsQuery={statsQuery}
                        setStatsQuery={setStatsQuery}
                        verticalActiveKey={verticalActiveKey}
                        setVerticalActiveKey={setVerticalActiveKey}
                        statsValue={statsValue}
                        setStatsValue={setStatsValue}
                      />
                    </Col>
                  </Row>
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-3">
                      <DisplayTable
                        data={remindersData}
                        heading={t("maintenance_reminders")}
                        type="reminders"
                      />
                    </Col>
                    <Col className="p-0 m-3">
                      <DisplayTable
                        data={renewalsData}
                        heading={t("renewals")}
                        type="renewals"
                      />
                    </Col>
                  </Row>
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-3">
                      <MissedInspectionsTable data={missedInspectionData} />
                    </Col>
                    <Col className="p-0 m-3"></Col>
                  </Row>
                </ScrollContainer>
              </CardBody>
            </Card>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Dashboard;

//styles

const KpiContainer = styled.div`
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  //height: 78px;
`;

const ScrollContainer = styled(Container)`
  margin: 0;
  padding: 0;
  overflow: auto;
  height: ${window.innerHeight - 214}px;
  ${scrollbarStyles}
`;

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
