import React from "react";
import { Card, CardBody } from "../../shared/components/Card";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { AddFormButton, Icon } from "../../shared/components/TableElements";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { RightIcon } from ".";
import { useStripe } from "@stripe/react-stripe-js";
import { post, postApi } from "../../api/getUser";
import { useSelector } from "react-redux";
import { useState } from "react";
import InfoPopup from "../../shared/components/InfoPopup";
import EssentialImg from "../../shared/img/Essential.png";
import AdvancedImg from "../../shared/img/Advanced.png";
import Tooltip from "../../shared/components/Tooltip";

const PlanCard = ({
  heading,
  features,
  toggleValue,
  noOfVehicles,
  perVehiclePrice,
  totalPrice,
  //currency,
  currencyCode,
  product,
  subscriptionData,
}) => {
  const { t } = useTranslation("common");

  const allVehicles = useSelector((state) => state.vehicles.list).filter(
    (v) => v.vehicleId
  );

  //const { currency } = useSelector((state) => state.units);

  //For showing Error popup when noOfVehicles is more than no of vehicles in org
  const [showErrPopup, setShowErrPopup] = useState(false);

  const stripe = useStripe();

  const perDurationCap = toggleValue === "yearly" ? t("year") : t("month");
  const perDurationSmall =
    toggleValue === "yearly" ? t("year_sm") : t("month_sm");

  let planImg = EssentialImg;
  if (product === "5") {
    planImg = EssentialImg;
  } else if (product === "6") {
    planImg = AdvancedImg;
  }

  const handleSubscribe = async () => {
    try {
      if (subscriptionData?.vehiclecount > noOfVehicles) {
        setShowErrPopup(true);
        return;
      } else {
        const apiData = {
          querystring: "create_session",
          pro_id: product,
          duration: toggleValue === "yearly" ? 2 : 1,
          quantity: noOfVehicles,
          is_react: 1,
        };
        const res = await post("subscription", apiData);
        const checkoutSessionId = res?.id;
        const stripeRes = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {showErrPopup && (
        <InfoPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={t("no_of_vehicles_err_msg", {
            orgVehicles: subscriptionData?.vehiclecount,
          })}
          icon={{ className: "fi fi-rr-cross-circle", color: "red" }}
        />
      )}
      <Card
        className="p-0"
        shadow={"true"}
        //style={{ height: "300px" }}
      >
        <CardBody className="d-flex flex-column gap-3 justify-content-between">
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img
                  //style={{ width: "40px", height: "40px" }}
                  src={planImg}
                  width={25}
                  height={25}
                />
                <h4>{heading}</h4>
              </div>
              <h4
              //style={{ fontSize: "18px", fontWeight: 600 }}
              >{`${currencyCode} ${totalPrice}/${perDurationCap}`}</h4>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Desc>{`${currencyCode} ${perVehiclePrice} ${t("per")} ${t(
                "vehicle_sm"
              )}  ${t("per")} ${perDurationSmall}`}</Desc>
              <Desc className="align-self-start">{`${t("for")} ${
                noOfVehicles < 5 ? 5 : noOfVehicles
              } ${t("vehicles_sm")}`}</Desc>
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            {features.map((feature, i) => (
              <div
                key={feature.key}
                className="d-flex align-items-center gap-2"
              >
                <i
                  className="fi fi-rs-check-circle"
                  style={{ color: "green", marginTop: 3 }}
                />
                <span>{feature.label}</span>
                {feature?.tooltipText && (
                  <Tooltip text={feature?.tooltipText}>
                    <Icon className="fi fi-rr-info"></Icon>
                  </Tooltip>
                )}
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center">
            <AddFormButton
              type="button"
              id="subscribe-btn"
              onClick={handleSubscribe}
            >
              {t("subscribe")}
            </AddFormButton>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default PlanCard;
