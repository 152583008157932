import React, { useEffect, useState } from "react";
import {
  AddFormButton,
  ActionIconContainer,
  ColumnHeaderRow,
  DataRow,
  TableContainer,
} from "../TableElements";
import { Col, Container, Modal, Row } from "react-bootstrap";
import {
  colorAdditionalHover,
  colorGray,
  colorHover,
  colorLightGray,
  colorRowHover,
  scrollbarStyles,
} from "../../../utils/palette";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SelectTechnicianPopup = ({
  showPopup,
  setShowPopup,
  technician,
  setTechnician,
  techniciansList,
}) => {
  const { t } = useTranslation("common");

  const { currency } = useSelector((state) => state.units);

  const daysOfWeekObj = {
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S",
    7: "S",
  };

  const [selected, setSelected] = useState("");

  //Load the value of technician received from props if it exists
  useEffect(() => {
    if (technician) {
      setSelected(technician);
    }
  }, []);

  const handleTechClick = (t) => {
    setSelected(t);
  };

  const handleSelect = () => {
    setTechnician(selected);
    setShowPopup(false);
  };

  return (
    <Modal
      show={showPopup}
      onHide={() => setShowPopup((prev) => !prev)}
      size="lg"
    >
      <Modal.Body
        style={
          {
            //height: "-webkit-fill-available",
            //height: `${window.innerHeight - 100}px`,
          }
        }
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5>{t("select_tech")}</h5>
          <ActionIconContainer onClick={() => setShowPopup((prev) => !prev)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
        <TableContainer className="m-0 p-0">
          <ColumnHeaderRow
          // style={{
          //   border: `1px solid ${colorLightGray}`,
          //   //background: colorLightGray,
          // }}
          // className="py-2 mx-0"
          >
            <Col md={1}></Col>
            <Col md={3}>
              <span>{t("name")}</span>
            </Col>
            <Col md={2}>
              <span>{t("rate_per_hour")}</span>
            </Col>
            <Col md={3}>
              <span>{t("working_days")}</span>
            </Col>
            <Col md={3}>
              <span>{t("working_hours")}</span>
            </Col>
          </ColumnHeaderRow>
          <TechniciansContainer>
            {techniciansList?.length === 0 && (
              <div className="d-flex justify-content-center p-2">
                {t("no_records")}
              </div>
            )}
            {techniciansList?.map((tech) => {
              return (
                <TechnicianRow
                  key={tech.user_id}
                  onClick={() => handleTechClick(tech)}
                  //style={{ border: `1px solid ${colorLightGray}` }}
                  className="m-0"
                >
                  <Col md={1}>
                    <div>
                      <input
                        type="radio"
                        name="technician"
                        //value={selected.user_id}
                        checked={tech.user_id === selected.user_id}
                        //onChange={(e) => console.log(e.target.value)}
                        style={{ marginTop: "0.25rem" }}
                        readOnly
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <span>{tech.label}</span>
                  </Col>
                  <Col md={2}>
                    <span>{`${tech.hourly_rate} ${currency}`}</span>
                  </Col>
                  <Col md={3}>
                    {[1, 2, 3, 4, 5, 6, 7]?.map((no) => (
                      <React.Fragment key={no}>
                        <DaySpan
                          active={tech?.working_days
                            ?.split(":")
                            ?.includes(no.toLocaleString())}
                        >
                          {daysOfWeekObj[no]}
                        </DaySpan>
                      </React.Fragment>
                    ))}
                  </Col>
                  <Col md={3}>
                    <span>
                      {`${tech.starting_time?.slice(
                        0,
                        5
                      )} - ${tech.ending_time?.slice(0, 5)}`}
                    </span>
                  </Col>
                </TechnicianRow>
              );
            })}
          </TechniciansContainer>
        </TableContainer>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <AddFormButton onClick={handleSelect}>{t("select")}</AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectTechnicianPopup;

//styles
export const TechniciansContainer = styled.div`
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
  //overflow-x: hidden;
  // /${scrollbarStyles};
  //width: -webkit-fill-width;
`;

export const DaySpan = styled.span`
  color: red;
  ${(p) => p.active && `color: black`}
`;

export const TechnicianRow = styled(DataRow)`
  //border: 1px solid ${colorLightGray};
  cursor: pointer;

  &:hover {
    background: ${colorRowHover};
  }
`;
