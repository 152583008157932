import { Route, Switch } from "react-router";
import WorkOrdersTable from "./WorkOrdersTable";
import WorkOrdersForm from "./WorkOrdersForm";
import getDay from "date-fns/getDay";
import isBefore from "date-fns/isBefore";
import set from "date-fns/set";
import isSameDay from "date-fns/isSameDay";
import max from "date-fns/max";
import min from "date-fns/min";
import isSameMinute from "date-fns/isSameMinute";
import isAfter from "date-fns/isAfter";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import startOfDay from "date-fns/startOfDay";
import addDays from "date-fns/addDays";
import { NATEXT } from "../../shared/constants";

export default () => (
  <Switch>
    <Route exact path="/workorders" component={WorkOrdersTable} />
    <Route exact path="/workorders/addnew" component={WorkOrdersForm} />
  </Switch>
);

export const calculateSinglePauseTime = (
  schedule,
  pauseStartTime,
  pauseEndTime,
  workingStartTime,
  workingEndTime
) => {
  const workingDays = schedule.split(":").map(Number); // Convert schedule to an array of numbers
  let totalWorkingHours = 0;
  let current = pauseStartTime;

  let workEnd = workingEndTime;
  if (workingEndTime === "00:00") {
    workEnd = "23:59";
  }

  const [startingHours, startingMinutes] = workingStartTime
    .split(":")
    .map(Number);
  const [endingHours, endingMinutes] = workEnd.split(":").map(Number);

  //Convert workingStartTime and workingEndTime to no of minutes for comparison
  const scheduleStart = startingHours * 60 + startingMinutes;
  const scheduleEnd = endingHours * 60 + endingMinutes;

  while (isBefore(current, pauseEndTime)) {
    if (isWorkingDay(current, schedule)) {
      let workStartTime, workEndTime;
      if (scheduleStart > scheduleEnd) {
        // Working hours span across two days (e.g., 19:00 to 06:00)
        const nightShiftStart = set(current, {
          hours: startingHours,
          minutes: startingMinutes,
          seconds: 0,
          milliseconds: 0,
        });

        let nextDay = addDays(current, 1);
        const nightShiftEnd = set(nextDay, {
          hours: endingHours,
          minutes: endingMinutes,
          seconds: 0,
          milliseconds: 0,
        });

        if (isSameDay(current, pauseStartTime)) {
          workStartTime = max([nightShiftStart, pauseStartTime]);
          workEndTime = min([nightShiftEnd, pauseEndTime]);
        } else if (isSameDay(current, pauseEndTime)) {
          workStartTime = max([nightShiftStart, current]);
          workEndTime = min([nightShiftEnd, pauseEndTime]);
        } else {
          workStartTime = nightShiftStart;
          workEndTime = nightShiftEnd;
        }
      } else {
        // Normal working hours (e.g., 8:00 to 17:00)
        const start = set(current, {
          hours: startingHours,
          minutes: startingMinutes,
          seconds: 0,
          milliseconds: 0,
        });
        workStartTime = isSameDay(current, pauseStartTime)
          ? max([start, pauseStartTime])
          : start;
        //console.log("workStartTime", max([start, startTime]));
        const end = set(current, {
          hours: endingHours,
          minutes: endingMinutes,
          seconds: 0,
          milliseconds: 0,
        });
        workEndTime = isSameDay(current, pauseEndTime)
          ? min([end, pauseEndTime])
          : end;
      }

      //console.log("workStartTime", workStartTime);
      //console.log("workEndTime", workEndTime);
      // Check if the actual working hours are within the defined working hours
      if (
        isBefore(workStartTime, workEndTime) ||
        isSameMinute(workStartTime, workEndTime)
      ) {
        const dailyWorkingHours = differenceInMilliseconds(
          workEndTime,
          workStartTime
        );
        // console.log(
        //   current + "  " + dailyWorkingHours / (1000 * 60 * 60),
        //   "hrs"
        // );
        totalWorkingHours += +dailyWorkingHours;
      }
    }
    current = addDays(current, 1);
    current = startOfDay(current);
  }
  const totalInSeconds = Math.round(totalWorkingHours / 1000);

  return totalInSeconds;
};

export function getTotalPauseTime(pauses, workStartTime, workEndTime) {
  let totalPauseTime = 0;
  //console.log("Inside Pause Time", workStartTime, workEndTime, pauses);
  pauses?.forEach((pause) => {
    const pauseStart = max([new Date(pause.start_time * 1000), workStartTime]);
    const pauseEnd = min([new Date(pause.end_time * 1000), workEndTime]);
    if (isBefore(pauseStart, pauseEnd)) {
      totalPauseTime += differenceInMilliseconds(pauseEnd, pauseStart);
    }
  });
  return totalPauseTime;
}

export const getTimeSinceAcceptance = (
  startTime,
  endTime,
  workingStartTime,
  workingEndTime,
  schedule,
  pauses
) => {
  if (startTime && startTime > 0) {
    let current = startTime;
    let totalWorkingHours = 0;
    let workEnd = workingEndTime;
    if (workingEndTime === "00:00") {
      workEnd = "23:59";
    }
    const [startingHours, startingMinutes] = workingStartTime
      .split(":")
      .map(Number);
    const [endingHours, endingMinutes] = workEnd.split(":").map(Number);

    //Convert workingStartTime and workingEndTime to no of minutes for comparison
    const scheduleStart = startingHours * 60 + startingMinutes;
    const scheduleEnd = endingHours * 60 + endingMinutes;

    while (isBefore(current, endTime)) {
      if (isWorkingDay(current, schedule)) {
        let workStartTime = "";
        let workEndTime = "";
        let carryOverTime = 0;

        if (scheduleStart > scheduleEnd) {
          // Working hours span across two days (e.g., 19:00 to 06:00)
          const nightShiftStart = set(current, {
            hours: startingHours,
            minutes: startingMinutes,
            seconds: 0,
            milliseconds: 0,
          });

          const prevNightShiftEnd = set(startTime, {
            hours: endingHours,
            minutes: endingMinutes,
            seconds: 0,
            milliseconds: 0,
          });

          let nextDay = addDays(current, 1);
          const nightShiftEnd = set(nextDay, {
            hours: endingHours,
            minutes: endingMinutes,
            seconds: 0,
            milliseconds: 0,
          });

          if (isSameDay(current, startTime)) {
            workStartTime = max([nightShiftStart, startTime]);
            workEndTime = min([nightShiftEnd, endTime]);
          } else if (isSameDay(current, endTime)) {
            workStartTime = max([nightShiftStart, current]);
            workEndTime = min([nightShiftEnd, endTime]);
          } else {
            workStartTime = nightShiftStart;
            workEndTime = nightShiftEnd;
          }

          if (isBefore(current, prevNightShiftEnd)) {
            carryOverTime = differenceInMilliseconds(
              prevNightShiftEnd,
              current
            );

            let pausesBeforePrevNightShiftEnd = pauses.filter((p) =>
              isBefore(new Date(p.start_time * 1000), prevNightShiftEnd)
            );

            if (pausesBeforePrevNightShiftEnd.length > 0) {
              let carryOverPauseTime = getTotalPauseTime(
                pausesBeforePrevNightShiftEnd,
                startTime,
                prevNightShiftEnd
              );
              carryOverTime -= carryOverPauseTime;
            }
          }
        } else {
          // Normal working hours (e.g., 8:00 to 17:00)
          const start = set(current, {
            hours: startingHours,
            minutes: startingMinutes,
            seconds: 0,
            milliseconds: 0,
          });
          workStartTime = isSameDay(current, startTime)
            ? max([start, startTime])
            : start;
          //console.log("workStartTime", max([start, startTime]));
          const end = set(current, {
            hours: endingHours,
            minutes: endingMinutes,
            seconds: 0,
            milliseconds: 0,
          });
          workEndTime = isSameDay(current, endTime) ? min([end, endTime]) : end;
        }

        //console.log("workStartTime", workStartTime);
        //console.log("workEndTime", workEndTime);

        // Check if the actual working hours are within the defined working hours
        if (
          isBefore(workStartTime, workEndTime) ||
          isSameMinute(workStartTime, workEndTime)
        ) {
          const dailyPauseTime = getTotalPauseTime(
            pauses?.filter(
              (pause) =>
                (isSameDay(new Date(pause.start_time * 1000), workStartTime) ||
                  isSameDay(new Date(pause.end_time * 1000), workEndTime)) &&
                isAfter(new Date(pause.start_time * 1000), startTime) &&
                isBefore(new Date(pause.end_time * 1000), endTime)
            ),
            workStartTime,
            workEndTime
          );
          //console.log(dailyPauseTime);
          const dailyWorkingHours =
            differenceInMilliseconds(workEndTime, workStartTime) +
            Number(carryOverTime) -
            dailyPauseTime;
          // console.log(
          //   current + "  " + dailyWorkingHours / (1000 * 60 * 60),
          //   "hrs"
          // );
          totalWorkingHours += dailyWorkingHours;
          carryOverTime = 0;
        }
      }

      current = addDays(current, 1);
      current = startOfDay(current);
    }

    return {
      display: dhm(totalWorkingHours),
      seconds: totalWorkingHours / 1000,
    };
  } else {
    return {
      display: NATEXT,
      seconds: 0,
    };
  }
};

// Function to check if a given day is a working day based on the provided schedule
export function isWorkingDay(start, schedule) {
  const workingDays = schedule
    ? schedule.split(":").map((no) => {
        if (+no === 7) {
          return 0;
        } else {
          return +no;
        }
      })
    : [0, 1, 2, 3, 4, 5, 6];

  return workingDays.includes(start.getDay()); // Check if the day falls within workingDays array
}

export function dhm(t) {
  let cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60000),
    pad = function (n) {
      return n < 10 ? "0" + n : n;
    };
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }

  //console.log(d, h, m);
  if (!d && !h && !m) {
    return NATEXT;
  }

  if (+d < 0 || +h < 0 || +m < 0) {
    return NATEXT;
  }

  let display = "";
  if (d && +d > 0) {
    display += `${d}d`;
    if (h && +h > 0) {
      display += ` ${h}h`;
    }
  } else {
    if (h && +h > 0) {
      display += `${h}h`;
    }
    if (m && +m > 0) {
      display += ` ${m}m`;
    }
  }

  return display;

  // if (d) {
  //   return +d > 0 || +h > 0 ? `${d}d ${h}h ${m}m` : NATEXT;
  // } else {
  //   return +h > 0 || +m > 0 ? `${h}h ${m}m` : NATEXT;
  // }
  //return d + " days : " + pad(h) + " hours : " + pad(m) + " mins ";
}

export const getTotalTime = (
  startTime,
  endTime,
  workingStartTime,
  workingEndTime,
  schedule,
  pauses
) => {
  let current = startTime;
  let totalWorkingHours = 0;

  let workEnd = workingEndTime;
  if (workingEndTime === "00:00") {
    workEnd = "23:59";
  }
  //console.log(workingStartTime, workingEndTime);
  const [startingHours, startingMinutes] = workingStartTime
    .split(":")
    .map(Number);
  const [endingHours, endingMinutes] = workEnd.split(":").map(Number);

  //Convert workingStartTime and workingEndTime to no of minutes for comparison
  const scheduleStart = startingHours * 60 + startingMinutes;
  const scheduleEnd = endingHours * 60 + endingMinutes;

  while (isBefore(current, endTime)) {
    if (isWorkingDay(current, schedule)) {
      let workStartTime = "";
      let workEndTime = "";

      if (scheduleStart > scheduleEnd) {
        // Working hours span across two days (e.g., 19:00 to 06:00)
        const nightShiftStart = set(current, {
          hours: startingHours,
          minutes: startingMinutes,
          seconds: 0,
          milliseconds: 0,
        });

        let nextDay = addDays(current, 1);
        const nightShiftEnd = set(nextDay, {
          hours: endingHours,
          minutes: endingMinutes,
          seconds: 0,
          milliseconds: 0,
        });

        if (isSameDay(current, startTime)) {
          workStartTime = max([nightShiftStart, startTime]);
          workEndTime = min([nightShiftEnd, endTime]);
        } else if (isSameDay(current, endTime)) {
          workStartTime = max([nightShiftStart, current]);
          workEndTime = min([nightShiftEnd, endTime]);
        } else {
          workStartTime = nightShiftStart;
          workEndTime = nightShiftEnd;
        }
      } else {
        // Normal working hours (e.g., 8:00 to 17:00)
        const start = set(current, {
          hours: startingHours,
          minutes: startingMinutes,
          seconds: 0,
          milliseconds: 0,
        });
        workStartTime = isSameDay(current, startTime)
          ? max([start, startTime])
          : start;
        //console.log("workStartTime", max([start, startTime]));
        const end = set(current, {
          hours: endingHours,
          minutes: endingMinutes,
          seconds: 0,
          milliseconds: 0,
        });
        workEndTime = isSameDay(current, endTime) ? min([end, endTime]) : end;
      }

      //console.log("workStartTime", workStartTime);
      //console.log("workEndTime", workEndTime);

      // Check if the actual working hours are within the defined working hours
      if (
        isBefore(workStartTime, workEndTime) ||
        isSameMinute(workStartTime, workEndTime)
      ) {
        const dailyPauseTime = getTotalPauseTime(
          pauses?.filter(
            (pause) =>
              (isSameDay(pause.start_time, workStartTime) ||
                isSameDay(pause.end_time, workEndTime)) &&
              isAfter(pause.start_time, startTime) &&
              isBefore(pause.end_time, endTime)
          ),
          workStartTime,
          workEndTime
        );
        const dailyWorkingHours =
          differenceInMilliseconds(workEndTime, workStartTime) - dailyPauseTime;
        // console.log(
        //   current + "  " + dailyWorkingHours / (1000 * 60 * 60),
        //   "hrs"
        // );
        totalWorkingHours += dailyWorkingHours;
      }
    }

    current = addDays(current, 1);
    current = startOfDay(current);
  }

  return totalWorkingHours / 1000;
};
