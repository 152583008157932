import axios from "axios";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { putApi } from "../../api/getUser";
import { fetchServicesTasks } from "../../features/servicesTasks/servicesTasksSlice";
import RowOptions from "../../shared/components/table/components/RowOptions";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
} from "../../shared/components/TableElements";
import DataReactTable from "../DataTable/components/DataReactTable";
import { Link } from "react-router-dom";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import { NATEXT } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { updateNoOfRecords } from "../../features/columns/columnsSlice";
import { sortItems } from "../../shared/helpers";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const ServicesTasksTable = () => {
  const { t } = useTranslation("common");
  const rowData = useSelector((state) => state.servicesTasks.list);
  const serviceTasksStatus = useSelector((state) => state.servicesTasks.status);
  const location = useLocation();
  const fromCancel = location.state?.fromCancel;
  const dispatch = useDispatch();
  const {
    reload,
    setReload,
    setLoading,
    isClientApiCalled,
    setIsClientApiCalled,
    isLoggedIn,
  } = useAuth();
  const allVehicles = useSelector((state) => state.vehicles.list);

  //Effect to set initial default rows
  useEffect(() => {
    if (isLoggedIn && (!isClientApiCalled || !fromCancel)) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "servicetasks",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          const { default_rows } = data;
          dispatch(updateNoOfRecords({ defaultRows: default_rows }));
          setLoading(false);
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    (async () => {
      if (reload === "servicetasks") {
        setReload("");
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: "All",
        };
        dispatch(fetchServicesTasks(apiData));
      }
    })();
  }, [reload]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "veh_id",
        Cell: ({ row }) => {
          const allVehicles = useSelector((state) => state.vehicles.list);
          const [currentVehicle, setCurrentVehicle] = useState(null);
          useEffect(() => {
            if (allVehicles?.length > 0) {
              if (row.original.veh_id !== "All") {
                let current = allVehicles.find(
                  (v) => v.veh_id === row.original.veh_id
                );
                setCurrentVehicle(current);
              }
            }
          }, [allVehicles]);

          return (
            <VehicleComponent
              vehicleName={currentVehicle ? currentVehicle?.label : "All"}
              //groupName={row.original.group_name}
              //vehicleId={row.original.veh_id}
              //vehicleImg={row.original.veh_img}
              vehicleId={currentVehicle ? currentVehicle?.veh_id : ""}
              vehicleImg={currentVehicle ? currentVehicle?.veh_img : ""}
              orgId={row.original.org_id}
            />
          );
        },
        //disableGlobalFilter: true,
      },
      {
        id: 2,
        Header: t("service_task_name_caps"),
        accessor: "service_task_name",
        accessorId: "task_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, "service_task_name");
        },
        //disableGlobalFilter: true,
      },
      // {
      //   id: 3,
      //   Header: "Last Service Date",
      //   accessor: "service_date",
      //   Cell: ({ row }) =>
      //     row?.original?.service_date
      //       ? new Date(row?.original?.service_date * 1000).toLocaleDateString(
      //           "en-GB"
      //         )
      //       : NATEXT,
      //   //disableGlobalFilter: true,
      // },
      {
        id: "edit",
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row.original} />,
        disableSortBy: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };
  return (
    <Container>
      <Row className="mx-1 d-flex align-items-center justify-content-around">
        <Col
          sm={10}
          //className="d-flex justify-content-start align-items-center"
        >
          <ColumnHeading>{t("service_tasks")}</ColumnHeading>
        </Col>
        <Col
          sm={2}
          className="d-flex justify-content-end align-items-center mb-1"
        >
          <Link to={"/services/servicetasks/addnew"}>
            <AddFormButton>{t("add_custom_task")}</AddFormButton>
          </Link>
        </Col>
      </Row>
      <Row>
        <DataReactTable
          reactTableData={reactTableData}
          //valuesArray={servicesValuesArray}
          //initialData={initialData}
          //filterToggleColumns={filterToggleColumns}
          //defaultColumns={defaultColumns}
          //columnSizeData={columnSizeData}
        />
      </Row>
    </Container>
  );
};

export default ServicesTasksTable;
