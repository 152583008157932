import { createSlice } from "@reduxjs/toolkit";
import { getAdvancedFilterColumnName } from "../../shared/helpers";

const initialState = {
  fillups: {
    //date: {},
    odo: {},
    distance: {},
    quantity: {},
    totalCost: {},
    efficiency: {},
    fillingStation: {},
    transactionType: {},
    cardNumber: {},
    fuelBrand: {},
    fuelType: {},
    comments: {},
  },
  service: {
    //date: {},
    odo: {},
    serviceStation: {},
    serviceTask: {},
    totalCost: {},
    totalLaborCost: {},
    totalPartsCost: {},
    transactionType: {},
    cardNumber: {},
    servicedBy: {},
    comments: {},
  },
  trips: {
    //depDate: {},
    depOdo: {},
    departure: {},
    //arrDate: {},
    arrOdo: {},
    arrival: {},
    distance: {},
    time: {},
    tripBy: {},
    comments: {},
  },
  expense: {
    //date: {},
    odo: {},
    vendor: {},
    expenseTask: {},
    totalCost: {},
    transactionType: {},
    cardNumber: {},
    expenseBy: {},
    comments: {},
  },
  dailymileage: {
    enteredBy: {},
    //date: {},
    startingOdo: {},
    endingOdo: {},
    distance: {},
    comments: {},
  },
  maintenancereminders: {
    task: {},
    daysRemaining: {},
    odoRemaining: {},
    lastServiceOdo: {},
    dueOdo: {},
    lastServiceDate: {},
    dueDate: {},
    status: {},
  },
  renewals: {
    task: {},
    daysRemaining: {},
    odoRemaining: {},
    lastServiceOdo: {},
    dueOdo: {},
    lastServiceDate: {},
    dueDate: {},
    status: {},
  },
  inspectionsubmissions: {
    formName: {},
    inspectionBy: {},
    issues: {},
    issuesType: "all",
  },
  issues: {
    odo: {},
    issue: {},
    issueDesc: {},
    reportedBy: {},
    reportedDate: {},
    closeDate: {},
    timeToClose: {},
  },
  workorders: {
    woType: {},
    assignedTo: {},
    woNumber: {},
    dueOn: {},
    poNumber: {},
    invoiceNumber: {},
    //totalCost: {},
    comments: {},
  },
};

const advancedFilterSlice = createSlice({
  name: "advancedFilter",
  initialState,
  reducers: {
    addAdvancedFilter(state, action) {
      const { screen, column, name, radioName, ...rest } = action.payload;
      const columnName = getAdvancedFilterColumnName(screen);
      state[columnName][column] = rest;
    },
    removeAdvancedFilter(state, action) {
      const { column, screen } = action.payload;
      const columnName = getAdvancedFilterColumnName(screen);
      state[columnName][column] = {};
    },
    addAdvancedFilters(state, action) {
      const { columns, screen } = action.payload;
      const columnName = getAdvancedFilterColumnName(screen);
      columns.forEach((col) => {
        const { column, filtered, ...rest } = col;
        if (column) {
          if (filtered) {
            state[columnName][column] = rest;
          } else {
            state[columnName][column] = {};
          }
        }
      });
    },
    clearAllAdvancedFilters(state, action) {
      const { columns, screen } = action.payload;
      const columnName = getAdvancedFilterColumnName(screen);
      columns.forEach((column) => {
        state[columnName][column] = {};
      });
    },
    addIssuesType(state, action) {
      if (action.payload) {
        const { value } = action.payload;
        state.inspectionsubmissions.issuesType = value;
      }
    },
    addStatusType(state, action) {
      const { statusObj, screen } = action.payload;
      state[screen].status = statusObj;
    },
    addCheckboxFilter(state, action) {
      const { screen, filterName, filterObj } = action.payload;
      state[screen][filterName] = filterObj;
    },
  },
});

export const {
  addAdvancedFilter,
  addAdvancedFilters,
  removeAdvancedFilter,
  clearAllAdvancedFilters,
  addIssuesType,
  addStatusType,
  addCheckboxFilter,
} = advancedFilterSlice.actions;
export default advancedFilterSlice.reducer;
