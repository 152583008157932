import React from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../../shared/components/TableElements";
import {
  FormGroupLabel,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { formInputStyles } from "../../../shared/constants/styles";
import Error from "@/shared/components/form/Error";
import { useTranslation } from "react-i18next";

const PauseConfirmPopup = ({
  showPopup,
  setShowPopup,
  reason,
  setReason,
  reasonError,
  setReasonError,
  handlePauseClick,
  pauseBtnLoading,
}) => {
  const { t } = useTranslation("common");

  const handleCancelClick = () => {
    setReason("");
    setReasonError("");
    setShowPopup(false);
  };

  return (
    <Modal show={showPopup} onHide={handleCancelClick} centered>
      <Modal.Body className="d-flex flex-column gap-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{t("pause_work_order")}</h5>
          <ActionIconContainer onClick={() => setShowPopup((prev) => !prev)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
        <div>
          <FormGroupLabel className="mt-0">
            {t("reason_for_pause")}
          </FormGroupLabel>
          <FormItem>
            <input
              type="text"
              style={formInputStyles}
              id="reason-for-pause"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormItem>
          {reasonError && <Error error={reasonError} />}
        </div>
        <div className="d-flex justify-content-end">
          <div className="d-flex gap-3">
            <FilterButton type="button" onClick={handleCancelClick}>
              {t("cancel")}
            </FilterButton>
            <AddFormButton
              type="button"
              onClick={async () => handlePauseClick()}
              disabled={pauseBtnLoading}
            >
              {pauseBtnLoading ? <CustomLoader /> : t("pause")}
            </AddFormButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PauseConfirmPopup;
