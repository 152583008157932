import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import {
  colorHover,
  colorBackground,
  colorRed,
  colorGray,
} from "@/utils/palette";
import { left, paddingLeft, marginLeft } from "@/utils/directions";
import {
  SidebarNavLink,
  SidebarLinkTitle,
  SidebarLinkIcon,
  getIconComponent,
  IconContainer,
  SidebarIcon,
  NewBadge,
} from "./SidebarLink";
import { useLocation } from "react-router";
import { useEffect } from "react";
import {
  colorGrayNew,
  sidenavColor,
  sidenavLinkHoverColor,
} from "../../../../utils/palette";
import { useTranslation } from "react-i18next";

const SidebarCategory = ({
  title,
  icon,
  isNew,
  children,
  collapse,
  isScreenOpen,
}) => {
  const { t } = useTranslation("common");

  const [isOpen, setIsOpen] = useState(isScreenOpen);
  useEffect(() => {
    setIsOpen(isScreenOpen);
  }, [isScreenOpen]);

  //const IconComponent = getIconComponent(icon);

  return (
    <SidebarCategoryWrap collapse={collapse} id="sidebar-category">
      <SidebarCategoryButton
        as="button"
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <SidebarIcon className={icon}></SidebarIcon>
        <SidebarLinkTitle>
          {title}
          {isNew && (
            <NewBadge bg="custom">
              <span>{t("new")}</span>
            </NewBadge>
          )}
        </SidebarLinkTitle>
        {!collapse && (
          <SidebarCategoryChevron
            className="lnr lnr-chevron-right"
            open={isOpen}
          />
        )}
      </SidebarCategoryButton>
      <Collapse in={collapse || isOpen}>
        <SidebarSubmenuWrap>
          <SidebarSubmenu>{children}</SidebarSubmenu>
        </SidebarSubmenuWrap>
      </Collapse>
    </SidebarCategoryWrap>
  );
};

SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SidebarCategory.defaultProps = {
  icon: "",
  isNew: false,
};

export default SidebarCategory;

// region STYLES

const SidebarCategoryButton = styled(SidebarNavLink)`
  width: 100%;
`;

export const SidebarCategoryChevron = styled.span`
  ${marginLeft}: auto;
  ${(props) =>
    props.open &&
    `
    transform: rotate(90deg);
  `};
  font-size: 10px;
  line-height: 18px;
  color: ${colorGrayNew};
`;

const SidebarSubmenuWrap = styled.div`
  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      position: absolute;
      width: 0;
      transition: 0.3s;
      display: none;
      ${left}: 55px;

      & & {
        position: relative;
        ${left}: 0;

        a {
          ${paddingLeft}: 30px;
        }
      }
    `}
  }
`;

const SidebarSubmenu = styled.ul`
  background: ${sidenavColor};
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;
  //background-color: ${colorHover};
  list-style: none;
  margin-top: -1px;

  span {
    font-size: 14px;
  }

  & & {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    a,
    button {
      ${paddingLeft}: 53px;
    }
  }

  a,
  button {
    ${paddingLeft}: 32px; //Changed from 43 to 23

    &:hover {
      background-color: ${sidenavLinkHoverColor};
    }
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
        padding: 0 0 15px 0;
        transition: 0s;
    `}
  }
`;

const SidebarCategoryWrap = styled.li`
  padding: 7px 0px;
  //background: ${sidenavColor};

  ${(props) =>
    props.collapse &&
    `
    &:hover {
      
      ${SidebarCategoryButton} {
        //background: ${colorHover(props)};
        background: ${sidenavLinkHoverColor};
        
        &:before {
          opacity: 1;
        }
      }
    }
  `};

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > div {
        position: absolute;
        width: 0;
        transition: .3s;
        display: none;
        left: 55px;
        
        a,
        button {
          width: 100%;
          ${paddingLeft(props)}: 15px;
        }
      }
      
      & & > div {
        position: relative;
        display: block;
        min-width: 185px;
        left: 0;
        
        a,
        button {
          ${paddingLeft(props)}: 30px;
        }
      }
    
      &:hover > div,
      &:hover & > div {
        //background: ${sidenavLinkHoverColor};
        display: inherit!important;
        width: 220px;
      }
    `}
  }
`;

// endregion
