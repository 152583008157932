import React from "react";

import {
  AddFormButton,
  ColumnHeading,
  CustomLoader,
  FilterButton,
  Icon,
} from "../TableElements";
import styled from "styled-components";
import { colorLightGray } from "../../../utils/palette";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isObjEmpty } from "../../helpers";
import { useHistory, useLocation } from "react-router";
import ActionPopup from "../ActionPopup";
import { useSelector } from "react-redux";
import SubscribePopup from "../SubscribePopup";
import { useAuth } from "../../../contexts/AuthContext";

const FormHeader = ({
  //screen,
  title,
  saveButtonText,
  handleCancel,
  disableSave,
  closeIssueData,
  setCloseIssueData,
  showConfirmationPopup,
  setShowConfirmationPopup,
  isSaveLoading,
  errors,
  rowData,
  priority,
  setError,
  clearErrors,
  setShowPauseConfirmPopup,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const { roleId } = useSelector((state) => state.user);

  //State to show create wo confirmation popup
  const [showCreateWOPopup, setShowCreateWOPopup] = useState(false);
  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const [showSaveButton, setShowSaveButton] = useState(true);

  const location = useLocation();
  const screen = location.pathname;
  const status = rowData?.status;

  //Toggle visibility of save button
  useEffect(() => {
    if (screen?.includes("organization") || screen?.includes("operator")) {
      if (roleId == 2 || roleId == 4) {
        setShowSaveButton(false);
      }
    }
  }, [screen, roleId]);

  const handleCreateWOClick = () => {
    if (tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    setShowCreateWOPopup(true);
  };

  const handleCreateWO = () => {
    history.push("/workorders/addnew", {
      data: rowData,
      type: "add",
      mode: "from-add-issue",
    });
  };

  //console.log("status", status);
  return (
    <>
      {showCreateWOPopup && (
        <ActionPopup
          showPopup={showCreateWOPopup}
          setShowPopup={setShowCreateWOPopup}
          handleYes={handleCreateWO}
          message={t("create_wo_from_issue_confirmation_msg", {
            count: 1,
          })}
          handleNo={() => setShowCreateWOPopup(false)}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <FormHeaderContainer>
        <div>
          <ColumnHeading>{title}</ColumnHeading>
        </div>
        <div className="d-flex gap-3">
          {screen?.includes("issues") && (status == 0 || status == 1) && (
            <>
              {/* <FilterButton>Create Work Order</FilterButton> */}
              <CloseIssueDropdown
                closeIssueData={closeIssueData}
                setCloseIssueData={setCloseIssueData}
                showConfirmationPopup={showConfirmationPopup}
                setShowConfirmationPopup={setShowConfirmationPopup}
                errors={errors}
                priority={priority}
                setError={setError}
                clearErrors={clearErrors}
              />
              {status === 0 && (
                <FilterButton onClick={handleCreateWOClick} type="button">
                  {t("create_work_order")}
                </FilterButton>
              )}
              <div style={{ borderLeft: "1px solid lightgray" }}></div>
            </>
          )}

          {/* {screen?.includes("workorders") &&
          (workOrderStatus === 0 || workOrderStatus === 1) && (
            <>
            {workOrderStatus === 0 && (
                <FilterButton onClick={handleAcceptWOClick}>
                {t("accept_work_order")}
                </FilterButton>
                )}
                {workOrderStatus === 1 && (
                  <SplitButton
                  mainActionText={t("close_work_order")}
                  subActionText={t("pause_work_order")}
                  />
                  )}
                  <FilterButton>{t("print_work_order")}</FilterButton>
                  <div style={{ borderLeft: "1px solid lightgray" }}></div>
                  </>
                )} */}

          {showSaveButton && (
            <AddFormButton
              id="save-form-button"
              type="submit"
              disabled={disableSave || isSaveLoading}
              //isLoading={isSaveLoading}
              //onClick={handleSave}
            >
              {isSaveLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          )}
          {handleCancel && (
            <FilterButton
              id="cancel-form-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaveLoading}
            >
              {t("cancel")}
            </FilterButton>
          )}
        </div>
      </FormHeaderContainer>
    </>
  );
};

export default FormHeader;

//styles

export const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 12px 12px 14px;
`;

export const CloseIssueDropdown = ({
  closeIssueData,
  setCloseIssueData,
  showConfirmationPopup,
  setShowConfirmationPopup,
  errors,
  priority,
  setError,
  clearErrors,
}) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const closeIssueRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!closeIssueRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [closeIssueRef]);

  const handleCloseIssue = (e) => {
    //console.log("priority", priority);
    if (!priority) {
      setError(
        "priority",
        { type: "required", message: t("required_err") },
        { shouldFocus: true }
      );
      setOpen(false);
      return;
    } else {
      clearErrors("priority");
    }
    //e.preventDefault()
    if (isObjEmpty(errors)) {
      setCloseIssueData("close-issue");
      setShowConfirmationPopup(!showConfirmationPopup);
    }
  };

  const handleCloseWithServiceEntry = () => {
    if (!priority) {
      setError(
        "priority",
        { type: "required", message: t("required_err") },
        { shouldFocus: true }
      );
      setOpen(false);
      return;
    } else {
      clearErrors("priority");
    }

    setCloseIssueData("close-with-service-entry");
    //setShowConfirmationPopup(!showConfirmationPopup);
    // }
  };

  return (
    <div ref={closeIssueRef}>
      <FilterButton
        onClick={() => setOpen((prev) => !prev)}
        type="button"
        isDropdownOpen={open}
        //style={{ background: "#2EB26B", color: 'white' }}
      >
        {t("close_issue")}{" "}
        <i
          className="fi fi-bs-angle-small-down"
          style={{ margin: "0.25rem 0 0 0.25rem" }}
        ></i>
      </FilterButton>
      {open && (
        <CloseIssueDropdownWrap>
          <CloseIssueDropdownItem
            onClick={handleCloseWithServiceEntry}
            type="submit"
          >
            {t("close_with_service_entry")}
          </CloseIssueDropdownItem>
          <CloseIssueDropdownItem onClick={handleCloseIssue} type="button">
            {t("close_issue")}
          </CloseIssueDropdownItem>
        </CloseIssueDropdownWrap>
      )}
    </div>
  );
};

const CloseIssueDropdownWrap = styled.div`
  border: 1px solid ${colorLightGray};
  background: white;
  position: absolute;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 0;
  z-index: 1;
`;

const CloseIssueDropdownItem = styled.button`
  padding: 0.5rem;
  background: white;
  border: none;
  display: block;
  width: -webkit-fill-available;
  text-align: justify;
  &:hover {
    background: ${colorLightGray};
  }
`;
