import React from "react";
import { NotAssignedVehiclesContainer } from "./table/components/ReactTableConstructor";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { ActionIcon, ActionIconContainer } from "./TableElements";
import { colorBorder, colorLightGray } from "../../utils/palette";
import { useTranslation } from "react-i18next";

const NotInspectedVehiclesPopup = ({ showPopup, setShowPopup, data }) => {
  const { t } = useTranslation("common");
  return (
    <Modal show={showPopup} onHide={() => setShowPopup(!showPopup)}>
      <Modal.Header>
        {/* <p style={{ fontWeight: 500, fontSize: "16px" }}>
          Non Inspected Vehicles
        </p>
        <ActionIcon
          className="fi fi-rr-cross-small"
          onClick={() => setShowPopup(!showPopup)}
        ></ActionIcon> */}
        <h4>{t("non_inspected_vehicles")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={() => setShowPopup(!showPopup)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container
          className="m-0 p-0"
          style={{
            border: `1px solid ${colorLightGray}`,
            //background: colorLightGray,
          }}
        >
          <Row
            style={
              {
                //border: `1px solid ${colorLightGray}`,
                //background: colorLightGray,
              }
            }
            className="py-2 mx-0"
          >
            <Col>
              <div style={{ fontWeight: 600 }}>{t("veh_name")}</div>
            </Col>
            <Col>
              <div style={{ fontWeight: 600 }}>{t("operator")}</div>
            </Col>
          </Row>
          <NotAssignedVehiclesContainer>
            {data?.map((veh) => {
              return (
                <Row
                  key={veh.vehicleId}
                  style={{ borderBottom: `1px solid ${colorBorder}` }}
                  className="py-2 px-0 mx-0"
                >
                  <Col>
                    <div>{veh.vehicleName}</div>
                  </Col>
                  <Col>
                    {veh.users.length > 0 ? (
                      <>
                        {veh.users?.map((user, i) => {
                          return <div key={user.userId}>{user.userName}</div>;
                        })}
                      </>
                    ) : (
                      <div>{t("not_assigned")}</div>
                    )}
                  </Col>
                </Row>
              );
            })}
          </NotAssignedVehiclesContainer>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default NotInspectedVehiclesPopup;
