import React, { useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext } from "react";
import { auth } from "../api/firebase";
import { getGroups, getUsers, getVehicles, postApi } from "../api/getUser";
import { updateUsersList } from "../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { updateVehiclesList } from "../features/vehicles/vehiclesSlice";
import { updateGroupsList } from "../features/groups/groupsSlice";
import { updateUser } from "../features/user/userSlice";
import { updateUnits } from "../features/units/unitsSlice";
import { Redirect, useHistory } from "react-router";
//const auth = getAuth();

const UserNameContext = createContext();

export const useUserName = () => {
  return useContext(UserNameContext);
};

export const UserNameProvider = ({ children }) => {
  const [userName, setUserName] = useState("");

  const value = {
    userName,
    setUserName,
  };

  return (
    <UserNameContext.Provider value={value}>
      {children}
    </UserNameContext.Provider>
  );
};
