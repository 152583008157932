// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: "AIzaSyBLiKzvTAA0GUp4BoWi5OHztPjOnQfJHLs",
  authDomain: "simply-fleet-6e5ee.firebaseapp.com",
  projectId: "simply-fleet-6e5ee",
  storageBucket: "simply-fleet-6e5ee.appspot.com",
  messagingSenderId: "325492021214",
  appId: "1:325492021214:android:80728ab6eb0640cb",
  databaseURL: "https://simply-fleet-6e5ee.firebaseio.com",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
setPersistence(auth, indexedDBLocalPersistence);
export default app;
