import React from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";

import { ActionIconContainer } from "../../shared/components/TableElements";
import {
  FormGroupLabel,
  FormItem,
  LinkSpan,
} from "../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../shared/components/Card";
import { NATEXT } from "../../shared/constants";
import { useSelector } from "react-redux";
import {
  ItemContainer,
  ItemLabel,
  ItemValue,
} from "../../shared/components/table/components/RightPanel";
import { IssueStatus } from "../Issues/IssuesTable";
import {
  convertToDisplayTime,
  getDisplayDateWithTimeNew,
  getDistanceUnit,
} from "../../shared/helpers";
import { useHistory } from "react-router";

const IssuePopup = ({ issueData, showPopup, setShowPopup }) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { distance } = useSelector((state) => state.units);
  const { roleId } = useSelector((state) => state.user);
  const {
    veh_id,
    issue,
    issue_desc,
    odo,
    priority,
    reported_date,
    user_name,
    status,
    source,
    comments,
    reported_by_name,
  } = issueData;

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const currentVehicle = vehiclesList.find((v) => v.veh_id === veh_id);

  const priorityText =
    priority == 0
      ? t("not_set")
      : priority == 1
      ? t("low")
      : priority == 2
      ? t("med")
      : priority == 3
      ? t("high")
      : "";

  //   const reportedAtText = format(
  //     new Date(reported_date * 1000),
  //     "d-MMM-yyyy h:mm a"
  //   );

  const reportedAtText = getDisplayDateWithTimeNew(reported_date);

  const handleEditIssue = () => {
    history.push("issues/addnew", {
      type: "edit",
      mode: "from-fault-codes",
      //route,
      //screen,
      data: { rowData: issueData },
    });
  };

  return (
    <Modal show={showPopup} size="md">
      <Modal.Header>
        <h4>{t("issue_details")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={() => setShowPopup(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <Container className="p-0 m-0">
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 260}px`,
                overflow: "auto",
              }}
            >
              <Row>
                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("vehicle")}</ItemLabel>
                    <ItemValue>{currentVehicle?.name}</ItemValue>
                  </ItemContainer>
                </Col>
                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("odometer")}</ItemLabel>
                    <ItemValue>
                      {odo}{" "}
                      {getDistanceUnit(
                        currentVehicle?.primary_meter === "Hours"
                          ? "Hours"
                          : distance
                      )}
                    </ItemValue>
                  </ItemContainer>
                </Col>

                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("status")}</ItemLabel>
                    <ItemValue>
                      <IssueStatus status={status} />
                    </ItemValue>
                  </ItemContainer>
                </Col>
                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("priority")}</ItemLabel>
                    <ItemValue>{priorityText}</ItemValue>
                  </ItemContainer>
                </Col>

                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("issue")}</ItemLabel>
                    <ItemValue>{issue}</ItemValue>
                  </ItemContainer>
                </Col>
                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("odometer")}</ItemLabel>
                    <ItemValue>
                      {odo}{" "}
                      {getDistanceUnit(
                        currentVehicle?.primary_meter === "Hours"
                          ? "Hours"
                          : distance
                      )}
                    </ItemValue>
                  </ItemContainer>
                </Col>

                {issue_desc && (
                  <Col md={12}>
                    <ItemContainer>
                      <ItemLabel>{t("issue_desc")}</ItemLabel>
                      <ItemValue>{issue_desc}</ItemValue>
                    </ItemContainer>
                  </Col>
                )}

                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("reported_at")}</ItemLabel>
                    <ItemValue>{reportedAtText}</ItemValue>
                  </ItemContainer>
                </Col>
                <Col md={6}>
                  <ItemContainer>
                    <ItemLabel>{t("reported_by")}</ItemLabel>
                    <ItemValue>{reported_by_name}</ItemValue>
                  </ItemContainer>
                </Col>
                <Col md={12}>
                  <ItemContainer>
                    <ItemLabel>{t("source")}</ItemLabel>
                    <ItemValue>{source}</ItemValue>
                  </ItemContainer>
                </Col>

                {comments && (
                  <Col md={12}>
                    <ItemContainer>
                      <ItemLabel>{t("notes_tv")}</ItemLabel>
                      <ItemValue>{comments}</ItemValue>
                    </ItemContainer>
                  </Col>
                )}

                {roleId !== 4 && (
                  <Col md={12}>
                    <ItemContainer>
                      <LinkSpan
                        onClick={handleEditIssue}
                        style={{ fontSize: 14 }}
                      >
                        {t("edit_issue")}
                      </LinkSpan>
                    </ItemContainer>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default IssuePopup;
