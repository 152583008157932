import React, { useRef } from "react";
import { colorAdditional, colorLightGray } from "../../../../utils/palette";
import Tooltip from "@/shared/components/Tooltip";
import styled from "styled-components";
import { NATEXT } from "../../../constants";

const TaskPillComponent = ({ stringValue }) => {
  let valueNamesArr = [];
  let valuesToShow = [];
  let valuesOnHover = [];
  let hoverText = "";

  if (stringValue) {
    valueNamesArr = stringValue.split(",");
    valueNamesArr?.forEach((s) => s.trim());
    if (valueNamesArr.length > 2) {
      valuesToShow = valueNamesArr?.slice(0, 2);
      valuesOnHover = valueNamesArr?.slice(2, valueNamesArr.length);
    } else {
      valuesToShow = [...valueNamesArr];
    }

    if (valuesOnHover.length > 0) {
      valuesOnHover.forEach((value) => {
        hoverText += `${value}, `;
      });
      hoverText = hoverText.slice(0, hoverText.length - 2);
    }
  }

  return (
    <>
      {stringValue ? (
        <div
          className="d-flex gap-2 flex-wrap"
          //style={{ display: "-webkit-box", WebkitLineClamp: 2 }}
        >
          {valuesToShow.map((name, index) => {
            return (
              <div key={index}>
                {name?.length > 20 ? (
                  <Tooltip text={name}>
                    <ValueContainer additional>
                      {`${name.slice(0, 20)}...`}
                    </ValueContainer>
                  </Tooltip>
                ) : (
                  <ValueContainer>{name}</ValueContainer>
                )}
              </div>
            );

            // return name?.length > 20 ? (
            //   <Tooltip text={name}>
            //     <ValueContainer additional>
            //       {`${name.slice(0, 20)}...`}
            //     </ValueContainer>
            //   </Tooltip>
            // ) : (
            //   <ValueContainer>{name}</ValueContainer>
            // );
          })}
          {valuesOnHover.length > 0 && (
            <Tooltip text={hoverText}>
              <ValueContainer additional>
                {`+${valuesOnHover.length}`}
              </ValueContainer>
            </Tooltip>
          )}
        </div>
      ) : (
        <div>{NATEXT}</div>
      )}
    </>
  );
};

export default TaskPillComponent;

//styles
export const ValueContainer = styled.div`
  border: 1px solid ${colorAdditional};
  border-radius: 10px;
  padding: 3px 6px;
  //cursor: pointer;
  font-size: 14px;
  //white-space: nowrap;

  ${(p) =>
    p.additional &&
    `
    
    &:hover {
      background: ${colorLightGray};
    }
    `}
`;
