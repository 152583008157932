import React, { useState } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import {
  FormGroupLabel,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { handleNegative } from "../../../shared/helpers";
import { formInputStyles } from "../../../shared/constants/styles";
import { useTranslation } from "react-i18next";
import { NATEXT } from "../../../shared/constants";
import { useEffect } from "react";

const CustomTimeToCompletePopup = ({
  showPopup,
  setShowPopup,
  customTotalTime,
  setCustomTotalTime,
}) => {
  const { t } = useTranslation("common");

  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);

  useEffect(() => {
    setDays(customTotalTime?.days ? customTotalTime?.days : 0);
    setHours(customTotalTime?.hours ? customTotalTime?.hours : 0);
    setMinutes(customTotalTime?.minutes ? customTotalTime?.minutes : 0);
  }, [customTotalTime]);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSaveClick = () => {
    let display = "";
    let seconds = 0;
    if (+days) {
      seconds += +days * 86400;
      display += days + "d ";
    }
    if (+hours) {
      seconds += +hours * 3600;
      display += hours + "h ";
    }
    if (+minutes) {
      seconds += +minutes * 60;
      display += minutes + "m";
    }

    if (!+days && !+hours && !+minutes) {
      display = NATEXT;
    }
    setCustomTotalTime({ display, seconds, days, hours, minutes });
    setShowPopup(false);
  };
  return (
    <Modal show={showPopup} onHide={handleClose}>
      <Modal.Header>
        <h4>{t("time_taken_to_complete")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container className="pt-0 pb-4">
          <Row>
            <Col>
              <FormGroupLabel>{t("days")}</FormGroupLabel>
              <FormItem>
                <input
                  id="days-input"
                  type="number"
                  onKeyDown={handleNegative}
                  style={formInputStyles}
                  placeholder={t("days")}
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroupLabel>{t("hours_const")}</FormGroupLabel>
              <FormItem>
                <input
                  id="hours-input"
                  type="number"
                  onKeyDown={handleNegative}
                  style={formInputStyles}
                  placeholder={t("hours_const")}
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroupLabel>{t("minutes")}</FormGroupLabel>
              <FormItem>
                <input
                  id="minutes-input"
                  type="number"
                  onKeyDown={handleNegative}
                  style={formInputStyles}
                  placeholder={t("minutes")}
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton id="save-button" type="button" onClick={handleSaveClick}>
          {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
          {t("save")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomTimeToCompletePopup;
