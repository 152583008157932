import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { FormHeaderContainer } from "../../../shared/components/form/FormHeader";
import SplitButton from "../../../shared/components/SplitButton";
import {
  AddFormButton,
  ColumnHeading,
  CustomLoader,
  FilterButton,
} from "../../../shared/components/TableElements";

const WorkOrderFormHeader = ({
  title,
  handleCancel,
  disableSave,
  isSaveLoading,
  rowData,
  workOrderStatus,
  handlePauseWOClick,
  handleAcceptWOClick,
  handleResumeWOClick,
  handleCloseWOClick,
  acceptWOBtnLoading,
  resumeWOBtnLoading,
  printFunc,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const location = useLocation();
  const screen = location.pathname;
  const status = rowData?.status;

  const handlePrint = () => {
    printFunc();
  };

  return (
    <FormHeaderContainer>
      <div>
        <ColumnHeading>{title}</ColumnHeading>
      </div>
      <div className="d-flex gap-3">
        {workOrderStatus !== "new" && (
          <>
            {workOrderStatus === 0 && (
              <FilterButton
                onClick={handleAcceptWOClick}
                type="button"
                disabled={acceptWOBtnLoading}
                //loading={true}
              >
                {acceptWOBtnLoading ? <CustomLoader /> : t("accept_work_order")}
              </FilterButton>
            )}
            {(workOrderStatus === 1 || workOrderStatus === 2) && (
              <SplitButton
                workOrderStatus={workOrderStatus}
                //mainActionLabel={splitButtonData.mainActionLabel}
                //subActionLabel={splitButtonData.subActionLabel}
                //mainActionHandler={splitButtonData.mainActionHandler}
                //subActionHandler={splitButtonData.subActionHandler}
                handleCloseWOClick={handleCloseWOClick}
                handlePauseWOClick={handlePauseWOClick}
                handleResumeWOClick={handleResumeWOClick}
                resumeWOBtnLoading={resumeWOBtnLoading}
              />
            )}
            <FilterButton onClick={handlePrint} type="button">
              {t("print_work_order")}
            </FilterButton>
            <div style={{ borderLeft: "1px solid lightgray" }}></div>
          </>
        )}

        <AddFormButton
          id="save-form-button"
          type="submit"
          disabled={disableSave || isSaveLoading}
          //isLoading={isSaveLoading}
          //onClick={handleSave}
        >
          {isSaveLoading ? <CustomLoader /> : t("save")}
        </AddFormButton>
        <FilterButton
          id="cancel-form-button"
          type="button"
          onClick={handleCancel}
        >
          {t("cancel")}
        </FilterButton>
      </div>
    </FormHeaderContainer>
  );
};

export default WorkOrderFormHeader;

//styles
