import React from "react";
import { Modal, Table } from "react-bootstrap";
import { colorYellowNew, scrollbarStyles } from "../../../utils/palette";
import styled from "styled-components";
import { ActionIconContainer, AddFormButton } from "../TableElements";
import { useTranslation } from "react-i18next";
import { LinkAnchor } from "../form/FormElements";

const ErrorDetailsTable = ({
  showPopup,
  setShowPopup,
  issuesArr,
  selectValues,
  importFile,
}) => {
  //console.log(selectValues);
  const { t } = useTranslation("common");

  // //get the index of whatever's available - name(Vehicle Name), license_no, vin_no
  // const getVehicleDataIndex = () => {
  //   let vehIndex = 0;
  //   let selectValuesArr = Object.values(selectValues).map((o) => o.value);

  //   if (selectValuesArr.includes("name")) {
  //     const vehNameEntry = Object.entries(selectValues).find(
  //       (arr) => arr[1].value === "name"
  //     );

  //     vehIndex = +vehNameEntry[0];
  //     //console.log("vehIndex", vehIndex);
  //     return vehIndex;
  //   }

  //   if (selectValuesArr.includes("license_no")) {
  //     const licenseEntry = Object.entries(selectValues).find(
  //       (arr) => arr[1].value === "license_no"
  //     );

  //     vehIndex = +licenseEntry[0];
  //     return vehIndex;
  //   }

  //   if (selectValuesArr.includes("vin_no")) {
  //     const vinEntry = Object.entries(selectValues).find(
  //       (arr) => arr[1].value === "vin_no"
  //     );

  //     vehIndex = +vinEntry[0];
  //     return vehIndex;
  //   }

  //   return vehIndex;
  // };

  // let vehDataIndex = getVehicleDataIndex();

  return (
    <Modal show={showPopup} onHide={() => setShowPopup(false)} size="lg">
      <Modal.Header>
        <h5>{t("rows_with_issues")}</h5>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={() => setShowPopup(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ScrollableDiv>
          <Table className="m-0">
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 10,
                background: "white",
              }}
            >
              <tr>
                <th style={{ paddingLeft: "1rem" }}>{t("row_no")}</th>
                <th>{`${t("error")} / ${t("warning")}`}</th>
              </tr>
            </thead>
            <tbody>
              {issuesArr.map((issue, i) => (
                <tr key={issue.rowIndex}>
                  <td style={{ paddingLeft: "1rem" }}>{issue.rowIndex}</td>
                  {/* <td>
                    {issue.type === "row-issue"
                      ? `Issue at row ${issue?.rowIndex}`
                      : `${
                          importFile?.rows[issue?.rowIndex - 1][vehDataIndex]
                            ? importFile?.rows[issue?.rowIndex - 1][
                                vehDataIndex
                              ]
                            : t("vehicle")
                        } not found`}
                  </td> */}
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <i
                        className={
                          "fi fi-rr-triangle-warning"
                          // issue.type === "warning"
                          //   ? "fi fi-rr-triangle-warning"
                          //   : "fi fi-rr-cross-circle"
                        }
                        style={{
                          position: "relative",
                          top: "1px",
                          color:
                            issue.type === "warning" ? colorYellowNew : "red",
                        }}
                      />
                      <span>{issue.message}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollableDiv>
        <span className="p-3">
          {t("for_further_assistance_contact_us_on")}{" "}
          <LinkAnchor
            href="mailto:support@simply-fleet.com"
            style={{ fontSize: 14 }}
          >
            support@simply-fleet.com
          </LinkAnchor>
        </span>
      </Modal.Body>
      <Modal.Footer className="mt-2">
        <AddFormButton
          type="button"
          id="ok-btn"
          onClick={() => setShowPopup(false)}
          //disabled={disableImport || importLoading}
        >
          {t("ok")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorDetailsTable;

//styles
const ModalBody = styled(Modal.Body)`
  height: ${window.innerHeight - 300}px;
  ${scrollbarStyles}
  overflow: auto;
  padding: 0;
`;

const ScrollableDiv = styled.div`
  height: ${window.innerHeight - 230}px;
  ${scrollbarStyles}
  overflow: auto;
  padding: 0;
  margin-bottom: 0.5rem;
`;
