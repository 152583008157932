import { configureStore } from "@reduxjs/toolkit";
import modeReducer from "../../features/mode/modeSlice";
import borderReducer from "../../features/border/borderSlice";
import customizerReducer from "../../features/customizer/customizerSlice";
import shadowReducer from "../../features/shadow/shadowSlice";
import directionReducer from "../../features/direction/directionSlice";
import sidebarReducer from "../../features/sidebar/sidebarSlice";
import userReducer from "../../features/user/userSlice";
import fillupsReducer from "../../features/fillups/fillupsSlice";
import vehiclesReducer from "../../features/vehicles/vehiclesSlice";
import groupsReducer from "../../features/groups/groupsSlice";
import filterReducer from "../../features/filter/filterSlice";
import loadingReducer from "../../features/loading/loadingSlice";
import columnsReducer from "../../features/columns/columnsSlice";
//import servicesReducer from "../../features/services/servicesSlice";
import advancedFilterReducer from "../../features/advancedFilter/advancedFilterSlice";
import rowReducer from "../../features/row/rowSlice";
import servicesTasksReducer from "../../features/servicesTasks/servicesTasksSlice";
import snackbarReducer from "../../features/snackbar/snackbarSlice";
import usersReducer from "../../features/users/usersSlice";
import unitsReducer from "../../features/units/unitsSlice";
import expenseTasksReducer from "../../features/expenseTasks/expenseTasksSlice";
import formDataReducer from "../../features/formData/formDataSlice";
import inspectionTasksReducer from "../../features/inspectionTasks/inspectionTasksSlice";
import storedFiltersReducer from "../../features/storedFilters/storedFiltersSlice";

//import vehicleTypesReducer from "../../features/vehicleTypes/vehicleTypesSlice";

const reducer = {
  mode: modeReducer,
  border: borderReducer,
  customizer: customizerReducer,
  shadow: shadowReducer,
  direction: directionReducer,
  sidebar: sidebarReducer,
  user: userReducer,
  fillups: fillupsReducer,
  vehicles: vehiclesReducer,
  groups: groupsReducer,
  filter: filterReducer,
  loading: loadingReducer,
  columns: columnsReducer,
  //services: servicesReducer,
  advancedFilter: advancedFilterReducer,
  row: rowReducer,
  servicesTasks: servicesTasksReducer,
  snackbar: snackbarReducer,
  users: usersReducer,
  units: unitsReducer,
  expenseTasks: expenseTasksReducer,
  formData: formDataReducer,
  inspectionTasks: inspectionTasksReducer,
  storedFilters: storedFiltersReducer,
  //vehiclesTypes: vehicleTypesReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
});
export default store;
