import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  Label,
} from "../../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Desc } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { postApi } from "../../../api/getUser";
import { useSelector } from "react-redux";
import { AttrContainer } from "./InspectionTasksForm";
import { colorTextNew } from "../../../utils/palette";
import { isObjEmpty } from "../../../shared/helpers";
import { useDispatch } from "react-redux";
import { fetchInspectionTasks } from "../../../features/inspectionTasks/inspectionTasksSlice";

const InspectionTasksFormPopup = ({
  showPopup,
  setShowPopup,
  newTaskInfo,
  setNewTaskId,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const dispatch = useDispatch();
  //const { rowData, screen, type } = location.state;
  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const { userId } = useSelector((state) => state.user);

  //Focus state values
  const [inspectionTaskFocus, setInspectionTaskFocus] = useState(false);

  const allInspectionTasks = useSelector((state) => state.inspectionTasks.list);

  //console.log(errors);

  //React hook form fields
  const inspectionTask = watch("inspectionTask");
  const passFail = watch("passFail");
  const info = watch("info");
  const photo = watch("photo");
  const instructions = watch("instructions");

  //Check if any one of the attributes is checked
  // const isAttributeChecked = passFail || info || photo;

  const attributesArr = [
    {
      id: 0,
      label: t("inspection_passfail"),
      value: "passFail",
      isChecked: passFail,
      desc: t("pass_fail_desc"),
    },
    {
      id: 1,
      label: t("info"),
      value: "info",
      isChecked: info,
      desc: t("info_desc"),
    },
    {
      id: 2,
      label: t("photo"),
      value: "photo",
      isChecked: photo,
      desc: t("photo_desc"),
    },
  ];

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  useEffect(() => {
    if (newTaskInfo && newTaskInfo?.taskName) {
      const { taskName } = newTaskInfo;
      setValue("inspectionTask", taskName);
    }
  }, [newTaskInfo]);

  const handleClose = () => {
    reset({
      inspectionTask: "",
      passFail: false,
      info: false,
      photo: false,
      instructions: "",
    });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const addInspectionTask = async () => {
    console.log(errors);
    // if (!isObjEmpty(errors)) {
    //   return;
    // }
    if (!inspectionTask) {
      setError(
        "inspectionTask",
        { type: "required", message: t("required_err") },
        { shouldFocus: true }
      );
      return;
    } else {
      clearErrors("inspectionTask");
    }

    const isAttributeChecked = passFail || info || photo;

    if (!isAttributeChecked) {
      setError(
        "photo",
        { type: "custom", message: "Any one action is required" },
        { shouldFocus: true }
      );
      return;
    } else {
      clearErrors("photo");
    }

    for (let task of allInspectionTasks) {
      if (
        task.task_name.toLocaleLowerCase() ===
        inspectionTask.toLocaleLowerCase()
      ) {
        setError(
          "inspectionTask",
          {
            type: "custom",
            message: t("dup_insp_task_name_error"),
          },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors("inspectionTask");
      }
    }

    const inspectionTaskId = `${userId}_${Date.now()}`;
    const apiData = {
      querystring: "addEditInspectionTask",
      inspection_task_name: inspectionTask ? inspectionTask : "",
      pass_required: passFail ? passFail : false,
      value_required: info ? info : false,
      image_required: photo ? photo : false,
      description: instructions ? instructions : "",
      operation: "create",
      inspection_task_id: inspectionTaskId,
      action: 1,
      sync_version: "v2",
    };

    // (async () => {
    setSaveBtnLoading(true);
    const response = await postApi(apiData, "inspection");
    if (response.success === 1) {
      dispatch(
        fetchInspectionTasks({ querystring: "get_all_inspection_tasks" })
      );
      reset({
        inspectionTask: "",
        passFail: false,
        info: false,
        photo: false,
        instructions: "",
      });
      setSaveBtnLoading(false);
      const apiData = {
        //email: "testgroup@12.in",
        querystring: "getallservicetask",
        service_veh_id: "All",
      };

      setNewTaskId(inspectionTaskId);
      setShowPopup(false);
    }
    // })();
  };

  const handleCancel = () => {
    reset({
      inspectionTask: "",
      passFail: false,
      info: false,
      photo: false,
      instructions: "",
    });

    history.goBack();
  };

  const inputStyles = {
    //borderColor: colorLightGray,
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    //width: '200px'
  };
  return (
    <Modal show={showPopup} size="xl">
      <Modal.Header>
        <h4>{t("add_inspection_task")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FormContainer
          horizontal={false}
          //onSubmit={handleSubmit(onSubmit)}
          //style={{ width: "100%" }}
        >
          <Container>
            <Row>
              <Col>
                <Card style={{ paddingBottom: 0 }}>
                  <CardBody
                    style={{
                      height: `${window.innerHeight - 240}px`,
                      overflow: "auto",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <FormGroupLabel className="m-0">
                          {t("inspection_task")}
                          {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                        </FormGroupLabel>
                        <FormItem>
                          <FormField
                            name="inspectionTask"
                            control={control}
                            component="input"
                            //type="number"
                            //errors={errors}
                            rules={{
                              required: t("required_err"),
                            }}
                            defaultValue=""
                            placeholder={t("inspection_task_name")}
                            style={inputStyles}
                          />
                        </FormItem>
                        {errors?.inspectionTask && (
                          <Error error={errors.inspectionTask.message} />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10}>
                        <FormGroupLabel style={{ marginBottom: "0px" }}>
                          {t("task_actions_label")}
                          {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                        </FormGroupLabel>
                        {/* <Desc>{t("task_actions_desc")}</Desc> */}
                        {attributesArr.map((attr) => {
                          return (
                            <Row key={attr.id}>
                              <AttrContainer>
                                <Controller
                                  key={attr.id}
                                  name={attr.value}
                                  control={control}
                                  // rules={{
                                  //   required: !isAttributeChecked
                                  //     ? "Any one action is required"
                                  //     : false,
                                  // }}
                                  errors={errors}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <div>
                                      <CheckBoxInput
                                        name={attr.value}
                                        type="checkbox"
                                        onChange={onChange}
                                        style={{
                                          width: "auto",
                                          margin: "0 4px 0 0",
                                        }}
                                        id={attr.value}
                                        checked={attr.isChecked}
                                      />
                                      <Label htmlFor={attr.value}>
                                        {attr.label}
                                      </Label>
                                    </div>
                                  )}
                                />
                                <Desc>{attr.desc}</Desc>
                              </AttrContainer>
                            </Row>
                          );
                        })}
                        {errors?.photo && (
                          <Error error={errors.photo.message} />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10}>
                        <FormGroupLabel>
                          {t("instructions_to_driver_label")}
                          {/* <Tooltip text="Set a Reminder once in every...">
                          <Info />
                        </Tooltip> */}
                        </FormGroupLabel>

                        <FormItem>
                          <FormField
                            name="instructions"
                            control={control}
                            component="input"
                            //type="number"
                            //errors={errors}
                            // rules={{
                            //   required: "Inspection Task Name is required",
                            // }}
                            defaultValue=""
                            placeholder={t(
                              "instructions_to_driver_placeholder"
                            )}
                            style={inputStyles}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </FormContainer>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton
          id="save-form-button"
          type="button"
          onClick={addInspectionTask}
        >
          {saveBtnLoading ? <CustomLoader /> : t("save")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default InspectionTasksFormPopup;
