import React from "react";
import { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import ContainsFilter from "./ContainsFilter";
import RangeFilter from "./RangeFilter";
import TypeFilter from "./TypeFilter";
import DateFilter from "./DateFilter";
import {
  buttonColorNew,
  colorBlue,
  colorGray,
  colorGrayNew,
  colorLightGray,
  colorTextNew,
  colorYellowNew,
  sidenavLinkHoverColor,
} from "../../../../../utils/palette";
import styled, { css } from "styled-components";
import { useEffect } from "react";
import { ReactComponent as CrossSmallIcon } from "@/shared/img/cross-small.svg";
import { ReactComponent as FilterIcon } from "@/shared/img/filter.svg";
import {
  ActionIcon,
  AddFormButton,
  FilterButton,
  ActionIconContainer,
} from "../../../TableElements";
import { useDispatch } from "react-redux";
import { addAdvancedFilters } from "../../../../../features/advancedFilter/advancedFilterSlice";
import { addFilterDate } from "../../../../../features/filter/filterSlice";
import { useTranslation } from "react-i18next";
import { updateFilterState } from "../TempFilter";
import { useSelector } from "react-redux";
import getUnixTime from "date-fns/getUnixTime";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { useAuth } from "../../../../../contexts/AuthContext";
import { getListingDisplayDate, getScreenName } from "../../../../helpers";
import { updateMoreFilters } from "../../../../../features/storedFilters/storedFiltersSlice";

const AdvancedFiltersNew = ({
  show,
  onHide,
  dateOption,
  setDateOption,
  filterData,
  setFilterData,
  appliedFilters,
  setAppliedFilters,
  screen,
  setLocalFilters,
  initialData,
}) => {
  const { t } = useTranslation("common");

  const commonProps = {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    //setLocalFilters,
  };

  const dispatch = useDispatch();
  const { minDate } = useSelector((state) => state.columns);

  useEffect(() => {
    // const currentFilterCol = appliedFilters.find(
    //   (col) => col.column === data.column
    // );

    const appliedFilterCols = appliedFilters.map((col) => col.column);

    let filterData = { ...initialData };
    if (appliedFilterCols.length > 0) {
      for (let key in filterData) {
        if (appliedFilterCols.includes(key)) {
          let appliedFilter = appliedFilters.find((col) => col.column === key);
          if (appliedFilter?.dateOption) {
            let fromDate = new Date(appliedFilter?.from * 1000);
            let toDate = new Date(appliedFilter?.to * 1000);
            appliedFilter = { ...appliedFilter, from: fromDate, to: toDate };
          }
          if (appliedFilter?.type === "checkbox") {
            const { headersArray, moreFilters, ...rest } = appliedFilter;
            appliedFilter = { ...rest };
          }
          filterData[key] = appliedFilter;
        }
      }
    }
    setFilterData(filterData);

    // setFilterData((prev) => {
    //   let temp = { ...prev };
    //   for (let key in temp) {
    //     if (appliedFilterCols.includes(key)) {
    //       const appliedFilter = appliedFilters.find(
    //         (col) => col.column === key
    //       );
    //       temp[key] = appliedFilter;
    //     }
    //   }
    //   return temp;
    // });
  }, [appliedFilters, initialData, show]);

  const filtersArrayNew = [];
  const getFilterComponent = (key, value) => {
    if (Object.hasOwn(value, "from")) {
      return (
        <DateFilter
          commonProps={commonProps}
          dateOption={dateOption}
          setDateOption={setDateOption}
          data={filterData[key]}
          //top={topValue}
        />
      );
    } else if (Object.hasOwn(value, "input_2")) {
      return (
        <RangeFilter
          commonProps={commonProps}
          //top={initialTopValue + (32 * filtersArrayNew.length - 1)}
          data={filterData[key]}
        />
      );
    } else if (Object.hasOwn(value, "input_1")) {
      return (
        <ContainsFilter
          commonProps={commonProps}
          //top={initialTopValue + (32 * filtersArrayNew.length - 1)}
          data={filterData[key]}
        />
      );
    } else {
      return (
        <TypeFilter
          commonProps={commonProps}
          //top={initialTopValue + (32 * filtersArrayNew.length - 1)}
          data={filterData[key]}
        />
      );
    }
  };

  // for (const key in filterData) {
  //   if (Object.hasOwn(filterData, key)) {
  //     if (key === "status") {
  //       continue;
  //     }
  //     filtersArrayNew.push({
  //       id: key,
  //       column: key,
  //       name: filterData[key].name,
  //     });
  //   }
  // }

  const [showFilter, setShowFilter] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [filtersList, setFiltersList] = useState([]);

  useEffect(() => {
    let filtersList = [];
    for (const key in initialData) {
      if (Object.hasOwn(initialData, key)) {
        if (key === "status") {
          continue;
        }
        filtersList.push({
          id: key,
          column: key,
          name: initialData[key].name,
        });
      }
    }
    setFiltersList(filtersList);
    setShowFilter(filtersList[0]);
    setCurrentValue(filtersList[0].column);
  }, [initialData]);

  const handlefilterClick = (filter) => {
    setCurrentValue(filter.column);
    setShowFilter(filter);
  };

  //Method to apply more filters for client side screens (Currently Vehicles and Inspection Schedules)
  const handleLocalFilters = () => {
    const tempLocalFilters = [];
    const appliedFilters = [];
    for (let col in filterData) {
      const data = filterData[col];
      if (Object.hasOwn(data, "from")) {
        const dateInfo = {
          ...data,
          from: Math.round(data.from / 1000),
          to: Math.round(data.to / 1000),
        };
        let fromUnix = getUnixTime(startOfDay(data.from));
        let toUnix = getUnixTime(endOfDay(data.to));
        const readableDate = {
          ...data,
          from: fromUnix,
          to: toUnix,
          fromString: getListingDisplayDate(fromUnix), //new Date(data.from).toLocaleDateString("en-US"),
          toString: getListingDisplayDate(toUnix), //new Date(data.to).toLocaleDateString("en-US"),
        };
        if (data?.isFiltered) {
          tempLocalFilters.push({
            id: data.column,
            type: data.column,
            data: dateInfo,
          });
          appliedFilters.push(readableDate);
        }
      } else if (Object.hasOwn(data, "input_2")) {
        if (data.radioValue === "between") {
          if (+data.input_1 > 0 && +data.input_2 > +data.input_1) {
            // setLocalFilters((prev) => {
            //   const temp = prev.filter((f) => f.type !== data.column);
            //   temp.push({
            //     id: data.column,
            //     type: data.column,
            //     data: data,
            //   });
            //   return temp;
            // });
            tempLocalFilters.push({
              id: data.column,
              type: data.column,
              data: data,
            });
            appliedFilters.push(data);
          }
        } else {
          if (+data.input_1 > 0) {
            // setLocalFilters((prev) => {
            //   const temp = prev.filter((f) => f.type !== data.column);
            //   temp.push({
            //     id: data.column,
            //     type: data.column,
            //     data: data,
            //   });
            //   return temp;
            // });
            tempLocalFilters.push({
              id: data.column,
              type: data.column,
              data: data,
            });
            appliedFilters.push(data);
          }
        }
      } else if (
        !Object.hasOwn(data, "input_2") &&
        Object.hasOwn(data, "input_1")
      ) {
        if (data.input_1) {
          // setLocalFilters((prev) => {
          //   const temp = prev.filter((f) => f.type !== "formName");
          //   temp.push({
          //     id: "formName",
          //     type: "formName",
          //     data: data,
          //   });
          //   return temp;
          // });
          tempLocalFilters.push({
            id: "formName",
            type: "formName",
            data: data,
          });
          appliedFilters.push(data);
        }
      } else {
        const headersArray = [];
        const apiData = {};
        let { name, column, ...rest } = data;
        let isValue = false;
        for (let type in rest) {
          isValue = isValue || rest[type].value;
          if (rest[type].value) {
            headersArray.push(rest[type].header); //headersArray is used to Show in filters applied
            apiData[type] = 1; //apiData is used to send api Data for type Columns
          }
        }
        if (isValue) {
          // setLocalFilters((prev) => {
          //   const temp = prev.filter((f) => f.type !== data.column);
          //   temp.push({
          //     id: data.column,
          //     type: data.column,
          //     data: apiData,
          //   });
          //   return temp;
          // });
          tempLocalFilters.push({
            id: data.column,
            type: data.column,
            data: apiData,
          });
          appliedFilters.push({ headersArray, ...data });
        }
      }
    }
    setAppliedFilters(appliedFilters);
    setLocalFilters((prev) => [...prev, ...tempLocalFilters]);

    // const appliedMoreFilters = appliedFilters.map((filter) => ({
    //   ...filter,
    //   moreFilters: true,
    // }));

    // const appliedMoreFilters = appliedFilters.map((filter) => {
    //   if (
    //     filter.column === "lastInspectionDate" ||
    //     filter.column === "dueDate"
    //   ) {
    //     return {
    //       ...filter,
    //       from: getUnixTime(filter?.from),
    //       to: getUnixTime(filter?.to),
    //       moreFilters: true,
    //     };
    //   } else {
    //     return { ...filter, moreFilters: true };
    //   }
    // });
    const appliedMoreFilters = appliedFilters.map((filter) => {
      return { ...filter, moreFilters: true };
    });

    dispatch(
      updateMoreFilters({
        screen: getScreenName(screen),
        appliedFilters: appliedMoreFilters,
      })
    );

    // setFilters((prev) => {
    //   let temp = { ...prev };
    //   let currentScreen = getScreenName(screen);
    //   let currentScreenFilters = temp[currentScreen];
    //   let filtersWithoutMoreFilters = currentScreenFilters.filter(
    //     (f) => !f?.moreFilters
    //   );
    //   let updatedFilters = [
    //     ...filtersWithoutMoreFilters,
    //     ...appliedMoreFilters,
    //   ];
    //   return { ...temp, [currentScreen]: updatedFilters };
    // });
  };

  const handleClearButtonClick = (filter) => {
    const column = filterData[filter.column];
    updateFilterState(column, setFilterData, minDate, screen, t);
  };

  const handleApply = () => {
    if (
      screen.includes("inspectionscheduling") ||
      screen.includes("vehicles")
    ) {
      handleLocalFilters();
    } else {
      let appliedFilters = [];
      let apiFilterData = [];
      for (let col in filterData) {
        const data = filterData[col];
        //Date Filter Check
        if (Object.hasOwn(data, "from")) {
          let fromUnix = getUnixTime(startOfDay(data.from));
          let toUnix = getUnixTime(endOfDay(data.to));
          const readableDate = {
            ...data,
            from: fromUnix,
            to: toUnix,
            fromString: getListingDisplayDate(fromUnix), //new Date(data.from).toLocaleDateString("en-US"),
            toString: getListingDisplayDate(toUnix), //new Date(data.to).toLocaleDateString("en-US"),
          };
          //let fromUnix = getUnixTime(data.from);
          //let toUnix = getUnixTime(data.to);
          // if (screen.includes("dailymileage")) {

          // }
          const dateInfo = {
            ...data,
            from: fromUnix,
            to: toUnix,
          };
          const { column, name, from, to, isFiltered } = dateInfo;
          if (isFiltered) {
            appliedFilters.push(readableDate);
          }
          if (dateInfo.mode === "custom") {
            apiFilterData.push({ column, from, to, filtered: isFiltered });
          } else {
            dispatch(addFilterDate({ start: from, end: to })); // Sending start_date and end_date
          }
          //Range Filter Check
        } else if (Object.hasOwn(data, "input_2")) {
          const { radioValue } = data;
          if (radioValue === "between") {
            const { input_1, input_2, radioValue, name, column } = data;
            if (input_1 && input_2 && +input_1 > 0 && +input_2 > +input_1) {
              // let input1 = input_1;
              // let input2 = input_2;
              // if (column === "timeToClose") {
              //   input1 = input_1 * 60 * 60;
              //   input2 = input_2 * 60 * 60;
              // }

              appliedFilters.push(data);
              apiFilterData.push({
                column,
                input_1,
                input_2,
                radioValue,
                filtered: true,
              });
            } else {
              apiFilterData.push({
                column,
                input_1,
                input_2,
                radioValue,
                filtered: false,
              });
            }
          } else {
            const { column, input_1, radioValue, name } = data;
            if (input_1 && +input_1 > 0) {
              // let input1 = input_1;
              // if (column === "timeToClose") {
              //   input1 = input_1 * 60 * 60;
              // }

              appliedFilters.push(data);
              apiFilterData.push({
                column,
                input_1,
                radioValue,
                filtered: true,
              });
            } else {
              apiFilterData.push({
                column,
                input_1,
                radioValue,
                filtered: false,
              });
            }
          }
          // Contains Filter Check
        } else if (
          Object.hasOwn(data, "input_1") &&
          !Object.hasOwn(data, "input_2")
        ) {
          const { input_1, radioValue, name, column } = data;
          if (input_1) {
            appliedFilters.push(data);
            apiFilterData.push({ column, input_1, radioValue, filtered: true });
          } else {
            apiFilterData.push({
              column,
              input_1,
              radioValue,
              filtered: false,
            });
          }
        } else {
          //Type Filter Check
          const headersArray = [];
          const apiData = {};
          let { name, column, ...rest } = data;
          let isValue = false;
          for (let type in rest) {
            isValue = isValue || rest[type].value;
            if (rest[type].value) {
              headersArray.push(rest[type].header); //headersArray is used to Show in filters applied
              apiData[type] = 1; //apiData is used to send api Data for type Columns
            }
          }
          if (isValue) {
            appliedFilters.push({ headersArray, ...data });
            apiFilterData.push({ column, ...apiData, filtered: true });
          } else {
            apiFilterData.push({ column, ...apiData, filtered: false });
          }
        }
      }
      dispatch(addAdvancedFilters({ columns: apiFilterData, screen }));
      setAppliedFilters(appliedFilters);
      const appliedMoreFilters = appliedFilters.map((filter) => ({
        ...filter,
        moreFilters: true,
      }));

      // if (screen.includes("services")) {
      dispatch(
        updateMoreFilters({
          screen: getScreenName(screen),
          appliedFilters: appliedMoreFilters,
        })
      );
      // } else {
      // setFilters((prev) => {
      //   let temp = { ...prev };
      //   let currentScreen = getScreenName(screen);
      //   let currentScreenFilters = temp[currentScreen];
      //   let filtersWithoutMoreFilters = currentScreenFilters.filter(
      //     (f) => !f?.moreFilters
      //   );
      //   let updatedFilters = [
      //     ...filtersWithoutMoreFilters,
      //     ...appliedMoreFilters,
      //   ];
      //   return { ...temp, [currentScreen]: updatedFilters };
      // });
      // }
      // setFilters((prev) => ({
      //   ...prev,
      //   [getScreenName(screen)]: appliedFilters,
      // }));
    }
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      //style={{ overflow: 'scroll' }}
    >
      <ModalHeader>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontWeight: 500 }}
        >
          {t("more_filters")}
        </Modal.Title>

        {/* <IconWrap onClick={onHide}>
          <CrossSmallIcon />
        </IconWrap> */}
        <ActionIconContainer onClick={onHide}>
          <i className="fi fi-rr-cross-small" />
        </ActionIconContainer>
      </ModalHeader>
      <Modal.Body style={{ minHeight: "320px" }}>
        {filtersList.length > 0 && (
          <Container>
            <Row>
              <Col xs={5}>
                {filtersList?.map((filter) => (
                  <Row key={filter.id}>
                    <FilterNameContainer
                      active={filter.column === currentValue}
                      isFiltered={filterData[filter.column].isFiltered}
                      onClick={() => handlefilterClick(filter)}
                    >
                      <p>{filter.name}</p>
                      <FilterIcon />
                    </FilterNameContainer>
                  </Row>
                ))}
              </Col>
              <Col xs={7} className="mt-1">
                <div className="d-flex justify-content-between align-items-center">
                  <FilterSubHeading>{showFilter?.name}</FilterSubHeading>

                  <ClearButton
                    onClick={() => handleClearButtonClick(showFilter)}
                  >
                    {t("clear")}
                  </ClearButton>
                </div>
                {/* <div onClick={() => handlefilterClick(filter)}> */}
                {/* {showFilter.filterComponent} */}
                {getFilterComponent(
                  showFilter?.column,
                  filterData[showFilter?.column]
                )}
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton onClick={handleApply} type="button">
          {t("apply")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedFiltersNew;

//styles

export const FilterNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 4px !important;
  white-space: nowrap;
  cursor: pointer;
  min-width: 160px;
  svg {
    display: none;
  }

  ${(p) =>
    p.isFiltered &&
    `svg {
    display: initial;
    fill: ${colorGray};
  }`}

  color: ${colorTextNew};
  &:hover {
    ${(p) =>
      !p.active &&
      `background: ${colorGrayNew};
    border-radius: 5px;`};
    // p {
    //   ${(p) => !p.active && `color: ${colorGrayNew}`};
    // }
  }

  ${(p) =>
    p.active &&
    css`
      p {
        color: white;
      }
      svg {
        fill: white;
      }
      background: ${buttonColorNew};
      border-radius: 5px;
    `}
`;

export const ModalHeader = styled(Modal.Header)`
  //background: ${buttonColorNew};
  //color: white;
  div {
    font-size: 16px;
    font-weight: bold;
  }
  svg {
    cursor: pointer;
  }
`;

export const FilterSubHeading = styled.h4`
  color: black;
  font-size: 15px;
  font-weight: 500;
`;

export const ClearButton = styled(FilterButton)`
  height: 32px;
`;
