import styled from "styled-components";
import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorBorder,
  colorDustyWhite,
  colorFieldsBorder,
  colorText,
  colorWhite,
} from "@/utils/palette";
import {
  left,
  paddingLeft,
  direction,
  marginLeft,
  marginRight,
} from "@/utils/directions";
import { ButtonToolbar } from "../Button";
import {
  anchorColor,
  anchorColorHover,
  colorBlue,
  colorBlueHover,
  colorFormItemHover,
  colorGray,
  colorGrayNew,
  colorLightBlue,
  colorLightGray,
  colorTextNew,
} from "../../../utils/palette";

export const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: relative;
`;

export const FormGroupField = styled.div`
  width: 100%;
  gap: 50px;
  display: flex;
  margin: auto;
  direction: ${direction};
`;
//Added
export const FormItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  //width: 250px;

  input {
    height: 34px;
    line-height: 34px;
  }

  //Code to remove blue background on autofill
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      ${(p) => !p.showWarning && `border-color: ${colorLightBlue}`};
  }`}

  ${({ readOnly }) => readOnly && `border: none;`}

  input {
    font-weight: 400;
    &::-webkit-input-placeholder {
      color: ${colorAdditional};
      font-size: 12px;
    }

    &[disabled] {
      line-height: 22px;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
  background: #f2f2f2;
  border-color: ${colorLightGray};
  span {
    background: #f2f2f2;
  }
  select {
    background: #f2f2f2;
    &[disabled] {
      cursor: context-menu;
    }
  }
`}

  // ${({ focus }) =>
    focus &&
    `
  //   border-color: ${colorLightBlue};
  // `}

  &:focus-within {
    outline: none;
    ${(p) => !p.showWarning && `border-color: ${colorLightBlue}`};
  }

  ${(p) => p.showWarning && `border-color: red`}
`;

//Added
export const UnitSpan = styled.span`
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  background: ${colorBackgroundBody};
  //border: 1px solid ${colorBlueHover};
  border-radius: 5px;
  //color: ${colorGray};
  font-size: 14px;
  font-weight: 300;
`;

//Added
export const FormHeading = styled.h5`
  margin-bottom: 10px;
  font-size: 16px;
  color: ${colorTextNew};
`;

export const FormGroupLabel = styled.span`
  margin-top: 16px;
  margin-bottom: 4px;
  //height: 12px;
  font-size: 12px;
  display: inline-block;
  color: ${colorTextNew};
  //text-transform: uppercase;

  span {
    color: ${colorDustyWhite};
  }

  svg {
    width: 18px;
    height: 18px;
    padding-left: 3px;
  }
`;

export const FormButtonToolbar = styled(ButtonToolbar)`
  margin-top: 10px;
  direction: ${direction};
`;

export const FormGroupDescription = styled.span`
  font-size: 10px;
  color: ${colorAdditional};
  line-height: 13px;
  margin-top: 2px;
`;

export const FormGroupIcon = styled.div`
  padding: 6px;
  height: 32px;
  background: ${colorFieldsBorder};
  border: 1px solid ${colorFieldsBorder};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  //position: relative;
  text-align: ${left};
  direction: ${direction};
  margin: 0 8px;

  //Code to remove blue background on autofill
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  // Remove up and down arrow from numbered inputs

  //For Chrome
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  //For Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  //For clock icon in timepicker
  input::-webkit-calendar-picker-indicator {
    filter: invert(0.4) sepia(0.1);
    cursor: pointer;
    //margin-right: -4px;
  }

  input,
  textarea {
    font-weight: 400;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    //line-height: 34px;
    //height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid ${colorLightGray};
    color: ${colorText};

    &::-webkit-input-placeholder {
      color: ${colorAdditional};
      font-size: 12px;
      vertical-align: middle;
    }
    &::-moz-placeholder {
      color: ${colorAdditional};
      font-size: 12px;
      vertical-align: middle;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: ${colorAdditional};
      font-size: 12px;
      vertical-align: middle;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: ${colorAdditional};
      font-size: 12px;
      vertical-align: middle;
    }

    // &[disabled] {
    //   background: #f2f2f2;
    //   line-height: 0px;
    //   //border-radius: 0px;

    //   &:focus,
    //   &:active {
    //     border-color: ${colorBorder};
    //   }
    // }

    &:focus,
    &:active {
      outline: none;
      border-color: ${colorBlue};
    }
  }

  input {
    &[disabled] {
      background: #f2f2f2;
      //line-height: 0;
      //border-radius: 0px;

      &:focus,
      &:active {
        border-color: ${colorBorder};
      }
    }
  }

  textarea {
    min-height: 85px;

    &[disabled] {
      background: #f2f2f2;
      //line-height: 0;
      //border-radius: 0px;
    }
  }

  ${(props) =>
    props.horizontal &&
    `

    ${FormGroup} {
      display: flex;
      flex-wrap: wrap;
    }

    ${FormGroupField} {
      width: calc(100% - 80px);
      ${paddingLeft(props)}: 10px;
      ${marginLeft(props)}: 80px;
    }

    ${FormGroupLabel} {
      width: 80px;
      max-height: 32px;
      //line-height: 18px;
      margin: auto 0;

      & + ${FormGroupField} {
        ${marginLeft(props)}: 0;
      }
    }

    ${FormGroupDescription} {
      ${marginLeft(props)}: 90px;
    }

    ${FormButtonToolbar} {
      ${marginLeft(props)}: 0;
    }

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        ${marginLeft(props)}: 120px;
        ${paddingLeft(props)}: 20px;
      }

      ${FormGroupDescription}, ${FormButtonToolbar} {
        ${marginLeft(props)}: 140px;
      }
    }
  `}

  ${(props) =>
    props.preview &&
    `
    display: flex;

    & > div:nth-child(2) {
      ${marginRight(props)}: 50px;
    }

    ${FormGroup} {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    p {
      margin-bottom: 10px;
    }
  `}

  ${(props) =>
    props.justify &&
    `
    display: flex;
    flex-wrap: wrap;

    ${FormGroup} {
      width: 33.3333%;
    }

    ${FormButtonToolbar} {
      width: 100%;
    }
  `}
`;

export const FormHalfContainer = styled.div`
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    ${marginRight}: 30px;
  }

  ${FormButtonToolbar} {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    ${marginRight}: 0;
  }
`;

export const FormFieldButton = styled.button`
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  background: ${(props) => (props.active ? colorAccent : colorFieldsBorder)};
  border: 1px solid
    ${(props) => (props.active ? colorAccent : colorFieldsBorder)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => (props.active ? colorWhite : colorAdditional)};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`;

export const CheckBoxInput = styled.input`
  position: relative;
  top: 1px;
  width: auto !important;
  //margin: 0 8px;
`;

export const Label = styled.label`
  //margin-left: 4px;
  margin: 0;

  ${(p) =>
    !p.disabled &&
    `
  
  &:hover {
    color: ${colorBlueHover};
  }
  `}
`;

export const HintText = styled.p`
  color: ${colorGray};
  font-size: 12px;
  padding-top: 0.25rem;
`;

export const CostInput = styled.input`
  background: white !important;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  text-align: end;
`;

export const CostElement = styled.p`
  text-align: end;
  padding: 0 0.5rem;
  font-size: 12px;
`;

export const FormValueContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  font-weight: 400;
`;

export const LinkSpan = styled.span`
  font-size: 12px;
  color: ${anchorColor};
  border-bottom: 1px solid ${anchorColor};
  cursor: pointer;
  width: max-content;

  &:hover {
    color: ${anchorColorHover};
    border-color: ${anchorColorHover};
  }
`;

export const LinkAnchor = styled.a`
  font-size: 12px;
  color: ${anchorColor};
  border-bottom: 1px solid ${anchorColor};
  cursor: pointer;
  width: max-content;

  &:hover {
    color: ${anchorColorHover};
    border-color: ${anchorColorHover};
  }
`;
