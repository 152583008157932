import React, { useState, useEffect, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import DataReactTable from "../DataTable/components/DataReactTable";

import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import RowOptions from "../../shared/components/table/components/RowOptions";
import {
  AddFormButton,
  ColumnHeading,
} from "../../shared/components/TableElements";
import { NATEXT } from "../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router";
import { postApi, putApi } from "../../api/getUser";
import UserComponent from "../../shared/components/table/components/UserComponent";
import { getDisplayDate } from "../../shared/helpers";
import { addRowData } from "../../features/row/rowSlice";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";

const DailyTimeSheetTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const {
    reload,
    setReload,
    isLoggedIn,
    setLoading,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();
  const location = useLocation();
  const screen = location.pathname;
  const { orgDate } = useSelector((state) => state.user);

  const [rowData, setRowData] = useState([]);

  const fromCancel = location?.state?.fromCancel;

  useEffect(() => {
    if (
      isLoggedIn &&
      screen?.includes("dailytimesheet") &&
      orgDate &&
      (!isClientApiCalled || !fromCancel)
    ) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        let colOrder = "[0,1,2,3,4,5,6]";
        let minDate = orgDate;
        let defaultRows = 10;
        const columnsapiData = {
          querystring: "get_user_screen_settings",
          screen_name: "dailytimesheet",
        };
        const userSettingsRes = await putApi(columnsapiData, "dashboard");
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          const { col_order, min_date, default_rows } = data;
          if (col_order) {
            colOrder = col_order;
          }
          if (min_date) {
            minDate = min_date;
          }
          defaultRows = default_rows;
        }
        dispatch(
          updateUserSettingsData({
            col_order: colOrder,
            min_date: minDate,
            default_rows: defaultRows,
          })
        );
        const apiData = {
          querystring: "get_daily_operator",
          sdate: 0,
          edate: getUnixTime(new Date()),
        };
        const res = await postApi(apiData, "commonNew");
        if (res.success) {
          const { user_data } = res;
          setRowData(user_data);
          dispatch(addRowData(user_data));
          setLoading(false);
        }
      })();
    }
  }, [isLoggedIn, orgDate]);

  //Reload Effect
  useEffect(() => {
    if (
      isLoggedIn &&
      screen?.includes("dailytimesheet") &&
      reload === "dailytimesheet"
    ) {
      setReload("");
      (async () => {
        const apiData = {
          querystring: "get_daily_operator",
          sdate: 0,
          edate: getUnixTime(new Date()),
        };
        const res = await postApi(apiData, "commonNew");
        if (res.success) {
          setReload("");
          const { user_data } = res;
          setRowData(user_data);
          dispatch(addRowData(user_data));
        }
      })();
    }
  }, [reload]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("name"),
        accessor: "name",
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <UserComponent
            userName={row.original.name}
            //groupName={row.original.group_name}
            userId={row.original.user_id}
            userImg={row.original.user_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("vehicle"),
        accessor: "veh_name",
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.veh_name}
            //groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 3,
        Header: t("date_tv"),
        accessor: "timesheet_date",
        //width: columnWidth,
        //Cell: ({ value }) => new Date(value * 1000).toLocaleDateString("en-GB"),
        Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
      },
      {
        id: "edit",
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row?.original} />,
        disableSortBy: true,
      },
      // {
      //   id: 12,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <Container>
      <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
        <Col>
          <ColumnHeading>{t("daily_ts")}</ColumnHeading>
        </Col>
        <Col className="d-flex justify-content-end mb-1">
          <Link to={"/dailytimesheet/addnew"}>
            <AddFormButton id="add-timesheet-button">
              {t("add_timesheet")}
            </AddFormButton>
          </Link>
        </Col>
      </Row>
      {/* <Row> */}
      {/* <Col>  */}
      <div style={{ height: "100%" }}>
        <DataReactTable
          reactTableData={reactTableData}
          //valuesArray={fillupsValuesArray}
          //initialData={initialData}
          //defaultColumns={defaultColumns}
        />
      </div>
      {/* </Col> */}
      {/* </Row> */}
    </Container>
    // <div style={{ height: '90%', background: 'lightblue' }}>Hi</div>
  );
};

export default DailyTimeSheetTable;
