import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import { getAdvancedFilterColumnName, getScreenName } from "../helpers";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import { initialLoadDelay } from "../constants";
import { useDispatch } from "react-redux";
import { putApi } from "../../api/getUser";
import getUnixTime from "date-fns/getUnixTime";
import { addSnackbarData } from "../../features/snackbar/snackbarSlice";
import { useTranslation } from "react-i18next";

const useFetchData = ({ startDate, endDate, query, sortIndex }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { pathname } = location;

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const { isLoggedIn, reload, setReload, setLoading } = useAuth();

  const { orgDate } = useSelector((state) => state.user);

  const { data, status } = useSelector((state) => state.filter);
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;
  const screenName = getScreenName(pathname);

  let columns = useSelector((state) => state.advancedFilter[screenName]);

  const fromCancel = location.state?.fromCancel;

  const storedFilters = useSelector((state) => state.storedFilters);

  //If coming from cancel, set first time load to true after timeout
  //to allow filtering in listing
  useEffect(() => {
    if (isLoggedIn && fromCancel) {
      setTimeout(() => {
        setFirstTimeLoad(false);
      }, initialLoadDelay);
    }
  }, [isLoggedIn]);

  //If status is failed, then show failed snackbar
  useEffect(() => {
    if (status === "failed") {
      dispatch(addSnackbarData({ msg: t("listing_err_msg"), type: 2 }));
    }
  }, [status]);

  //On initial load, send update initial data
  useEffect(() => {
    if (
      firstTimeLoad &&
      isLoggedIn &&
      orgDate &&
      (!fromCancel || status === "initial")
    ) {
      (async () => {
        //setFromCancel(true);
        setLoading(true);
        // if (columns) {
        //   dispatch(
        //     clearAllAdvancedFilters({
        //       columns: Object.keys(columns),
        //       screen: "fillups",
        //     })
        //   );
        // }
        //const startDate = 0;
        //const endDate = getUnixTime(new Date());

        let colOrder = "[0,1,2,3,4,5,6]";
        let minDate = orgDate;
        let defaultRows = 10;

        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: screenName,
        };

        const userSettingsRes = await putApi(apiData, "dashboard");
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { col_order, min_date, default_rows } = data;
            if (col_order) {
              colOrder = col_order;
            }
            if (min_date) {
              minDate = min_date;
            }
            defaultRows = default_rows;
          }
          dispatch(
            updateUserSettingsData({
              col_order: colOrder,
              min_date: minDate,
              default_rows: defaultRows,
            })
          );
        }

        let index = sortIndex;
        if (colOrder && colOrder.length > 0) {
          const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
          index = colOrderUI.findIndex((col) => col === sortIndex);
        }

        let startTime = startDate;
        let endTime = endDate;
        const currentScreenFilters = storedFilters[screenName];
        const dateFilter = currentScreenFilters?.find(
          (col) => col?.column === "date"
        );

        if (dateFilter) {
          const { from, to } = dateFilter;
          startTime = from;
          endTime = to;
        }

        if (pathname.includes("inspectionsubmissions")) {
          const dateFilter = currentScreenFilters?.find(
            (col) => col?.filterName === "date"
          );

          if (dateFilter) {
            const { from, to } = dateFilter?.value;
            startTime = from;
            endTime = to;
          }
        }

        const searchFilter = currentScreenFilters?.find(
          (col) => col?.filterName === "search"
        );

        dispatch(
          updateInitialData({
            query,
            startDate: startTime,
            endDate: endTime,
            order: index,
            length: defaultRows ? defaultRows : 10,
            search: searchFilter ? searchFilter.value : "",
          })
        );

        const initialData = {
          draw: 1,
          length: defaultRows ? defaultRows : 10,
          search: {
            value: searchFilter ? searchFilter?.value : "",
            regex: false,
          },
          order: [
            {
              column: index,
              dir: "desc",
            },
          ],
          start_date: startTime,
          end_date: endTime,
          start: 0,
          // filter_group_id: "",
          // filter_vehicle_id: "",
          filter_group_id,
          filter_vehicle_id,
          querystring: query,
        };

        dispatch(fetchData({ ...initialData, ...columns }));
        setTimeout(() => {
          setFirstTimeLoad(false);
        }, initialLoadDelay);
      })();
    }
  }, [isLoggedIn, orgDate, columns]);

  //Fetch data on every filter change expect for start and set start to 0
  useEffect(() => {
    if (
      //pathname.includes("fillups") &&
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === query &&
      !reload
    ) {
      dispatch(updateStart(0));
      dispatch(fetchData({ ...data, ...columns, start: 0 }));
    }
  }, [
    draw,
    length,
    //start,
    search,
    order,
    start_date,
    end_date,
    filter_group_id,
    filter_vehicle_id,
    //querystring,
    columns,
  ]);

  //Fetch data on change of start
  useEffect(() => {
    if (
      //pathname.includes("fillups") &&
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === query &&
      !reload
    ) {
      dispatch(fetchData({ ...data, ...columns }));
    }
  }, [start]);

  //Reload Effect
  useEffect(() => {
    if (
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === query &&
      reload === screenName
    ) {
      dispatch(updateStart(0));
      dispatch(fetchData({ ...data, ...columns, start: 0 }));
      setReload("");
    }
  }, [reload]);
};

export default useFetchData;
