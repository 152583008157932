// export const distanceCurrencyMapping = [
//   {
//     currency: "USD",
//     distance: "Miles",
//   },
//   {
//     currency: "GBP",
//     distance: "Miles",
//   },
//   {
//     currency: "ZAR",
//     distance: "Kilometers",
//   },
//   {
//     currency: "AUD",
//     distance: "Kilometers",
//   },
//   {
//     currency: "NZD",
//     distance: "Kilometers",
//   },
// //   {
// //     currency: "all",
// //     distance: "Kilometers",
// //   },
// ];

export const distanceCurrencyMapping = {
  //Default - Kilometers
  USD: "Miles",

  GBP: "Miles",

  ZAR: "Kilometers",

  //AUD: "Kilometers",

  //NZD: "Kilometers",
};

export const volumeCurrencyMapping = {
  //Default - Liters
  USD: "Gallons (US)",

  //GBP: "Liters",
  //ZAR: "Liters",
  //AUD: "Liters",
  //NZD: "Liters",
};

export const consumptionCurrencyMapping = {
  //Default - km/L
  USD: "mpg (US)",
  GBP: "mpg (UK)",
  ZAR: "L/100km",
  AUD: "L/100km",
  CAD: "L/100km",
  //NZD: 'km/L',
};

export const hourlyConsumptionCurrencyMapping = {
  //Default - L/Hr
  USD: "G (US)/Hr",
  GBP: "G (UK)/Hr",
};
