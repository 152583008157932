import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { ActionIconContainer, AddFormButton } from "./TableElements";
import { useTranslation } from "react-i18next";

const ErrorPopup = ({
  showPopup,
  setShowPopup,
  message,
  onClose,
  actionIcon,
  listMsg,
}) => {
  const { t } = useTranslation("common");

  const handleOnHide = () => {
    if (onClose) {
      onClose();
    } else {
      setShowPopup(false);
    }
  };

  return (
    <Modal show={showPopup} onHide={handleOnHide} centered size="md">
      <Modal.Body className="d-flex flex-column">
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer
            onClick={handleOnHide}
            id="close-info-popup-icon-container"
          >
            <i className="fi fi-rr-cross-small" id="close-info-popup-icon" />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-3 justify-content-start align-items-center p-2">
          <InfoIcon
            className={`fi fi-rr-triangle-warning align-self-start`}
            color={"red"}
            id="info-icon"
          />
          <div>
            {/* <p style={{ fontSize: "16px" }} id="info-msg">
              {message}
            </p> */}
            <p>{message || t("form_err_msg")}</p>
            {/* {listMsg && (
              <ul>
                {listMsg.map((msg, i) => (
                  <li key={i} style={{ fontSize: "14px", fontWeight: 400 }}>
                    {msg}
                  </li>
                ))}
              </ul>
            )} */}
          </div>
        </div>

        {actionIcon && (
          <div className="d-flex justify-content-center align-items-center mt-2">
            <AddFormButton
              onClick={actionIcon.handler}
              type="button"
              id="action-btn"
              style={{ width: "min-content" }}
            >
              {actionIcon.label}
            </AddFormButton>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorPopup;

export const InfoIcon = styled.i`
  font-size: 32px;
  color: ${(p) => p.color};
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
`;
