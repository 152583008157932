import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import {
  DurationComponent,
  WorkOrderStatus,
} from "../../../../../components/WorkOrders/WorkOrdersTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TaskPillComponent from "../TaskPillComponent";
import { getIssuePriorityText } from "../../../../../components/Issues/IssuesTable";
import { getDisplayDateWithTime, getDistanceUnit } from "../../../../helpers";
import { useEffect } from "react";
import { useState } from "react";
import { postApi } from "../../../../../api/getUser";
import { LinkSpan } from "../../../form/FormElements";
import PausesTable from "../../../../../components/WorkOrders/components/PausesTable";
import { useDispatch } from "react-redux";
import { fetchServicesTasks } from "../../../../../features/servicesTasks/servicesTasksSlice";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { taskBgColor } from "../../../../../utils/palette";
import { NATEXT } from "../../../../constants";
import LogsPopup from "../../../../../components/WorkOrders/components/LogsPopup";

const WorkOrdersRightPanel = ({
  rowData,
  logs,
  setLogs,
  showLogs,
  setShowLogs,
  serviceTasks,
  setServiceTasks,
}) => {
  const { t } = useTranslation("common");

  const { orgId } = useSelector((state) => state.user);
  const { currency, distance } = useSelector((state) => state.units);

  //const [serviceTasks, setServiceTasks] = useState([]);
  const [pauses, setPauses] = useState([]);
  const [completedPauses, setCompletedPauses] = useState([]);
  const [showPausesTable, setShowPausesTable] = useState(false);

  //const [logs, setLogs] = useState([]);
  const [showLogsPopup, setShowLogsPopup] = useState(false);

  //State to determine whether to show logs or old pause table
  //const [showLogs, setShowLogs] = useState(false);

  const {
    veh_id,
    assigned_to,
    comments,
    create_date,
    created_by,
    due_date,
    start_date,
    end_date,
    invoice_number,
    name,
    odo,
    po_number,
    priority,
    service_id,
    //service_task_ids,
    status,
    technician_id,
    all_issues,
    //total_cost,
    //type,
    wo_number,
    wo_img_name,
    tax,
    discount,
    //labor_cost,
    //parts_cost,
    wo_id,
    reminder_name,
    createdBy,
    parts_total,
    labor_total,
    total,
  } = rowData;

  // let partsTotal, laborTotal, total;
  // if (total_cost) {
  //   let totalCost = JSON.parse(total_cost);
  //   partsTotal = totalCost.parts_total;
  //   laborTotal = totalCost.labor_cost_total;
  //   total = totalCost.total;
  // }

  const workOrderImgs = [];
  let woImgNames = "";
  if (wo_img_name) {
    woImgNames = wo_img_name?.split(":::");
    woImgNames?.forEach((imgName, i) => {
      workOrderImgs.push({
        id: imgName,
        url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/workorder/${wo_id}/${imgName}`,
      });
    });
  }

  //Effect to fetch pauses
  useEffect(() => {
    //Logic to get pauses
    (async () => {
      const pauseApiFetchData = {
        querystring: "get_all_pauses_for_workorder",
        wo_id: wo_id,
      };
      const pauseResponse = await postApi(pauseApiFetchData, "commonNew");
      if (pauseResponse.success === 1) {
        const { user_data } = pauseResponse;
        const completedPauses = user_data.filter((p) => p.end_time);
        setPauses(user_data);
        setCompletedPauses(completedPauses);
      }
    })();
  }, [wo_id]);

  // useEffect(() => {
  //   (async () => {
  //     let serviceTaskOptions = [];
  //     let selectedServiceTasks = [];
  //     const apiData = {
  //       querystring: "get_all_service_tasks",
  //       service_veh_id: "All",
  //     };

  //     const res = await postApi(apiData, "commonNew");

  //     if (res.success) {
  //       serviceTaskOptions = res.user_data;
  //       if (rowData?.labor_cost && rowData?.parts_cost) {
  //         const serviceTaskIds = JSON.parse(rowData?.service_task_ids);
  //         const laborCostData = JSON.parse(rowData?.labor_cost);
  //         const partsCostData = JSON.parse(rowData?.parts_cost);
  //         serviceTaskIds?.forEach((taskId) => {
  //           let temp = {
  //             ...serviceTaskOptions.find((t) => t.service_task_id === taskId),
  //           };
  //           temp.partsCost = +partsCostData[taskId];
  //           temp.laborCost = +laborCostData[taskId];
  //           temp.totalCost = +partsCostData[taskId] + +laborCostData[taskId];
  //           selectedServiceTasks.push(temp);
  //         });
  //       }
  //     }
  //     setServiceTasks(selectedServiceTasks);
  //   })();
  // }, [rowData]);

  // useEffect(() => {
  //   //Single service fetch call on edit
  //   (async () => {
  //     const singleWOApiData = {
  //       querystring: "fetchSingleWoData",
  //       wo_id: rowData?.wo_id,
  //     };
  //     const singleWORes = await postApi(singleWOApiData, "workOrder");
  //     if (singleWORes.success) {
  //       const { service_details_data } = singleWORes;

  //       if (service_details_data?.length > 0) {
  //         let serviceTaskDetails = [];
  //         for (let task of service_details_data) {
  //           const {
  //             service_task_detail_id,
  //             service_task_id,
  //             service_id,
  //             wo_id,
  //             labor_cost,
  //             parts_cost,
  //             total_cost,
  //             service_task_name,
  //           } = task;
  //           serviceTaskDetails.push({
  //             service_task_detail_id,
  //             service_task_id,
  //             service_id,
  //             service_task_name,
  //             wo_id,
  //             laborCost: +labor_cost ? +labor_cost : "",
  //             partsCost: +parts_cost ? +parts_cost : "",
  //             totalCost: +total_cost ? +total_cost : "",
  //             action: 2,
  //             label: service_task_name,
  //             value: service_task_id,
  //           });
  //         }
  //         setServiceTasks(serviceTaskDetails);
  //       }
  //     }
  //   })();
  // }, [rowData]);

  return (
    <>
      <Row>
        <Col md={12}>
          <ItemContainer className="d-flex align-items-center gap-2">
            <ItemLabel className="m-0">{`${t("work_order")}:`}</ItemLabel>
            <ItemValue>{wo_number}</ItemValue>
          </ItemContainer>
        </Col>

        {/* {!!odo && +odo !== -1000 && (
          <Col>
            <ItemContainer>
              <ItemLabel>{t("odometer")}</ItemLabel>
              <ItemValue>{`${odo} ${getDistanceUnit(distance)}`}</ItemValue>
            </ItemContainer>
          </Col>
        )} */}

        <Col>
          <ItemContainer>
            <ItemLabel>{t("work_order_priority")}</ItemLabel>
            <ItemValue>{getIssuePriorityText(priority)}</ItemValue>
          </ItemContainer>
        </Col>

        {(all_issues || reminder_name) && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>
                {reminder_name ? t("assoc_reminder") : t("assoc_issue")}
              </ItemLabel>
              <ItemValue>
                <TaskPillComponent
                  stringValue={reminder_name ? reminder_name : all_issues}
                />
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {/* <Col>
        <ItemContainer>
        <ItemLabel>{t("status")}</ItemLabel>
        <ItemValue>
            <WorkOrderStatus status={status} />
          </ItemValue>
        </ItemContainer>
      </Col> */}

        {!!create_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("created_on")}</ItemLabel>
              <ItemValue>{getDisplayDateWithTime(create_date)}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {created_by && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("created_by")}</ItemLabel>
              <ItemValue>{createdBy}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!due_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("due_on")}</ItemLabel>
              <ItemValue>{getDisplayDateWithTime(due_date)}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {technician_id && (
          <Col>
            <ItemContainer>
              <ItemLabel>{t("assigned_to")}</ItemLabel>
              <ItemValue>{assigned_to}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!start_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("started_on")}</ItemLabel>
              <ItemValue>{getDisplayDateWithTime(start_date)}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!end_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("ended_on")}</ItemLabel>
              <ItemValue>{getDisplayDateWithTime(end_date)}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {!!start_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("duration")}</ItemLabel>
              <ItemValue>
                {<DurationComponent rowData={rowData} pauses={pauses} />}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {showLogs ? (
          <>
            {logs.length > 0 && (
              <Col md={12} className="mb-2">
                <ItemContainer>
                  <ItemValue>
                    <LinkSpan onClick={() => setShowLogsPopup(true)}>{`${t(
                      t("notes_prog_updates")
                    )} (${logs.length})
                       `}</LinkSpan>
                  </ItemValue>
                </ItemContainer>
              </Col>
            )}
            {showLogsPopup && (
              <LogsPopup
                showPopup={showLogsPopup}
                setShowPopup={setShowLogsPopup}
                logs={logs}
                setLogs={setLogs}
                //user={createdBy}
                readOnly={true}
              />
            )}
          </>
        ) : (
          <>
            {pauses.length > 0 && (
              <Col md={12} className="mb-2">
                <ItemContainer>
                  {/* <ItemLabel>{t("duration")}</ItemLabel> */}
                  <ItemValue>
                    <LinkSpan onClick={() => setShowPausesTable(true)}>{`${t(
                      "paused"
                    )} ${pauses.length} ${
                      pauses.length === 1 ? t("time_tv") : t("times")
                    }`}</LinkSpan>
                  </ItemValue>
                </ItemContainer>
              </Col>
            )}
            {showPausesTable && (
              <PausesTable
                showPopup={showPausesTable}
                setShowPopup={setShowPausesTable}
                pauses={pauses}
              />
            )}
          </>
        )}
      </Row>

      {serviceTasks?.map((task) => {
        return (
          <Row
            key={task.service_task_id}
            style={{
              background: taskBgColor,
              padding: "10px 0",
              margin: "0.25rem 0",
              borderRadius: "5px",
            }}
          >
            <p style={{ fontSize: "16px" }}>{task.service_task_name}</p>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("parts")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.partsCost ? task.partsCost : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("labor")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.laborCost ? task.laborCost : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("tc_tv")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.totalCost ? task.totalCost : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>
          </Row>
        );
      })}

      {serviceTasks.length > 0 && (
        <>
          <Row
            style={{
              background: taskBgColor,
              padding: "10px 0",
              margin: "0.25rem 0",
              borderRadius: "5px",
            }}
          >
            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("parts_total")}`}</ItemLabel>
                <ItemValue>
                  {parts_total ? parts_total?.toFixed(2) : 0}
                </ItemValue>
              </ItemContainer>
            </Col>
            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("labor_total")}`}</ItemLabel>
                <ItemValue>
                  {labor_total ? labor_total?.toFixed(2) : 0}
                </ItemValue>
              </ItemContainer>
            </Col>
            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("total")}`}</ItemLabel>
                <ItemValue>{total ? total?.toFixed(2) : 0}</ItemValue>
              </ItemContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col sm={6}>
              <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
                <ItemLabel
                  className="m-0"
                  //style={{ minWidth: "90px" }}
                >
                  {t("discount")}:
                </ItemLabel>
                <ItemValue>{`${
                  +discount ? Math.round(+discount) : 0
                } ${currency}`}</ItemValue>
              </ItemContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col sm={6}>
              <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
                <ItemLabel
                  className="m-0"
                  //style={{ minWidth: "80px" }}
                >
                  {t("tax")}:
                </ItemLabel>
                <ItemValue>{`${
                  +tax ? Math.round(+tax) : 0
                } ${currency}`}</ItemValue>
              </ItemContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col sm={6}>
              <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
                <ItemLabel
                  className="m-0"
                  //style={{ minWidth: "90px" }}
                >
                  {t("grand_total")}:
                </ItemLabel>
                <ItemValue style={{ fontWeight: 500 }}>
                  {`${total - discount + tax} ${currency}`}
                </ItemValue>
              </ItemContainer>
            </Col>
          </Row>
        </>
      )}

      <Row>
        {comments && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {workOrderImgs.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {workOrderImgs.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {po_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("purchase_order_no")}</ItemLabel>
              <ItemValue>{po_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {invoice_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("invoice_no")}</ItemLabel>
              <ItemValue>{invoice_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default WorkOrdersRightPanel;
