import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  LinkAnchor,
  LinkSpan,
} from "../../shared/components/form/FormElements";
import { t } from "i18next";
import { Card, CardBody } from "../../shared/components/Card";
import { useTranslation } from "react-i18next";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { post, postApi } from "../../api/getUser";
import format from "date-fns/format";
import { BoldSpan, NormalSpan } from ".";
import {
  colorBlue,
  colorStatusActive,
  colorSwitchActive,
} from "../../utils/palette";
import InvoicesTable from "./InvoicesTable";
import ComparePlansTable from "./ComparePlansTable";
import { ActionIconContainer } from "../../shared/components/TableElements";
import { useHistory } from "react-router";
import ActionPopup from "../../shared/components/ActionPopup";
import FreeImg from "../../shared/img/Free.png";
import EssentialImg from "../../shared/img/Essential.png";
import AdvancedImg from "../../shared/img/Advanced.png";
import MediumImg from "../../shared/img/medium.png";
import EnterpriseImg from "../../shared/img/enterprise.png";
import SmallImg from "../../shared/img/small.png";

const ExistingPlan = ({
  paymentData,
  invoices,
  priceDetailsData,
  subscriptionData,
  perVehiclePrice,
  toggleValue,
}) => {
  const { t } = useTranslation("common");

  const [showComparePlans, setShowComparePlans] = useState(false);
  const [showNewPlanPopup, setShowNewPlanPopup] = useState(false);

  let planType = "";
  let planImg = "";
  const productPlan = subscriptionData?.product;
  if (productPlan === "5") {
    planImg = EssentialImg;
    planType = t("essential");
  } else if (productPlan === "6") {
    planType = t("advanced");
    planImg = AdvancedImg;
  } else if (productPlan === "1") {
    planType = t("small_sm");
    planImg = SmallImg;
  } else if (productPlan === "2") {
    planType = t("medium_sm");
    planImg = MediumImg;
  } else if (productPlan === "3") {
    planType = t("enterprise_sm");
    planImg = EnterpriseImg;
  }

  const isAndroid = subscriptionData.isandroid;
  const isIos = subscriptionData.isios;

  let blueCardText = "";

  const advancedFeatures = [
    t("everything_in_essential"),
    t("work_order_mgmt"),
    t("trip_mgmt"),
    t("priority_support"),
  ];

  if (isAndroid) {
    blueCardText = t("android_plan_msg");
  } else if (isIos) {
    blueCardText = blueCardText = t("apple_plan_msg");
  } else {
    if (["1", "2", "3"].includes(productPlan)) {
      blueCardText = t("per_vehicle_billing_structure_msg");
    } else if (productPlan === "5") {
      blueCardText = `${t("advanced_plan_marketing_text")} ${
        advancedFeatures[Math.floor(Math.random() * 4)]
      } `;
    }
  }

  const duration =
    paymentData?.duration === "year" ? t("year_sm") : t("month_sm");
  const quantity = paymentData?.quantity;
  const amount = paymentData?.amount;
  const totalAmount = paymentData?.quantity * paymentData?.amount;
  const currency = paymentData?.currency;

  const qtyPriceString =
    !isAndroid && !isIos
      ? `${quantity} ${
          quantity === 1 ? t("vehicle_sm") : t("vehicles_sm")
        } @ ${currency} ${amount}/${t("vehicle_sm")}`
      : `${subscriptionData.no_of_vehicles} ${
          subscriptionData.no_of_vehicles === 1
            ? t("vehicle_sm")
            : t("vehicles_sm")
        }`;

  let renewsOn =
    typeof paymentData.current_period_end === "number"
      ? `${t("renews_on")} ${format(
          new Date(paymentData?.current_period_end * 1000),
          "d MMMM yyyy"
        )}`
      : paymentData.current_period_end;

  if (isAndroid || isIos) {
    renewsOn = format(
      new Date(subscriptionData.read_expiration_date_sec),
      "d MMMM yyyy"
    );
  }

  const email = paymentData?.email;
  const cardDetails = `${paymentData.brand} ${t("ending_in_sm")} ${
    paymentData.last4
  }`;
  const expiry = `${t("expiry")} ${paymentData.exp_month}/${
    paymentData.exp_year
  }`;

  const handleChangePlan = async () => {
    const res = await post("subscription", {
      querystring: "create_customer_session",
      is_react: 1,
    });
    window.location.href = res;
  };

  const handleChangeCancel = () => {
    const oldPlans = ["1", "2", "3"];
    if (oldPlans.includes(subscriptionData.product)) {
      setShowNewPlanPopup(true);
    } else {
      handleChangePlan();
    }
  };

  const CurrentPlanCard = () => {
    const { t } = useTranslation("common");
    return (
      <Card shadow="true">
        <CardBody className="d-flex flex-column gap-2">
          <h5>{t("current_plan")}</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <img
                //style={{ width: "40px", height: "40px" }}
                src={planImg}
                width={30}
                height={30}
              />
              <div className="d-flex flex-column">
                <BoldSpan>{`${planType}`}</BoldSpan>
                {!isAndroid && !isIos && (
                  <NormalSpan>{`${currency} ${totalAmount}/${duration}`}</NormalSpan>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <BoldSpan>{qtyPriceString}</BoldSpan>
              {!isAndroid && !isIos && paymentData?.duration === "year" && (
                <NormalSpan>{`${currency} ${(totalAmount / 12)?.toFixed(2)}/${t(
                  "month_sm"
                )} (${t("billed_yearly_sm")})`}</NormalSpan>
              )}
            </div>
          </div>
          {/* "#28a745"  */}

          <NormalSpan style={{ color: "#28a745" }}>{renewsOn}</NormalSpan>
          {!isAndroid && !isIos && (
            <>
              <LinkAnchor
                //style={{ fontSize: 14 }}
                onClick={handleChangeCancel}
              >{`${t("change")} / ${t("cancel_plan")}`}</LinkAnchor>
              <LinkSpan
                //style={{ fontSize: 14 }}
                onClick={() => setShowComparePlans(true)}
              >
                {t("see_all_plans_and_pricing")}
              </LinkSpan>
            </>
          )}
        </CardBody>
      </Card>
    );
  };

  const PaymentMethodCard = () => {
    const { t } = useTranslation("common");
    return (
      <Card shadow="true">
        <CardBody className="d-flex flex-column gap-2">
          <h5>{t("payment_method")}</h5>

          <div className="d-flex gap-2 align-items-start">
            {/* <div> */}
            <i
              className="fi fi-rr-credit-card"
              style={{
                fontSize: "45px",
                position: "relative",
                bottom: 5,
                height: "45px",
              }}
            ></i>
            {/* </div> */}
            <div className="d-flex flex-column">
              <BoldSpan>{cardDetails}</BoldSpan>
              <NormalSpan>{expiry}</NormalSpan>
            </div>
          </div>
          <Desc>{email}</Desc>
          <LinkAnchor
            //style={{ fontSize: 14 }}
            onClick={handleChangePlan}
          >{`${t("change")} ${t("payment_method")}`}</LinkAnchor>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="d-flex flex-column gap-4">
      {showNewPlanPopup && (
        <ActionPopup
          showPopup={showNewPlanPopup}
          setShowPopup={setShowNewPlanPopup}
          handleYes={handleChangePlan}
          handleNo={() => setShowNewPlanPopup(false)}
          message={t("change_sub_to_new_plan_msg")}
          modalSize={"lg"}
        />
      )}
      {showComparePlans && (
        <Modal
          size="xl"
          show={showComparePlans}
          onHide={() => setShowComparePlans(false)}
        >
          <Modal.Header>
            <h4>{t("compare_plans")}</h4>
            <div className="d-flex justify-content-end">
              <ActionIconContainer onClick={() => setShowComparePlans(false)}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ComparePlansTable
              perVehiclePrice={perVehiclePrice}
              toggleValue={toggleValue}
              currency={priceDetailsData?.currency}
              currencyCode={priceDetailsData?.currency_code}
              insideModal={true}
              subscriptionData={subscriptionData}
            />
          </Modal.Body>
        </Modal>
      )}
      <Row className="d-flex justify-content-center">
        <Col className="d-flex justify-content-center" md={5}>
          <CurrentPlanCard />
        </Col>
        {!isAndroid && !isIos && (
          <Col className="d-flex justify-content-center" md={5}>
            <PaymentMethodCard />
          </Col>
        )}
      </Row>
      {blueCardText && (
        <Row className="d-flex justify-content-center">
          <Col md={10}>
            <Card shadow="true">
              <CardBody style={{ background: "#9fc5f8" }}>
                <NormalSpan>
                  {blueCardText}{" "}
                  {!isAndroid && !isIos && subscriptionData.product === "5" && (
                    <LinkSpan
                      style={{ fontSize: 14 }}
                      onClick={handleChangePlan}
                    >
                      {t("click_here_to_upgrade")}
                    </LinkSpan>
                  )}
                  {!isAndroid &&
                    !isIos &&
                    ["1", "2", "3"].includes(subscriptionData.product) && (
                      <LinkSpan
                        style={{ fontSize: 14 }}
                        onClick={() => setShowComparePlans(true)}
                      >
                        {t("click_here_to_learn_more")}
                      </LinkSpan>
                    )}
                </NormalSpan>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!isAndroid && !isIos && (
        <Row className="d-flex justify-content-center mb-4">
          <Col md={10}>
            <InvoicesTable invoices={invoices} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ExistingPlan;
