import { Route, Switch } from "react-router";
import InspectionScheduleForm from "./InspectionScheduleForm";
import InspectionScheduleTable from "./InspectionScheduleTable";

export default () => (
  <Switch>
    <Route
      exact path="/inspection/inspectionscheduling/"
      component={InspectionScheduleTable}
    />
    <Route
      path="/inspection/inspectionscheduling/addnew"
      component={InspectionScheduleForm}
    />
  </Switch>
);