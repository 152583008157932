import {
  colorAdditional,
  colorBlue,
  colorGray,
  colorLightBlue,
  colorLightGray,
  colorTextNew,
} from "../../utils/palette";

export const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minWidth: "100px",
    maxWidth: "200px",
    height: "35px",
    marginLeft: "0",
    //marginRight: "5px",
    //marginTop: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    color: colorTextNew,
    borderColor: state.hasValue ? colorBlue : colorLightGray,
    borderWidth: state.hasValue ? "2px" : "1px",
    minHeight: state.isFocused ? "35px" : "35px",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? colorTextNew : colorTextNew,
    backgroundColor: state.isSelected
      ? colorLightBlue
      : baseStyles.backgroundColor,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: colorTextNew,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "#636c72",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: 2,
  }),
};

export const selectStylesNew = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      minWidth: "140px",
      maxWidth: "150px",
      padding: "0px",
      margin: "0px",
      height: "32px",
      //marginLeft: "0",
      //marginRight: "15px",
      //marginTop: "10px",
      //borderRadius: "5px",
      cursor: "pointer",
      color: colorTextNew,
      borderColor: state.isFocused ? colorLightBlue : colorLightGray,
      //minHeight: state.isFocused ? "35px" : "35px",
      minHeight: "32px",
      borderRadius: "5px",
      zIndex: 1,
      boxShadow: state.isFocused ? null : null,
      borderColor:
        state.getValue().length > 0 &&
        state.getValue()[0]?.value !== "" &&
        state.getValue()[0]?.value !== "all"
          ? colorBlue
          : colorLightGray,
      borderWidth:
        state.getValue().length > 0 &&
        state.getValue()[0]?.value !== "" &&
        state.getValue()[0]?.value !== "all"
          ? "2px"
          : "1px",
    };
  },
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? colorTextNew : colorTextNew,
    backgroundColor: state.isSelected
      ? colorLightGray
      : baseStyles.backgroundColor,
    // borderBottom: state.isSelected
    //   ? `1px solid ${colorGray}`
    //   : baseStyles.border,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    paddingBottom: state.getValue().length > 0 ? "4px" : "0px",
    color: colorTextNew,
    zIndex: 1,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    //color: "#636c72",
    padding: "5px 2px",
    margin: 1,
    color: colorAdditional,
    fontSize: "12px",
    //lineHeight: '32px',
    //minHeight: '32px'
    //padding: "7px 0",
  }),
  container: (baseStyles, state) => {
    const type = state.innerProps.id;
    return {
      ...baseStyles,
      //height: "fit-content",
      zIndex: type === "group-dropdown" ? 30 : 20,
      //padding: "7px 0",
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    //height: "32px",
    //padding: "0 7px",
    padding: "0 6px",
    margin: 0,
    //overflowY: 'auto'
  }),

  input: (provided, state) => ({
    ...provided,
    padding: "0 2px",
    margin: 0,
    //paddingBottom: "5px",
    //lineHeight: '32px',
    //height: "32px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
  multiValueContainer: (provided, state) => ({
    ...provided,
    //margin: 0,
    //paddingRight: '5px',
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    //marginTop: "-1px",
    margin: 0,
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    //...provided,
    padding: "0 8px 4px 8px",
    //color: state.isFocused || state.hasValue ? colorGray : colorLightGray,
    color: colorGray,
  }),
};

export const statusStyles = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      minWidth: "150px",
      maxWidth: "400px",
      padding: "0px",
      margin: "0px",
      cursor: "pointer",
      height: "32px",
      color: colorTextNew,
      borderColor: state.isFocused ? colorLightBlue : colorLightGray,
      minHeight: "32px",
      borderRadius: "5px",
      zIndex: 1,
      boxShadow: state.isFocused ? null : null,
      borderColor:
        state.getValue().length > 0 && state.getValue()[0]?.value !== ""
          ? colorBlue
          : colorLightGray,
      borderWidth:
        state.getValue().length > 0 && state.getValue()[0]?.value !== ""
          ? "2px"
          : "1px",
    };
  },
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? colorTextNew : colorTextNew,
    backgroundColor: state.isSelected ? "white" : baseStyles.backgroundColor,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    paddingBottom:
      state.getValue().length > 0 && state.getValue()[0]?.value !== ""
        ? "4px"
        : "0px",
    color: colorTextNew,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,

    padding: "5px 2px",
    margin: 1,
    color: colorAdditional,
    fontSize: "12px",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,

    zIndex: 10,
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    padding: "0 6px",
    margin: 0,
    //flexWrap: 'unset'
  }),

  input: (provided, state) => ({
    ...provided,
    padding: "0 2px",
    margin: 0,
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    //marginTop: '-2px',
    marginTop:
      state.getValue().length > 0 && state.getValue()[0]?.value !== ""
        ? "-2px"
        : "0px",
  }),
  multiValueContainer: (provided, state) => ({
    ...provided,
    //margin: 0,
    //paddingRight: '5px',
    //display: "none",
    //height: '32px',
    marginTop: "10px",
    //overflowX: "scroll",
    //flex: '1 0 auto',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    marginTop: "-1px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    marginBottom: "-1px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    marginBottom: "-1px",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    //...provided,
    padding: "0 8px 2px 8px",
    //color: state.isFocused || state.hasValue ? colorGray : colorLightGray,
    color: colorAdditional,
  }),
};

export const formStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    //minWidth: "250px",
    padding: "0px",
    margin: "0px",
    //height: "32px",
    //marginLeft: "0",
    //marginRight: "15px",
    //marginTop: "10px",
    //borderRadius: "5px",
    cursor: "pointer",
    color: colorTextNew,
    borderColor: state.isFocused ? colorLightBlue : colorLightGray,
    //minHeight: state.isFocused ? "35px" : "35px",
    minHeight: "34px",
    boxShadow: state.isFocused ? null : null,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? colorTextNew : colorTextNew,
    backgroundColor: state.isSelected
      ? colorLightBlue
      : baseStyles.backgroundColor,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    paddingBottom: "2px",
    color: colorTextNew,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "#636c72",
    padding: 0,
    marginRight: "4px",
    fontSize: "12px",
    //lineHeight: '32px',
    //minHeight: '32px'
    //padding: "7px 0",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "34px",
    //height: "fit-content",
    //zIndex: 2,
    //padding: "7px 0",
    //width: 250
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: "34px",
    //padding: "0 7px",
    padding: "0 6px",
    margin: 0,
    //overflowY: 'auto'
  }),

  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    //paddingBottom: "5px",
    //lineHeight: '32px',
    //height: "32px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
    zIndex: 10,
    //marginBottom: '10px'
  }),
  dropdownIndicator: (provided, state) => ({
    //...provided,
    padding: "0 10px 2px 10px",
    //color: state.isFocused || state.hasValue ? colorGray : colorLightGray,
    color: colorAdditional,
  }),
};

export const issuesStyles = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      minWidth: "150px",
      maxWidth: "300px",
      padding: "0px",
      margin: "0px",
      height: "32px",
      //marginLeft: "0",
      //marginRight: "15px",
      //marginTop: "10px",
      //borderRadius: "5px",
      cursor: "pointer",
      color: colorTextNew,
      borderColor: state.isFocused ? colorLightBlue : colorLightGray,
      //minHeight: state.isFocused ? "35px" : "35px",
      minHeight: "32px",
      borderRadius: "5px",
      zIndex: 1,
      boxShadow: state.isFocused ? null : null,
      borderColor:
        state.getValue().length > 0 && state.getValue()[0]?.value !== "all"
          ? colorBlue
          : colorLightGray,
      borderWidth:
        state.getValue().length > 0 && state.getValue()[0]?.value !== "all"
          ? "2px"
          : "1px",
    };
  },
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? colorTextNew : colorTextNew,
    backgroundColor: state.isSelected
      ? colorLightGray
      : baseStyles.backgroundColor,
    // borderBottom: state.isSelected
    //   ? `1px solid ${colorGray}`
    //   : baseStyles.border,
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    paddingBottom:
      state.getValue().length > 0 //&& state.getValue()[0]?.value !== "all"
        ? "4px"
        : "0px",
    color: colorTextNew,
    zIndex: 1,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    //color: "#636c72",
    padding: "5px 2px",
    margin: 1,
    color: colorAdditional,
    fontSize: "12px",
    //lineHeight: '32px',
    //minHeight: '32px'
    //padding: "7px 0",
  }),
  container: (baseStyles, state) => {
    const type = state.innerProps.id;
    return {
      ...baseStyles,
      //height: "fit-content",
      zIndex: type === "group-dropdown" ? 30 : 20,
      //padding: "7px 0",
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
    margin: 0,
  }),

  input: (provided, state) => ({
    ...provided,
    padding: "0 2px",
    margin: 0,
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
  multiValueContainer: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    //...provided,
    padding: "0 8px 4px 8px",
    //color: state.isFocused || state.hasValue ? colorGray : colorLightGray,
    color: colorGray,
  }),
};

export const formInputStyles = {
  //borderColor: colorLightGray,
  color: colorTextNew,
  border: "none",
  borderRadius: 5,
  fontSize: "14px",
  outline: "none",
  width: "100%",
  paddingLeft: "0.5rem",
  //padding: '0 0.5rem',
  lineHeight: "34px",
  //boxShadow: 'none'
  background: "none",
};

//Styles for open menu dropdown
export const openDropdownStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    //minWidth: "250px",
    padding: "0px",
    margin: "0px",
    cursor: "text",
    //height: "32px",
    //marginLeft: "0",
    //marginRight: "15px",
    //marginTop: "10px",
    //borderRadius: "5px",
    //cursor: "pointer",
    color: colorTextNew,
    borderColor: state.isFocused ? colorLightBlue : colorLightGray,
    //minHeight: state.isFocused ? "35px" : "35px",
    minHeight: "32px",
    boxShadow: state.isFocused ? null : null,
  }),
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      color: state.isFocused ? colorTextNew : colorTextNew,
      backgroundColor: state.isSelected ? "white" : "white",
      backgroundColor: state.isFocused ? "#faf9f6" : "white",
      // borderBottom: state.isSelected
      //   ? `1px solid ${colorGray}`
      //   : baseStyles.border,
    };
  },
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: colorTextNew,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    //color: "#636c72",
    padding: "5px 2px",
    margin: 1,
    color: colorAdditional,
    fontSize: "13px",
    //lineHeight: '32px',
    //minHeight: '32px'
    //padding: "7px 0",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    //height: "fit-content",
    //zIndex: 2,
    //padding: "7px 0",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    //height: "32px",
    //padding: "0 7px",
    padding: "0 6px",
    margin: 0,
    //overflowY: 'auto'
  }),

  input: (provided, state) => ({
    ...provided,
    padding: "0 2px",
    margin: 0,
    //paddingBottom: "5px",
    //lineHeight: '32px',
    //height: "32px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
  // multiValueRemove: (provided, state) => ({
  //   ...provided,
  //   display: 'none'
  // }),
  // multiValueLabel: (provided, state) => ({
  //   ...provided,
  //   margin: 0,
  //   padding: 0,
  //   //margin: 0,
  //   //paddingRight: '5px',
  //   display: 'none',
  // }),
  multiValueContainer: (provided, state) => ({
    ...provided,
    //margin: 0,
    //paddingRight: '5px',
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
    height: `${window.innerHeight - 220}px`,
    border: `1px solid ${colorLightGray}`,
    boxShadow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    //margin: 0,
    //height: "100%",
    //height: `${window.innerHeight - 400}px`,
    maxHeight: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};
