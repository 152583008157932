import React from "react";
import ReactTablePagination from "../../shared/components/table/components/ReactTablePagination";
import ReactTableHeader from "../../shared/components/table/components/ReactTableHeader";
import BodyReactTable from "../../shared/components/table/components/ReactTableBody";
import {
  ActionIcon,
  StatusContainer,
  Table,
} from "../../shared/components/TableElements";
import { TableWrap } from "../../shared/components/table/components/ReactTableConstructor";
import {
  useAsyncDebounce,
  useColumnOrder,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { getDisplayDate } from "../../shared/helpers";
import { LinkAnchor } from "../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../shared/components/Card";
import { SearchInput } from "../../shared/components/table/components/TempFilter";
import { colorStatusActive, colorSwitchActive } from "../../utils/palette";

const InvoicesTable = ({ invoices }) => {
  const { t } = useTranslation("common");
  const invoicesColumnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("date_tv"),
        accessor: "created",
        //accessor: (row) => getDisplayDate(row.original.created),
        Cell: ({ value }) => getDisplayDate(value),
      },
      {
        id: 2,
        Header: t("amount"),
        accessor: "amount",

        //Cell: ({ row }) => `${row.original.odo} ${getDistanceUnit(distance)}`,
      },
      {
        id: 3,
        Header: t("status"),
        //accessor: "amount",
        disableSortBy: true,
        Cell: ({ row }) => (
          <StatusContainer background={colorStatusActive}>
            <p>{t("paid")}</p>
          </StatusContainer>
        ),
      },
      {
        id: 4,
        Header: t("pdf"),
        accessor: "pdf",
        disableSortBy: true,
        Cell: ({ value }) => (
          <LinkAnchor href={value}>{t("download")}</LinkAnchor>
        ),
      },
    ],
    []
  );
  const tableOptions = {
    columns: invoicesColumnHeaders,
    data: invoices,
    //updateDraggableData,
    //updateEditableData,
    //setFilterValue,
    defaultColumn: {},
    isEditable: false,
    withDragAndDrop: false,
    dataLength: invoices.length,
    autoResetPage: false,
    disableSortBy: false, // !isSortable
    //manualSortBy: true, // !isSortable
    //manualGlobalFilter: true, //!withSearchEngine
    //manualPagination: true, //!withPagination
    pageCount: Math.ceil(invoices?.length / length),
    initialState: {
      pageIndex: 0,
      pageSize: 25,
      //pageSize: 50,  //manualPageSize ? manualPageSize[0] : 10,
      globalFilter: "",
      sortBy: [{ id: 1, desc: true }], // id: columns[0]?.id
      columnOrder: [1, 2, 3, 4, 5, 6],
      //hiddenColumns: [7, 8, 9, 10, 11, 12, 13],
    },
    disableSortRemove: true,
    //defaultCanSort: true,
    //autoResetSortBy: false,
  };

  const {
    allColumns,
    toggleHideColumn,
    visibleColumns,
    getToggleHideAllColumnsProps,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    setHiddenColumns,
    setColumnOrder,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, sortBy, columnOrder, hiddenColumns },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect
  );

  const onChange = useAsyncDebounce((item) => {
    const value = item.trim();
    // if (value) {
    //   setSearchHasValue(true);
    // } else {
    //   setSearchHasValue(false);
    // }

    //setFilterValue(value);
    setGlobalFilter(value);
  }, 200);

  return (
    <>
      <Card shadow={"true"}>
        <CardBody className="p-0">
          <div style={{ background: "#f9f9f9" }} className="p-3">
            <SearchInput
              id="search"
              type="search"
              placeholder={t("filter_by_keyword")}
              onChange={(e) => onChange(e.target.value)}
              //active={searchHasValue}
            />
          </div>
          <TableWrap
            className="p-3"
            //windowHeight={windowHeight}
            pagination={true}
            //height={}
            //style={{ background: "white" }}
          >
            <Table
              {...getTableProps()}
              bordered
              id="invoices-data-table"
              //className={}
            >
              {/* Removed bordered property from Table and added className='resizable-table'*/}
              <ReactTableHeader
                headerGroups={headerGroups}
                isSortable={true}
                isResizable={false}
              />
              <BodyReactTable
                page={page}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                updateDraggableData={updateDraggableData}
                updateEditableData={updateEditableData}
                isEditable={false}
                withDragAndDrop={false}
              />
            </Table>
            {rows.length === 0 && (
              <div style={{ textAlign: "center", fontWeight: 600 }}>
                No Records
              </div>
            )}
          </TableWrap>

          {rows.length > 0 && (
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={[10, 25, 50, 100]}
              dataLength={dataLength}
              screen={"/billing"}
              rows={rows}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default InvoicesTable;
