import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import Modal from "react-bootstrap/Modal";
import { FilterButton } from "./TableElements";
import { downloadImage } from "../helpers";

const ZoomComponent = ({ showPopup, setShowPopup, isImg, doc }) => {
  const [rotateDeg, setRotateDeg] = useState(0);

  const getFileType = (ext) => {
    //console.log(ext);
    if (ext.includes("pdf")) {
      return "pdf";
    }
    if (
      ext.includes("image") ||
      ext.includes("img") ||
      ext.includes("png") ||
      ext.includes("jpeg") ||
      ext.includes("jpg") ||
      ext.includes("webp") ||
      ext.includes("jfif")
    ) {
      return "img";
    }

    if (ext.includes("csv")) {
      return "csv";
    }
  };

  let fileType = "";

  if (doc?.file) {
    fileType = getFileType(doc?.file?.type);
  } else if (doc?.extension) {
    if (doc.extension === "txt") {
      fileType = getFileType(doc?.type[0]);
    } else {
      fileType = getFileType(doc.extension);
    }
  } else {
    fileType = getFileType(doc?.url?.split(".").pop());
  }

  const [docType, setDocType] = useState("");

  const handleRotate = () => {
    setRotateDeg((prev) => (prev === 270 ? 0 : prev + 90));
  };

  const handleReset = (reset) => {
    setRotateDeg(0);
    reset();
  };

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className="d-flex justify-content-end gap-1 mb-3 mx-3">
        <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={() => zoomIn()}
        >
          <i className="fi fi-rr-zoom-in m-0"></i>
        </FilterButton>
        <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={() => zoomOut()}
        >
          <i className="fi fi-rr-zoom-out m-0"></i>
        </FilterButton>
        <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={() => handleReset(resetTransform)}
        >
          <i className="fi fi-rr-refresh m-0"></i>
        </FilterButton>
        <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={handleRotate}
        >
          <i className="fi fi-rr-rotate-right m-0"></i>
        </FilterButton>
        <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={() => {
            downloadImage(doc?.url, doc?.extension);
          }}
        >
          <i className="fi fi-rr-cloud-download-alt m-0"></i>
        </FilterButton>
      </div>
    );
  };

  return (
    // <Modal show={showPopup} onHide={() => setShowPopup(false)} size="md">
    <>
      {fileType === "img" && (
        <div
        //style={{ width: 600, height: 600 }}
        >
          <TransformWrapper>
            <Controls />
            <TransformComponent>
              <Img
                rotateDeg={rotateDeg}
                src={doc.url}
                alt="zoomed-img"
                //width="100%"
                //width={500}
                //height={400}
                //className={className}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}

      {fileType === "pdf" && <embed src={doc.url} height={400} width={600} />}
    </>
    // </Modal>
  );
};

export default ZoomComponent;

//styles

const Img = styled.img`
  transform: rotate(${(p) => p.rotateDeg}deg);
  object-fit: contain;
  width: auto;
  max-width: 750px;
  height: 400px;
`;
