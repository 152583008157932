import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, newUrl, url } from "../../api/getUser";
import { auth } from "../../api/firebase";

const initialState = {
  list: [],
  status: "idle",
  error: null,
};

export const fetchExpenseTasks = createAsyncThunk(
  "expenseTasks/fetchExpenseTasks",
  async (apiData) => {
    try {
      const token = await getToken(); //sessionStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const email = auth?.currentUser?.email; //sessionStorage.getItem("email");
      const response = await axios.post(newUrl, { ...apiData, email }, config);

      const data = response?.data[0]?.value?.user_data;
      return data;
    } catch (err) {
      return err.message;
    }
  }
);

const expenseTasksSlice = createSlice({
  name: "expenseTasks",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchExpenseTasks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseTasks.fulfilled, (state, action) => {
        console.log(action.payload);
        state.status = "succeeded";
        const expenseTasksList = [];
        action.payload.forEach((item) => {
          expenseTasksList.push({
            ...item,
            value: item.expense_task_id,
            label: item.expense_task_name,
            //laborCost: 0,
            //partsCost: 0,
            cost: "",
          });
        });
        //console.log('action.payload', action.payload);
        state.list = expenseTasksList; //action.payload;
      })
      .addCase(fetchExpenseTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default expenseTasksSlice.reducer;
