import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ColumnHeading } from "../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../shared/components/Card";
import { useState } from "react";
import { postApi } from "../../api/getUser";
import { getDisplayDate } from "../../shared/helpers";
import styled from "styled-components";
import ExistingPlan from "./ExistingPlan";
import NewPlan from "./NewPlan";
import { TableSpinner } from "../../shared/components/table/components/ReactTableConstructor";
import { useAuth } from "../../contexts/AuthContext";
import useQuery from "../../shared/custom-hooks/useQuery";
import InfoPopup from "../../shared/components/InfoPopup";

const Billing = () => {
  const { t } = useTranslation("common");

  const { isLoggedIn } = useAuth();

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [perVehiclePrice, setPerVehiclePrice] = useState({
    essential: 0,
    advanced: 0,
  });
  const [totalPrice, setTotalPrice] = useState({ essential: 0, advanced: 0 });
  const [priceDetailsData, setPriceDetailsData] = useState(null);
  const [noOfVehicles, setNoOfVehicles] = useState(5);

  const [paymentData, setPaymentData] = useState(null);
  const [invoices, setInvoices] = useState([]);

  const [loading, setLoading] = useState(true);

  const [toggleValue, setToggleValue] = useState("monthly");
  const [trialUser, setTrialUser] = useState(false);

  const query = useQuery();
  const status = query.get("status");
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [subscriptionPopupData, setSubscriptionPopupData] = useState({
    message: t("sub_success_msg"),
    icon: { className: "fi fi-rs-check-circle", color: "green" },
  });

  useEffect(() => {
    if (isLoggedIn) {
      //Show Subscription popup based on status retrieved from query params
      if (status) {
        if (status === "success") {
          setSubscriptionPopupData({
            message: t("sub_success_msg"),
            icon: { className: "fi fi-rs-check-circle", color: "green" },
          });
        } else if (status === "failed") {
          setSubscriptionPopupData({
            message: t("sub_failure_msg"),
            icon: { className: "fi fi-rr-cross-circle", color: "red" },
          });
        }
        setShowSubscriptionPopup(true);
      }
      (async () => {
        const subRes = await postApi(
          {
            querystring: "get_subscription_status",
          },
          "commonNew"
        );
        if (subRes.success) {
          const { subscription_data } = subRes;
          setSubscriptionData(subscription_data);

          if (subscription_data.subscription_status === "trial") {
            setTrialUser(true);
          }

          if (subscription_data.subscription_status === "paid") {
            if (subscription_data?.stripe_status === "canceled") {
              if (subscription_data?.read_expiration_date_sec <= new Date()) {
                setTrialUser(true);
              }

              if (subscription_data?.isandroid || subscription_data?.isios) {
                setTrialUser(true);
              }
            }
            const invoiceRes = await postApi(
              { querystring: "get_invoice_details" },
              "subscription"
            );
            if (invoiceRes.success) {
              const { card } = invoiceRes;
              setPaymentData(card);
              const invoiceData = invoiceRes.invoices;

              let temp = invoiceData.map((i) => ({
                ...i,
                invoiceDate: getDisplayDate(i.created),
              }));

              setInvoices(temp);
            }
          }
        }

        const priceDetailsRes = await postApi(
          {
            querystring: "get_price_deatils",
          },
          "subscription"
        );
        if (priceDetailsRes.success) {
          const { subscription } = priceDetailsRes;
          const {
            advance_monthly_price,
            advance_yearly_price,
            currency,
            enterprice_price,
            enterprice_yearly_price,
            essential_monthly_price,
            essential_yearly_price,
            medium_price,
            medium_yearly_price,
            small_price,
            small_yearly_price,
          } = subscription;

          setPerVehiclePrice({
            essential: essential_monthly_price,
            advanced: advance_monthly_price,
          });

          setTotalPrice({
            essential: essential_monthly_price * noOfVehicles,
            advanced: advance_monthly_price * noOfVehicles,
          });

          setPriceDetailsData(subscription);
        }
      })();
    }
  }, [isLoggedIn]);

  //Effect to set Loading to false
  useEffect(() => {
    if (subscriptionData) {
      const { subscription_status } = subscriptionData;
      if (subscription_status === "paid") {
        if (priceDetailsData && paymentData) {
          setLoading(false);
        }
      } else {
        if (priceDetailsData) {
          setLoading(false);
        }
      }
    }
  }, [subscriptionData, priceDetailsData, paymentData, invoices]);

  //Efffect to change totalPrice on change of noOfVehicles
  // useEffect(() => {
  //   let vehicles = noOfVehicles <= 5 ? 5 : noOfVehicles;

  //     setTotalPrice((prev) => {
  //       let temp = { ...prev };
  //       temp.essential = prev.essential * vehicles;
  //       temp.advanced = prev.advanced * vehicles;
  //       return temp;
  //     });

  //     setTotalPrice({ essential:  })

  // }, [noOfVehicles]);

  //Effect to change price per vehicle on change of monthly/yearly toggle plans
  useEffect(() => {
    if (priceDetailsData) {
      let vehicles = noOfVehicles <= 5 ? 5 : noOfVehicles;
      const {
        advance_monthly_price,
        advance_yearly_price,
        essential_monthly_price,
        essential_yearly_price,
      } = priceDetailsData;
      if (toggleValue === "monthly") {
        setPerVehiclePrice({
          essential: essential_monthly_price,
          advanced: advance_monthly_price,
        });
        setTotalPrice({
          essential: essential_monthly_price * vehicles,
          advanced: advance_monthly_price * vehicles,
        });
      } else {
        setPerVehiclePrice({
          essential: essential_yearly_price,
          advanced: advance_yearly_price,
        });
        setTotalPrice({
          essential: essential_yearly_price * vehicles,
          advanced: advance_yearly_price * vehicles,
        });
      }
    }
  }, [toggleValue, noOfVehicles]);

  // const handleToggleChange = (e) => {
  //   setToggleValue(e.currentTarget.value);
  // };

  return (
    <>
      {showSubscriptionPopup && (
        <InfoPopup
          showPopup={showSubscriptionPopup}
          setShowPopup={setShowSubscriptionPopup}
          message={subscriptionPopupData?.message}
          icon={subscriptionPopupData?.icon}
          actionIcon={{
            handler: () => setShowSubscriptionPopup(false),
            label: t("ok"),
          }}
        />
      )}
      <Container>
        <Row
          className="pb-3 pt-1 px-1 d-flex align-items-center justify-content-around"
          //style={{ background: "white", position: "sticky", top: 0 }}
        >
          <Col>
            <ColumnHeading>{t("billing")}</ColumnHeading>
          </Col>
        </Row>
        <Card>
          <CardBody style={{ background: "#f5f5f5" }}>
            {loading ? (
              <div
                className="text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <TableSpinner animation="border" />
              </div>
            ) : trialUser ? (
              <NewPlan
                priceDetailsData={priceDetailsData}
                totalPrice={totalPrice}
                perVehiclePrice={perVehiclePrice}
                noOfVehicles={noOfVehicles}
                setNoOfVehicles={setNoOfVehicles}
                toggleValue={toggleValue}
                setToggleValue={setToggleValue}
                subscriptionData={subscriptionData}
              />
            ) : (
              <ExistingPlan
                paymentData={paymentData}
                priceDetailsData={priceDetailsData}
                invoices={invoices}
                subscriptionData={subscriptionData}
                perVehiclePrice={perVehiclePrice}
                toggleValue={toggleValue}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Billing;

export const RightIcon = (
  <i
    className="fi fi-rs-check-circle"
    style={{ color: "green", position: "relative", top: 2 }}
  />
);

export const WrongIcon = (
  <i
    className="fi fi-rr-cross-circle"
    style={{ color: "red", position: "relative", top: 2 }}
  />
);

//styles
export const BoldSpan = styled.span`
  font-weight: 600;
`;

export const NormalSpan = styled.span`
  font-weight: 400;
`;
