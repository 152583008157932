import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { buttonColorNew, colorLightGray } from "../../../../utils/palette";
import { AddFormButton } from "../../TableElements";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { DateContainer } from "./TempFilter";
import useClickAway from "../../../custom-hooks/useClickAway";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import startOfYesterday from "date-fns/startOfYesterday";
import endOfYesterday from "date-fns/endOfYesterday";
import startOfYear from "date-fns/startOfYear";
import { convertToFilterDate, getDateFnsLocale } from "../../../helpers";
import { CustomInput } from "../../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { FormGroupLabel } from "../../form/FormElements";
import addDays from "date-fns/addDays";

const CombinedDateFilter = ({
  //date,
  //setDate,
  //toggleDateFilter,
  //setToggleDateFilter,
  presetDate,
  handleDateApply,
  allTimeStartDate,
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const screen = location.pathname;

  const [toggleDateFilter, setToggleDateFilter] = useState(false);

  const [date, setDate] = useState({
    filterText: presetDate?.filterText
      ? presetDate?.filterText
      : "last_90_days",
    from: presetDate?.from ? presetDate?.from : subDays(new Date(), 89),
    to: presetDate?.to ? presetDate?.to : new Date(),
    dateOption: presetDate?.dateOption
      ? presetDate?.dateOption
      : "last-90-days",
  });

  useEffect(() => {
    setDate({
      filterText: presetDate?.filterText
        ? presetDate?.filterText
        : "last_90_days",
      from: presetDate?.from ? presetDate?.from : subDays(new Date(), 89),
      to: presetDate?.to ? presetDate?.to : new Date(),
      dateOption: presetDate?.dateOption
        ? presetDate?.dateOption
        : "last-90-days",
    });
  }, [presetDate]);

  const dateFilterRef = useClickAway({
    handleCloseElement: () => setToggleDateFilter(false),
  });
  const dateOptionsArr = [
    {
      label: t("all_time"),
      value: "all-time",
    },
    {
      label: t("today"),
      value: "today",
    },
    {
      label: t("yesterday"),
      value: "yesterday",
    },
    {
      label: t("last_7_days"),
      value: "last-7-days",
    },
    {
      label: t("last_30_days"),
      value: "last-30-days",
    },
    {
      label: t("last_90_days"),
      value: "last-90-days",
    },
    {
      label: t("this_month"),
      value: "this-month",
    },
    {
      label: t("this_year"),
      value: "this-year",
    },
    {
      label: t("custom"),
      value: "custom",
    },
  ];

  const dateOptionsObj = {
    "all-time": t("all_time"),
    today: t("today"),

    yesterday: t("yesterday"),

    "last-7-days": t("last_7_days"),

    "last-30-days": t("last_30_days"),

    "last-90-days": t("last_90_days"),

    "this-month": t("this_month"),

    "this-year": t("this_year"),

    custom: t("custom"),
  };

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
    getCurrentDates(date.dateOption);
  }, []);

  useEffect(() => {
    setSelected(null);
  }, [date.dateOption]);

  function getCurrentDates(value) {
    if (value === "all-time") {
      const end = new Date();
      const start = allTimeStartDate;
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "last-7-days") {
      const end = new Date();
      const start = subDays(new Date(), 6);
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "this-month") {
      const end = new Date();
      //const start = new Date().setDate(1);
      const start = startOfMonth(new Date());
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "today") {
      //const end = new Date();
      //const start = new Date();
      const start = startOfToday();
      const end = endOfToday();
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "last-30-days") {
      const end = new Date();
      const start = subDays(new Date(), 29);
      //let start = new Date();
      //start.setDate(start.getDate() - 30);
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "yesterday") {
      //const end = new Date();
      //const start = new Date();
      const start = startOfYesterday();
      const end = endOfYesterday();
      //start.setDate(start.getDate() - 1);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "last-90-days") {
      const end = new Date();
      //let start = new Date();
      //start.setDate(start.getDate() - 90);
      const start = subDays(new Date(), 89);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "this-year") {
      const end = endOfToday(); //new Date();
      const start = startOfYear(new Date());
      //let start = new Date(new Date().getFullYear(), 0, 1);
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setDate((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "custom") {
      //const end = new Date();
      setSelected(null);
      let start = new Date();
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setDate((prev) => ({
        ...prev,
        //from: start,
        //to: start,
        dateOption: value,
      }));
    }
  }

  const handleDateChange = (selected) => {
    //console.log(selected);
    const [start, end] = selected;
    setDate((prev) => ({
      ...prev,
      from: start,
      to: end,
      dateOption: "custom",
    }));
    //setSelectedDateOption("custom");
  };

  const onStartDateChange = (date) => {
    setDate((prev) => ({
      ...prev,
      from: date,
      dateOption: "custom",
    }));
  };

  const onEndDateChange = (date) => {
    setDate((prev) => ({
      ...prev,
      to: date,
      dateOption: "custom",
    }));
  };

  const handleDateOptionClick = (option) => {
    //setSelectedDateOption(option.value);
    getCurrentDates(option.value);
  };

  const handleApply = () => {
    setToggleDateFilter((prev) => !prev);
    setDate((prev) => ({
      ...prev,
      filterText:
        date?.dateOption === "custom"
          ? `${convertToFilterDate(date.from)} - ${convertToFilterDate(
              date.to
            )}`
          : dateOptionsObj[date?.dateOption],
      //warningText: getWarningText(),
    }));
    handleDateApply(date);
  };
  return (
    <div ref={dateFilterRef}>
      <DateContainer onClick={() => setToggleDateFilter((prev) => !prev)}>
        <span>{date.filterText}</span>
        <i className="fi fi-bs-angle-small-down"></i>
      </DateContainer>
      {toggleDateFilter && (
        <DateFilterContainer>
          <Row
            className="m-0 p-0"
            style={{ borderBottom: "1px solid lightgray" }}
          >
            <Col className="m-0 p-1">
              {dateOptionsArr.map((option) => {
                return (
                  <DateOptionContainer
                    id={option.value}
                    key={option.value}
                    isSelected={option.value === date.dateOption}
                    onClick={() => handleDateOptionClick(option)}
                  >
                    {option.label}
                  </DateOptionContainer>
                );
              })}
            </Col>
            <Col className="m-0 pt-2 px-2">
              {/* <DatePicker
                selected={selected}
                onChange={handleDateChange}
                startDate={date.from}
                endDate={date.to}
                selectsRange
                inline
                showMonthDropdown
                showYearDropdown
              /> */}
              <div style={{ width: "200px" }}>
                {!(
                  screen.includes("dashboard") &&
                  date?.dateOption === "all-time"
                ) && (
                  <>
                    <FormGroupLabel id="from-label">{t("from")}</FormGroupLabel>
                    <DatePicker
                      //className="col-xl-12"
                      locale={getDateFnsLocale()}
                      dateFormat={"P"}
                      name="From"
                      selected={date.from}
                      onChange={onStartDateChange}
                      customInput={<CustomInput />}
                      showYearDropdown={true}
                      showMonthDropdown={true}
                    />
                    <FormGroupLabel id="to-label">{t("to")}</FormGroupLabel>

                    <DatePicker
                      name="To"
                      minDate={date.from ? addDays(date.from, 1) : null}
                      locale={getDateFnsLocale()}
                      dateFormat={"P"}
                      selected={date.to}
                      onChange={onEndDateChange}
                      customInput={<CustomInput />}
                      showYearDropdown={true}
                      showMonthDropdown={true}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row className="m-0 p-2" style={{ width: "-webkit-fill-available" }}>
            <Col sm={12} className="d-flex justify-content-center m-0 p-0">
              <AddFormButton
                onClick={handleApply}
                disabled={!date.from || !date.to}
              >
                {t("apply")}
              </AddFormButton>
            </Col>
          </Row>
        </DateFilterContainer>
      )}
    </div>
  );
};

export default CombinedDateFilter;

//styles

export const DateFilterContainer = styled.div`
  border: 1px solid ${colorLightGray};
  background: white;
  position: absolute;
  display: flex;
  z-index: 100;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  //width: 400px;
`;

export const DateOptionContainer = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  whitespace: nowrap;
  width: 120px;

  &:hover {
    background: ${colorLightGray};
  }

  &:active {
    background: ${buttonColorNew};
    color: white;
  }

  ${(p) =>
    p.isSelected &&
    `
  background: ${buttonColorNew};
  color: white;
  &:hover {
    background: ${buttonColorNew};
  }
  `};
`;
