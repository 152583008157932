import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  className: 'light',
};

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    changeThemeToLight: (state, action) => {
      state.className = 'light';
    },
    changeThemeToDark: (state, action) => {
      state.className = 'dark';
    },
  },
});

export const { changeThemeToLight, changeThemeToDark } = modeSlice.actions;

export default modeSlice.reducer;



