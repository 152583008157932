import React from "react";
import "./Switch.css";

const Switch = ({ isOn, handleToggle, onColor, isDisabled, name, id }) => {
  return (
    <span id="switch-span">
      <input
        //name={name}
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
      />
      <label
        style={{
          background: isOn && onColor,
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        className="react-switch-label"
        htmlFor={id}
        id="switch-label"
      >
        <span className={`react-switch-button`} id="switch-btn-span" />
      </label>
    </span>
  );
};

export default Switch;
