import React, { useState, useEffect, useContext, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getVehicles, postApi, putApi } from "../../api/getUser";
import DataReactTable from "../DataTable/components/DataReactTable";
import { NATEXT } from "../../shared/constants";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import {
  AddFormButton,
  ColumnHeading,
  StatusContainer,
} from "../../shared/components/TableElements";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  colorStatusActive,
  colorStatusInactive,
  colorYellowNew,
} from "../../utils/palette";
import { updateVehiclesList } from "../../features/vehicles/vehiclesSlice";
import { useAuth } from "../../contexts/AuthContext";
import {
  updateNoOfRecords,
  updateUserSettingsData,
} from "../../features/columns/columnsSlice";
import { useHistory, useLocation } from "react-router";
import SubscribePopup from "../../shared/components/SubscribePopup";
import {
  daysHoursMinutes,
  getDistanceUnit,
  getTranslatedFuelTypeUnit,
  getTranslatedPMUnit,
} from "../../shared/helpers";
import { addRowData } from "../../features/row/rowSlice";
import {
  initialContainsData,
  initialRangeData,
  initialfuelTypeData,
} from "../../shared/constants/filterData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const VehiclesTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowData, setRowData] = useState([]);

  const location = useLocation();
  const fromCancel = location?.state?.fromCancel;

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const { distance } = useSelector((state) => state.units);

  const groupsList = useSelector((state) => state.groups.list);
  const {
    isLoggedIn,
    reload,
    setReload,
    setLoading,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();
  const { roleId } = useSelector((state) => state.user);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  const KPIValues = [
    {
      id: 0,
      name: t("active_vehicles"), //t("cost"),
      value: vehiclesList?.filter((v) => v.veh_active)?.length,
    },
    {
      id: 1,
      name: t("vehicle_downtime"),
      value: NATEXT,
    },
  ];

  //Effect to set initial default rows and calculate down-time
  useEffect(() => {
    if (isLoggedIn && (!isClientApiCalled || !fromCancel)) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        let defaultRows = 10;
        let minDate = 0;
        let colOrder = "[0,1,2,3,4,5]";
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "vehicle",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { default_rows, col_order } = data;
            if (col_order) {
              colOrder = col_order;
            }
            defaultRows = default_rows;
          }
          dispatch(
            updateUserSettingsData({
              col_order: colOrder,
              min_date: minDate,
              default_rows: defaultRows,
              screen: "vehicles",
            })
          );
          const vehiclesList = await getVehicles("get_all_org_vehicles_v2", t);
          dispatch(updateVehiclesList(vehiclesList));
          dispatch(addRowData(vehiclesList));
          setLoading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && reload === "vehicles") {
      (async () => {
        const vehiclesList = await getVehicles("get_all_org_vehicles_v2", t);
        dispatch(updateVehiclesList(vehiclesList));
        dispatch(addRowData(vehiclesList));
        if (reload === "vehicles") {
          setReload("");
        }
      })();
    }
  }, [reload]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          // const prevSecondaryStatus = prev.original.secondary_status;
          // const currSecondaryStatus = curr.original.secondary_status;
          // const prevVehActive = curr.original.secondary_status;
          // const currSecondaryStatus = curr.original.secondary_status;

          const prevVehicleUnconfirmed =
            prev.original.veh_active === 0 &&
            prev.original.secondary_status === 2;
          const currentVehicleUnconfirmed =
            curr.original.veh_active === 0 &&
            curr.original.secondary_status === 2;

          const sortByVehicleName = () => {
            if (
              prev.original[columnId]?.toLowerCase() >
              curr.original[columnId]?.toLowerCase()
            ) {
              return 1;
            } else if (
              prev.original[columnId]?.toLowerCase() <
              curr.original[columnId]?.toLowerCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          };

          if (prevVehicleUnconfirmed && currentVehicleUnconfirmed) {
            sortByVehicleName();
          }

          if (prevVehicleUnconfirmed && !currentVehicleUnconfirmed) {
            return -1;
          }

          if (!prevVehicleUnconfirmed && !currentVehicleUnconfirmed) {
            return 1;
          }

          // if (
          //   prev.original[columnId]?.toLowerCase() >
          //   curr.original[columnId]?.toLowerCase()
          // ) {
          //   return 1;
          // } else if (
          //   prev.original[columnId]?.toLowerCase() <
          //   curr.original[columnId]?.toLowerCase()
          // ) {
          //   return -1;
          // } else {
          //   return 0;
          // }
        },
        //sortType: "alphanumeric",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        //width: getColumnWidth("name", "Vehicle"),
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("year"),
        accessor: "year",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 3,
        Header: t("type"),
        accessor: "veh_type",
        //disableFilters: true,
        //width: getColumnWidth("fillup_date", "Date"),
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 4,
        Header: t("status"),
        accessor: "veh_active",
        Cell: ({ row }) => <VehicleStatus rowData={row.original} />,
        //disableFilters: true,
        //width: 150,
      },
      {
        id: 5,
        Header: t("operational_status"),
        accessor: "veh_active",
        Cell: ({ row }) => <SecondaryStatus rowData={row.original} />,
        //disableFilters: true,
        //width: 150,
      },
      {
        id: 6,
        Header: t("make"),
        accessor: "make",
        //disableFilters: true,
        //width: getColumnWidth("odo", "Odo"),
        // disableGlobalFilter: false,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 7,
        Header: t("model"),
        accessor: "model",
        Cell: ({ value }) => (value ? value : NATEXT),
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
      },
      {
        id: 8,
        Header: t("license"),
        accessor: "license_no",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 9,
        Header: t("vin"),
        accessor: "vin_no",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 10,
        Header: t("primary_meter"),
        accessor: "primary_meter",
        Cell: ({ value }) => (value ? getTranslatedPMUnit(value, t) : NATEXT),
      },
      {
        id: 11,
        Header: t("fuel_type"),
        accessor: "fuel_type",
        Cell: ({ value }) =>
          value ? getTranslatedFuelTypeUnit(value, t) : NATEXT,
      },
      {
        id: 12,
        Header: t("current_odo"), //t("fuel_type"),
        accessor: "odo",
        Cell: ({ row }) => {
          const { primary_meter, odo } = row?.original;
          let odoUnit = primary_meter === "Hours" ? primary_meter : distance;
          return odo ? `${odo} ${getDistanceUnit(odoUnit)}` : NATEXT;
        },
      },

      {
        id: 13,
        Header: t("group"),
        accessor: "group_id",
        Cell: ({ value }) => {
          return value
            ? groupsList?.find((u) => u.group_id === value)?.group_name
            : NATEXT;
        },
        //Cell: ({ value }) => (value ? value : NATEXT),

        //disableFilters: true,
        //width: 150,
      },

      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    [groupsList, distance]
  );

  const handleAddVehicle = async () => {
    const apiData = {
      querystring: "getusersubscriptionstatus",
      source: "vehicle",
    };
    const res = await postApi(apiData, "commonNew");
    console.log("res", res);
    let errMsg = "";
    if (res.success) {
      const { subscription_data } = res;
      if (subscription_data != "Email not found") {
        const { no_of_vehicles, prod_id, prostatus, usercount } =
          subscription_data;
        //usercount refers to vehicleCount(No of vehicles)

        if (prostatus == "trial" && usercount >= 5) {
          errMsg = t("no_pro_err_msg");
        } else {
          if (prostatus == "paid" && prod_id == 1 && usercount >= 30) {
            errMsg = t("no_pro_vehicle_err_msg");
          } else if (prostatus == "paid" && prod_id == 2 && usercount >= 100) {
            errMsg = t("no_pro_vehicle_err_msg");
          } else if (prostatus == "paid" && prod_id == 3 && usercount >= 300) {
            errMsg = t("no_pro_vehicle_enterprise_err_msg");
          } else if (
            prostatus == "paid" &&
            prod_id == 5 &&
            usercount >= no_of_vehicles
          ) {
            errMsg = t("no_pro_vehicle_count_err_msg");
          } else if (
            prostatus == "paid" &&
            prod_id == 6 &&
            usercount >= no_of_vehicles
          ) {
            errMsg = t("no_pro_vehicle_count_err_msg");
          }
          // else {
          //   errMsg = t("pro_err_msg");
          // }
        }
      } else {
        errMsg = t("no_pro_err_msg");
      }
    } else {
      errMsg = t("no_pro_err_msg");
    }

    if (errMsg) {
      setsubscribePopupErrMsg(errMsg);
      setShowSubscribePopup(true);
      return;
    }

    history.push("vehicles/addnew");
  };

  const initialData = {
    make: {
      column: "make",
      name: t("make"),
      accessor: "make",
      ...getDefaultFilterData().defaultContainsData,
    },
    model: {
      column: "model",
      name: t("model"),
      accessor: "model",
      ...getDefaultFilterData().defaultContainsData,
    },
    vehicleType: {
      column: "vehicleType",
      name: t("veh_type"),
      accessor: "veh_type",
      ...getDefaultFilterData().defaultContainsData,
    },
    fuelType: {
      column: "fuelType",
      name: t("fuel_type"),
      ...getDefaultFilterData().defaultFuelTypeData,
    },
    primaryMeter: {
      column: "primaryMeter",
      name: t("primary_meter"),
      ...getDefaultFilterData().defaultPrimaryMeterData,
    },
    customSpecs: {
      column: "customSpecs",
      name: t("custom_specs"),
      accessor: "custom_specs",
      ...getDefaultFilterData().defaultContainsData,
    },
    docDetails: {
      column: "docDetails",
      name: t("doc_name_details"),
      accessor: "additional_documents",
      ...getDefaultFilterData().defaultContainsData,
    },
    currentOdo: {
      column: "currentOdo",
      name: t("current_odo"),
      accessor: "odo",
      ...getDefaultFilterData().defaultRangeData,
    },
  };

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: vehiclesList.slice(1, vehiclesList.length),
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          <Col
            sm={10}
            //className="d-flex justify-content-start align-items-center"
          >
            <ColumnHeading>{t("vehicles")}</ColumnHeading>
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            {roleId !== 4 && (
              // <Link to={"/vehicles/addnew"}>
              <AddFormButton
                id="add-form-btn"
                type="button"
                onClick={handleAddVehicle}
              >
                {t("add_vehicle")}
              </AddFormButton>
              // </Link>
            )}
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={KPIValues}
            initialData={initialData}
            //filterToggleColumns={filterToggleColumns}
            //defaultColumns={defaultColumns}
            //columnSizeData={columnSizeData}
          />
        </Row>
      </Container>
    </>
  );
};

export default VehiclesTable;

export const VehicleStatus = ({ rowData }) => {
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const { t } = useTranslation("common");
  let selectedVehicle = vehiclesList.find(
    (v) => v.vehicleId === rowData?.veh_id
  );
  //console.log(selectedVehicle);
  let statusData = { text: "", bg: "" };

  const vehActive = selectedVehicle?.veh_active;
  const secondaryStatus = selectedVehicle?.secondary_status;

  if (vehActive == 1 || (vehActive == 0 && secondaryStatus == 1)) {
    statusData = { text: t("active"), bg: colorStatusActive };
  } else {
    statusData = { text: t("inactive"), bg: colorStatusInactive };
  }

  return (
    <StatusContainer background={statusData.bg}>
      <p
      //style={{ color: "white" }}
      >
        {statusData.text}
      </p>
    </StatusContainer>
  );
};

export const SecondaryStatus = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { veh_active, secondary_status } = rowData;
  let secondaryStatus = { text: t("in_service"), color: "green" };

  if (veh_active === 0 && secondary_status === 1) {
    secondaryStatus.text = t("out_of_service");
    secondaryStatus.color = "red";
  }

  if (
    (veh_active === 0 && secondary_status === 0) ||
    (veh_active === 2 && secondary_status === 0)
  ) {
    secondaryStatus.text = "-";
    secondaryStatus.color = "";
  }

  if (veh_active === 0 && secondary_status === 2) {
    secondaryStatus.text = t("unconfirmed");
    secondaryStatus.color = "#E19C00";
  }

  return (
    <div style={{ color: secondaryStatus.color }}>{secondaryStatus.text}</div>
  );
};
