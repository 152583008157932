import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import MailIcon from "mdi-react/MailIcon";
import {
  TopbarBack,
  TopbarButton,
  TopbarButtonNewLabel,
} from "./BasicTopbarComponents";
import {
  TopbarCollapse,
  TopbarCollapseContent,
  TopbarCollapseTitleWrap,
  TopbarCollapseItem,
  TopbarCollapseImageWrap,
  TopbarCollapseMessage,
  TopbarCollapseName,
  TopbarCollapseDate,
  TopbarCollapseLink,
  TopbarCollapseTitle,
  TopbarCollapseButton,
} from "./CollapseTopbarComponents";
import { SidebarLinkIcon } from "../sidebar/SidebarLink";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { colorBlue, colorLightGray } from "../../../../utils/palette";

const TopbarMail = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const collapseMail = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <TopbarCollapse>
      <TopbarMailButton type="button" onClick={collapseMail}>
        {/* <MailIcon /> */}
        <SidebarLinkIcon icon={faQuestionCircle} />
        {/* <TopbarButtonNewLabel>
          <span />
        </TopbarButtonNewLabel> */}
      </TopbarMailButton>
      {isCollapsed && (
        <TopbarBack
          aria-label="topbar__back"
          type="button"
          onClick={collapseMail}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarCollapseContent>
          {/* <TopbarCollapseTitleWrap>
            <TopbarCollapseTitle>Unread messages</TopbarCollapseTitle>
            <TopbarCollapseButton type="button">Mark all as read</TopbarCollapseButton>
          </TopbarCollapseTitleWrap> */}
          {/* {notifications.map((notification) => (
            <TopbarCollapseItem key={notification.id}>
              <TopbarCollapseImageWrap>
                <img src={notification.ava} alt="" />
              </TopbarCollapseImageWrap>
              <TopbarCollapseName>{notification.name}</TopbarCollapseName>
              <TopbarMailCollapseMessage>{notification.message}</TopbarMailCollapseMessage>
              <TopbarCollapseDate>{notification.date}</TopbarCollapseDate>
            </TopbarCollapseItem>
            
          ))} */}
          <LinkContainer>
            <i className="fi fi-rr-info"></i>
            <a href="https://www.simplyfleet.app/faq">FAQ</a>
          </LinkContainer>
          <LinkContainer>
            <i className="fi fi-rr-info"></i>
            <a href="https://www.simplyfleet.app/video-tutorial">Demo Video</a>
          </LinkContainer>
          <LinkContainer>
            <i className="fi fi-rr-info"></i>
            <span>Feedback</span>
          </LinkContainer>
          <LinkContainer>
            <i className="fi fi-rr-info"></i>
            <span>Contact Support</span>
          </LinkContainer>
          {/* <TopbarCollapseLink to="/mail" onClick={collapseMail}>
            See all messages
          </TopbarCollapseLink> */}
        </TopbarCollapseContent>
      </Collapse>
    </TopbarCollapse>
  );
};

export default TopbarMail;

// region STYLES

const TopbarMailCollapseMessage = styled(TopbarCollapseMessage)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TopbarMailButton = styled(TopbarButton)`
  @media screen and (max-width: 640px) {
    right: 5px !important;
  }
`;

const LinkContainer = styled.div`
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background: ${colorLightGray};
  }

  span {
    color: ${colorBlue};
  }

  i {
    position: relative;
    top: 2px;
    color: ${colorBlue};
  }
`;

// endregion
