import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Switch from "../../shared/components/form/Switch/Switch";
import { colorSwitchActive } from "../../utils/palette";
import { FormGroupLabel } from "../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";

const IntegrationsForm = ({ rowData, type }) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");

  const telematicsDataSync = watch("telematicsDataSync");
  const status = watch("status");

  const isDisabled = type === "edit" && !status;

  //Edit Effect
  useEffect(() => {
    if (type === "edit") {
      const { provider_type_1 } = rowData;
      setValue("telematicsDataSync", provider_type_1 ? true : false);
    }
  }, [rowData]);

  return (
    <Container>
      <Row className="p-4">
        <Col>
          <Controller
            name="telematicsDataSync"
            control={control}
            defaultValue={false}
            //errors={errors}
            render={({ field: { onChange, value, ref } }) => (
              <div>
                <FormGroupLabel className="mt-2 mb-3">{t("telematics_data_sync")}</FormGroupLabel>
                <div
                  //style={{ height: "34px" }}
                  className="d-flex align-items-center gap-2"
                >
                  <Switch
                    id={"telematics-data-sync-switch"}
                    isOn={value}
                    onColor={colorSwitchActive}
                    handleToggle={onChange}
                    isDisabled={isDisabled}
                  />
                  <span>
                    {telematicsDataSync ? t("active") : t("inactive")}
                  </span>
                  {/* <span>{status ? t("active") : t("inactive")}</span> */}
                </div>
              </div>
            )}
            //rules={{ required: t("required_err") }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default IntegrationsForm;
