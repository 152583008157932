import { Route, Switch } from "react-router";
import TripsTable from "./TripsTable";
import TripsForm from "./TripsForm";
import { postApi } from "../../api/getUser";
import { getDistanceUnit } from "../../shared/helpers";
import { useSelector } from "react-redux";
import { NATEXT } from "../../shared/constants";
import { useTranslation } from "react-i18next";

export default () => (
  <Switch>
    <Route exact path="/trips" component={TripsTable} />
    <Route exact path="/trips/addnew" component={TripsForm} />
  </Switch>
);

export const getSecondaryTableColumns = () => {
  const { t } = useTranslation("common");
  return {
    fillupsColumns: [
      { header: t("odo"), accessor: "odo" },
      { header: t("qty"), accessor: "qty" },
      { header: t("distance"), accessor: "distance" },
      { header: t("tc_tv"), accessor: "total_cost" },
      { header: t("efficiency"), accessor: "eff" },
      { header: "", accessor: "edit" },
    ],
    expensesColumns: [
      { header: t("odo"), accessor: "odo" },
      { header: t("tc_tv"), accessor: "total_cost" },
      { header: t("vendor"), accessor: "vendor" },
      { header: "", accessor: "edit" },
    ],
    incomeColumns: [
      { header: t("desc"), accessor: "income_desc" },
      { header: t("billed_to"), accessor: "billed_to" },
      { header: t("amount"), accessor: "amount" },
      { header: `${t("paid")}/${t("unpaid")}`, accessor: "paid" },
      { header: "", accessor: "edit" },
    ],
  };
};

export const fillupsColumns = [
  { header: "Odo", accessor: "odo" },
  { header: "Qty", accessor: "qty" },
  { header: "Distance", accessor: "distance" },
  { header: "Total Cost", accessor: "total_cost" },
  { header: "Efficiency", accessor: "eff" },
  { header: "", accessor: "edit" },
];

export const expensesColumns = [
  { header: "Odo", accessor: "odo" },
  { header: "Total Cost", accessor: "total_cost" },
  { header: "Vendor", accessor: "vendor" },
  { header: "", accessor: "edit" },
];

export const incomeColumns = [
  { header: "Description", accessor: "income_desc" },
  { header: "Billed to", accessor: "billed_to" },
  { header: "Amount", accessor: "amount" },
  { header: "Paid/Unpaid", accessor: "paid" },
  { header: "", accessor: "edit" },
];

export const updateFillupsData = (rowData, type, setData, units, t) => {
  let displayData = {
    rowId: rowData.fillup_id,
    odo: rowData.odo
      ? `${rowData.odo} ${getDistanceUnit(units.distance)}`
      : NATEXT,
    qty: `${rowData.qty} ${rowData.qty_unit}`,
    distance: rowData.distance
      ? `${rowData.distance} ${getDistanceUnit(units.distance)}`
      : NATEXT,
    total_cost: `${rowData.total_cost} ${units.currency}`,
    eff: rowData.eff ? `${rowData.eff} ${units.consumption}` : NATEXT,
  };

  if (type === "add") {
    setData((prev) => {
      let temp = [...prev];
      temp = temp.filter((row) => row.rowId !== rowData.fillup_id);
      temp.push({ rowId: rowData.fillup_id, rowData, displayData });
      return temp;
    });
  }
  if (type === "edit") {
    setData((prev) => {
      const temp = [...prev];
      return temp.map((row) => {
        if (row.rowId === rowData.fillup_id) {
          let updated = {
            rowId: rowData.fillup_id,
            rowData,
            displayData,
          };
          return updated;
        } else {
          return row;
        }
      });
    });
  }
};

export const updateExpensesData = (rowData, type, setData, units, t) => {
  let displayData = {
    rowId: rowData.expense_id,
    //Add Service Task
    odo: rowData.odo
      ? `${rowData.odo} ${getDistanceUnit(units.distance)}`
      : NATEXT,
    total_cost: rowData.total_cost
      ? `${rowData.total_cost} ${units.currency}`
      : NATEXT,
    vendor: rowData.vendor ? rowData.vendor : NATEXT,
  };

  if (type === "add") {
    setData((prev) => {
      let temp = [...prev];
      temp = temp.filter((row) => row.rowId !== rowData.expense_id);
      temp.push({ rowId: rowData.expense_id, rowData, displayData });
      return temp;
    });
  }
  if (type === "edit") {
    setData((prev) => {
      const temp = [...prev];
      return temp.map((row) => {
        if (row.rowId === rowData.expense_id) {
          let updated = {
            rowId: rowData.expense_id,
            rowData,
            displayData,
          };
          return updated;
        } else {
          return row;
        }
      });
    });
  }
};

export const updateIncomeData = (rowData, type, setData, units, t) => {
  let displayData = {
    rowId: rowData.income_id,
    income_desc: rowData.income_desc ? rowData.income_desc : NATEXT,
    billed_to: rowData.billed_to ? rowData.billed_to : NATEXT,
    amount: rowData.amount ? `${rowData.amount} ${units.currency}` : NATEXT,
    paid: rowData.paid == 1 ? t("paid") : t("unpaid"),
  };

  if (type === "add") {
    setData((prev) => {
      console.log(prev);
      let temp = [...prev];
      temp = temp.filter((row) => row.rowId !== rowData.income_id);
      temp.push({ rowId: rowData.income_id, rowData, displayData });
      return temp;
    });
  }
  if (type === "edit") {
    setData((prev) => {
      const temp = [...prev];
      return temp.map((row) => {
        if (row.rowId === rowData.income_id) {
          let updated = {
            rowId: rowData.income_id,
            rowData,
            displayData,
          };
          return updated;
        } else {
          return row;
        }
      });
    });
  }
};

export const updateTable = async (apiData, updateFunc, setData, units, t) => {
  const res = await postApi(apiData, "commonNew");
  if (res.success) {
    const { user_data } = res;
    if (user_data?.length > 0) {
      user_data.forEach((rowData) => {
        updateFunc(rowData, "add", setData, units, t);
      });
    }
  }
};
