import React, { useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext } from "react";
import { auth } from "../api/firebase";
import {
  getGroups,
  getUsers,
  getVehicles,
  post,
  postApi,
  putApi,
} from "../api/getUser";
import { updateUsersList } from "../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { updateVehiclesList } from "../features/vehicles/vehiclesSlice";
import { updateGroupsList } from "../features/groups/groupsSlice";
import { updateUser } from "../features/user/userSlice";
import { updateUnits } from "../features/units/unitsSlice";
import { Redirect, useHistory, useLocation } from "react-router";
import { fetchExpenseTasks } from "../features/expenseTasks/expenseTasksSlice";
import { fetchServicesTasks } from "../features/servicesTasks/servicesTasksSlice";
import { fetchInspectionTasks } from "../features/inspectionTasks/inspectionTasksSlice";
import { useSelector } from "react-redux";
import { generateTreeArr } from "../shared/helpers";
import { addGroupId, addVehicleId } from "../features/filter/filterSlice";
import { initReactI18next, useTranslation } from "react-i18next";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [reload, setReload] = useState("");
  const [loading, setLoading] = useState(false);
  const [dummyRecordsPopup, setDummyRecordsPopup] = useState({
    signUp: false,
    show: false,
  });

  const [tag, setTag] = useState("free-user");
  const [product, setProduct] = useState(0);
  const [showSubscriptionExpiredPopup, setShowSubscriptionExpiredPopup] =
    useState(false);

  const [showRatingPopup, setShowRatingPopup] = useState(false);

  const [showErrPopup, setShowErrPopup] = useState(false);

  //State to check if client side api(users, vehicles, service, expense, inspection tasks...)
  //initial listing call has been made
  //This flag is needed in case user refreshes from form and goes back to listing
  //Then initial listing api needs to be called but fromCancel will block the api call
  const [isClientApiCalled, setIsClientApiCalled] = useState(false);

  const [vehicle, setVehicle] = useState({
    id: 0,
    label: t("all_vehicles"),
    value: "",
    vehicleId: "",
  });

  const [groupId, setGroupId] = useState("all-groups");

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const groupsList = useSelector((state) => state.groups.list);

  const [vehicles, setVehicles] = useState([]);
  const [groups, setGroups] = useState([]);
  //const [search, setSearch] = useState("");

  // const [filters, setFilters] = useState({
  //   fillups: [],
  //   dailymileage: [],
  //   maintenancereminders: [],
  //   trips: [],
  //   work_order: [],
  // });

  const [fetchTelematicsOdo, setFetchTelematicsOdo] = useState(false);
  const [showFaultCodes, setShowFaultCodes] = useState(false);
  const [integrationsData, setIntegrationsData] = useState([]);

  const value = {
    user,
    setUser,
    isLoggedIn,
    setIsLoggedIn,
    signUp,
    setSignUp,
    reload,
    setReload,
    loading,
    setLoading,
    dummyRecordsPopup,
    setDummyRecordsPopup,
    tag,
    setTag,
    product,
    setProduct,
    showSubscriptionExpiredPopup,
    setShowSubscriptionExpiredPopup,
    showRatingPopup,
    setShowRatingPopup,
    isClientApiCalled,
    setIsClientApiCalled,
    showErrPopup,
    setShowErrPopup,
    vehicle,
    setVehicle,
    groupId,
    setGroupId,
    vehicles,
    setVehicles,
    groups,
    setGroups,
    integrationsData,
    setIntegrationsData,
    showFaultCodes,
    setShowFaultCodes,
    fetchTelematicsOdo,
    setFetchTelematicsOdo,
    //filters,
    //setFilters,
    //search,
    //setSearch,
  };
  useEffect(() => {
    // if (!isLoggedIn) {
    //   history.push("/login");
    // }

    onAuthStateChanged(auth, (user) => {
      console.log("user in onAuthStateChanged", user);
      if (user) {
        setIsLoggedIn(true);
        //setUser(user);
        if (!pathname || pathname === "/" || pathname === "/login") {
          history.push("/dashboard");
        }
      } else {
        //const query = window.location.search ? window.location.search : "";
        //setUser(null);
        history.push("/login");
      }

      if (
        localStorage.getItem("logout") === "true" ||
        !localStorage.getItem("logout")
      ) {
        history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn && !signUp) {
      //Get Org Units
      (async () => {
        const apiData = {
          querystring: "get_org_units",
        };

        const response = await postApi(apiData, "commonNew");
        const unitsData = response.user_data[0];
        dispatch(updateUnits(unitsData));
      })();
      //Get Users
      (async () => {
        const usersList = await getUsers();
        dispatch(updateUsersList(usersList));
      })();

      //Get Subscription status
      (async () => {
        let tag = "free-user";
        const subRes = await postApi(
          {
            querystring: "get_subscription_status",
          },
          "commonNew"
        );
        if (subRes.success) {
          const { subscription_data } = subRes;
          const { subscription_status, product } = subscription_data;
          if (subscription_data?.showpopup) {
            setShowSubscriptionExpiredPopup(true);
          }
          setProduct(product);
          if (subscription_data != "Email not found") {
            if (subscription_status == "paid" && product == 5) {
              tag = "essential-user";
            } else if (subscription_status == "paid") {
              tag = "advanced-user";
            } else {
              tag = "free-user";
            }
          }

          setTag(tag);

          const apiData = {
            querystring: "getsurveypopup",
          };
          const res = await postApi(apiData, "commonNew");
          if (res.success) {
            const resArr = res.user_data;
            let veh_count = subscription_data?.vehiclecount
              ? subscription_data?.vehiclecount
              : 0;
            if (resArr?.length === 0) {
              if (veh_count >= 3) {
                setShowRatingPopup(true);
              }
            } else if (resArr?.length > 0) {
              const { score, last_shown } = resArr[0];
              let nextLastShown = 604800;
              if (last_shown) {
                nextLastShown += last_shown;
              }

              if (
                Number(score) <= 0 &&
                veh_count >= 3 &&
                nextLastShown < Math.round(Date.now() / 1000)
              ) {
                setShowRatingPopup(true);
              }
            }
          }
        }
      })();

      //Get Vehicles
      (async () => {
        const vehiclesList = await getVehicles("getallvehicle", t);
        dispatch(updateVehiclesList(vehiclesList));
      })();

      //Get Groups
      (async () => {
        const groupsList = await getGroups("get_all_groups_list", t);
        dispatch(updateGroupsList(groupsList));
      })();

      //Get User Profile
      (async () => {
        const apiData = {
          querystring: "get_user_profile",
        };

        const response = await postApi(apiData, "commonNew");
        if (response.success) {
          const userData = response.user_data[0];
          dispatch(updateUser(userData));
        }
      })();

      //Fetch all expense tasks
      (async () => {
        const apiData = {
          querystring: "getallexpensetask",
          expense_veh_id: "All",
        };
        dispatch(fetchExpenseTasks(apiData));
      })();

      //Fetch all service tasks
      (async () => {
        // if (serviceTasksStatus === "idle") {
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: "All",
        };
        dispatch(fetchServicesTasks(apiData));
        // }
      })();

      //Fetch all insp tasks
      (async () => {
        const apiData = {
          querystring: "get_all_inspection_tasks",
        };
        dispatch(fetchInspectionTasks(apiData));
      })();

      //Fetch integrations data
      (async () => {
        const apiData = {
          querystring: "get_integrations_data",
        };
        const res = await post("integrations", apiData);
        console.log(res);
        if (res.success) {
          const { data } = res;
          if (data.length > 0) {
            for (let intObj of data) {
              const { provider, provider_type, service_enabled, service_type } =
                intObj;
              if (
                provider_type === 1 &&
                service_enabled === 1 &&
                service_type === 1
              ) {
                setFetchTelematicsOdo(true);
              }

              if (
                provider_type === 1 &&
                provider !== 3 //Since there are no fault codes for streamline
                //&&
                //service_enabled === 1 &&
                //service_type === 2
              ) {
                setShowFaultCodes(true);
              }

              setIntegrationsData(data);
            }
          }
        }
      })();
    }
  }, [isLoggedIn, signUp]);

  useEffect(() => {
    if (isLoggedIn) {
      if (groupsList.length > 0) {
        const groupsTreeArr = generateTreeArr(groupsList);
        setGroups(groupsTreeArr);
        //setGroupId(groupsTreeArr[0]?.key);
      }

      setVehicles(vehiclesList);
    }
  }, [vehiclesList, groupsList]);

  useEffect(() => {
    if (isLoggedIn) {
      const selectedGroup = groupsList.find((g) => g.group_id === groupId);
      if (groupId === undefined) {
        setVehicle("");
      } else if (groupId === "all-groups") {
        setVehicles(vehiclesList);
        setVehicle({
          id: 0,
          label: t("all_vehicles"),
          value: "",
          vehicleId: "",
        });
      } else {
        const temp = vehiclesList.filter((singleVehicle) => {
          if (
            singleVehicle?.id === 0 ||
            groupId === singleVehicle?.group_id ||
            (selectedGroup?.groupNodes &&
              selectedGroup.groupNodes.includes(singleVehicle?.group_id))
          ) {
            return singleVehicle;
          }
        });
        setVehicles(temp);
        setVehicle({
          id: 0,
          label: t("all_vehicles"),
          value: "",
          vehicleId: "",
        });
      }

      const value = groupId && groupId !== "all-groups" ? groupId : "";
      dispatch(addGroupId(value));
    }
  }, [groupId, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      const value = vehicle?.vehicleId ? vehicle.vehicleId : "";
      setTimeout(() => {
        dispatch(addVehicleId(value));
      }, 200);
    }
  }, [vehicle, isLoggedIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
