import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "bootstrap/dist/css/bootstrap.min.css";
//import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
//import { LoadScript } from '@react-google-maps/api';
import TimepickerStyles from "@/shared/components/form/date-pickers/timepickerStyles";
import Loading from "@/shared/components/Loading";
import Router from "./Router";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import { config as i18nextConfig } from "../../translations";
import GlobalStyles from "./globalStyles";
import RechartStyles from "./rechartStyles";
import NotificationStyles from "./notificationStyles";
import { AuthProvider } from "../../contexts/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

i18n.init(i18nextConfig);

const ThemeComponent = ({ children }) => {
  const { mode, direction, shadow, border } = useSelector((state) => ({
    mode: state.mode.className,
    direction: state.direction.direction,
    shadow: state.shadow.className,
    border: state.border.className,
  }));

  // const theme = createMuiTheme({
  //   palette: {
  //     type: mode,
  //   },
  // });

  return (
    // <MuiThemeProvider theme={theme}>
    <ThemeProvider
      theme={{
        mode,
        direction,
        shadow,
        border,
      }}
    >
      <GlobalStyles />
      <NotificationStyles />
      <RechartStyles />
      <TimepickerStyles />

      {children}
    </ThemeProvider>
    // </MuiThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

const App = () => {
  useEffect(() => {
    //window.addEventListener('load', initAuth0);
    const selectedLang = localStorage.getItem("language");

    if (!selectedLang) {
      if (window.navigator.language.includes("es")) {
        localStorage.setItem("language", "es");
      } else {
        localStorage.setItem("language", "en");
      }
    }
  }, []);

  //const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PK)
  );

  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            {/* <GoogleOAuthProvider> */}
            <ConnectedThemeComponent>
              {/* <LoadScript
              loadingElement={<Loading loading />}
              googleMapsApiKey=""
            > */}
              {/* <PrimeReactProvider> */}
              {/* <ScrollToTop> */}
              <Elements
                stripe={stripePromise}
                //options={options}
              >
                <Router />
              </Elements>
              {/* </ScrollToTop> */}
              {/* </PrimeReactProvider> */}
              {/* </LoadScript> */}
            </ConnectedThemeComponent>
            {/* </GoogleOAuthProvider> */}
          </AuthProvider>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
