import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, newUrl, url } from "../../api/getUser";
import { auth } from "../../api/firebase";

const initialState = {
  list: [],
  status: "idle",
  error: null,
};

export const fetchInspectionTasks = createAsyncThunk(
  "inspectionTasks/fetchInspectionTasks",
  async (apiData) => {
    try {
      const token = await getToken(); //sessionStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const email = auth?.currentUser?.email; //sessionStorage.getItem("email");
      const response = await axios.post(newUrl, { ...apiData, email }, config);
      const data = response?.data[0]?.value?.user_data;
      return data;
    } catch (err) {
      return err.message;
    }
  }
);

const inspectionTasksSlice = createSlice({
  name: "inspectionTasks",
  initialState,
  reducers: {
    updateInspectionTasks: (state, action) => {
      const { rowData } = action.payload;
      console.log("rowData", rowData);
      state.list = rowData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInspectionTasks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchInspectionTasks.fulfilled, (state, action) => {
        state.status = "succeeded";
        const inspTasksList = [];
        action.payload.forEach((item) => {
          inspTasksList.push({
            ...item,
            value: item.task_id,
            label: item.task_name,
          });
        });
        //console.log('action.payload', action.payload);
        state.list = inspTasksList; //action.payload;
      })
      .addCase(fetchInspectionTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { updateInspectionTasks } = inspectionTasksSlice.actions;

export default inspectionTasksSlice.reducer;
