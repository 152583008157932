import React from "react";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "./TableElements";
import { InfoIcon } from "./InfoPopup";
import { colorLightBlueStatus } from "../../utils/palette";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ActionPopup = ({
  showPopup,
  setShowPopup,
  message,
  handleYes,
  handleNo,
  yesBtnLoading,
  noBtnLoading,
  modalSize,
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal
      show={showPopup}
      onHide={() => setShowPopup(!showPopup)}
      centered
      size={modalSize ? modalSize : "sm"}
    >
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer
            onClick={() => setShowPopup(false)}
            id="close-action-popup-icon-container"
          >
            <i className="fi fi-rr-cross-small" id="close-action-popup-icon" />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-3 justify-content-start align-items-center p-2">
          <InfoIcon
            className={"fi fi-rr-interrogation"}
            color={colorLightBlueStatus}
            id="info-icon"
          />
          <p style={{ fontSize: "16px" }} id="info-msg">
            {message}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2 gap-3">
          <FilterButton onClick={handleNo} type="button" id="no-action-btn">
            {noBtnLoading ? <CustomLoader /> : t("no")}
          </FilterButton>
          <AddFormButton onClick={handleYes} type="button" id="yes-action-btn">
            {yesBtnLoading ? <CustomLoader /> : t("yes")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActionPopup;
