import { t } from "i18next";
import { useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const getInitialDateData = () => {
  const { minDate, maxDate } = useSelector((state) => state.columns);
  const initialDateData = useMemo(
    () => ({
      dateOption: "all_time",
      from: new Date(minDate * 1000),
      to: new Date(),
      isFiltered: false,
    }),
    [minDate]
  );

  return initialDateData;
};

export const initialDateData = {
  dateOption: "all_time",
  from: new Date(sessionStorage.getItem("minDate") * 1000),
  to: new Date(),
  isFiltered: false,
};

export const initialRangeData = {
  radioValue: "greater-than",
  radioName: "greater than",
  input_1: "",
  input_2: "",
  isFiltered: false,
};

export const initialContainsData = {
  radioValue: "is",
  radioName: "is",
  input_1: "",
  isFiltered: false,
};

export const initialTransactionTypeData = {
  cash: { header: "Cash", value: false },
  debitCard: { header: "Debit Card", value: false },
  creditCard: { header: "Credit Card", value: false },
  check: { header: "Check", value: false },
  isFiltered: false,
  type: "checkbox",
};

export const initialfuelTypeData = {
  petrol: { header: "Petrol", value: false },
  diesel: { header: "Diesel", value: false },
  isFiltered: false,
  type: "checkbox",
};

export const initialStatusData = {
  scheduled: { header: "SCHEDULED", value: false },
  dueSoon: { header: "DUE SOON", value: false },
  overdue: { header: "OVERDUE", value: false },
};

export const statusDataOptions = [
  { type: "scheduled", label: "SCHEDULED", value: false },
  { type: "dueSoon", label: "DUE SOON", value: false },
  { type: "overdue", label: "OVERDUE", value: false },
];

export const initialWOTypeData = {
  scheduled: { header: "Scheduled", value: false },
  unscheduled: { header: "Unscheduled", value: false },
  isFiltered: false,
  type: "checkbox",
};
