import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msg: "",
  type: 1, // 2 -> Failure, 1 -> Success
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addMsg: (state, action) => {
      //addMsg is used only for success cases. So type = 1;
      state.msg = action.payload;
      state.type = 1;
    },
    addSnackbarData: (state, action) => {
      //addSnackbarData added later on in order to have the option to make type 0 and show failure snackbar
      const { msg, type } = action.payload;
      state.msg = msg;
      state.type = type;
    },
  },
});

export const { addMsg, addSnackbarData } = snackbarSlice.actions;
export default snackbarSlice.reducer;
