import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../../features/filter/filterSlice";
import VehicleComponent from "../../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import { initialContainsData } from "../../../shared/constants/filterData";
import DataReactTable from "../../DataTable/components/DataReactTable";
import { colorAdditional, colorLightGray } from "../../../utils/palette";
import { useRef } from "react";
import styled from "styled-components";
import Tooltip from "@/shared/components/Tooltip";
import { postApi, putApi } from "../../../api/getUser";
import { useHistory, useLocation } from "react-router";
import { NATEXT, initialLoadDelay } from "../../../shared/constants";
import { useState } from "react";
import { getUnixTime } from "date-fns/esm";
import subDays from "date-fns/subDays";
import TaskPillComponent from "../../../shared/components/table/components/TaskPillComponent";
import { useAuth } from "../../../contexts/AuthContext";
import { updateUserSettingsData } from "../../../features/columns/columnsSlice";
import ExplorePopup from "../../../shared/components/ExplorePopup";
import { LinkSpan } from "../../../shared/components/form/FormElements";
import useFetchData from "../../../shared/custom-hooks/useFetchData";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import getDefaultFilterData from "../../../shared/constants/getDefaultFilterData";

const InspectionSubmissionsTable = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.filter);

  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;

  const { orgDate } = useSelector((state) => state.user);

  const { isLoggedIn, setLoading, reload, setReload } = useAuth();

  const location = useLocation();
  //const fromCancel = location.state?.fromCancel;

  const path = location.pathname;
  let screen = path.slice(1, path.length);
  const columns = useSelector(
    (state) => state.advancedFilter.inspectionSubmissions
  );

  //Logic for first time load(Reload start_date and end_date)
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  useFetchData({
    startDate: getUnixTime(subDays(startOfToday(), 6)),
    endDate: getUnixTime(endOfToday()),
    query: "get_inspection_submission_data",
    sortIndex: 3,
  });

  //On initial load, send update initial data
  // useEffect(() => {
  //   if (
  //     isLoggedIn &&
  //     firstTimeLoad &&
  //     path.includes("inspectionsubmissions") &&
  //     orgDate
  //     //&&
  //     //(!fromCancel || status === "initial")
  //   ) {
  //     (async () => {
  //       setLoading(true);
  //       console.log("Initial Effect called - Submissions ");

  //       let colOrder = "[0,1,2,3,4]";
  //       let defaultRows = 10;
  //       let minDate = orgDate;
  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "inspectionsubmissions",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       console.log("userSettingsRes", userSettingsRes);
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         const { col_order, min_date, default_rows } = data;
  //         if (min_date) {
  //           console.log("min_date", min_date);
  //           minDate = min_date;
  //         }
  //         defaultRows = default_rows;
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       // let index = 1;
  //       // if (col_order) {
  //       //   const colOrder = JSON.parse(col_order).map((item) => +item + 1);
  //       //   index = colOrder.findIndex((col) => col === 2);
  //       // } else {
  //       //   index = 2;
  //       // }

  //       const startDate = getUnixTime(subDays(new Date(), 6));
  //       const endDate = getUnixTime(new Date());
  //       dispatch(
  //         updateInitialData({
  //           query: "get_inspection_submission_data",
  //           startDate,
  //           endDate,
  //           order: 2,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: 2,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: startDate,
  //         end_date: endDate,
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "get_inspection_submission_data",
  //       };
  //       dispatch(fetchData({ ...initialData, ...columns }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // //Fetch data on every filter change expect for start and set start to 0
  // useEffect(() => {
  //   if (
  //     isLoggedIn &&
  //     screen?.includes("inspectionsubmissions") &&
  //     querystring === "get_inspection_submission_data" &&
  //     !firstTimeLoad
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data, ...columns }));
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   //querystring,
  //   columns,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (
  //     isLoggedIn &&
  //     screen?.includes("inspectionsubmissions") &&
  //     querystring === "get_inspection_submission_data" &&
  //     !firstTimeLoad
  //   ) {
  //     dispatch(fetchData({ ...data, ...columns }));
  //   }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   if (
  //     isLoggedIn &&
  //     reload === "inspectionsubmissions" &&
  //     screen?.includes("inspectionsubmissions") &&
  //     !firstTimeLoad
  //   ) {
  //     setReload("");
  //     dispatch(fetchData({ ...data, ...columns }));
  //   }
  // }, [reload]);

  // useEffect(() => {
  //   const startDate = getUnixTime(subDays(new Date(), 6));
  //   const endDate = getUnixTime(new Date());
  //   const data = {
  //     draw: 1,
  //     length: 10,
  //     search: {
  //       value: "",
  //       regex: false,
  //     },
  //     order: [
  //       {
  //         column: 1,
  //         dir: "desc",
  //       },
  //     ],
  //     start_date: startDate,
  //     end_date: endDate,
  //     start: 0,
  //     filter_group_id: "",
  //     filter_vehicle_id: "",
  //     email: "testgroup@12.in",
  //     querystring: querystring,
  //   };
  //   dispatch(fetchData(data));
  // }, [querystring]);

  // useEffect(() => {
  //   //console.log(firstTimeLoad);
  //   if (firstTimeLoad) {
  //     console.log('firstTimeLoad');
  //     //const startDate = getUnixTime(subDays(new Date(), 6));
  //     //console.log(startDate);
  //     //const endDate = getUnixTime(new Date());
  //     start_date =  getUnixTime(subDays(new Date(), 6));
  //     end_date = getUnixTime(new Date());
  //     dispatch(
  //       fetchData({
  //         ...otherData,
  //         ...columns,
  //         querystring: "get_inspection_submission_data",
  //         start_date,
  //         end_date,
  //       })
  //     );
  //     //console.log(firstTimeLoad)
  //     setFirstTimeLoad(false);
  //   }
  // }, []);

  const issuesListRef = useRef();

  const newData = {
    //start_date,
    //end_date,
    //screen,
    ...data,
    ...columns,
    querystring: "get_inspection_submission_data",
  };
  const rowData = useSelector((state) => state.filter.rowData);
  const [nonInspectedVehicles, setNonInspectedVehicles] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchData(newData));
  // }, [data, columns, deletedData]);

  const otherValues = useSelector((state) => state.filter.values);
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const allVehicles = vehiclesList?.slice(1, vehiclesList.length);
  let notInspectedVehiclesLength = "";
  useEffect(() => {
    if (
      screen.includes("inspectionsubmissions") &&
      isLoggedIn &&
      querystring === "get_inspection_submission_data"
    ) {
      (async () => {
        let notInspected = [];
        if (otherValues?.notInspected) {
          notInspected = JSON.parse(otherValues.notInspected);
          notInspectedVehiclesLength = notInspected.length;
        }

        //let vehiclesTableData = [];
        let vehiclesTableData = [];
        let vehiclesAssignedArr = [];
        const res = await postApi(
          { querystring: "get_all_vehicle_operators" },
          "commonNew"
        );
        if (res.success) {
          vehiclesAssignedArr = res?.user_data;
        }

        if (notInspected?.length > 0) {
          if (allVehicles.length > 0) {
            allVehicles.forEach((v) => {
              if (notInspected.includes(v.vehicleId)) {
                let temp = [];
                vehiclesAssignedArr.forEach((veh) => {
                  if (v.vehicleId === veh.veh_id) {
                    temp.push({ userId: veh.user_id, userName: veh.name });
                  }
                });
                vehiclesTableData.push({
                  vehicleId: v.vehicleId,
                  vehicleName: v.label,
                  users: temp,
                });
              }
            });
          }
        } else {
          vehiclesTableData = [];
        }

        setNonInspectedVehicles(vehiclesTableData);
      })();
    }
  }, [otherValues]); //Removed start_date and end_date from dependency array

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("inspection_form_name"),
        accessor: "form_name",
        //disableGlobalFilter: true,
        //disableSortBy: true,
      },
      {
        id: 3,
        Header: t("date_tv"),
        accessor: "inspection_date",
        Cell: ({ value }) =>
          new Date(value * 1000).toLocaleDateString(navigator.language, {
            day: "numeric",
            year: "numeric",
            month: "short",
          }),
        //disableSortBy: true,
      },
      {
        id: 4,
        Header: t("inspection_by"),
        accessor: "user_name",
        //disableSortBy: true,
      },
      {
        id: 5,
        Header: t("issues"),
        accessor: "task_name",
        accessorId: "task_name",
        //accessor: ({ row }) => <div>{row.task_name}</div>,
        Cell: ({ row }) => (
          <TaskPillComponent
            stringValue={row?.original?.task_name}
            //issuesListRef={issuesListRef}
          />
        ), //<div style={{ color: 'red' }}>{row.original.task_name}</div>,
        disableSortBy: true,
      },
      // {
      //   id: 6,
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    []
  );

  // const rows = [];

  // for (let i = 0; i < rowData?.length; i++) {
  //   rows.push({
  //     inspection_id: rowData[i].inspection_id,
  //     form_id: rowData[i].form_id,
  //     row_id: rowData[i].inspection_id,
  //     name: rowData[i].name ? rowData[i].name.slice(0, 20) : NATEXT,
  //     form_name: rowData[i].form_name ? rowData[i].form_name : NATEXT,
  //     inspection_date: rowData[i].inspection_date,
  //     start_time: rowData[i].start_time,
  //     end_time: rowData[i].end_time,
  //     inspection_by: rowData[i].inspection_by
  //       ? rowData[i].inspection_by
  //       : NATEXT,
  //     veh_id: rowData[i].veh_id ? `${rowData[i].veh_id}` : NATEXT,
  //     veh_img: rowData[i].veh_img ? `${rowData[i].veh_img}` : NATEXT,
  //     signature: rowData[i].signature ? rowData[i].signature : NATEXT,
  //     task_name: rowData[i].task_name,
  //     user_name: rowData[i].user_name ? rowData[i].user_name : NATEXT,
  //     last_updated_at: rowData[i].last_updated_at
  //       ? rowData[i].last_updated_at
  //       : NATEXT,
  //     comments: rowData[i].comments ? rowData[i].comments.slice(0, 70) : NATEXT,
  //     org_id: rowData[i].org_id,
  //   });
  // }

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  const { defaultContainsData } = getDefaultFilterData();

  const initialData = {
    formName: {
      column: "formName",
      name: t("inspection_form_name"),
      ...defaultContainsData,
    },
    // date: {
    //   column: "date",
    //   name: "Date",
    //   ...initialDateData,
    // },

    inspectionBy: {
      column: "inspectionBy",
      name: t("inspection_by"),
      ...defaultContainsData,
    },
    issues: {
      column: "issues",
      name: t("issues"),
      ...defaultContainsData,
    },
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/ITDQ82YQ"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-start">
          {/* <Col sm={9}>
            <ColumnHeading>Inspection Submissions</ColumnHeading>
          </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("inspection_submissions")}</ColumnHeading>
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col
            sm={3}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            <AddFormButton
              onClick={() =>
                history.push("/inspection/inspectionsubmissions/addnew", {
                  type: "add",
                })
              }
            >
              {t("add_inspection_submission")}
            </AddFormButton>
          </Col>
          {/* <Col sm={3} className="d-flex justify-content-end align-items-center">
          <Link to={'/inspection/inspectionscheduling/addnew'}>
            <AddFormButton>Add Inspection Submission</AddFormButton>
          </Link>
        </Col> */}
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            initialData={initialData}
            notInspectedVehiclesLength={nonInspectedVehicles?.length}
            vehiclesTableData={nonInspectedVehicles}
          />
        </Row>
      </Container>
    </>
  );
};

export default InspectionSubmissionsTable;

export const TaskNameComponent = ({ stringValue, issuesListRef }) => {
  // useEffect(() => {
  //   const hasClamping = (el) => {
  //     const { clientHeight, scrollHeight } = el;
  //     //console.log(clientHeight, scrollHeight, collapse);
  //     return clientHeight !== scrollHeight;
  //   };
  //   console.log(hasClamping(issuesRef.current));
  // }, []);

  const issuesRef = useRef();
  //const singleIssueRef = useRef();

  //console.log(issuesRef.current?.clientWidth, issuesRef.current?.scrollWidth);

  //const { stringValue } = data;
  let issueNamesArr = [];
  let issuesToShow = [];
  let issuesOnHover = [];
  let hoverText = "";

  if (stringValue) {
    issueNamesArr = stringValue.split(",");
    issueNamesArr?.forEach((s) => s.trim());
    if (issueNamesArr.length > 2) {
      issuesToShow = issueNamesArr?.slice(0, 2);
      issuesOnHover = issueNamesArr?.slice(2, issueNamesArr.length);
    } else {
      issuesToShow = [...issueNamesArr];
    }

    if (issuesOnHover.length > 0) {
      issuesOnHover.forEach((issue) => {
        hoverText += `${issue}, `;
      });
      hoverText = hoverText.slice(0, hoverText.length - 2);
    }
  }

  // useEffect(() => {
  //   adjustIssuesWidth();
  // }, []);

  // const adjustIssuesWidth = (el) => {
  //   if (task_name && issueNamesArr) {
  //     issueNamesArr = task_name.split(",");
  //     issueNamesArr?.forEach((s) => s.trim());
  //     if (el.current?.clientWidth !== el.current?.scrollWidth) {
  //       let length = 0;
  //       let maxlength = el.current?.clientWidth - 50;
  //       issueNamesArr.forEach((task) => {
  //         length += 8 * task.length;
  //         if (length < maxlength) {
  //           issuesToShow.push(task);
  //         }
  //       });
  //       issuesToShow.push(`+${issueNamesArr.length - issuesToShow.length}`);
  //     } else if(el.current?.clientWidth === el.current?.scrollWidth) {
  //       issuesToShow = [...issueNamesArr];
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", adjustIssuesWidth(issuesRef));
  // }, [issuesRef]);

  // adjustIssuesWidth(issuesRef);

  // useEffect(() => {
  //   adjustIssuesWidth(issuesRef);
  // }, [issuesRef]);

  //console.log(issueNamesArr, task_name);

  return (
    <>
      {stringValue ? (
        <div className="d-flex gap-2">
          {issuesToShow.map((name, id) => {
            return (
              <IssueContainer
                //ref={singleIssueRef}
                key={id}
                // style={{
                //   border: `1px solid ${colorAdditional}`,
                //   borderRadius: "10px",
                //   padding: "4px",
                //   fontSize: "14px",
                //   whiteSpace: "nowrap",
                //   //width: "auto",
                // }}
              >
                {name}
              </IssueContainer>
            );
          })}
          {issuesOnHover.length > 0 && (
            <Tooltip text={hoverText}>
              <IssueContainer additional>
                {`+${issuesOnHover.length}`}
              </IssueContainer>
            </Tooltip>
          )}
        </div>
      ) : (
        <div>-</div>
      )}
    </>
  );
};

export const IssueContainer = styled.div`
  border: 1px solid ${colorAdditional};
  border-radius: 10px;
  padding: 3px 6px;
  font-size: 14px;
  white-space: nowrap;

  ${(p) =>
    p.additional &&
    `
  
  &:hover {
    background: ${colorLightGray};
  }
  `}
`;
