import React, { useState } from "react";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  LinkAnchor,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import {
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import Switch from "../../shared/components/form/Switch/Switch";
import { colorSwitchActive, scrollbarStyles } from "../../utils/palette";
import styled from "styled-components";
import { countries, currencies } from "country-data";
import { useEffect } from "react";
import {
  addOrganizationData,
  getUnits,
  postApi,
  urlConfig,
} from "../../api/getUser";
import {
  consumptionCurrencyMapping,
  distanceCurrencyMapping,
  hourlyConsumptionCurrencyMapping,
  volumeCurrencyMapping,
} from "../../shared/constants/loginMappingDetails";
import axios from "axios";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../api/firebase";
import {
  emailPattern,
  getDistanceFactorForEff,
  getTranslatedPMUnit,
  getTranslatedVolumeUnit,
  getVolumeFactorForEff,
  handleNegative,
} from "../../shared/helpers";
import { PhotoUploadComponent } from "../UserManagement/UserDetailsForm";
import { uploadImgToS3 } from "../Issues/IssuesForm";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { countryCurrencyMapping } from "../../shared/constants";
import ErrorPopup from "../../shared/components/ErrorPopup";

const SignUpDetailsForm = ({
  signUpMode,
  setSignUpMode,
  signUpWithEmail,
  setSignUpWithEmail,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");

  const history = useHistory();

  const { setSignUp } = useAuth();

  const countriesList = countries.all.filter((c) => c.currencies.length > 0);
  const currenciesList = currencies.all;

  //console.log(countriesList);

  // const countriesOptions = countriesList.map((c) => ({
  //   ...c,
  //   label: c.name,
  //   value: c.alpha3,
  //   currency: c.currencies[0],
  // }));

  // const currenciesOptions = currenciesList.map((c) => ({
  //   ...c,
  //   label: c.name,
  //   value: c.code,
  // }));

  const countriesOptions = countryCurrencyMapping.map((c) => ({
    label: c[0],
    value: c[0],
    currency: c[1],
    countryCode: countriesList.find((country) => country.name === c[0])?.alpha2,
  }));

  const currenciesOptions = countryCurrencyMapping.map((c) => ({
    value: c[1],
    code: c[1],
    label: currenciesList.find((cur) => cur.code === c[1])?.name,
  }));

  //console.log("countries", countriesOptions);
  //console.log("currencies", currenciesOptions);

  const [distanceOptions, setDistanceOptions] = useState([]);
  const [volumeOptions, setVolumeOptions] = useState([]);
  const [consumptionOptions, setConsumptionOptions] = useState([]);
  const [hourlyConsumptionOptions, setHourlyConsumptionOptions] = useState([]);

  //Hook Form Values
  const country = watch("country");
  const currency = watch("currency");
  const distance = watch("distance");
  const volume = watch("volume");
  const consumption = watch("consumption");
  const hourlyConsumption = watch("hourlyConsumption");
  const email = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  const orgName = watch("orgName");
  const address = watch("address");
  const phone = watch("phone");
  const fleetSize = watch("fleetSize");

  //State for Switch Component
  const [vehicleConsumptionInHours, setVehicleConsumptionInHours] =
    useState(false);

  const [orgPhoto, setOrgPhoto] = useState(null);

  const [registerBtnLoading, setRegisterBtnLoading] = useState(false);

  const allUsers = useSelector((state) => state.users.list);

  const fleetSizeOptions = [
    {
      label: "1-10",
      value: "1-10",
    },
    {
      label: "10-50",
      value: "10-50",
    },
    {
      label: "50-100",
      value: "50-100",
    },
    {
      label: "100+",
      value: "100+",
    },
  ];

  const [errMsg, setErrMsg] = useState("");
  const [showErrPopup, setShowErrPopup] = useState(false);

  useEffect(() => {
    (async () => {
      setSignUp(true);
      //Automatically set Country
      const locale = window.navigator.userLanguage || window.navigator.language;
      const countryCode = locale.slice(-2)?.toUpperCase();
      setValue(
        "country",
        countriesOptions.find((c) => c.countryCode === countryCode)
      );

      setValue("fleetSize", { label: "1-10", value: "1-10" });
      const unitsRes = await getUnits();
      if (unitsRes.success) {
        const { user_data } = unitsRes;

        let distanceUnits = user_data
          .filter((u) => u.unit_type === "distance")
          .map((u) => ({
            ...u,
            label: getTranslatedPMUnit(u.unit_name, t),
            value: u.unit_name,
          }));

        let volumeUnits = user_data
          .filter((u) => u.unit_type === "volume")
          .map((u) => ({
            ...u,
            label: getTranslatedVolumeUnit(u.unit_name, t),
            value: u.unit_name,
          }));

        let consumptionUnits = user_data
          .filter((u) => u.unit_type === "consumption")
          .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        let hourlyConsumptionUnits = user_data
          .filter((u) => u.unit_type === "hour_consumption")
          .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        setDistanceOptions(distanceUnits);
        setVolumeOptions(volumeUnits);
        setConsumptionOptions(consumptionUnits);
        setHourlyConsumptionOptions(hourlyConsumptionUnits);

        // distanceOptions.current = user_data
        //   .filter((u) => u.unit_type === "distance")
        //   .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        // volumeOptions.current = user_data
        //   .filter((u) => u.unit_type === "volume")
        //   .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        // consumptionOptions.current = user_data
        //   .filter((u) => u.unit_type === "consumption")
        //   .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        // hourlyConsumptionOptions.current = user_data
        //   .filter((u) => u.unit_type === "hour_consumption")
        //   .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));
      }
    })();
  }, []);

  //Update email when signup is google or apple
  useEffect(() => {
    if (signUpMode === "google" || signUpMode === "apple") {
      setValue("email", auth.currentUser.email);
    }
  }, [signUpMode]);

  //Set currency and unit dropdowns on vehicle change
  useEffect(() => {
    if (country) {
      setValue(
        "currency",
        currenciesOptions.find((c) => c.code === country.currency)
      );

      //Set Distance
      if (Object.keys(distanceCurrencyMapping).includes(country.currency)) {
        setValue(
          "distance",
          distanceOptions.find(
            (d) => d.value === distanceCurrencyMapping[country.currency]
          )
        );
      } else {
        setValue(
          "distance",
          distanceOptions.find((d) => d.value === "Kilometers")
        );
      }

      //Set Volume
      if (Object.keys(volumeCurrencyMapping).includes(country.currency)) {
        setValue(
          "volume",
          volumeOptions.find(
            (d) => d.value === volumeCurrencyMapping[country.currency]
          )
        );
      } else {
        setValue(
          "volume",
          volumeOptions.find((d) => d.value === "Liters")
        );
      }

      //Set Consumption
      if (Object.keys(consumptionCurrencyMapping).includes(country.currency)) {
        setValue(
          "consumption",
          consumptionOptions.find(
            (d) => d.value === consumptionCurrencyMapping[country.currency]
          )
        );
      } else {
        setValue(
          "consumption",
          consumptionOptions.find((d) => d.value === "km/L")
        );
      }

      //Set Hourly Consumption
      if (
        Object.keys(hourlyConsumptionCurrencyMapping).includes(country.currency)
      ) {
        setValue(
          "hourlyConsumption",
          hourlyConsumptionOptions.find(
            (d) =>
              d.value === hourlyConsumptionCurrencyMapping[country.currency]
          )
        );
      } else {
        setValue(
          "hourlyConsumption",
          hourlyConsumptionOptions.find((d) => d.value === "L/Hr")
        );
      }
    }
  }, [
    country,
    distanceOptions,
    volumeOptions,
    consumptionOptions,
    hourlyConsumptionOptions,
  ]);

  const addNewOrgData = async () => {
    const apiData = {
      name: "new user",
      org_name: orgName,
      app_expires: 1577836800,
      user_id: "",
      org_img: orgPhoto ? orgPhoto.imgName : "",
      effdistfact: getDistanceFactorForEff(distance.value, consumption.value),
      effvolfact: getVolumeFactorForEff(volume.value, consumption.value),
      org_country: country.value,
      distance: distance.value,
      hourly_machines_present: vehicleConsumptionInHours ? 1 : 0,
      hourly_consumption: hourlyConsumption.value,
      volume: volume.value,
      consumption: consumption.value,
      currency: currency.value,
      org_addressone: address,
      org_addresstwo: "",
      comments: "",
      last_updated: "0",
      action: 1,
      pro_status: "0",
      role_id: "1",
      user_active: "1",
      phone: phone ? phone : "",
      fleet_size: fleetSize.value,
    };

    const res = await addOrganizationData(apiData);
    const responseData = res?.data;
    const orgId = responseData[0]?.value?.org_id;
    const isSuccess = responseData[1]?.value?.success;
    const userId = responseData[1]?.value?.user_data?.user_id;

    if (isSuccess) {
      if (orgPhoto) {
        const orgImg = {
          file: orgPhoto.file,
          key: `image_data/org_${orgId}/logo/${orgPhoto.imgName}`,
        };
        const uploadImgToS3Res = await uploadImgToS3(orgImg);
        if (uploadImgToS3Res === 200) {
          setSignUp(false);
          localStorage.setItem("logout", false);
          setRegisterBtnLoading(false);
          history.push("/users/addnew", {
            type: "edit",
            route: "/users/addnew",
            screen: "users",
            mode: "from-signup",
            data: { userId },
          });
        }
      } else {
        setSignUp(false);
        localStorage.setItem("logout", false);
        setRegisterBtnLoading(false);
        history.push("/users/addnew", {
          type: "edit",
          route: "/users/addnew",
          screen: "users",
          mode: "from-signup",
          data: { userId },
        });
      }
    } else {
      console.log("Something went wrong");
    }
  };

  const onSubmit = async (data, e) => {
    try {
      e.preventDefault();

      setRegisterBtnLoading(true);

      const apiData = {
        querystring: "checkuseremail",
        email: email.toLocaleLowerCase(),
      };
      const res = await axios.post(urlConfig.getInfo, apiData);
      const chekUserEmailRes = res.data[0].value;
      if (chekUserEmailRes.success) {
        const { user_data } = chekUserEmailRes;
        if (user_data === "email_found") {
          // setError(
          //   "email",
          //   {
          //     type: "duplicateEmail",
          //     message:
          //       "This email already exists in our database. Please Login instead.",
          //   },
          //   { shouldFocus: true }
          // );
          setErrMsg(t("email_exists_on_server"));
          setShowErrPopup(true);
          setRegisterBtnLoading(false);
          return;
        }
      }

      if (signUpMode === "email") {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email.toLocaleLowerCase(),
          password
        );
      }

      const addOrgRes = await addNewOrgData();
      document.cookie = `uiPreference=new; path=/; domain=.simplyfleet.app`;
      // if (addOrgRes) {
      //   setSignUp(false);
      //   setRegisterBtnLoading(false);
      //   localStorage.setItem("logout", false);
      //   history.push("users/addnew");
      // }
    } catch (error) {
      let errorMessage = "";
      console.log("firebase Error", error);
      if (error?.code == "auth/email-already-in-use") {
        errorMessage = t("existing_login_err_msg");
      } else {
        errorMessage = t("email_exists_on_firebase") + error?.code;
      }
      // setError(
      //   "email",
      //   {
      //     type: error.code,
      //     message: errorMessage,
      //   },
      //   { shouldFocus: true }
      // );
      setErrMsg(errorMessage);
      setShowErrPopup(true);
      setRegisterBtnLoading(false);
      return;
    }
    // })();
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={errMsg}
        />
      )}
      <FormContainerWithScroll
        onSubmit={handleSubmit(onSubmit)}
        id="sign-up-form"
      >
        <Container id="sign-up-form-container">
          <Row>
            <Col className="d-flex justify-content-between align-items-center p-3">
              <h4 id="sign-up-heading">
                {signUpMode === "email" ? t("email_sign_up") : t("org_details")}
              </h4>
              {signUpMode === "email" && (
                <FilterButton
                  id="go-back-btn"
                  onClick={() => setSignUpWithEmail(false)}
                  type="button"
                >
                  {t("go_back_changed")}
                </FilterButton>
              )}
            </Col>
          </Row>
          {signUpMode === "email" && (
            <>
              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <FormGroupLabel id="email-label">
                    {t("email_address")}
                  </FormGroupLabel>
                  <FormItem disabled={false} id="email-div">
                    <FormField
                      name="email"
                      id="email"
                      control={control}
                      component="input"
                      type="text"
                      defaultValue=""
                      placeholder={t("email_address")}
                      style={formInputStyles}
                      disabled={false}
                      rules={{
                        required: t("required_err"),
                        pattern: {
                          value: emailPattern,
                          message: t("invalid_email_err_msg"),
                        },
                      }}
                      //onKeyUp={handleSIOdoChange}
                    />
                    {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                  </FormItem>

                  {errors?.email && <Error error={errors.email.message} />}
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <FormGroupLabel id="password-label">
                    {t("password")}
                  </FormGroupLabel>
                  <FormItem disabled={false} id="password-div">
                    <FormField
                      name="password"
                      id="password"
                      control={control}
                      component="input"
                      type="password"
                      defaultValue=""
                      placeholder={t("password")}
                      style={formInputStyles}
                      disabled={false}
                      rules={{
                        required: t("required_err"),
                        minLength: {
                          value: 6,
                          message: t("invalid_pwd"),
                        },
                      }}
                      //onKeyUp={handleSIOdoChange}
                    />
                    {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                  </FormItem>

                  {errors?.password && (
                    <Error error={errors.password.message} />
                  )}
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <FormGroupLabel id="confirm-password">
                    {t("confirm_password")}
                  </FormGroupLabel>
                  <FormItem disabled={false} id="confirm-password-div">
                    <FormField
                      name="confirmPassword"
                      id="confirm-password"
                      control={control}
                      component="input"
                      type="password"
                      defaultValue=""
                      placeholder={t("confirm_password")}
                      style={formInputStyles}
                      disabled={false}
                      rules={{
                        required: t("required_err"),
                        minLength: {
                          value: 6,
                          message: t("invalid_pwd"),
                        },
                        validate: {
                          matchPassword: (v) =>
                            v === password || t("pw_mismatch_err_msg"),
                        },
                      }}
                      //onKeyUp={handleSIOdoChange}
                    />
                    {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                  </FormItem>

                  {errors?.confirmPassword && (
                    <Error error={errors.confirmPassword.message} />
                  )}
                </Col>
              </Row>
              <hr className="mt-4 mb-0" />
            </>
          )}
          <Row className="d-flex justify-content-center pt-4">
            <Col className="d-flex flex-column" md={8}>
              {/* <FormGroupLabel>Organization Photo</FormGroupLabel> */}
              {/* <FilterButton style={{ width: "min-content" }}>Select</FilterButton> */}
              <div
              //className="align-self-start"
              >
                <PhotoUploadComponent photo={orgPhoto} setPhoto={setOrgPhoto} />
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="org-name-label">
                {t("org_name")}
              </FormGroupLabel>
              <FormItem disabled={false} id="org-name-div">
                <FormField
                  name="orgName"
                  id="org-name"
                  control={control}
                  component="input"
                  type="text"
                  defaultValue=""
                  placeholder={t("org_name")}
                  style={formInputStyles}
                  disabled={false}
                  rules={{ required: t("required_err") }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.orgName && <Error error={errors.orgName.message} />}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="address-label">{t("address")}</FormGroupLabel>
              <FormItem disabled={false} id="address-div">
                <FormField
                  name="address"
                  id="address"
                  control={control}
                  component="input"
                  type="text"
                  defaultValue=""
                  placeholder={t("address")}
                  style={formInputStyles}
                  disabled={false}
                  rules={{ required: t("required_err") }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.address && <Error error={errors.address.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="country-label">{t("country")}</FormGroupLabel>
              <Controller
                name="country"
                //id="country"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    options={countriesOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("country")}
                    styles={formStyles}
                    isDisabled={false}
                    id="country-select"
                  />
                )}
                rules={{ required: t("required_err") }}
              />

              {errors?.country && <Error error={errors.country.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="phone-label">{t("phone")}</FormGroupLabel>
              <FormItem disabled={false} id="phone-div">
                <FormField
                  name="phone"
                  id="phone"
                  control={control}
                  component="input"
                  type="text"
                  onKeyDown={handleNegative}
                  defaultValue=""
                  placeholder={t("phone")}
                  style={formInputStyles}
                  disabled={false}
                  //rules={{ required: t("required_err") }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.phone && <Error error={errors.phone.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="currency-label">
                {t("currency")}
              </FormGroupLabel>
              <Controller
                name="currency"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    id="currency-select"
                    options={currenciesOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("currency")}
                    styles={formStyles}
                    isDisabled={false}
                  />
                )}
                rules={{ required: t("required_err") }}
              />

              {errors?.currency && <Error error={errors.currency.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="fleet-size-label">
                {t("fleet_size")}
              </FormGroupLabel>
              <Controller
                name="fleetSize"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    id="fleet-size-select"
                    options={fleetSizeOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("fleet_size")}
                    styles={formStyles}
                    isDisabled={false}
                    isSearchable={false}
                  />
                )}
                //rules={{ required: t("required_err") }}
              />

              {errors?.fleetSize && <Error error={errors.fleetSize.message} />}
            </Col>
          </Row>

          <hr className="mt-4 mb-0" />

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="distance-label">
                {t("distance")}
              </FormGroupLabel>
              <Controller
                name="distance"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    id="distance-select"
                    options={distanceOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("distance")}
                    styles={formStyles}
                    isDisabled={false}
                  />
                )}
                rules={{ required: t("required_err") }}
              />

              {errors?.distance && <Error error={errors.distance.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="volume-label">{t("volume")}</FormGroupLabel>
              <Controller
                name="volume"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    id="volume-select"
                    options={volumeOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("volume")}
                    styles={formStyles}
                    isDisabled={false}
                  />
                )}
                rules={{ required: t("required_err") }}
              />

              {errors?.volume && <Error error={errors.volume.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="consumption-label">
                {t("consumption")}
              </FormGroupLabel>
              <Controller
                name="consumption"
                control={control}
                //errors={errors}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    id="consumption-select"
                    options={consumptionOptions}
                    //value={vehicles.find((c) => c.value === value)}
                    value={value}
                    onChange={onChange}
                    //onCreateOption={handleCreate}
                    placeholder={t("consumption")}
                    styles={formStyles}
                    isDisabled={false}
                  />
                )}
                rules={{ required: t("required_err") }}
              />

              {errors?.consumption && (
                <Error error={errors.consumption.message} />
              )}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center py-4">
            <Col md={8}>
              <div
                //style={{ height: "34px" }}
                className="d-flex align-items-center gap-2"
              >
                <Switch
                  id={"vehicle-consumption-in-hours-switch"}
                  isOn={vehicleConsumptionInHours}
                  onColor={colorSwitchActive}
                  handleToggle={() =>
                    setVehicleConsumptionInHours((prev) => !prev)
                  }
                />
                <span style={{ fontSize: "12px" }} id="span-msg">
                  {t("hr_machine_desc")}
                </span>
              </div>
            </Col>
          </Row>

          {vehicleConsumptionInHours && (
            <Row className="d-flex justify-content-center pb-4">
              <Col md={8}>
                <FormGroupLabel id="hourly-consumption-label">
                  {t("hourly_consumption")}
                </FormGroupLabel>
                <Controller
                  name="hourlyConsumption"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      id="hourly-consumption-select"
                      options={hourlyConsumptionOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      //onCreateOption={handleCreate}
                      placeholder={t("hourly_consumption")}
                      styles={formStyles}
                      isDisabled={false}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.hourlyConsumption && (
                  <Error error={errors.hourlyConsumption.message} />
                )}
              </Col>
            </Row>
          )}

          <hr className="mt-0 mb-0" />
          <Row className="d-flex justify-content-center my-4">
            <Col
              md={8}
              className="d-flex justify-content-center flex-column gap-2"
            >
              <AddFormButton
                id="register-btn"
                style={{ background: "#003f7d" }}
                disabled={registerBtnLoading}
                type="submit"
              >
                {registerBtnLoading ? <CustomLoader /> : t("register")}
              </AddFormButton>
              <div style={{ fontSize: "12px" }} id="privacy-policy-text">
                {t("agree_to_privacy_policy_msg")}{" "}
                <LinkAnchor
                  id="privacy-policy"
                  href="https://www.simplyfleet.app/privacy-policy"
                  target="_blank"
                >
                  {t("privacy_policy_small")}
                </LinkAnchor>
              </div>
            </Col>
          </Row>
        </Container>
      </FormContainerWithScroll>
    </>
  );
};

export default SignUpDetailsForm;

//styles

const FormContainerWithScroll = styled(FormContainer)`
  //height: ${window.innerHeight - 164}px;
  width: ${window.innerWidth - 800}px;
  margin: 40px 0;
  //overflow: auto;
  //margin-top: 800px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  ${scrollbarStyles};
`;
