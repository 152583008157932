import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { paddingLeft } from "@/utils/directions";

// import {
//   changeThemeToDark, changeThemeToLight,
// } from '@/redux/actions/themeActions';
import axios from "axios";
import Topbar from "./topbar/Topbar";
import TopbarWithNavigation from "./topbar_with_navigation/TopbarWithNavigation";
import Sidebar from "./components/sidebar/Sidebar";
import Customizer from "./customizer/Customizer";
//import WelcomeNotification from './components/WelcomeNotification';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from "../../features/sidebar/sidebarSlice";
import {
  changeThemeToLight,
  changeThemeToDark,
} from "../../features/mode/modeSlice";
import { updateUser } from "../../features/user/userSlice";
import { getGroups, getUsers, getVehicles } from "../../api/getUser";
import { updateVehiclesList } from "../../features/vehicles/vehiclesSlice";
import { updateGroupsList } from "../../features/groups/groupsSlice";
import { fetchServicesTasks } from "../../features/servicesTasks/servicesTasksSlice";
import { fetchColumns } from "../../features/columns/columnsSlice";
import { getColOrderQuery } from "../../shared/helpers";
import { updateUsersList } from "../../features/users/usersSlice";
import { useAuth } from "../../contexts/AuthContext";

const Layout = () => {
  const dispatch = useDispatch();

  const { customizer, sidebar, theme, rtl } = useSelector((state) => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
    rtl: state.rtl,
  }));

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    //dispatch(changeMobileSidebarVisibility());
  };

  // const changeToDark = () => {
  //   dispatch(changeThemeToDark());
  // };

  // const changeToLight = () => {
  //   dispatch(changeThemeToLight());
  // };

  return (
    <LayoutContainer
      collapse={sidebar.collapse}
      topNavigation={customizer.topNavigation}
    >
      {/* <Customizer
        changeSidebarVisibility={sidebarVisibility}
        changeToLight={changeToLight}
        changeToDark={changeToDark}
      /> */}
      {customizer.topNavigation ? (
        <TopbarWithNavigation
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      ) : (
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
      )}
      <Sidebar
        sidebar={sidebar}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        topNavigation={customizer.topNavigation}
      />
    </LayoutContainer>
  );
};

export default withRouter(Layout);

// region STYLES

const LayoutContainer = styled.div`
  & + div {
    ${(props) =>
      props.collapse &&
      `
      ${paddingLeft(props)}: 0;
    `};

    @media screen and (min-width: 576px) {
      ${(props) =>
        props.collapse &&
        `
        ${paddingLeft(props)}: 60px;
      `}

      ${(props) =>
        props.topNavigation &&
        `
         ${paddingLeft(props)}: 0;
      `}
    }
  }
`;

// endregion
