import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    withBoxShadow: false,
    topNavigation: false,
};

const customizerSlice = createSlice({
    name: 'customizer',
    initialState,
    reducers: {
        toggleTopNavigation: (state, action) => {
            state.topNavigation = !state.topNavigation;
        },
    },
});

export const { toggleTopNavigation } = customizerSlice.actions;
export default customizerSlice.reducer;


