import React from "react";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "./TableElements";
import { InfoIcon } from "./InfoPopup";
import { colorLightBlueStatus, colorYellowNew } from "../../utils/palette";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const SubscribePopup = ({
  showPopup,
  setShowPopup,
  message,
  onGoToBilling,
}) => {
  const history = useHistory();

  const { t } = useTranslation("common");

  const handleGoToBilling = () => {
    if (onGoToBilling) {
      onGoToBilling();
    }
    history.push("/billing");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <Modal
      show={showPopup}
      onHide={() => setShowPopup(!showPopup)}
      centered
      size={"sm"}
    >
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer
            onClick={() => setShowPopup(false)}
            id="close-action-popup-icon-container"
          >
            <i className="fi fi-rr-cross-small" id="close-action-popup-icon" />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-3 justify-content-start align-items-center p-2">
          <InfoIcon
            className={"fi fi-rr-triangle-warning"}
            color={colorYellowNew}
            id="info-icon"
          />
          <p style={{ fontSize: "16px" }} id="info-msg">
            {message}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2 gap-3">
          <FilterButton
            onClick={handleCancel}
            type="button"
            id="cancel-action-btn"
          >
            {t("cancel")}
          </FilterButton>
          <AddFormButton
            onClick={handleGoToBilling}
            type="button"
            id="go-to-billing-action-btn"
          >
            {t("go_to_billing")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscribePopup;
