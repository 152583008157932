import React, { useEffect, useMemo, useState } from "react";
import DataReactTable from "../DataTable/components/DataReactTable";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router";
import { post, postApi, putApi } from "../../api/getUser";
import { Col, Container, Row } from "react-bootstrap";
import {
  AddFormButton,
  ColumnHeading,
} from "../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import { addRowData } from "../../features/row/rowSlice";
import { updateNoOfRecords } from "../../features/columns/columnsSlice";
import { useDispatch } from "react-redux";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import { NATEXT } from "../../shared/constants";
import { getDisplayDateWithTimeNew } from "../../shared/helpers";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { Card, CardBody } from "../../shared/components/Card";
import { useSelector } from "react-redux";

const FaultCodesTable = () => {
  const {
    isLoggedIn,
    setLoading,
    isClientApiCalled,
    setIsClientApiCalled,
    integrationsData,
  } = useAuth();
  const { pathname } = useLocation();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const { roleId } = useSelector((state) => state.user);

  const location = useLocation();
  const fromCancel = location?.state?.fromCancel;

  const [showFaultCodesTable, setShowFaultCodesTable] = useState(true);

  const [rowData, setRowData] = useState([]);
  let kpiValuesArray = [];

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        //width: getColumnWidth("name", "Vehicle"),
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("fault_code"),
        accessor: "faultCodes",
        disableCol: true,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 3,
        Header: t("fault_desc"),
        accessor: "faultDescs",
        accessorId: "task_name",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
    ],
    [rowData]
  );

  useEffect(() => {
    if (isLoggedIn && pathname.includes("faultcodes")) {
      let showFaultCodes = false;
      //setShowFaultCodesTable(false);
      if (integrationsData?.length > 0) {
        setLoading(true);
        for (let intObj of integrationsData) {
          const { provider, provider_type, service_enabled, service_type } =
            intObj;
          if (
            provider_type === 1 &&
            service_enabled === 1 &&
            service_type === 2
          ) {
            showFaultCodes = true;
            //setShowFaultCodesTable(true);
          }
        }
        setLoading(false);
        setShowFaultCodesTable(showFaultCodes);
      }
    }
  }, [integrationsData, isLoggedIn]);

  useEffect(() => {
    if (
      isLoggedIn &&
      pathname.includes("faultcodes") &&
      (!isClientApiCalled || !fromCancel)
    ) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "faultcodes",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          const { default_rows } = data;
          dispatch(updateNoOfRecords({ defaultRows: default_rows }));
          const apiData = {
            querystring: "get_fault_codes",
            provider_type: 1,
          };
          const res = await post("integrations", apiData);
          if (res.success) {
            const { data } = res;
            let temp = [];
            for (let faultData of data) {
              const { veh_id, org_id, veh_img, name, faults } = faultData;
              let faultCodesArr = [];
              let faultDescsArr = [];
              let faultCodesData = [];
              //   for (let fault of arr) {
              //     const { dtcShortCode, dtcDescription } = fault;
              //     faultCodesData.push({
              //       faultCode: dtcShortCode,
              //       faultDesc: dtcDescription,
              //     });
              //     faultCodesArr.push(dtcShortCode);
              //     faultDescsArr.push(dtcDescription);
              //   }
              //   let faultCodesStr =
              //     faultCodesArr.length > 0 ? faultCodesArr.join(", ") : "";
              //   let faultDescsStr =
              //     faultDescsArr.length > 0 ? faultDescsArr.join(", ") : "";

              let faultCodesStr =
                faults?.length > 0
                  ? faults.map((f) => f.dtcShortCode).join(", ")
                  : "";
              let faultDescsStr =
                faults?.length > 0
                  ? faults.map((f) => f.dtcDescription).join(", ")
                  : "";
              temp.push({
                ...faultData,
                faultCodes: faultCodesStr,
                faultDescs: faultDescsStr,
              });
            }
            setRowData(temp);
            dispatch(addRowData(temp));
            setLoading(false);
          } else {
            setLoading(false);
            setRowData([]);
            dispatch(addRowData([]));
          }
        }
      })();
    }
  }, [isLoggedIn]);

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          <Col className="d-flex flex-column">
            <ColumnHeading>{t("fault_codes")}</ColumnHeading>
            {showFaultCodesTable && (
              <Desc>
                {getDisplayDateWithTimeNew(Math.round(new Date() / 1000))}
              </Desc>
            )}
            {/* <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan> */}
          </Col>
        </Row>
        {showFaultCodesTable ? (
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={kpiValuesArray}
            //initialData={initialData}
            //defaultColumns={defaultColumns}
          />
        ) : (
          <Col>
            <Card>
              <CardBody
                formcard="true"
                style={{ background: "transparent" }}
                className="my-2 py-0"
              >
                {roleId === 4 ? (
                  <div
                    className="d-flex flex-column gap-3 justify-content-center align-items-center"
                    style={{ height: "-webkit-fill-available" }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <p>{t("fault_codes_str_1")}</p>
                      <p>{t("fault_codes_str_2")}</p>
                      <p>
                        {t("fault_codes_str_3")}
                        <b>{` ${t("setup")} > ${t("integrations")} `}</b>
                        {t("within_sf")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column gap-3 justify-content-center align-items-center"
                    style={{ height: "-webkit-fill-available" }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <p>{t("fault_codes_str_4")}</p>
                      <p>{t("fault_codes_str_5")}</p>
                    </div>
                    {/* <div className="d-flex justify-content-center"> */}
                    <AddFormButton
                      style={{ width: "min-content" }}
                      type="button"
                      onClick={() => {
                        history.push("/setup/integrations");
                      }}
                    >
                      {t("modify_telematics_config")}
                    </AddFormButton>
                    {/* </div> */}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        )}
      </Container>
    </>
  );
};

export default FaultCodesTable;
