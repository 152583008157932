import { useTranslation } from "react-i18next";
import { FilterButton } from "../TableElements";
import React, { useRef } from "react";
import styled from "styled-components";
import { colorLightGray, disabledBgColor } from "../../../utils/palette";
import { useDeferredValue } from "react";
import { useEffect } from "react";
import ZoomedDocPopup from "./ZoomedDocPopup";
import { useState } from "react";
import ZoomModal from "../ZoomModal";

const DocUploader = ({
  docFile,
  setDocFile,
  documents,
  setDocuments,
  currentDoc,
  isDisabled,
  accFileTypes,
}) => {
  const { t } = useTranslation("common");

  const hiddenFileInput = useRef(null);

  const [showZoomedImg, setShowZoomedImg] = useState(false);

  // useEffect(() => {
  //   if (docFile?.extension === "txt") {
  //     let ext = docFile?.type[0];
  //     setDocFile((prev) => ({ ...prev, extension: ext }));
  //   }
  // }, [docFile]);

  //Update Documents upon update of docFile
  // useEffect(() => {
  //   if (docFile) {
  //     setDocuments((prev) => {
  //       let temp = [...prev];
  //       temp = temp.map((d) => {
  //         if (d.id === currentDoc.id) {
  //           return {
  //             ...d,
  //             image: docFile,
  //           };
  //         } else {
  //           return d;
  //         }
  //       });
  //       return temp;
  //     });
  //   }
  // }, [docFile]);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    //console.log('click');
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const { files } = event.target;
    //console.log(event.target.files);
    const uploadedFile = files[0];
    const extension = uploadedFile.name.split(".").pop();
    console.log(uploadedFile);
    if (uploadedFile) {
      setDocFile({
        id: Date.now(),
        file: uploadedFile,
        url: URL.createObjectURL(uploadedFile),
        extension: extension,
        ext: extension,
        type: uploadedFile.type,
        imgFormat: "normal",
        imgName: `license.${extension}`, //This key is added for license
      });
    }
  };

  //console.log(docFile);

  return (
    <>
      {/* {showZoomedImg && (
        <ZoomedDocPopup
          showPopup={showZoomedImg}
          setShowPopup={setShowZoomedImg}
          img={docFile}
        />
      )} */}
      {showZoomedImg && (
        <ZoomModal
          showModal={showZoomedImg}
          setShowModal={setShowZoomedImg}
          doc={docFile}
        />
      )}
      <div className="d-flex gap-3">
        <DocUploadContainer
          disabled={isDisabled && !docFile}
          id="upload-doc-div"
        >
          {docFile ? (
            <ImgContainer>
              {["png", "jpg", "jpeg", "jfif"].includes(docFile?.ext) ? (
                <img
                  src={docFile.url}
                  alt="doc-photo"
                  width={424}
                  height={212}
                  id="doc"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <iframe
                  src={docFile.url}
                  alt="doc-photo"
                  width={424}
                  height={212}
                  id="doc"
                  //style={{ overflow: "hidden", height: "101%", width: "101%" }}
                />
              )}

              <OverlayContainer disabled={isDisabled}>
                <IconContainer>
                  <Icon
                    id="zoom-in-icon"
                    className="fi fi-rr-zoom-in"
                    onClick={() => setShowZoomedImg(true)}
                  ></Icon>
                  <Icon
                    id="remove-icon"
                    disabled={isDisabled}
                    className="fi fi-rr-cross-circle"
                    onClick={() => setDocFile("")}
                  ></Icon>
                </IconContainer>
              </OverlayContainer>
            </ImgContainer>
          ) : (
            <DocUploadContainer
              id="upload-doc-div"
              onClick={handleClick}
              hoverEffect
              disabled={isDisabled}
            >
              <i
                className="fi fi-br-upload"
                style={{ width: "10px" }}
                id="upload-icon"
              ></i>
              <span>{t("upload_document")}</span>
            </DocUploadContainer>
          )}
        </DocUploadContainer>

        <input
          type="file"
          id="upload-img"
          name="upload-img"
          accept={
            accFileTypes
              ? accFileTypes
              : "image/*,.doc,.docx, .pdf, application/msword"
          }
          ref={hiddenFileInput}
          onChange={handleChange}
          //onClick={handleChange}
          style={{ display: "none" }}
          //multiple
          disabled={isDisabled}
        />

        {docFile && !isDisabled && (
          <FilterButton
            type="button"
            id="change-doc"
            onClick={handleClick}
            className="align-self-end"
          >
            {t("change")}
          </FilterButton>
        )}
      </div>
    </>
  );
};

export default DocUploader;

//styles

export const DocUploadContainer = styled.div`
  width: 424px;
  height: 212px;
  border: 1px dashed ${colorLightGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  //cursor: pointer;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  ${(p) =>
    p.hoverEffect &&
    !p.disabled &&
    `
  &:hover {
    background: ${colorLightGray};
  }
  `}

  ${(p) => p.disabled && `background: ${disabledBgColor}`}
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: lightgray;

  // ${(p) => p.disabled && `display: none`};
`;

export const ImgContainer = styled.div`
  position: relative;
  //max-width: 400px;

  &:hover {
    ${OverlayContainer} {
      opacity: 1;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: white;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  // i {
  //   &:hover {
  //     color: gray;
  //   }
  // }
`;

const Icon = styled.i`
  &:hover {
    color: gray;
  }
  ${(p) => p.disabled && `display: none`};
`;

//styles - end
