import React, { useState } from "react";
import { VehicleImg, VehicleName } from "./VehicleComponent";
import Tooltip from "@/shared/components/Tooltip";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";

import { NATEXT } from "../../../constants";
import { colorLightBlue } from "../../../../utils/palette";

const UserComponent = ({ userName, groupName, userId, userImg, orgId }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const url = "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";
  const imageUrl = `${url}/org_${orgId}/user_${userId}/profile/${userImg}`;

  const handleUserNameHover = (event) => {
    let e = event.target;
    setShowTooltip(e.scrollWidth > e.clientWidth);
    //console.log(e.clientWidth, e.scrollWidth);
  };
  let userInitials = "";
  const userNameArr = userName.split(" ");
  userNameArr.forEach((name) => (userInitials += name[0]?.toUpperCase()));
  userInitials = userInitials.slice(0, 3);

  return (
    <div className="d-flex gap-2 align-items-center">
      {userImg ? (
        <VehicleImg
          zoom
          hovered={showIcon}
          src={imageUrl}
          alt="vehicle-img"
          // style={{ width: "40px", height: "40px" }}
          className="rounded-circle"
        />
      ) : (
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: 20,
            height: 20,
            border: `1px solid ${colorLightBlue}`,
            background: "gray",
            color: "white",

            textAlign: "center",
            //padding: "0.25rem",
          }}
        >
          <span style={{ fontSize: "8px" }}>{userInitials}</span>
        </div>
      )}
      {showTooltip ? (
        <Tooltip text={userName}>
          <VehicleName onMouseOver={(e) => handleUserNameHover(e)}>
            {userName}
            {/* </p> */}
            {/* <p>{groupName === "N/A" ? "" : groupName}</p> */}
          </VehicleName>
        </Tooltip>
      ) : (
        <VehicleName onMouseOver={(e) => handleUserNameHover(e)}>
          {userName}
          {/* </p> */}
          {/* <p>{groupName === "N/A" ? "" : groupName}</p> */}
        </VehicleName>
      )}
    </div>
  );
};

export default UserComponent;
