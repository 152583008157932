import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, newUrl, url } from "../../api/getUser";
import { auth } from "../../api/firebase";

const initialState = {
  order: [],
  minDate: "",
  maxDate: "",
  noOfRecords: 0,
  status: "idle",
  query: "",
  //firstTimeCall: true,
  error: null,
};

export const fetchColumns = createAsyncThunk(
  "columns/fetchColumns",
  async (query) => {
    try {
      console.log("fetch Columns called");
      const token = await getToken(); //sessionStorage.getItem("token");
      //const email = sessionStorage.getItem("email");
      const email = auth?.currentUser?.email;
      const jsonData = { email: email, querystring: query };
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(newUrl, jsonData, config);
      //console.log("fetch ColumnOrder Response", response);
      const res = response?.data[0]?.value.user_data[0];
      console.log("fetchColumns res", res);
      return { ...res, query };
    } catch (err) {
      return err.message;
    }
  }
);

export const updateColumns = createAsyncThunk(
  "columns/updateColumns",
  async (data) => {
    try {
      const token = await getToken(); //sessionStorage.getItem("token");
      const email = auth?.currentUser?.email; //sessionStorage.getItem("email");

      const jsonData = {
        email: email,
        screen_name: data.screenName,
        column_names: JSON.stringify(data.columnNames),
        default_rows: data.defaultRows,
        querystring: "insertcolorder",
      };
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      console.log("Data in updateColumns", data);
      const response = await axios.post(newUrl, jsonData, config);
      //console.log("Update Column Order Response", response);
      const res = response?.data[0]?.value.user_data[0];
      return res;
    } catch (err) {
      return err.message;
    }
  }
);

const columnsSlice = createSlice({
  name: "columns",
  initialState,
  reducers: {
    updateColOrderStatus: (state, action) => {
      state.status = "idle";
    },
    updateUserSettingsData: (state, action) => {
      const { col_order, min_date, default_rows } = action.payload;
      const screen = action.payload?.screen;
      let colOrder = col_order
        ? JSON.parse(col_order).map((item) => +item + 1)
        : [];
      if (screen === "vehicles" || screen === "users") {
        colOrder = [1];
        if (col_order) {
          let colOrderFromApi = JSON.parse(col_order).map((item) => +item);
          for (let col of colOrderFromApi) {
            if (col != 0 && col != 1) {
              colOrder.push(col);
            }
          }
        } else {
          colOrder = [];
        }

        // colOrder = col_order
        //   ? JSON.parse(col_order)
        //       .map((item) => +item)
        //       .filter((item) => item != 0 && item != 1)
        //   : [];
      }
      state.order = colOrder;
      state.minDate = min_date ? min_date : 0;
      state.noOfRecords = default_rows ? default_rows : 10;
    },
    updateNoOfRecords: (state, action) => {
      console.log("action.payload", action.payload);
      const { defaultRows } = action.payload;
      state.noOfRecords = defaultRows ? defaultRows : 10;
    },
    updateColOrder: (state, action) => {
      console.log("action.payload", action.payload);
      const { colOrder } = action.payload;
      state.order = colOrder;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchColumns.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchColumns.fulfilled, (state, action) => {
        console.log("action", action.payload);
        state.status = "succeeded";
        state.order =
          action.payload.colorder &&
          JSON.parse(action.payload.colorder).map((item) => +item + 1);
        state.minDate = action.payload.min_date ? action.payload.min_date : "";
        state.maxDate = action.payload.max_date ? action.payload.max_date : "";
        state.noOfRecords = action.payload.default_rows;
        state.query = action.payload.query;
        // if (state.firstTimeCall) {
        //   state.firstTimeCall = false;
        // }
      })
      .addCase(fetchColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateColumns.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectColumnOrder = (state) => state.order;
export const selectColumnOrderStatus = (state) => state.status;
export const selectColumnOrderError = (state) => state.error;
export const {
  updateColOrderStatus,
  updateUserSettingsData,
  updateNoOfRecords,
  updateColOrder,
} = columnsSlice.actions;

export default columnsSlice.reducer;
