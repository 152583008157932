import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InspectionForms from './InspectionForms';
import InspectionSchedule from './InspectionSchedule';
import InspectionSubmissions from './InspectionSubmissions';
import InspectionTasks from './InspectionTasks';

export default () => (
  <Switch>
    <Route path="/inspection/inspectionforms" component={InspectionForms} />
    <Route path="/inspection/inspectionscheduling" component={InspectionSchedule} />
    <Route path="/inspection/inspectionsubmissions" component={InspectionSubmissions} />
    <Route path="/inspection/inspectiontasks" component={InspectionTasks} />
  </Switch>
);

