import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fillups: []
};

const fillupsSlice = createSlice({
    name: 'fillups',
    initialState,
    reducers: {
        addFillups(state, action) {
            state.fillups = action.payload;
        }
    },
});

export const { addFillups } = fillupsSlice.actions;
export default fillupsSlice.reducer;


