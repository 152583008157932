import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Controller, useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormField from "../../shared/components/form/FormField";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { CustomInput } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  colorGray,
  colorLightBlueStatus,
  colorLightGray,
  colorSwitchActive,
  disabledBgColor,
} from "../../utils/palette";
import {
  emailPattern,
  getDateFnsLocale,
  getInitialStartTime,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../shared/helpers";
import Switch from "../../shared/components/form/Switch/Switch";
import Error from "@/shared/components/form/Error";
import { FilterButton } from "../../shared/components/TableElements";
import { useUserName } from "../../contexts/UserNameContext";
import InactiveErrorPopup from "./InactiveErrorPopup";
import { postApi } from "../../api/getUser";
import ZoomModal from "../../shared/components/ZoomModal";
import getUnixTime from "date-fns/getUnixTime";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import { t } from "i18next";
import SubscribePopup from "../../shared/components/SubscribePopup";

const UserDetailsForm = ({
  rowData,
  type,
  userPhoto,
  setUserPhoto,
  schedule,
  setSchedule,
  vehAssignedUpdated,
  setVehiclesAssigned,
  setVehAssignedUpdated,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { tag } = useAuth();

  const users = useSelector((state) => state.users.list);
  const [managerOptions, setManagerOptions] = useState(users);

  const { userId, roleId } = useSelector((state) => state.user);
  const { currency } = useSelector((state) => state.units);

  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const { userName, setUserName } = useUserName();

  const allRoles = [
    {
      value: 1,
      label: t("admin"),
    },
    {
      value: 2,
      label: t("manager"),
    },
    {
      value: 3,
      label: t("operator"),
    },
    {
      value: 4,
      label: t("technician"),
    },
  ];

  let roleOptions = [...allRoles];
  if (roleId === 2) {
    if (rowData?.role_id === 2) {
      roleOptions = allRoles.slice(1, 4);
    } else {
      roleOptions = allRoles.slice(2, 4);
    }
  }

  const [inactiveErrData, setInactiveErrData] = useState(null);

  const scheduleObj = {
    1: t("monday"),
    2: t("tuesday"),
    3: t("wednesday"),
    4: t("thursday"),
    5: t("friday"),
    6: t("saturday"),
    7: t("sunday"),
  };

  //hook form variables
  const name = watch("name");
  const email = watch("email");
  const phone = watch("phone");
  const address = watch("address");
  const role = watch("role");
  const manager = watch("manager");
  const joiningDate = watch("joiningDate");
  const rate = watch("rate");
  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const comments = watch("comments");
  const status = watch("status");

  const mode = location?.state?.mode;
  const firstTimeEdit = useRef(true);

  //Effect to set initial values
  useEffect(() => {
    if (type !== "edit") {
      setValue("status", true);

      setValue("role", {
        value: 3,
        label: t("operator"),
      });
    }

    //Call allmanagers api if user is manager
    // (async () => {
    //   if (roleId === 2) {
    //     //For manager login, manager of user i.e admin is not available in user list. Hence the api call
    //     const apiData = {
    //       edit_user_email: email,
    //       email: email,
    //       querystring: "getallmanagers",
    //     };
    //     const res = await postApi(apiData, "commonNew");
    //     if (res.success) {
    //       const optionsData = res.user_data.map((u) => ({
    //         ...u,
    //         label: u.name,
    //         value: u.user_id,
    //       }));
    //       setManagerOptions(optionsData);
    //     }
    //   }
    // })();
  }, []);

  //Effect to set managers list initially
  useEffect(() => {
    if (users.length > 0 && type !== "edit") {
      setManagerOptions(users);
      if (userId) {
        setValue(
          "manager",
          users.find((u) => u.user_id === userId)
        );
      }
    }
  }, [users, userId]);

  //Set userName global state whenever the local state changes
  useEffect(() => {
    if (rowData?.user_id === userId) {
      if (!name) {
        setUserName(role?.label);
      } else {
        setUserName(name);
      }
    }
  }, [name]);

  // //Update userInfo on change of local fields
  // useEffect(() => {
  //   userInfo.current = {
  //     name: name,
  //     email: email,
  //     phone: phone,
  //     address: address,
  //     role: role,
  //     manager: manager,
  //     joiningDate: joiningDate,
  //     userImg: userPhoto,
  //     comments: comments,
  //     status: status,
  //     rate: rate,
  //     startTime: startTime,
  //     endTime: endTime,
  //     schedule: schedule,
  //   };
  // }, [
  //   name,
  //   email,
  //   phone,
  //   address,
  //   comments,
  //   role,
  //   manager,
  //   joiningDate,
  //   userPhoto,
  //   status,
  //   rate,
  //   startTime,
  //   endTime,
  //   schedule,
  // ]);

  //Populating values on edit
  useEffect(() => {
    if (type === "edit" && users?.length > 0 && roleId) {
      if (rowData) {
        const {
          id,
          name,
          manager_name,
          email,
          phone,
          home_address,
          role_id,
          user_active,
          starting_time,
          ending_time,
          joining_date,
          manager_id,
          role_name,
          comments,
          working_days,
          hourly_rate,
          user_id,
          user_img,
          org_id,
        } = rowData;

        (async () => {
          setValue("name", name);
          setValue("email", email);
          setValue("phone", phone);
          setValue("address", home_address);
          setValue("comments", comments);

          setValue(
            "role",
            roleOptions.find((role) => role.value === role_id)
          );

          if (role_id == 2) {
            setManagerOptions(users.filter((u) => u.role_id === 1));
          }

          if (role_id == 3) {
            setManagerOptions(
              users.filter((u) => u.role_id === 1 || u.role_id === 2)
            );
          }

          let managersList = [...users];

          if (roleId === 2) {
            //For manager login, manager of user i.e admin is not available in user list. Hence the api call

            const apiData = {
              edit_user_email: email?.toLocaleLowerCase(),
              email: email?.toLocaleLowerCase(),
              querystring: "getallmanagers",
            };
            const res = await postApi(apiData, "commonNew");
            if (res.success) {
              //managersList = res.user_data;
              const optionsData = res.user_data.map((u) => ({
                ...u,
                label: u.name,
                value: u.user_id,
              }));
              managersList = optionsData;
              setManagerOptions(optionsData);
            }
          }

          //console.log("managerOptions", managerOptions);
          setValue(
            "manager",
            managersList.find((user) => user.user_id === manager_id)
          );

          setValue("status", user_active === 1);
          setValue(
            "joiningDate",
            joining_date ? new Date(joining_date * 1000) : ""
          );

          const url =
            "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";
          const imageUrl = `${url}/org_${org_id}/user_${user_id}/profile/${user_img}`;

          if (user_img) {
            setUserPhoto({ url: imageUrl, imgName: user_img });
          } else {
            setUserPhoto("");
          }

          setValue("startTime", starting_time?.slice(0, 5));
          setValue("endTime", ending_time?.slice(0, 5));
          setValue("rate", hourly_rate);
          if (working_days) {
            const workingDays = working_days?.split(":");
            const schedule = workingDays.map((d) => +d);
            setSchedule(schedule);
          }
        })();
      }
    }
  }, [rowData, users, roleId]);

  //Make manager null when role - Admin or Technician is selected
  //Update managerOptions based on change in role
  // useEffect(() => {
  //   if (role?.value === 1 || role?.value === 4) {
  //     setValue("manager", "");
  //   }
  //   if (role?.value === 2) {
  //     setValue(
  //       "manager",
  //       managerOptions.find((u) => u.user_id === userId)
  //     );
  //     setManagerOptions(users.filter((u) => u.role_id === 1));
  //   }
  //   if (role?.value === 3) {
  //     setValue(
  //       "manager",
  //       managerOptions.find((u) => u.user_id === userId)
  //     );
  //     setManagerOptions(
  //       users.filter((u) => u.role_id === 1 || u.role_id === 2)
  //     );
  //   }
  // }, [role]);

  const handleRoleChange = (value) => {
    if (value === 1 || value === 4) {
      setValue("manager", "");
    }
    if (value === 2) {
      setValue(
        "manager",
        managerOptions.find((u) => u.user_id === userId)
      );
      setManagerOptions(users.filter((u) => u.role_id === 1));
    }
    if (value === 3) {
      setValue(
        "manager",
        managerOptions.find((u) => u.user_id === userId)
      );
      setManagerOptions(
        users.filter((u) => u.role_id === 1 || u.role_id === 2)
      );
    }
  };

  const handleDayClick = (key) => {
    if (type === "edit" && !status) {
      return;
    }
    setSchedule((prev) => {
      if (prev.includes(key)) {
        return prev.filter((n) => n !== key);
      } else {
        let temp = [...prev];
        temp.push(key);
        //temp[key - 1] = key;
        return temp;
      }
    });
  };

  const handleSwitchToggle = (onChange, value) => {
    if (rowData?.user_id) {
      let usersUnderManager = [];
      users.forEach((u) => {
        if (u.manager_id === rowData.user_id) {
          usersUnderManager.push(u);
        }
      });

      if (usersUnderManager.length > 0 && !value) {
        setInactiveErrData({ users: usersUnderManager, manager: rowData });
        return;
      }
      //else {
      //Remove vehicles assigned when user becomes inactive
      if (!value) {
        setVehiclesAssigned([]);
        if (!vehAssignedUpdated) {
          setVehAssignedUpdated(true);
        }
      }
      onChange(value);
      //}
    } else {
      //Remove vehicles assigned when user becomes inactive
      if (!value) {
        setVehiclesAssigned([]);
        if (!vehAssignedUpdated) {
          setVehAssignedUpdated(true);
        }
      }
      onChange(value);
    }
  };

  return (
    //<FormContainer onSubmit={handleSubmit(onSubmit)}>
    <>
      {inactiveErrData && (
        <InactiveErrorPopup
          inactiveErrData={inactiveErrData}
          setInactiveErrData={setInactiveErrData}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <Container className="p-3">
        <Row>
          <Col md={2} className="pt-2 ">
            <PhotoUploadComponent
              photo={userPhoto}
              setPhoto={setUserPhoto}
              isDisabled={type === "edit" && !status}
              //rowData={rowData}
            />
          </Col>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <FormGroupLabel>{t("name")}</FormGroupLabel>
                <FormItem
                  disabled={type === "edit" && !status}
                  //tabIndex={0}
                >
                  <FormField
                    name="name"
                    control={control}
                    component="input"
                    rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("name")}
                    style={formInputStyles}
                    disabled={type === "edit" && !status}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.name && <Error error={errors.name.message} />}
              </Col>
              <Col md={2}></Col>

              <Col md={4}>
                <FormGroupLabel>{t("role")}</FormGroupLabel>
                <Controller
                  name="role"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      id="role"
                      options={roleOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={(e) => {
                        console.log("role changed", e);
                        if (
                          e.value == 4 &&
                          (tag === "free-user" || tag === "essential-user")
                        ) {
                          setShowSubscribePopup(true);
                          return;
                        }
                        onChange(e);
                        handleRoleChange(e.value);
                        //onChange;
                      }}
                      //onCreateOption={handleCreate}
                      placeholder={t("role_placeholder")}
                      styles={formStyles}
                      isDisabled={
                        rowData?.user_id === userId ||
                        (!status && type === "edit")
                      }
                      tag={tag}
                      components={{ Option }}
                      isSearchable={false}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.role && <Error error={errors.role.message} />}
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroupLabel>{t("email")}</FormGroupLabel>
                <FormItem
                  disabled={type === "edit"}
                  //tabIndex={0}
                >
                  <FormField
                    name="email"
                    control={control}
                    component="input"
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("email")}
                    rules={{
                      required: t("required_err"),
                      pattern: {
                        value: emailPattern,
                        message: "Invalid email address",
                      },
                    }}
                    style={formInputStyles}
                    disabled={type === "edit"}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.email && <Error error={errors.email.message} />}
              </Col>

              <Col md={2}></Col>

              <Col md={4}>
                <FormGroupLabel>{t("manager")}</FormGroupLabel>
                <Controller
                  name="manager"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      id="manager"
                      options={managerOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      //onCreateOption={handleCreate}
                      placeholder={
                        role?.value === 2 || role?.value === 3 || !role
                          ? t("manager_placeholder")
                          : "N/A"
                      }
                      styles={formStyles}
                      isDisabled={
                        role?.value === 1 ||
                        role?.value === 4 ||
                        roleId === 2 ||
                        (type === "edit" && !status)
                      }
                    />
                  )}
                  rules={{
                    required:
                      role?.value === 2 || role?.value === 3
                        ? t("required_err")
                        : false,
                  }}
                />

                {errors?.manager && <Error error={errors.manager.message} />}
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroupLabel>{t("phone")}</FormGroupLabel>
                <FormItem
                  disabled={type === "edit" && !status}
                  //tabIndex={0}
                >
                  <FormField
                    name="phone"
                    control={control}
                    component="input"
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("phone")}
                    style={formInputStyles}
                    disabled={type === "edit" && !status}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.phone && <Error error={errors.phone.message} />}
              </Col>
              <Col md={2}></Col>
              <Col md={4}>
                <FormGroupLabel>{t("status")}</FormGroupLabel>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={true}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <div
                      style={{ height: "34px" }}
                      className="d-flex align-items-center gap-2"
                    >
                      <Switch
                        id={"user-status-switch"}
                        isOn={value}
                        onColor={colorSwitchActive}
                        handleToggle={(e) =>
                          handleSwitchToggle(onChange, e.target.checked)
                        }
                      />
                      <span>{status ? t("active") : t("inactive")}</span>
                    </div>
                  )}
                  //rules={{ required: t("required_err") }}
                />
              </Col>
            </Row>

            {role?.value === 4 && roleId !== 4 && (
              <>
                <Row>
                  <Col md={10}>
                    <hr className="mt-4 mb-0 p-0" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroupLabel>{t("rate")}</FormGroupLabel>
                    <FormItem
                      disabled={type === "edit" && !status}
                      //tabIndex={0}
                    >
                      <FormField
                        name="rate"
                        control={control}
                        component="input"
                        defaultValue=""
                        type="number"
                        onKeyDown={handleNegative}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        //isAboveError={isAboveError}
                        placeholder={t("rate")}
                        style={formInputStyles}
                        disabled={type === "edit" && !status}
                        //onKeyUp={handleSIOdoChange}
                      />
                      <UnitSpan>{currency}/hr</UnitSpan>
                    </FormItem>

                    {errors?.rate && <Error error={errors.rate.message} />}
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <div className="d-flex gap-2 mt-4 mb-3 align-items-center">
                      <FormGroupLabel className="m-0 p-0">
                        {t("schedule")}
                      </FormGroupLabel>
                      {Object.keys(scheduleObj).map((key) => (
                        <DayContainer
                          key={key}
                          type="checkbox"
                          active={schedule?.includes(+key)}
                          onClick={() => handleDayClick(+key)}
                          disabled={type === "edit" && !status}
                        >
                          <span>{scheduleObj[+key]}</span>
                        </DayContainer>
                      ))}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroupLabel className="mt-0">
                      {t("start_time")}
                    </FormGroupLabel>
                    <FormItem disabled={type === "edit" && !status}>
                      <FormField
                        name="startTime"
                        control={control}
                        component="input"
                        type="time"
                        errors={errors}
                        // rules={{
                        //   required: t("required_err"),
                        // }}
                        //defaultValue={getInitialStartTime}
                        placeholder={t("start_time_placeholder")}
                        style={formInputStyles}
                        disabled={type === "edit" && !status}
                        //onChange={handleTimeChange}
                        //onKeyUp={handleDueOdoChange}
                      />
                    </FormItem>
                    {errors?.startTime && (
                      <Error error={errors.startTime.message} />
                    )}
                  </Col>
                  <Col md={2}></Col>
                  <Col md={4}>
                    <FormGroupLabel className="mt-0">
                      {t("end_time")}
                    </FormGroupLabel>
                    <FormItem disabled={type === "edit" && !status}>
                      <FormField
                        name="endTime"
                        control={control}
                        component="input"
                        type="time"
                        errors={errors}
                        // rules={{
                        //   required: t("required_err"),
                        //   //min: 'hi'
                        //   // validate: {
                        //   //   custom: {
                        //   //     value: startTime + 1,
                        //   //     message:
                        //   //       "Value must be greater than start time",
                        //   //   },
                        //   // },
                        // }}
                        //defaultValue=""
                        placeholder={t("end_time_placeholder")}
                        style={formInputStyles}
                        disabled={type === "edit" && !status}
                        //onChange={handleTimeChange}
                        //onKeyUp={handleDueOdoChange}
                      />
                    </FormItem>
                    {errors?.endTime && (
                      <Error error={errors.endTime.message} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <hr className="mt-4 mb-0 p-0" />
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col md={4}>
                <FormGroupLabel>{t("joining_date")}</FormGroupLabel>

                <Controller
                  name="joiningDate"
                  //defaultValue={new Date()}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      id="datepicker"
                      locale={getDateFnsLocale()}
                      dateFormat={"P"}
                      //maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      selected={value}
                      onChange={onChange}
                      customInput={<CustomInput disabled={false} />}
                      placeholderText={t("date_tv")}
                      disabled={type === "edit" && !status}
                    />
                  )}
                  // rules={{
                  //   required: t("required_err"),
                  // }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <FormGroupLabel id="address-label">
                  {t("address")}
                </FormGroupLabel>

                <FormItem
                  disabled={type === "edit" && !status}
                  //tabIndex={0}
                >
                  <FormField
                    name="address"
                    control={control}
                    component="input"
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("address")}
                    style={formInputStyles}
                    disabled={type === "edit" && !status}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.address && <Error error={errors.address.message} />}
              </Col>
            </Row>

            <Row className="pb-3">
              <Col md={10}>
                <FormGroupLabel id="comments-label">
                  {t("notes_tv")}
                </FormGroupLabel>
                <FormItem
                  disabled={type === "edit" && !status}
                  //tabIndex={0}
                >
                  <FormField
                    id="comments"
                    name="comments"
                    control={control}
                    component="input"
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("notes_tv")}
                    style={formInputStyles}
                    disabled={type === "edit" && !status}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.comments && <Error error={errors.comments.message} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
    //</FormContainer>
  );
};

export default UserDetailsForm;

export const PhotoUploadComponent = ({ photo, setPhoto, isDisabled }) => {
  //console.log("photo in PhotoUploadComponent", photo);
  const { t } = useTranslation("common");
  const location = useLocation();
  const screen = location.pathname;

  const hiddenFileInput = React.useRef(null);
  const [showZoomedImg, setShowZoomedImg] = useState(false);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    //console.log('click');
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const { files } = event.target;
    //console.log(event.target.files);
    const uploadedFile = files[0];
    console.log(uploadedFile);
    const extension = uploadedFile.name.split(".").pop();
    let imgName = "";
    if (screen.includes("users")) {
      imgName = `profile_${getUnixTime(new Date())}.${extension}`;
    } else if (screen.includes("vehicles")) {
      imgName = `vehicle_${getUnixTime(new Date())}.${extension}`;
    } else if (screen.includes("login") || screen.includes("organization")) {
      imgName = `logo_${getUnixTime(new Date())}.${extension}`;
    }
    if (uploadedFile) {
      setPhoto({
        id: Date.now(),
        file: uploadedFile,
        url: URL.createObjectURL(uploadedFile),
        extension: extension,
        imgName: imgName,
      });
    }

    // const { length, ...filesObj } = files;
    // //console.log(filesObj, length);
    // let uploadedFilesArr = [];
    // Object.keys(filesObj).map((key) => {
    //   uploadedFilesArr.push({
    //     id: Date.now() + key,
    //     file: filesObj[key],
    //     url: URL.createObjectURL(filesObj[key]),
    //   });
    // });

    // setImages((prev) => {
    //   //console.log(prev);
    //   //console.log(uploadedFilesArr);
    //   const temp = [...prev, ...uploadedFilesArr];
    //   return temp;
    // });
  };

  return (
    <>
      {/* {showZoomedImg && (
        <ZoomedDocPopup
          showPopup={showZoomedImg}
          setShowPopup={setShowZoomedImg}
          img={photo}
        />
      )} */}
      {showZoomedImg && (
        <ZoomModal
          showModal={showZoomedImg}
          setShowModal={setShowZoomedImg}
          doc={photo}
        />
      )}
      <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
        {photo ? (
          <PhotoUploadContainer
            id="img-upload-container"
            //disabled={isDisabled}
          >
            <ImgContainer id="img-container">
              <img
                src={photo.url}
                alt="user-photo"
                width={120}
                height={120}
                className="rounded-circle"
                id="img"
                style={{ objectFit: "cover" }}
              />
              <OverlayContainer
              //disabled={isDisabled}
              >
                <IconContainer>
                  <i
                    className="fi fi-rr-zoom-in"
                    onClick={() => setShowZoomedImg(true)}
                    id="zoom-in-img-icon"
                  ></i>
                  {!isDisabled && (
                    <i
                      className="fi fi-rr-cross-circle"
                      onClick={() => setPhoto("")}
                      id="remove-img-icon"
                    ></i>
                  )}
                </IconContainer>
              </OverlayContainer>
            </ImgContainer>
          </PhotoUploadContainer>
        ) : (
          <PhotoUploadContainer
            onClick={handleClick}
            hoverEffect
            disabled={isDisabled}
            id="img-upload-container"
          >
            <i className="fi fi-br-upload" style={{ color: colorGray }}></i>
            <span>{t("upload_photo")}</span>
          </PhotoUploadContainer>
        )}
        {photo && !isDisabled && (
          <div>
            <FilterButton
              id="img-change-btn"
              type="button"
              onClick={handleClick}
              className="align-self-end"
            >
              {t("change")}
            </FilterButton>
          </div>
        )}
      </div>

      <input
        type="file"
        id="upload-img"
        name="upload-img"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleChange}
        //onClick={handleChange}
        style={{ display: "none" }}
        //multiple
        disabled={isDisabled}
      />
    </>
  );
};

const Option = (props) => {
  // const { tag } = useAuth();
  const { t } = useTranslation("common");
  const { label, value } = props?.data;
  const { tag } = props?.selectProps;
  //console.log("props", props);
  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-start align-items-center">
        <span>{label}</span>
        {(tag === "free-user" || tag === "essential-user") && value == 4 && (
          <SubscribeTooltipIcon message={t("subscribe_to_advanced_msg")} />
        )}
      </div>
    </components.Option>
  );
};

//styles

export const PhotoUploadContainer = styled.div`
  border: 1px solid ${colorLightGray};
  border-radius: 100%;
  flex-direction: column;
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  ${(p) => p.disabled && `background: ${disabledBgColor}`};

  ${(p) =>
    p.hoverEffect &&
    !p.disabled &&
    `
  &:hover {
    background: ${colorLightGray};
  }
  `}
`;

export const DayContainer = styled.div`
  //margin-top: 0.5rem;
  display: flex;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 12px;
  border: 1px solid ${colorLightGray};

  &:hover {
    ${(p) => !p.active && !p.disabled && `background: ${colorLightGray}`}
  }

  ${(p) => p.active && `background: ${colorLightBlueStatus}`}
`;

export const OverlayContainer = styled.div`
  position: absolute;
  border-radius: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: lightgray;

  ${(p) => p.disabled && "display: none"};
`;

export const ImgContainer = styled.div`
  position: relative;
  //max-width: 400px;

  &:hover {
    ${OverlayContainer} {
      opacity: 1;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  i {
    &:hover {
      color: gray;
    }
  }
`;
