import React from "react";
import { Col, Tab, Nav, Container, Row } from "react-bootstrap";
import {
  ColumnHeaderRow,
  DataRow,
  ScrollableRow,
  TableContainer,
} from "../../shared/components/TableElements";
import { NotAssignedVehiclesContainer } from "../../shared/components/table/components/ReactTableConstructor";
import { colorBorder, colorLightGray } from "../../utils/palette";
import { useSelector } from "react-redux";
import { LinkSpan } from "../../shared/components/form/FormElements";
import { useState } from "react";
import NotInspectedVehiclesPopup from "../../shared/components/NotInspectedVehiclesPopup";
import { useTranslation } from "react-i18next";

const MissedInspectionsTable = ({ data }) => {
  const { t } = useTranslation("common");
  const { tableData, totalVehicleCount } = data;
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const [showAllMissedInspections, setShowAllMissedInspections] =
    useState(false);

  return (
    <>
      {showAllMissedInspections && (
        <NotInspectedVehiclesPopup
          showPopup={showAllMissedInspections}
          setShowPopup={setShowAllMissedInspections}
          data={tableData}
        />
      )}
      <h4 className="mb-1">{t("missed_insp_today")}</h4>
      <div
        className="d-flex align-items-center p-2"
        style={{
          fontWeight: 500,
          border: `1px solid ${colorBorder}`,
          borderBottom: "none",
        }}
      >
        {`${tableData.length}/${totalVehicleCount} ${t(
          "vehicles_not_inspected"
        )}`}
      </div>
      <TableContainer>
        <ColumnHeaderRow

        // style={{
        //   border: `1px solid ${colorLightGray}`,
        //   background: colorLightGray,
        // }}
        // className="py-2 mx-0"
        >
          <Col>
            <div>{t("vehicle")}</div>
          </Col>
          <Col>
            <div>{t("operator")}</div>
          </Col>
        </ColumnHeaderRow>

        <ScrollableRow height={"240px"}>
          <Col>
            {tableData.length === 0 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "240px" }}
              >
                {t("no_records")}
              </div>
            )}
            {tableData?.slice(0, 4).map((veh) => {
              return (
                <DataRow key={veh.vehicleId}>
                  <Col>
                    <div>{veh.vehicleName}</div>
                  </Col>
                  <Col>
                    {veh.users.length > 0 ? (
                      <>
                        {veh.users?.map((user, i) => {
                          return <div key={i}>{user.userName}</div>;
                        })}
                      </>
                    ) : (
                      <div>{t("not_assigned")}</div>
                    )}
                  </Col>
                </DataRow>
              );
            })}
          </Col>
        </ScrollableRow>
      </TableContainer>

      {tableData.length > 0 && (
        <div
          className="d-flex align-items-center p-2"
          style={{ border: `1px solid ${colorBorder}`, borderTop: "none" }}
        >
          <LinkSpan
            onClick={() => setShowAllMissedInspections(true)}
            style={{ fontSize: 14 }}
          >
            {t("view_all")}
          </LinkSpan>
        </div>
      )}
    </>
  );
};

export default MissedInspectionsTable;
