import React, { useEffect, useState } from "react";
import { postApi } from "../../../../../api/getUser";
import { Carousel, Col, Modal, Row } from "react-bootstrap";
import { ItemContainer, ItemLabel, ItemValue } from "./";
import {
  colorGray,
  failBgColor,
  failTextColor,
  taskBgColor,
} from "../../../../../utils/palette";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../TableElements";
import styled from "styled-components";
import {
  convertToDisplayTime,
  getDistanceFactorforOdo,
  getDistanceUnit,
} from "../../../../helpers";
import { NATEXT } from "../../../../constants";
import { useSelector } from "react-redux";
import ImageWithZoom from "../../../form/ImageWithZoom";
import ZoomModal from "../../../ZoomModal";

const InspectionSubmissionsRightPanel = ({
  rowData,
  clamped,
  setClamped,
  showReadMoreButton,
  notesRef,
  editData,
  setEditData,
  printData,
  setPrintData,
}) => {
  const { t } = useTranslation("common");
  const [submissionsData, setSubmissionsData] = useState([]);
  const [submissionsEditData, setSubmissionsEditData] = useState([]);
  const [selectedInspectionImgs, setSelectedInspectionImgs] = useState([]);
  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [showImgCarousel, setShowImgCarousel] = useState(false);
  const [imgArrData, setImgArrData] = useState({});

  const [showImgModal, setShowImgModal] = useState(false);

  const { orgId } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);

  useEffect(() => {
    setPrintData({ inspectionId: rowData.inspection_id });
  }, [rowData]);

  useEffect(() => {
    setEditData({
      route: "/inspection/inspectionsubmissions/addnew",
      screen: "inspectionsubmissions",
      data: { rowData, submissionsData: submissionsEditData, imgArrData },
    });
  }, [rowData, submissionsEditData, imgArrData]);

  const {
    form_id,
    inspection_id,
    form_name,
    inspection_by,
    inspection_date,
    start_time,
    end_time,
    task_name,
    user_name,
    signature,
    comments,
    org_id,
    primary_meter,
  } = rowData;

  let imagesData = {};

  useEffect(() => {
    (async () => {
      if (orgId && form_id && inspection_id && distance) {
        let res = await postApi(
          {
            form_id: form_id,
            inspection_id: inspection_id,
            querystring: "getsingleinspection",
          },
          "commonNew"
        );

        if (res.success) {
          const { user_data } = res;
          console.log(user_data);
          setSubmissionsData(user_data);

          if (user_data) {
            let temp = JSON.stringify(user_data);
            temp = JSON.parse(temp);
            temp.forEach((t) => {
              if (t.task_id === `${orgId}_odometer`) {
                let odoValue = t.value;
                const distanceFactor = getDistanceFactorforOdo(
                  primary_meter,
                  distance
                );
                if (+odoValue) {
                  odoValue = (Number(odoValue) / distanceFactor)?.toFixed(2);
                }
                t.value = odoValue;
              }
            });
            setSubmissionsEditData(temp);
          }

          // let temp = user_data.map((t) => {
          //   if (t.task_id === `${orgId}_odometer`) {
          //     let odoValue = t.value;
          //     const distanceFactor = getDistanceFactorforOdo("Miles", distance);
          //     if (+odoValue) {
          //       odoValue = (Number(odoValue) / distanceFactor)?.toFixed(2);
          //     }
          //     t.value = odoValue;
          //   }
          //   return t;
          // });
          // setSubmissionsEditData(temp);
          // temp.forEach((t) => {
          //   if (t.task_id === `${orgId}_odometer`) {
          //     let odoValue = t.value;
          //     const distanceFactor = getDistanceFactorforOdo("Miles", distance);
          //     if (+odoValue) {
          //       odoValue = (Number(odoValue) * distanceFactor)?.toFixed(2);
          //     }
          //     t.value = odoValue;
          //   }
          // });
          // setSubmissionsEditData(temp);
        }
      }
    })();
  }, [inspection_id, form_id, orgId, distance]);

  useEffect(() => {
    if (submissionsData) {
      submissionsData.forEach((sub) => {
        let imagesArr = [];
        let imagesUrl = [];
        if (sub.image && sub.image !== "-1000") {
          imagesArr = sub.image.split(":::");
          //console.log("imagesArr", imagesArr);
          imagesArr.forEach((img) => {
            const extension = img?.split(".")?.pop();
            imagesUrl.push({
              url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${org_id}/inspections/${inspection_id}/${img}`,
              inspectionId: inspection_id,
              imgName: img,
              extension,
            });
          });
          imagesData[sub.inspection_detail_id] = imagesUrl;
          imagesArr = [];
          imagesUrl = [];
        }
      });
      setImgArrData(imagesData);

      // let temp = [...submissionsData];

      // temp = temp.map((t) => {
      //   if (t.task_id === `${orgId}_odometer`) {
      //     let odoValue = t.value;
      //     const distanceFactor = getDistanceFactorforOdo("Miles", distance);
      //     if (+odoValue) {
      //       odoValue = (Number(odoValue) / distanceFactor)?.toFixed(2);
      //     }
      //     t.value = odoValue;
      //   }
      //   return t;
      // });
      //setSubmissionsEditData(temp);
    }
  }, [submissionsData]);

  return (
    <>
      {showImgCarousel && (
        <ZoomModal
          showModal={showImgCarousel}
          setShowModal={setShowImgCarousel}
          docs={selectedInspectionImgs}
          modalHeading={selectedTaskName}
        />
      )}
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>
              {new Date(inspection_date * 1000).toLocaleDateString(
                navigator.language,
                {
                  day: "numeric",
                  year: "numeric",
                  month: "short",
                }
              )}
            </ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("inspection_by")}</ItemLabel>
            <ItemValue>{user_name}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("start_time")}</ItemLabel>
            <ItemValue>
              {/* {new Date(1000 * start_time).toLocaleTimeString()} */}
              {convertToDisplayTime(new Date(1000 * start_time))}
            </ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("end_time")}</ItemLabel>
            <ItemValue>
              {/* {new Date(1000 * end_time).toLocaleTimeString()} */}
              {convertToDisplayTime(new Date(1000 * end_time))}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      {submissionsData.map((sub, i) => {
        return (
          <div
            key={i}
            className="my-1 p-2"
            style={{
              background: sub.pass === 0 ? failBgColor : taskBgColor,
              borderRadius: "5px",
            }}
          >
            <Row>
              <Col md={6} className="d-flex flex-column">
                <span style={{ fontWeight: 400 }}>{sub.task_name}</span>
              </Col>
              <Col md={4}>
                {sub.pass === 1 ? (
                  <span
                    style={{
                      color: "#4DC78E",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {t("inspection_pass")}
                  </span>
                ) : sub.pass === 0 ? (
                  <span
                    style={{
                      color: failTextColor,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {t("inspection_fail")}
                  </span>
                ) : sub.pass === 2 ? (
                  <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                    {t("not_inspected")}
                  </span>
                ) : (
                  ""
                )}
              </Col>
              <Col md={2} className="d-flex align-items-center">
                {sub.image && sub.image !== "-1000" && (
                  <ImageIcon
                    className="fi fi-rr-picture"
                    onClick={() => {
                      const imagesArr = imgArrData[sub.inspection_detail_id];
                      if (imagesArr && imagesArr?.length > 0) {
                        setSelectedInspectionImgs(imagesArr);
                        setSelectedTaskName(sub.task_name);
                      }
                      setShowImgCarousel(true);
                    }}
                    //style={{ marginRight: "8px" }}
                  ></ImageIcon>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {sub.value && sub.value !== "-1000" && (
                  <span style={{ fontSize: "14px" }}>
                    {sub.value}{" "}
                    {sub.task_id === `${orgId}_odometer`
                      ? getDistanceUnit(
                          primary_meter === "Hours" ? "Hours" : distance
                        )
                      : ""}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {sub.comments && (
                  <span style={{ fontWeight: 300 }}>
                    <Icon className="fi fi-rr-comment"></Icon>
                    {sub.comments}
                  </span>
                )}
              </Col>
            </Row>
          </div>
        );
      })}

      {signature && signature !== NATEXT && (
        <Row>
          <Col>
            <ItemContainer>
              <ItemLabel>{t("signature")}</ItemLabel>
              <ItemValue>
                {/* <ImageWithZoomContainer
                  onClick={() => handleImgClick(signature)}
                  onMouseEnter={() =>
                    setImgHovered({
                      hovered: true,
                      url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`,
                    })
                  }
                  onMouseLeave={() =>
                    setImgHovered({ hovered: false, url: "" })
                  }
                  isHovered={
                    imgHovered?.hovered &&
                    imgHovered?.url ===
                      `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`
                  }
                >
                  <img
                    src={`https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`}
                    alt="sign-image"
                    className="img-thumbnail"
                    style={{
                      width: "120px",
                      height: "80px",
                    }}
                    //onClick={() => handleImgClick(img)}
                    //key={item.id}
                    //onClick={() => handleImageClick(item.url)}
                  />
                  <i className="fi fi-rr-zoom-in"></i>
                </ImageWithZoomContainer>
                {showImgModal && (
                  <ImgModal
                    showImgModal={showImgModal}
                    setShowImgModal={setShowImgModal}
                    currentImg={{
                      url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`,
                    }}
                    //setCurrentImg={setCurrentImg}
                  />
                )} */}
                <ImageWithZoom
                  //key={img.id}
                  img={{
                    url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/inspections/${inspection_id}/${signature}`,
                  }}
                  //handleDeleteImg={handleDeleteImg}
                  shouldDelete={false}
                />
              </ItemValue>
            </ItemContainer>
          </Col>
        </Row>
      )}

      {comments && comments !== NATEXT && (
        <Row>
          <Col>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        </Row>
      )}
    </>
  );
};

export default InspectionSubmissionsRightPanel;

//styles

export const ImageIcon = styled.i`
  cursor: pointer;
  color: ${colorGray};
  //margin-right: 8px;
  position: relative;
  top: 2px;

  &:hover {
    color: #c6c6c6;
  }
`;
