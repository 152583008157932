import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SortUpAngleIcon } from "@/shared/img/angle-small-up.svg";
import { ReactComponent as SortDownAngleIcon } from "@/shared/img/angle-small-down.svg";
import { colorGray, colorTableBorder } from "../../../../utils/palette";
import styled from "styled-components";

const Header = ({ column, isSortable }) => {
  const [isColumnHovered, setIsColumnHovered] = useState(false);

  let parentColumnStyles = {};
  if (column.isParent) {
    parentColumnStyles = {
      display: "flex",
      justifyContent: "center",
      //width: '600px',
      //marginRight: `${window.innerWidth - 0.86 * window.innerWidth}px`, //'160px'
    };
  }

  return (
    <ColumnHeaderContainer
      hovered={isColumnHovered && isSortable && column.canSort}
      className="react-table__column-header"
      onMouseEnter={() => setIsColumnHovered(true)}
      onMouseLeave={() => setIsColumnHovered(false)}
      //style={parentColumnStyles}
      isCenterAligned={column.isCenterAligned}
    >
      <span className={isSortable ? "react-table__column-header" : ""}>
        {column.render("Header")}
      </span>
      {isSortable && column.canSort && (
        <Sorting column={column} isColumnHovered={isColumnHovered} />
      )}
    </ColumnHeaderContainer>
  );
};

Header.propTypes = {
  column: PropTypes.shape({
    //Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
};

const Sorting = ({ column, isColumnHovered }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <span>
        {isColumnHovered ? (
          <SortUpAngleIcon fill={colorGray} />
        ) : (
          <SortUpAngleIcon fill="white" />
        )}
      </span>
    ) : (
      <span>
        {column.isSortedDesc ? <SortDownAngleIcon /> : <SortUpAngleIcon />}
      </span>
    )}
  </span>
);

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
};

const getStylesResizable = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const ReactTableHeader = ({ headerGroups, isResizable, isSortable }) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return [props];
  };
  const headerPropsResize = (props, { column }) => {
    if (column.getResizerProps && isResizable) {
      return getStylesResizable(props, column.align);
    }
    return [props];
  };

  return (
    <thead
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        margin: 0,
        padding: 0,
      }}
    >
      {headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          className="react-table thead tr"
          style={{
            //borderRight: column.isBorderReqd ? "1px solid black" : "none",
            borderBottom: "none",
            //borderLeft: column.isBorderReqd ? "1px solid lightgray" : "none",
          }}
        >
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(headerPropsSortable)}
              {...column.getHeaderProps(headerPropsResize)}
              {...column.getHeaderProps()}
              style={{
                borderBottom: "none",
                borderLeft: column.isBorderReqd
                  ? `1px solid ${colorTableBorder}`
                  : "none",
                //paddingLeft: column.isBorderReqd ? "32px" : "0",
              }}
            >
              <Header
                column={column}
                isSortable={isSortable}
                isCenterAligned={column.isCenterAligned}
              />
              {isResizable && (
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing && "isResizing"}`}
                />
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    })
  ).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
};

export default ReactTableHeader;

//styles

export const ColumnHeaderContainer = styled.span`
  //margin-left: 3px;
  font-weight: 500;
  height: 20px;
  //font-size: 15px;

  ${(p) =>
    p.isCenterAligned === true
      ? `justify-content: center`
      : `justify-content: start`};
  ${(p) =>
    p.hovered &&
    `
    cursor: pointer;
  span{
    color: ${colorGray}!important;
}`}
`;
