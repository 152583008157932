import React, { useState } from "react";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "./TableElements";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  buttonColorNew,
  colorBorder,
  colorGray,
  colorLightGray,
  sidenavColor,
  sidenavLinkTextColor,
} from "../../utils/palette";
import { useAuth } from "../../contexts/AuthContext";
import { postApi } from "../../api/getUser";

const RatingPopup = () => {
  const { setShowRatingPopup } = useAuth();
  const { t } = useTranslation("common");
  const valuesArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [rating, setRating] = useState(null);
  const [reason, setReason] = useState("");
  //const [submitted, setSubmitted] = useState(false);

  const [mode, setMode] = useState("no-rating");

  const [closeLoading, setCloseLoading] = useState(false);

  const handleSubmit = async () => {
    const apiData = {
      survey_type: 1,
      score: rating,
      reason: reason,
      querystring: "savefeedbackrating",
      last_shown: Math.floor(Date.now() / 1000),
    };

    const res = await postApi(apiData, "commonNew");
    setMode("submitted");
    //setShowRatingPopup(false);
  };

  const handleClose = async () => {
    // if (!rating) {
    setCloseLoading(true);
    const apiData = {
      survey_type: 1,
      querystring: "updatefeedbackrating",
      last_shown: Math.floor(Date.now() / 1000),
    };

    const res = await postApi(apiData, "commonNew");
    setCloseLoading(false);
    // }
    setShowRatingPopup(false);
  };

  const RatingNumberComponent = ({ value }) => {
    const handleRatingNoClick = async () => {
      const apiData = {
        survey_type: 1,
        score: value,
        reason: reason,
        querystring: "savefeedbackrating",
        last_shown: Math.floor(Date.now() / 1000),
      };

      const res = await postApi(apiData, "commonNew");
      setRating(value);
      setMode("rating");
    };

    return (
      <RatingNoContainer
        id={value}
        type="button"
        //onClick={handleRatingClick}
        onClick={handleRatingNoClick}
        //value={value}
      >
        <p style={{ color: sidenavLinkTextColor }}>{value}</p>
      </RatingNoContainer>
    );
  };

  return (
    <RatingPopupContainer>
      <div className="d-flex justify-content-end">
        {closeLoading ? (
          <CustomLoader />
        ) : (
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        )}
      </div>
      {/* Body */}
      {/* {rating ? (
        submitted ? (
          <div>
            <p>{t("thanks_for_feedback_msg")}</p>
          </div>
        ) : (
          <div className="d-flex flex-column gap-3">
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <AddFormButton onClick={handleSubmit} type="button">
              {t("submit")}
            </AddFormButton>
          </div>
        )
      ) : (
        <div className="d-flex flex-column gap-3">
          <div>
            <p style={{ color: sidenavLinkTextColor }}>
              {t("rating_popup_msg")}
            </p>
          </div>
          <div className="d-flex gap-2">
            {valuesArr.map((no) => (
              <RatingNumberComponent value={no} key={no} />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ color: sidenavLinkTextColor }}>Not Likely</span>
            <span style={{ color: sidenavLinkTextColor }}>Very Likely</span>
          </div>
        </div>
      )} */}

      {mode === "no-rating" && (
        <div className="d-flex flex-column gap-3">
          <div>
            <p style={{ color: sidenavLinkTextColor, fontWeight: 500 }}>
              {t("rating_popup_msg")}
            </p>
          </div>
          <div className="d-flex gap-2">
            {valuesArr.map((no) => (
              <RatingNumberComponent value={no} key={no} />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ color: sidenavLinkTextColor, fontSize: "12px" }}>
              Not Likely
            </span>
            <span style={{ color: sidenavLinkTextColor, fontSize: "12px" }}>
              Very Likely
            </span>
          </div>
        </div>
      )}

      {mode === "rating" && (
        <div className="d-flex flex-column gap-3">
          <p style={{ color: sidenavLinkTextColor }}>
            What is the primary reason for your score?
          </p>
          <textarea
            style={{ height: 100 }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="d-flex justify-content-center">
            <AddFormButton
              onClick={handleSubmit}
              type="button"
              style={{ width: "min-content" }}
            >
              {t("submit")}
            </AddFormButton>
          </div>
        </div>
      )}

      {mode === "submitted" && (
        <div className="d-flex justify-content-center align-items-center p-2">
          <p style={{ color: sidenavLinkTextColor }}>
            {t("thanks_for_feedback_msg")}
          </p>
        </div>
      )}
    </RatingPopupContainer>
  );
};

export default RatingPopup;

//styles
const RatingNoContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  background: ${buttonColorNew};
  &:hover {
    background: ${colorGray};
  }
`;

const RatingPopupContainer = styled.div`
  padding: 12px;
  height: auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: ${sidenavColor}; //#d8e6ed;
  bottom: 0%;
  right: 7%;
  border: 1px solid;
  border-radius: 5px;
  transition: "height 2s";
  overflow: hidden;
`;
