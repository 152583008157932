import React from "react";
import { Route, Switch } from "react-router-dom";
import MaintenanceReminders from "./MaintenanceReminders";
import Renewals from "./Renewals";

export default () => (
  <Switch>
    <Route
      path="/reminders/maintenancereminders"
      component={MaintenanceReminders}
    />
    <Route path="/reminders/renewals" component={Renewals} />
  </Switch>
);
