import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  colorGray,
  colorLightGray,
  disabledBgColor,
  taskBgColor,
} from "../../../utils/palette";
import { useLocation } from "react-router";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../SubscribePopup";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import ImageWithZoom from "./ImageWithZoom";
import { LinkSpan } from "./FormElements";
import ZoomModal from "../ZoomModal";

const FileUploaderNew = ({
  handleFileUpload,
  disabled,
  images,
  setImages,
  shouldDelete,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const location = useLocation();
  const screen = location.pathname;
  const { t } = useTranslation("common");

  //State to maintain allowed file types for Daily Time Sheet
  const [allowedFileTypes, setAllowedFileTypes] = useState(
    "image/*, .doc, .docx, .pdf"
  );

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  useEffect(() => {
    if (screen?.includes("dailytimesheet")) {
      console.log(images);
      if (images?.length > 0) {
        if (images[0].file?.type?.includes("image")) {
          setAllowedFileTypes("image/*");
        }
      } else {
        setAllowedFileTypes("image/*, .doc, .docx, .pdf");
      }
    }
  }, [images]);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    //console.log('click');
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (files) => {
    console.log("tag", tag);
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    //console.log(event);
    //const { files } = event.target;
    //console.log(event.target.files);
    //const fileUploaded = event.target.files[0];
    const { length, ...filesObj } = files;

    //console.log(filesObj, length);
    let uploadedFilesArr = [];
    Object.keys(filesObj).map((key) => {
      const extension = filesObj[key].name.split(".").pop();
      uploadedFilesArr.push({
        id: Date.now() + key,
        file: filesObj[key],
        url: URL.createObjectURL(filesObj[key]),
        extension,
      });
    });

    setImages((prev) => {
      //console.log(prev);
      //console.log(uploadedFilesArr);
      const temp = [...prev, ...uploadedFilesArr];
      return temp;
    });

    //handleFileUpload(files);
  };

  const handleImgClick = (img) => {
    setSelectedDoc(img);
    setShowModal(true);
  };
  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}

      {showModal && (
        <ZoomModal
          showModal={showModal}
          setShowModal={setShowModal}
          doc={selectedDoc}
        />
      )}

      <Dropzone
        onDrop={handleChange}
        multiple={true}
        //accept="image/*"
        accept={{
          "image/*": [".jpg", ".jpeg", ".png", ".jfif"],
          "application/pdf": [".pdf", ".doc", ".docx"],
        }}
        noClick
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <FileUploaderContainer
            {...getRootProps({ className: "dropzone" })}
            $disabled={tag === "free-user" || disabled}
          >
            {/* <input {...getInputProps()} /> */}
            {images?.map((img) => {
              return (
                <ImageWithZoom
                  key={img.id}
                  img={img}
                  //handleDeleteImg={handleDeleteImg}
                  images={images}
                  setImages={setImages}
                  shouldDelete={shouldDelete}
                  fromDropzone={true} //If fromDropzone is true, then image modal should be shown separately outside Dropzone since click events like zoom in, zoom out,... dont work inside Dropzone
                  imgClickHandler={() => handleImgClick(img)}
                />
              );
            })}
            {/* <Dropzone
              onDrop={handleChange}
              multiple={true}
              //accept="image/*"
              accept={{
                "image/*": [".jpg", ".jpeg", ".png"],
                "application/pdf": [".pdf", ".doc", ".docx"],
              }}
              noDragEventsBubbling
              disabled={disabled}
            >
              {({ getRootProps, getInputProps }) => ( */}
            <div
              //{...getRootProps({ className: "dropzone" })}
              style={{
                width: "-webkit-fill-available",
                height: "-webkit-fill-available",
              }}
              className="d-flex align-items-center"
            >
              {/* <input {...getInputProps()} /> */}

              {images?.length === 0 && disabled && (
                <div
                  className="d-flex justify-content-center align-items-center gap-1"
                  style={{
                    width: "-webkit-fill-available",
                    height: "-webkit-fill-available",
                  }}
                >
                  <p>{t("no_records")}</p>
                </div>
              )}

              {!disabled && (
                <>
                  {images?.length === 0 ? (
                    <div
                      className="d-flex justify-content-center align-items-center gap-1"
                      style={{
                        width: "-webkit-fill-available",
                        height: "-webkit-fill-available",
                      }}
                    >
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {t("drag_and_drop_files_here")}
                        </p>
                        <LinkSpan
                          style={{ fontSize: "12px" }}
                          onClick={handleClick}
                        >
                          {`${t("or")} ${t("click_to_select_file")}`}
                        </LinkSpan>
                      </>
                    </div>
                  ) : (
                    <AddImgIcon
                      className="fi fi-rr-add"
                      onClick={handleClick}
                      disabled={disabled}
                    ></AddImgIcon>
                  )}
                  <input
                    type="file"
                    id="upload-img"
                    name="upload-img"
                    accept={
                      screen.includes("dailytimesheet")
                        ? allowedFileTypes
                        : "image/*, .doc, .docx, .pdf"
                    }
                    ref={hiddenFileInput}
                    onChange={(e) => handleChange(e.target.files)}
                    //onClick={handleChange}
                    style={{ display: "none" }}
                    multiple={true}
                    disabled={disabled}
                  />
                </>
              )}
            </div>
          </FileUploaderContainer>
        )}
      </Dropzone>
    </>
  );
};

export default FileUploaderNew;

//styles

export const AddImgIcon = styled.i`
  font-size: 30px;
  position: relative;
  top: 4px;
  color: ${colorGray};

  ${(p) =>
    !p.disabled &&
    `
    cursor: pointer;
    &:hover {
    color: ${colorLightGray};
    }
`};

  ${(p) =>
    p.disabled &&
    `
      //color: ${colorLightGray};
      cursor: not-allowed
    `};
`;

const FileUploaderContainer = styled.div`
  display: flex;
  padding: 0 0.5rem;
  overflow-y: auto;
  justify-content: start;
  align-items: center;
  gap: 8px;
  border: 1px dashed ${colorLightGray};
  border-radius: 5px;
  background: #f2f4f7; //${taskBgColor};
  //width: 400px;
  height: 120px;
`;
