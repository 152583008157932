import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  FilterButton,
} from "../../../shared/components/TableElements";
import styled from "styled-components";
import {
  colorGray,
  colorLightGray,
  colorStatusActive,
  scrollbarStyles,
  taskBgColor,
} from "../../../utils/palette";
import { getDisplayDateWithTimeNew } from "../../../shared/helpers";
import getUnixTime from "date-fns/getUnixTime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormGroupLabel } from "../../../shared/components/form/FormElements";

const LogsPopup = ({
  logs,
  setLogs,
  showPopup,
  setShowPopup,
  user,
  readOnly,
  assocIssues,
}) => {
  const { t } = useTranslation("common");
  const { userName, userId } = useSelector((state) => state.user);

  const [note, setNote] = useState("");
  //Will contain selected issue ids
  const [selectedIssues, setSelectedIssues] = useState([]);

  const handleAddNote = () => {
    if (note) {
      let linkedIssueNames = [];
      if (selectedIssues.length > 0) {
        selectedIssues.forEach((issueId) => {
          const issueSelected = assocIssues.find((i) => i.issue_id === issueId);
          if (issueSelected) {
            linkedIssueNames.push(issueSelected?.issue);
          }
        });
      }
      setLogs((prev) => {
        let temp = [...prev];
        temp.unshift({
          log_id: `${userId}_${Date.now()}`,
          time_stamp: getUnixTime(new Date()),
          message: note,
          user: userName,
          user_id: userId,
          log_type: 0,
          linkedIssues: linkedIssueNames,
        });
        return temp;
      });
      setNote("");
      setSelectedIssues([]);
    }
  };

  const handleEditLog = (logId) => {
    setLogs((prev) => {
      let temp = [...prev];
      temp.forEach((log) => {
        if (log.log_id === logId) {
          log.isEdit = true;
        }
      });
      return temp;
    });
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleIssueClick = (issueId) => {
    setSelectedIssues((prev) => {
      if (prev.includes(issueId)) {
        return prev.filter((n) => n !== issueId);
      } else {
        let temp = [...prev];
        temp.push(issueId);
        //temp[key - 1] = key;
        return temp;
      }
    });
  };

  return (
    <Modal show={showPopup} size="lg" onHide={() => setShowPopup(false)}>
      <Modal.Header>
        <h4>{t("notes_prog_updates")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ScrollableDiv $readOnly={readOnly}>
          {logs.length === 0 ? (
            <span
              className="d-flex justify-content-center align-items-center"
              style={{ height: "-webkit-fill-available" }}
            >
              {t("no_notes")}
            </span>
          ) : (
            <>
              {logs.map((log) => (
                <LogDiv key={log.log_id}>
                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          fontWeight: 300,
                          alignSelf: "center",
                        }}
                      >{`${getDisplayDateWithTimeNew(log.time_stamp)} - ${
                        log.user
                      }`}</p>

                      {(log.log_type == 1 ||
                        log.log_type == 2 ||
                        log.log_type == 3 ||
                        log.log_type == 4 ||
                        log.log_type == 5) && (
                        <div>
                          {log.log_type == 1 && (
                            <div className="d-flex gap-1">
                              <i
                                className="fi fi-rr-add"
                                style={{ position: "relative", top: "2px" }}
                              ></i>
                              <span>{t("wo_created")}</span>
                            </div>
                          )}
                          {log.log_type == 2 && (
                            <div className="d-flex gap-1">
                              <i
                                className="fi fi-rs-check-circle"
                                style={{ position: "relative", top: "2px" }}
                              ></i>
                              <span>{t("wo_accepted")}</span>
                            </div>
                          )}
                          {log.log_type == 3 && (
                            <div className="d-flex gap-1">
                              <i
                                className="fi fi-rr-pause-circle"
                                style={{ position: "relative", top: "2px" }}
                              ></i>
                              <span>{t("wo_paused")}</span>
                            </div>
                          )}
                          {log.log_type == 4 && (
                            <div className="d-flex gap-1">
                              <i
                                className="fi fi-rr-play-circle"
                                style={{ position: "relative", top: "2px" }}
                              ></i>
                              <span>{t("wo_resumed")}</span>
                            </div>
                          )}
                          {log.log_type == 5 && (
                            <div className="d-flex gap-1 align-items-center">
                              <i
                                className="fi fi-rr-stop-circle"
                                style={{ position: "relative", top: "2px" }}
                              ></i>
                              <span>{t("wo_closed")}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {log?.message && <p>{log.message}</p>}
                    {log?.log_type === 0 &&
                      log?.linkedIssues &&
                      log?.linkedIssues?.length > 0 && (
                        <div className="d-flex gap-2 flex-wrap">
                          {log.linkedIssues.map((issueName, i) => (
                            <ReadOnlyIssueTag key={i}>
                              <span>{issueName}</span>
                            </ReadOnlyIssueTag>
                          ))}
                        </div>
                      )}
                  </div>
                </LogDiv>
              ))}
            </>
          )}
        </ScrollableDiv>
        {!readOnly && (
          <>
            <TextAreaDiv $disabled={!note}>
              <textarea
                placeholder={t("add_note_prog_update")}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              {/* <i
              className="fi fi-rr-comment-medical"
              onClick={handleAddNote}
              //$disabled={!note}
              ></i> */}
            </TextAreaDiv>
            {assocIssues?.length > 0 && (
              <div>
                <FormGroupLabel>{t("logs_relevant_issue")}</FormGroupLabel>
                <HorScrollDiv>
                  {assocIssues.map((issue) => (
                    <IssueTag
                      key={issue.issue_id}
                      type="checkbox"
                      active={selectedIssues?.includes(issue?.issue_id)}
                      onClick={() => handleIssueClick(issue?.issue_id)}
                      //disabled={}
                    >
                      <span>{issue?.issue}</span>
                    </IssueTag>
                  ))}
                </HorScrollDiv>
              </div>
            )}
            <div className="d-flex justify-content-end mt-3">
              <AddFormButton
                type="button"
                disabled={!note}
                onClick={handleAddNote}
              >
                {t("submit")}
              </AddFormButton>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LogsPopup;

//styles

const ScrollableDiv = styled.div`
  height: ${(p) => (p.$readOnly ? "400px" : "310px")};
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${scrollbarStyles}
`;

const LogDiv = styled.div`
  border-radius: 5px;
  background: ${taskBgColor};
  padding: 0.5rem;
`;

const TextAreaDiv = styled.div`
  border: 1px solid ${colorLightGray};
  margin-top: 1rem;
  border-radius: 5px;
  display: flex;
  padding: 0.25rem;

  textarea {
    border: none;
    outline: none;
    width: auto;
    resize: none;
    width: -webkit-fill-available;
  }

  i {
    font-size: 24px;
    position: relative;
    top: 8px;
    align-self: end;
    color: ${colorGray};
    ${(p) => p.$disabled && `color: ${colorLightGray}`};

    ${(p) =>
      !p.$disabled &&
      ` cursor: pointer;
    &:hover {
      color: ${colorLightGray};
    }`};
  }
`;

const IssueTag = styled.div`
  //margin-top: 0.5rem;
  display: flex;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${colorLightGray};

  span {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    ${(p) => !p.active && !p.disabled && `background: ${colorLightGray}`}
  }

  ${(p) => p.active && `background: ${colorStatusActive}`}
`;

const HorScrollDiv = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  ${scrollbarStyles}
`;

const ReadOnlyIssueTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${colorLightGray};
  background: ${colorStatusActive};
  span {
    white-space: nowrap;
    //max-width: 120px;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
`;
