import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { buttonColorNew, colorLightGray } from "../../../../utils/palette";
import { useState } from "react";
import { AddFormButton } from "../../TableElements";
import { Col, Row } from "react-bootstrap";
import { convertToDisplayDate, convertToFilterDate, getDateFnsLocale } from "../../../helpers";
import { addFilterDate } from "../../../../features/filter/filterSlice";
import { useDispatch } from "react-redux";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import startOfYesterday from "date-fns/startOfYesterday";
import endOfYesterday from "date-fns/endOfYesterday";
import startOfMonth from "date-fns/startOfMonth";
import subDays from "date-fns/subDays";
import startOfYear from "date-fns/startOfYear";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
//import { endOfToday, endOfYesterday, getUnixTime, startOfToday, startOfYesterday } from "date-fns";

const ExtDateFilter = ({
  submissionsData,
  setSubmissionsData,
  isDateFilterOpen,
  setIsDateFilterOpen,
  dailyTSFilters,
  setDailyTSFilters,
}) => {
  const { t } = useTranslation("common");
  const { minDate } = useSelector((state) => state.columns);
  const dispatch = useDispatch();
  const location = useLocation();
  const screen = location.pathname;
  const dateOptionsArr = [
    {
      label: t("today"),
      value: "today",
    },
    {
      label: t("yesterday"),
      value: "yesterday",
    },
    {
      label: t("last_7_days"),
      value: "last-7-days",
    },
    {
      label: t("last_30_days"),
      value: "last-30-days",
    },
    {
      label: t("last_90_days"),
      value: "last-90-days",
    },
    {
      label: t("this_month"),
      value: "this-month",
    },
    {
      label: t("this_year"),
      value: "this-year",
    },
    {
      label: t("all_time"),
      value: "all-time",
    },
    {
      label: t("custom"),
      value: "custom",
    },
  ];

  const dateOptionsObj = {
    today: t("today"),

    yesterday: t("yesterday"),

    "last-7-days": t("last_7_days"),

    "last-30-days": t("last_30_days"),

    "last-90-days": t("last_90_days"),

    "this-month": t("this_month"),

    "this-year": t("this_year"),
    "all-time": t("all_time"),

    custom: t("custom"),
  };

  //Logic to get warning text in inspection submissions
  const getWarningText = () => {
    const { dateOption } = submissionsData;
    if (dateOption === "last-7-days") {
      return `${t("in")} ${t("the")} ${t("last_7_days_sm")}`;
    } else if (dateOption === "last-30-days") {
      return `${t("in")} ${t("the")} ${t("last_30_days_sm")}`;
    } else if (dateOption === "last-90-days") {
      return `${t("in")} ${t("the")} ${t("last_90_days_sm")}`;
    } else if (dateOption === "this-year") {
      return `${t("this_year_sm")}`;
    } else if (dateOption === "this-month") {
      return `${t("this_month_sm")}`;
    } else if (dateOption === "today") {
      return `${t("today_sm")}`;
    } else if (dateOption === "yesterday") {
      return `${t("yesterday_sm")}`;
    } else if (dateOption === "all-time") {
      return `${t("all_time")}`;
    } else if (dateOption === "custom") {
      return `from ${convertToDisplayDate(
        submissionsData?.from
      )} - ${convertToDisplayDate(submissionsData?.to)}`;
    }
  };

  const handleApply = () => {
    setSubmissionsData((prev) => ({
      ...prev,
      filterText:
        submissionsData?.dateOption === "custom"
          ? `${convertToFilterDate(
              submissionsData.from
            )} - ${convertToFilterDate(submissionsData.to)}`
          : dateOptionsObj[submissionsData?.dateOption],
      warningText: getWarningText(),
    }));
    setIsDateFilterOpen((prev) => !prev);
    if (submissionsData.from && submissionsData.to) {
      const startDate = Math.round(submissionsData.from / 1000);
      const endDate = Math.round(submissionsData.to / 1000);

      if (screen?.includes("inspectionsubmissions")) {
        dispatch(addFilterDate({ start: startDate, end: endDate }));
      } else if (screen?.includes("dailytimesheet")) {
        setDailyTSFilters((prev) => {
          let temp = prev.filter((f) => f.type !== "date");
          temp.push({ type: "date", data: { startDate, endDate } });
          return temp;
        });
      }
    }
  };

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
    getCurrentDates(submissionsData.dateOption);
  }, []);

  useEffect(() => {
    setSelected(null);
  }, [submissionsData.dateOption]);

  function getCurrentDates(value) {
    if (value === "last-7-days") {
      const end = new Date();
      //let start = new Date();
      //start.setDate(start.getDate() - 7);
      const start = subDays(new Date(), 6);
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "this-month") {
      const end = new Date();
      //const start = new Date().setDate(1);
      const start = startOfMonth(new Date());
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "today") {
      //const end = new Date();
      //const start = new Date();
      const start = startOfToday();
      const end = endOfToday();
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "last-30-days") {
      const end = new Date();
      const start = subDays(new Date(), 29);
      //let start = new Date();
      //start.setDate(start.getDate() - 30);
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "yesterday") {
      //const end = new Date();
      //const start = new Date();
      const start = startOfYesterday();
      const end = endOfYesterday();
      //start.setDate(start.getDate() - 1);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "last-90-days") {
      const end = new Date();
      //let start = new Date();
      //start.setDate(start.getDate() - 90);
      const start = subDays(new Date(), 89);
      //setData((prev) => ({ ...prev, from: start, to: end }));
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "this-year") {
      const end = new Date();
      const start = startOfYear(new Date());
      //let start = new Date(new Date().getFullYear(), 0, 1);
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "all-time") {
      const end = new Date();
      const start = new Date(minDate * 1000);
      //let start = new Date(new Date().getFullYear(), 0, 1);
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setSubmissionsData((prev) => ({
        ...prev,
        from: start,
        to: end,
        dateOption: value,
      }));
      setSelected(start);
    } else if (value === "custom") {
      //const end = new Date();
      setSelected(null);
      let start = new Date();
      //start.setDate(start.getDate() - (start.getDay() + 7));
      setSubmissionsData((prev) => ({
        ...prev,
        //from: start,
        //to: start,
        dateOption: value,
      }));
    }
  }

  const handleDateOptionClick = (option) => {
    //setSelectedDateOption(option.value);
    getCurrentDates(option.value);
  };

  const handleDateChange = (selected) => {
    console.log(selected);
    const [start, end] = selected;
    setSubmissionsData((prev) => ({
      ...prev,
      from: start,
      to: end,
      dateOption: "custom",
    }));
    //setSelectedDateOption("custom");
  };

  return (
    <>
      <DateFilterContainer>
        {/* <DateOptions
        //dateOption={dateOption}
        //setDateOption={setDateOption}
        data={data}
        handleDateOptionChange={(e) =>
          setData((prev) => ({ ...prev, dateOption: e.target.value }))
        }
      />
      <div>
        <span>From</span>
        <DatePicker
          className="col-xl-12"
          dateFormat={"MM/dd/yyyy"}
          name="From"
          selected={data.from}
          onChange={(date) =>
            setData((prev) => ({ ...prev, from: date, dateOption: "custom" }))
          }
          customInput={<CustomInput />}
        />
      </div>
      <div className="w-100">
        <span>To</span>
        <DatePicker
          name="To"
          //minDate={new Date()}
          //minDate={new Date(data.from*1000)}
          dateFormat={"MM/dd/yyyy"}
          selected={data.to}
          onChange={(date) =>
            setData((prev) => ({ ...prev, to: date, dateOption: "custom" }))
          }
          customInput={<CustomInput />}
        />
      </div>
      {isError && <ErrorText>{errorMessage}</ErrorText>}
      <div className="d-flex align-items-center justify-content-center w-100">
        <AddFormButton>Apply</AddFormButton>
      </div> */}
        <Row
          className="m-0 p-0"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <Col className="m-0 p-1">
            {dateOptionsArr.map((option) => {
              return (
                <DateOptionContainer
                  id={option.value}
                  key={option.value}
                  isSelected={option.value === submissionsData.dateOption}
                  onClick={() => handleDateOptionClick(option)}
                >
                  {option.label}
                </DateOptionContainer>
              );
            })}
          </Col>
          <Col className="m-0 pt-2 px-2">
            <DatePicker
              selected={selected}
              onChange={handleDateChange}
              startDate={submissionsData.from}
              endDate={submissionsData.to}
              selectsRange
              inline
              locale={getDateFnsLocale()}
              dateFormat={"P"}
              showMonthDropdown
              showYearDropdown
            />
          </Col>
        </Row>
        <Row className="m-0 p-2" style={{ width: "-webkit-fill-available" }}>
          <Col sm={12} className="d-flex justify-content-center m-0 p-0">
            <AddFormButton
              onClick={handleApply}
              disabled={!submissionsData.from || !submissionsData.to}
            >
              Apply
            </AddFormButton>
          </Col>
        </Row>
      </DateFilterContainer>
    </>
  );
};

export default ExtDateFilter;

//styles

export const DateFilterContainer = styled.div`
  border: 1px solid ${colorLightGray};
  //padding: 20px;
  background: white;
  position: absolute;
  display: flex;
  //gap: 16px;
  z-index: 100;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`;

export const DateOptionContainer = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  whitespace: nowrap;

  &:hover {
    background: ${colorLightGray};
  }

  &:active {
    background: ${buttonColorNew};
    color: white;
  }

  ${(p) =>
    p.isSelected &&
    `
  background: ${buttonColorNew};
  color: white;
  &:hover {
    background: ${buttonColorNew};
  }
  `};
`;
