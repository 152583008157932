import React from "react";
import { Route, Switch } from "react-router-dom";
import VehiclesTable from "./VehiclesTable";
import VehiclesForm from "./VehiclesForm";

export default () => (
  <Switch>
    <Route exact path="/vehicles" component={VehiclesTable} />
    <Route exact path="/vehicles/addnew" component={VehiclesForm} />
  </Switch>
);
