import React, { useState } from "react";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  LinkSpan,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import { colorBlue, scrollbarStyles } from "../../utils/palette";
import styled from "styled-components";
import { useEffect } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../api/firebase";
import { emailPattern } from "../../shared/helpers";
import InfoPopup from "../../shared/components/InfoPopup";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";

const ForgotPasswordForm = ({ forgotPWData, setForgotPWData }) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");

  const email = watch("email");

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [showResetPWInfoPopup, setShowResetPWInfoPopup] = useState(false);

  //console.log(updatePWData);

  //Set Email
  useEffect(() => {
    if (forgotPWData?.email) {
      setValue("email", forgotPWData?.email);
    }
  }, []);

  const handleCloseInfoPopup = () => {
    setForgotPWData(null);
    setShowResetPWInfoPopup(false);
  };

  const onSubmit = async () => {
    try {
      setSaveBtnLoading(true);
      await sendPasswordResetEmail(auth, email?.toLocaleLowerCase());
      setSaveBtnLoading(false);
      setShowResetPWInfoPopup(true);
    } catch (e) {
      let errorMessage = "";
      console.log(e);
      if (e.code === "auth/user-not-found") {
        errorMessage = t("email_does_not_exist_err_msg");
      } else {
        errorMessage = t("invalid_email");
      }
      setError(
        "email",
        { type: e.code, message: errorMessage },
        { shouldFocus: true }
      );
      setSaveBtnLoading(false);

      setShowResetPWInfoPopup(false);
      return;
    }
  };

  return (
    <>
      {showResetPWInfoPopup && (
        <InfoPopup
          showPopup={showResetPWInfoPopup}
          setShowPopup={setShowResetPWInfoPopup}
          icon={{ className: "fi fi-rr-info", color: colorBlue }}
          message={t("verification_email_sent")}
          onClose={handleCloseInfoPopup}
        />
      )}
      <FormContainerWithScroll
        onSubmit={handleSubmit(onSubmit)}
        //className="d-flex justify-content-center align-items-center"
        id="reset-password-form"
      >
        <Container id="reset-password-form-container">
          <Row className="p-3 d-flex justify-content-center align-items-center">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center"
            >
              <div id="reset-pw-div">
                <h4 id="reset-pw-heading">{t("reset_pw")}</h4>
                <Desc id="reset-pw-desc">{t("link_to_change_pw_msg")}</Desc>
              </div>
              <FilterButton
                id="go-back-btn"
                onClick={() => setForgotPWData(null)}
                type="button"
              >
                {t("go_back_changed")}
              </FilterButton>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <FormGroupLabel id="email-label">
                {t("email_address")}
              </FormGroupLabel>
              <FormItem disabled={false} id="email-div">
                <FormField
                  name="email"
                  id="email"
                  control={control}
                  component="input"
                  type="email"
                  defaultValue=""
                  placeholder={t("email_address")}
                  style={formInputStyles}
                  disabled={false}
                  rules={{
                    required: t("required_err"),
                    pattern: {
                      value: emailPattern,
                      message: t("invalid_email_err_msg"),
                    },
                  }}
                  //onKeyUp={handleSIOdoChange}
                />
                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
              </FormItem>

              {errors?.email && <Error error={errors.email.message} />}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center my-4">
            <Col
              md={8}
              className="d-flex justify-content-center flex-column gap-2"
            >
              <AddFormButton
                id="submit-btn"
                style={{ background: "#003f7d" }}
                disabled={saveBtnLoading}
              >
                {saveBtnLoading ? <CustomLoader /> : t("submit")}
              </AddFormButton>
            </Col>
          </Row>
        </Container>
      </FormContainerWithScroll>
    </>
  );
};

export default ForgotPasswordForm;

//styles
const FormContainerWithScroll = styled(FormContainer)`
  //height: ${window.innerHeight - 400}px;
  height: 270px;
  width: ${window.innerWidth - 800}px;
  overflow: auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  background: white;
  ${scrollbarStyles};
`;
